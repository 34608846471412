<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    let tour_settings_loaded = false;
    let tour_settings_data = null;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();  
        console.log(json)  ;
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") || 
              (key == "enable_hotspots" && json[0][key] == "0") || 
              (key == "enable_autorotate" && json[0][key] == "0") ||
              (key == "enable_advertisement" && json[0][key] == "0") || 
              (key == "enable_gyro" && json[0][key] == "0") ||
              (key == "enable_vr" && json[0][key] == "0") ||
              (key == "enable_sound" && json[0][key] == "0") ||
              (key == "enable_floorspots" && json[0][key] == "0") ||
              (key == "enable_new_transition" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
            (key == "enabled" && json[0][key] == "1") || 
              (key == "enable_hotspots" && json[0][key] == "1") || 
              (key == "enable_autorotate" && json[0][key] == "1") ||
              (key == "enable_advertisement" && json[0][key] == "1") || 
              (key == "enable_gyro" && json[0][key] == "1") ||
              (key == "enable_vr" && json[0][key] == "1") ||
              (key == "enable_sound" && json[0][key] == "1") ||
              (key == "enable_floorspots" && json[0][key] == "1") ||
              (key == "enable_new_transition" && json[0][key] == "1")
          ) {
              json[0][key] = true
          }
          
        }

        tour_settings_data = json[0];
        
        tour_settings_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("tour_settings");

    async function save_settings() {
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(tour_settings_data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateTourSettings.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  
 
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if tour_settings_loaded}
    <main in:fade>
      
      <h1>{$t("admin")["aside"]["components"]["settings"]}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      
      <div class="component item">
        <label >{$t("admin")["global"]["enabled"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enabled}>
      </div>   

      <div class="component item">
        <label >{$t("admin")["components"]["tour_settings"]["enable_hotspots"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_hotspots}>
      </div>   

      <div class="component item">
        <label >{$t("admin")["components"]["tour_settings"]["enable_autorotate"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_autorotate}>
      </div>   

      <div class="component item">
        <label >{$t("admin")["components"]["tour_settings"]["enable_ad"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_advertisement}>
      </div>   

      <div class="component item">
        <label >{$t("admin")["components"]["tour_settings"]["enable_gyro"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_gyro}>
      </div>   

      <div class="component item">
        <label >{$t("admin")["components"]["tour_settings"]["enable_vr"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_vr}>
      </div>   

      <div class="component item">
        <label >{$t("admin")["components"]["tour_settings"]["enable_sounds"]}</label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_sound}>
      </div>     

      <div class="component item">
        <label >Body class name: </label>
        <input type="text" bind:value={tour_settings_data.body_class_name}>
      </div>   
      
      <div class="component item">
        <label >Enable Floorspot ?: (zobraziť floorspoty stále) </label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_floorspots}>
      </div>   

      <div class="component item">
        <label >Enable New transition ? (2D smooth transition): </label>
        <input type="checkbox" bind:checked={tour_settings_data.enable_new_transition}>
      </div>   

      <div class="component item">
        <label >Defaultné SVG floorspotu: </label>
        <input type="text" bind:value={tour_settings_data.dizajn_hotspotu}>
      </div>   

      <div class="component item">
        <label >Default SCALE floorspotu: </label>
        <input type="text" bind:value={tour_settings_data.scale_hotspotu}>
      </div>   

      <div class="component item">
        <label >Default FOV: </label>
        <input type="text" bind:value={tour_settings_data.default_fov}>
      </div>   

      <div class="component item">
        <label >MIN FOV: </label>
        <input type="text" bind:value={tour_settings_data.fovmin}>
      </div>   

      <div class="component item">
        <label >MAX FOV: </label>
        <input type="text" bind:value={tour_settings_data.fovmax}>
      </div>   
      
    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>
  