<script>
  let show_enter_vr = true;
    import { fade, scale } from 'svelte/transition';
    import {get, writable} from 'svelte/store';
    import { device_type } from '../store.js';
    import { sidebar } from '../store.js';
    import { shortcuts } from '../store.js';
    let tmp;

    let browserName;
    let userAgent = navigator.userAgent;
    function detectBrowser() {
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
      } else if (userAgent.match(/android/i)) {
        browserName = "Android";
      } else if (userAgent.match(/iphone/i)) {
        browserName = "iPhone";
      } else {
        browserName = "Unknown";
      }
    }

    var OS = navigator.platform;
    

    detectBrowser();

    // console.log (OS + ' |' + browserName);

    device_type.subscribe(value => {
      tmp = value;
      
    });

    let sidebar_opened;
    sidebar.subscribe(value => {
      sidebar_opened = value;

      if (
        sidebar_opened
      ) {
        show_enter_vr = false;
      }
    });

    let shortcuts_opened;
    shortcuts.subscribe(value => {
      shortcuts_opened = value;

      if (
        shortcuts_opened
      ) {
        show_enter_vr = false;
      }
    });

    

    const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);

    function enter_vr() {
      // let krpano = document.getElementById("pano");
      let krpano = document.getElementById('krpanoSWFObject');
      // console.log(krpano);
      show_enter_vr = false;
      krpano.call("webvr.enterVR();");
    }

</script>

<div id="woow-enter-vr">
{#if show_enter_vr == true && sidebar_opened == false && OS.includes('Linux1')} 
  <div id="enter_vr" transition:animate>
    <p>Chcete vstúpiť do režimu VR? </p>
    <div class="buttons">
      <button id="exit" on:click={() => show_enter_vr = false}>Zrušit</button>
      <button id="enter" on:click="{() => enter_vr()}">Vstúpiť</button>
    </div>
  </div>
{/if}

</div>

<style lang="scss">
  @import "../assets/scss/mixins.scss";
  @import "../assets/scss/fonts.scss";
  #woow-enter-vr{
  #enter_vr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px 22px;
    z-index: 1;
    @include blur;
    border-radius: 12px;
    box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.2); 

    p {
      @include rubik-m16;

      /* identical to box height, or 150% */
      text-align: center;

      /* Neutral/(700) #3F3F46 */
      color: #3F3F46;
    }

    .buttons  {
      display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin-top: 22px;
        
        

      button {
        @include rubik-m16;
        font-size: 14px;


      }

      #enter {
          background-color: #4F46E5;
        }

        #exit {
          background-color: #3F3F46;
        }
      

      button {
        margin: 0;
      }
    }
  }
}
</style>