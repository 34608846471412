<script>
  import { get } from 'svelte/store';
  import { tooltip } from "@svelte-plugins/tooltips";
  import { fly } from 'svelte/transition';
  import { t, locale, locales } from "../i18n.js";
  import { should_deactivate_otacadlo_graphic, show_floorplan, tooltipAnimation } from '../store.js';
  import { vr_question } from '../store.js';
  import { vr_mode } from '../store.js';
  import { gyro_mode } from '../store.js';
  import { gyro_available, hotspots , active_components} from '../store.js';
  import { mobile_submenu } from '../store.js';
  import { mobile_settings_submenu } from '../store.js';
  import { sidebar } from '../store.js';
  import { shortcuts, tour_settings, cart, measures, languages, enabled_measure, tutorial } from '../store.js';
  import { contact_form_enabled, flat_filter_enabled, dizajn, active_scene, all_scenes, new_model, dispositions_enabled, hide_all_hotspots_not_patches, permanently_hotspots, enable_otacadlo } from '../store.js';

  export let userdata, sample_flat_name, sample_flat_scenes, layers_settings, social_settings, floorplan_settings, contact_form_settings, google_map_settings, tour_info_settings, settings_settings, model_3d_settings, mode, enable_bookcall, enable_vr, enable_sound, enable_tutorial, _dispositions_enabled;
  //////console.log(enable_sound);
  ////console.log(userdata);
  //console.log(settings_settings);

  let soravia = false;
  let url = String(window.location);
  if (url.includes("soravia.html")) {
      soravia = true;
  }

  

  let _tutorial, _permanently_hotspots, _enable_otacadlo = null;
  tutorial.subscribe(value => {
      _tutorial = value;
  });

  enable_otacadlo.subscribe(value => {
      _enable_otacadlo = value;
  });

  

  permanently_hotspots.subscribe(value => {
      _permanently_hotspots = value;
  });
  

  // tmp

  let current_scene = null;
  let enabled_dizajn, _should_deactivate_otacadlo_graphic = false;
  let _measures;

  should_deactivate_otacadlo_graphic.subscribe((value) => {
    _should_deactivate_otacadlo_graphic = value
  });

  active_scene.subscribe((value) => {
		current_scene = value;
    
    // mám zobraziť zmenu vrstiev ?
    sample_flat_scenes.forEach(element => {
      if (element.name == get(active_scene)) {
        enabled_dizajn = element.enable_design;
      } 
    });
    
	});

  measures.subscribe((value) => {
		_measures = value;   
    //////console.log(value);
    switch (value) {
      case true:
        break;
    
      default:
        break;
    }
	});

  let _dizajn;
  if (layers_settings != null ) {
    sort(layers_settings, "layer_order");
    ////console.log(layers_settings);
  }
  
  
  let _flat_filter_enabled = false;
  flat_filter_enabled.subscribe((value) => {
    _flat_filter_enabled = value;
  });
  

  // zmena dizajnu / vrstvy
  function change_design($target, $value) {
    //////console.log($target + " : " + $value + " = " + krpano.get($target));
    let krpano = document.getElementById("krpanoSWFObject");  
    krpano.set($target, $value);
    dizajn.update(n => $value);
    //krpano.call("loadscene(" + get(active_scene) + ", null, KEEPVIEW, BLEND(1));");
  }



  dizajn.subscribe((value) => {
    _dizajn = value;
    
    let krpano = document.getElementById("krpanoSWFObject");  
    krpano.set("dizajn", value);
    //////console.log(krpano.get("dizajn"));
    krpano.call("loadscene(" + get(active_scene) + ", null, KEEPVIEW, BLEND(1));");
  });

  
  
  let krpano = document.getElementById("krpanoSWFObject");


  let _vrMode;

  vr_mode.subscribe((value) => {
    _vrMode = value;
    // switch (value) {
    //   case true:
    //   krpano.call("webvr.enterVR()");
    //     break;

    //   case false:
    //     krpano.call("webvr.exitVR()");
    //     break;
    // }
    switch_vr_mode();
  });

  let _hotspots;

  hotspots.subscribe((value) => {
    _hotspots = value;
    ////console.log("hotspots :" + value);
    switch (value) {
      case true:
        enable_hotspots();
        break;

      case false:
        disable_hotspots();
        break;
    }
  
  });

  
  import { disabled_icons, showing_intro } from '../store.js';

  let dis_icon = get(disabled_icons);

  disabled_icons.subscribe(value => {
  
		dis_icon = value;
	});

  let _sidebar = false;

  languages.subscribe(value => {
    if (value) {
      if (innerWidth < 650) {
        sidebar.update( n=> false);
      }
    }
  });

  sidebar.subscribe(value => {
		_sidebar = value;

    if (
      value == true
    ) {
      shortcuts.update(n => false);

      if (innerWidth < 650) {
        cart.update(n => false);
        languages.update(n => false);
      }
      vr_question.update(n => false);
    }
	});

  let blur_enabled = false;

  import { blur } from '../store.js';
  blur.subscribe(value => {
    
    blur_enabled = value;
  });

  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  
  let last_component;
  let is_vr = false;
  let is_gyro = false;
  let is_gyro_available = false;
  let mob_submenu = false;
  let mob_settings_submenu = false;

  gyro_mode.subscribe(value => {
		is_gyro = value;
	});

  gyro_available.subscribe(value => {
		is_gyro_available = value;
	});

  mobile_submenu.subscribe(value => {
		mob_submenu = value;
	});

  mobile_settings_submenu.subscribe(value => {
		mob_settings_submenu = value;
	});

  $: {
    is_gyro;

    if (krpano == null) {
      krpano = document.getElementById("krpanoSWFObject");
    }

    krpano.call("set(plugin[gyro].enabled, " + is_gyro + ");");
  } 

  function enable_autorotate () {
    if (krpano == null) {
      krpano = document.getElementById("krpanoSWFObject");
    }
    krpano.call("autorotate.start()");
  }

  function disable_autorotate () {
    if (krpano == null) {
      krpano = document.getElementById("krpanoSWFObject");
    }
    krpano.call("autorotate.stop()");
  }

  let autorotate = false;
  // zapínanie vypínanie autorotácie
  $: if (autorotate == true) {
    enable_autorotate();
  }
   
  // zapínanie vypínanie autorotácie
  $: if (autorotate == false) {
    disable_autorotate();
  }

  import { play_sound } from '../store.js';

  // $: if (settings.components[5].values[5].enabled == true) {
  //   // enable background sound
  //   play_sound.update(n=> true);
  // }

  // $: if (settings.components[5].values[5].enabled == false) {
  //   // enable background sound
  //   play_sound.update(n=> false);
  // }


  function enable_hotspots () {
    hide_all_hotspots_not_patches.update(n => false);
    if (krpano == null) {
      krpano = document.getElementById("krpanoSWFObject");
    }
    // krpano.call("show_hotspots");
    // krpano.set("show_hs", "true");

    let hotspots_array = krpano.get("hotspot").getArray();
    //////console.log(hotspots_array);
      hotspots_array.forEach(element => {
        if (element.patch != undefined && element.patch != null) {
          krpano.call("set(hotspot[" + element.name + "].alpha, 1);");  
        } else if (element.floorspot && !_permanently_hotspots) {
          krpano.call("set(hotspot[" + element.name + "].alpha, 0.0);");
        } else {
          krpano.call("set(hotspot[" + element.name + "].alpha, 0.4);");
        }
      
        krpano.call("set(hotspot[" + element.name + "].enabled, true);");
      });
    
  }

  function disable_hotspots () {
    hide_all_hotspots_not_patches.update(n => true);
    if (krpano == null) {
      krpano = document.getElementById("krpanoSWFObject");
    }
    // krpano.call("hide_hotspots");
    // krpano.set("show_hs", "false");

    let hotspots_array = krpano.get("hotspot").getArray();
    ////console.log(hotspots_array);
      hotspots_array.forEach(element => {
        
        if ((element.patch != undefined && element.patch != null)  ) {
          
        } else {
          if (element.woow_id != undefined && element.name.indexOf("hotspot") != 0) {
            ////console.log(element.name +  element.name.indexOf("hotspot"));
            krpano.call("set(hotspot[" + element.name + "].alpha, 0.0);");
            krpano.call("set(hotspot[" + element.name + "].enabled, false);");
          }
        }

        if (element.name.includes("abu_simbel")) {

            krpano.call("set(hotspot[" + element.name + "].alpha, 0.0);");
            krpano.call("set(hotspot[" + element.name + "].enabled, false);");
        }
      });


      setTimeout(() => {
        ////console.log("teraz");
          krpano.call("doubleclick_style_onclick();"); 
      }, 2000);
      
      
  }

  function hide_hotspots_not_patches() {
      let hotspots_array = krpano.get("hotspot").getArray();
      
      ////console.log(hotspots_array[10].name);
      let name = hotspots_array[10].name;
      krpano.call("set(hotspot[" + name + "].alpha, 0.0);");
      

  }

  // setTimeout(() => {
  //   hide_hotspots_not_patches();
  // }, 3000);

  // // zapínanie vypínanie hotspotov
  // $: if (settings.components[5].values[0].enabled == true) {
  //   enable_hotspots();
  // }

  // // zapínanie vypínanie autorotácie
  // $: if (settings.components[5].values[0].enabled == false) {
  //   disable_hotspots();
  // }

  let active = false, navigating = false;

  function share_facebook() {
    let url = 'https://www.facebook.com/sharer/sharer.php?u='+userdata["base_path"];
    window.open(url, '_blank').focus();
  }

  function share_twitter() {
    let url = 'https://twitter.com/share?url='+userdata["base_path"];
    window.open(url, '_blank').focus();
  }

  function share_linkedIn() {
    let url = 'https://www.linkedin.com/shareArticle?mini=true&url='+userdata["base_path"];
    window.open(url, '_blank').focus();
  }

  function copy_url() {
    document.getElementById('share_link').select();
		document.execCommand('copy');

    addNotification({
      text: $t("components")['share']['link'],
      position: "top-center",
      removeAfter: 4000,
      type: "success",
    });
    
  }

  function switch_vr_mode() {
    if (krpano == null) {
      krpano = document.getElementById("krpanoSWFObject");
    }
    if (krpano) {
      switch (_vrMode) {
        case true : 
          krpano.call("webvr.enterVR()");  
          vr_mode.update(n => true);
        break;

        case false : 
          krpano.call("webvr.exitVR()");        
          vr_mode.update(n => false);
        break;
      }
    }
  }

  function switch_gyro_mode() {
    gyro_mode.update(n => !n);
    // if (
    //   is_gyro == true
    // ) {
    //   // ////console.log('vypínam gyro');
    //   gyro_mode.update(n => false);
    // }

    // else {
    //   // ////console.log('zapínam gyro');
    //   gyro_mode.update(n => true);
      
    // }
    
  }

  // zapnutie / vypnutie modalu
  import { modal } from '../store.js';
  import { modal_type } from '../store.js';


  let enable_zvuk = false;
  let enabled_share_sub, enabled_layers_sub = false;
  let enabled_settings_sub = false;

  let isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera());
    },
    vr : function () {
      return(isMobile.Windows == false);
    }
  };

  var OS = navigator.platform;

  function enable_share() {
    if (
      // isMobile.any() || OS.indexOf('linux') != - 1
      window.matchMedia("(pointer: coarse)").matches
      
    ) {
      mob_submenu = true;
      enabled_share_sub = true;
      // ////console.log(enabled_share_sub);
    }
    
  }

  function enable_layers() {
    if (
      // isMobile.any() || OS.indexOf('linux') != - 1
      window.matchMedia("(pointer: coarse)").matches
      
    ) {
      mob_submenu = true;
      enabled_layers_sub = true;
      // ////console.log(enabled_share_sub);
    }
    
  }

  function enable_settings() {
    
    
    if (
      window.matchMedia("(pointer: coarse)").matches
    ) {
      mob_submenu = false;
      mob_settings_submenu = !mob_settings_submenu;
      enabled_share_sub = false;
      enabled_settings_sub = !enabled_settings_sub;
      // ////console.log(enabled_settings_sub);
    }
    
  }

     // sortovanie produktov
     function sort(data, key) {
      data.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
      data = data;
    }

  import {newDelay} from "../store.js";
  let delay = 0;
  async function setDelay(newDelay) {
		delay = newDelay;
	}


  function animate_wall() {
    
    if (krpano.get("hotspot[video2]") != null) {
      krpano.call("moveto(240,0)");	
      let video_time = krpano.get("hotspot[video2].time");
      switch (video_time) {
        case 0:
          krpano.call("hotspot[video2].play()");
          let timer = setTimeout(() => {
            krpano.call("hotspot[video2].pause()");
          }, 3400);
          break;
      
        default:
          krpano.call("hotspot[video2].play()");
          krpano.set("hotspot[video2].onvideocomplete", function(hs) {
            krpano.call("hotspot[video2].pause()");
            krpano.call("hotspot[video2].seek(0.0)");
          });
          break;
      }
      
    }
  }

  let measure_type = 1;
  let show_measure_close_icon = false;

  function measure_1() {
    show_measure_close_icon = true;
    krpano.call("depthmap_measure3d_start(1)"); 
    sidebar.update(n => false);
    disabled_icons.update(n => true);
    shortcuts.update(n => false);
    show_floorplan.update(n => false);
    cart.update(n => false);
    enabled_measure.update(n => true);
    disable_hotspots();
    measure_type = 1;
  }

  function measure_2() {
    show_measure_close_icon = true;
    krpano.call("depthmap_measure3d_start(2)"); 
    sidebar.update(n => false);
    disabled_icons.update(n => true);
    shortcuts.update(n => false);
    show_floorplan.update(n => false);
    cart.update(n => false);
    enabled_measure.update(n => true);
    disable_hotspots();
    measure_type = 2;
  }

  function enable_measure() {
    switch (measure_type) {
      case 1:
        krpano.call("depthmap_measure3d_start(1)"); 
        break;
    
      default:
      krpano.call("depthmap_measure3d_start(2)"); 
        break;
    }
  }

  function dispo_toggle() {
        dispositions_enabled.update( n => !n);
    }

    function disable_measure() {
      enabled_measure.update(n => false);
      disabled_icons.update(n => false);
      enable_hotspots();
      krpano.call("stop_measuring()"); 
      show_measure_close_icon = false;
    }

</script>


<div id="woow-sidebar-new">
  <p>{_enable_otacadlo} {_dispositions_enabled}</p>
  {#if _vrMode == false && (!_enable_otacadlo || _should_deactivate_otacadlo_graphic) && !_dispositions_enabled}
    {#if userdata["tooltips"] == true}
       <!-- content here -->
      <div class="sidebar-icon" class:active="{_sidebar == true || _flat_filter_enabled}" class:hide_blur="{blur_enabled === true || dis_icon == true || soravia}" on:click="{() => sidebar.update(n => true)}" on:click="{() => show_floorplan.update(n => false)}"
       use:tooltip={{ content: $t("components")['tooltips']['sidebar'],  position: 'right', align: 'center', animation: get(tooltipAnimation), theme: 'tooltip-woow'}}  
       />
      {:else}
        <div class="sidebar-icon" class:active="{_sidebar == true || _flat_filter_enabled}" class:hide_blur="{blur_enabled === true || dis_icon == true || soravia}" on:click="{() => sidebar.update(n => true)}" on:click="{() => show_floorplan.update(n => false)}"></div>
    {/if}
    
  {/if}
  

  {#if _sidebar == true && _vrMode == false}
    <div id="sidebar" data-id="{_sidebar}" transition:fly={{x: -296, delay}}>
      <div class="header">
        

        {#if mob_submenu == true || mob_settings_submenu == true}
          <h2 class="active" on:click="{() => enabled_share_sub = false}" on:click="{() => enabled_layers_sub = false}" on:click="{() => enabled_settings_sub = false}" on:click="{() => mob_submenu = false}" on:click="{() => mob_settings_submenu = false}" >
            {#if userdata.base_path == "https://mollyinvr.com/projects/semerinka/"}
               Varianty
              {:else}
                {$t("components")['sidebar_menu']['sidebar-back']}
            {/if}
            
          </h2>
        {/if}

        {#if mob_submenu == false && mob_settings_submenu == false}
          <h1 class="active"> {$t("components")['sidebar_menu']['sidebar-title']}</h1>
        {/if}

        <div class="close" on:click="{() => sidebar.update(n => false)}"/></div>
      <div id="sidebar-content" class="content" class:active="{mob_submenu == false && mob_settings_submenu == false }">
        <ul>
          {#if sample_flat_name.includes("B3H")}
            <li class="dispositions" on:click="{() => dispo_toggle()}" on:click="{() => sidebar.update(n => false)}">
              {$t("components")['dispositions']['client-change']}
            </li>
          {/if}
          
          {#if floorplan_settings != null}
            <li class="floor-plan" on:click="{() => sidebar.update(n => false)}" on:click="{() => blur.update(n => true)}" on:click="{() => show_floorplan.update(n => !n)}">
              {$t("components")['floorplan']['sidebar-title']}
            </li>
          {/if}
          
          {#if layers_settings != null}
            <li class="layers submenu" on:click={() => enable_layers()}>
              <!-- {$t("components")['layers']['sidebar-title']} -->
              {#if userdata.base_path == "https://mollyinvr.com/projects/semerinka/"}
                Varianty
                {:else}
                {$t("components")['layers']['sidebar-title']}
              {/if}
              <!-- <div id="layers-container_submenu" class:active="{enabled_layers_sub}" class="layers-container"> -->
                <ul class:layers-semerinka="{userdata.base_path == 'https://mollyinvr.com/projects/semerinka/'}" class:active="{enabled_layers_sub}">
                  {#each layers_settings as layer}
                    <!-- {#if layer.name == "Zariadený"}
                      <li class="layer-item" class:active="{_dizajn == layer.krpano_value}" on:click={() => change_design(layer.krpano_variable, layer.krpano_value)}>{layer.name}</li>
                    {:else}
                      <li class="layer-item" class:active="{_dizajn == layer.krpano_value}" on:click={() => change_design(layer.krpano_variable, layer.krpano_value)}> {$t("components")['layers']['second']}</li>
                    {/if}   -->
                    <li class="layer-item" class:active="{_dizajn == layer.krpano_value}" on:click={() => change_design(layer.krpano_variable, layer.krpano_value)}>
                      {$t("components")['layers'][layer.krpano_value]} 
                    </li>
                  {/each}
                </ul>
              <!-- </div> -->
            </li>   
          {/if}
          {#if model_3d_settings != null && mode != "public"}
            <li class="model-3d"
              on:click="{() => new_model.update(n => true)}"
              
              on:click="{() => active_components.update(n => "sidebar")}">
               {$t("components")['model3d']['sidebar-title']}
            </li>
          {/if}
          <!-- <li class="model-3d"
            on:click="{() => modal.update(n => true)}"
            on:click="{() => modal_type.update(n => "google-map2")}"
            
            on:click="{() => active_components.update(n => "sidebar")}">
            {$t("components")['rotator']['sidebar-title']}
          </li> -->
          
        <!-- 
          <li class="layers submenu">
            Layers
            <ul class="">
              <li class="layer-item">Layer 1 - (Random name)</li>
              <li class="layer-item active">Layer 2 - (Random name)</li>
              <li class="layer-item active">Layer 3 - (Random name)</li>
            </ul>
          </li>
          <li class=" attributes">Attributes</li>
          <li class=" google-map">Google map</li>
          <li class=" tour-info">Tour info</li>
          <li class="mortgage-calculator">Mortgage calculator</li>
          <li class="chatbot">ChatBot</li>
          
          <li class="product-filter submenu">
            Product filter
            <ul>
              <li class="sub-submenu">
                <label for="p-filter"
                  >product filter<input type="checkbox" id="p-filter" /></label
                >
              </li>
              <li>nábytok<span>2</span></li>
              <li>osveltenie<span>16</span></li>
              <li class="active">dekorácie<span>3</span></li>
              <li>textilie<span>1</span></li>
            </ul>
          </li>-->

          <!-- komponent share -->
          {#if social_settings != null}        
            <li  class="share submenu" on:click={() => enable_share()}>{$t("components")['share']['sidebar-title']} 
              <div id="share-container_submenu" class:active="{enabled_share_sub}" class="share-container">
                <div class="social-icons">
                  
                    {#if social_settings.enable_facebook == true}
                      <div class="social-item facebook" on:click="{share_facebook}"></div>
                    {/if}

                    {#if social_settings.enable_twitter}
                      <div class="social-item twitter" on:click="{share_twitter}"></div>
                    {/if}

                    {#if social_settings.enable_linkedin}
                        <div class="social-item linkedin" on:click="{share_linkedIn}"></div>
                    {/if}
                  
                </div>
                <div class="social-link">
                  <input id="share_link" type="text" value="{userdata["base_path"]}">
                  <div class="copy" on:click="{copy_url}"></div>
                </div>
              </div>
            </li>
          {/if}
        

          <!-- komponent google mapa -->
          {#if google_map_settings != null}
            <li class="google-map" 
              on:click="{() => modal_type.update(n => 'google-map')}"
              on:click="{() => modal.update(n => true)}"
              on:click="{() => active_components.update(n => "sidebar")}">
              {$t("components")['maps']['sidebar-title']}
            </li>
          {/if}

          <!-- komponent contact form -->
          {#if contact_form_settings != null}
            <li class="contact_form" 
              on:click="{() => sidebar.update(n => false)}"
              on:click="{() => contact_form_enabled.update( n => true)}"
              on:click="{() => active_components.update(n => "sidebar")}">
              {$t("components")['contact_form']['sidebar-title']}
            </li>
          {/if}
          
          {#if tour_info_settings != null}
            <li class="tour-info" 
              on:click="{() => modal_type.set('tour-info')}"
              on:click="{() => modal.update(n => !n)}">
              {$t("components")['tourinfo']['sidebar-title']}
            </li>
          {/if}

          {#if enable_bookcall}
            <li class="book_call"
              on:click="{() => modal_type.update(n => 'book-call')}"
              on:click="{() => modal.update(n => true)}"
              on:click="{() => active_components.update(n => "sidebar")}">
              {$t("components")['book_call']['title']}
            </li>
          {/if}
          

          {#if current_scene == "B3G_04"}
            <li class="animate-wall"
              on:click="{() => animate_wall()}">
              {$t("components")['walls']['animate']}
            </li>
          {/if}

          {#if _measures}
            <li class="measure1"
              on:click="{() => measure_1()}">
              {$t("components")['walls']['measure1']}
            </li>

            <li class="measure2"
              on:click="{() => measure_2()}">
              {$t("components")['walls']['measure2']}
            </li>
          {/if}            
          
        </ul>
      </div> 
      <div class="footer">
        <ul>
          <li class="settings submenu" on:click={() => enable_settings()}>{$t("components")['settings']['title']}
            <ul class:active="{enabled_settings_sub}"class="settings-container">

              {#if enable_tutorial}
              <li class="">
                <label class="settings-item">{$t("components")['tutorial']['title']}
                  <input type="checkbox" bind:checked="{_tutorial}"  on:click="{() => tutorial.update(n => !n)}" on:click="{() => sidebar.update(n => false)}" on:click="{() => active_components.update(n => "sidebar")}"/>
                </label>
              </li>
            {/if}
              <li class="">
                <label class="settings-item">{$t("components")['settings']["autorotate"]}
                  <input type="checkbox" bind:checked="{autorotate}"/>
                </label>
              </li>
              <li class="">
                <label class="settings-item">{$t("components")['settings']["permanently_hotspots"]}
                  <input type="checkbox" bind:checked="{_permanently_hotspots}"  on:click="{() => permanently_hotspots.update(n => !n)}"/>
                </label>
              </li>
              {#if settings_settings.enable_hotspots == true}
                <li class="">
                  <label class="settings-item">{$t("components")['settings']["show_hotspots"]}
                    <input type="checkbox" bind:checked="{_hotspots}"  on:click="{() => hotspots.update(n => !n)}"/>
                  </label>
                </li>
              {/if}


              
                <li class="">
                  <label class="settings-item">{$t("components")['settings']["vr_mode"]}
                    <input type="checkbox" bind:checked="{_vrMode}"  on:click="{() => sidebar.update(n => false)}" on:change="{() => switch_vr_mode()}"/>
                  </label>
                </li>
              

              
              
                <li class="">
                  <label class="settings-item">{$t("components")['settings']["gyro"]}
                    <input type="checkbox" bind:checked="{is_gyro}" on:change="{() => switch_gyro_mode()}"/>
                  </label>
                </li>
              
              
              
              {#if enable_sound == true}
                <li class="">
                  <label class="settings-item">{$t("components")['settings']["sound"]}
                    <input type="checkbox" bind:checked="{enable_zvuk}"/>
                  </label>
                </li>
              {/if}

          
        
            </ul>

          </li>
          
        </ul>
        

        
      </div>
    </div>
  {/if}


  {#if enable_zvuk == true}
		{#await import('./Sounds.svelte') then c}
			<svelte:component this={c.default}/>
		{/await}
	{/if}

  {#if show_measure_close_icon}
  <div id="close-measure" on:click={() => disable_measure()} />
     <!-- content here -->
  {/if}

</div>

  <style lang="scss">
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";
    
    #woow-sidebar-new{
    #measure {
      position: absolute;
      top: 72px;
      left: 16px;
      width: 40px;
      height: 40px;
      z-index: 2;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
    
    }

    #close-measure {
    top: 72px;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    z-index: 10;;

    &.mobile {
      top: 24px;
    }

    &.tablet-landscape {
      top: 32px;
    }
  }

  .sidebar-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    @include blur;
    border-radius: $radius-S;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: var(--primary-color);
      
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      opacity: 0;
      visibility: hidden;
      
    }
  }

  #sidebar {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    width: $sidebar-width;
    height: calc(100% - 30px);
    @include blur;
    border-radius: $radius-M;
    transition: all 0.2s ease-in-out;
    z-index: 11;

    &.active {
      left: 16px;
    }

    .header {
      padding: 0px 16px 2px 16px;
      @include underline-2-a;

      h1 {
        @include rubik-m20;
        padding-right: 28px;
        margin-bottom: 8px;
        display: none;
        &.active {
          display: block;
        }
      }

      h2 {
        display: none;
        align-items: center;
        @include rubik-r14;
        height: 24px;
        margin-bottom: 10px;
        cursor: pointer;
        &::before {
          content: "";
          width: 8px;
          height: 14px;
          background-color: var(--primary-color);
          mask-repeat: no-repeat;
          -webkit-mask-repeat: no-repeat;
          mask-size: cover;
          -webkit-mask-size: cover;
          display: inline-block;
          transform: rotate(180deg);
          margin: 0 21px 0 8px;
        }
        &.active {
          display: flex;
        }
      }
    }

    .content {
      overflow: auto;
      height: calc(100% - 121px);
      padding: 12px 8px;
      transition: all 0.25s ease-in-out;
      // position: relative;
    }

    .content,
    .footer {
      ul {
        @include rubik-r14;
        margin: 0;
        padding: 0;

        li {
          width: 100%;
          height: 48px;
          padding-left: 8px;
          cursor: pointer;
          margin-bottom: $spacing-S;
          display: flex;
          align-items: center;

          &.active {
            &:hover {
              background-color: var(--main-color-light10);
              border: 1px solid var(--main-color-light60);
            }
          }

          &.user {
            &:hover {
              background-color: transparent;
            }
          }
        

          &.active {
            background-color: var(--main-color-light005);
            border-radius: $radius-S;
            border: 1px solid var(--main-color-light20);
            color: var(--main-color);

            &::before {
              background-color: var(--main-color);
            }

            &.submenu {
              &::after {
                background-color: var(--main-color);
              }
            }
          }

          &.submenu {
            &::after {
              content: "";
              position: relative;
              width: 6.67px;
              height: 11.67px;
              background-color: var(--primary-color);
             
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center;
              margin-left: auto;
              margin-right: 8px;
              
            }
          }

          &::before {
            content: "";
            position: relative;
            margin-right: 12px;
            width: 24px;
            height: 24px;
            background-size: 100%;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: center;
            -webkit-mask-position: center;
            mask-size: contain;
            -webkit-mask-size: contain;
            background-color: var(--primary-color);
            display: block;
          }

          


          ul, .share-container{
            visibility: hidden;
            opacity: 0;
            color: var(--primary-color);
            position: absolute;
            left: $sidebar-width;
            width: $sidebar-width;
            @include blur;
            padding: $spacing-S;
            border-radius: $radius-M;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-self: baseline;
            margin-left: 16px;
            //margin-left: 0px;
            //transition: 0.35s all ease-out !important;

            &:before {
              content: '';
              position: absolute;
              left: -30px;
              top: 0px;
              width: 30px;
              height: 100%;
              display: block;
              
            }

              &.settings-container{
                align-self: end;
                padding: 8px 8px 0 8px;
                margin: -8px 0 -8px 16px;
                transition: all 0.25s ease-in-out;
              }

            &.active {
              visibility: visible;
            }

            li {
              padding-left: $spacing-S;
              display: flex;
              justify-content: space-between;

              &.layer-item{
                justify-content: flex-start;
                &::before {
                    content: "";
                    position: relative;
                    margin-right: 12px;
                    width: 20px;
                    height: 18px;
                    background-size: 100%;
                    
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: var(--primary-color);
                    display: block;
                  }
                  &.active {
                    &::before {
                      background-color: var(--main-color);
                    }
                  }
              }

              &.sub-submenu {
                margin-bottom: 7px;
              }

              &.active {
                span {
                  background-color: var(--main-color-light005);
                }
              }

              label {
                position: relative;
                height: 48px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                cursor: pointer;
                &:not(.settings-item) {
                  @include underline-1-a(8px);
                  &::after {
                  left: 0;
                  transform: translate(0%);
                }
                }
              }

              input {
                margin-right: 8px;
              }

              &::before {
                display: none;
              }

              span {
                margin-right: 8px;
                width: 24px;
                height: 24px;
                background-color: var(--primary-color-light005);
                border-radius: $radius-S;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .share-container{
            justify-content: flex-start;
            align-items: flex-start;
            cursor: default;
            transition: all 0.25s ease-in-out;
              .social-icons {
                display: flex;
                .social-item{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 40px;
                  height: 40px;
                  box-shadow: 0 0 0 1px var(--primary-color-light20);
                  border-radius: $radius-S;
                  margin: 0 16px 8px 0;
                  cursor: pointer;

                    &::after{
                      content: '';
                      display: block;
                      width: 21px;
                      height: 21px;
                      background-color: var(--primary-color);
                      mask-position: center;
                      -webkit-mask-position: center;
                      mask-repeat: no-repeat;
                      -webkit-mask-repeat: no-repeat;
                      // mask-size: contain;
                    }
                }
              }
              .social-link{
                display: flex;
                align-items: center;
                  input{
                    width: 215px;
                    height: 40px;
                    border-radius: $radius-S;
                    margin-right: 8px;
                    border: 1px solid var(--primary-color-light20);
                    background-color: transparent;
                  }
                  .copy{
                    width: 40px;
                    height: 40px;
                    background-color: var(--primary-color);
                    border-radius: $radius-S;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                      &::after{
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-color: var(--secondary-color);
                        mask-repeat: no-repeat;
                        -webkit-mask-repeat: no-repeat;

                      
                      }
                  }
              }
          }
        }
      }
    }

 

    .footer {
      @include underline-1-b;
      position: absolute;
      bottom: 13.5px;
      width: 100%;
      padding: 0 8px;

      a {
        position: relative;
        @include rubik-r14;
        color: var(--main-color);
        text-decoration: none;
        margin-left: 44px;
        bottom: 10.5px;

        &:visited {
          color: var(--main-color);
        }
      }
    }


    //  -------------------------------- hover ---------------------------------------------------------------------

    @media (hover: hover) and (pointer: fine) {
     
      ul {
        li {
          &:hover {
            background-color: var(--primary-color-light005);
            border-radius: $radius-S;

            ul, .share-container { 
              opacity: 1;
              visibility: visible;
              transition: 0.35s all ease-in !important;
            }

            .share-container{
              .social-icons{
                .social-item{
                  &:hover{
                    box-shadow: 0 0 0 1px var(--primary-color-light60);
                  }
                }
              }
              .social-link{
                .copy{
                  &:hover{
                    background-color: var(--primary-hover);
                  }
                }
              }
            }

          }
          &.active {
            &:hover {
              background-color: var(--main-color-light10);
              border: 1px solid var(--main-color-light60);
            }
          }

          &.user {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
      }
  }

 //  ----------------POINTER-------------------------------------------------------------------------------------------------------

 @media (pointer: coarse) {
    #sidebar {
      overflow: hidden;
      .content {
        ul {
          height: 100%;
          overflow: auto;
          
        }
    }
      .content{
        overflow: visible;
        position: relative;
        left: -296px;
        height: calc(100% - 121px);
        &.active {
          left: 0px;
        }
        ul{
          li{
            ul, .share-container, .layers-container {
              background-color: transparent;
              position: absolute;
              top: 0;
              transition: .25s;
              &.active {
                display: block;
                opacity: 1;
              }
            }
            .share-container {
              top: 8px;
            }
          }
        }
      }

      .footer {
        position: static;

        ul {
          li {
            ul {
              position: absolute;
              top: 66px;
              height: max-content;
              background-color: transparent;
              backdrop-filter: blur(0px) !important;
              &.active{
                left: -16px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
  </style>