<script>
    import { Router, Route } from 'svelte-routing';
    import ProtectedRoute from './ProtectedRoute.svelte';
    import Notifications from 'svelte-notifications';
    import Dashboard from './routes/admin_components/Dashboard.svelte';
    import Admin from './routes/admin_components/Admin.svelte';
    import Components from './routes/admin_components/Components.svelte';
    import Logo from './routes/admin_components/Logo.svelte';
    import Shortcuts from './routes/admin_components/Shortcuts_new.svelte';
    import TourSettings from './routes/admin_components/TourSettings.svelte';
    import Share from './routes/admin_components/Share.svelte';
    import Maps from './routes/admin_components/Maps.svelte';
    import Tourinfo from './routes/admin_components/Tourinfo.svelte';
    import Floorplan from './routes/admin_components/Floorplan_new.svelte';
    import Users from './routes/admin_components/Users.svelte';
    import AddUser from './routes/admin_components/Add_user.svelte';
    import Login from './routes/Login.svelte';
    import MergeScenes from './routes/admin_components/Merge_scenes.svelte';
    import Sounds from './routes/admin_components/Sounds.svelte';
    import ContactForm from './routes/admin_components/Contact_form.svelte';
    import Tour from './routes/Tour_new.svelte';
    import Meta from './routes/admin_components/Meta.svelte';
    import Scenes from './routes/admin_components/Scenes.svelte';
    import AudioPlayer from "./routes/admin_components/AudioPlayer.svelte";
    import ClearData from './routes/admin_components/Clear_data.svelte';
    import ImportData from './routes/admin_components/Import_data.svelte';
    import ConfiguratorUsers from './routes/admin_components/Configurator_users.svelte';
    import AddConfiguratorUser from './routes/admin_components/Add_Configurator_user.svelte';
    import ImportConfiguratorUsers from './routes/admin_components/Configurator_users_import.svelte';
    import Products from './routes/admin_components/Products.svelte';
    import ProductCategories from './routes/admin_components/Product_categories.svelte';
    import AddProductCategory from './routes/admin_components/Add_configurator_category.svelte';
    import ProductMJ from './routes/admin_components/Product_mj.svelte';
    import AddConfiguratorProductMJ from './routes/admin_components/Add_configurator_mj.svelte';
    import EditConfiguratorProductMJ from './routes/admin_components/Edit_configurator_mj.svelte';
    import ProductStandards from './routes/admin_components/Product_standards.svelte';
    import AddConfiguratorProductStandard from './routes/admin_components/Add_configurator_standard.svelte';
    import EditConfiguratorProductStandard from './routes/admin_components/Edit_configurator_standard.svelte';
    import EditConfiguratorProductCategory from './routes/admin_components/Edit_configurator_category.svelte';
    import AddConfiguratorProduct from './routes/admin_components/Add_configurator_product.svelte';
    import EditConfiguratorProducts from './routes/admin_components/Edit_configurator_product.svelte';
    import ImportConfiguratorProducts from './routes/admin_components/Configurator_products_import.svelte';
    import Flats from './routes/admin_components/Flats.svelte';
    import AddConfiguratorFlat from './routes/admin_components/Add_Configurator_flat.svelte';
    import ImportConfiguratorFlats from './routes/admin_components/Configurator_flats_import.svelte';
    import ConfiguratorSampleFlats from './routes/admin_components/Configurator_sample_flats.svelte';
    import AddConfiguratorSampleFlat from './routes/admin_components/Add_Configurator_sample_flat.svelte';
    import ImportConfiguratorSampleFlats from './routes/admin_components/Configurator_sample_flats_import.svelte';
    import NewScenes from './routes/admin_components/NewScenes.svelte';
    import NewScenesSettings from './routes/admin_components/NewScenesSettings.svelte';    
    import Flat_products from './routes/admin_components/Configurator_flat_products.svelte';
    import Flat_filter from './routes/admin_components/Configurator_flat_filter.svelte';
    import Layers from './routes/admin_components/Layers.svelte';
    import Loader from './routes/Loader.svelte';
    import ImportMax from './routes/admin_components/ImportMax.svelte';
    import Rotator from './routes/admin_components/Rotator.svelte';

    
    

    let userdata = {
    	"username" : null,
	    "password" : null,

        //OFFLINE JURAJ DEMO
        // "cdn_path" : "http://localhost/molly/",
        // "base_path" : "http://localhost/molly/", // ** 4fwfrksf **
        // "assets_folder" : "",
        // "start_project" : "",

        
        // BRATISLAVA DEMO
        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/bratislava/", // ** 4fwfrksf **
        // "assets_folder" : "woowstudio/bratislava/",
        // "start_project" : "",
        // "allow_flat_detail" : true,
        // "ga_id" : "G-49JTMCGHPH"

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/appka/", // ** k2nhtv99_ **
        // "assets_folder" : "bratislava/",
        // "start_project" : "",


        //"base_path" : "https://mollyinvr.com/projects/cobenzlgasse/"  // ** 6776c3un **
        // "assets_folder" : "bratislava/",
        // "start_project" : "",

        //"base_path" : "https://mollyinvr.com/projects/euroveatower/" // ** 63i5fpvs **
        // "assets_folder" : "bratislava/",
        // "start_project" : "",

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/florian/", // ** 5xsej4b1 **
        // "assets_folder" : "alto/florian/",
        // "start_project" : "",

        //"base_path" : "https://mollyinvr.com/projects/lofty/kominarska/" // ** v3s2htgc **
        // "assets_folder" : "bratislava/",
        // "start_project" : "",

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/millhouse/", // ** 5batk3ay **
        // "assets_folder" : "immocap/millhouse/",
        // "start_project" : "",

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/penta/bory/", // ** cawaps7g **
        // "assets_folder" : "penta/nadvorie/",
        // "start_project" : "",

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://konfiguratorbytu.sk/nadvorie/", // ** qcamb5vq **
        // "assets_folder" : "penta/nadvorie/",
        // "start_project" : "EXT",
        
        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/penta/waltrovka/", // ** vve7ulzr **
        // "assets_folder" : "penta/waltrovka/",
        // "start_project" : "FAKE",

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/proxenta/stockerka/", // ** svdf51w5 **
        // "assets_folder" : "proxenta/stockerka/",
        // "start_project" : "",

        // "cdn_path" : "https://cdn.woowstudio.com/",
        // "base_path" : "https://woowstudio.com/projects/proxenta/stockerka/", // ** svdf51w5 **
        // "assets_folder" : "proxenta/stockerka/",
        // "start_project" : "",

        //"base_path" : "https://mollyinvr.com/projects/schwechat-flughafen/"  // ** n628nkbz **
        // "assets_folder" : "bratislava/",
        // "start_project" : "",

        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/semerinka/", // ** nwqzve0k **
        // "assets_folder" : "crestyl/semerinka/",
        // "start_project" : "BATHA",
        
        //Strabag - Soley
        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/strabag/soley/", //  ** 16nkv5xa **
        // "assets_folder" : "strabag/soley/",
        // "start_project" : "FAKE",
        // "allow_flat_detail" : true,
        // "tooltips" : true

        //Strabag - Meischlgasse
        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/strabag/meischlgasse/", //  ** 3hxtvkvt **
        // "assets_folder" : "strabag/meischlgasse/",
        // "start_project" : "FAKE",
        // "allow_flat_detail" : true,
        // "tooltips" : true
        
        "cdn_path" : "https://cdn.mollyinvr.com/",
        "base_path" : "https://mollyinvr.com/projects/vysocany/", // ** gfmtgews **
        "assets_folder" : "penta/vysocany/",
        "start_project" : "",

        // Nový Ružinov
        // "cdn_path" : "https://cdn.mollyinvr.com/",
        // "base_path" : "https://mollyinvr.com/projects/czslovakia/ruzinov/", // ** 27tw6cvu **
        // "assets_folder" : "czslovakia/novy_ruzinov/",
        // "start_project" : "FAKE",
        // "allow_flat_detail" : true,
        // "allow_flat_detail_more_info_button" : true
        
        /* LUKAS PREZENTACNY LINK */
        //"base_path" : "https://woowstudio.com/app/"  // ** k2nhtv99 **

        /*  LUKAS OFFLINE REŽIM */
        // "base_path" : ""

        /* JURAJ DEMA */
        //"base_path" : "https://woowstudio.com/configuration/"  // ** cvral22m **
        //"base_path" : "https://woowstudio.com/dispositions/"  // ** qhh0y2ex **

    }

</script>

<Notifications>
    <Router>
        <ProtectedRoute path="/dashboard" component={Dashboard} {userdata} />
        <ProtectedRoute path="/admin" component={Admin} {userdata}/>
        <ProtectedRoute path="/scenes" component={Scenes} {userdata} />
        <ProtectedRoute path="/users" component={Users} {userdata}/>
        <ProtectedRoute path="/add-user" component={AddUser} {userdata}/>
        <ProtectedRoute path="/meta" component={Meta} {userdata} />
        <ProtectedRoute path="/components" component={Components} {userdata} />
        <ProtectedRoute path="/logo" component={Logo} {userdata} />
        <ProtectedRoute path="/shortcuts" component={Shortcuts} {userdata} />
        <ProtectedRoute path="/tour-settings" component={TourSettings} {userdata} />
        <ProtectedRoute path="/share" component={Share} {userdata} />
        <ProtectedRoute path="/google-maps" component={Maps} {userdata} />
        <ProtectedRoute path="/tour-info" component={Tourinfo} {userdata} />
        <ProtectedRoute path="/floorplan" component={Floorplan} {userdata} />
        <ProtectedRoute path="/components/merge-scenes" component={MergeScenes} {userdata} />
        <ProtectedRoute path="/sounds" component={Sounds} {userdata} />
        <ProtectedRoute path="/contact-form" component={ContactForm} {userdata} />
        <ProtectedRoute path="/components/player" component={AudioPlayer} {userdata} />
        <ProtectedRoute path="/clear-data" component={ClearData} {userdata} />
        <ProtectedRoute path="/import-data" component={ImportData} {userdata} />
        <ProtectedRoute path="/product-categories" component={ProductCategories} {userdata} />
        <ProtectedRoute path="/add-product-category" component={AddProductCategory} {userdata} />
        <ProtectedRoute path="/edit-product-category" component={EditConfiguratorProductCategory} {userdata} />

        <ProtectedRoute path="/product-mj" component={ProductMJ} {userdata} />
        <ProtectedRoute path="/add-product-mj" component={AddConfiguratorProductMJ} {userdata} />
        <ProtectedRoute path="/edit-product-mj" component={EditConfiguratorProductMJ} {userdata} />

        <ProtectedRoute path="/product-standards" component={ProductStandards} {userdata} />
        <ProtectedRoute path="/add-product-standard" component={AddConfiguratorProductStandard} {userdata} />
        <ProtectedRoute path="/edit-product-standard" component={EditConfiguratorProductStandard} {userdata} />
        
        <ProtectedRoute path="/configurator-users" component={ConfiguratorUsers} {userdata} />
        <ProtectedRoute path="/add-configurator-user" component={AddConfiguratorUser} {userdata} />
        <ProtectedRoute path="/import-configurator-users" component={ImportConfiguratorUsers} {userdata} />
        <ProtectedRoute path="/products" component={Products} {userdata} />
        <ProtectedRoute path="/add-configurator-product" component={AddConfiguratorProduct} {userdata} />
        <ProtectedRoute path="/edit-configurator-product" component={EditConfiguratorProducts} {userdata} />
        <ProtectedRoute path="/import-configurator-products" component={ImportConfiguratorProducts} {userdata} />
        <ProtectedRoute path="/flats" component={Flats} {userdata} />
        <ProtectedRoute path="/add-configurator-flat" component={AddConfiguratorFlat} {userdata} />
        <ProtectedRoute path="/import-configurator-flats" component={ImportConfiguratorFlats} {userdata} />
        <ProtectedRoute path="/configurator-sample-flats" component={ConfiguratorSampleFlats} {userdata} />
        <ProtectedRoute path="/add-configurator-sample-flat" component={AddConfiguratorSampleFlat} {userdata} />
        <ProtectedRoute path="/import-configurator-sample-flats" component={ImportConfiguratorSampleFlats} {userdata} />
        <ProtectedRoute path="/scenes-configuration" component={NewScenes} {userdata} />
        <ProtectedRoute path="/scenes-configuration-settings" component={NewScenesSettings} {userdata} />

        <ProtectedRoute path="/flat-products" component={Flat_products} {userdata} />
        <ProtectedRoute path="/flat-filter" component={Flat_filter} {userdata} />
        <ProtectedRoute path="/layers" component={Layers} {userdata} />
        <ProtectedRoute path="/configurator-import-max" component={ImportMax} {userdata} />
        <ProtectedRoute path="/rotator" component={Rotator} {userdata} />

        
        
        
        
        <!-- <Route path="/" component={Tour} {userdata} /> -->
        <Route path="/login" component={Login} {userdata}/>
        <Route path="/tour" component={Tour} {userdata}/> 
        <Route path="" component={Loader} {userdata}/> 
        
        <!-- <Route path="*" component={NotFound} {userdata} /> -->
        <!-- <Route path="*" component={Tour} {userdata} /> -->
    </Router>
</Notifications>