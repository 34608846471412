<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";
  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";

  import { product_category_id } from "../../store.js";

  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  let loading_data, flats_loaded = false;
  let flats = [];

  export let userdata;
  let products = null;

  // Načítanie dát z DB
  async function getData($id, $target, $var, $par) {
    loading_data = true;
    let data = {id : $id} 
    const res = await fetch(
      userdata['base_path'] + "assets/php/getData.php",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );

    if (res.ok) {
      const json = await res.json(); 
    
      // prevod hodnoty boolean
      if (
        typeof json[0] === 'object'
        
        
        // Object.keys(json).length === 0
        ) 
      {
        json.forEach(element => {
          const keys = Object.keys(element);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                (key == "enabled" && element[key] == "0") || (key == "bind_standard" && element[key] == "0") 
            ) {
                element[key] = false
            }

            if (
              (key == "enabled" && element[key] == "1") || (key == "bind_standard" && element[key] == "1")
            ) {
                element[key] = true
            }
            
          }  
        });
        

        flats = json;

        loading_data = false;
      }

      else {
        console.log("žiadne dáta");
      }
      
    } else {
      throw new Error(data);
    }
  }
  getData("flats", flats, "flats"); 

  async function save_product(id) {
    let category = null;

    product_categories.forEach(element => {
      if (element.id == id) {
        category = element;
      }
    });

    console.log(category);

    const res = await fetch(userdata['base_path'] + 'assets/php/updateConfiguratorProductCategory.php', {
          method: "POST",
          body: JSON.stringify(category),
        })

        if (res.ok) {
            const json = await res.json()
            

            if (json.status == true) {
              addNotification({
                  text: 'Kategória bola aktualizovaná.',
                  position: 'top-center',
                  removeAfter: 4000,
                  type: 'success'
              });

              getData("product_categories", product_categories, "product_categories"); 

            } else if (json.status == false) {
              addNotification({
                  text: 'Kategória nebola aktualizovaná!',
                  position: 'top-center',
                  removeAfter: 4000,
                  type: 'danger'
              })
            }

        } else {
          throw new Error(data);
        }
  }

  async function delete_product(id) {
    let flat = null;

    flats.forEach(element => {
      if (element.id == id) {
        flat = element;
      }
    });

    const res = await fetch(userdata['base_path'] + 'assets/php/deleteConfiguratorFlat.php', {
          method: "POST",
          body: JSON.stringify(flat),
        })

        if (res.ok) {
            const json = await res.json()
            if (json.status == true) {
              addNotification({
                  text: 'Byt bol vymazaný.',
                  position: 'top-center',
                  removeAfter: 4000,
                  type: 'success'
              });

              getData("flats", flats, "flats"); 

            } else if (json.status == false) {
              addNotification({
                  text: 'Byt nebol vymazaný!',
                  position: 'top-center',
                  removeAfter: 4000,
                  type: 'danger'
              })
            }

        } else {
          throw new Error(data);
        }
  }

  function edit_product(id) {
    product_category_id.update(n => id);
    navigate("edit-product-category");
  }

  function sort() {
    product_categories.sort((a, b) => (a.category_order > b.category_order) ? 1 : -1);
    product_categories = product_categories;
  }
</script>

<div id="wrapper">
  <Aside />

  {#if loading_data == false && flats}
  <main in:fade>
    
    <h1>Byty</h1>

    <table>
      <thead>
        <tr>
          <td>{$t("admin")["global"]["id"]}</td>
          <td>{$t("admin")["global"]["name"]}</td>
          <td>{$t("admin")["global"]["save"]}</td>
          <td>{$t("admin")["global"]["edit"]}</td>
          <td>{$t("admin")["global"]["delete"]}</td>
        </tr>
      </thead>
      <tbody>
        {#each flats as flat}
           <tr>
            <!-- Id kategórie -->
            <td><p class="name">{flat.id}</p></td>

            <!-- názov kategórie -->
            <td><p class="name">{flat.name}</p></td>

            <!-- uložiť -->
            <td on:click={() => save_product(flat.id)}><i class="fa-solid fa-floppy-disk save" />
            </td>

            <!-- upraviť -->
            <td on:click={() => edit_product(flat.id)}>
              <i class="fa-solid fa-gear edit" />
            </td>
            
            <!-- vymazať -->
            <td on:click={() => delete_product(flat.id)}><i class="fa-solid fa-trash-can delete" />
            </td>
           </tr>
        {/each}
      </tbody>
  </main>
  <Header />
  {/if}
  
</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";

</style>