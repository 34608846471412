<script context="module">
    import Device from 'svelte-device-info';
</script>

<script>
    import Analytics from '../tour_components/Analytics.svelte';
    let fakeFlats;
    export let userdata;
    let scene_mobile_suffix = "";
    let _mobile_patch_suffix = "";
    let model_suffix = "";
    let DisclaimerLoaded = false;
    let showRotatorIcon = false;


    import { onMount } from 'svelte';
    import {getCookie, verifiyFloor} from '../utils.js';
    

    if (userdata["base_path"] != "") {
        getCookie("favID");
        getCookie("topNR");

        const cookieEvent = new CustomEvent("cookieChanged", {
            bubbles: true,
            detail: {
                cookieValue: document.cookie,
                checkChange: () => {
                    if (cookieEvent.detail.cookieValue != document.cookie) {
                        cookieEvent.detail.cookieValue = document.cookie;
                        return 1;
                    } else {
                        return 0;
                    }
                },
                listenCheckChange: () => {
                    setInterval(function () {
                        if (cookieEvent.detail.checkChange() == 1) {
                        cookieEvent.detail.changed = true;
                        //fire the event
                        cookieEvent.target.dispatchEvent(cookieEvent);
                        } else {
                        cookieEvent.detail.changed = false;
                        }
                    }, 1000);
                },
                changed: false
            }
        });

        document.addEventListener("DOMContentLoaded", function (e) {
            e.target.dispatchEvent(cookieEvent);
        });

        document.addEventListener("cookieChanged", function (e) {
            e.detail.listenCheckChange();
            if(e.detail.changed === true ){
                ////////////////////////////console.log(e.detail);
                getCookie("favID");
                getCookie("topNR");
            }
        });
    }
	import { t, locale, locales } from "../i18n.js";
    const { addNotification } = getNotificationsContext();
    import { getNotificationsContext } from "svelte-notifications";
    import { fade, fly, slide, scale } from "svelte/transition";
    import { quintOut } from "svelte/easing";
    import {get, writable} from 'svelte/store';
    // import globálnych premenných
    import {active_product_id, playground_view, vr_mode} from '../store.js';
    import {all_scenes, all_hotspots, all_products, config_products, basket_categories, config_product_categories, config_product_variants, config_product_measures_units, nazov_bytu, dispositions_enabled, global_project_settings, check_ath, permanently_hotspots, disabled_icons} from '../store.js';
    import {active_scene, import_config_hotspots, reimport_hotspots, hotspots, cookies_compare_flats, blur } from '../store.js';
    import {dizajn, measures, modal, modal_type, otacadlo_compare_flats, enable_krpano_import, flat_3D, active_point, otacadlo_logika } from '../store.js';
    import {enable_price_filter, enable_area_info, enable_compare_flats, enable_dispositions_filter, 
        enable_enter_vr_function, enable_floor_filter, enable_playground, enable_status_filter, pdf_flat_compare_export, 
        show_floorplan_thumbnail, svg_h, svg_w, enable_flat_filter, is_woow_demo, should_deactivate_otacadlo_graphic, AnimationTimes } from '../store.js';
    import {mobile_patch_suffix, show_tutorial, enabled_flat_detail, attributes,new_model, building_view } from '../store.js';
    import {configuration, reset_configurator, video_time, flat_filter_enabled, infopanel, videopanel, infopanelData, videopanelData, 
        basket_enabled, tour_mode, vr_product_enabled, fl_settings, fl_pins, shortcuts_data, tutorial, hide_all_hotspots_not_patches, 
        enable_otacadlo, enable_floorplan_nav, otacadlo_img, changed_flat, changedFloor, poisScene, load_view } from '../store.js';
        
    let _AnimationTimes = {};
    let _otacadlo_logika;
    otacadlo_logika.subscribe(value => {
        _otacadlo_logika = value;
    })
    AnimationTimes.subscribe(value => {
        _AnimationTimes = value;
    })
    
    let isMobile = null;

    let holobytNames = ["holo"];

    
    switch (true) {

        case Device.isPhone:  //////////////////////////////////////////////////////////////////////console.log('this device is a smartphone'); break
        case Device.isTablet: //////////////////////////////////////////////////////////////////////console.log('this device is a tablet');     break
            isMobile = true;

            switch (userdata["assets_folder"]) {
                case "woowstudio/bratislava/" :
                    scene_mobile_suffix = "_m";
                    mobile_patch_suffix.update( n =>  "_m");
                break;
            }
            
            //scene_mobile_suffix = "";
            
        break;
        default:              //////////////////////////////////////////////////////////////////////console.log('this device is neither a smartphone nor a tablet')
    }

    let components = [];
    let old_components = [];

    let dizajn_hotspotu, scale_hotspotu, rotator, tour_settings, all_languages, _dispositions_enabled;
    let should_enable_disposition, buildings_loaded = false;
    let _poisScene = null;
    
    // help premenné
    let krpano, current_scene, hotspots_data, _vr_mode, krpano_loaded, _enable_krpano_import, _flat_3D, _dizajn, modal_type_tmp, show_modal, _current_scene;
    let enable_sidebar, enable_bookcall, enable_sound, enable_vr, _enable_otacadlo, enable_tutorial, 
        _permanently_hotspots, _basket_enabled, _tutorial, _measures, _import_config_hotspots, loaded_3d_model, enabled_new_transition = false;
    let _otacadlo_compare_flats = [];
    let _hotspots, loader = true;
    let _video_time = 0;
    let _changed_flat, _changedFloor = null;

    let show_loader = true;

    // ID bytu z URL adresy
    let current_user, sample_flat_name, enable_smooth_transition, sample_flat_id, mode, Modal, Blur, Tutorial, FlatDispositions, Otacadlo, Otacadlo_img, fix_scene = null;
    let model_enabled = false;
    let _load_view = "KEEPVIEW";

    // nastavenia projektu
    let project_settings, sample_flat, sample_flat_scenes, sample_flat_hotspots, products, product_variants, product_categories, product_standards, product_mj, price_list, all_patches = [];
    // Všetky komponenty
    let Shortcuts,Logo,Google_map,Social,Floorplan,Tour_info,Screenshot,Settings,Layers,Vr,Contact_form,model_3d,Flat_filter,Attributes,Basket,Pdf,Languages,Login,Sidebar, Model, FlatDetail = null;
    // nastavenia pre komponenty
    let shortcuts_settings,logo_settings,layers_settings,social_settings,contact_form_settings, google_map_settings, floorplan_settings, 
        floorplan_pins,languages_settings,tour_info_settings,settings_settings, model_3d_settings, _configuration,
        attributes_settings, basket_settings, pdf_settings, vr_settings, _reset = null;

    // základné premenné pre Krpano
    let init_variables = {allow_saving : false, generating_screenshot : false, screenshot_data : null, permanenty_hotspots : false, floors : "", dizajn : ""};
    
    let selected_start_scene = null;

    $: {
        _mobile_patch_suffix;
        // //////////////////console.log(_mobile_patch_suffix);
    }

    $: {
        init_variables;
        ////////////console.log(init_variables);
    }

    $: {
        selected_start_scene;
        //////////////////////console.log("načítavam scénu : " + selected_start_scene);
    }

    load_view

    load_view.subscribe(value => {        
        _load_view = value;
        //console.log(value);
    });

    poisScene.subscribe(value => {        
        _poisScene = value;
        if (value != null) {
            selected_start_scene = value;
        }
        ////////////////////////////console.log(value);
    });
    

    let url = String(window.location);
    // overenie názvu projektu z URL
    let url_parameters = new URLSearchParams(window.location.search);
    let loader_text = $t("components")['preloader']['loading_components'];

    // update globálných premenných
    ////console.log(url_parameters);
    
    changedFloor.subscribe(value => {
        _changedFloor = value;
        ////console.log(value);
    });

    if (url_parameters.has("floor")) {
        ////console.log(url_parameters.get("floor"));
        changedFloor.update ( n=> url_parameters.get("floor"));
    }

    changed_flat.subscribe(value => {
        ////////console.log(value);
        DisclaimerLoaded = false;
        _changed_flat = value;
        if (value != null) {
            remove_all(value);
        } 
    });
    
    blur.subscribe(value => {
        //////////////////////////////////////console.log("BLUR : " + value);
    });

    dispositions_enabled.subscribe(value => {
        _dispositions_enabled = value;
    });

    hotspots.subscribe(value => {
        _hotspots = value;
        ////////////////////console.log(value);
    });
    enable_krpano_import.subscribe(value => {
        _enable_krpano_import = value;
    });
    flat_3D.subscribe(value => {
        _flat_3D = value;
        //////////////console.log("3D model");
    });
    measures.subscribe(value => {
        _measures = value;
    });
    enable_otacadlo.subscribe(value => {
        _enable_otacadlo = value;
        //////////////////////////////////console.log(value);
    });

    let showDisclaimer = false;
    let disclaimerText = "";

    
    function loadDisclaimer(text, time) {
        ////////////////console.log("pridávam disclaimer");
        disclaimerText = text;
        showDisclaimer = true;
        DisclaimerLoaded = true
        setTimeout(() => {
            showDisclaimer = false;
        }, time);
    }

    function hideTags(hotspoty) {
        let hotspotNames = ["", "_tag_close", "_tag_title", "_tag_img", "_tag_mask", "_tag_price", "_tag_content", "_tag_link"];
        if (hotspoty != undefined) {
            hotspoty.forEach(hotspot => {
                if (hotspot.type == "tag") {
                    for (let index = 0; index < hotspotNames.length; index++) {
                        const name = hotspotNames[index];
                        krpano.set("hotspot[hts_" + hotspot.id + name + "].alpha", 0);
                    }
                }   
            });
        }
        
    }

    
    permanently_hotspots.subscribe(value => {
        _permanently_hotspots = value;
        if (krpano_loaded) {
            import_hotspots(sample_flat_hotspots, sample_flat_scenes, project_settings, products, _import_config_hotspots, products, sample_flat_name, _vr_mode, enable_smooth_transition, _hotspots);
        }

        
    });
    tutorial.subscribe(value => {
        _tutorial = value;
    });
    basket_enabled.subscribe(value => {
        _basket_enabled = value;
        //////////////////////////////////////////////////////console.log("basket = " + value);
    });
    video_time.subscribe(value => {
        _video_time = value;
    });
    dizajn.subscribe(value => {
        _dizajn = value;
        if (holobytNames.includes(value)) {
            hideTags(sample_flat_hotspots);
        }
    });
    new_model.subscribe(value => {
        model_enabled = value;    
    });
    modal_type.subscribe(value => {
        modal_type_tmp = value;    
    });
    mobile_patch_suffix.subscribe(value => {
        _mobile_patch_suffix = value;
    });
    modal.subscribe(value => {
        show_modal = value;
    });
    active_scene.subscribe(value => {
        _current_scene = value;
        //////////////////////////////////////console.log(value);
        
        function add_video(_video_time) {
            krpano.call("addhotspot(video);");
            krpano.set("hotspot[video].url", userdata['cdn_path'] + "krpano/plugins/videoplayer.js");
            krpano.set("hotspot[video].videourl",  userdata['cdn_path'] + "assets/videos/comprimovane.mp4");
            krpano.set("hotspot[video].enabled", "false");
            krpano.set("hotspot[video].height", "prop");
            krpano.set("hotspot[video].pausedonstart", false);
            krpano.set("hotspot[video].visible", true);
            //krpano.set("hotspot[video].posterurl", "scenes/synagoga_12_u.jpg");
            krpano.set("hotspot[video].distorted", true);
            krpano.set("hotspot[video].scale", 1.0);
            krpano.set("hotspot[video].alpha", "0.8");
            krpano.set("hotspot[video].rz", "0.0");
            krpano.set("hotspot[video].ox", "0");
            krpano.set("hotspot[video].oy", "10");
            krpano.set("hotspot[video].loop", "true");
            krpano.set("hotspot[video].volume", "1.0");
            krpano.set("hotspot[video].directionalsound", true);
            krpano.set("hotspot[video].range", "90");
            krpano.set("hotspot[video].rangefalloff", "1.0");
            krpano.set("hotspot[video].outofrangevolume", "0.0");
            krpano.set("hotspot[video].zorder", 12);
            krpano.set("hotspot[video].edge", "lefttop");

            
            krpano.set("hotspot[video].onvideoready", function(hs) {
                //////////////////////////////////////////////////////////console.log("video1 : " + _video_time);    
                if (_video_time > 0) {
                    
                    krpano.call("hotspot[video].seek(" +  _video_time + ")");
                } else {
                    krpano.call("hotspot[video].seek(0)");
                }
            });
            //krpano.call("hotspot[video].seek", 12);
            
            
        }

        switch (value) {
            case "B3G_04" : 
                krpano.call("addhotspot(video2);");
                krpano.set("hotspot[video2].url", userdata['cdn_path'] + "krpano/plugins/videoplayer.js");
                krpano.set("hotspot[video2].videourl",  userdata['cdn_path'] + "assets/videos/Stena_Animacia_pan_105_tilt_0_F_65_A_1.5_1632x1088_REVERSE.mp4");
                krpano.set("hotspot[video2].enabled", "false");
                krpano.set("hotspot[video2].height", "prop");
                krpano.set("hotspot[video2].pausedonstart", true);
                krpano.set("hotspot[video2].visible", true);
                krpano.set("hotspot[video2].distorted", true);
                krpano.set("hotspot[video2].scale", 0.65);
                krpano.set("hotspot[video2].rx", "0.0");
                krpano.set("hotspot[video2].ry", "0.0");
                krpano.set("hotspot[video2].rz", "0.0");
                krpano.set("hotspot[video2].ox", "0");
                krpano.set("hotspot[video2].oy", "0");
                krpano.set("hotspot[video2].loop", "false");
                krpano.set("hotspot[video2].volume", "1.0");
                krpano.set("hotspot[video2].directionalsound", true);
                krpano.set("hotspot[video2].range", "90");
                krpano.set("hotspot[video2].rangefalloff", "1.0");
                krpano.set("hotspot[video2].outofrangevolume", "0.0");
                krpano.set("hotspot[video2].zorder", 12);
                krpano.set("hotspot[video2].ath", -105);
                krpano.set("hotspot[video2].atv", 0);
                krpano.set("hotspot[video2].playbackrate", -1);
                krpano.set("hotspot[video2].width", "1000");
            break;

            case "B3G_03" : 
                krpano.call("addhotspot(video3);");
                krpano.set("hotspot[video3].url", userdata['cdn_path'] + "krpano/plugins/videoplayer.js");
                krpano.set("hotspot[video3].videourl",  userdata['cdn_path'] + "assets/videos/adela_-90_-15_F40_928x1856.mp4");
                krpano.set("hotspot[video3].enabled", "false");
                krpano.set("hotspot[video3].height", "prop");
                krpano.set("hotspot[video3].pausedonstart", false);
                krpano.set("hotspot[video3].visible", true);
                krpano.set("hotspot[video3].distorted", true);
                krpano.set("hotspot[video3].scale", 0.40);
                krpano.set("hotspot[video3].rx", "0.0");
                krpano.set("hotspot[video3].ry", "0.0");
                krpano.set("hotspot[video3].rz", "0.0");
                krpano.set("hotspot[video3].ox", "0");
                krpano.set("hotspot[video3].oy", "7");
                krpano.set("hotspot[video3].loop", "true");
                krpano.set("hotspot[video3].volume", "1.0");
                krpano.set("hotspot[video3].directionalsound", true);
                krpano.set("hotspot[video3].range", "90");
                krpano.set("hotspot[video3].rangefalloff", "1.0");
                krpano.set("hotspot[video3].outofrangevolume", "0.0");
                krpano.set("hotspot[video3].zorder", 12);
                krpano.set("hotspot[video3].ath", 90);
                krpano.set("hotspot[video3].atv", 15);
                krpano.set("hotspot[video3].playbackrate", -1);
                krpano.set("hotspot[video3].width", "928px");
                krpano.set("hotspot[video3].zorder", 999);
            break;


            case "B3G_05":
                add_video(_video_time);
                krpano.set("hotspot[video].ath", 58);
                krpano.set("hotspot[video].atv", 3);
                krpano.set("hotspot[video].rx", "2.8");
                krpano.set("hotspot[video].ry", "32");
                krpano.set("hotspot[video].width", "865px");
                break;
            case "B3G_06":
                add_video(_video_time);
                krpano.set("hotspot[video].ath", "32.3");
                krpano.set("hotspot[video].atv", "0.3");
                krpano.set("hotspot[video].rx", "0.4");
                krpano.set("hotspot[video].ry", "57.5");
                krpano.set("hotspot[video].width", "303px");
                break;
        
            default:
                break;
        }
    });
    reset_configurator.subscribe(value => {
        if (value == true) {
            _reset = "reset";
            getDataFromDB();
        }
    });
    import_config_hotspots.subscribe((value) => {
	    _import_config_hotspots = value;
	});

   
    let loaded = 0;

    let loader_function;
    let loadingInterval;

    //////////////////////////////////////////////////////////console.log(url_parameters);

    // PHP akcia na načítanie údajov
    async function getDataFromDB(tour_id) {
        
        //krpano = null;
        krpano_loaded = false;
        //////////////////////////////////////console.log(tour_id);
        // funkcia na vytiahnutie parametrov z URL
        function getAllParams(url) {
  
            // Create an empty object
            let obj = {};

            // Extract the query params
            let paramsArray = url.match(/([^?=&]+)(=([^&]*))/g)

            // Check if there is one or more params
            if (paramsArray && tour_id == undefined) {

                // Iterate the params array
                paramsArray.forEach((query) => {

                    // Split the array
                    let strings = query.split("=")

                    // Assign the values to the object
                    obj[strings[0]] = strings[1];
                    let tm = null;
                    // if (obj.scene == undefined ? tm = null : selected_start_scene = obj.scene);
                    ////////////////////////console.log(selected_start_scene);    
                })
            } else {
                //obj.project = "B8A";
                if (tour_id == undefined ? obj.project = "first" : obj.project = tour_id);
                
                
            }

            if (obj.filter != null || obj.filter != undefined) {
                //////////////////////////////////////////////////////////console.log(obj.filter);
                if (obj.filter == "false") {

                    setTimeout(() => {
                        //flat_filter_enabled.update(n => false);    
                    }, 2000);
                    
                } else {
                    //flat_filter_enabled.update(n => true);
                    building_view.update(n => true);

                    
                }
            } else {
                
            }

            // if (obj.scene != null || obj.scene != undefined) {
               
            //     fix_scene = obj.scene;
            //     //////////////////////////////////////////////////////////console.log(fix_scene);
                
            // }

            if (obj.mode) {
                mode = obj.mode;
                tour_mode.update(n => mode);
            } else {
                if (_import_config_hotspots) {
                    mode = "config"
                } else {
                    mode = "client"
                }
                
            }


           

            // ////////////////////////////////////////////////////console.log(obj);
            // ////////////////////////////////////////////////////console.log(mode);

            return obj;
        }
            
        // zistenie celej URL adresy
        let query = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + window.location.search;

        // vytvorenie objektu z parametrov + ich hodnoty
        let parameters = getAllParams(query);
        url_parameters = new URLSearchParams(window.location.search);

        
        //////////////////////////////////////////////////////console.log(parameters);

        const username = 'Woowstudio'; // Nahraďte týmto vaše meno
        const password = 'Woowjetop1'; // Nahraďte týmto vaše heslo

        let headers_data = {};
        const basicAuthHeader = 'Basic ' + btoa(username + ':' + password);

        if (userdata["base_path"] == "https://mollyinvr.com/projects/appka/") {
            headers_data = {
                'Authorization': basicAuthHeader
            }
        }

        parameters.base_path = userdata["base_path"];
        parameters.cdn_path = userdata["cdn_path"];
        parameters.start_project = userdata["start_project"];
        parameters.assets_folder = userdata["assets_folder"];

        //////////////////////////console.log(parameters);

        ////////////////////////////////console.log(basicAuthHeader);

        try {
            const res = await self.fetch(
                userdata['cdn_path'] + "php/loadData.php",{
                    headers: headers_data,
                    method: "POST",
                    body: JSON.stringify(parameters, _reset),
                }
            );

            // ak sa vrátili dáta
            if (res.ok) {
                // const text = await res.text(); 
                // ////////////////////////////////////////////////////////console.log(text);
                const json = await res.json(); 
                //console.log(json);

                if (userdata["base_path"] == "https://mollyinvr.com/projects/penta/waltrovka/" && json.sample_flat_name == "OFFICE" && isMobile) {
                    scene_mobile_suffix = "_m";
                    
                }

                if (json.sample_flat_name == "OFFICE" && userdata["assets_folder"] == "penta/waltrovka/") {
                    showRotatorIcon = true;
                }
                ////////////////////////////////console.log(scene_mobile_suffix);
                ////////////////////////////console.log(json);
                fakeFlats = json.all_projects_tours;
                current_user = json.current_user;
                project_settings = json.project_settings;

                global_project_settings.update(n => json.project_settings);
                components = json["components"];

                if (old_components.length > 0) {
                    old_components.forEach(element => {
                        components = [...components, element];    
                    });
                    
                }

                components = [...new Set(components)];
                
                ////////////////////////////////console.log(components);
                all_patches = json.all_variants_path;
                ////////////////////////////console.log(json.all_variants_path);
                
                sample_flat = json.sample_flat;
                json.sample_flats_all_data.forEach(element => {
                    if (element.name == json.sample_flat_name) {
                        if (element.load_view != null && element.load_view != undefined) {
                            load_view.update( n => element.load_view.toUpperCase());
                        }
                    }
                });
                    
                


                if (sample_flat.default_lang != null && sample_flat.default_lang != undefined) {
                    if (all_languages == null) {
                        all_languages = sample_flat.default_lang.split(",");
                        $locale = all_languages[0];
                    }
                    
                }
                sample_flat_name = json.sample_flat_name;
                nazov_bytu.update(n => json.sample_flat_name);
                sample_flat_id= json.sample_flat_id;
                enable_smooth_transition = json.enable_smooth_transition;
                //////////////////////////////////////console.log(enable_smooth_transition);
                // ////////////////////////////////////////////////////////console.log(sample_flat_id);
                // ////////////////////////////////////////////////////////console.log(json.config_flat_id);
                sample_flat_scenes = json.sample_flat_scenes;
                all_scenes.update(n => json.sample_flat_scenes);
                sample_flat_hotspots = json.sample_flat_hotspots;
                all_hotspots.update(n => json.sample_flat_hotspots);
                _configuration = json.configuration;
                measures.update(n => json.allow_measures);
                //////////////////////////////////////console.log(json.tour_settings);
                tour_settings = json.tour_settings;

                if (json.tour_settings != null && json.tour_settings.body_class_name != null && json.tour_settings.body_class_name != undefined && json.tour_settings.body_class_name != "") {    
                    var d = document.getElementById("woowstudio");
                    d.classList.add(json.tour_settings.body_class_name);
                    //document.body.classList.add(json.tour_settings.body_class_name);
                }

                if (json.tour_settings != null && json.tour_settings.enable_floorspots != null && json.tour_settings.enable_floorspots != undefined && json.tour_settings.enable_floorspots != "") {    
                    permanently_hotspots.update(n => true);
                    //////////////////////////////////////console.log(json.tour_settings.enable_floorspots);
                } else {
                    permanently_hotspots.update(n => false);
                }

                if (json.tour_settings != null && json.tour_settings.enable_new_transition != null && json.tour_settings.enable_new_transition != undefined && json.tour_settings.enable_new_transition != "") {    
                    enabled_new_transition = true;
                }

                if (json.tour_settings != null && json.tour_settings.dizajn_hotspotu != null && json.tour_settings.dizajn_hotspotu != undefined && json.tour_settings.dizajn_hotspotu != "") {    
                    dizajn_hotspotu = json.tour_settings.dizajn_hotspotu;
                } else {
                    dizajn_hotspotu = "hts-ring-w.svg";
                }

                if (json.tour_settings != null && json.tour_settings.scale_hotspotu != null && json.tour_settings.scale_hotspotu != undefined && json.tour_settings.scale_hotspotu != "") {    
                    scale_hotspotu = json.tour_settings.scale_hotspotu;
                    //////////////////////////////////////console.log(scale_hotspotu);
                } else {
                    scale_hotspotu = "0.008";
                }

                // import (spojenie) premenných do krpana (dizajn, floor, produkty)
                init_variables = {...init_variables, ...json.init_variables};
                if (_changedFloor != null) {
                    init_variables.floors = verifiyFloor(_changedFloor);    

                }

                
                //////////////////////////////////////////////////////console.log(init_variables);
                
                // načítanie krpana 
                

                // pridanie komponentov
                //////////////////////////////////////////////console.log(components);

                dispositions_enabled.update( n => false);  
                tutorial.update(n => false);   
                
                
                if (json.flat_filter != null) {
                                if (json.flat_filter.enable_price_filter != null || json.flat_filter.enable_price_filter != undefined) {
                                enable_price_filter.update(n => json.flat_filter.enable_price_filter);
                            }

                            if (json.flat_filter.enable_status_filter != null || json.flat_filter.enable_status_filter != undefined) {
                                enable_status_filter.update(n => json.flat_filter.enable_status_filter);
                            }

                            if (json.flat_filter.enable_dispositions_filter != null || json.flat_filter.enable_dispositions_filter != undefined) {
                                enable_dispositions_filter.update(n => json.flat_filter.enable_dispositions_filter);
                            }

                            if (json.flat_filter.enable_floor_filter != null || json.flat_filter.enable_floor_filter != undefined) {
                                enable_floor_filter.update(n => json.flat_filter.enable_floor_filter);
                            }

                            if (json.flat_filter.enable_compare_flats != null || json.flat_filter.enable_compare_flats != undefined) {
                                enable_compare_flats.update(n => json.flat_filter.enable_compare_flats);
                            }

                            if (json.flat_filter.show_floorplan_thumbnail != null || json.flat_filter.show_floorplan_thumbnail != undefined) {
                                show_floorplan_thumbnail.update(n => json.flat_filter.show_floorplan_thumbnail);
                            }
                            
                            if (json.flat_filter.pdf_flat_compare_export != null || json.flat_filter.pdf_flat_compare_export != undefined) {
                                pdf_flat_compare_export.update(n => json.flat_filter.pdf_flat_compare_export);
                            }

                            if (json.flat_filter.enable_enter_vr_function != null || json.flat_filter.enable_enter_vr_function != undefined) {
                                enable_enter_vr_function.update(n => json.flat_filter.enable_enter_vr_function);
                            }

                            if (json.flat_filter.enable_krpano_import != null || json.flat_filter.enable_krpano_import != undefined) {
                                enable_krpano_import.update(n => json.flat_filter.enable_krpano_import);
                            }

                            if (json.flat_filter.enable_playground != null || json.flat_filter.enable_playground != undefined) {
                                enable_playground.update(n => json.flat_filter.enable_playground);
                            }

                            if (json.flat_filter.enable_area_info != null || json.flat_filter.enable_area_info != undefined) {
                                enable_area_info.update(n => json.flat_filter.enable_area_info);
                            }

                            if (json.flat_filter.svg_w != null || json.flat_filter.svg_w != undefined) {
                                svg_w.update(n => json.flat_filter.svg_w);
                            }

                            if (json.flat_filter.svg_h != null || json.flat_filter.svg_h != undefined) {
                                svg_h.update(n => json.flat_filter.svg_h);
                            }

                            if (json.flat_filter.enable_flat_filter != null || json.flat_filter.enable_flat_filter != undefined) {
                                enable_flat_filter.update(n => json.flat_filter.enable_flat_filter);
                            }

                            if (json.flat_filter.enable_floorplan_nav != null || json.flat_filter.enable_floorplan_nav != undefined) {
                                enable_floorplan_nav.update(n => json.flat_filter.enable_floorplan_nav);
                            }

                            if (json.flat_filter.enable_demo_flats != null || json.flat_filter.enable_demo_flats != undefined) {
                                is_woow_demo.update(n => json.flat_filter.enable_demo_flats);
                            }

                            if (json.flat_filter.enable_flat_detail != null || json.flat_filter.enable_flat_detail != undefined) {
                                enabled_flat_detail.update(n => json.flat_filter.enable_flat_detail);
                                if (json.flat_filter.enable_flat_detail) {
                                    add_components("flat_detail");
                                    
                                }
                            }
                            }


                components.forEach(element => {
                    // pridanie hodnôt pre jednotlivé komponenty
                    
                    switch (element.replace("component_","")) {
                        case "shortcuts":
                            shortcuts_settings = json.shortcuts_settings;
                            shortcuts_data.update(n => json.shortcuts_settings);
                        break;

                        case "logo":
                            logo_settings = json.logo_settings;
                        break;

                        case "layers":
                            layers_settings = json.layers_settings;
                            ////////////////////////////////////////////////////console.log(json.layers_settings);
                        break;   
                        
                        case "social":
                            social_settings = json.social_settings;
                            break;  
                            
                        case "contact_form":
                            contact_form_settings = {
                                enabled: true
                            };
                        break;  

                        case "google_map":
                            google_map_settings = json.google_map_settings;
                        break;  
                        
                        case "floorplan":
                            floorplan_settings = json.floorplan_settings;
                            fl_settings.update(n => json.floorplan_settings)
                            floorplan_pins = json.floorplan_pins;
                            fl_pins.update(n => json.floorplan_pins);
                        break;  

                        case "languages":
                            languages_settings = {
                                enabled: true
                            };
                        break;  

                        case "tour_info":
                            tour_info_settings = json.tour_info_settings;
                        break;  

                        case "settings":
                            settings_settings = json.settings_settings;
                        break; 
                        
                        case "3d_model":
                            model_3d_settings = json.model_3d_settings;
                        break; 

                        case "attributes": 
                            if (parameters.mode == "public" ) {
                                import_config_hotspots.update(n => false);                     
                            } else {
                                import_config_hotspots.update(n => true);                     
                            }
                            
                            products = json.products;
                            ////////////////////////////console.log(products);
                            all_products.update(n => json.products);
                            product_variants = json.product_variants;
                            product_categories = json.product_categories;
                            product_mj = json.product_mj;
                            
                            product_standards = json.product_standards;
                            price_list = json.price_list;
                            configuration.update(n => json.configuration);
                            config_products.update(n => json.config_products);
                            basket_categories.update(n =>json.basket_categories);
                            config_product_categories.update(n =>json.basket_categories);
                            config_product_variants.update(n =>json.product_variants);
                            config_product_measures_units.update(n => json.product_mj);
                            attributes_settings = json.attributes_settings;
                            basket_settings = json.basket_settings;
                        break; 

                        case "basket":
                            basket_settings = json.basket_settings;
                            //basket_enabled.update(n => true);
                        break; 

                        case "pdf" : 
                            pdf_settings = {
                                enabled: true
                            }
                        break;
                        
                        case "vr" : 
                            enable_vr = true;
                        break;

                        case "sidebar" : 
                            enable_sidebar = true;
                        break;

                        case "bookcall" : 
                            enable_bookcall = true;
                        break;

                        case "sound" : 
                            enable_sound = true;
                        break;

                        case "otacadlo" : 
                            enable_otacadlo.update(n => true);
                            if (_enable_otacadlo == null && _changed_flat == null) {
                            }
                            if ((
                                _changed_flat != "ext" && _changed_flat != "EXT" && _changed_flat != "CASTLE" && _changed_flat != "CENTRUM" && _changed_flat != "WOOWSTUDIO" && _changed_flat != "LOBBY"
                                ) && _changed_flat != null) {
                                enable_otacadlo.update(n => false);
                            }
                        break;

                        case "otacadlo_img" : 
                            //otacadlo_img.update(n => true);
                        break;

                        case "measures" : 
                        //////////////////////////////////////////////console.log(json.allow_measures);
                            measures.update(n => true);
                        break;

                        case "flat_filter" :
                            //////////////////////////////////////console.log(json.flat_filter);
                            if (json.flat_filter != null) {
                                if (json.flat_filter.enable_price_filter != null || json.flat_filter.enable_price_filter != undefined) {
                                enable_price_filter.update(n => json.flat_filter.enable_price_filter);
                            }

                            if (json.flat_filter.enable_status_filter != null || json.flat_filter.enable_status_filter != undefined) {
                                enable_status_filter.update(n => json.flat_filter.enable_status_filter);
                            }

                            if (json.flat_filter.enable_dispositions_filter != null || json.flat_filter.enable_dispositions_filter != undefined) {
                                enable_dispositions_filter.update(n => json.flat_filter.enable_dispositions_filter);
                            }

                            if (json.flat_filter.enable_floor_filter != null || json.flat_filter.enable_floor_filter != undefined) {
                                enable_floor_filter.update(n => json.flat_filter.enable_floor_filter);
                            }

                            if (json.flat_filter.enable_compare_flats != null || json.flat_filter.enable_compare_flats != undefined) {
                                enable_compare_flats.update(n => json.flat_filter.enable_compare_flats);
                            }

                            if (json.flat_filter.show_floorplan_thumbnail != null || json.flat_filter.show_floorplan_thumbnail != undefined) {
                                show_floorplan_thumbnail.update(n => json.flat_filter.show_floorplan_thumbnail);
                            }
                            
                            if (json.flat_filter.pdf_flat_compare_export != null || json.flat_filter.pdf_flat_compare_export != undefined) {
                                pdf_flat_compare_export.update(n => json.flat_filter.pdf_flat_compare_export);
                            }

                            if (json.flat_filter.enable_enter_vr_function != null || json.flat_filter.enable_enter_vr_function != undefined) {
                                enable_enter_vr_function.update(n => json.flat_filter.enable_enter_vr_function);
                            }

                            if (json.flat_filter.enable_krpano_import != null || json.flat_filter.enable_krpano_import != undefined) {
                                enable_krpano_import.update(n => json.flat_filter.enable_krpano_import);
                            }

                            if (json.flat_filter.enable_playground != null || json.flat_filter.enable_playground != undefined) {
                                enable_playground.update(n => json.flat_filter.enable_playground);
                            }

                            if (json.flat_filter.enable_area_info != null || json.flat_filter.enable_area_info != undefined) {
                                enable_area_info.update(n => json.flat_filter.enable_area_info);
                            }

                            if (json.flat_filter.svg_w != null || json.flat_filter.svg_w != undefined) {
                                svg_w.update(n => json.flat_filter.svg_w);
                            }

                            if (json.flat_filter.svg_h != null || json.flat_filter.svg_h != undefined) {
                                svg_h.update(n => json.flat_filter.svg_h);
                            }

                            if (json.flat_filter.enable_flat_filter != null || json.flat_filter.enable_flat_filter != undefined) {
                                enable_flat_filter.update(n => json.flat_filter.enable_flat_filter);
                            }

                            if (json.flat_filter.enable_floorplan_nav != null || json.flat_filter.enable_floorplan_nav != undefined) {
                                enable_floorplan_nav.update(n => json.flat_filter.enable_floorplan_nav);
                            }

                            if (json.flat_filter.enable_demo_flats != null || json.flat_filter.enable_demo_flats != undefined) {
                                is_woow_demo.update(n => json.flat_filter.enable_demo_flats);
                            }

                            if (json.flat_filter.enable_flat_detail != null || json.flat_filter.enable_flat_detail != undefined) {
                                enabled_flat_detail.update(n => json.flat_filter.enable_flat_detail);
                                if (json.flat_filter.enable_flat_detail) {
                                    add_components("flat_detail");
                                    
                                }
                            }
                            }
                            

                            
                        break;

                        
                        
                        case "dispositions" : 
                            if (_changed_flat == null) {
                                should_enable_disposition = true;
                            } else {
                                should_enable_disposition = false;
                            }
                            ////////////////////////////////////console.log(_dispositions_enabled + " : " + json.enable_otacadlo + " : " + _enable_otacadlo );
                            if ((_dispositions_enabled == null && _changed_flat == null) )  {
                                dispositions_enabled.update( n => true);    
                            } else {
                                dispositions_enabled.update( n => false);    
                            }
                        break;

                        default:
                            break;
                    }
                    if (rotator == null) {
                        rotator = json.rotator;
                    } else {
                        //enable_flat_filter.update(n => true);
                        //enable_otacadlo.update(n => true);
                    }

                    

                    
                    
                    add_components(element.replace("component_",""));
                });
                    
                if (url_parameters.has('filter') && url_parameters.get('filter')) {
                    add_components("flat_filter");
                    add_components("otacadlo");
                    enable_playground.update(n => true);
                    enable_flat_filter.update(n => true);
                    enable_enter_vr_function.update(n => true);
                    enable_status_filter.update(n =>  true);
                    enable_price_filter.update(n =>  true);
                    //enable_otacadlo.update(n => true);
                }

                if (url_parameters.has('r')) {
                    if (url_parameters.get('r') == "0") {
                        ////////////////////////////////console.log("vypni grafiku otacadla");
                        should_deactivate_otacadlo_graphic.update(n => true);    
                    } else {
                        ////////////////////////////////console.log("zapni grafiku otacadla");
                        //should_deactivate_otacadlo_graphic.update(n => false);
                    }
                } 

                if (url_parameters.has('floor')) {
                    init_variables.floors = url_parameters.get('floor');
                    //changed_floor.update( n =>  url_parameters.get('floor'));
                }

                if (url_parameters.has('design')) {
                    
                    init_variables.dizajn = url_parameters.get('design');
                    dizajn.update(n => init_variables.dizajn);
                }

                

                if (url_parameters.has('scene')) {
                    selected_start_scene = url_parameters.get('scene');
                }


                if (!krpano_loaded) {
                    
                    load_krpano(project_settings, sample_flat, products, krpano, loadingInterval);
                } else {
                    
                    //import_krpano_scenes(sample_flat, sample_flat_scenes, project_settings, enable_smooth_transition);
                }
                add_components("sidebar");

            } else {
                ////////////////////////////////////////////////////////console.log("nemám dáta pre tabuľku " + $table_name);
            }   
        } catch (error) {
        //////////////////////////////console.error('Chyba pri sťahovaní dát:', error);
        }
  	}

    

    function addVideoHotspot(name, scene, videourl, scale, ox, oy, loop, ath, atv, rx, ry, width, pausedonstart, allowTogglePause, enableCursor) {
        krpano.call("removehotspot(" + name );

        if (_AnimationTimes[name] == "undefined" || _AnimationTimes[name] == undefined) {
            _AnimationTimes[name] = {};
            _AnimationTimes[name].title = name;
            _AnimationTimes[name].scene = scene;
            _AnimationTimes[name].time = 0;
            AnimationTimes.update ( n=> _AnimationTimes);
        }
        
        
        krpano.call("addhotspot(" + name + ");");
        krpano.set("hotspot[" + name + "].alpha", 0);
        krpano.set("hotspot[" + name + "].url", userdata['cdn_path'] + "krpano/plugins/videoplayer.js");
        krpano.set("hotspot[" + name + "].videourl", videourl);
        krpano.set("hotspot[" + name + "].enabled", enableCursor);
        krpano.set("hotspot[" + name + "].height", "prop");
        krpano.set("hotspot[" + name + "].pausedonstart", pausedonstart);
        krpano.set("hotspot[" + name + "].visible", true);
        krpano.set("hotspot[" + name + "].distorted", true);
        krpano.set("hotspot[" + name + "].scale", scale);
        krpano.set("hotspot[" + name + "].ox", ox);
        krpano.set("hotspot[" + name + "].oy", oy);
        krpano.set("hotspot[" + name + "].rx", rx);
        krpano.set("hotspot[" + name + "].ry", ry);
        krpano.set("hotspot[" + name + "].loop", loop);
        krpano.set("hotspot[" + name + "].volume", "1.0");
        krpano.set("hotspot[" + name + "].directionalsound", true);
        krpano.set("hotspot[" + name + "].ath", ath);
        krpano.set("hotspot[" + name + "].atv", atv);
        krpano.set("hotspot[" + name + "].width", width);
        krpano.set("hotspot[" + name + "].zorder", 999);
        krpano.call("tween(hotspot[" + name + "].alpha, 1.0);");
        
        krpano.set("hotspot[" + name + "].onvideoready", function(hs) {
            
            if (_AnimationTimes[name].time > 0) {
                krpano.call("hotspot[" + name + "].seek(" +  _AnimationTimes[name].time + ")");
            } else {
                krpano.call("hotspot[" + name + "].seek(0)");
            }
        });
        
        krpano.set("hotspot[" + name + "].onvideocomplete", function () {
            
        });

        if (allowTogglePause) {
            krpano.set("hotspot[" + name + "].onclick", function () {
                krpano.call("hotspot[" + name + "].togglepause"); 
                let Currenttime = krpano.get("hotspot[" + name + "].time");
                _AnimationTimes[name].time = Currenttime;
                AnimationTimes.update( n=> _AnimationTimes);
            });
        }
    }
    
    // 1. zistenie aký prefix má označenie bytu v URL
    function load_krpano ($project_settings, $sample_flat, $products, krpano, loadingInterval) {
        if (loaded != 0) {
            loaded = 0;
            clearInterval(loadingInterval);
        }
        ////////////////////////////console.log("načítvam krpano");
        if (krpano != undefined || krpano != "undefined") {
            krpano = null;
            loader_function = null;
            clearInterval(loader_function);
            embedpano({
                xml: userdata['cdn_path'] + "krpano/pano.xml",
                target:"pano",
                html5:"only+webgl",
                webglsettings:{preserveDrawingBuffer:true},
                mobilescale:1.0,
                initvars : init_variables,
                hooks: {
                loadSceneData(scene) {
                },
                sceneChanged(scene) {
                }
            },
                onready: function (krpano) {
                    krpano.memory.maxmem = 300;
                    ////////////////////////////console.log("krpano načítané");
                    krpano_loaded = true;
                    krpano = krpano.get("global"); 
                    krpano.set($project_settings[0].krpano_variable_name, init_variables.dizajn);
                    ////console.log(init_variables);
                    dizajn.update(n => init_variables.dizajn);
                    //active_floor.update(n => init_variables.floors);
                   
                    function percentage() {
                        loaded = parseInt(krpano.get('progress.progress')* 100);
                        if (loaded >= 100) {
                            clearInterval(loadingInterval);

                            
                        }    
                    }

                    loadingInterval = setInterval(percentage, 10);

                    vr_mode.subscribe(value => {
                        let tmp_scenes = krpano.get('scene').getArray();
                        tmp_scenes.forEach(element => {
                            let tmp_content = null;
                            if (value) {   
                                tmp_content = krpano.get('scene[' +  element.name  + '].content').replace('true', 'false').replace('true', 'false');
                            } else {
                                tmp_content = krpano.get('scene[' +  element.name  + '].content').replace('true', 'false').replace('false', 'true');
                            }
                            krpano.set('scene[' + element.name + '].content', tmp_content);
                            //////////////////////////////////////console.log(tmp_content);
                        });

                        

                        krpano.call('loadscene(' + krpano.get('xml.scene') + ', null, ' + _load_view + ', NOBLEND())');
                    });

                    function prekopaj_infopanely_desktop() {
                        ////////////////////////////////////////////////////////////////console.log("skúšam");
                        var hts_count = krpano.get("hotspot.count");
                        ////////////////////////////////////////////////////////////////console.log(hts_count);
                        for (let index = 0; index < hts_count; index++) {
                            ////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].name"));
                        }
                        
                            for (let index = 0; index < hts_count; index++) {
                                
                                if (
                                    krpano.get("hotspot[" + index +"].hstype") != null &&
                                    (krpano.get("hotspot[" + index +"].name").includes("info") ||
                                    krpano.get("hotspot[" + index +"].name").includes("video")	)
                                ) {
                                    ////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].name") + " : " + krpano.get("hotspot[" + index +"].hstype"));	
                                    krpano.set("hotspot[" + index +"].alpha", "1.0");
                                    krpano.set("hotspot[" + index +"].visible", "true");
                                    ////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].hstype"));
                                    // ////////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].name"));
                                    //////////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));

                                    var hs = krpano.get("hotspot[" + index +"]");
                                    hs.onclick = function() {

                                        hts_id = krpano.get("hotspot[" + index +"].id");
                                        ////////////////////////////////////////////////////////////////console.log(hts_id + " : " + index);
                                        
                                        if (
                                            krpano.get("hotspot[" + index +"].hstype").includes('info') != null
                                        ) {
                                            ////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].name"));
                                            modal_type.set('info-v'+ krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));
                                        }

                                        if (
                                            
                                            krpano.get("hotspot[" + index +"].hstype").includes('video')
                                        ) {
                                            ////////////////////////////////////////////////////////////////console.log(krpano.get("hotspot[" + index +"].name"));
                                            modal_type.set('video-v2');
                                        }

                                        modal.update(n => !n);

                                        
                                    }
                                }

                            }
                    }

                    function prekopaj_infopanely_vr() {
                        //////////////////////////////////////////////////////////console.log("lúskam infopanely");
                        setTimeout(() => {
                            var hts_count = krpano.get("hotspot.count");
                            for (let index = 0; index < hts_count; index++) {
                                if (krpano.get("hotspot[" + index +"].name").includes("video") || krpano.get("hotspot[" + index +"].name").includes("info")) {
                                    
                                    var hs = krpano.get("hotspot[" + index +"]");
                                        hs.onclick = function() {
                                            infopanel.update( n => false);
                                            videopanel.update( n => false);
                                            settings.scenes.forEach(element => {
                                                if (element.id.toLowerCase() == krpano.get("xml.scene").toLowerCase()) {
                                                    element.hotspots.forEach(hotspot => {
                                                        if (hotspot.name == krpano.get("hotspot[" + index +"].name")) {
                                                            
                                                            if (krpano.get("hotspot[" + index +"].name").includes("video")) {
                                                                //////////////////////////////////////////////////////////////////console.log("ide o videopanel");
                                                                videopanelData.update( n => hotspot);
                                                                videopanel.update( n => true);
                                                            } else {
                                                                //////////////////////////////////////////////////////////////console.log("ide o infopanel");
                                                                infopanelData.update( n => hotspot);
                                                                infopanel.update( n => true);
                                                            }
                                                            
                                                        }
                                                    });
                                                }
                                            });
                                        };

                                }
                            }
                        }, 500);
                        //var hs = krpano.get("hotspot[info]");                         
                    }

                    // zobrazenie menu ikony
                    function move_menu_icon() {
                        //////////////////////////////////////////////////////////console.log("move menu");
                        let menu_icon = true;
                        var krpano = document.getElementById("krpanoSWFObject");
                        let actual_ath = krpano.get("view.hlookat");
                        let actual_atv = krpano.get("view.vlookat");

                        let new_ath = 0;
                        let hotspotID = "menu";

                        // otáčanie hotspotu po osi X
                        krpano.set("hotspot["+ hotspotID + "].ath", actual_ath);

                        if (
                            menu_icon = true
                        ) {
                            let position_hotspot_button = 30;
                            
                            if (
                                (actual_atv - position_hotspot_button) / (position_hotspot_button) < 1 && 
                                (actual_atv - position_hotspot_button) > 0 
                            ) {
                                
                                krpano.set("hotspot["+ hotspotID +"].visible", "true");   
                                new_ath = (((actual_atv / position_hotspot_button)*100)-100)/100;
                                

                                if (
                                    new_ath > 0 && 
                                    new_ath < 100
                                ) {
                                    krpano.set("hotspot["+ hotspotID + "].alpha", new_ath );
                                }

                                else if (
                                    new_ath > 100
                                ) {
                                    krpano.set("hotspot["+ hotspotID + "].alpha", "1.0");
                                }
                                
                            }

                            else if(
                                
                                (actual_atv - position_hotspot_button) / (position_hotspot_button) >= 1
                            ) {
                                
                                krpano.set("hotspot["+ hotspotID + "].alpha", "1.0");
                            }

                            else {
                                
                                krpano.set("hotspot["+ hotspotID + "].alpha", "0.0");
                                krpano.set("hotspot["+ hotspotID +"].visible", "false"); 
                            }
                        }
                    }

                    // Krpano eventy
                    krpano.events.onxmlcomplete = function() {
                        if (tour_settings.default_fov != undefined) {
                            krpano.set("view.fov", parseInt(tour_settings.default_fov));
                            krpano.set("view.fovmin", parseInt(tour_settings.fovmin));
                            krpano.set("view.fovmax", parseInt(tour_settings.fovmax)); 
                        }
                        //loader_function = setInterval(percentage, 100);   

                    
                        
                    };
                    krpano.events.onviewchanged = function() {
                        
                        if (get(vr_mode) == true) {
                            //////////////////////////////////////////////////////////console.log("zmenila sa orientácia");
                            move_menu_icon();    
                        }
                    };
                    krpano.events.onnewpano = function() {
                        current_scene = krpano.get("xml.scene").toUpperCase();
                        active_scene.update( n=> current_scene);
                        
                        ////////////////////////////////console.log(krpano.get("scene[" + current_scene + "]"));

                        if (get(vr_mode) == true) {
                            //krpano.set("scene[" + krpano.get("xml.scene") + "].model", false); 
                        } else {
                            //krpano.set("scene[" + krpano.get("xml.scene") + "].model", true); 
                        }

                        
                        
                    };
                    krpano.events.onremovepano = function() {
                        if (krpano.get("xml.scene") != null) {
                            current_scene = krpano.get("xml.scene").toUpperCase();
                            //krpano.set("scene[" + krpano.get("xml.scene") + "].model", false);   
                            ////////////////////////////////////////////////////////console.log(current_scene);
                        } 
                    
                        if (krpano.get("hotspot[video]") != null) {
                            video_time.update( n => krpano.get("hotspot[video].time"));
                        }

                        if (Object.keys(_AnimationTimes).length > 0) {
                            ////////////////////console.log(Object.keys(_AnimationTimes).length);

                            for (const [key, value] of Object.entries(_AnimationTimes)) {
                                if (value.scene != undefined && current_scene == value.scene) {
                                    krpano.call("hotspot[" + value.title + "].togglepause"); 
                                    let Currenttime = krpano.get("hotspot[" + value.title + "].time");
                                    value.time = Currenttime;
                                }
                            }

                            AnimationTimes.update( n=> _AnimationTimes);
                            // _AnimationTimes.forEach(hotspot => {
                            //     krpano.call("hotspot[" + hotspot.title + "].togglepause"); 
                            //     let Currenttime = krpano.get("hotspot[" + hotspot.title + "].time");
                            //     _AnimationTimes[hotspot].time = Currenttime;
                            //     AnimationTimes.update( n=> _AnimationTimes);
                            // });
                        }
                        
                    }
                    krpano.events.onblendcomplete = function () {   
                    }
                    krpano.events.onloadcomplete = function() {
                        
                        // pri každom plnom načítaní scény naimportuj hotspoty
                        import_hotspots(sample_flat_hotspots, sample_flat_scenes, project_settings, products, _import_config_hotspots, products, sample_flat_name, _vr_mode, enable_smooth_transition, _hotspots);
                        clearInterval(loader_function);
                        show_loader = false;
                        //loader = false;
                        //////////////////console.log("načítané");

                        

                        if (enable_smooth_transition) {
                            setTimeout(() => {
                                hide_current_floorspot();    

                                if (userdata["assets_folder"] == "strabag/soley/" && sample_flat_name != "FAKE" && DisclaimerLoaded == false) {
                                    loadDisclaimer("Alle 3D-Touren und Visualisierungen dienen lediglich der Veranschaulichung der Wohnungen und haben reinen Symbolcharakter.", 5000);
                                }
                            }, 1000);

                            if (selected_start_scene != "" && current_scene != selected_start_scene) {
                                setTimeout(() => {
                                    krpano.call("loadscene(" + selected_start_scene + ", null, " + _load_view + ", BLEND(1))");
                                    selected_start_scene = "";
                                }, 100);
                            }
                        }

                        setTimeout(() => {
                            // addVideoHotspot(name, scene, videourl, scale, ox, oy, loop, ath, atv, rx, ry, width, pausedonstart, allowTogglePause, enableCursor) 
                            switch (current_scene) {
                                case "PENTH_08" : 
                                    // stena
                                    let zenaPath = userdata['cdn_path'] + "media/-16_-8_F30_A07.mp4";
                                    // stena
                                    let stenaPath = userdata['cdn_path'] + "media/P131_T8_F48_A150.mp4";
                                    addVideoHotspot("zena", "PENTH_08", zenaPath, 0.528, -123, 25, true, 19, 8, 0, 0, "688px", false, true, true);
                                    addVideoHotspot("stena", "PENTH_08", stenaPath, 0.368, 8.2, 20, false, 230, -7, 1.3, 0, "688px", true, true, true);
                                break;

                                case "PENTH_04" : 
                                    let Path = userdata['cdn_path'] + "media/pan-151tilt-20fov35_A1_open.mp4";
                                    addVideoHotspot("telka", "PENTH_04", Path, 0.7, 204, 96, false, 151, 20, 0, 0, "688px", false, false, false);
                                break;
                            }

                            
                        }, 2000);
                    }

                    krpano.events.webvr_onentervr = function() {
                        ////////////////////////////////////////////////////console.log("asgadhd");
                        vr_mode.update(n => true);
                        _vr_mode = true;
                        
                        krpano.set("scene[" + krpano.get("xml.scene") + "].model", false); 
                        //prekopaj_infopanely_vr();
                    }
                    krpano.events.webvr_onexitvr = function() {
                        vr_mode.update(n => false);
                        _vr_mode = false;
                        krpano.set("scene[" + krpano.get("xml.scene") + "].model", true); 
                    }
                    krpano.events.onblendcomplete = function() {
                        if (enable_smooth_transition) {
                            hide_current_floorspot();
                        }
                    }

                    

                    function hide_current_floorspot() {
                        //////////////////////////////////////console.log("spúšťam");
                        for (let index = 1; index < sample_flat_scenes.length + 1; index++) {
                            let i = index + 1;
                            if (sample_flat_scenes[index] != undefined && sample_flat_scenes[index].name == current_scene) {
                                
                                let krpano = document.getElementById("krpanoSWFObject");
                                krpano.set("hotspot[abu_simbel_spot_" + i + "].alpha","0.0");
                                krpano.set("hotspot[abu_simbel_spot_" + i + "].enabled","false");
                            }  else {
                                krpano.set("hotspot[abu_simbel_spot_" + i + "].enabled","true");
                            }      
                        }
                    
                    }

                    
                    
                    
                    // naimportuj scény
                    if (_enable_krpano_import ? import_krpano_scenes(sample_flat, sample_flat_scenes, project_settings, enable_smooth_transition) : hide_loader());                 
                    
                    
                    function hide_loader() {
                        //////////////////////////////////////console.log("nemôžem importovať scény, vypínam intro ..");
                        clearInterval(loader_function);
                        
                        show_loader = false;
                    }

                    check_components();  

                    async function includeMustHaveComponents() {
                        Modal = (await import('../tour_components/Modal.svelte')).default;
                        Blur = (await import('../tour_components/Blur.svelte')).default; 
                    }

                    includeMustHaveComponents();
                    
                    
                }, 
            });
        }
  	}

    //  ***** globálne funkcie *****
        
    // funkcia pre naimportovaie scén do krpana
    function import_krpano_scenes($sample_flat, $sample_flat_scenes, $project_settings, $enable_smooth_transition) {
        
        ////////////////////////////console.log("project settings : " + $project_settings);
        
        krpano = document.getElementById("krpanoSWFObject");
        

        function nacitane() {
            //////////////////////////////////////console.log("nacitane");
        }

        ////////////////console.log($sample_flat);
        
        $sample_flat_scenes.forEach(element => {
           
            let model = element["sample_flat_id"];
            //////console.log(model);
            //////console.log(sample_flat);
            if (sample_flat.model_name != null && sample_flat.model_name != undefined && sample_flat.model_name != "") {
                model = sample_flat.model_name;
                
            } else {
                //////////////////console.log("model nebol nájdený !");
            }

            // if (element["model_name"] != null && element["model_name"] != undefined && element["model_name"] != "") {
            //     model = element["model_name"];
            // }

            //////////////////console.log("model je : " + model);
            // štandardný projekt
            if ( element['enable_design'] == false && element['enable_floors'] == false) {
                // kontrola či mám zapnúť plynulý prechod
                
                 if (!$enable_smooth_transition) {
                    if (_measures) {
                        krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] + "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) + "' enabled='true' scale='1.0' onloadcomplete='" + nacitane() + "'/></image>");
                        krpano.set("scene[" + element['name'] + "].model", true);   
                    } else {
                        krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] + "_%s" + scene_mobile_suffix + ".jpg'/></image>");
                    }

                } else {
                    krpano.call("addstyle(" + element["name"] +");");
                    krpano.set("style[" + element["name"] + "].ox", element["ox"]);  
                    krpano.set("style[" + element["name"] + "].oy", element["oz"]);   
                    krpano.set("style[" + element["name"] + "].oz", element["oy"]);  
                    krpano.set("style[" + element["name"] + "].linkedscene", element["name"]);  
                    krpano.set("style[" + element["name"] + "].autohotspot", true);
                    krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] + "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] +  "models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) + "' enabled='true' scale='1.0'/></image>");
                    krpano.set("scene[" + element['name'] + "].model", true);   
                }
 
                

            }

            // zapnuté vrstvy
            if ( element['enable_design'] == true && element['enable_floors'] == false ) {
                if ($enable_smooth_transition) {        
                        krpano.call("addstyle(" + element["name"] +");");
                        krpano.set("style[" + element["name"] + "].ox", element["ox"]);  
                        krpano.set("style[" + element["name"] + "].oy", element["oz"]);   
                        krpano.set("style[" + element["name"] + "].oz", element["oy"]); 
                        krpano.set("style[" + element["name"] + "].linkedscene", element["name"]);  
                        krpano.set("style[" + element["name"] + "].autohotspot", true);
                        krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] +  "_%$dizajn%" + "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) + "' enabled='true' scale='1.0'/></image>");
                        krpano.set("scene[" + element['name'] + "].model", true);   
                } else {
                    if (_measures ) {
                    //    ////////////////////////////////////console.log("1");
                        krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] +  $project_settings[3].value +"/" + element['name'] +  "_%$dizajn%" + "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) + "' enabled='true' scale='1.0'/></image>");
                        krpano.set("scene[" + element['name'] + "].model", true);   
                    } else {
                        ////////////////////////////////////console.log(element);
                        //////////////////////////////////console.log("ddh");
                        krpano.call("addstyle(" + element["name"] +");");
                        krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] +  "_%$dizajn%" + "_%s" + scene_mobile_suffix + ".jpg'/></image>");
                        // krpano.set("style[" + element["name"] + "].ox", element["ox"]);  
                        // krpano.set("style[" + element["name"] + "].oy", element["oz"]);   
                        // krpano.set("style[" + element["name"] + "].oz", element["oy"]);

                        // krpano.set("style[" + element["name"] + "].linkedscene", element["name"]);  
                        // krpano.set("style[" + element["name"] + "].autohotspot", true);
                        // krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["base_path"] +"assets/" + $project_settings[3].value +"/" + element['name'] +  "_%$dizajn%" + "_%s.jpg'/><depthmap url='" + userdata["base_path"] + "assets/models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) + "' enabled='true' scale='1.0'/></image>");
                        // krpano.set("scene[" + element['name'] + "].model", true);  
                    }
                    
                    
                    
                    //krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["base_path"] +"assets/" + $project_settings[3].value +"/" + element['name'] +  "_%$dizajn%" + "_%s.jpg'/></image>");
                    
                }
            }

            // zapnuté poschodia
            if ( element['enable_design'] == false && element['enable_floors'] == true ) {
                
                //kontrola či mám zapnúť plynulý prechod
                if (_import_config_hotspots) {
                    
                    krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] +  "_%$floors%" + "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ ",-" + element['position_x']+ "' enabled='true' scale='1.0'/></image>");
                } else {
                    krpano.call("addstyle(" + element["name"] +");");
                    krpano.set("style[" + element["name"] +"].ox", element["ox"]);  
                    krpano.set("style[" + element["name"] +"].oy", element["oz"]);   
                    krpano.set("style[" + element["name"] +"].oz", element["oy"]);  
                    krpano.set("style[" + element["name"] +"].linkedscene", element["name"]);  
                    krpano.set("style[" + element["name"] +"].autohotspot", true);
                    krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] +  "_%$floors%" + "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) +  "' enabled='true' scale='1.0'/></image>");
                    krpano.set("scene[" + element['name'] + "].model", true);   
                }
                //krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["base_path"] +"assets/" + $project_settings[3].value +"/" + element['name'] +  "_%$floors%" + "_%s.jpg'/></image>");
            }


            // zpanuté aj vrstvy aj poschodia
            if ( element['enable_design'] == true && element['enable_floors'] == true ) {            
                
                // kontrola či mám zapnúť plynulý prechod
                if (_import_config_hotspots) {
                    krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] + "_%$dizajn%_%$floors%" +  "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ ",-" + element['position_x']+ "' enabled='true' scale='1.0'/></image>");
                } else {
                    krpano.call("addstyle(" + element["name"] +");");
                    krpano.set("style[" + element["name"] +"].ox", element["ox"]);  
                    krpano.set("style[" + element["name"] +"].oy", element["oz"]);   
                    krpano.set("style[" + element["name"] +"].oz", element["oy"]);  
                    krpano.set("style[" + element["name"] +"].linkedscene", element["name"]);  
                    krpano.set("style[" + element["name"] +"].autohotspot", true);
                    krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] +"assets/" + userdata["assets_folder"] + $project_settings[3].value +"/" + element['name'] + "_%$dizajn%_%$floors%" +  "_%s" + scene_mobile_suffix + ".jpg'/><depthmap url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "models/" +  model + model_suffix + ".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ ",-" + element['position_x']+ "' enabled='true' scale='1.0'/></image>");
                }
            }
            
        });

        // ***** Spustenie prvej scény *****
            //////////////////////////////////////console.log(selected_start_scene);

                // ak je v URL zapísaná scéna (scene="")
                ////////////////////////console.log(selected_start_scene);
                if (selected_start_scene != null && !enable_smooth_transition) {
                    let scene_found = false;
                    $sample_flat_scenes.forEach(element => {
                        if (element.name == selected_start_scene) {
                            //////////////////////////console.log(" :: " +  element.name);
                            krpano.call("loadscene(" + element.name + ", null, " + _load_view + ")");
                            krpano.call("lookat(" + element.hlookat + "," + element.vlookat +")");	
                            scene_found = true;
                            poisScene.update( n => null);
                        }
                    });

                    if (!scene_found) {
                        find_scene();    
                    }
                    
                } else {
                    find_scene();
                    
                }     
                
                function find_scene() {

                    if (_configuration != null && _configuration != undefined) {
                        
                        let start_scene, hlookat, vlookat = null;
                        _configuration.forEach(element => {
                            if (element.type == "configuration" && element.scene_id != null) {
                                start_scene = element.scene_id;
                                hlookat = element.hlookat;
                                vlookat = element.vlookat;
                                //krpano.call("loadscene(B8A_00)");   
                            } else {
                                
                                // // Ak nemá sample byt nastavenú štartovaciu scénu
                                // if ($sample_flat.start_scene == "" || $sample_flat.start_scene == null || $sample_flat.start_scene == undefined) {
                                //     krpano.call("loadscene(" + $sample_flat_scenes[0].name + ", null, MERGE)");
                                //     krpano.call("lookat(" + parseInt($sample_flat_scenes[0].hlookat) + "," + parseInt($sample_flat_scenes[0].vlookat) +")");	
                                // } else {
                                //     // ak má sample byt nastavenú štartovaciu scénu
                                //     let start_scene_id = sample_flat.start_scene;

                                //     $sample_flat_scenes.forEach(element => {
                                //         if (element.id == start_scene_id) {
                                //             krpano.call("loadscene(" + element["name"] + ", null, MERGE)");
                                //             krpano.call("lookat(" + element.hlookat + "," + element.vlookat +")");	
                                //         }
                                //     });

                                    
                                // }
                                
                            }
                        });

                        if (start_scene != null) {
                    
                    krpano.call("loadscene(" + start_scene + ", null, MERGE, BLEND(0))");
                    krpano.call("lookat(" + parseInt(hlookat) + "," + parseInt(vlookat) +")");	 
                        } else {
                    // Ak nemá sample byt nastavenú štartovaciu scénu
                    if ($sample_flat.start_scene == "" || $sample_flat.start_scene == null || $sample_flat.start_scene == undefined) {

                        if (fix_scene != null) {
                            ////////////////////////////////////////////////////////console.log(fix_scene);
                            krpano.call("loadscene(" + fix_scene + ", null, MERGE)");
                            krpano.call("lookat(" + parseInt($sample_flat_scenes[0].hlookat) + "," + parseInt($sample_flat_scenes[0].vlookat) +")");	
                        }

                        else {
                            
                            krpano.call("loadscene(" + $sample_flat_scenes[0].name + ", null, MERGE)");
                            krpano.call("lookat(" + parseInt($sample_flat_scenes[0].hlookat) + "," + parseInt($sample_flat_scenes[0].vlookat) +")");	
                        }

                        
                    } else {
                        
                        // ak má sample byt nastavenú štartovaciu scénu
                        let start_scene_id = sample_flat.start_scene;

                        $sample_flat_scenes.forEach(element => {
                            if (element.id == start_scene_id) {
                                krpano.call("loadscene(" + element["name"] + ", null, MERGE)");
                                krpano.call("lookat(" + element.hlookat + "," + element.vlookat +")");	
                            }
                        });

                        
                    }
                        }
                    } else {
                //////////////////////////////////////////////////////////console.log("b");
                // krpano.call("loadscene(B8A_00)");  
                // Ak nemá sample byt nastavenú štartovaciu scénu
                    if ($sample_flat.start_scene == "" || $sample_flat.start_scene == null || $sample_flat.start_scene == undefined) {
                        
                        $sample_flat_scenes.forEach(element => {
                            
                        
                            krpano.call("loadscene(" + $sample_flat_scenes[0].name + ", null, MERGE)");
                            krpano.call("lookat(" + parseInt($sample_flat_scenes[0].hlookat) + "," + parseInt($sample_flat_scenes[0].vlookat) +")");	

                            //     //let start_scene_id = 696;
                            //  if (element.id == start_scene_id) {
                            //     krpano.call("loadscene(" + element["name"] + ", null, MERGE)");
                            //     krpano.call("lookat(" + element.hlookat + "," + element.vlookat +")");	
                            // }
                            
                        });
                    } else {
                        
                        // ak má sample byt nastavenú štartovaciu scénu
                        let start_scene_id = sample_flat.start_scene;
                        
                        
                        
                        $sample_flat_scenes.forEach(element => {
                            if (element.id == start_scene_id) {
                                //////////////////////////////////////console.log(" :: " +  element.name);
                                krpano = document.getElementById("krpanoSWFObject");
                                krpano.call("loadscene(" + element.name + ", null, MERGE)");
                                krpano.call("lookat(" + element.hlookat + "," + element.vlookat +")");	
                            }
                        });
                    }
                    }
                }
    }

    // importovanie hotspotov
    function import_hotspots($hotspots, $sample_flat_scenes, $project_settings, $products, $import_config_hotspots, $produkty, $sample_flat_name, $vr_mode, $enable_smooth_transition, $show_hotspots) { 
       // ////////////////////////////////////console.log($hotspots);
        if ( $hotspots != null && _current_scene != null ) {
            if (krpano == null) {
                krpano = document.getElementById("krpanoSWFObject");
            }
            for (let index = 0; index < $hotspots.length; index++) {
                const hotspot = $hotspots[index]; 
                if ( (hotspot.scene_id.toLowerCase() == _current_scene.toLowerCase()) )   {   
                    let import_enabled = true;

                    // neimportovať hotspoty next node
                    if ($enable_smooth_transition && hotspot.type == "next-node") {
                        import_enabled = false;
                    }

                    // neimportovať hotspot attribute
                    if (!$import_config_hotspots && hotspot.type == "attribute") {
                        import_enabled = false;
                    }

                    if (!import_config_hotspots) {
                        import_enabled = false;
                    }



                    
                    
                //////////////////////////////////////////////////////console.log(import_enabled);
                    if (import_enabled || hotspot.enable_permanent ) {
                        //////////////////////////////////////////////////////console.log(hotspot.scene_id + " / " + _current_scene + " / " + _import_config_hotspots);
                        krpano.call("addhotspot(hts_" + hotspot.id + ");");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].woow_id, true);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].keep, false);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].ath," +hotspot.ath+ ");");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].atv," +hotspot.atv+ ");");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].distorted, false);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].rx, 0);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].ry, 0);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.8);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].zorder, 2);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].width, auto);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].height, prop);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].capture, false);");
                        //////////////////////////////////////////////////////console.log(hotspot);
                    
                        switch (hotspot.skin) {
                            case 'arrow' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/arrow-floor.png);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width," + hotspot.width + "px);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].height, prop);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].rx," + hotspot.rx + ");");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].ry," + hotspot.ry + ");");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].distorted, true);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].atv, 30);");

                                // // tieň
                                // krpano.call("addhotspot(hts_0_" + hotspot.id + ");");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].keep, false);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].ath," +hotspot.ath+ ");");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].url, krpano/icons/arrow-floor-dark.png);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].width," + hotspot.width + "px);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].height, prop);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].rx," + hotspot.rx + ");");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].distorted, true);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].atv, 30.6);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].alpha, 0.4);");
                                // krpano.call("set(hotspot[hts_0_" + hotspot.id + "].zorder, 1);");
                            
                            break;

                            case 'floorspot' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts-ring-w.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 400px);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].height, prop);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].rx," + hotspot.rx + ");");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].ry," + hotspot.ry + ");");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].distorted, true);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].atv," + hotspot.atv + ");");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].zorder, 300);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, true);");

                                // krpano.set("hotspot[hts_" + hotspot.id + "].style", "skin_hotspotstyle");
                                // krpano.set("hotspot[hts_" + hotspot.id + "].linkedscene_hoffset", "1000.0");
                                // krpano.set("hotspot[hts_" + hotspot.id + "].linkedscene", "B8A_00");
                            
                            break;

                            case 'info' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_infopanel.svg);");
                            break;

                            case 'video' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_videopanel.svg);");
                            break;

                            case 'tag' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_tag.svg);");
                            break;

                            case 'attribute-podlaha' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_podlaha.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-lista' : 
                            case 'attribute-sokel' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_lista.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-sanita' : 
                            case 'attribute-bateria1' : 
                            case 'attribute-bateria2' : 
                            case 'attribute-bateria3' : 
                            case 'attribute-bateria4' : 
                            case 'attribute-umyvadlo' :
                            case 'attribute-umyvadlo1' :
                            case 'attribute-umyvadlo2' :
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_sanita.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-sanita2' : 
                            case 'attribute-tlacidlo' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_sanita2.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-obklad' : 
                            case 'attribute-obklady' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_obklad.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-obklad2' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_obklad2.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-dlazba' : 
                            case 'attribute-dlazby' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_dlazba.svg);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;

                            case 'attribute-dvere' : 
                            case 'attribute-dvere1' : 
                            case 'attribute-dvere2' : 
                            case 'attribute-kovanie' : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/hts_attribute_dvere.svg);");
                        krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 32px);");
                            break;
                        }




                        if (!_permanently_hotspots) {
                            
                            krpano.call("set(hotspot[hts_" + hotspot.id + "].enable_permanent, false);");
                            krpano.set("hotspot[hts_"+hotspot.id+"].onover", function(hs) {
                                krpano.call("tween(hotspot[hts_" + hotspot.id + "].alpha, 1.0);");
                                if (hotspot.type != "next-node") {
                                    
                                    //document.body.classList.remove('hover');
                                } else {
                                    document.body.classList.add('hover');
                                    //krpano.se("set(cursors[standard]", "url('icons/hts-ring-active.svg'))");

                                }
                            });

                            krpano.set("hotspot[hts_"+hotspot.id+"].onout", function(hs) {
                                //document.body.classList.remove('hover');
                                
                                if (hotspot.type == "next-node") {
                                    if (_permanently_hotspots) {
                                        krpano.call("tween(hotspot[hts_" + hotspot.id + "].alpha, 0.8);");
                                        
                                    } else {
                                        krpano.call("tween(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                    }
                                    
                                } else {
                                    krpano.call("tween(hotspot[hts_" + hotspot.id + "].alpha, 0.8);");
                                }
                            });
                        } else {
                            
                            krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 1.0);");
                            krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, true);");
                            
                            
                            krpano.call("set(hotspot[hts_" + hotspot.id + "].enable_permanent, true);");


                            krpano.set("hotspot[hts_"+hotspot.id+"].onover", function(hs) {
                                if (hotspot.type == "next-node") {
                                    krpano.call("tween(hotspot[hts_" + hotspot.id + "].alpha, 1.0);");
                                }
                               
                            });

                            krpano.set("hotspot[hts_"+hotspot.id+"].onout", function(hs) {
                                if (hotspot.type == "next-node") {
                                    krpano.call("tween(hotspot[hts_" + hotspot.id + "].alpha, 0.8);");
                                }
                            });
                        }
                        
                        if (hotspot.skin.includes("attribute")){
                            krpano.call("set(hotspot[hts_" + hotspot.id + "].zorder, 300);");
                        }

                        switch (hotspot.type) {
                            case "next-node" : 
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].zorder, 2);");
                                //krpano.call("set(hotspot[hts_" + hotspot.id + "].width, 500px);");
                                krpano.call("set(hotspot[hts_" + hotspot.id + "].floorspot, true);");
                                //krpano.call("set(hotspot[hts_" + hotspot.id + "].scale, 5.0);");

                                if (!$show_hotspots) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, false);");
                                }
                                
                                if(hotspot.enable_permanent) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].url, " + userdata["cdn_path"] + "icons/" + dizajn_hotspotu + ");");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].width," + hotspot.width + " );");
                                }

                                if (_permanently_hotspots) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.8);");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, true);");
                                    
                                } else {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                    
                                }
                                
                                

                                
                                if (hotspot.target_scene_id != null || hotspot.target_scene_id != "") {
                                    
                                    $sample_flat_scenes.forEach(element => {
                                        if (
                                            element.id == hotspot.target_scene_id
                                            
                                        ) {

                                            
                                            // if (sample_flat_name == "E02B" || sample_flat_name == "F02A" || sample_flat_name == "F06C" || sample_flat_name == "B3H") {
                                            //     new_transition = true;
                                            // }

                                            // new_transition = _current_scene.toUpperCase().includes("F02");
                                            switch (enabled_new_transition) {
                                                case true:
                                                
                                                    krpano.set("scene[" + _current_scene + "].model", false);   
                                                    ////////////////////////////////////////////////////console.log(krpano.get("scene[" + _current_scene + "].model"));   
                                                    krpano.set("hotspot[hts_" + hotspot.id + "].use3dtransition", true);    
                                                    krpano.set("hotspot[hts_" +hotspot.id+ "].onclick", "scene3dtransition('" + element.name + "')");        

                                                    break;
                                            
                                                default:
                                                    krpano.set("hotspot[hts_"+hotspot.id+"].onclick", function(hs) {  
                                                        ////////////console.log("gdhd");
                                                        if ($vr_mode) {
                                                            krpano.call("loadscene(" + element.name + ", null, " + _load_view + ", NOBLEND())");
                                                        } else {
                                                            krpano.call("loadscene(" + element.name + ", null, " + _load_view + ", " + $project_settings[6].value + ")");

                                                            if (_load_view != "KEEPVIEW" ) {
                                                                krpano.set("view.hlookat", element.hlookat);
                                                                krpano.set("view.vlookat", element.vlookat);
                                                                //krpano.call("lookat(" + element.hlookat + "," + element.vlookat +")");
                                                            }
                                                            
                                                        }
                                                        
                                                        active_scene.update(n => element.name);
                                                        active_product_id.update(n => -1);
                                                        // krpano.set("hotspot[hts_" + hotspot.id + "].use3dtransition", true);    
                                                        // krpano.set("hotspot[hts_"+hotspot.id+"].onclick", "scene3dtransition('" + element.name + "')");     
                                                    });
                                                break;
                                            }
                                            //krpano.set("hotspot[hts_" + hotspot.id + "].linkedscene", element.name);
                                            
                                        }
                                    });
                                }

                            break;

                            case "info" : 
                                if (!$show_hotspots) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, false);");
                                }
                                // modal_type_tmp = hotspot.version;
                                krpano.set("hotspot[hts_"+hotspot.id+"].onclick", function(hs) {
                                    hotspots_data = {
                                        title : hotspot.content_title,
                                        img: hotspot.content_img,
                                        headline : hotspot.content_headline,
                                        subtitle : hotspot.content_subtitle,
                                        text : hotspot.content_text
                                    }

                                    modal_type_tmp = hotspot.version;
                                    modal.update(n => true);
                                });
                                
                            break;

                            case "tag" : 
                                if (!$show_hotspots || holobytNames.includes(_dizajn) ) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, false);");
                                }

                                let hts_array = ["tag_close", "tag_title", "tag_img", "tag_mask", "tag_price", "tag_content", "tag_link"];

                                
                                krpano.set("hotspot[hts_"+hotspot.id+"].onclick", function(hs) {

                                    krpano.call("moveto(" + hotspot.ath + ", " + hotspot.atv + ")");	

                                    // pozadie hotspotu
                                    krpano.call("addhotspot(hts_" + hotspot.id + "_tag_wrapper);");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].type, text );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].width, 280px );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].height, 454px );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].bgcolor, 0xFFFFFF );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].bgalpha, 0.9 );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].bgroundedge, 12 );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].zorder, 10 );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].ath, " + hotspot.ath + " );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].atv, " + hotspot.atv + " );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].ox, " + 30 + " );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].oy, " + -20 + " );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].edge, lefttop );");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].handcursor, false );");
                                    // krpano.call("set(hotspot[hts_" + hotspot.id + "_tag_wrapper].maskchildren, true );");

                                    // Close button
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_close);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].type, img);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].parent, hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].width, 32px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].height, 32px );");
                                    let closeUrl = userdata["cdn_path"] + "icons/tag-close.svg";
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_close].url", closeUrl);
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].zorder, 111 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].oy, 8px );")
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_close].ox, 240px );");;

                                    // Názov hotspotu
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_title);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].type, text );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].parent, hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].width, 280px );");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_title].html", hotspot.tag_title );
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].bgalpha, 0 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].oy, 240px );")
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].ox, 16px );")
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].css, color:0x3F3F46; text-align: left; font-size: 16px; font-weight: 600;);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_title].handcursor, false );");

                                    // Cena 
                                    if (hotspot.tag_price != "" && hotspot.tag_price != null ) {
                                        krpano.call("addlayer(hts_" + hotspot.id + "_tag_price);");
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].type, text );");
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].parent, hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].html, " + hotspot.tag_price + "  €);");
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].bgalpha, 0 );");
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].oy, 240px );")
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].ox, 264px );")
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].css, color:0x3F3F46; text-align: right; font-size: 14px;);");
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].edge, righttop );")
                                        krpano.call("set(layer[hts_" + hotspot.id + "_tag_price].handcursor, false );");
                                    }
                                    
                                    
                                    // Čiara 
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_line);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].type, text );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].parent, hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].width, 248 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].height, 1px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].bgalpha, 1 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].bgcolor, 0x3F3F46 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].oy, 268px );")
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].ox, 16px );")
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].alpha, 0.1 );")
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_line].handcursor, false );");
                                    
                                    

                                    // Maska pre obrázok
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_mask);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].type, text);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].parent, hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].width, 264px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].height, 220px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].bgcolor, 0xffffff );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].bgroundedge, 6 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].ox, 8px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].oy, 8px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].maskchildren, true );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_mask].handcursor, false );");
                                    

                                    // Obrázok
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_img);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].type, img);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].parent, layer[hts_" + hotspot.id + "_tag_mask] );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].width, prop );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].height, 220px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].bgroundedge, 6 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].url, " + userdata['cdn_path'] + "images/" + hotspot.tag_img + " );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].edge, center );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].oy, 110 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].ox, 132 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_img].handcursor, false );");                            

                                    // Link
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_link);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].type, text );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].parent, hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].width, 248px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].height, 32px );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].ox, 140 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].oy, 438 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].edge, bottom );");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_link].html",  $t("components")['tags']['show-more'] );
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].bgcolor, 0x3F3F46 );"); 
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].css, color:0xFFFFFF; text-align: center; padding-top: 6px);");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].bgalpha, 1.0 );");
                                    krpano.call("set(layer[hts_" + hotspot.id + "_tag_link].bgroundedge, 6 );");


                                    // Obsah
                                    krpano.call("addlayer(hts_" + hotspot.id + "_tag_content);");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].type", "text");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].parent", "hotspot[hts_" + hotspot.id + "_tag_wrapper] );");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].width", "248px");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].height", "115px");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].html", hotspot.tag_content);
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].bgalpha", "0.0" );
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].ox", "16px" );
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].oy", "280px");
                                    
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].css" , "color:0x3F3F46; text-align: left;");
                                    krpano.set("layer[hts_" + hotspot.id + "_tag_content].handcursor", false);

                                    

                                    // zatvorenie hotspotu TAG
                                    krpano.set("layer[hts_"+ hotspot.id + "_tag_close].onclick", function(hs) {
                                        krpano.call("removehotspot(hts_" + hotspot.id + "_tag_wrapper, true);");
                                        hts_array.forEach(element => {
                                            krpano.call("removelayer(hts_" + hotspot.id + "_" + element + ");");
                                        });
                                    });

                                    // Otvorenie odkazu
                                    krpano.set("layer[hts_"+ hotspot.id + "_tag_link].onclick", function(hs) {
                                        window.open(hotspot.tag_link, '_blank');
                                    });

                                });

                            break;

                            case "attribute" : 
                                if (!$show_hotspots) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].enabled, false);");
                                }
                                if (!import_config_hotspots) {
                                    krpano.call("set(hotspot[hts_" + hotspot.id + "].alpha, 0.0);");
                                }
                                krpano.set("hotspot[hts_"+hotspot.id+"].onclick", function(hs) {
                                    check_ath.update(n => true);
                                    active_product_id.update(n => hotspot.product_id);
                                    attributes.update(n => true);
                                    
                                    if (_vr_mode == true) {
                                        vr_product_enabled.update(n => true);    
                                    }
                                    
                                });
                                
                                products.forEach(product => {
                                    
                                    if (parseInt(hotspot.product_id) == parseInt(product.id)) {
                                        ////////////////////////////console.log(hotspot.product_id + " : " + product.id);
                                        
                                        ////////////////////////////console.log(all_patches);

                                    
                                        
                                    
                                        let positions = ["f","l","r","u","d","b"];

                                        if (all_patches != null) {
                                        ////////////////////////////console.log(product.id + "/" + product.name + " : " + hotspot.product_id);
                                            Object.keys(all_patches).forEach(function(key) {
                                                ////////////////////////////console.log(key);
                                                if (key == product.patch_id) {
                                                    ////////////////////////////console.log(key + " : " + product.patch_id);
                                                    Object.keys(all_patches[key]).forEach(function(scena) {
                                                        if (scena == _current_scene) {
                                                            let count = 0;
                                                            Object.keys(all_patches[key][scena]).forEach(function(variant) {
                                                            
                                                                if (count == 0) {
                                                                Object.keys(all_patches[key][scena][variant]).forEach(function(side) {
                                                                    
                                                                        ////////////////////////////console.log(key + " / " + scena + " / " + side + " / " + all_patches[key][scena][variant][side].exist );
                                                                        count++;

                                                                        
                                                                        
                                                                            ////////////////////////////console.log(userdata['cdn_path'] + "assets/patches/" + _current_scene + "_" + _dizajn + "_&_" + product.patch_id +"_%$" + product.patch_id +"%_" + side + ".webp");
                                                                            krpano.call("addhotspot(hts_" + product.patch_id + "_" + _current_scene + "_" + side + ");");	
                                                                            if (_dizajn != "") {
                                                                                krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "patches/" + _current_scene + "_" + _dizajn + "_&_" + product.patch_id +"_%$" + product.patch_id +"%_" + side + _mobile_patch_suffix +".webp);");
                                                                            } else {
                                                                                krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "patches/" + _current_scene + "_&_" + product.patch_id +"_%$" + product.patch_id +"%_" + side + _mobile_patch_suffix +".webp);");
                                                                            }
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].patch, true);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].keep, false);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].distorted, true);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].width, 1000);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].height, 1000);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].torigin, image);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].enabled, false);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].cursor, pointer);");
                                                                            krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].depth, off);");

                                                                            ////////////////////////////console.log(krpano.get("hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "]"));

                                                                            switch (product.patch_id) {
                                                                                case "obklady":
                                                                                    //////////////////////////////////////////////////////////console.log("obklady :" + product.patch_id + "/" + product.product_order);
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].zorder, -1);");
                                                                                    
                                                                                    break;
                                                                            
                                                                                default:
                                                                                    //////////////////////////////////////////////////////////console.log(product.product_order);
                                                                                    //let zorder = product.product_order;
                                                                                    let zorder = product.product_order * 0.1;
                                                                                    //////////////////////////////////////////////////////////console.log("hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].zorder," + zorder + ");");
                                                                                    krpano.set("hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].zorder", zorder );
                                                                                    break;
                                                                            }
                                                                                
                                                                            

                                                                            switch (side) {
                                                                                // front
                                                                                case "f":
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].ath, 0);");
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].atv, 0);");
                                                                                    break;
                                                                            
                                                                                    // left
                                                                                case "l" :
                                                                                
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].ath, -90);");
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].atv, 0);");
                                                                                    break;
                                                                            
                                                                                // right
                                                                                case "r" : 
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].ath, +90);");
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].atv, 0);");
                                                                                    break;
                                                                            
                                                                                // top
                                                                                case "u" : 
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].ath, 0);");
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].atv, -90);");
                                                                                    break;
                                                                            
                                                                                // down
                                                                                case "d" : 
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].ath, 0);");
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].atv, +90);");
                                                                                    break;

                                                                                // back
                                                                                case "b" : 
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].ath, 180);");
                                                                                    krpano.call("set(hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "].atv, 0);");
                                                                                    break;
                                                                        
                                                                                default:
                                                                                    break;
                                                                            }

                                                                            //////////////////////////////////////////////////////////console.log(krpano.get("hotspot[hts_" + product.patch_id + "_" + _current_scene + "_" + side + "]"));
                                                                        
                                                                    
                                                                    
                                                                }); 
                                                            }   
                                                            });
                                                        }
                                                    });
                                                }
                                                
                                            });
                                        }
                                


                                    

                                    }
                                });					
                            break;
                        }
                    }
                }
            }         
        }
        
        if ($enable_smooth_transition == true) {
            //////////////////////////////////////console.log(dizajn_hotspotu + " : " + scale_hotspotu);
            for (let index = 1; index < $sample_flat_scenes.length + 1; index++) {     
                if ( _permanently_hotspots) {
                    //do_crop_animation(64,64, 60);
                    // krpano.set("hotspot[abu_simbel_spot_" + index + "].onloaded", function () {
                    //     //////////////////console.log("nacitany");
                    //     // krpano.call("hotspot[abu_simbel_spot_" + index + "].do_crop_animation(64,64,60)");
                    // });
                    krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].alpha, 0.8);");
                    
                    krpano.set("hotspot[abu_simbel_spot_" + index + "].onover", function(hs) {
                        ////////////////////////////////////////////////////console.log("ideš cez = " + index );
                        krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].alpha, 1.0);");
                        let up = scale_hotspotu * 1.2;
                        //////////////////////////////////////console.log(up);
                        krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].scale, " + up +  ");");
                        //////////////////////////////////////console.log(index);
                    });
                    krpano.set("hotspot[abu_simbel_spot_" + index + "].onout", function(hs) {
                        krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].alpha, 0.8);");
                        krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].scale, " + scale_hotspotu +  ");");
                    });
                } else {
                    krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].alpha, 0.0);");
                    krpano.set("hotspot[abu_simbel_spot_" + index + "].onover", function(hs) {
                        krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].alpha, 0.8);");
                    });
                    krpano.set("hotspot[abu_simbel_spot_" + index + "].onout", function(hs) {
                        krpano.call("tween(hotspot[abu_simbel_spot_" + index + "].alpha, 0.0);");
                    });
                }
                //krpano.set("hotspot[abu_simbel_spot_" + index + "].alpha","1.0");
                krpano.set("hotspot[abu_simbel_spot_" + index + "].url", userdata["cdn_path"] + "icons/" + dizajn_hotspotu + "");
                krpano.set("hotspot[abu_simbel_spot_" + index + "].scale", + scale_hotspotu + "");
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].zorder", 100);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].width", 200);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].height", 200);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].distorted", true);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].depth", 0);

                // krpano.set("hotspot[abu_simbel_spot_" + index + "].oversampling", 2);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].mipmapping",true);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].depthbuffer", true);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].torigin", "world");
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].rotationorder", "xyz");
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].rx", -90);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].alpha", 0.8);
                // krpano.set("hotspot[abu_simbel_spot_" + index + "].vcenter", true);
                
                //krpano.set("hotspot[abu_simbel_spot_" + index + "].enabled","true");
                // ////////////////////////////////////console.log(index + " : " + krpano.get("hotspot[abu_simbel_spot_" + index + "].url"));
                //////////////////////////////////////console.log(krpano.get("hotspot[abu_simbel_spot_" + index + "]"));
            }
        } else {
            for (let index = 1; index < $sample_flat_scenes.length + 1; index++) {     
                // if ( _permanently_hotspots) {
                //     krpano.set("hotspot[abu_simbel_spot_" + index + "].zorder", 100);
                // }
            }

            
        }

        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].zorder')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].alpha')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].width')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].height')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].url')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].ox')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].oy')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].scale')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].distorted')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].enable')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].depth')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].oversampling')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].mipmapping')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].depthbuffer')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].torigin')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].rotationorder')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].rx')); 
        // ////////////////////////////////////console.log(krpano.get('hotspot[abu_simbel_spot_2].vcenter')); 
        


        
        
        
        
        
    }

    


    // Funkcia na pridanie komponentov
    async function add_components($id) {
        
        switch ($id) {
            case "logo":
                if (Logo == null) {
                    Logo = (await import('../tour_components/Logo.svelte')).default
                }    
            break;

            case "shortcuts":
                if (Shortcuts == null) {
                    Shortcuts = (await import('../tour_components/Shortcuts_new.svelte')).default
                }   
            break;

            case "screenshots":
                if (Screenshot == null) {
                    Screenshot = (await import('../tour_components/Screenshot.svelte')).default
                }   
            break;

            case "flat_filter":
                if (Flat_filter == null) {
                ////////////////////console.log("flat");
                    Flat_filter = (await import('../tour_components/Flat_filter.svelte')).default
                }
            break;

            case "sidebar":
                if (Sidebar == null) {
                    Sidebar = (await import('../tour_components/Sidebar_new.svelte')).default
                }
            break;

            case "contact_form" :
                if (Contact_form == null) {
                    Contact_form = (await import('../tour_components/Contact_form.svelte')).default;
                }
            break;

            case "floorplan" :
                if (Floorplan == null) {
                    Floorplan = (await import('../tour_components/Floorplan_new.svelte')).default;
                }
                
            break;

            case "languages" : 
                if (Languages == null) {
                    Languages = (await import('../tour_components/Languages.svelte')).default;
                }
            break;

            case "tour_info" :
                
            break;

            case "attributes" :
                if (Attributes == null) {
                    Attributes = (await import('../tour_components/Attributes.svelte')).default;
                }
            break;

            case "layers" :
                // document.getElementById("component_layers").checked = true;
            break;

            case "basket":
                if (Basket == null) {
                    Basket = (await import('../tour_components/Basket.svelte')).default    
                }
                
                basket_enabled.update(n => true);
            break;

            case "3d_model" : 
                if (Model == null) {
                    Model = (await import('../tour_components/3D_model.svelte')).default
                }
            break;

            case "vr":
                if (Vr == null) {
                    Vr = (await import('../tour_components/Vr_tools.svelte')).default
                }
                
            break;

            case "tutorial":
                if (Tutorial == null) {
                    Tutorial = (await import('../tour_components/Tutorial.svelte')).default;
                }
                
                // if (get(show_tutorial)) {
                    
                // }

                if (_tutorial != null  && _changed_flat == null) {
                    tutorial.update(n => true);    
                } else {
                    tutorial.update(n => false);    
                }
                
                
                
                //tutorial.update(n => true);
                enable_tutorial = true;
            break;

            case "flat_detail":
                if (FlatDetail == null) {
                    FlatDetail = (await import('../tour_components/3D_flat.svelte')).default
                }
                
            break;
        
            case "otacadlo":
                //////////////////////////////////console.log("čítam otacadlo");
                if (Otacadlo == null) {
                    Otacadlo = (await import('../tour_components/Otacadlo_new_logic.svelte')).default;
                }
                
            break;

            case "otacadlo_img":
                if (Otacadlo_img == null) {
                    Otacadlo_img = (await import('../tour_components/Otacadlo_img.svelte')).default
                }
                
            break;

            case "dispositions":
                if (FlatDispositions == null) {
                    FlatDispositions = (await import('../tour_components/Flat_dispositions.svelte')).default;    
                }
                //////////////////////////////////////console.log("Flat d");
                
            break;

            default:
                break;
        }
        
    }

    // funkcia na označovanie načítaných komponentov
    function check_components() {
        // ////////////////////////////////////////////////////////console.log(components);

        let i = 0;
        let y = 0;
        

        components.forEach(element => {
            if (document.getElementById(element) != null) {
                setTimeout(() => {
                    document.getElementById(element).checked = true;    
                }, 200 + i);
                i = i + 240;

                setTimeout(() => {
                    document.getElementById("label_" + element).classList.add("active");
                }, 240 + y);
                y = y + 280;
            }
            
        });

        setTimeout(() => {
            loader_text = $t("components")['preloader']['components_loaded'];
        }, i + 400);


        setTimeout(() => {
            loader_text = "spúšťam VR ";
            loader = false;            
        }, i + 900);

    }

    const initializeRemarkable = () => {
        getDataFromDB();
    }

    onMount(async () => {        
        //Languages = (await import('../tour_components/Languages.svelte')).default;
    });

    function remove_all_scenes() {
        //var krpano = document.getElementById('krpanoSWFObject');
        //enable_smooth_transition = false;
        //////////////////////////////////////console.log(krpano.get('scene.count'));
        //////////////////////////////////////console.log(sample_flat_scenes);
        if (sample_flat_scenes != null && sample_flat_scenes != undefined) {
            sample_flat_scenes.forEach(element => {
                //krpano.call("scene.removeItem(" + element.name.toLowerCase() + ");");
                if (krpano != null) {
                    krpano.call("scene.removearrayitem(" + element.name.toLowerCase() + ");");
                }
                
                
                
            });
            //////////////////////////////////////console.log(krpano.get('scene.count'));
            //////////////////////////////////////console.log(sample_flat_scenes);
        }
        sample_flat_scenes = [];
        //krpano = null;
        _enable_krpano_import = true;
    }

    function remove_all_hotspots() {
        //////////////////////////////////////console.log(sample_flat_hotspots);
        if (sample_flat_hotspots != null && sample_flat_hotspots != undefined) {
            sample_flat_hotspots.forEach(element => {
                krpano.call("removehotspot" + element.name + ");");
                sample_flat_hotspots.splice(element);
            });
            //////////////////////////////////////console.log(krpano.get('hotspot.count'));
            //////////////////////////////////////console.log(sample_flat_hotspots);
        }
    }

    function remove_all(target) {
        var krpanoObject = document.getElementById('krpanoSWFObject');
        
        loaded = 0;
        show_loader = true;
        krpano_loaded = false;
        attributes_settings = null;
        layers_settings = null;
        google_map_settings = null;
        social_settings = null;
        contact_form_settings = null;
        google_map_settings = null;
        floorplan_settings = null;
        tour_info_settings = null;
        settings_settings = null; 
        model_3d_settings = null;
        mode = null; 
        enable_bookcall = false; 
        enable_sound = false; 
        enable_vr = false; 
        enable_tutorial = false; 
        _tutorial = false;
        
        if (krpanoObject != null) {
            krpanoObject.remove();
        }
        
        if (window.gc) {
            ////////////////////////////////console.log("gc");
            window.gc();
        }
        
        Logo = null;
        Shortcuts = null;
        Screenshot = null;
        Flat_filter = null;
        Sidebar = null;
        Contact_form = null;
        Floorplan = null;
        Blur = null;
        Modal = null;
        Languages = null;
        Attributes = null;
        Basket = null;
        Model = null;
        Vr = null;
        FlatDispositions = null;
        Otacadlo_img = null;
        FlatDetail = null;

        // let arraytoDelete = [Shortcuts, Logo, Screenshot, Flat_filter, Sidebar, Contact_form, Floorplan, Modal, Blur, Languages, Attributes, Basket, Model, Vr, FlatDispositions, Otacadlo_img, FlatDetail];
        // arraytoDelete.forEach(element => {
        //     element = null;
        // });
        
        //krpano.call("loop(hotspot.count GT 0, removehotspot(0));");
        
        remove_all_scenes();
        all_scenes.update( n => []);
        basket_enabled.update( n => false);
        //should_deactivate_otacadlo_graphic.update( n => false);

        //all_hotspots.update( n => []);
        //krpano.call('generate_abu_simbel_hotspots');
        getDataFromDB(target);

        //krpano.call("loop(scene.count GT 0, removescene(0));");
        
        //remove_all_hotspots();
        ////////////////////////////////////console.log(components);
        old_components = [];
        
        components.forEach(element => {
            if (element == "component_otacadlo") {
                old_components = [...old_components, "component_otacadlo"];
            }

            if (element == "component_flat_filter") {
                old_components = [...old_components, "component_flat_filter"];
            }
        });

        ////////////////////////////////////console.log(old_components);
        components = [];
        components = [...components, old_components ];
        
        //krpano = null;
    
        //removepano("pano");
        
    }


</script>
<svelte:head>
    <script src="{userdata['cdn_path']}krpano/pano.js" on:load={initializeRemarkable}></script>
</svelte:head>

<div id="woow-loader">
    <!-- // Intro - preloader -->
    {#if show_loader}
        <div id="intro-animation" out:fade={{ delay: 250, duration: 300 }} style="background-image: url('{userdata["cdn_path"]}assets/{userdata["assets_folder"]}images/cover.webp');">
            <div class="cover">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="100%" id="blobSvg"> 
                    <defs>                       
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">                          
                            <stop offset="0%" style="stop-color: rgb(255,255,255);"></stop>                           
                            <stop offset="100%" style="stop-color: rgb(255,255,255);"></stop>                       
                        </linearGradient>
                    </defs>
                    <path id="blob" fill="url(#gradient)">                            
                        <animate attributeName="d" dur="10000ms" repeatCount="indefinite" values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"></animate>
                    </path>
                </svg>    
            </div>
            <div class="wrapper">
                <div class="logo">
                    <img src="{userdata["cdn_path"]}images/woow-logo.svg" alt="" class="logo">
                </div>
                 <img class="preloader" src="{userdata["cdn_path"]}icons/preloader.svg" alt="">
                <p>{$t("components")['intro']["loading-tour"]}</p>
                <span>{$t("components")['intro']["tour-loaded"]} : {loaded} % </span>
                {#if loaded == 100}
                <!-- <p>Data loaded, starting the tour...</p> -->
                {/if}
            </div>
        </div>
        <!-- <div class="cover" out:fade={{ delay: 0, duration: 300, easing: quintOut }}></div>
        <div id="loader" out:fade={{ delay: 250, duration: 500, easing: quintOut }}>
            <div class="loader-wrapper">
                {#if logo_settings != undefined}
                    <div class="logo">
                        <img src="{userdata["base_path"]}assets/img/{logo_settings.logo_url}" alt="" class="logo">
                    </div>
                {/if}
                
                <span>{loader_text}</span>
                <img class="preloader" src="assets/icons/preloader.svg" alt="">
                <div class="grid">
                    {#if components != null}
                        {#each components as component}
                            <label id="label_{component}" class="checkbox path">
                                <p>{component.replace("component_","")}</p>
                                
                                <input id="{component}" type="checkbox" class="animated">
                                <svg viewBox="0 0 21 21">
                                    <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                </svg>
                            </label>
                        {/each}
                    {/if}                
                </div>
            </div>
        </div> -->
    {/if}

    <!-- // Dynamické načítanie komponentov -->
    {#if Shortcuts != null}
        <svelte:component this={Shortcuts} {userdata} {shortcuts_settings} {sample_flat} {sample_flat_scenes} {_dispositions_enabled} {current_user}/>
    {/if}

    {#if Logo != null && logo_settings != null}
        <svelte:component this={Logo} {userdata} {logo_settings} {_dispositions_enabled}/>
    {/if}

    {#if Screenshot != null}
        <svelte:component this={Screenshot} {userdata} {_dispositions_enabled}/>
    {/if}

    {#if Flat_filter != null}
        <svelte:component this={Flat_filter} {userdata} {sample_flat} {_dispositions_enabled} {should_enable_disposition} {Otacadlo} {isMobile} {fakeFlats}/>
    {/if}

    {#if Sidebar != null && enable_sidebar}
        <svelte:component this={Sidebar} {userdata} {sample_flat_scenes} {sample_flat_name} {layers_settings} {social_settings} {contact_form_settings} {google_map_settings} {floorplan_settings} {tour_info_settings} {settings_settings} {model_3d_settings} {mode} {enable_bookcall} {enable_sound} {enable_vr} {enable_tutorial} {_dispositions_enabled}/>
    {/if}

    {#if Contact_form != null}
        <svelte:component this={Contact_form} {userdata}/>
    {/if}

    {#if Floorplan != null}
        <svelte:component this={Floorplan} {userdata} {floorplan_settings} {floorplan_pins} {sample_flat_scenes} {current_scene} {sample_flat} />
    {/if}

    {#if Modal && show_modal}
        {#if ( modal_type_tmp === 'google-map' || modal_type_tmp === 'google-map2' || modal_type_tmp === 'book-call' ) && google_map_settings != null}
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['maps']['title']}" settings="" url="{google_map_settings.url}" filter="{google_map_settings.filter}" position="" id="" current_scene="{current_scene}" {userdata}/>
        {/if}

        {#if ( modal_type_tmp == 'flat-compare')}
            <svelte:component this={Modal} {userdata} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['maps']['title']}" settings="" position="" id="" current_scene="{current_scene}"/>
        {/if}

        {#if modal_type_tmp === 'model-3d' && model_3d_settings != null}
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="3D model" settings="" url="" filter="" position="" id="" current_scene="{current_scene}"/>
        {/if}   


        {#if modal_type_tmp === 'tour-info' && tour_info_settings != null}
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['tourinfo']['title']}" settings="{tour_info_settings}" url="" filter="" position="" id="" current_scene="{current_scene}" {tour_info_settings} {userdata}/>
        {/if}

        {#if modal_type_tmp === 'info-v1' || modal_type_tmp === 'info-v2' || modal_type_tmp === 'image-only' }
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" source={hotspots_data} title="" settings="" url="" filter=""  position="" id="" current_scene="{current_scene}" prefix="{userdata['base_path']}"/>
        {/if}

        <!-- {#if modal_type_tmp === 'info-v1' || modal_type_tmp === 'info-v2' || modal_type_tmp === 'info-v3' }
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
        {/if} -->

        {#if modal_type_tmp === 'video-v1'}
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
        {/if}

        {#if modal_type_tmp === 'video-v2'}
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
        {/if}
    {/if}

    {#if Blur != null || _tutorial}
        <svelte:component this={Blur}/>
    {/if}

    {#if Languages != null && languages_settings != null}
        <svelte:component this={Languages} {userdata} {languages_settings} {all_languages} {_dispositions_enabled}/>
    {/if}

    {#if Attributes != null && _import_config_hotspots && krpano_loaded}
        <svelte:component this={Attributes} {userdata} {attributes_settings} {products} {product_variants} {product_categories} {price_list} {product_mj}/>
    {/if}
    
    {#if Basket != null && _basket_enabled}
        <svelte:component this={Basket} {userdata} {sample_flat_name} {pdf_settings} {_dispositions_enabled}/>
    {/if}

    {#if Model != null && model_enabled == true && _import_config_hotspots}
        <svelte:component this={Model} {userdata}/>
    {/if}

    {#if Vr != null}
        <svelte:component this={Vr} {userdata} {components}/>
    {/if}

    {#if FlatDispositions != null && !_tutorial && _dispositions_enabled} 
        <svelte:component this={FlatDispositions} {userdata} {sample_flat_name} {_enable_otacadlo}/>
    {/if}
    
    {#if Otacadlo != null && _enable_otacadlo}
        <svelte:component this={Otacadlo} {userdata} {rotator} {tour_settings} {should_enable_disposition} {sample_flat_name} {buildings_loaded} {sample_flat} {fakeFlats}/>
    {/if}

    {#if Otacadlo_img != null}
        <svelte:component this={Otacadlo_img} {userdata}/>
    {/if}

    {#if Tutorial != null && _tutorial && !loader}
        <svelte:component this={Tutorial} {userdata} {components} {enable_smooth_transition}  {languages_settings} {_basket_enabled} {logo_settings}/>
    {/if}

    {#if FlatDetail != null && _flat_3D}
        <svelte:component this={FlatDetail} {userdata}/>
    {/if}

    {#if showDisclaimer}
            <div id="disclaimer" in:fade={{ delay: 250, duration: 300 }} out:fade={{ delay: 250, duration: 300 }} >{disclaimerText}</div>
    {/if}
    
    {#if Analytics != null}
        <svelte:component this={Analytics} {userdata}/>
    {/if}

    

    {#if showRotatorIcon && _enable_otacadlo == false}
        <div id="show-rotator" on:click={() => enable_otacadlo.update( n => true)}/>
    {/if}

<div id="pano"/>

</div>

<style lang="scss">

    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/variables.scss";

    #show-rotator {
        width: 40px;
        height: 40px;
        @include blur;
        position: absolute;
        left: 16px;
        bottom: 16px;
        border-radius: $radius-S;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 3;
        &::after {
            width: 24px;
            height: 24px;
            content: '';
            display: block;
            background-color: var(--primary-color);
            mask-repeat: no-repeat;
        }
    }

    #disclaimer {
        position: absolute;
        left: 50%;
        bottom: 72px;
        transform: translateX(-50%);
        text-align: center;
        color: var(--secondary-color);
        background-color: var(--main-color);
        border-radius: 6px;
        padding: 8px 12px;
        z-index: 999;
        max-width: 500px;
        width: calc(100% - 32px);

    }

    #woow-loader{
    #intro-animation{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 99999;
        //background-image: url('../assets/images/cover.webp');
        background-size: cover;
        background-position: center;
        //backdrop-filter: blur(60px) !important;
        display: flex;
        justify-content: center;
        align-items: center;

        #blobSvg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 340px;
            height: 340px;
            //box-shadow: 0 0 50px -35px black;
        }

        .cover {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;


            // background: linear-gradient(
            // -45deg,
            //     #e98074,
            //     #AC269F,
            //     #707FC9,
            //     #43AA8B
            // );
            // background-size: 400% 400%;
            // animation: animated-background 12s ease infinite;
            // overflow: hidden; 
            -webkit-backdrop-filter: blur(30px) !important;
            backdrop-filter: blur(30px) !important;
            -webkit-backdrop-filter: blur(30px) !important;
            opacity:1;
            
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px 40px;
            
            border-radius: 12px;
            
            z-index: 2;

            p {
                @include rubik-r12;
                padding: 8px 0px;
            }

            span {
                @include rubik-r14;
            }
            

            .preloader {
                width: 40px;
                opacity: 0.2;
            }

        }
        

     
    }

    @keyframes animated-background {
	0% {
		background-position: 0% 50%;
	}
  25% {
    background-position: 50% 50%;
  }
	50% {
		background-position: 100% 50%;
	}
  75% {
    background-position: 50% 50%;
  }
	100% {
		background-position: 0% 50%;
	}
}
    

    #pano {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100%;
        z-index: 0;
        opacity: 1;
		visibility: visible;
		transition: all 0.25s ease-in-out;

        &.hidden {
            opacity: 0;
		    visibility: hidden;
        }
    }

    .logo {
        max-width: 120px;
    }
}
</style>