<script>
    import { t, locale, locales } from "../i18n.js";
    import { Link, navigate } from 'svelte-routing';

    function getProps({ href, isPartiallyCurrent, isCurrent }) {
        const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;
        // The object returned here is spread on the anchor element's attributes
        if (isActive) {
        return { class: "active" };
        }
        return {};
    }

</script>
<aside>
    <img src="../../assets/images/woow-logo-white.svg" alt="logo">
    <navigation>
        <h3>{$t("admin")["aside"]["dashboard"]["headline"]}</h3>
        <span>{$t("admin")["aside"]["dashboard"]["subtitle"]}</span>
        <Link class="link" to="/admin"><i class="fa-solid fa-desktop"></i>{$t("admin")["aside"]["dashboard"]["dashboard"]}</Link>
        <Link class="link" to="/"><i class="fa-solid fa-arrow-up-right-from-square"></i>{$t("admin")["aside"]["dashboard"]["show_tour"]}</Link>
    </navigation>
    
    <navigation>
        <h3>{$t("admin")["aside"]["projects"]["headline"]}</h3>
        <span>{$t("admin")["aside"]["projects"]["subtitle"]}</span>
        <Link class="link" to="/configurator-import-max"><i class="fa-solid fa-file-import"></i>Import MAX</Link>
        <Link class="link" to="/configurator-sample-flats"><i class="fa-solid fa-building"></i>{$t("admin")["aside"]["projects"]["projects"]}</Link>
    </navigation>
    
    <navigation>
        <h3>{$t("admin")["aside"]["scenes"]["headline"]}</h3>
        <span>{$t("admin")["aside"]["scenes"]["subtitle"]}</span>
        <Link class="link" to="/scenes-configuration"><i class="fa-solid fa-panorama"></i>{$t("admin")["aside"]["scenes"]["scenes"]}</Link>
    </navigation>

    <navigation>
        <h3>{$t("admin")["aside"]["components"]["headline"]}</h3>
        <span>{$t("admin")["aside"]["components"]["subtitle"]}</span>
        <Link class="link" to="/logo"><i class="fa-solid fa-image"></i>{$t("admin")["aside"]["components"]["logo"]}</Link>
        <Link class="link" to="/google-maps"><i class="fa-solid fa-location-dot"></i>{$t("admin")["aside"]["components"]["google_map"]}</Link>
        <Link class="link" to="/share"><i class="fa-solid fa-share-nodes"></i>{$t("admin")["aside"]["components"]["share"]}</Link>
        <Link class="link" to="/tour-settings"><i class="fa-solid fa-sliders"></i>{$t("admin")["aside"]["components"]["settings"]}</Link>
        <Link class="link" to="/floorplan"><i class="fa-solid fa-map-pin"></i>{$t("admin")["aside"]["components"]["floorplan"]}</Link>
        <Link class="link" to="/shortcuts"><i class="fa-solid fa-images"></i>{$t("admin")["aside"]["components"]["shortcuts"]}</Link>
        <Link class="link" to="/sounds"><i class="fa-solid fa-volume-high"></i>{$t("admin")["aside"]["components"]["sounds"]}</Link>
        <Link class="link" to="/tour-info"><i class="fa-solid fa-circle-info"></i>{$t("admin")["aside"]["components"]["tour_info"]}</Link>
        <Link class="link" to="/contact-form"><i class="fa-solid fa-file-signature"></i>Kontaktný formulár</Link>
        <Link class="link" to="/flat-filter"><i class="fa-solid fa-filter"></i>Filter bytov</Link>
        <Link class="link" to="/layers"><i class="fa-solid fa-layer-group"></i>Vrstvy / Layers</Link>
        <Link class="link" to="/rotator"><i class="fa-solid fa-arrows-spin"></i>Rotátor / Otáčadlo</Link>
    </navigation>

    <navigation>
        <h3>{$t("admin")["aside"]["configurator"]["headline"]}</h3>
        <span>{$t("admin")["aside"]["configurator"]["subtitle"]}</span>
        <Link class="link" to="/configurator-users"><i class="fa-solid fa-users"></i>{$t("admin")["aside"]["configurator"]["users"]}</Link>
        <Link class="link" to="/add-configurator-user"><i class="fa-solid fa-user-plus"></i>{$t("admin")["aside"]["configurator"]["add_user"]}</Link>
        <Link class="link" to="/import-configurator-users"><i class="fa-solid fa-file-csv"></i>{$t("admin")["aside"]["configurator"]["import_users"]}</Link>
        <hr>
        <Link class="link" to="/product-categories"><i class="fa-solid fa-list"></i>{$t("admin")["aside"]["configurator"]["categories"]}</Link>
        <Link class="link" to="/add-product-category"><i class="fa-solid fa-circle-plus"></i>{$t("admin")["aside"]["configurator"]["add_category"]}</Link>
        <hr>
        <Link class="link" to="/product-mj"><i class="fa-solid fa-ruler"></i>{$t("admin")["aside"]["configurator"]["units"]}</Link>
        <Link class="link" to="/add-product-mj"><i class="fa-solid fa-circle-plus"></i>{$t("admin")["aside"]["configurator"]["add_unit"]}</Link>
        <hr>
        <Link class="link" to="/product-standards"><i class="fa-regular fa-star-half-stroke"></i>{$t("admin")["aside"]["configurator"]["standards"]}</Link>
        <Link class="link" to="/add-product-standard"><i class="fa-solid fa-circle-plus"></i>{$t("admin")["aside"]["configurator"]["add_standard"]}</Link>
        <hr>
        <Link class="link" to="/products"><i class="fa-solid fa-cart-shopping"></i>{$t("admin")["aside"]["configurator"]["products"]}</Link>
        <Link class="link" to="/add-configurator-product"><i class="fa-solid fa-cart-plus"></i>{$t("admin")["aside"]["configurator"]["add_product"]}</Link>
        <Link class="link" to="/import-configurator-products"><i class="fa-solid fa-file-csv"></i>{$t("admin")["aside"]["configurator"]["import_products"]}</Link>
        <hr>
        <Link class="link" to="/flats"><i class="fa-solid fa-building"></i>{$t("admin")["aside"]["configurator"]["appartments"]}</Link>
        <Link class="link" to="/add-configurator-flat"><i class="fa-solid fa-circle-plus"></i>{$t("admin")["aside"]["configurator"]["add_appartment"]}</Link>
        <Link class="link" to="/import-configurator-flats"><i class="fa-solid fa-file-csv"></i>{$t("admin")["aside"]["configurator"]["import_appartments"]}</Link>
        <hr>
        <Link class="link" to="/flat-products"><i class="fa-solid fa-file-csv"></i>{$t("admin")["aside"]["configurator"]["flat_products"]}</Link>
    </navigation>
</aside>

<style lang="scss">
    @import '../assets/scss/themes.scss';
    
    aside {
        grid-area: aside;
        background-color: $aside-background;
        color: $text-color;
        padding: 0px 16px;

        navigation {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: inherit;
        margin-top: 32px;
        position: relative;
            h3 {
                color: $primary-color;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 4px;
                padding-left: 16px;
            }
            span {
                color: inherit;
                font-weight: 300;
                padding-left: 16px;
                text-align: left;
                margin-bottom:16px;
                font-size: 12px;
            }

            hr {
                width: 100%;
                height: 1px;
                background-color: #94a3b8;
                opacity: .2;
            }
        }

        &.aside-off {
            padding: 0px 16px;
        }
    }

        img {
            margin: 16px auto;
            display: block;
        }


</style>