<script>
  
    import { blur, dispositions_enabled } from '../store.js';
    import { sidebar } from '../store.js';
    import { fade, scale } from 'svelte/transition';

    const jq = window.$;

    let blur_enabled, _dispositions_enabled = false;

    dispositions_enabled.subscribe(value => {
      _dispositions_enabled = value;
  

      if (!value) {
        blur_enabled = value;
      }
    });

    blur.subscribe(value => {
      

      blur_enabled = value;
		  
	  });

    let hide_elements_during_blur = ['sidebar-icon', 'shortcuts-icon', 'logo', 'appartments-icon',  'cart-icon', 'attributes', 'screenshot-icon'];

    $: {
      blur_enabled;

      switch (blur_enabled) {
        case true : 
          sidebar.update(n => false);
          
          for (let index = 0; index < hide_elements_during_blur.length; index++) {
            
            let element = document.getElementById(hide_elements_during_blur[index]);
            
            if ( element != null ) {
              element.classList.add("hide-blur");
            }
            
            
          }

          
          jq("#chat-application").addClass("hidden");
          
        break;

        default : 
        // hotspots.update(n => true);
        for (let index = 0; index < hide_elements_during_blur.length; index++) {
            let element = document.getElementById(hide_elements_during_blur[index]);
            if (element != null) {
              element.classList.remove("hide-blur");
            }
          }

          jq("#chat-application").removeClass("hidden");
        break;
      }
    }

</script>
<div id="woow-blur">
   {#if blur_enabled || _dispositions_enabled}
     <!-- <div id="blur" class="chuj" transition:fade/> -->
   {/if}
</div>

<style lang="scss">
  @import "../assets/scss/themes.scss";
  @import "../assets/scss/fonts.scss";
  @import "../assets/scss/mixins.scss";
  @import "../assets/scss/variables.scss";

  #woow-blur{
  #blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 63, 70, 0.2);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 1;
  }
}
</style>