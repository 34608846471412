<script>
    import {get, writable} from 'svelte/store';
    export let settings, components;
    export let userdata;

    import { t, locale, locales } from "../i18n.js";
    let layers_hotspot_to_delete = [];
    let floorplan_to_delete = [];
    let shortcuts_to_delete = [];

    let global_menu_main_color = '0x4F46E5';
    let global_menu_main_color_hex = '#4F46E5';
    let global_menu_background_icon_color = "";
    let global_menu_icon_bg_border = "2.0 " + global_menu_main_color+ " 1.0";
    let global_menu_text_bg_color = "0xDDDCE4";
    let global_menu_text_css = "color:" + global_menu_main_color_hex + "; text-align:center;font-size:14px;"
    let global_menu_text_css_normal = "color:#3F3F46; text-align:center;font-size:14px;"

    let show_back_button = false;
    let get_ath = false;
    let tmp = userdata;
    tmp = settings;
    let krpano;

    let _configuration, _config_products, _config_product_variants, _all_products, _config_product_measures_units = [];

    import { vr_mode, loaded_krpano, infopanel, infopanelData, videopanel, videopanelData, dizajn, configuration, config_products, config_product_variants, config_product_measures_units, all_products, cart_price, global_project_settings, vr_product_enabled, active_product_id, check_ath, tmp_attribute_from_model, fl_settings, fl_pins, active_scene, shortcuts_data, all_scenes, vr_safe_data, hotspots, permanently_hotspots } from "../store.js";
    import { add_attribute, current_component } from 'svelte/internal';
    let _vrMode, ath, atv, _infopanelData, _videopanelData, project_settings, _floorplan_settings, _floorplan_pins, shortcuts, scenes;

    let opened_floorlan, _current_scene, opened_shortctus = false;

    all_scenes.subscribe(value => {
        scenes = value;
    
    });

    active_scene.subscribe(value => {
        _current_scene = value;

        if (opened_floorlan) {
            set_pagination = true;
            remove_floorplan();
            add_floorplan();
        };

        if (opened_shortctus) {
            enable_shortcut_pagination = true;
            // remove_shortcuts();
            // add_shortcuts();
        }

    });

    fl_settings.subscribe(value => {
        _floorplan_settings = value;
    
    });

    shortcuts_data.subscribe(value => {
        shortcuts = value;
    });

    fl_pins.subscribe(value => {
        _floorplan_pins = value;
        
    });

    let total_price = 0;
    check_ath.subscribe(value => {
        get_ath = value;
        if (value) {
            get_ath_atv();
        }
        
    });

    cart_price.subscribe(value => {
        total_price = value;
    });

    let _active_product_id;

    active_product_id.subscribe(value => {
        remove_floorplan();
        remove_shortcuts();
        if (_all_products != undefined && _all_products[0] != undefined &&_vrMode)  {
            switch (value) {
                case -1:

                    remove_product_detail();
                break;
            
                default:
                    get_ath = false;
                    add_product_detail();
                break;
            }
        }
        
    });


    vr_product_enabled.subscribe(value => {
       switch (value) {
        case true:
            //remove_product_detail();
            get_ath = true;
            add_product_detail();
            break;
       
        default:
            remove_product_detail();
            check_ath.update(n => false);
            break;
       }
    });

    
    all_products.subscribe(value => {
        if (value != undefined) {
            _all_products = value;  
        }    
    });

    global_project_settings.subscribe(value => {
        if (value != undefined) {
            project_settings = value;
        }    
    });
    
    // načítanie konfigurácie produktov 
    configuration.subscribe(value => {
        if (value != undefined) { _configuration = value; }    
    });

    // načítanie produktov
    config_products.subscribe(value => {
        _config_products = value;
    

        if (value != undefined) {
        
        //console.log(_config_products);
        }    
    });

    // načítanie produktových variantov
    config_product_variants.subscribe(value => {
        _config_product_variants = value;
        
        if (value != undefined) {
        
        
        sort(_config_product_variants, "variant_order");
        }    
    });

    config_product_measures_units.subscribe(value => {
        
        
        if (value != undefined) {
            _config_product_measures_units = value;
        }
    });
  
    // sortovanie produktov
    function sort(data, key) {
        data.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
        data = data;
    }

    loaded_krpano.subscribe((value) => {
        if (
            value == true
        ) {
            krpano = document.getElementById("krpanoSWFObject");
        }
    });




    vr_mode.subscribe((value) => {
        
        krpano = document.getElementById("krpanoSWFObject");
        _vrMode = value;
        switch (value) {
        case true:
            add_vr_menu();       
            get_ath_atv();      
            break;
            
        case false:
            vr_product_enabled.update(n => false);
            remove_vr_menu();
            remove_global_menu();
            remove_alert_exit();
            remove_global_menu_shortcuts();
            remove_global_menu_floorplan();
            //remove_global_menu_attributes();
            remove_global_menu_shop();
            remove_global_menu_layers();
            remove_global_menu_model();
            remove_global_menu_info();
            remove_global_menu_settings();
            remove_settings_item_settings();
            remove_settings_item_layers();
            remove_submenu_settings_items();
            remove_submenu_layers_items();
            remove_submenu_global_info();
            remove_submenu_shortcuts();
            removeInfoPanel();
            //remove_attributes_elements();
            remove_attributes_back_button();
            remove_attributes_detail_back_button();
            remove_basket();
            remove_floorplan();
            remove_shortcuts();
            remove_alert_exit();
            remove_floorplan();
            break;
        }
    });

    infopanel.subscribe((value) => {
        if (value == true) { 
            remove_global_menu();
            createInfoPanel();
            
        } else {
            removeInfoPanel();
        }
    });

    videopanel.subscribe((value) => {
        if (value == true) {
            remove_global_menu();
            createVideoPanel();
            
        } else {
            removeVideoPanel();
        }
    });

    infopanelData.subscribe((value) => {
        _infopanelData = value;
    });

    videopanelData.subscribe((value) => {
        _videopanelData = value;
        //////console.log(value);
    }); 

    let _dizajn;

    dizajn.subscribe((value) => {
        _dizajn = value;
    });


    /* FUNKCIE VR */

    // Infopanely
    function createInfoPanel() {
        ////console.log("pridávam infopanel");

        setTimeout(() => {
                    
        if (krpano) {
            get_ath_atv();
            ////console.log(_infopanelData);
            krpano.call("addhotspot(infopanel-bg);");
            krpano.set("hotspot[infopanel-bg].type", "text");
            krpano.set("hotspot[infopanel-bg].ath", ath);
            krpano.set("hotspot[infopanel-bg].atv", 0);
            krpano.set("hotspot[infopanel-bg].width", "600px");
            krpano.set("hotspot[infopanel-bg].height", "373px");
            krpano.set("hotspot[infopanel-bg].distorted", "true");
            krpano.set("hotspot[infopanel-bg].bgcolor", "0xfafafa");
            krpano.set("hotspot[infopanel-bg].alpha", "1.0");
            krpano.set("hotspot[infopanel-bg].bgroundedge", "12");
            krpano.set("hotspot[infopanel-bg].cursor", "default");
            krpano.set("hotspot[infopanel-bg].visible", "true");
            krpano.set("hotspot[infopanel-bg].enabled", false);
            krpano.set("hotspot[infopanel-bg].zorder", 900);

            //////////console.log(_infopanelData.type);

            switch (_infopanelData.type) {
                case "infopanel-v1":
                    //////////console.log("infopanel v1");
                    createInfopanelTitle();
                    createInfopanelHeadline();
                    
                    createInfopanelContent();

                    switch (_infopanelData.data.img) {
                        case "img/pozvanka.jpg":
                        case "img/sofer.jpg":
                        case "img/To_je_on_plagat.jpeg":
                            createInfopanelImage("a4");
                            break;
                    
                        default:
                            createInfopanelImage("left");
                            break;
                    }
                    break;
            
                case "infopanel-v3":
                    createInfopanelTitle();
                    createInfopanelImage("center");
                    break;
                default:
                    break;
            }

            add_infopanel_close_button();
            //krpano.call("hotspot[menu].onclick()");
              
            
        }            
        }, 50);


    }

    function createInfopanelTitle() {
        krpano.call("addhotspot(infopanel-title);");
        krpano.set("hotspot[infopanel-title].type", "text");
        krpano.set("hotspot[infopanel-title].ath", ath);
        krpano.set("hotspot[infopanel-title].atv", "0");
        krpano.set("hotspot[infopanel-title].html", _infopanelData.title);
        krpano.set("hotspot[infopanel-title].ox", "0px");
        krpano.set("hotspot[infopanel-title].oy", "-168px");
        krpano.set("hotspot[infopanel-title].alpha", "1.0");
        krpano.set("hotspot[infopanel-title].visible", "true");
        krpano.set("hotspot[infopanel-title].keep", "true");
        krpano.set("hotspot[infopanel-title].zorder", "1000");
        krpano.set("hotspot[infopanel-title].distorted", "true");
        krpano.set("hotspot[infopanel-title].width", "500px");
        krpano.set("hotspot[infopanel-title].height", "40px");
        krpano.set("hotspot[infopanel-title].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[infopanel-title].bgalpha", "0");
        krpano.set("hotspot[infopanel-title].vcenter", "true");
        krpano.set("hotspot[infopanel-title].cursor", "default");
        krpano.set("hotspot[infopanel-title].scale", "0.66");
        krpano.set("hotspot[infopanel-title].enabled", false);
        krpano.set("hotspot[infopanel-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    }

    function createInfopanelHeadline() {
        krpano.call("addhotspot(infopanel-headline);");
        krpano.set("hotspot[infopanel-headline].type", "text");
        krpano.set("hotspot[infopanel-headline].ath", ath);
        krpano.set("hotspot[infopanel-headline].atv", "0");
        krpano.set("hotspot[infopanel-headline].html", _infopanelData.data.headline);
        krpano.set("hotspot[infopanel-headline].ox", "0px");
        krpano.set("hotspot[infopanel-headline].oy", "-140px");
        krpano.set("hotspot[infopanel-headline].alpha", "1.0");
        krpano.set("hotspot[infopanel-headline].visible", "true");
        krpano.set("hotspot[infopanel-headline].keep", "true");
        krpano.set("hotspot[infopanel-headline].zorder", "1000");
        krpano.set("hotspot[infopanel-headline].distorted", "true");
        krpano.set("hotspot[infopanel-headline].width", "750px");
        
        krpano.set("hotspot[infopanel-headline].height", "60px");
        krpano.set("hotspot[infopanel-headline].css", "color:#FAFAFA; text-align:left;font-size:12px;");
        krpano.set("hotspot[infopanel-headline].bgalpha", "0");
        krpano.set("hotspot[infopanel-headline].vcenter", "true");
        krpano.set("hotspot[infopanel-headline].cursor", "default");
        krpano.set("hotspot[infopanel-headline].scale", "0.66");
        krpano.set("hotspot[infopanel-headline].title", false);
        krpano.set("hotspot[infopanel-headline].css", "color:#3F3F46; text-align:center;font-size:16px;color:#3f3f46;font-weight:500;");
    }

    function createInfopanelImage($type) {
        krpano.call("addhotspot(infopanel-image);");
        krpano.set("hotspot[infopanel-image].type", "image");
        krpano.set("hotspot[infopanel-image].ath", ath);
        krpano.set("hotspot[infopanel-image].atv", "0");
        
        krpano.set("hotspot[infopanel-image].height", "prop");
        krpano.set("hotspot[infopanel-image].zorder", 1000);
        krpano.set("hotspot[infopanel-image].distorted", true);
        krpano.set("hotspot[infopanel-image].enabled", false);
        krpano.set("hotspot[infopanel-image].url", "assets/" + _infopanelData.data.img);

        //////////console.log($type);
        switch ($type) {
            case "left":
                krpano.set("hotspot[infopanel-image].ox", "-280px");
                krpano.set("hotspot[infopanel-image].oy", "-100");
                krpano.set("hotspot[infopanel-image].width", "250px");
                krpano.set("hotspot[infopanel-image].edge", "lefttop");
                break;

            case "center":
                krpano.set("hotspot[infopanel-image].ox", "0px");
                krpano.set("hotspot[infopanel-image].oy", "10px");
                krpano.set("hotspot[infopanel-image].height", "320px");
                krpano.set("hotspot[infopanel-image].width", "prop");
                break;

            case "a4":
                krpano.set("hotspot[infopanel-image].ox", "-250px");
                krpano.set("hotspot[infopanel-image].oy", "-100px");
                krpano.set("hotspot[infopanel-image].height", "prop");
                krpano.set("hotspot[infopanel-image].width", "180px");
                krpano.set("hotspot[infopanel-image].edge", "lefttop");
                break;                
        
            default:
                break;
        }
    }

    function createInfopanelContent() {
        krpano.call("addhotspot(infopanel-content);");
        krpano.set("hotspot[infopanel-content].type", "text");
        krpano.set("hotspot[infopanel-content].ath", ath);
        krpano.set("hotspot[infopanel-content].atv", "0");
        krpano.set("hotspot[infopanel-content].html", _infopanelData.data.content);
        krpano.set("hotspot[infopanel-content].ox", "-10px");
        krpano.set("hotspot[infopanel-content].oy", "-100px");
        krpano.set("hotspot[infopanel-content].alpha", "1.0");
        krpano.set("hotspot[infopanel-content].visible", "true");
        krpano.set("hotspot[infopanel-content].keep", "true");
        krpano.set("hotspot[infopanel-content].zorder", "1000");
        krpano.set("hotspot[infopanel-content].distorted", "true");
        krpano.set("hotspot[infopanel-content].width", "440px");
        krpano.set("hotspot[infopanel-content].height", "410px");
        krpano.set("hotspot[infopanel-content].css", "color:#FAFAFA; text-align:left;font-size:12px;");
        krpano.set("hotspot[infopanel-content].bgalpha", "0");
        krpano.set("hotspot[infopanel-content].vcenter", false);
        krpano.set("hotspot[infopanel-content].enabled", false);
        krpano.set("hotspot[infopanel-content].cursor", "default");
        krpano.set("hotspot[infopanel-content].scale", "0.66");
        krpano.set("hotspot[infopanel-content].edge", "lefttop");
        krpano.set("hotspot[infopanel-content].css", "color:#3F3F46; text-align:left;font-size:14px;color:#3f3f46;font-weight:100;");
    }

    function removeInfoPanel() {
        if (krpano) {
            //////////console.log("odstranujem infopanel");
            krpano.call("removehotspot(infopanel-bg)");
            krpano.call("removehotspot(infopanel-title)");
            krpano.call("removehotspot(infopanel_close_button)");
            krpano.call("removehotspot(infopanel-headline)");
            krpano.call("removehotspot(infopanel-image)");
            krpano.call("removehotspot(infopanel-content)");
            

            infopanel.update(n=> false);
            //infopanelData.update(n=> {});
        }
        

    }

    function add_infopanel_close_button() {
        if (krpano) {
            krpano.call("addhotspot(infopanel_close_button);");
            krpano.set("hotspot[infopanel_close_button].type", "image");
            krpano.set("hotspot[infopanel_close_button].ath", ath);
            krpano.set("hotspot[infopanel_close_button].atv", 0);
            krpano.set("hotspot[infopanel_close_button].width", "21px");
            krpano.set("hotspot[infopanel_close_button].height", "21px");
            krpano.set("hotspot[infopanel_close_button].url", userdata["cdn_path"] + "images/close.svg");
            krpano.set("hotspot[infopanel_close_button].ox", "281px");
            krpano.set("hotspot[infopanel_close_button].oy", "-168px");
            krpano.set("hotspot[infopanel_close_button].alpha", "1.0");
            krpano.set("hotspot[infopanel_close_button].visible", "true");
            krpano.set("hotspot[infopanel_close_button].keep", "true");
            krpano.set("hotspot[infopanel_close_button].zorder", 1000);
            krpano.set("hotspot[infopanel_close_button].distorted", "true");

            var hs = krpano.get("hotspot[infopanel_close_button]");
			hs.onclick = function() {
                removeInfoPanel();
            }

            
        }
    }

    // Videopanely
    function createVideopanelTitle() {
        //////////console.log("pridávam title : " + _videopanelData.title);
        krpano.call("addhotspot(videopanel-title);");
        krpano.set("hotspot[videopanel-title].type", "text");
        krpano.set("hotspot[videopanel-title].ath", ath);
        krpano.set("hotspot[videopanel-title].atv", "0");
        krpano.set("hotspot[videopanel-title].html", _videopanelData.title);
        krpano.set("hotspot[videopanel-title].ox", "0px");
        krpano.set("hotspot[videopanel-title].oy", "-168px");
        krpano.set("hotspot[videopanel-title].alpha", "1.0");
        krpano.set("hotspot[videopanel-title].visible", "true");
        krpano.set("hotspot[videopanel-title].keep", "true");
        krpano.set("hotspot[videopanel-title].zorder", "1000");
        krpano.set("hotspot[videopanel-title].distorted", "true");
        krpano.set("hotspot[videopanel-title].width", "500px");
        krpano.set("hotspot[videopanel-title].height", "40px");
        krpano.set("hotspot[videopanel-title].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[videopanel-title].bgalpha", "0");
        krpano.set("hotspot[videopanel-title].vcenter", "true");
        krpano.set("hotspot[videopanel-title].cursor", "default");
        krpano.set("hotspot[videopanel-title].scale", "0.66");
        krpano.set("hotspot[videopanel-title].enabled", false);
        krpano.set("hotspot[videopanel-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    }

    function createVideoHts() {
        
        if (krpano != "undefined") {
            get_ath_atv();
            //////console.log("pridávam video do videopanelu");
            krpano.call("addhotspot(video);");
            krpano.set("hotspot[video].url", "assets/krpano/plugins/videoplayer.js");
            krpano.set("hotspot[video].videourl", "assets/" + _videopanelData.data.local_video_url);
            krpano.set("hotspot[video].ath", ath);
            krpano.set("hotspot[video].atv", "0");
            krpano.set("hotspot[video].width", "prop");
            krpano.set("hotspot[video].height", "320px");
            krpano.set("hotspot[video].pausedonstart", "false");
            krpano.set("hotspot[video].visible", true);
            krpano.set("hotspot[video].posterurl", "scenes/synagoga_12_u.jpg");
            krpano.set("hotspot[video].distorted", true);
            krpano.set("hotspot[video].scale", 1.0);
            krpano.set("hotspot[video].rx", "0.0");
            krpano.set("hotspot[video].ry", "0.0");
            krpano.set("hotspot[video].rz", "0.0");
            krpano.set("hotspot[video].ox", "0");
            krpano.set("hotspot[video].oy", "10");
            krpano.set("hotspot[video].loop", "true");
            krpano.set("hotspot[video].volume", "1.0");
            krpano.set("hotspot[video].directionalsound", true);
            krpano.set("hotspot[video].range", "90");
            krpano.set("hotspot[video].rangefalloff", "1.0");
            krpano.set("hotspot[video].outofrangevolume", "0.0");
            krpano.set("hotspot[video].zorder", 1200);
        }
    }

    function createVideoPanel() {
        //////////console.log("pridávam videoPanel");

        setTimeout(() => {
                 if (krpano) {
            get_ath_atv();
            //////////console.log(_videopanelData);
            krpano.call("addhotspot(videopanel-bg);");
            krpano.set("hotspot[videopanel-bg].type", "text");
            krpano.set("hotspot[videopanel-bg].ath", ath);
            krpano.set("hotspot[videopanel-bg].atv", 0);
            krpano.set("hotspot[videopanel-bg].width", "600px");
            krpano.set("hotspot[videopanel-bg].height", "373px");
            krpano.set("hotspot[videopanel-bg].distorted", "true");
            krpano.set("hotspot[videopanel-bg].bgcolor", "0xfafafa");
            krpano.set("hotspot[videopanel-bg].alpha", "1.0");
            krpano.set("hotspot[videopanel-bg].bgroundedge", "12");
            krpano.set("hotspot[videopanel-bg].cursor", "default");
            krpano.set("hotspot[videopanel-bg].visible", "true");
            krpano.set("hotspot[videopanel-bg].enabled", false);
            krpano.set("hotspot[videopanel-bg].zorder", 900);

            //////////console.log(_videopanelData.type);

            switch (_videopanelData.type) {
                case "videopanel-v2":
                    //////////console.log("infopanel v1");
                    createVideopanelTitle();
                    createVideoHts();
                    break;
            
                default:
                    createVideoHts();
                    break;
            }

            add_videopanel_close_button();
        }
        }, 50);
        
   

    }

    function removeVideoPanel() {
        if (krpano) {
            //////////console.log("odstranujem videopanel");
            krpano.call("removehotspot(videopanel-bg)");
            krpano.call("removehotspot(videopanel-title)");
            krpano.call("removehotspot(videopanel_close_button)");
            krpano.call("removehotspot(video)");
            videopanel.update(n=> false);
            videopanelData.update(n=> {});
        }
    }

    function add_videopanel_close_button() {
        if (krpano) {
            krpano.call("addhotspot(videopanel_close_button);");
            krpano.set("hotspot[videopanel_close_button].type", "image");
            krpano.set("hotspot[videopanel_close_button].ath", ath);
            krpano.set("hotspot[videopanel_close_button].atv", 0);
            krpano.set("hotspot[videopanel_close_button].width", "21px");
            krpano.set("hotspot[videopanel_close_button].height", "21px");
            krpano.set("hotspot[videopanel_close_button].url", userdata["cdn_path"] + "images/close.svg");
            krpano.set("hotspot[videopanel_close_button].ox", "281px");
            krpano.set("hotspot[videopanel_close_button].oy", "-168px");
            krpano.set("hotspot[videopanel_close_button].alpha", "1.0");
            krpano.set("hotspot[videopanel_close_button].visible", "true");
            krpano.set("hotspot[videopanel_close_button].keep", "true");
            krpano.set("hotspot[videopanel_close_button].zorder", 1000);
            krpano.set("hotspot[videopanel_close_button].distorted", "true");

            var hs = krpano.get("hotspot[videopanel_close_button]");
			hs.onclick = function() {
                removeVideoPanel();
            }

            
        }
    }

    // Zistenie aktuálnej polohy
    function get_ath_atv() {
        if (krpano) {
            ath = krpano.get("view.hlookat");
            atv = krpano.get("view.vlookat")
        }
    }

    // FUNCKIE PRE JEDNOTLIVÉ KOMPONENTY VR

    /* VR menu - footer */
    function add_vr_menu() {
        //console.log("pridávam vr menu");        
        krpano.call("addhotspot(menu);");
        krpano.set("hotspot[menu].ath", 0);
        krpano.set("hotspot[menu].atv", 50);
        krpano.set("hotspot[menu].url", userdata["cdn_path"] + "images/menu.svg");
        krpano.set("hotspot[menu].zorder", 9900);
        krpano.set("hotspot[menu].keep", "true");
        krpano.set("hotspot[menu].onclick", "js(toggle_global_menu();)"); // js(toggle_menu(true););"
        krpano.set("hotspot[menu].onhover", "js(menu_icon(true););");
        krpano.set("hotspot[menu].onout", "js(menu_icon(false););");
        krpano.set("hotspot[menu].visible", true);
        krpano.set("hotspot[menu].alpha", "1.0");

        var hs = krpano.get("hotspot[menu]");
        hs.onclick = function() {
            get_ath = true;
            removeInfoPanel();
            removeVideoPanel();
            remove_settings_item_settings();
            remove_settings_item_layers();
            remove_submenu_layers_items();
            remove_submenu_settings_items();
            add_global_menu();
            remove_product_detail();
            remove_basket();
            remove_floorplan();
            remove_shortcuts();

            //krpano.set("hotspot[menu].onclick", "js(toggle_global_menu();)"); // js(toggle_menu(true););"
            //krpano.call("hotspot[menu].onclick()");
            
        }

        // krpano.set("hotspot[menu].onclick", "js(toggle_global_menu();)"); // js(toggle_menu(true););"
        // krpano.call("hotspot[menu].onclick()");
        //krpano.call("hotspot[global_menu_close_button].onclick()");
        
        
    }
    function remove_vr_menu() {
        if (krpano) {
            // krpano.set("hotspot[menu].visible", false);
            // krpano.set("hotspot[menu].alpha", "0.0");
            krpano.call("removehotspot(menu)");

        }
    }

    /* GLOBAL VR MENU */
    function add_global_menu() {
        if (krpano) {
            if (get_ath) {
                get_ath_atv();
            }
            

            //add_product_detail();
            

            //////////console.log("zapínam VR menu");
            krpano.call("addhotspot(global_menu-bg);");
            krpano.set("hotspot[global_menu-bg].type", "text");
            krpano.set("hotspot[global_menu-bg].ath", ath);
            krpano.set("hotspot[global_menu-bg].atv", 0);
            krpano.set("hotspot[global_menu-bg].width", "900");
            krpano.set("hotspot[global_menu-bg].height", "560px");
            krpano.set("hotspot[global_menu-bg].scale", "0.66");
            krpano.set("hotspot[global_menu-bg].distorted", "true");
            krpano.set("hotspot[global_menu-bg].bgcolor", "0xfafafa");
            krpano.set("hotspot[global_menu-bg].alpha", "0.6");
            krpano.set("hotspot[global_menu-bg].bgroundedge", "12");
            krpano.set("hotspot[global_menu-bg].cursor", "default");
            krpano.set("hotspot[global_menu-bg].visible", true);
            krpano.set("hotspot[global_menu-bg].keep", "true");
            krpano.set("hotspot[global_menu-bg].zorder", 900);
            krpano.set("hotspot[global_menu-bg].enabled", false);
            krpano.set("hotspot[global_menu-bg].bgborder", "2.0 0x3F3F46 0.2");

            krpano.call("addhotspot(global_menu-title);");
            krpano.set("hotspot[global_menu-title].type", "text");
            krpano.set("hotspot[global_menu-title].ath", ath);
            krpano.set("hotspot[global_menu-title].atv", "0");
            krpano.set("hotspot[global_menu-title].html", "Menu");
            krpano.set("hotspot[global_menu-title].ox", "0px");
            krpano.set("hotspot[global_menu-title].oy", "-168px");
            krpano.set("hotspot[global_menu-title].alpha", "1.0");
            krpano.set("hotspot[global_menu-title].visible", true);
            krpano.set("hotspot[global_menu-title].keep", "true");
            krpano.set("hotspot[global_menu-title].zorder", "1000");
            krpano.set("hotspot[global_menu-title].distorted", "true");
            krpano.set("hotspot[global_menu-title].width", "500px");
            krpano.set("hotspot[global_menu-title].height", "40px");
            krpano.set("hotspot[global_menu-title].bgalpha", "0");
            krpano.set("hotspot[global_menu-title].vcenter", "true");
            krpano.set("hotspot[global_menu-title].cursor", "default");
            krpano.set("hotspot[global_menu-title].scale", "0.66");
            krpano.set("hotspot[global_menu-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
            krpano.set("hotspot[global_menu-title].enabled", false);

            add_global_menu_close_button();
            add_global_menu_exit_vr_button();
            add_global_menu_floorplan();
            add_global_menu_shop();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_info();
            add_global_menu_settings();
            add_global_menu_attributes();
            add_global_menu_shortcuts();
        //add_alert_exit();
        }
    }
    function remove_global_menu() {
        //console.log("odoberám VR menu");
        

            let elements = ['global_menu-bg', 'global_menu-title', 'exit-vr-button', 'exit-vr-text', 'global_menu_close_button']
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }

            remove_global_menu_all_items();
            remove_settings_item_settings();
            remove_submenu_settings_items();
            remove_attributes_elements();
            //remove_submenu_global_info();
            //remove_submenu_shortcuts();
            remove_attributes_back_button();
            remove_attributes_detail_back_button();
            
            
        
    }

    /* GLOBAL VR MENU ITEMS */
    function add_global_menu_close_button() {
        if (krpano) {
            krpano.call("addhotspot(global_menu_close_button);");
            krpano.set("hotspot[global_menu_close_button].type", "image");
            krpano.set("hotspot[global_menu_close_button].ath", ath);
            krpano.set("hotspot[global_menu_close_button].atv", 0);
            krpano.set("hotspot[global_menu_close_button].width", "21px");
            krpano.set("hotspot[global_menu_close_button].height", "21px");
            krpano.set("hotspot[global_menu_close_button].url", userdata["cdn_path"] + "images/close.svg");
            krpano.set("hotspot[global_menu_close_button].ox", "281px");
            krpano.set("hotspot[global_menu_close_button].oy", "-168px");
            krpano.set("hotspot[global_menu_close_button].alpha", "1.0");
            krpano.set("hotspot[global_menu_close_button].visible", true);
            krpano.set("hotspot[global_menu_close_button].keep", "true");
            //krpano.set("hotspot[global_menu_close_button].onclick", "js(toggle_global_menu(););");
            krpano.set("hotspot[global_menu_close_button].zorder", 1000);
            krpano.set("hotspot[global_menu_close_button].distorted", "true");

            var hs = krpano.get("hotspot[global_menu_close_button]");
			hs.onclick = function() {
                remove_global_menu();
            }
        }
    }

    /* GLOBAL MENU EXIT VR BUTTON */
    function add_global_menu_exit_vr_button() {
        // Exit VR button - image
        krpano.call("addhotspot(exit-vr-button);");
        krpano.set("hotspot[exit-vr-button].type", "image");
        krpano.set("hotspot[exit-vr-button].ath", ath);
        krpano.set("hotspot[exit-vr-button].atv", 0);
        krpano.set("hotspot[exit-vr-button].url", userdata["cdn_path"] + "images/exit-vr.svg");
        krpano.set("hotspot[exit-vr-button].ox", "-255px");
        krpano.set("hotspot[exit-vr-button].oy", "-168px");
        krpano.set("hotspot[exit-vr-button].alpha", "1.0");
        krpano.set("hotspot[exit-vr-button].visible", "true");
        krpano.set("hotspot[exit-vr-button].keep", "true");
        //krpano.set("hotspot[exit-vr-button].onclick", "js(leave_vr(););");
        krpano.set("hotspot[exit-vr-button].zorder", 1000);
        krpano.set("hotspot[exit-vr-button].scale", 0.66);
        krpano.set("hotspot[exit-vr-button].distorted", "true");

        let hs_exit = krpano.get("hotspot[exit-vr-button]");
        hs_exit.onclick = function() {
            //////////console.log("v1");
            //krpano.call("webvr.exitVR()");
            add_alert_exit();
        }

        // Exit VR button - text
        krpano.call("addhotspot(exit-vr-text);");
        krpano.set("hotspot[exit-vr-text].type", "text");
        krpano.set("hotspot[exit-vr-text].ath", ath);
        krpano.set("hotspot[exit-vr-text].atv", 0);
        krpano.set("hotspot[exit-vr-text].html", $t("components")['vr']['exit']);
        krpano.set("hotspot[exit-vr-text].width", "70px");
        krpano.set("hotspot[exit-vr-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[exit-vr-text].ox", "-241px");
        krpano.set("hotspot[exit-vr-text].oy", "-175px");
        krpano.set("hotspot[exit-vr-text].alpha", "1.0");
        krpano.set("hotspot[exit-vr-text].bgalpha", "0.0");
        krpano.set("hotspot[exit-vr-text].edge", "top");
        krpano.set("hotspot[exit-vr-text].visible", "true");
        krpano.set("hotspot[exit-vr-text].keep", "true");
        //krpano.set("hotspot[exit-vr-text].onclick", "js(leave_vr(););");
        krpano.set("hotspot[exit-vr-text].zorder", 1000);
        krpano.set("hotspot[exit-vr-text].scale", "0.66");
        krpano.set("hotspot[exit-vr-text].distorted", "true");

        let hs_exit_text = krpano.get("hotspot[exit-vr-text]");
        hs_exit_text.onclick = function() {
            //////////console.log("v2");
            //krpano.call("webvr.exitVR()");
            add_alert_exit();
        }
    }

    /*  CONFIRM LEAVE VR MODE */
    function add_alert_exit() {
        remove_global_menu();
        get_ath_atv();
        krpano.call("addhotspot(alert-exit-bg);");
        krpano.set("hotspot[alert-exit-bg].type", "text");
        krpano.set("hotspot[alert-exit-bg].ath", ath);
        krpano.set("hotspot[alert-exit-bg].atv", "0");
        krpano.set("hotspot[alert-exit-bg].alpha", "1.0");
        krpano.set("hotspot[alert-exit-bg].visible", "true");
        krpano.set("hotspot[alert-exit-bg].keep", "true");
        krpano.set("hotspot[alert-exit-bg].enabled", false);
        krpano.set("hotspot[alert-exit-bg].zorder", "1000");
        krpano.set("hotspot[alert-exit-bg].width", "300px");
        krpano.set("hotspot[alert-exit-bg].height", "113px");
        krpano.set("hotspot[alert-exit-bg].distorted", "true");
        krpano.set("hotspot[alert-exit-bg].bgcolor", "0xfafafa");
        krpano.set("hotspot[alert-exit-bg].bgroundedge", "12");
        krpano.set("hotspot[alert-exit-bg].cursor", "default");
        krpano.set("hotspot[alert-exit-bg].scale", "0.66");
        krpano.set("hotspot[alert-exit-bg].bgshadow", "0 0 20 0x000000 0.4");


        krpano.call("addhotspot(alert-exit-button1);");
        krpano.set("hotspot[alert-exit-button1].type", "text");
        krpano.set("hotspot[alert-exit-button1].ath", ath);
        krpano.set("hotspot[alert-exit-button1].atv", "0");
        krpano.set("hotspot[alert-exit-button1].html", $t("components")['vr']['exit-vr']);
        krpano.set("hotspot[alert-exit-button1].ox", "-45px");
        krpano.set("hotspot[alert-exit-button1].oy", "13px");
        krpano.set("hotspot[alert-exit-button1].visible", "true");
        //krpano.set("hotspot[alert-exit-button1].onclick", "js(confirm_leave_vr(););");
        krpano.set("hotspot[alert-exit-button1].keep", "true");
        krpano.set("hotspot[alert-exit-button1].zorder", 1100);
        krpano.set("hotspot[alert-exit-button1].width", "120px");
        krpano.set("hotspot[alert-exit-button1].height", "40px");
        krpano.set("hotspot[alert-exit-button1].distorted", "true");
        krpano.set("hotspot[alert-exit-button1].bgcolor", "0x3F3F46");
        krpano.set("hotspot[alert-exit-button1].alpha", "1");
        krpano.set("hotspot[alert-exit-button1].bgroundedge", "6");
        krpano.set("hotspot[alert-exit-button1].vcenter", "true");
        krpano.set("hotspot[alert-exit-button1].scale", "0.66");
        krpano.set("hotspot[alert-exit-button1].css", "color:#FAFAFA; text-align:center; font-size:14px; font-weight:900;");

        let hs_exit_b1 = krpano.get("hotspot[alert-exit-button1]");
        hs_exit_b1.onclick = function() {
            ////////console.log("v1");
            remove_alert_exit();
            krpano.call("webvr.exitVR()");
            remove_alert_exit();
            vr_mode.update(n => false);
        }

        krpano.call("addhotspot(alert-exit-button2);");
        krpano.set("hotspot[alert-exit-button2].type", "text");
        krpano.set("hotspot[alert-exit-button2].ath", ath);
        krpano.set("hotspot[alert-exit-button2].atv", "0");
        krpano.set("hotspot[alert-exit-button2].ox", "45px");
        krpano.set("hotspot[alert-exit-button2].oy", "13px");
        krpano.set("hotspot[alert-exit-button2].html", $t("components")['vr']['no']);
        krpano.set("hotspot[alert-exit-button2].visible", "true");
        //krpano.set("hotspot[alert-exit-button2].onclick", "js(cancel_leave_vr(););");
        krpano.set("hotspot[alert-exit-button2].keep", "true");
        krpano.set("hotspot[alert-exit-button2].zorder", 1100);
        krpano.set("hotspot[alert-exit-button2].width", "120px");
        krpano.set("hotspot[alert-exit-button2].height", "40px");
        krpano.set("hotspot[alert-exit-button2].distorted", "true");
        krpano.set("hotspot[alert-exit-button2].bgcolor", "0x4F46E5");
        krpano.set("hotspot[alert-exit-button2].alpha", "1");
        krpano.set("hotspot[alert-exit-button2].bgroundedge", "6");
        krpano.set("hotspot[alert-exit-button2].vcenter", "true");
        krpano.set("hotspot[alert-exit-button2].scale", "0.66");
        krpano.set("hotspot[alert-exit-button2].css", "color:#FAFAFA; text-align:center; font-size:14px; font-weight:900;");

        let hs_exit_b2 = krpano.get("hotspot[alert-exit-button2]");
        hs_exit_b2.onclick = function() {
            ////////console.log("v2");
            //krpano.call("webvr.exitVR()");
            remove_alert_exit();
        }

        krpano.call("addhotspot(alert-exit-question);");
        krpano.set("hotspot[alert-exit-question].type", "text");
        krpano.set("hotspot[alert-exit-question].ath", ath);
        krpano.set("hotspot[alert-exit-question].atv", "0");
        krpano.set("hotspot[alert-exit-question].enabled", false);
        krpano.set("hotspot[alert-exit-question].html", $t("components")['vr']['exit-vr']);
        krpano.set("hotspot[alert-exit-question].ox", "0px");
        krpano.set("hotspot[alert-exit-question].oy", "-20px");
        krpano.set("hotspot[alert-exit-question].html", $t("components")['vr']['exit-question']);
        krpano.set("hotspot[alert-exit-question].visible", "true");
        krpano.set("hotspot[alert-exit-question].keep", "true");
        krpano.set("hotspot[alert-exit-question].zorder", "1000");
        krpano.set("hotspot[alert-exit-question].width", "268px");
        krpano.set("hotspot[alert-exit-question].height", "21px");
        krpano.set("hotspot[alert-exit-question].distorted", "true");
        krpano.set("hotspot[alert-exit-question].alpha", "1");
        krpano.set("hotspot[alert-exit-question].bgalpha", "0");
        krpano.set("hotspot[alert-exit-question].vcenter", "true");
        krpano.set("hotspot[alert-exit-question].scale", "0.66");
        krpano.set("hotspot[alert-exit-question].css", "color:#3F3F46; text-align:center; font-size:14px;");
    }

    function remove_alert_exit () {
        if (krpano) {
            let elements = ['alert-exit-bg', 'alert-exit-button1', 'alert-exit-button2', 'alert-exit-question']
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA SHORTCUTS  */
    function add_global_menu_shortcuts() {
        // Shortcuts
        krpano.call("addhotspot(shortcuts-bg);");
        krpano.set("hotspot[shortcuts-bg].type", "text");
        krpano.set("hotspot[shortcuts-bg].ath", ath);
        krpano.set("hotspot[shortcuts-bg].atv", 0);
        krpano.set("hotspot[shortcuts-bg].alpha", "1.0");
        krpano.set("hotspot[shortcuts-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[shortcuts-bg].ox", "-184px");
        krpano.set("hotspot[shortcuts-bg].oy", "-68px");
        krpano.set("hotspot[shortcuts-bg].visible", "true");
        krpano.set("hotspot[shortcuts-bg].enabled", "false");
        krpano.set("hotspot[shortcuts-bg].keep", "true");
        krpano.set("hotspot[shortcuts-bg].zorder", 1000);
        krpano.set("hotspot[shortcuts-bg].width", "106px");
        krpano.set("hotspot[shortcuts-bg].height", "120px");
        krpano.set("hotspot[shortcuts-bg].distorted", "true");
        krpano.set("hotspot[shortcuts-bg].bgroundedge", "12");
        krpano.set("hotspot[shortcuts-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(shortcuts);");
        krpano.set("hotspot[shortcuts].type", "image");
        krpano.set("hotspot[shortcuts].ath", ath);
        krpano.set("hotspot[shortcuts].atv", 0);
        krpano.set("hotspot[shortcuts].url", userdata["cdn_path"] + "images/shortcuts.svg");
        krpano.set("hotspot[shortcuts].ox", "-184px");
        krpano.set("hotspot[shortcuts].oy", "-81px");
        krpano.set("hotspot[shortcuts].visible", "true");
        krpano.set("hotspot[shortcuts].enabled", "false");
        krpano.set("hotspot[shortcuts].keep", "true");
        krpano.set("hotspot[shortcuts].width", "30px");
        krpano.set("hotspot[shortcuts].height", "30px");
        krpano.set("hotspot[shortcuts].zorder", "1000");	
        if (shortcuts != "") {
            krpano.set("hotspot[shortcuts].alpha", "1.0");
        } else {
            krpano.set("hotspot[shortcuts].alpha", "0.4");    
        }				

        // text
        krpano.call("addhotspot(shortcuts-text);");
        krpano.set("hotspot[shortcuts-text].type", "text");
        krpano.set("hotspot[shortcuts-text].html", $t("components")['vr']['shortcuts']);
        krpano.set("hotspot[shortcuts-text].ath", ath);
        krpano.set("hotspot[shortcuts-text].atv", 0);
        krpano.set("hotspot[shortcuts-text].ox", "-184px");
        krpano.set("hotspot[shortcuts-text].oy", "-50px");
        krpano.set("hotspot[shortcuts-text].visible", "true");
        krpano.set("hotspot[shortcuts-text].enabled", "false");
        krpano.set("hotspot[shortcuts-text].keep", "true");
        krpano.set("hotspot[shortcuts-text].zorder", "1000");	
        krpano.set("hotspot[shortcuts-text].width", "158px");	
        krpano.set("hotspot[shortcuts-text].distorted", "true");	
        krpano.set("hotspot[shortcuts-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[shortcuts-text].bgalpha", "0");	
        krpano.set("hotspot[shortcuts-text].edge", "true");	
        if (shortcuts != "") {  
            krpano.set("hotspot[shortcuts-text].alpha", "1.0");
        } else {
            krpano.set("hotspot[shortcuts-text].alpha", "0.4");
        }

        if (shortcuts != "") {
            // Emtpy layer
            krpano.call("addhotspot(shortcuts-bg-empty);");
            krpano.set("hotspot[shortcuts-bg-empty].type", "text");
            krpano.set("hotspot[shortcuts-bg-empty].ath", ath);
            krpano.set("hotspot[shortcuts-bg-empty].atv", 0);
            krpano.set("hotspot[shortcuts-bg-empty].width", "106px");
            krpano.set("hotspot[shortcuts-bg-empty].height", "120px");
            krpano.set("hotspot[shortcuts-bg-empty].ox", "-184px");
            krpano.set("hotspot[shortcuts-bg-empty].oy", "-68px");
            krpano.set("hotspot[shortcuts-bg-empty].alpha", "0.0");
            krpano.set("hotspot[shortcuts-bg-empty].visible", "true");
            krpano.set("hotspot[shortcuts-bg-empty].keep", "true");
            krpano.set("hotspot[shortcuts-bg-empty].zorder", "1100");	
            krpano.set("hotspot[shortcuts-bg-empty].bgroundedge", "12");	
            krpano.set("hotspot[shortcuts-bg-empty].distorted", "true");

            var hs_s = krpano.get("hotspot[shortcuts-bg-empty]");
            hs_s.onover = function() {
            global_menu_add_hover("shortcuts");
            }

            hs_s.onout = function() {
            global_menu_remove_hover("shortcuts");
            }

            hs_s.onclick = function() {
                remove_global_menu();
                add_shortcuts();
            }
        }

        

    }

    function remove_global_menu_shortcuts() {
        if (krpano) {
            let elements = ['shortcuts-bg', 'shortcuts', 'shortcuts-text','shortcuts-bg-empty']
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

     /*  GLOBAL MENU IKONA FLOORPLAN  */
     function add_global_menu_floorplan() {
        // Floorplan
        krpano.call("addhotspot(floorplan-bg);");
        krpano.set("hotspot[floorplan-bg].type", "text");
        krpano.set("hotspot[floorplan-bg].ath", ath);
        krpano.set("hotspot[floorplan-bg].atv", 0);
        krpano.set("hotspot[floorplan-bg].alpha", "1.0");
        krpano.set("hotspot[floorplan-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[floorplan-bg].ox", "-61px"); 
        krpano.set("hotspot[floorplan-bg].oy", "-68px");
        krpano.set("hotspot[floorplan-bg].visible", "true");
        krpano.set("hotspot[floorplan-bg].keep", "true");
        krpano.set("hotspot[floorplan-bg].zorder", 1000);
        krpano.set("hotspot[floorplan-bg].width", "106px");
        krpano.set("hotspot[floorplan-bg].height", "120px");
        krpano.set("hotspot[floorplan-bg].distorted", "true");
        krpano.set("hotspot[floorplan-bg].enabled", false);
        krpano.set("hotspot[floorplan-bg].bgroundedge", "12");
        krpano.set("hotspot[floorplan-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(floorplan);");
        krpano.set("hotspot[floorplan].type", "image");
        krpano.set("hotspot[floorplan].ath", ath);
        krpano.set("hotspot[floorplan].atv", 0);
        krpano.set("hotspot[floorplan].url", userdata["cdn_path"] + "images/floorplan.svg");
        krpano.set("hotspot[floorplan].ox", "-61px");
        krpano.set("hotspot[floorplan].oy", "-81px");
        krpano.set("hotspot[floorplan].visible", "true");
        krpano.set("hotspot[floorplan].enabled", false);
        krpano.set("hotspot[floorplan].keep", "true");
        krpano.set("hotspot[floorplan].width", "30px");
        krpano.set("hotspot[floorplan].height", "30px");
        krpano.set("hotspot[floorplan].zorder", "1000");					

        if (_floorplan_pins != "") {
            krpano.set("hotspot[floorplan].alpha", "1.0");
        } else {
            krpano.set("hotspot[floorplan].alpha", "0.6");
        }

        // text
        krpano.call("addhotspot(floorplan-text);");
        krpano.set("hotspot[floorplan-text].type", "text");
        krpano.set("hotspot[floorplan-text].html", $t("components")['vr']['floorplan']);
        krpano.set("hotspot[floorplan-text].ath", ath);
        krpano.set("hotspot[floorplan-text].atv", 0);
        krpano.set("hotspot[floorplan-text].ox", "-61px");
        krpano.set("hotspot[floorplan-text].oy", "-50px");
        krpano.set("hotspot[floorplan-text].alpha", "1.0");
        krpano.set("hotspot[floorplan-text].visible", true);
        krpano.set("hotspot[floorplan-text].enabled", false);
        krpano.set("hotspot[floorplan-text].keep", "true");
        krpano.set("hotspot[floorplan-text].zorder", "1000");	
        krpano.set("hotspot[floorplan-text].width", "105px");	
        krpano.set("hotspot[floorplan-text].distorted", "true");	
        
        krpano.set("hotspot[floorplan-text].bgalpha", "0");	
        krpano.set("hotspot[floorplan-text].edge", "true");	
        krpano.set("hotspot[floorplan-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	

        if (_floorplan_pins != "") {
            krpano.set("hotspot[floorplan-text].alpha", "1.0");
            
        } else {
            krpano.set("hotspot[floorplan-text].alpha", "0.6");
        }


        if (_floorplan_pins != "") {
            // Emtpy layer
            krpano.call("addhotspot(floorplan-bg-empty);");
            krpano.set("hotspot[floorplan-bg-empty].type", "text");
            krpano.set("hotspot[floorplan-bg-empty].ath", ath);
            krpano.set("hotspot[floorplan-bg-empty].atv", 0);
            krpano.set("hotspot[floorplan-bg-empty].width", "106px");
            krpano.set("hotspot[floorplan-bg-empty].height", "120px");
            krpano.set("hotspot[floorplan-bg-empty].ox", "-61px");
            krpano.set("hotspot[floorplan-bg-empty].oy", "-68px");
            krpano.set("hotspot[floorplan-bg-empty].alpha", "0.0");
            krpano.set("hotspot[floorplan-bg-empty].visible", "true");
            krpano.set("hotspot[floorplan-bg-empty].keep", "true");
            krpano.set("hotspot[floorplan-bg-empty].zorder", "1100");	
            krpano.set("hotspot[floorplan-bg-empty].bgroundedge", "12");	
            krpano.set("hotspot[floorplan-bg-empty].distorted", "true");

            var hs_s = krpano.get("hotspot[floorplan-bg-empty]");
            hs_s.onover = function() {
                global_menu_add_hover("floorplan");
            }

            hs_s.onout = function() {
                global_menu_remove_hover("floorplan");
            }

            hs_s.onclick = function() {
                remove_global_menu();
                show_back_button = true;
                get_ath = false;
                add_floorplan();
                
            }
        }

        
    }

    function remove_global_menu_floorplan() {
        if (krpano) {
            let elements = ['floorplan-bg', 'floorplan', 'floorplan-text','floorplan-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IOKNA ATTRIBUTES  */
    function add_global_menu_attributes() {
        // Attributes
        krpano.call("addhotspot(attributes-bg);");
        krpano.set("hotspot[attributes-bg].type", "text");
        krpano.set("hotspot[attributes-bg].ath", ath);
        krpano.set("hotspot[attributes-bg].atv", 0);
        krpano.set("hotspot[attributes-bg].alpha", "1.0");
        krpano.set("hotspot[attributes-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[attributes-bg].ox", "61px"); 
        krpano.set("hotspot[attributes-bg].oy", "-68px");
        krpano.set("hotspot[attributes-bg].visible", "true");
        krpano.set("hotspot[attributes-bg].enabled", "false");
        krpano.set("hotspot[attributes-bg].keep", "true");
        krpano.set("hotspot[attributes-bg].zorder", 1000);
        krpano.set("hotspot[attributes-bg].width", "106px");
        krpano.set("hotspot[attributes-bg].height", "120px");
        krpano.set("hotspot[attributes-bg].distorted", "true");
        krpano.set("hotspot[attributes-bg].bgroundedge", "12");
        krpano.set("hotspot[attributes-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(attributes);");
        krpano.set("hotspot[attributes].type", "image");
        krpano.set("hotspot[attributes].ath", ath);
        krpano.set("hotspot[attributes].atv", 0);
        krpano.set("hotspot[attributes].url", userdata["cdn_path"] + "images/attributes.svg");
        krpano.set("hotspot[attributes].ox", "61px");
        krpano.set("hotspot[attributes].oy", "-81px");

        if (_config_products != undefined) {
            krpano.set("hotspot[attributes].alpha", "1.0");
        } else {
            krpano.set("hotspot[attributes].alpha", "0.4");
        }
        
        krpano.set("hotspot[attributes].visible", "true");
        krpano.set("hotspot[attributes].enabled", "false");
        krpano.set("hotspot[attributes].keep", "true");
        krpano.set("hotspot[attributes].width", "30px");
        krpano.set("hotspot[attributes].height", "30px");
        krpano.set("hotspot[attributes].zorder", "1000");					

        // text
        krpano.call("addhotspot(attributes-text);");
        krpano.set("hotspot[attributes-text].type", "text");
        krpano.set("hotspot[attributes-text].html", $t("components")['vr']['attributes']);
        krpano.set("hotspot[attributes-text].ath", ath);
        krpano.set("hotspot[attributes-text].atv", 0);
        krpano.set("hotspot[attributes-text].ox", "61px");
        krpano.set("hotspot[attributes-text].oy", "-50px");

        if (_config_products != undefined) {
            krpano.set("hotspot[attributes-text].alpha", "1.0");
        } else {
            krpano.set("hotspot[attributes-text].alpha", "0.4");
        }
        
        krpano.set("hotspot[attributes-text].visible", "true");
        krpano.set("hotspot[attributes-text].enabled", "false");
        krpano.set("hotspot[attributes-text].keep", "true");
        krpano.set("hotspot[attributes-text].zorder", "1000");	
        krpano.set("hotspot[attributes-text].width", "105px");	
        krpano.set("hotspot[attributes-text].distorted", "true");	
        krpano.set("hotspot[attributes-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[attributes-text].bgalpha", "0");	
        krpano.set("hotspot[attributes-text].edge", "true");	

        // Emtpy layer
        krpano.call("addhotspot(attributes-bg-empty);");
        krpano.set("hotspot[attributes-bg-empty].type", "text");
        krpano.set("hotspot[attributes-bg-empty].ath", ath);
        krpano.set("hotspot[attributes-bg-empty].atv", 0);
        krpano.set("hotspot[attributes-bg-empty].width", "106px");
        krpano.set("hotspot[attributes-bg-empty].height", "120px");
        krpano.set("hotspot[attributes-bg-empty].ox", "61px");
        krpano.set("hotspot[attributes-bg-empty].oy", "-68px");
        krpano.set("hotspot[attributes-bg-empty].alpha", "0.0");
        krpano.set("hotspot[attributes-bg-empty].visible", "true");
        krpano.set("hotspot[attributes-bg-empty].keep", "true");
        krpano.set("hotspot[attributes-bg-empty].zorder", "1100");	
        krpano.set("hotspot[attributes-bg-empty].bgroundedge", "12");	
        krpano.set("hotspot[attributes-bg-empty].distorted", "true");

        if (_config_products != undefined) {
            // Emtpy layer
            krpano.call("addhotspot(attributes-bg-empty);");
            krpano.set("hotspot[attributes-bg-empty].type", "text");
            krpano.set("hotspot[attributes-bg-empty].ath", ath);
            krpano.set("hotspot[attributes-bg-empty].atv", 0);
            krpano.set("hotspot[attributes-bg-empty].width", "106px");
            krpano.set("hotspot[attributes-bg-empty].height", "120px");
            krpano.set("hotspot[attributes-bg-empty].ox", "61px");
            krpano.set("hotspot[attributes-bg-empty].oy", "-68px");
            krpano.set("hotspot[attributes-bg-empty].alpha", "0.0");
            krpano.set("hotspot[attributes-bg-empty].visible", "true");
            krpano.set("hotspot[attributes-bg-empty].keep", "true");
            krpano.set("hotspot[attributes-bg-empty].zorder", "1100");	
            krpano.set("hotspot[attributes-bg-empty].bgroundedge", "12");	
            krpano.set("hotspot[attributes-bg-empty].distorted", "true");

            var hs_s = krpano.get("hotspot[attributes-bg-empty]");
            hs_s.onover = function() {
            global_menu_add_hover("attributes");
            }

            hs_s.onout = function() {
            global_menu_remove_hover("attributes");
            }

            hs_s.onclick = function() {
                
                //remove_global_menu_all_items();
                //krpano.set("hotspot[global_menu-title].html", $t("components")['settings']['title']);
                //krpano.set("hotspot[global_menu-title].html", "Attributes");
                //add_settings_item_attributes();
                // add_submenu_settings_items();
                //create_attributes_items();
                //add_settings_item_attributes();
                remove_global_menu();
                show_back_button = true;
                get_ath = false;
                add_product_detail();
                
            }

        }

        
        
    }

    function remove_global_menu_attributes() {
        if (krpano) {
            let elements = ['attributes-bg', 'attributes', 'attributes-text','attributes-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    let attributes_pagination = 0;
    let allow_attributes_adding = false;

    function create_attributes_items() {

        // zmazanie šípky späť z detailu produktu
        let elements = ['back-attributes_detail', 'back-attributes-text_detail'];
        for (let index = 0; index < elements.length; index++) {
            krpano.call("removehotspot(" + elements[index] + ")");
        }

        // funkcia na vytvorenie detailu
        function create_detail() {
            
            // button ulozit
            krpano.call("addhotspot(button-save-config);");
            krpano.set("hotspot[button-save-config].type", "text");
            krpano.set("hotspot[button-save-config].ath", ath);
            krpano.set("hotspot[button-save-config].atv", "0");
            krpano.set("hotspot[button-save-config].edge", "rightbottom");
            krpano.set("hotspot[button-save-config].ox", "-5px");
            krpano.set("hotspot[button-save-config].oy", "176px");
            krpano.set("hotspot[button-save-config].html", "Uložiť zmeny");
            krpano.set("hotspot[button-save-config].visible", "true");
            krpano.set("hotspot[button-save-config].keep", "true");
            krpano.set("hotspot[button-save-config].zorder", 1100);
            krpano.set("hotspot[button-save-config].width", "200px");
            krpano.set("hotspot[button-save-config].height", "40px");
            krpano.set("hotspot[button-save-config].distorted", "true");
            krpano.set("hotspot[button-save-config].bgcolor", "0x4F46E5");
            krpano.set("hotspot[button-save-config].alpha", "1");
            krpano.set("hotspot[button-save-config].bgroundedge", "6");
            krpano.set("hotspot[button-save-config].vcenter", "true");
            krpano.set("hotspot[button-save-config].scale", "0.66");
            krpano.set("hotspot[button-save-config].css", "color:#FAFAFA; text-align:center; font-size:14px;");

            // button kosik
            krpano.call("addhotspot(button-checkout);");
            krpano.set("hotspot[button-checkout].type", "text");
            krpano.set("hotspot[button-checkout].ath", ath);
            krpano.set("hotspot[button-checkout].atv", "0");
            krpano.set("hotspot[button-checkout].edge", "leftbottom");
            krpano.set("hotspot[button-checkout].ox", "5px");
            krpano.set("hotspot[button-checkout].oy", "176px");
            krpano.set("hotspot[button-checkout].html", "Checkout");
            krpano.set("hotspot[button-checkout].visible", "true");
            krpano.set("hotspot[button-checkout].keep", "true");
            krpano.set("hotspot[button-checkout].zorder", 1100);
            krpano.set("hotspot[button-checkout].width", "200px");
            krpano.set("hotspot[button-checkout].height", "40px");
            krpano.set("hotspot[button-checkout].distorted", "true");
            krpano.set("hotspot[button-checkout].bgcolor", "0x3F3F46");
            krpano.set("hotspot[button-checkout].alpha", "1");
            krpano.set("hotspot[button-checkout].bgroundedge", "6");
            krpano.set("hotspot[button-checkout].vcenter", "true");
            krpano.set("hotspot[button-checkout].scale", "0.66");
            krpano.set("hotspot[button-checkout].css", "color:#FAFAFA; text-align:center; font-size:14px;");

            // zvazbeny produkt
            krpano.call("addhotspot(bind-product);");
            krpano.set("hotspot[bind-product].type", "text");
            krpano.set("hotspot[bind-product].ath", ath);
            krpano.set("hotspot[bind-product].atv", "0");
            krpano.set("hotspot[bind-product].edge", "bottom");
            krpano.set("hotspot[bind-product].ox", "0");
            krpano.set("hotspot[bind-product].oy", "138px");
            krpano.set("hotspot[bind-product].html", "Obvodová lišta");
            krpano.set("hotspot[bind-product].visible", "true");
            krpano.set("hotspot[bind-product].keep", "true");
            krpano.set("hotspot[bind-product].zorder", 1100);
            krpano.set("hotspot[bind-product].width", "644px");
            krpano.set("hotspot[bind-product].height", "40px");
            krpano.set("hotspot[bind-product].distorted", "true");
            krpano.set("hotspot[bind-product].bgcolor", "0xFAFAFA");
            krpano.set("hotspot[bind-product].alpha", "1");
            krpano.set("hotspot[bind-product].bgroundedge", "6");
            krpano.set("hotspot[bind-product].vcenter", "true");
            krpano.set("hotspot[bind-product].bgborder", "1.0 0x3F3F46 0.2");
            krpano.set("hotspot[bind-product].scale", "0.66");
            krpano.set("hotspot[bind-product].css", "color:#3F3F46; text-align:left; font-size:16px; margin-left:8px; font-weight:500;");

            // aktivny produkt
            krpano.call("addhotspot(active-product);");
            krpano.set("hotspot[active-product].type", "text");
            krpano.set("hotspot[active-product].ath", ath);
            krpano.set("hotspot[active-product].atv", "0");
            krpano.set("hotspot[active-product].edge", "top");
            krpano.set("hotspot[active-product].ox", "0");
            krpano.set("hotspot[active-product].oy", "-149px");
            krpano.set("hotspot[active-product].html", "Podlaha");
            krpano.set("hotspot[active-product].visible", "true");
            krpano.set("hotspot[active-product].keep", "true");
            krpano.set("hotspot[active-product].zorder", 1100);
            krpano.set("hotspot[active-product].width", "644px");
            krpano.set("hotspot[active-product].height", "376px");
            krpano.set("hotspot[active-product].distorted", "true");
            krpano.set("hotspot[active-product].bgcolor", "0xFAFAFA");
            krpano.set("hotspot[active-product].alpha", "1");
            krpano.set("hotspot[active-product].bgroundedge", "6");
            krpano.set("hotspot[active-product].vcenter", "false");
            krpano.set("hotspot[active-product].bgborder", "1.0 0x3F3F46 1.0");
            krpano.set("hotspot[active-product].scale", "0.66");
            krpano.set("hotspot[active-product].css", "color:#3F3F46; text-align:left; font-size:16px; margin-left:8px; margin-top:4px; font-weight:500;");

            // aktivny produkt čiara
            krpano.call("addhotspot(active-product-hr);");
            krpano.set("hotspot[active-product-hr].type", "text");
            krpano.set("hotspot[active-product-hr].ath", ath);
            krpano.set("hotspot[active-product-hr].atv", "0");
            krpano.set("hotspot[active-product-hr].edge", "top");
            krpano.set("hotspot[active-product-hr].ox", "0");
            krpano.set("hotspot[active-product-hr].oy", "-129px");
            krpano.set("hotspot[active-product-hr].visible", "true");
            krpano.set("hotspot[active-product-hr].keep", "true");
            krpano.set("hotspot[active-product-hr].zorder", 1100);
            krpano.set("hotspot[active-product-hr].width", "627px");
            krpano.set("hotspot[active-product-hr].height", "1px");
            krpano.set("hotspot[active-product-hr].distorted", "true");
            krpano.set("hotspot[active-product-hr].bgcolor", "0x3F3F46");
            krpano.set("hotspot[active-product-hr].alpha", "0.2");
            krpano.set("hotspot[active-product-hr].scale", "0.66");

            // produkt kratky popis
            krpano.call("addhotspot(product-short-description);");
            krpano.set("hotspot[product-short-description].type", "text");
            krpano.set("hotspot[product-short-description].ath", ath);
            krpano.set("hotspot[product-short-description].atv", "0");
            krpano.set("hotspot[product-short-description].edge", "lefttop");
            krpano.set("hotspot[product-short-description].ox", "-206px");
            krpano.set("hotspot[product-short-description].oy", "-119px");
            krpano.set("hotspot[product-short-description].html", "300 x 600 mm");
            krpano.set("hotspot[product-short-description].visible", "true");
            krpano.set("hotspot[product-short-description].keep", "true");
            krpano.set("hotspot[product-short-description].zorder", 1100);
            krpano.set("hotspot[product-short-description].width", "auto");
            krpano.set("hotspot[product-short-description].height", "24px");
            krpano.set("hotspot[product-short-description].distorted", "true");
            krpano.set("hotspot[product-short-description].bgcolor", "0x3F3F46");
            krpano.set("hotspot[product-short-description].alpha", "1");
            krpano.set("hotspot[product-short-description].bgroundedge", "6");
            krpano.set("hotspot[product-short-description].vcenter", "true");
            krpano.set("hotspot[product-short-description].scale", "0.66");
            krpano.set("hotspot[product-short-description].css", "color:#FAFAFA; text-align:center; font-size:12px; padding:10px");

            // produkt nazov variantu
            krpano.call("addhotspot(product-variant-name);");
            krpano.set("hotspot[product-variant-name].type", "text");
            krpano.set("hotspot[product-variant-name].ath", ath);
            krpano.set("hotspot[product-variant-name].atv", "0");
            krpano.set("hotspot[product-variant-name].edge", "lefttop");
            krpano.set("hotspot[product-variant-name].ox", "-206px");
            krpano.set("hotspot[product-variant-name].oy", "-96px");
            krpano.set("hotspot[product-variant-name].html", "Dub Hamilton");
            krpano.set("hotspot[product-variant-name].visible", "true");
            krpano.set("hotspot[product-variant-name].keep", "true");
            krpano.set("hotspot[product-variant-name].zorder", 1100);
            krpano.set("hotspot[product-variant-name].width", "auto");
            krpano.set("hotspot[product-variant-name].height", "auto");
            krpano.set("hotspot[product-variant-name].distorted", "true");
            krpano.set("hotspot[product-variant-name].bgcolor", "0x3F3F46");
            krpano.set("hotspot[product-variant-name].bgalpha", "0");
            krpano.set("hotspot[product-variant-name].alpha", "1");
            krpano.set("hotspot[product-variant-name].scale", "0.66");
            krpano.set("hotspot[product-variant-name].css", "color:#3F3F46; text-align:left; font-size:12px;");

            // produkt thumbnail
            krpano.call("addhotspot(product-variant-thumb);");
            krpano.set("hotspot[product-variant-thumb].type", "image");
            krpano.set("hotspot[product-variant-thumb].ath", ath);
            krpano.set("hotspot[product-variant-thumb].atv", 0);
            krpano.set("hotspot[product-variant-thumb].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "thumbnails/hamilton.jpg");
            krpano.set("hotspot[product-variant-thumb].edge", "lefttop");
            krpano.set("hotspot[product-variant-thumb].ox", "-206px");
            krpano.set("hotspot[product-variant-thumb].oy", "-73px");
            krpano.set("hotspot[product-variant-thumb].alpha", "1.0");
            krpano.set("hotspot[product-variant-thumb].visible", "true");
            krpano.set("hotspot[product-variant-thumb].enabled", "true");
            krpano.set("hotspot[product-variant-thumb].keep", "true");
            krpano.set("hotspot[product-variant-thumb].width", "40");
            krpano.set("hotspot[product-variant-thumb].height", "40px");
            krpano.set("hotspot[product-variant-thumb].zorder", "1100");
            krpano.set("hotspot[product-variant-thumb].distorted", "true");

            // produkt thumbnail border aktivny
            krpano.call("addhotspot(product-variant-thumb-active);");
            krpano.set("hotspot[product-variant-thumb-active].type", "image");
            krpano.set("hotspot[product-variant-thumb-active].ath", ath);
            krpano.set("hotspot[product-variant-thumb-active].atv", 0);
            krpano.set("hotspot[product-variant-thumb-active].url", userdata["cdn_path"] + "images/vr-thumb-active.png");
            krpano.set("hotspot[product-variant-thumb-active].edge", "lefttop");
            krpano.set("hotspot[product-variant-thumb-active].ox", "-206px");
            krpano.set("hotspot[product-variant-thumb-active].oy", "-73px");
            krpano.set("hotspot[product-variant-thumb-active].alpha", "1.0");
            krpano.set("hotspot[product-variant-thumb-active].visible", "true");
            krpano.set("hotspot[product-variant-thumb-active].enabled", "true");
            krpano.set("hotspot[product-variant-thumb-active].keep", "true");
            krpano.set("hotspot[product-variant-thumb-active].width", "40");
            krpano.set("hotspot[product-variant-thumb-active].height", "40px");
            krpano.set("hotspot[product-variant-thumb-active].zorder", "1100");
            krpano.set("hotspot[product-variant-thumb-active].distorted", "true");

            // tmp druhy variant
            krpano.call("addhotspot(product-variant-thumb2);");
            krpano.set("hotspot[product-variant-thumb2].type", "image");
            krpano.set("hotspot[product-variant-thumb2].ath", ath);
            krpano.set("hotspot[product-variant-thumb2].atv", 0);
            krpano.set("hotspot[product-variant-thumb2].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "thumbnails/silk-oak.jpg");
            krpano.set("hotspot[product-variant-thumb2].edge", "lefttop");
            krpano.set("hotspot[product-variant-thumb2].ox", "-160px");
            krpano.set("hotspot[product-variant-thumb2].oy", "-73px");
            krpano.set("hotspot[product-variant-thumb2].alpha", "1.0");
            krpano.set("hotspot[product-variant-thumb2].visible", "true");
            krpano.set("hotspot[product-variant-thumb2].enabled", "true");
            krpano.set("hotspot[product-variant-thumb2].keep", "true");
            krpano.set("hotspot[product-variant-thumb2].width", "40");
            krpano.set("hotspot[product-variant-thumb2].height", "40px");
            krpano.set("hotspot[product-variant-thumb2].zorder", "1100");
            krpano.set("hotspot[product-variant-thumb2].distorted", "true");

            // produkt thumbnail border neaktivny
            krpano.call("addhotspot(product-variant-thumb-deactive);");
            krpano.set("hotspot[product-variant-thumb-deactive].type", "image");
            krpano.set("hotspot[product-variant-thumb-deactive].ath", ath);
            krpano.set("hotspot[product-variant-thumb-deactive].atv", 0);
            krpano.set("hotspot[product-variant-thumb-deactive].url", userdata["cdn_path"] + "images/vr-thumb.png");
            krpano.set("hotspot[product-variant-thumb-deactive].edge", "lefttop");
            krpano.set("hotspot[product-variant-thumb-deactive].ox", "-160px");
            krpano.set("hotspot[product-variant-thumb-deactive].oy", "-73px");
            krpano.set("hotspot[product-variant-thumb-deactive].alpha", "1.0");
            krpano.set("hotspot[product-variant-thumb-deactive].visible", "true");
            krpano.set("hotspot[product-variant-thumb-deactive].enabled", "true");
            krpano.set("hotspot[product-variant-thumb-deactive].keep", "true");
            krpano.set("hotspot[product-variant-thumb-deactive].width", "40");
            krpano.set("hotspot[product-variant-thumb-deactive].height", "40px");
            krpano.set("hotspot[product-variant-thumb-deactive].zorder", "1100");
            krpano.set("hotspot[product-variant-thumb-deactive].distorted", "true");

            // šipka na rozbalenie zvazbeneho produktu
            krpano.call("addhotspot(bind-product-arrow);");
            krpano.set("hotspot[bind-product-arrow].type", "image");
            krpano.set("hotspot[bind-product-arrow].ath", ath);
            krpano.set("hotspot[bind-product-arrow].atv", 0);
            krpano.set("hotspot[bind-product-arrow].url", userdata["cdn_path"] + userdata["cdn_path"] + "images/unwrap.svg");
            krpano.set("hotspot[bind-product-arrow].edge", "rightbottom");
            krpano.set("hotspot[bind-product-arrow].ox", "205px");
            krpano.set("hotspot[bind-product-arrow].oy", "129px");
            krpano.set("hotspot[bind-product-arrow].alpha", "1.0");
            krpano.set("hotspot[bind-product-arrow].visible", "true");
            krpano.set("hotspot[bind-product-arrow].enabled", "true");
            krpano.set("hotspot[bind-product-arrow].keep", "true");
            krpano.set("hotspot[bind-product-arrow].width", "12px");
            krpano.set("hotspot[bind-product-arrow].height", "prop");
            krpano.set("hotspot[bind-product-arrow].zorder", "1100");
            krpano.set("hotspot[bind-product-arrow].distorted", "true");
            
        }

        function remove_detail() {
            // let elements = ['back-attributes_detail', 'back-attributes-text_detail'];
            // for (let index = 0; index < elements.length; index++) {
            //     krpano.call("removehotspot(" + elements[index] + ")");
            // }
            krpano.call("removehotspot(button-save-config);");
            krpano.call("removehotspot(button-checkout);");
            krpano.call("removehotspot(bind-product);");
            krpano.call("removehotspot(active-product);");
            krpano.call("removehotspot(active-product-hr);");
            krpano.call("removehotspot(product-short-description);");
            krpano.call("removehotspot(product-variant-name);");
            krpano.call("removehotspot(product-variant-thumb);");
            krpano.call("removehotspot(product-variant-thumb2);");
            krpano.call("removehotspot(product-variant-thumb-active);");
            krpano.call("removehotspot(product-variant-thumb-deactive);");
            krpano.call("removehotspot(bind-product-arrow);");
        }
        
        

        for (let index = 0; index < _all_products.length; index++) {
            const element = _all_products[index];

            switch (attributes_pagination) {
                case 0:
                    if (index < 8) {
                        allow_attributes_adding = true
                    } else {
                        allow_attributes_adding = false;
                    }
                break;
                case 1:
                    if (index > 7 && index < 15) {
                        allow_attributes_adding = true
                    } else {
                        allow_attributes_adding = false;
                    }
                break;
            }

            if (allow_attributes_adding) {
                //bg    
                krpano.call("addhotspot(" + element.patch_id + "-bg);");
                krpano.set("hotspot[" + element.patch_id + "-bg].type", "text");
                krpano.set("hotspot[" + element.patch_id + "-bg].ath", ath);
                krpano.set("hotspot[" + element.patch_id + "-bg].atv", 0);
                krpano.set("hotspot[" + element.patch_id + "-bg].alpha", "1.0");
                krpano.set("hotspot[" + element.patch_id + "-bg].visible", "true");
                krpano.set("hotspot[" + element.patch_id + "-bg].enabled", "false");
                krpano.set("hotspot[" + element.patch_id + "-bg].keep", "true");
                krpano.set("hotspot[" + element.patch_id + "-bg].zorder", 1000);
                krpano.set("hotspot[" + element.patch_id + "-bg].width", "106px");
                krpano.set("hotspot[" + element.patch_id + "-bg].height", "120px");
                krpano.set("hotspot[" + element.patch_id + "-bg].distorted", "true");
                krpano.set("hotspot[" + element.patch_id + "-bg].bgroundedge", "12");
                krpano.set("hotspot[" + element.patch_id + "-bg].bgborder", "2.0 0x3F3F46 0.2");
                krpano.set("hotspot[" + element.patch_id + "-bg].bgborder", "2.0 0x3F3F46 0.2");

                // ikona
                krpano.call("addhotspot(" + element.patch_id + "-icon);");
                krpano.set("hotspot[" + element.patch_id + "-icon].type", "image");
                krpano.set("hotspot[" + element.patch_id + "-icon].ath", ath);
                krpano.set("hotspot[" + element.patch_id + "-icon].atv", 0);
                
                krpano.set("hotspot[" + element.patch_id + "-icon].alpha", "1.0");
                krpano.set("hotspot[" + element.patch_id + "-icon].visible", "true");
                krpano.set("hotspot[" + element.patch_id + "-icon].enabled", "false");
                krpano.set("hotspot[" + element.patch_id + "-icon].keep", "true");
                krpano.set("hotspot[" + element.patch_id + "-icon].width", "prop");
                krpano.set("hotspot[" + element.patch_id + "-icon].height", "30px");
                krpano.set("hotspot[" + element.patch_id + "-icon].zorder", "1000");					

                // text
                krpano.call("addhotspot(" + element.patch_id + "-text);");
                krpano.set("hotspot[" + element.patch_id + "-text].type", "text");
                // krpano.set("hotspot[attributes-text].html", $t("components")['vr']['attributes']);
                krpano.set("hotspot[" + element.patch_id + "-text].html", element.name);
                krpano.set("hotspot[" + element.patch_id + "-text].ath", ath);
                krpano.set("hotspot[" + element.patch_id + "-text].atv", 0);
                krpano.set("hotspot[" + element.patch_id + "-text].alpha", "1.0");
                krpano.set("hotspot[" + element.patch_id + "-text].visible", "true");
                krpano.set("hotspot[" + element.patch_id + "-text].enabled", "false");
                krpano.set("hotspot[" + element.patch_id + "-text].keep", "true");
                krpano.set("hotspot[" + element.patch_id + "-text].zorder", "1000");	
                krpano.set("hotspot[" + element.patch_id + "-text].width", "105px");	
                krpano.set("hotspot[" + element.patch_id + "-text].distorted", "true");	
                krpano.set("hotspot[" + element.patch_id + "-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
                krpano.set("hotspot[" + element.patch_id + "-text].bgalpha", "0");	
                krpano.set("hotspot[" + element.patch_id + "-text].edge", "true");

                // Emtpy layer
                krpano.call("addhotspot(" + element.patch_id + "-bg-empty);");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].type", "text");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].ath", ath);
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].atv", 0);
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].width", "106px");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].height", "120px");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].alpha", "0.0");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].visible", "true");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].keep", "true");
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].zorder", "1100");	
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].bgroundedge", "12");	
                krpano.set("hotspot[" + element.patch_id + "-bg-empty].distorted", "true");

                let hs_attribute = krpano.get("hotspot[" + element.patch_id + "-bg-empty]");
                hs_attribute.onclick = function() {
                    remove_attributes_elements();
                    krpano.set("hotspot[global_menu-title].html", element.name);


                    krpano.call("addhotspot(back-attributes_detail);");
                    krpano.set("hotspot[back-attributes_detail].type", "image");
                    krpano.set("hotspot[back-attributes_detail].ath", ath);
                    krpano.set("hotspot[back-attributes_detail].atv", "0");
                    krpano.set("hotspot[back-attributes_detail].url", userdata["cdn_path"] + "images/back.svg");
                    krpano.set("hotspot[back-attributes_detail].ox", "-255px");
                    krpano.set("hotspot[back-attributes_detail].oy", "-168px");
                    krpano.set("hotspot[back-attributes_detail].alpha", "1.0");
                    krpano.set("hotspot[back-attributes_detail].visible", "true");
                    krpano.set("hotspot[back-attributes_detail].keep", "true");
                    krpano.set("hotspot[back-attributes_detail].zorder", "1400");
                    krpano.set("hotspot[back-attributes_detail].distorted", "true");
                    krpano.set("hotspot[back-attributes_detail].scale", "0.66");

                    var hs_back = krpano.get("hotspot[back-attributes_detail]");
                    hs_back.onclick = function() {
                        create_attributes_items();
                        add_settings_item_attributes();
                        remove_detail();
                    }


                    krpano.call("addhotspot(back-attributes-text_detail);");
                    krpano.set("hotspot[back-attributes-text_detail].type", "text");
                    krpano.set("hotspot[back-attributes-text_detail].ath", ath);
                    krpano.set("hotspot[back-attributes-text_detail].atv", "0");
                    krpano.set("hotspot[back-attributes-text_detail].ox", "-242px");
                    krpano.set("hotspot[back-attributes-text_detail].html", $t("components")['vr']['back']);
                    krpano.set("hotspot[back-attributes-text_detail].oy", "-175px");
                    krpano.set("hotspot[back-attributes-text_detail].alpha", "1.0");
                    krpano.set("hotspot[back-attributes-text_detail].visible", "true");
                    krpano.set("hotspot[back-attributes-text_detail].keep", "true");
                    krpano.set("hotspot[back-attributes-text_detail].zorder", "1400");
                    krpano.set("hotspot[back-attributes-text_detail].distorted", "true");
                    krpano.set("hotspot[back-attributes-text_detail].width", "70px");
                    krpano.set("hotspot[back-attributes-text_detail].css", "color:#FAFAFA; text-align:left;font-size:16px;");
                    krpano.set("hotspot[back-attributes-text_detail].bgalpha", "0");
                    krpano.set("hotspot[back-attributes-text_detail].scale", "0.66");
                    krpano.set("hotspot[back-attributes-text_detail].edge", "top");

                    var hs_back_text = krpano.get("hotspot[back-attributes-text_detail]");
                    hs_back_text.onclick = function() {
                        create_attributes_items();
                        add_settings_item_attributes();
                        remove_detail();
                    }

                    create_detail();
                }



                switch (index) {
                    case 0:
                    case 8:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "-184px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "-68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "-81px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "-50px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "-68px");
                    break;

                    case 1:
                    case 9:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "-61px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "-68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "-61px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "-81px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "-61px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "-50px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "-61px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "-68px");
                    break;

                    case 2:
                    case 10:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "61px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "-68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "61px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "-81px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "61px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "-50px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "61px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "-68px");
                    break;

                    case 3:
                    case 11:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "184px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "-68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "184px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "-81px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "184px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "-50px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "184px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "-68px");
                    break;

                    case 4:
                    case 12:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "50px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "81px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "-184px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "68px");
                    break;

                    case 5:
                    case 13:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "-61px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "-61px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "50px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "-61px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "81px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "-61px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "68px");
                    break;

                    case 6:
                    case 14:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "61px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "61px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "50px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "61px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "81px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "61px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "68px");
                    break;

                    case 7:
                    case 15:
                    krpano.set("hotspot[" + element.patch_id + "-bg].ox", "184px"); 
                    krpano.set("hotspot[" + element.patch_id + "-bg].oy", "68px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].ox", "184px");
                    krpano.set("hotspot[" + element.patch_id + "-icon].oy", "50px");
                    krpano.set("hotspot[" + element.patch_id + "-text].ox", "184px");
                    krpano.set("hotspot[" + element.patch_id + "-text].oy", "81px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].ox", "184px");
                    krpano.set("hotspot[" + element.patch_id + "-bg-empty].oy", "68px");
                    break;
                
                
                    default:

                        break;
                }

                switch (element.patch_id) {
                    case "bateria1":
                    case "bateria2":
                    case "tlacidlo":
                    case "umyvadlo1":
                    case "umyvadlo2":
                        krpano.set("hotspot[" + element.patch_id + "-icon].url", userdata["cdn_path"] + "images/sanita1-vr.svg");    
                    break;
                    
                    case "kovanie":
                    case "dvere1":
                    case "dvere2":
                        krpano.set("hotspot[" + element.patch_id + "-icon].url", userdata["cdn_path"] + "images/dvere-vr.svg");    
                    break;

                    case "bateria3":
                    case "bateria4":
                    case "zlab":
                        krpano.set("hotspot[" + element.patch_id + "-icon].url", userdata["cdn_path"] + "images/sanita2-vr.svg");    
                    break;
                
                    default:
                        krpano.set("hotspot[" + element.patch_id + "-icon].url", userdata["cdn_path"] + "images/" + element.patch_id + "-vr.svg");
                    break;
                }
            }
        }

        krpano.call("addhotspot(product-pagination-arrow-r);");
        krpano.set("hotspot[product-pagination-arrow-r].type", "image");
        krpano.set("hotspot[product-pagination-arrow-r].ath", ath);
        krpano.set("hotspot[product-pagination-arrow-r].atv", 90);
        krpano.set("hotspot[product-pagination-arrow-r].url", userdata["cdn_path"] + "images/arrow-vr-r.svg");
        krpano.set("hotspot[product-pagination-arrow-r].ox", "268");   
        krpano.set("hotspot[product-pagination-arrow-r].oy", "200px");
        krpano.set("hotspot[product-pagination-arrow-r].alpha", "1.0");
        krpano.set("hotspot[product-pagination-arrow-r].visible", "true");
        krpano.set("hotspot[product-pagination-arrow-r].enabled", "true");
        krpano.set("hotspot[product-pagination-arrow-r].keep", "true");
        krpano.set("hotspot[product-pagination-arrow-r].width", "prop");
        krpano.set("hotspot[product-pagination-arrow-r].height", "40px");
        krpano.set("hotspot[product-pagination-arrow-r].zorder", "1100");
        krpano.set("hotspot[product-pagination-arrow-r].distorted", "true");

        krpano.call("addhotspot(product-pagination-arrow-l);");
        krpano.set("hotspot[product-pagination-arrow-l].type", "image");
        krpano.set("hotspot[product-pagination-arrow-l].ath", ath);
        krpano.set("hotspot[product-pagination-arrow-l].atv", -90);
        krpano.set("hotspot[product-pagination-arrow-l].url", userdata["cdn_path"] + "images/arrow-vr-l.svg");
        krpano.set("hotspot[product-pagination-arrow-l].ox", "-268");   
        krpano.set("hotspot[product-pagination-arrow-l].oy", "200px");
        krpano.set("hotspot[product-pagination-arrow-l].alpha", "1.0");
        krpano.set("hotspot[product-pagination-arrow-l].visible", "true");
        krpano.set("hotspot[product-pagination-arrow-l].enabled", "true");
        krpano.set("hotspot[product-pagination-arrow-l].keep", "true");
        krpano.set("hotspot[product-pagination-arrow-l].width", "prop");
        krpano.set("hotspot[product-pagination-arrow-l].height", "40px");
        krpano.set("hotspot[product-pagination-arrow-l].zorder", "1100");
        krpano.set("hotspot[product-pagination-arrow-l].distorted", "true");

        // klik na šípku ktorá mení produkty v menu
        function pagination_click($hotspot) {
            let hs_pag = krpano.get("hotspot[" + $hotspot + "]");
            hs_pag.onclick = function() {
                switch (attributes_pagination) {
                    case 0:
                        attributes_pagination = 1;
                        break;
                
                    default:
                        attributes_pagination = 0;
                        break;
                }
                remove_attributes_elements();
                create_attributes_items();
            };
        }

        pagination_click("pagination-arrow-r");
        pagination_click("pagination-arrow-l");

    }

    function add_settings_item_attributes() {
        krpano.call("addhotspot(back-attributes);");
        krpano.set("hotspot[back-attributes].type", "image");
        krpano.set("hotspot[back-attributes].ath", ath);
        krpano.set("hotspot[back-attributes].atv", "0");
        krpano.set("hotspot[back-attributes].url", userdata["cdn_path"] + "images/back.svg");
        krpano.set("hotspot[back-attributes].ox", "-255px");
        krpano.set("hotspot[back-attributes].oy", "-168px");
        krpano.set("hotspot[back-attributes].alpha", "1.0");
        krpano.set("hotspot[back-attributes].visible", "true");
        krpano.set("hotspot[back-attributes].keep", "true");
        krpano.set("hotspot[back-attributes].zorder", "1300");
        krpano.set("hotspot[back-attributes].distorted", "true");
        krpano.set("hotspot[back-attributes].scale", "0.66");


        //krpano.set("hotspot[settings-text].html", "Schichten");
        krpano.set("hotspot[global_menu-title].html", "Attributes");


        var hs_back = krpano.get("hotspot[back-attributes]");

        hs_back.onclick = function() {
            remove_attributes_elements();
            remove_attributes_back_button();
            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_info();
            add_global_menu_settings();	
            krpano.set("hotspot[global_menu-title].html", "Menu");
        }

        krpano.call("addhotspot(back-attributes-text);");
        krpano.set("hotspot[back-attributes-text].type", "text");
        krpano.set("hotspot[back-attributes-text].ath", ath);
        krpano.set("hotspot[back-attributes-text].atv", "0");
        krpano.set("hotspot[back-attributes-text].ox", "-242px");
        krpano.set("hotspot[back-attributes-text].html", $t("components")['vr']['back']);
        krpano.set("hotspot[back-attributes-text].oy", "-175px");
        krpano.set("hotspot[back-attributes-text].alpha", "1.0");
        krpano.set("hotspot[back-attributes-text].visible", "true");
        krpano.set("hotspot[back-attributes-text].keep", "true");
        krpano.set("hotspot[back-attributes-text].zorder", "1300");
        krpano.set("hotspot[back-attributes-text].distorted", "true");
        krpano.set("hotspot[back-attributes-text].width", "70px");
        krpano.set("hotspot[back-attributes-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[back-attributes-text].bgalpha", "0");
        krpano.set("hotspot[back-attributes-text].scale", "0.66");
        krpano.set("hotspot[back-attributes-text].edge", "top");

        var hs_back_text = krpano.get("hotspot[back-attributes-text]");
        hs_back_text.onclick = function() {
            //add_global_menu();
            remove_attributes_elements();
            remove_attributes_back_button();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_info();
            add_global_menu_settings();	
        }

    }

    function remove_attributes_elements() {         
        if (krpano) {

            let tmp_array = ["-bg", "-icon", "-text", "-bg-empty"];

            for (let index = 0; index < _all_products.length; index++) {
                const element = _all_products[index];

                krpano.call("removehotspot(" + element.patch_id + ")");
                for (let i = 0; i < tmp_array.length; i++) {
                    krpano.call("removehotspot(" + element.patch_id + tmp_array[i] + ")");
                }
            }

            
        }
    }

    function remove_attributes_back_button() {
        krpano.call("removehotspot(back-attributes);");
        krpano.call("removehotspot(back-attributes-text);");
        krpano.call("removehotspot(pagination-arrow-l);");
        krpano.call("removehotspot(pagination-arrow-r);");
        //krpano.call("removehotspot(global_menu-title);");
        krpano.set("hotspot[global_menu-title].html", "Menu");
        
    }

    function remove_attributes_detail_back_button() {
        krpano.call("removehotspot(back-attributes_detail);");
        krpano.call("removehotspot(back-attributes-text_detail);");
    }

    

    /*  GLOBAL MENU IKONA SHOP  */
    function add_global_menu_shop() {
        // Shop
        krpano.call("addhotspot(shop-bg);");
        krpano.set("hotspot[shop-bg].type", "text");
        krpano.set("hotspot[shop-bg].ath", ath); 
        krpano.set("hotspot[shop-bg].atv", 0);
        krpano.set("hotspot[shop-bg].alpha", "1.0");
        krpano.set("hotspot[shop-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[shop-bg].ox", "184px"); 
        krpano.set("hotspot[shop-bg].oy", "-68px");
        krpano.set("hotspot[shop-bg].visible", true);
        krpano.set("hotspot[shop-bg].enabled", false);
        krpano.set("hotspot[shop-bg].keep", "true");
        krpano.set("hotspot[shop-bg].zorder", 1000);
        krpano.set("hotspot[shop-bg].width", "106px");
        krpano.set("hotspot[shop-bg].height", "120px");
        krpano.set("hotspot[shop-bg].distorted", "true");
        krpano.set("hotspot[shop-bg].bgroundedge", "12");
        krpano.set("hotspot[shop-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(shop);");
        krpano.set("hotspot[shop].type", "image");
        krpano.set("hotspot[shop].ath", ath);
        krpano.set("hotspot[shop].atv", 0);
        krpano.set("hotspot[shop].url", userdata["cdn_path"] + "images/shop.svg");
        krpano.set("hotspot[shop].ox", "184px");  
        krpano.set("hotspot[shop].oy", "-81px");

        if (_config_products != undefined) {
            krpano.set("hotspot[shop].alpha", "1.0");
        } else {
            krpano.set("hotspot[shop].alpha", "0.4");
        }

        
        krpano.set("hotspot[shop].visible", true);
        krpano.set("hotspot[shop].enabled", false);
        krpano.set("hotspot[shop].keep", "true");
        krpano.set("hotspot[shop].width", "30px");
        krpano.set("hotspot[shop].height", "30px");
        krpano.set("hotspot[shop].zorder", 1100);					

        // text
        krpano.call("addhotspot(shop-text);");
        krpano.set("hotspot[shop-text].type", "text");
        krpano.set("hotspot[shop-text].html", $t("components")['vr']['shop']);
        krpano.set("hotspot[shop-text].ath", ath);
        krpano.set("hotspot[shop-text].atv", 0);
        krpano.set("hotspot[shop-text].ox", "184px");
        krpano.set("hotspot[shop-text].oy", "-50px"); 

        if (_config_products != undefined) {
            krpano.set("hotspot[shop-text].alpha", "1.0");    
        } else {
            krpano.set("hotspot[shop-text].alpha", "0.4");
        }
        
        krpano.set("hotspot[shop-text].visible", true);
        krpano.set("hotspot[shop-text].enabled", false);
        krpano.set("hotspot[shop-text].keep", "true");
        krpano.set("hotspot[shop-text].zorder", 1100);	
        krpano.set("hotspot[shop-text].width", "105px");	
        krpano.set("hotspot[shop-text].distorted", "true");	
        krpano.set("hotspot[shop-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[shop-text].bgalpha", "0");	
        krpano.set("hotspot[shop-text].edge", "true");	

        // Emtpy layer
        krpano.call("addhotspot(shop-bg-empty);");
        krpano.set("hotspot[shop-bg-empty].type", "text");
        krpano.set("hotspot[shop-bg-empty].ath", ath);
        krpano.set("hotspot[shop-bg-empty].atv", 0);
        krpano.set("hotspot[shop-bg-empty].width", "106px");
        krpano.set("hotspot[shop-bg-empty].height", "120px");
        krpano.set("hotspot[shop-bg-empty].ox", "184px");
        krpano.set("hotspot[shop-bg-empty].oy", "-68px");
        krpano.set("hotspot[shop-bg-empty].alpha", "0.0");
        krpano.set("hotspot[shop-bg-empty].visible", "true");
        krpano.set("hotspot[shop-bg-empty].enabled", true);
        krpano.set("hotspot[shop-bg-empty].keep", "true");  
        krpano.set("hotspot[shop-bg-empty].zorder", "1200");	
        krpano.set("hotspot[shop-bg-empty].bgroundedge", "12");	
        krpano.set("hotspot[shop-bg-empty].distorted", "true");

        if (_config_products != undefined) {
            krpano.call("addhotspot(shop-bg-empty);");
            krpano.set("hotspot[shop-bg-empty].type", "text");
            krpano.set("hotspot[shop-bg-empty].ath", ath);
            krpano.set("hotspot[shop-bg-empty].atv", 0);
            krpano.set("hotspot[shop-bg-empty].width", "106px");
            krpano.set("hotspot[shop-bg-empty].height", "120px");
            krpano.set("hotspot[shop-bg-empty].ox", "184px");
            krpano.set("hotspot[shop-bg-empty].oy", "-68px");
            krpano.set("hotspot[shop-bg-empty].alpha", "0.0");
            krpano.set("hotspot[shop-bg-empty].visible", "true");
            krpano.set("hotspot[shop-bg-empty].enabled", true);
            krpano.set("hotspot[shop-bg-empty].keep", "true");  
            krpano.set("hotspot[shop-bg-empty].zorder", "1200");	
            krpano.set("hotspot[shop-bg-empty].bgroundedge", "12");	
            krpano.set("hotspot[shop-bg-empty].distorted", "true");

            var hs_s = krpano.get("hotspot[shop-bg-empty]");
            hs_s.onover = function() {
            global_menu_add_hover("shop");
            }

            hs_s.onout = function() {
            global_menu_remove_hover("shop");
            }

            hs_s.onclick = function() {
                add_basket();
                remove_global_menu();
            }
        }
        
    }

    function remove_global_menu_shop() {
        if (krpano) {
            let elements = ['shop-bg', 'shop', 'shop-text','shop-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA LAYERS  */
    function add_global_menu_layers() {
        // Layers
        krpano.call("addhotspot(layers-bg);");
        krpano.set("hotspot[layers-bg].type", "text");
        krpano.set("hotspot[layers-bg].ath", ath); 
        krpano.set("hotspot[layers-bg].atv", 0);
        krpano.set("hotspot[layers-bg].alpha", "1.0");
        krpano.set("hotspot[layers-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[layers-bg].ox", "-184px");  
        krpano.set("hotspot[layers-bg].oy", "68px");
        krpano.set("hotspot[layers-bg].visible", "true");
        krpano.set("hotspot[layers-bg].enabled", "false");
        krpano.set("hotspot[layers-bg].keep", "true");
        krpano.set("hotspot[layers-bg].zorder", 1000);
        krpano.set("hotspot[layers-bg].width", "106px");
        krpano.set("hotspot[layers-bg].height", "120px");
        krpano.set("hotspot[layers-bg].distorted", "true");
        krpano.set("hotspot[layers-bg].bgroundedge", "12");
        krpano.set("hotspot[layers-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(layers);");
        krpano.set("hotspot[layers].type", "image");
        krpano.set("hotspot[layers].ath", ath);
        krpano.set("hotspot[layers].atv", 0);
        krpano.set("hotspot[layers].url", userdata["cdn_path"] + "images/layers.svg");
        krpano.set("hotspot[layers].ox", "-184px");   
        krpano.set("hotspot[layers].oy", "50px");

        
        if (components.includes("component_layers")) {
            krpano.set("hotspot[layers].alpha", "1.0");
        } else {
            krpano.set("hotspot[layers].alpha", "0.4");
        }
        
        krpano.set("hotspot[layers].visible", "true");
        krpano.set("hotspot[layers].enabled", "false");
        krpano.set("hotspot[layers].keep", "true");
        krpano.set("hotspot[layers].width", "30px");
        krpano.set("hotspot[layers].height", "30px");
        krpano.set("hotspot[layers].zorder", "1100");					

        // text
        krpano.call("addhotspot(layers-text);");
        krpano.set("hotspot[layers-text].type", "text");
        krpano.set("hotspot[layers-text].html", $t("components")['vr']['layers']);
        krpano.set("hotspot[layers-text].ath", ath);
        krpano.set("hotspot[layers-text].atv", 0);
        krpano.set("hotspot[layers-text].ox", "-184px");
        krpano.set("hotspot[layers-text].oy", "81px"); 

        if (components.includes("component_layers")) {
            krpano.set("hotspot[layers-text].alpha", "1.0");
        } else {
            krpano.set("hotspot[layers-text].alpha", "0.4");
        }
        
        krpano.set("hotspot[layers-text].visible", "true");
        krpano.set("hotspot[layers-text].enabled", "false");
        krpano.set("hotspot[layers-text].keep", "true");
        krpano.set("hotspot[layers-text].zorder", "1100");	
        krpano.set("hotspot[layers-text].width", "105px");	
        krpano.set("hotspot[layers-text].distorted", "true");	
        krpano.set("hotspot[layers-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[layers-text].bgalpha", "0");	
        krpano.set("hotspot[layers-text].edge", "true");
        
        if (components.includes("component_layers")) {
            // Emtpy layer
            krpano.call("addhotspot(layers-bg-empty);");
            krpano.set("hotspot[layers-bg-empty].type", "text");
            krpano.set("hotspot[layers-bg-empty].ath", ath);
            krpano.set("hotspot[layers-bg-empty].atv", 0);
            krpano.set("hotspot[layers-bg-empty].width", "106px");
            krpano.set("hotspot[layers-bg-empty].height", "120px");
            krpano.set("hotspot[layers-bg-empty].ox", "-184px");
            krpano.set("hotspot[layers-bg-empty].oy", "68px");  
            krpano.set("hotspot[layers-bg-empty].alpha", "0.0");
            krpano.set("hotspot[layers-bg-empty].visible", "true");
            krpano.set("hotspot[layers-bg-empty].keep", "true");  
            krpano.set("hotspot[layers-bg-empty].zorder", "1100");	
            krpano.set("hotspot[layers-bg-empty].bgroundedge", "12");	
            krpano.set("hotspot[layers-bg-empty].distorted", "true");

            var hs_s = krpano.get("hotspot[layers-bg-empty]");
            hs_s.onover = function() {
            global_menu_add_hover("layers");
            }

            hs_s.onout = function() {
            global_menu_remove_hover("layers");
            }

            hs_s.onclick = function() {
                remove_global_menu_all_items();
                add_settings_item_layers();
                add_submenu_layers_items();
            }
        } 

    }

    function remove_global_menu_layers() {
        if (krpano) {
            let elements = ['layers-bg', 'layers', 'layers-text','layers-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA MODEL  */
    function add_global_menu_model() {
        // Model
        krpano.call("addhotspot(model-bg);");
        krpano.set("hotspot[model-bg].type", "text");
        krpano.set("hotspot[model-bg].ath", ath); 
        krpano.set("hotspot[model-bg].atv", 0);
        krpano.set("hotspot[model-bg].alpha", "1.0");
        krpano.set("hotspot[model-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[model-bg].ox", "-61x");    
        krpano.set("hotspot[model-bg].oy", "68px");
        krpano.set("hotspot[model-bg].visible", "true");
        krpano.set("hotspot[model-bg].enabled", "false");
        krpano.set("hotspot[model-bg].keep", "true");
        krpano.set("hotspot[model-bg].zorder", 1000);
        krpano.set("hotspot[model-bg].width", "106px");
        krpano.set("hotspot[model-bg].height", "120px");
        krpano.set("hotspot[model-bg].distorted", "true");
        krpano.set("hotspot[model-bg].bgroundedge", "12");
        krpano.set("hotspot[model-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(model);");
        krpano.set("hotspot[model].type", "image");
        krpano.set("hotspot[model].ath", ath);
        krpano.set("hotspot[model].atv", 0);
        krpano.set("hotspot[model].url", userdata["cdn_path"] + "images/model.svg");
        krpano.set("hotspot[model].ox", "-61px");  
        krpano.set("hotspot[model].oy", "50px");
        krpano.set("hotspot[model].alpha", "0.4");
        krpano.set("hotspot[model].visible", "true");
        krpano.set("hotspot[model].enabled", "false");
        krpano.set("hotspot[model].keep", "true");
        krpano.set("hotspot[model].width", "30px");
        krpano.set("hotspot[model].height", "30px");
        krpano.set("hotspot[model].zorder", "1000");					

        // text
        krpano.call("addhotspot(model-text);");
        krpano.set("hotspot[model-text].type", "text");
        krpano.set("hotspot[model-text].html", $t("components")['vr']['model']);
        krpano.set("hotspot[model-text].ath", ath);
        krpano.set("hotspot[model-text].atv", 0);
        krpano.set("hotspot[model-text].ox", "-61px");
        krpano.set("hotspot[model-text].oy", "81px"); 
        krpano.set("hotspot[model-text].alpha", "0.4");
        krpano.set("hotspot[model-text].visible", "true");
        krpano.set("hotspot[model-text].enabled", "false");
        krpano.set("hotspot[model-text].keep", "true");
        krpano.set("hotspot[model-text].zorder", "1000");	
        krpano.set("hotspot[model-text].width", "105px");	
        krpano.set("hotspot[model-text].distorted", "true");	
        krpano.set("hotspot[model-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[model-text].bgalpha", "0");	
        krpano.set("hotspot[model-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(model-bg-empty);");
        // krpano.set("hotspot[model-bg-empty].type", "text");
        // krpano.set("hotspot[model-bg-empty].ath", ath);
        // krpano.set("hotspot[model-bg-empty].atv", 0);
        // krpano.set("hotspot[model-bg-empty].width", "106px");
        // krpano.set("hotspot[model-bg-empty].height", "120px");
        // krpano.set("hotspot[model-bg-empty].ox", "-61x");
        // krpano.set("hotspot[model-bg-empty].oy", "81px");
        // krpano.set("hotspot[model-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[model-bg-empty].visible", "false");
        // krpano.set("hotspot[model-bg-empty].keep", "true");  
        // krpano.set("hotspot[model-bg-empty].zorder", "1100");	
        // krpano.set("hotspot[model-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[model-bg-empty].distorted", "true");
    }

    function remove_global_menu_model() {
        if (krpano) {
            let elements = ['model-bg', 'model', 'model-text','model-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA INFO  */
    function add_global_menu_info() {
        // Info
        krpano.call("addhotspot(info-bg);");
        krpano.set("hotspot[info-bg].type", "text");
        krpano.set("hotspot[info-bg].ath", ath); 
        krpano.set("hotspot[info-bg].atv", 0);
        krpano.set("hotspot[info-bg].alpha", "1.0");
        krpano.set("hotspot[info-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[info-bg].ox", "61x");    
        krpano.set("hotspot[info-bg].oy", "68px");
        krpano.set("hotspot[info-bg].visible", "true");
        krpano.set("hotspot[info-bg].enabled", "false");
        krpano.set("hotspot[info-bg].keep", "true");
        krpano.set("hotspot[info-bg].zorder", 1000);
        krpano.set("hotspot[info-bg].width", "106px");
        krpano.set("hotspot[info-bg].height", "120px");
        krpano.set("hotspot[info-bg].distorted", "true");
        krpano.set("hotspot[info-bg].bgroundedge", "12");
        krpano.set("hotspot[info-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(info);");
        krpano.set("hotspot[info].type", "image");
        krpano.set("hotspot[info].ath", ath);
        krpano.set("hotspot[info].atv", 0);
        krpano.set("hotspot[info].url", userdata["cdn_path"] + "images/info.svg");
        krpano.set("hotspot[info].ox", "61px");  
        krpano.set("hotspot[info].oy", "50px");
        krpano.set("hotspot[info].alpha", "0.4");
        krpano.set("hotspot[info].visible", "true");
        krpano.set("hotspot[info].enabled", "false");
        krpano.set("hotspot[info].keep", "true");
        krpano.set("hotspot[info].width", "30px");
        krpano.set("hotspot[info].height", "30px");
        krpano.set("hotspot[info].zorder", "1000");					

        // text
        krpano.call("addhotspot(info-text);");
        krpano.set("hotspot[info-text].type", "text");
        krpano.set("hotspot[info-text].html", $t("components")['vr']['tour-info']);
        krpano.set("hotspot[info-text].ath", ath);
        krpano.set("hotspot[info-text].atv", 0);
        krpano.set("hotspot[info-text].ox", "61px");
        krpano.set("hotspot[info-text].oy", "81px"); 
        krpano.set("hotspot[info-text].alpha", "0.4");
        krpano.set("hotspot[info-text].visible", "true");
        krpano.set("hotspot[info-text].enabled", "false");
        krpano.set("hotspot[info-text].keep", "true");
        krpano.set("hotspot[info-text].zorder", "1000");	
        krpano.set("hotspot[info-text].width", "105px");	
        krpano.set("hotspot[info-text].distorted", "true");	
        krpano.set("hotspot[info-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[info-text].bgalpha", "0");	
        krpano.set("hotspot[info-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(info-bg-empty);");
        // krpano.set("hotspot[info-bg-empty].type", "text");
        // krpano.set("hotspot[info-bg-empty].ath", ath);
        // krpano.set("hotspot[info-bg-empty].atv", 0);
        // krpano.set("hotspot[info-bg-empty].width", "106px");
        // krpano.set("hotspot[info-bg-empty].height", "120px");
        // krpano.set("hotspot[info-bg-empty].ox", "61x");
        // krpano.set("hotspot[info-bg-empty].oy", "68px");
        // krpano.set("hotspot[info-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[info-bg-empty].visible", "false");
        // krpano.set("hotspot[info-bg-empty].keep", "true");  
        // krpano.set("hotspot[info-bg-empty].zorder", "1100");	
        // krpano.set("hotspot[info-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[info-bg-empty].distorted", "true");
        // krpano.set("hotspot[info-bg-empty].onhover", "js(global_menu_add_hover(info););");
        // krpano.set("hotspot[info-bg-empty].onout", "js(global_menu_remove_hover(info););");
        // krpano.set("hotspot[info-bg-empty].onclick",  "js(toggle_global_menu(submenu,info, " + settings.components[8].data.title + "););");	
    }

    function remove_global_menu_info() {
        if (krpano) {
            let elements = ['info-bg', 'info', 'info-text','info-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA SETTINGS  */
    function add_global_menu_settings() {
        // Settings
        krpano.call("addhotspot(settings-bg);");
        krpano.set("hotspot[settings-bg].type", "text");
        krpano.set("hotspot[settings-bg].ath", ath); 
        krpano.set("hotspot[settings-bg].atv", 0);
        krpano.set("hotspot[settings-bg].alpha", "1.0");
        krpano.set("hotspot[settings-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[settings-bg].ox", "184"); 
        krpano.set("hotspot[settings-bg].oy", "68px");
        krpano.set("hotspot[settings-bg].visible", "true");
        krpano.set("hotspot[settings-bg].keep", "true");
        krpano.set("hotspot[settings-bg].zorder", 1000);
        krpano.set("hotspot[settings-bg].width", "106px");
        krpano.set("hotspot[settings-bg].height", "120px");
        krpano.set("hotspot[settings-bg].distorted", "true");
        krpano.set("hotspot[settings-bg].bgroundedge", "12");
        krpano.set("hotspot[settings-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(settings);");
        krpano.set("hotspot[settings].type", "image");
        krpano.set("hotspot[settings].ath", ath);
        krpano.set("hotspot[settings].atv", 0);
        krpano.set("hotspot[settings].url", userdata["cdn_path"] + "images/settings.svg");
        krpano.set("hotspot[settings].ox", "184px");  
        krpano.set("hotspot[settings].oy", "50px");
        krpano.set("hotspot[settings].alpha", "1.0");
        krpano.set("hotspot[settings].visible", "true");
        krpano.set("hotspot[settings].enabled", "false");
        krpano.set("hotspot[settings].keep", "true");
        krpano.set("hotspot[settings].width", "30px");
        krpano.set("hotspot[settings].height", "30px");
        krpano.set("hotspot[settings].zorder", "1000");		
        
        // text
        krpano.call("addhotspot(settings-text);");
        krpano.set("hotspot[settings-text].type", "text");
        krpano.set("hotspot[settings-text].html", $t("components")['vr']['settings']);
        krpano.set("hotspot[settings-text].ath", ath);
        krpano.set("hotspot[settings-text].atv", 0);
        krpano.set("hotspot[settings-text].ox", "184px");
        krpano.set("hotspot[settings-text].oy", "81px"); 
        krpano.set("hotspot[settings-text].alpha", "1.0");
        krpano.set("hotspot[settings-text].visible", "true");
        krpano.set("hotspot[settings-text].keep", "true");
        krpano.set("hotspot[settings-text].zorder", "1000");	
        krpano.set("hotspot[settings-text].width", "105px");	
        krpano.set("hotspot[settings-text].distorted", "true");	
        krpano.set("hotspot[settings-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[settings-text].bgalpha", "0");	
        krpano.set("hotspot[settings-text].edge", "true");	
        
        // Emtpy layer
        krpano.call("addhotspot(settings-bg-empty);");
        krpano.set("hotspot[settings-bg-empty].type", "text");
        krpano.set("hotspot[settings-bg-empty].ath", ath);
        krpano.set("hotspot[settings-bg-empty].atv", 0);
        krpano.set("hotspot[settings-bg-empty].width", "106px");
        krpano.set("hotspot[settings-bg-empty].height", "120px");
        krpano.set("hotspot[settings-bg-empty].ox", "184x");
        krpano.set("hotspot[settings-bg-empty].oy", "68px");
        krpano.set("hotspot[settings-bg-empty].alpha", "0.0");
        krpano.set("hotspot[settings-bg-empty].visible", "true");
        krpano.set("hotspot[settings-bg-empty].keep", "true");  
        krpano.set("hotspot[settings-bg-empty].zorder", "1100");	
        krpano.set("hotspot[settings-bg-empty].bgroundedge", "12");	
        krpano.set("hotspot[settings-bg-empty].distorted", "true");

        var hs_s = krpano.get("hotspot[settings-bg-empty]");
        hs_s.onover = function() {
           global_menu_add_hover("settings");
        }

        hs_s.onout = function() {
           global_menu_remove_hover("settings");
        }

        hs_s.onclick = function() {
            remove_global_menu_all_items();
            add_settings_item_settings();
            add_submenu_settings_items();
            krpano.set("hotspot[global_menu-title].html", "Einstellungen");
        }

    }

    function remove_global_menu_settings() {
        if (krpano) {
            let elementiky = ['settings-bg','settings','settings-text','settings-bg-empty'];
            for (let index = 0; index < elementiky.length; index++) {
                krpano.call("removehotspot(" + elementiky[index] + ")");
                //krpano.set("hotspot[" + elements[index] + "].alpha", "0.2");
            }
            krpano.set("hotspot[global_menu-title].html", "Menu");
            
        }
    }

    /*  NASTAVENIA - položka SETTINGS  */
    function add_settings_item_settings() {
        //get_ath_atv();
        krpano.call("addhotspot(back-settings);");
        krpano.set("hotspot[back-settings].type", "image");
        krpano.set("hotspot[back-settings].ath", ath);
        krpano.set("hotspot[back-settings].atv", "0");
        krpano.set("hotspot[back-settings].url", userdata["cdn_path"] + "images/back.svg");
        krpano.set("hotspot[back-settings].ox", "-255px");
        krpano.set("hotspot[back-settings].oy", "-168px");
        krpano.set("hotspot[back-settings].alpha", "1.0");
        krpano.set("hotspot[back-settings].visible", "true");
        krpano.set("hotspot[back-settings].keep", "true");
        krpano.set("hotspot[back-settings].zorder", "1300");
        krpano.set("hotspot[back-settings].distorted", "true");
        krpano.set("hotspot[back-settings].scale", "0.66");

        var hs_back = krpano.get("hotspot[back-settings]");
        hs_back.onclick = function() {
            //add_global_menu();
            remove_settings_item_settings();
            remove_submenu_settings_items();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_settings();
            add_global_menu_info();
            
        }

        krpano.call("addhotspot(back-settings-text);");
        krpano.set("hotspot[back-settings-text].type", "text");
        krpano.set("hotspot[back-settings-text].ath", ath);
        krpano.set("hotspot[back-settings-text].atv", "0");
        krpano.set("hotspot[back-settings-text].ox", "-242px");
        krpano.set("hotspot[back-settings-text].html", $t("components")['vr']['back']);
        krpano.set("hotspot[back-settings-text].oy", "-175px");
        krpano.set("hotspot[back-settings-text].alpha", "1.0");
        krpano.set("hotspot[back-settings-text].visible", "true");
        krpano.set("hotspot[back-settings-text].keep", "true");
        krpano.set("hotspot[back-settings-text].zorder", "1300");
        krpano.set("hotspot[back-settings-text].distorted", "true");
        krpano.set("hotspot[back-settings-text].width", "70px");
        krpano.set("hotspot[back-settings-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[back-settings-text].bgalpha", "0");
        krpano.set("hotspot[back-settings-text].scale", "0.66");
        krpano.set("hotspot[back-settings-text].edge", "top");

        var hs_back_text = krpano.get("hotspot[back-settings-text]");
        hs_back_text.onclick = function() {
            //add_global_menu();
            remove_settings_item_settings();
            remove_submenu_settings_items();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_info();
            add_global_menu_settings();	
        }
    }

    function add_settings_item_layers() {
        krpano.call("addhotspot(back-layers);");
        krpano.set("hotspot[back-layers].type", "image");
        krpano.set("hotspot[back-layers].ath", ath);
        krpano.set("hotspot[back-layers].atv", "0");
        krpano.set("hotspot[back-layers].url", userdata["cdn_path"] + "images/back.svg");
        krpano.set("hotspot[back-layers].ox", "-255px");
        krpano.set("hotspot[back-layers].oy", "-168px");
        krpano.set("hotspot[back-layers].alpha", "1.0");
        krpano.set("hotspot[back-layers].visible", "true");
        krpano.set("hotspot[back-layers].keep", "true");
        krpano.set("hotspot[back-layers].zorder", "1300");
        krpano.set("hotspot[back-layers].distorted", "true");
        krpano.set("hotspot[back-layers].scale", "0.66");
        layers_hotspot_to_delete.push("back-layers");


        //krpano.set("hotspot[settings-text].html", "Schichten");
        krpano.set("hotspot[global_menu-title].html", $t("components")['vr']['layers']);


        var hs_back = krpano.get("hotspot[back-layers]");

        hs_back.onclick = function() {
            remove_settings_item_layers();
            remove_submenu_layers_items();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_info();
            add_global_menu_settings();	
        }

        krpano.call("addhotspot(back-layers-text);");
        krpano.set("hotspot[back-layers-text].type", "text");
        krpano.set("hotspot[back-layers-text].ath", ath);
        krpano.set("hotspot[back-layers-text].atv", "0");
        krpano.set("hotspot[back-layers-text].ox", "-242px");
        krpano.set("hotspot[back-layers-text].html", $t("components")['vr']['back']);
        krpano.set("hotspot[back-layers-text].oy", "-175px");
        krpano.set("hotspot[back-layers-text].alpha", "1.0");
        krpano.set("hotspot[back-layers-text].visible", "true");
        krpano.set("hotspot[back-layers-text].keep", "true");
        krpano.set("hotspot[back-layers-text].zorder", "1300");
        krpano.set("hotspot[back-layers-text].distorted", "true");
        krpano.set("hotspot[back-layers-text].width", "70px");
        krpano.set("hotspot[back-layers-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[back-layers-text].bgalpha", "0");
        krpano.set("hotspot[back-layers-text].scale", "0.66");
        krpano.set("hotspot[back-layers-text].edge", "top");
        layers_hotspot_to_delete.push("back-layers-text");

        var hs_back_text = krpano.get("hotspot[back-layers-text]");
        hs_back_text.onclick = function() {
            //add_global_menu();
            remove_settings_item_layers();
            remove_submenu_layers_items();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_info();
            add_global_menu_settings();	
        }

    }

    function remove_settings_item_settings() {
        if (krpano) {
            let elements = ['back-settings', 'back-settings-text'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            krpano.set("hotspot[global_menu-title].html", "Menu");
        }

        
        
    }

    function remove_settings_item_layers() {
        if (krpano) {
            let elements = ['back-layers', 'back-layers-text'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            krpano.set("hotspot[global_menu-title].html", "Menu");   
        }
    }

    // Pridanie položiek submenu pre Settings
    function add_submenu_settings_items() {
        // Advertisement
        // krpano.call("addhotspot(advertisement-text);");
        // krpano.set("hotspot[advertisement-text].type", "text");
        // krpano.set("hotspot[advertisement-text].ath", ath);
        // krpano.set("hotspot[advertisement-text].atv", "0");
        // krpano.set("hotspot[advertisement-text].html", $t("components")['vr']['ad']);
        // krpano.set("hotspot[advertisement-text].ox", "0px");
        // krpano.set("hotspot[advertisement-text].oy", "30px");
        // krpano.set("hotspot[advertisement-text].alpha", "1.0");
        // krpano.set("hotspot[advertisement-text].visible", "true");
        // krpano.set("hotspot[advertisement-text].keep", "true");
        // krpano.set("hotspot[advertisement-text].zorder", "1000");
        // krpano.set("hotspot[advertisement-text].distorted", "true");
        // krpano.set("hotspot[advertisement-text].width", "500px");
        // krpano.set("hotspot[advertisement-text].height", "64px");
        // krpano.set("hotspot[advertisement-text].bgcolor", "0xfafafa");
        // krpano.set("hotspot[advertisement-text].bgroundedge", "12");
        // krpano.set("hotspot[advertisement-text].vcenter", "true");
        // krpano.set("hotspot[advertisement-text].scale", "0.66");
        // krpano.set("hotspot[advertisement-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        // Hotspots
        // krpano.call("addhotspot(hotspots-text);");
        // krpano.set("hotspot[hotspots-text].type", "text");
        // krpano.set("hotspot[hotspots-text].ath", ath);
        // krpano.set("hotspot[hotspots-text].atv", "0");
        // krpano.set("hotspot[hotspots-text].html", $t("components")['vr']['hotspots']);
        // krpano.set("hotspot[hotspots-text].ox", "0px");
        // krpano.set("hotspot[hotspots-text].oy", "-30px");
        // krpano.set("hotspot[hotspots-text].alpha", "1.0");
        // krpano.set("hotspot[hotspots-text].visible", "true");
        // krpano.set("hotspot[hotspots-text].keep", "true");
        // krpano.set("hotspot[hotspots-text].zorder", "1000");
        // krpano.set("hotspot[hotspots-text].distorted", "true");
        // krpano.set("hotspot[hotspots-text].width", "500px");
        // krpano.set("hotspot[hotspots-text].height", "64px");
        // krpano.set("hotspot[hotspots-text].bgcolor", "0xfafafa");
        // krpano.set("hotspot[hotspots-text].bgroundedge", "12");
        // krpano.set("hotspot[hotspots-text].vcenter", "true");
        // krpano.set("hotspot[hotspots-text].scale", "0.66");
        // krpano.set("hotspot[hotspots-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        // var hs_hts_text = krpano.get("hotspot[hotspots-text]");
        // hs_hts_text.onclick = function() {
        //     global_menu_toggle_checkbox("hotspots");
        // }

        // Sounds
        // krpano.call("addhotspot(sound-text);");
        // krpano.set("hotspot[sound-text].type", "text");
        // krpano.set("hotspot[sound-text].ath", ath);
        // krpano.set("hotspot[sound-text].atv", "0");
        // krpano.set("hotspot[sound-text].html", $t("components")['vr']['sound']);
        // krpano.set("hotspot[sound-text].ox", "0px");
        // krpano.set("hotspot[sound-text].oy", "90px");
        // krpano.set("hotspot[sound-text].alpha", "1.0");
        // krpano.set("hotspot[sound-text].visible", "true");
        // krpano.set("hotspot[sound-text].keep", "true");
        // krpano.set("hotspot[sound-text].zorder", "1000");
        // krpano.set("hotspot[sound-text].distorted", "true");
        // krpano.set("hotspot[sound-text].width", "500px");
        // krpano.set("hotspot[sound-text].height", "64px");
        // krpano.set("hotspot[sound-text].bgcolor", "0xfafafa");
        // krpano.set("hotspot[sound-text].bgroundedge", "12");
        // krpano.set("hotspot[sound-text].vcenter", "true");
        // krpano.set("hotspot[sound-text].scale", "0.66");
        // krpano.set("hotspot[sound-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        // var hs_sound_text = krpano.get("hotspot[sound-text]");
        // hs_sound_text.onclick = function() {
        //     global_menu_toggle_checkbox("sound");
        // }

        //Transition Hotspots
        krpano.call("addhotspot(transition-hotspots-text);");
        krpano.set("hotspot[transition-hotspots-text].type", "text");
        krpano.set("hotspot[transition-hotspots-text].ath", ath);
        krpano.set("hotspot[transition-hotspots-text].atv", "0");
        krpano.set("hotspot[transition-hotspots-text].html", $t("components")['settings']["permanently_hotspots"]);
        krpano.set("hotspot[transition-hotspots-text].ox", "0px");
        krpano.set("hotspot[transition-hotspots-text].oy", "-90px");
        krpano.set("hotspot[transition-hotspots-text].alpha", "1.0");
        krpano.set("hotspot[transition-hotspots-text].visible", "true");
        krpano.set("hotspot[transition-hotspots-text].keep", "true");
        krpano.set("hotspot[transition-hotspots-text].zorder", "1000");
        krpano.set("hotspot[transition-hotspots-text].distorted", "true");
        krpano.set("hotspot[transition-hotspots-text].width", "500px");
        krpano.set("hotspot[transition-hotspots-text].height", "64px");
        krpano.set("hotspot[transition-hotspots-text].bgcolor", "0xfafafa");
        krpano.set("hotspot[transition-hotspots-text].bgroundedge", "12");
        krpano.set("hotspot[transition-hotspots-text].vcenter", "true");
        krpano.set("hotspot[transition-hotspots-text].scale", "0.66");
        krpano.set("hotspot[transition-hotspots-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        var hs_hts_text = krpano.get("hotspot[transition-hotspots-text]");
        hs_hts_text.onclick = function() {
            global_menu_toggle_checkbox("transition-hotspots");
        }

        // //checkboxes
        // krpano.call("addhotspot(advertisement-check);");
        // krpano.set("hotspot[advertisement-check].type", "image");
        // krpano.set("hotspot[advertisement-check].ath", ath);
        // krpano.set("hotspot[advertisement-check].atv", "0");
        // krpano.set("hotspot[advertisement-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");
        // krpano.set("hotspot[advertisement-check].ox", "142px");
        // krpano.set("hotspot[advertisement-check].oy", "90px");
        // krpano.set("hotspot[advertisement-check].alpha", "1.0");
        // krpano.set("hotspot[advertisement-check].visible", "true");
        // krpano.set("hotspot[advertisement-check].keep", "true");
        // krpano.set("hotspot[advertisement-check].zorder", "1000");
        // krpano.set("hotspot[advertisement-check].distorted", "true");
        // krpano.set("hotspot[advertisement-check].scale", "0.66");

        // krpano.call("addhotspot(hotspots-check);");
        // krpano.set("hotspot[hotspots-check].type", "image");
        // krpano.set("hotspot[hotspots-check].ath", ath);
        // krpano.set("hotspot[hotspots-check].atv", "0");
        // krpano.set("hotspot[hotspots-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");
        // krpano.set("hotspot[hotspots-check].ox", "142px");
        // krpano.set("hotspot[hotspots-check].oy", "-30px");
        // krpano.set("hotspot[hotspots-check].alpha", "1.0");
        // krpano.set("hotspot[hotspots-check].visible", "true");
        // krpano.set("hotspot[hotspots-check].keep", "true");
        // krpano.set("hotspot[hotspots-check].zorder", "1000");
        // krpano.set("hotspot[hotspots-check].distorted", "true");
        // krpano.set("hotspot[hotspots-check].scale", "0.66");

        // var hs_hts_check = krpano.get("hotspot[hotspots-check]");
        // hs_hts_check.onclick = function() {
        //     global_menu_toggle_checkbox("hotspots");
        // }

        krpano.call("addhotspot(transition-hotspots-check);");
        krpano.set("hotspot[transition-hotspots-check].type", "image");
        krpano.set("hotspot[transition-hotspots-check].ath", ath);
        krpano.set("hotspot[transition-hotspots-check].atv", "0");
        switch (get(permanently_hotspots)) {
            case true : 
                krpano.set("hotspot[transition-hotspots-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");
            break;

            default: 
                krpano.set("hotspot[transition-hotspots-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");
            break;
        }
        
        krpano.set("hotspot[transition-hotspots-check].ox", "142px");
        krpano.set("hotspot[transition-hotspots-check].oy", "-90px");
        krpano.set("hotspot[transition-hotspots-check].alpha", "1.0");
        krpano.set("hotspot[transition-hotspots-check].visible", "true");
        krpano.set("hotspot[transition-hotspots-check].keep", "true");
        krpano.set("hotspot[transition-hotspots-check].zorder", "1000");
        krpano.set("hotspot[transition-hotspots-check].distorted", "true");
        krpano.set("hotspot[transition-hotspots-check].scale", "0.66");

        var hs_hts_check = krpano.get("hotspot[transition-hotspots-check]");
        hs_hts_check.onclick = function() {
            global_menu_toggle_checkbox("tarnsition-hotspots");
        }

        // krpano.call("addhotspot(sound-check);");
        // krpano.set("hotspot[sound-check].type", "image");
        // krpano.set("hotspot[sound-check].ath", ath);
        // krpano.set("hotspot[sound-check].atv", "0");
        // krpano.set("hotspot[sound-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");
        // krpano.set("hotspot[sound-check].ox", "142px");
        // krpano.set("hotspot[sound-check].oy", "90px");
        // krpano.set("hotspot[sound-check].alpha", "1.0");
        // krpano.set("hotspot[sound-check].visible", "true");
        // krpano.set("hotspot[sound-check].keep", "true");
        // krpano.set("hotspot[sound-check].zorder", "1000");
        // krpano.set("hotspot[sound-check].distorted", "true");
        // krpano.set("hotspot[sound-check].scale", "0.66");

        // var hs_sound_check = krpano.get("hotspot[sound-check]");
        // hs_sound_check.onclick = function() {
        //     global_menu_toggle_checkbox("sound");
        // }
    }

    function remove_submenu_settings_items() {
        if (krpano) {
            let elements = ['advertisement-text', 'hotspots-text', 'sound-text', 'transition-hotspots-text', 'advertisement-check', 'hotspots-check', 'sound-check', 'transition-hotspots-check' ];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    function remove_submenu_layers_items() {
        if (krpano) {
            let elements = ['layer1-text', 'layer2-text', 'layer1-check', 'layer2-check'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    // Obsah pre Global info
    function add_submenu_global_info() {
        krpano.call("addhotspot(info-image-left);");
        krpano.set("hotspot[info-image-left].type", "image");
        krpano.set("hotspot[info-image-left].ath", "0.0");
        krpano.set("hotspot[info-image-left].atv", "0.0");
        krpano.set("hotspot[info-image-left].url", 'assets/'+settings.components[8].data.img);
        krpano.set("hotspot[info-image-left].width", "270");
        krpano.set("hotspot[info-image-left].height", "prop");
        krpano.set("hotspot[info-image-left].edge", "righttop");
        krpano.set("hotspot[info-image-left].ox", "0");
        krpano.set("hotspot[info-image-left].oy", "-120px");
        krpano.set("hotspot[info-image-left].alpha", "0.0");
        krpano.set("hotspot[info-image-left].visible", "false");
        krpano.set("hotspot[info-image-left].keep", "true");
        krpano.set("hotspot[info-image-left].zorder", "1000");
        krpano.set("hotspot[info-image-left].distorted", "true");;
        krpano.set("hotspot[info-image-left].scale", "0.66");
        krpano.set("hotspot[info-image-left].bgroundedge", "12");

        krpano.call("addhotspot(info-content);");
        krpano.set("hotspot[info-content].type", "text");
        krpano.set("hotspot[info-content].ath", "0.0");
        krpano.set("hotspot[info-content].atv", "0.0");
        krpano.set("hotspot[info-content].html", settings.components[8].data.content);
        krpano.set("hotspot[info-content].ox", "20");
        krpano.set("hotspot[info-content].oy", "-120px");
        krpano.set("hotspot[info-content].alpha", "0.0");
        krpano.set("hotspot[info-content].visible", "false");
        krpano.set("hotspot[info-content].keep", "true");
        krpano.set("hotspot[info-content].zorder", "1000");
        krpano.set("hotspot[info-content].distorted", "true");
        krpano.set("hotspot[info-content].width", "350px");
        krpano.set("hotspot[info-content].height", "");
        krpano.set("hotspot[info-content].css", "color:#3F3F46; text-align:left;font-size:14px;color:#3f3f46;");
        krpano.set("hotspot[info-content].bgalpha", "0");
        krpano.set("hotspot[info-content].scale", "0.66");
        krpano.set("hotspot[info-content].edge", "lefttop");
        krpano.set("hotspot[info-content].vcenter", "true");
    }

    function remove_submenu_global_info() {
        if (krpano) {
            let elements = ['info-image-left','info-content' ];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    function add_submenu_layers_items() {

        // Layer 1
        krpano.call("addhotspot(layer1-text);");
        krpano.set("hotspot[layer1-text].type", "text");
        krpano.set("hotspot[layer1-text].ath", ath);
        krpano.set("hotspot[layer1-text].atv", "0");
        krpano.set("hotspot[layer1-text].html", $t("components")['vr']['zariadeny']);
        krpano.set("hotspot[layer2-text].edge", "bottom");
        krpano.set("hotspot[layer1-text].ox", "0px");
        krpano.set("hotspot[layer1-text].oy", "-15px");
        krpano.set("hotspot[layer1-text].alpha", "1.0");
        krpano.set("hotspot[layer1-text].visible", "true");
        krpano.set("hotspot[layer1-text].keep", "true");
        krpano.set("hotspot[layer1-text].zorder", "1000");
        krpano.set("hotspot[layer1-text].distorted", "true");
        krpano.set("hotspot[layer1-text].width", "500px");
        krpano.set("hotspot[layer1-text].height", "64px");
        krpano.set("hotspot[layer1-text].bgcolor", "0xfafafa");
        krpano.set("hotspot[layer1-text].bgroundedge", "12");
        krpano.set("hotspot[layer1-text].vcenter", "true");
        krpano.set("hotspot[layer1-text].scale", "0.66");
        krpano.set("hotspot[layer1-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");
        layers_hotspot_to_delete.push("layer1-text");

        var hs_layer1 = krpano.get("hotspot[layer1-text]");
        hs_layer1.onclick = function() {
            //global_menu_toggle_checkbox("layer1");
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");  
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
            //krpano.set("dizajn", "zar");
            dizajn.update(n => "zar");

        }

        // Layer2
        krpano.call("addhotspot(layer2-text);");
        krpano.set("hotspot[layer2-text].type", "text");
        krpano.set("hotspot[layer2-text].ath", ath);
        krpano.set("hotspot[layer2-text].atv", "0");
        krpano.set("hotspot[layer2-text].html", $t("components")['vr']['holobyt']);
        krpano.set("hotspot[layer2-text].edge", "top");
        krpano.set("hotspot[layer2-text].ox", "0px");
        krpano.set("hotspot[layer2-text].oy", "15px");
        krpano.set("hotspot[layer2-text].alpha", "1.0");
        krpano.set("hotspot[layer2-text].visible", "true");
        krpano.set("hotspot[layer2-text].keep", "true");
        krpano.set("hotspot[layer2-text].zorder", "1000");
        krpano.set("hotspot[layer2-text].distorted", "true");
        krpano.set("hotspot[layer2-text].width", "500px");
        krpano.set("hotspot[layer2-text].height", "64px");
        krpano.set("hotspot[layer2-text].bgcolor", "0xfafafa");
        krpano.set("hotspot[layer2-text].bgroundedge", "12");
        krpano.set("hotspot[layer2-text].vcenter", "true");
        krpano.set("hotspot[layer2-text].scale", "0.66");
        krpano.set("hotspot[layer2-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");
        layers_hotspot_to_delete.push("layer2-text");

        var hs_layer2 = krpano.get("hotspot[layer2-text]");
        hs_layer2.onclick = function() {
            //global_menu_toggle_checkbox("layer2");
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");  
            //krpano.set("dizajn", "holo");
            dizajn.update(n => "holo");
        }

        // checkbox layer1
        krpano.call("addhotspot(layer1-check);");
        krpano.set("hotspot[layer1-check].type", "image");
        krpano.set("hotspot[layer1-check].ath", ath);
        krpano.set("hotspot[layer1-check].atv", "0");
        layers_hotspot_to_delete.push("layer1-check");

        if (_dizajn == "zar") {
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");
        } else {
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");
        }
        
        krpano.set("hotspot[layer1-check].ox", "142px");
        krpano.set("hotspot[layer1-check].oy", "-15px");
        krpano.set("hotspot[layer1-check].alpha", "1.0");
        krpano.set("hotspot[layer1-check].visible", "true");
        krpano.set("hotspot[layer1-check].keep", "true");
        krpano.set("hotspot[layer1-check].zorder", "1000");
        krpano.set("hotspot[layer1-check].distorted", "true");
        krpano.set("hotspot[layer1-check].scale", "0.66");

        krpano.call("addhotspot(layer2-check);");
        krpano.set("hotspot[layer2-check].type", "image");
        krpano.set("hotspot[layer2-check].ath", ath);
        krpano.set("hotspot[layer2-check].atv", "0");
        layers_hotspot_to_delete.push("layer2-check");

        if (_dizajn == "holo") {
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");
        } else {
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");
        }


        krpano.set("hotspot[layer2-check].ox", "142px");
        krpano.set("hotspot[layer2-check].oy", "36px");
        krpano.set("hotspot[layer2-check].alpha", "1.0");
        krpano.set("hotspot[layer2-check].visible", "true");
        krpano.set("hotspot[layer2-check].keep", "true");
        krpano.set("hotspot[layer2-check].zorder", "1000");
        krpano.set("hotspot[layer2-check].distorted", "true");
        krpano.set("hotspot[layer2-check].scale", "0.66");

        var hs_layer1_check = krpano.get("hotspot[layer1-check]");
        hs_layer1_check.onclick = function() {
            //global_menu_toggle_checkbox("layer1");
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");  
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
            dizajn.update(n => "zar");
        }

        var hs_layer2_check = krpano.get("hotspot[layer2-check]");
        hs_layer2_check.onclick = function() {
            //global_menu_toggle_checkbox("layer2");
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-off.svg"); 
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");   
            dizajn.update(n => "holo");
        }

    }

    // Pridanie obsahu pre submenu Shortcuts
    function add_submenu_shortcuts() {
        krpano.call("addhotspot(sc-controls-bg);");
        krpano.set("hotspot[sc-controls-bg].type", "text");
        krpano.set("hotspot[sc-controls-bg].ath", ath);
        krpano.set("hotspot[sc-controls-bg].atv", "0");
        krpano.set("hotspot[sc-controls-bg].ox", "0px");
        krpano.set("hotspot[sc-controls-bg].oy", "170px");
        krpano.set("hotspot[sc-controls-bg].alpha", "1.0");
        krpano.set("hotspot[sc-controls-bg].visible", "true");
        krpano.set("hotspot[sc-controls-bg].enabled", "false");
        krpano.set("hotspot[sc-controls-bg].keep", "true");
        krpano.set("hotspot[sc-controls-bg].zorder", "1000");
        krpano.set("hotspot[sc-controls-bg].distorted", "true");
        krpano.set("hotspot[sc-controls-bg].width", "900px");
        krpano.set("hotspot[sc-controls-bg].height", "48px");
        krpano.set("hotspot[sc-controls-bg].bgroundedge", "0 0 12 12");
        krpano.set("hotspot[sc-controls-bg].bgalpha", "0");
        krpano.set("hotspot[sc-controls-bg].bgcolor", "0x3f3f46");
        krpano.set("hotspot[sc-controls-bg].scale", "0.66");
        krpano.set("hotspot[sc-controls-bg].cursor", "default");

        krpano.call("addhotspot(automatic-tour);");
        krpano.set("hotspot[automatic-tour].type", "text");
        krpano.set("hotspot[automatic-tour].html", $t("components")['vr']['automatic-tour']);
        krpano.set("hotspot[automatic-tour].ath", ath);
        krpano.set("hotspot[automatic-tour].atv", "0");
        krpano.set("hotspot[automatic-tour].ox", "-276px");
        krpano.set("hotspot[automatic-tour].oy", "170px");
        krpano.set("hotspot[automatic-tour].alpha", "0.0");
        krpano.set("hotspot[automatic-tour].visible", "false");
        krpano.set("hotspot[automatic-tour].keep", "true");
        krpano.set("hotspot[automatic-tour].zorder", "1000");
        krpano.set("hotspot[automatic-tour].scale", "0.66");
        krpano.set("hotspot[automatic-tour].distorted", "true");
        krpano.set("hotspot[automatic-tour].width", "150px");
        krpano.set("hotspot[automatic-tour].height", "48px");
        krpano.set("hotspot[automatic-tour].bgroundedge", "0 0 12 12");
        krpano.set("hotspot[automatic-tour].bgalpha", "0");
        krpano.set("hotspot[automatic-tour].bgcolor", "0x3f3f46");
        krpano.set("hotspot[automatic-tour].edge", "left");
        krpano.set("hotspot[automatic-tour].vcenter", "true");
        krpano.set("hotspot[automatic-tour].css", "color:#3F3F46; text-align:left;font-size:16px;font-weight:500;");


        krpano.call("addhotspot(at-pagination);");
        krpano.set("hotspot[at-pagination].type", "text");
        // krpano.set("hotspot[at-pagination].html", "[b]06 [/b]  / 12");
        krpano.set("hotspot[at-pagination].ath", ath);
        krpano.set("hotspot[at-pagination].atv", "0");
        krpano.set("hotspot[at-pagination].ox", "276px");
        krpano.set("hotspot[at-pagination].oy", "170px");
        krpano.set("hotspot[at-pagination].alpha", "0.0");
        krpano.set("hotspot[at-pagination].visible", "false");
        krpano.set("hotspot[at-pagination].keep", "true");
        krpano.set("hotspot[at-pagination].zorder", "1000");
        krpano.set("hotspot[at-pagination].distorted", "true");
        krpano.set("hotspot[at-pagination].scale", "0.66");
        krpano.set("hotspot[at-pagination].width", "150px");
        krpano.set("hotspot[at-pagination].height", "48px");
        krpano.set("hotspot[at-pagination].bgroundedge", "0 0 12 12");
        krpano.set("hotspot[at-pagination].bgalpha", "0");
        krpano.set("hotspot[at-pagination].bgcolor", "0x3f3f46");
        krpano.set("hotspot[at-pagination].edge", "right");
        krpano.set("hotspot[at-pagination].vcenter", "true");
        krpano.set("hotspot[at-pagination].css", "color:#3F3F46; text-align:right;font-size:16px;font-weight:500;");

        krpano.call("addhotspot(at-play);");
        krpano.set("hotspot[at-play].type", "image");
        krpano.set("hotspot[at-play].url", userdata["cdn_path"] + "images/play.svg");
        krpano.set("hotspot[at-play].ath", ath);
        krpano.set("hotspot[at-play].atv", "0");
        krpano.set("hotspot[at-play].ox", "0px");
        krpano.set("hotspot[at-play].oy", "170px");
        krpano.set("hotspot[at-play].alpha", "0.0");
        krpano.set("hotspot[at-play].visible", "false");
        krpano.set("hotspot[at-play].keep", "true");
        krpano.set("hotspot[at-play].zorder", "1000");
        krpano.set("hotspot[at-play].distorted", "true");
        krpano.set("hotspot[at-play].scale", "0.66");
        // krpano.set("hotspot[at-play].onclick", "js(auto_tour(true););");

        krpano.call("addhotspot(at-stop);");
        krpano.set("hotspot[at-stop].type", "image");
        krpano.set("hotspot[at-stop].url", userdata["cdn_path"] + "images/stop.svg");
        krpano.set("hotspot[at-stop].ath", ath);
        krpano.set("hotspot[at-stop].atv", "0");
        krpano.set("hotspot[at-stop].ox", "0px");
        krpano.set("hotspot[at-stop].oy", "170px");
        krpano.set("hotspot[at-stop].alpha", "0.0");
        krpano.set("hotspot[at-stop].visible", "false");
        krpano.set("hotspot[at-stop].keep", "true");
        krpano.set("hotspot[at-stop].zorder", "1100");
        krpano.set("hotspot[at-stop].distorted", "true");
        krpano.set("hotspot[at-stop].scale", "0.66");
        // krpano.set("hotspot[at-stop].onclick", "js(auto_tour(false););");


        krpano.call("addhotspot(at-previous);");
        krpano.set("hotspot[at-previous].type", "image");
        krpano.set("hotspot[at-previous].url", userdata["cdn_path"] + "images/previous.svg");
        krpano.set("hotspot[at-previous].ath", ath);
        krpano.set("hotspot[at-previous].atv", "0");
        krpano.set("hotspot[at-previous].ox", "-46px");
        krpano.set("hotspot[at-previous].oy", "170px");
        krpano.set("hotspot[at-previous].alpha", "0.0");
        krpano.set("hotspot[at-previous].visible", "false");
        krpano.set("hotspot[at-previous].keep", "true");
        krpano.set("hotspot[at-previous].zorder", "1000");
        krpano.set("hotspot[at-previous].distorted", "true");
        krpano.set("hotspot[at-previous].scale", "0.66");
        // krpano.set("hotspot[at-previous].onclick", "js(auto_tour(false););");
        // krpano.set("hotspot[at-previous].onclick", "js(switch_per_node('prev', 'per_three'););");

        krpano.call("addhotspot(at-next);");
        krpano.set("hotspot[at-next].type", "image");
        krpano.set("hotspot[at-next].url", userdata["cdn_path"] + "images/next.svg");
        krpano.set("hotspot[at-next].ath", ath);
        krpano.set("hotspot[at-next].atv", "0");
        krpano.set("hotspot[at-next].ox", "46px");
        krpano.set("hotspot[at-next].oy", "170px");
        krpano.set("hotspot[at-next].alpha", "0.0");
        krpano.set("hotspot[at-next].visible", "false");
        krpano.set("hotspot[at-next].keep", "true");
        krpano.set("hotspot[at-next].zorder", "1000");
        krpano.set("hotspot[at-next].distorted", "true");
        krpano.set("hotspot[at-next].scale", "0.66");
        // krpano.set("hotspot[at-next].onclick", "js(switch_per_node('next', 'per_three'););");
        
        krpano.call("addhotspot(sc-image-center);");
        krpano.set("hotspot[sc-image-center].type", "image");
        // krpano.set("hotspot[sc-image-center].url", "assets/krpano/scenes/praha_3_thumb_vr.jpg");
        krpano.set("hotspot[sc-image-center].ath", ath);
        krpano.set("hotspot[sc-image-center].atv", "0");
        krpano.set("hotspot[sc-image-center].width", "244px");
        krpano.set("hotspot[sc-image-center].ox", "0px");
        krpano.set("hotspot[sc-image-center].oy", "0px");
        krpano.set("hotspot[sc-image-center].alpha", "0.0");
        krpano.set("hotspot[sc-image-center].visible", "false");
        krpano.set("hotspot[sc-image-center].keep", "true");
        krpano.set("hotspot[sc-image-center].zorder", "1000");
        krpano.set("hotspot[sc-image-center].distorted", "true");
        krpano.set("hotspot[sc-image-center].scale", "0.66");
        // krpano.set("hotspot[sc-image-center].onclick", "js(open_scene('praha_3'););");


        krpano.call("addhotspot(sc-image-left);");
        krpano.set("hotspot[sc-image-left].type", "image");
        // krpano.set("hotspot[sc-image-left].url", "assets/krpano/scenes/praha_0_thumb_vr.jpg");
        krpano.set("hotspot[sc-image-left].ath", ath);
        krpano.set("hotspot[sc-image-left].atv", "0");
        krpano.set("hotspot[sc-image-left].width", "244px");
        krpano.set("hotspot[sc-image-left].ox", "-198px");
        krpano.set("hotspot[sc-image-left].oy", "0px");
        krpano.set("hotspot[sc-image-left].alpha", "0.0");
        krpano.set("hotspot[sc-image-left].visible", "false");
        krpano.set("hotspot[sc-image-left].keep", "true");
        krpano.set("hotspot[sc-image-left].zorder", "1000");
        krpano.set("hotspot[sc-image-left].distorted", "true");
        krpano.set("hotspot[sc-image-left].scale", "0.66");
        // krpano.set("hotspot[sc-image-left].onclick", "js(open_scene('praha_0'););");

        krpano.call("addhotspot(sc-image-right);");
        krpano.set("hotspot[sc-image-right].type", "image");
        // krpano.set("hotspot[sc-image-right].url", "assets/krpano/scenes/praha_9_thumb_vr.jpg");
        krpano.set("hotspot[sc-image-right].ath", ath);
        krpano.set("hotspot[sc-image-right].atv", "0");
        krpano.set("hotspot[sc-image-right].width", "244px");
        krpano.set("hotspot[sc-image-right].ox", "198px");
        krpano.set("hotspot[sc-image-right].oy", "0px");
        krpano.set("hotspot[sc-image-right].alpha", "0.0");
        krpano.set("hotspot[sc-image-right].visible", "false");
        krpano.set("hotspot[sc-image-right].keep", "true");
        krpano.set("hotspot[sc-image-right].zorder", "1000");
        krpano.set("hotspot[sc-image-right].distorted", "true");
        krpano.set("hotspot[sc-image-right].scale", "0.66");
        // krpano.set("hotspot[sc-image-right].onclick", "js(open_scene('praha_9'););");

        krpano.call("addhotspot(thumb-title-center);");
        krpano.set("hotspot[thumb-title-center].type", "text");
        krpano.set("hotspot[thumb-title-center].ath", ath);
        krpano.set("hotspot[thumb-title-center].atv", "0");
        krpano.set("hotspot[thumb-title-center].distorted", "true");
        krpano.set("hotspot[thumb-title-center].html", "Img #2");
        krpano.set("hotspot[thumb-title-center].ox", "0");
        krpano.set("hotspot[thumb-title-center].oy", "138px");
        krpano.set("hotspot[thumb-title-center].alpha", "0.0");
        krpano.set("hotspot[thumb-title-center].visible", "false");
        krpano.set("hotspot[thumb-title-center].keep", "true");
        krpano.set("hotspot[thumb-title-center].bgcolor", "0x3f3f46");
        krpano.set("hotspot[thumb-title-center].width", "244px");
        krpano.set("hotspot[thumb-title-center].bgalpha", "0.6");
        krpano.set("hotspot[thumb-title-center].css", "color:#fafafa; font-size: 14px; text-align:center;");
        krpano.set("hotspot[thumb-title-center].zorder", "1000");
        krpano.set("hotspot[thumb-title-center].scale", "0.66");

        krpano.call("addhotspot(thumb-title-left);");
        krpano.set("hotspot[thumb-title-left].type", "text");
        krpano.set("hotspot[thumb-title-left].ath", ath);
        krpano.set("hotspot[thumb-title-left].atv", "0");
        krpano.set("hotspot[thumb-title-left].distorted", "true");
        krpano.set("hotspot[thumb-title-left].html", "Img #1");
        krpano.set("hotspot[thumb-title-left].ox", "-198px");
        krpano.set("hotspot[thumb-title-left].oy", "138px");
        krpano.set("hotspot[thumb-title-left].alpha", "0.0");
        krpano.set("hotspot[thumb-title-left].visible", "false");
        krpano.set("hotspot[thumb-title-left].keep", "true");
        krpano.set("hotspot[thumb-title-left].bgcolor", "0x3f3f46");
        krpano.set("hotspot[thumb-title-left].width", "244px");
        krpano.set("hotspot[thumb-title-left].bgalpha", "0.6");
        krpano.set("hotspot[thumb-title-left].css", "color:#fafafa; font-size: 14px; text-align:center;");
        krpano.set("hotspot[thumb-title-left].zorder", "1000");
        krpano.set("hotspot[thumb-title-left].scale", "0.66");

        krpano.call("addhotspot(thumb-title-right);");
        krpano.set("hotspot[thumb-title-right].type", "text");
        krpano.set("hotspot[thumb-title-right].ath", ath);
        krpano.set("hotspot[thumb-title-right].atv", "0");
        krpano.set("hotspot[thumb-title-right].distorted", "true");
        krpano.set("hotspot[thumb-title-right].html", "Img #3");
        krpano.set("hotspot[thumb-title-right].ox", "198px");
        krpano.set("hotspot[thumb-title-right].oy", "138px");
        krpano.set("hotspot[thumb-title-right].alpha", "0.0");
        krpano.set("hotspot[thumb-title-right].visible", "false");
        krpano.set("hotspot[thumb-title-right].keep", "true");
        krpano.set("hotspot[thumb-title-right].bgcolor", "0x3f3f46");
        krpano.set("hotspot[thumb-title-right].width", "244px");
        krpano.set("hotspot[thumb-title-right].bgalpha", "0.6");
        krpano.set("hotspot[thumb-title-right].css", "color:#fafafa; font-size: 14px; text-align:center;");
        krpano.set("hotspot[thumb-title-right].zorder", "1000");
        krpano.set("hotspot[thumb-title-right].scale", "0.66");

        krpano.call("addhotspot(image-center-empty);");
        krpano.set("hotspot[image-center-empty].type", "text");
        krpano.set("hotspot[image-center-empty].ath", ath);
        krpano.set("hotspot[image-center-empty].atv", "0");
        krpano.set("hotspot[image-center-empty].ox", "0px");
        krpano.set("hotspot[image-center-empty].oy", "0px");
        krpano.set("hotspot[image-center-empty].alpha", "0.0");
        krpano.set("hotspot[image-center-empty].visible", "false");
        krpano.set("hotspot[image-center-empty].keep", "true");
        krpano.set("hotspot[image-center-empty].bgalpha", "1");
        krpano.set("hotspot[image-center-empty].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[image-center-empty].zorder", "1000");
        krpano.set("hotspot[image-center-empty].distorted", "true");
        krpano.set("hotspot[image-center-empty].scale", "0.66");
        // krpano.set("hotspot[image-center-empty].onclick", "js(open_scene('praha_3'););");

        krpano.call("addhotspot(image-left-empty);");
        krpano.set("hotspot[image-left-empty].type", "text");
        krpano.set("hotspot[image-left-empty].ath", ath);
        krpano.set("hotspot[image-left-empty].atv", "0");
        krpano.set("hotspot[image-left-empty].ox", "-198px");
        krpano.set("hotspot[image-left-empty].oy", "0px");
        krpano.set("hotspot[image-left-empty].alpha", "0.0");
        krpano.set("hotspot[image-left-empty].visible", "false");
        krpano.set("hotspot[image-left-empty].keep", "true");
        krpano.set("hotspot[image-left-empty].bgalpha", "0");
        krpano.set("hotspot[image-left-empty].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[image-left-empty].zorder", "1000");
        krpano.set("hotspot[image-left-empty].distorted", "true");
        krpano.set("hotspot[image-left-empty].scale", "0.66");
        // krpano.set("hotspot[image-left-empty].onclick", "js(open_scene('praha_0'););");

        krpano.call("addhotspot(image-right-empty);");
        krpano.set("hotspot[image-right-empty].type", "text");
        krpano.set("hotspot[image-right-empty].ath", ath);
        krpano.set("hotspot[image-right-empty].atv", "0");
        krpano.set("hotspot[image-right-empty].ox", "198px");
        krpano.set("hotspot[image-right-empty].oy", "0px");
        krpano.set("hotspot[image-right-empty].alpha", "0.0");
        krpano.set("hotspot[image-right-empty].visible", "false");
        krpano.set("hotspot[image-right-empty].keep", "true");
        krpano.set("hotspot[image-right-empty].bgalpha", "0");
        krpano.set("hotspot[image-right-empty].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[image-right-empty].zorder", "1000");
        krpano.set("hotspot[image-right-empty].distorted", "true");
        krpano.set("hotspot[image-right-empty].scale", "0.66");
        // krpano.set("hotspot[image-right-empty].onclick", "js(open_scene('praha_9'););");

        krpano.call("addhotspot(sc-previous);");
        krpano.set("hotspot[sc-previous].type", "image");
        krpano.set("hotspot[sc-previous].url", userdata["cdn_path"] + "images/sc-previous.svg");
        
        krpano.set("hotspot[sc-previous].ath", ath);
        krpano.set("hotspot[sc-previous].atv", "0");
        krpano.set("hotspot[sc-previous].ox", "-276px");
        krpano.set("hotspot[sc-previous].oy", "0px");
        krpano.set("hotspot[sc-previous].alpha", "0.0");
        krpano.set("hotspot[sc-previous].visible", "false");
        krpano.set("hotspot[sc-previous].keep", "true");
        krpano.set("hotspot[sc-previous].bgalpha", "0");
        krpano.set("hotspot[sc-previous].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[sc-previous].zorder", "1000");
        krpano.set("hotspot[sc-previous].distorted", "true");
        krpano.set("hotspot[sc-previous].scale", "0.66");
        // krpano.set("hotspot[at-previous].onclick", "prevscene());");
        // krpano.set("hotspot[sc-previous].onclick", "js(switch_per_node('prev', 'per_one'););");


        krpano.call("addhotspot(sc-next);");
        krpano.set("hotspot[sc-next].type", "image");
        krpano.set("hotspot[sc-next].url", userdata["cdn_path"] + "images/sc-next.svg");
        
        krpano.set("hotspot[sc-next].ath", ath);
        krpano.set("hotspot[sc-next].atv", "0");
        krpano.set("hotspot[sc-next].ox", "276px");
        krpano.set("hotspot[sc-next].oy", "0px");
        krpano.set("hotspot[sc-next].alpha", "0.0");
        krpano.set("hotspot[sc-next].visible", "false");
        krpano.set("hotspot[sc-next].keep", "true");
        krpano.set("hotspot[sc-next].bgalpha", "1");
        krpano.set("hotspot[sc-next].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[sc-next].zorder", "1000");
        krpano.set("hotspot[sc-next].distorted", "true");
        krpano.set("hotspot[sc-next].scale", "0.66");
        // krpano.set("hotspot[at-next].onclick", "nextscene()");
        // krpano.set("hotspot[sc-next].onclick", "js(switch_per_node('next', 'per_one'););");
    }

    function remove_submenu_shortcuts() {
        if (krpano) {
            let elements = ['[sc-controls-bg','info-content', 'automatic-tour', 'at-pagination', 'at-play', 'at-stop', 'at-previous', 'at-next', 'sc-image-center', 'sc-image-left', 'sc-image-right', 'thumb-title-center', 'thumb-title-left', 'thumb-title-right', 'image-center-empty', 'image-left-empty', 'image-right-empty', 'sc-previous', 'sc-next', ];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    function remove_global_menu_all_items() {
        remove_global_menu_attributes();
        remove_global_menu_floorplan();
        remove_global_menu_info();
        remove_global_menu_layers();
        remove_global_menu_model();
        remove_global_menu_settings();
        remove_global_menu_shop();
        remove_global_menu_shortcuts();

        let elements = layers_hotspot_to_delete;
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
    }

    // vytvorenie hover akcie pre ikony VR menu
    function global_menu_add_hover($id) {
        if (krpano) {
            krpano.set("hotspot["+ $id + "].url", userdata["cdn_path"] + "images/" + $id + "_active.svg" );
            krpano.set("hotspot["+ $id + "-bg].bgborder", global_menu_icon_bg_border);
            krpano.set("hotspot["+ $id + "-bg].bgcolor", global_menu_text_bg_color);
            krpano.set("hotspot["+ $id + "-text].css", global_menu_text_css);
        }
    }

    // odstránenie hover akcie pre ikony VR menu
    function global_menu_remove_hover($id) {
        if (krpano) {
            ////console.log(krpano.get("hotspot["+ $id + "]"));
            if (krpano.get("hotspot["+ $id + "]") != null) {
                krpano.set("hotspot["+ $id + "].url", userdata["cdn_path"] + "images/" + $id + ".svg" );
            }
            
            krpano.set("hotspot["+ $id + "-bg].bgborder", "2.0 0x3F3F46 0.2");
            krpano.set("hotspot["+ $id + "-bg].bgcolor", "0xFAFAFA");
            krpano.set("hotspot["+ $id + "-text].css", global_menu_text_css_normal);
        }
    }

    // akcia na prepínanie checkboxov
    function global_menu_toggle_checkbox($name) {
        //////console.log('togle');
        var krpano = document.getElementById("krpanoSWFObject");
        let url = krpano.get("hotspot[" + $name +"-check].url");
        switch (url) {
            case 'img/checkbox-on.svg' : 
                krpano.set("hotspot[" + $name +"-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
            break;

            case 'img/checkbox-off.svg' : 
                krpano.set("hotspot[" + $name +"-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");  
            break;
        }

        if ( 
            $name == 'layer1' 
        ) {
            krpano.set("hotspot[layer2-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
        }

        if ( 
            $name == 'layer2' 
        ) {
            krpano.set("hotspot[layer1-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
        }

        if ( 
            $name == 'sound' 
        ) {
            //krpano.call("sound[bgsnd].toggle()");
            let current_sound = krpano.get("sound[bgsnd].volume");
            ////console.log(current_sound);
            switch (current_sound) {
                case 1:
                    krpano.set("sound[bgsnd].volume", "0.0");
                    break;
            
                default:
                    krpano.set("sound[bgsnd].volume", "1.0");
                    break;
            }
        }

        if ( $name == 'advertisement' ) {
            switch (url) {
                case userdata["cdn_path"] + 'images/checkbox-on.svg' : 
                // krpano.call("autorotate.stop()");
                break;
        
                case userdata["cdn_path"] + 'images/checkbox-off.svg' : 
                // krpano.call("autorotate.start()");
                break;
            }
        }

        if ( 
            $name == 'hotspots' 
        ) {

            permanently_hotspots.update(n => !n);
            var hts_count = krpano.get("hotspot.count");
            for (let index = 0; index < hts_count; index++) {
                switch (url) {
                    case userdata["cdn_path"] + 'images/checkbox-on.svg' : 
                    krpano.set("hotspot[" + $name +"-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
                            if (
                                krpano.get("hotspot[" + index +"].name").includes("next-node") || 
                                krpano.get("hotspot[" + index +"].name").includes("info") ||
                                krpano.get("hotspot[" + index +"].name").includes("video")
                                
                                ) {
                                    var hts_name = krpano.get("hotspot[" + index +"].name");
                                    krpano.set("hotspot[" + index +"].alpha", "0.0");
                                    krpano.set("hotspot[" + index +"].visible", "false");
                                }
                    break;
            
                    case userdata["cdn_path"] + 'images/checkbox-off.svg' : 
                    if (
                        krpano.get("hotspot[" + index +"].name").includes("next-node") || 
                        krpano.get("hotspot[" + index +"].name").includes("info") ||
                        krpano.get("hotspot[" + index +"].name").includes("video")
                        
                        ) {
                            var hts_name = krpano.get("hotspot[" + index +"].name");
                            krpano.set("hotspot[" + index +"].alpha", "1.0");
                            krpano.set("hotspot[" + index +"].visible", "true");
                        }
                        krpano.set("hotspot[" + $name +"-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");  
                    break;
                }
            }
        }

        if ( 
            $name == 'transition-hotspots' 
        ) {

            permanently_hotspots.update(n => !n);
            var hts_count = krpano.get("hotspot.count");
            for (let index = 0; index < hts_count; index++) {
                switch (url) {
                    case 'img/checkbox-on.svg' : 
                    krpano.set("hotspot[" + $name +"-check].url", userdata["cdn_path"] + "images/checkbox-off.svg");  
                            // if (
                            //     krpano.get("hotspot[" + index +"].name").includes("next-node") || 
                            //     krpano.get("hotspot[" + index +"].name").includes("info") ||
                            //     krpano.get("hotspot[" + index +"].name").includes("video")
                                
                            //     ) {
                            //         var hts_name = krpano.get("hotspot[" + index +"].name");
                            //         krpano.set("hotspot[" + index +"].alpha", "0.0");
                            //         krpano.set("hotspot[" + index +"].visible", "false");
                            //     }
                    break;
            
                    case userdata["cdn_path"] + 'images/checkbox-off.svg' : 
                    // if (
                    //     krpano.get("hotspot[" + index +"].name").includes("next-node") || 
                    //     krpano.get("hotspot[" + index +"].name").includes("info") ||
                    //     krpano.get("hotspot[" + index +"].name").includes("video")
                        
                    //     ) {
                    //         var hts_name = krpano.get("hotspot[" + index +"].name");
                    //         krpano.set("hotspot[" + index +"].alpha", "1.0");
                    //         krpano.set("hotspot[" + index +"].visible", "true");
                    //     }
                        krpano.set("hotspot[" + $name +"-check].url", userdata["cdn_path"] + "images/checkbox-on.svg");  
                    break;
                }
            }
        }
    }

let enabled_move_icon = true;

function menu_icon($value) {
    var krpano = document.getElementById("krpanoSWFObject");
    //////////console.log('hover');
    let hotspotID = "menu";
    switch ($value) {
        case 'true' : 
            enabled_move_icon = false;
            krpano.set("hotspot["+ hotspotID + "].alpha", "1.0");
        break;

        case 'false' : 
            enabled_move_icon = true;
        break;
    }
}

let hotspot_to_delete = [];
let thumbs_to_delete = [];


let pagination = 0;
if (_config_products != undefined) {
    pagination = Math.floor(_config_products.length / 10);
}
let current_pagination = 0;

function add_product_detail () {
    vr_safe_data.update(n => false);
    remove_basket();
    remove_global_menu();
    let konfiguracia = _config_products;
    hotspot_to_delete = [];

    for (let index = 0; index < thumbs_to_delete.length; index++) {
        krpano.call("removehotspot(" + thumbs_to_delete[index] + ")");
    }

    thumbs_to_delete = [];

    if (get_ath) {
        get_ath_atv();
    }
    
    let active_product = parseInt(get(active_product_id));
    if (active_product < 0) {
        
        active_product = parseInt(_all_products[0].id)
    } 

    let category_name = "";
    let variant_title = "";
    let variant_price = "";
    let variant_price_suffix = "";
    let variant_description = "";

    _all_products.forEach(produkt => {
        if (parseInt(produkt.id) == active_product) {
            category_name = produkt.name;
        }
    });

    function get_title_price_description() {
        konfiguracia.forEach(config_product => {
            if (parseInt(config_product.product_id) == parseInt(active_product)) {
                _config_product_variants.forEach(variant => {
                    if (parseInt(variant.id) == parseInt(config_product.active_product_variant) ) {
                        variant_title = variant.name;

                        
                        variant_price = variant.price;
                        variant_price_suffix = " / " + config_product.mj;
                        

                        let description_max_length = 330;
                        if (variant.description.length > description_max_length) {
                            variant_description = variant.description.substring(0, description_max_length) + " ...";
                        } else {
                            variant_description = variant.description;
                        }
                        
                    }
                });
            }
        });
    }

    get_title_price_description();
    

    // TMP pozadie
    krpano.call("addhotspot(product-bg);");
    krpano.set("hotspot[product-bg].type", "text");
    krpano.set("hotspot[product-bg].ath", ath);
    krpano.set("hotspot[product-bg].atv", 0);
    krpano.set("hotspot[product-bg].width", "900");
    krpano.set("hotspot[product-bg].height", "560px");
    krpano.set("hotspot[product-bg].scale", "0.66");
    krpano.set("hotspot[product-bg].distorted", "true");
    krpano.set("hotspot[product-bg].bgcolor", "0xfafafa");
    krpano.set("hotspot[product-bg].alpha", "0.6");
    krpano.set("hotspot[product-bg].bgroundedge", "12");
    krpano.set("hotspot[product-bg].cursor", "default");
    krpano.set("hotspot[product-bg].visible", true);
    krpano.set("hotspot[product-bg].keep", "true");
    krpano.set("hotspot[product-bg].zorder", 900);
    krpano.set("hotspot[product-bg].enabled", false);

    hotspot_to_delete.push("product-bg");

    // biele pozadie pre content
    krpano.call("addhotspot(product-content-bg);");
    krpano.set("hotspot[product-content-bg].type", "text");
    krpano.set("hotspot[product-content-bg].ath", ath);
    krpano.set("hotspot[product-content-bg].atv", 0);
    krpano.set("hotspot[product-content-bg].width", "868");
    krpano.set("hotspot[product-content-bg].height", "488px");
    krpano.set("hotspot[product-content-bg].scale", "0.66");
    krpano.set("hotspot[product-content-bg].distorted", "true");
    krpano.set("hotspot[product-content-bg].bgcolor", "0xfafafa");
    krpano.set("hotspot[product-content-bg].alpha", "1");
    krpano.set("hotspot[product-content-bg].bgroundedge", "12");
    krpano.set("hotspot[product-content-bg].cursor", "default");
    krpano.set("hotspot[product-content-bg].visible", true);
    krpano.set("hotspot[product-content-bg].keep", "true");
    krpano.set("hotspot[product-content-bg].zorder", 900);
    krpano.set("hotspot[product-content-bg].enabled", false);
    krpano.set("hotspot[product-content-bg].oy", "10px");
    hotspot_to_delete.push("product-content-bg");
    
    // nazov kategorie
    krpano.call("addhotspot(category-name);");
    krpano.set("hotspot[category-name].type", "text");
    krpano.set("hotspot[category-name].ath", ath);
    krpano.set("hotspot[category-name].atv", "0");
    krpano.set("hotspot[category-name].html", category_name);
    krpano.set("hotspot[category-name].ox", "-10px");
    krpano.set("hotspot[category-name].oy", "-145px");
    krpano.set("hotspot[category-name].alpha", "1.0");
    krpano.set("hotspot[category-name].visible", true);
    krpano.set("hotspot[category-name].keep", "true");
    krpano.set("hotspot[category-name].zorder", "900");
    krpano.set("hotspot[category-name].distorted", "true");
    krpano.set("hotspot[category-name].width", "425px");
    krpano.set("hotspot[category-name].height", "40px");
    krpano.set("hotspot[category-name].edge", "lefttop");
    krpano.set("hotspot[category-name].bgalpha", "0");
    krpano.set("hotspot[category-name].vcenter", "true");
    krpano.set("hotspot[category-name].cursor", "default");
    krpano.set("hotspot[category-name].scale", "0.66");
    krpano.set("hotspot[category-name].css", "color:#3F3F46; text-align:left;font-size:18px;color:#3f3f46;");
    krpano.set("hotspot[category-name].enabled", false);
    hotspot_to_delete.push("category-name");

    function generate_product_title() {
        // nazov produktu - variantu
        krpano.call("addhotspot(product-name);");
        krpano.set("hotspot[product-name].type", "text");
        krpano.set("hotspot[product-name].ath", ath);
        krpano.set("hotspot[product-name].atv", "0");
        krpano.set("hotspot[product-name].html", variant_title);
        krpano.set("hotspot[product-name].ox", "-10px");
        krpano.set("hotspot[product-name].oy", "-120px");
        krpano.set("hotspot[product-name].alpha", "1.0");
        krpano.set("hotspot[product-name].visible", true);
        krpano.set("hotspot[product-name].keep", "true");
        krpano.set("hotspot[product-name].zorder", "900");
        krpano.set("hotspot[product-name].distorted", "true");
        krpano.set("hotspot[product-name].width", "425px");
        krpano.set("hotspot[product-name].height", "40px");
        krpano.set("hotspot[product-name].edge", "lefttop");
        krpano.set("hotspot[product-name].bgalpha", "0");
        krpano.set("hotspot[product-name].vcenter", "true");
        krpano.set("hotspot[product-name].cursor", "default");
        krpano.set("hotspot[product-name].scale", "0.66");
        krpano.set("hotspot[product-name].css", "color:#3F3F46; text-align:left;font-size:16px;color:#3f3f46;");
        krpano.set("hotspot[product-name].enabled", false);
        hotspot_to_delete.push("product-name");
    }

    generate_product_title();


    function generate_product_price() {
        // cena produktu - variantu
        krpano.call("addhotspot(product-price);");
        krpano.set("hotspot[product-price].type", "text");
        krpano.set("hotspot[product-price].ath", ath);
        krpano.set("hotspot[product-price].atv", "0");
        krpano.set("hotspot[product-price].html", formatter.format(variant_price) + variant_price_suffix);
        krpano.set("hotspot[product-price].ox", "-10px");
        krpano.set("hotspot[product-price].oy", "-95px");
        krpano.set("hotspot[product-price].alpha", "1.0");
        krpano.set("hotspot[product-price].visible", true);
        krpano.set("hotspot[product-price].keep", "true");
        krpano.set("hotspot[product-price].zorder", "900");
        krpano.set("hotspot[product-price].distorted", "true");
        krpano.set("hotspot[product-price].width", "425px");
        krpano.set("hotspot[product-price].height", "40px");
        krpano.set("hotspot[product-price].edge", "lefttop");
        krpano.set("hotspot[product-price].bgalpha", 0);
        krpano.set("hotspot[product-price].vcenter", "true");
        krpano.set("hotspot[product-price].cursor", "default");
        krpano.set("hotspot[product-price].scale", "0.66");
        krpano.set("hotspot[product-price].css", "color:#3F3F46; text-align:left;font-size:18px;color:#3f3f46;font-weight: 900;");
        krpano.set("hotspot[product-price].enabled", false);
        hotspot_to_delete.push("product-price");
    }

    generate_product_price();

    
    // button pre ulozenie nastavenia
    krpano.call("addhotspot(product-choose);");
    krpano.set("hotspot[product-choose].type", "text");
    krpano.set("hotspot[product-choose].ath", ath);
    krpano.set("hotspot[product-choose].atv", "0");
    krpano.set("hotspot[product-choose].html", "Variante bestätigen");
    krpano.set("hotspot[product-choose].ox", "28px");
    krpano.set("hotspot[product-choose].oy", "75px");
    krpano.set("hotspot[product-choose].alpha", "1.0");
    krpano.set("hotspot[product-choose].visible", true);
    krpano.set("hotspot[product-choose].keep", "true");
    krpano.set("hotspot[product-choose].zorder", "900");
    krpano.set("hotspot[product-choose].distorted", "true");
    krpano.set("hotspot[product-choose].width", "320px");
    krpano.set("hotspot[product-choose].height", "40px");
    krpano.set("hotspot[product-choose].edge", "lefttop");
    krpano.set("hotspot[product-choose].bgalpha", "1");
    krpano.set("hotspot[product-choose].bgcolor", "0x4F46E5");
    krpano.set("hotspot[product-choose].bgroundedge", "12px");
    krpano.set("hotspot[product-choose].vcenter", "true");
    krpano.set("hotspot[product-choose].cursor", "default");
    krpano.set("hotspot[product-choose].scale", "0.66");
    krpano.set("hotspot[product-choose].css", "color:white;text-align:center;font-size:16px;");
    krpano.set("hotspot[product-choose].enabled", true);
    hotspot_to_delete.push("product-choose");

    var hts_variant_choose = krpano.get("hotspot[product-choose]");
    hts_variant_choose.onclick = function() {
            vr_safe_data.update(n => false);
            krpano.set("hotspot[product-choose].html", "Ich schalte es ein");
            krpano.set("hotspot[product-choose].bgcolor", "0x3F3F46");
            setTimeout(() => {
                krpano.set("hotspot[product-choose].html", "Variante bestätigen");
                krpano.set("hotspot[product-choose].bgcolor", "0x4F46E5");
            }, 1000);
            konfiguracia.forEach(tmp_produkt => {
                
                if (parseInt(tmp_produkt.product_id) == parseInt(get(active_product_id))) {
                    let obj = {
                        _active_variant : tmp_produkt.active_product_variant,
                        _product_id : parseInt(tmp_produkt.product_id)
                    }
                    tmp_attribute_from_model.update(n => obj);           
                    
                }
            });
        
    }

    function generate_product_description() {
        // popis produktu
        krpano.call("addhotspot(product-description);");
        krpano.set("hotspot[product-description].type", "text");
        krpano.set("hotspot[product-description].ath", ath);
        krpano.set("hotspot[product-description].atv", "0");
        krpano.set("hotspot[product-description].html", variant_description);
        krpano.set("hotspot[product-description].ox", "-10px");
        krpano.set("hotspot[product-description].oy", "-70px");
        krpano.set("hotspot[product-description].alpha", "1.0");
        krpano.set("hotspot[product-description].visible", true);
        krpano.set("hotspot[product-description].keep", "true");
        krpano.set("hotspot[product-description].zorder", "900");
        krpano.set("hotspot[product-description].distorted", "true");
        krpano.set("hotspot[product-description].width", "425px");
        krpano.set("hotspot[product-description].height", "120px");
        krpano.set("hotspot[product-description].edge", "lefttop");
        krpano.set("hotspot[product-description].bgalpha", "0");
        krpano.set("hotspot[product-description].bgroundedge", "12px");
        krpano.set("hotspot[product-description].vcenter", "false");
        krpano.set("hotspot[product-description].cursor", "default");
        krpano.set("hotspot[product-description].scale", "0.66");
        krpano.set("hotspot[product-description].css", "color:#3f3f46;text-align:left;font-size:12px;");
        krpano.set("hotspot[product-description].enabled", false);
        hotspot_to_delete.push("product-description");
    }

    generate_product_description();


    let count = 0;
    let product_variant_thumb = "";


    function generate_thumbnails() {
        konfiguracia.forEach(config_product => {
            if (parseInt(config_product.product_id) == parseInt(active_product)) {
                _config_product_variants.forEach(variant => {
                    if (parseInt(variant.product_id) == parseInt(active_product)) {

                        if (parseInt(variant.id) == parseInt(config_product.active_product_variant)) {
                            product_variant_thumb = variant.variant_id;
                        }

                        let variant_ox = 0;
                        let variant_oy = 0;

                        variant_ox = -10 + (count * 42);

                        if (count < 7) {
                            variant_oy = -10;
                        } else {
                            variant_oy = 32;
                        }

                        // thumbnail pre variant
                        krpano.call("addhotspot(hts_" + variant.id +"_variant-thumbnail);");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].type", "image");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].ath", ath);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].atv", "0");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].ox", variant_ox);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].oy", variant_oy);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].alpha", "1.0");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].visible", true);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].keep", "true");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].zorder", "900");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].distorted", "true");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].width", "50px");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].height", "50px");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].edge", "lefttop");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].cursor", "default");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].scale", "0.66");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].enabled", false);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "thumbnails/" + variant.variant_id + ".jpg");
                        hotspot_to_delete.push("hts_" + variant.id +"_variant-thumbnail");
                        thumbs_to_delete.push("hts_" + variant.id +"_variant-thumbnail");


                        // thumbnail border
                        krpano.call("addhotspot(hts_" + variant.id +"_variant-thumbnail-active);");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].type", "image");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].ath", ath);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].atv", "0");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].ox", variant_ox);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].oy", variant_oy);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].alpha", "1.0");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].visible", true);
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].keep", "true");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].zorder", "900");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].distorted", "true");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].width", "50px");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].height", "50px");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].edge", "lefttop");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].cursor", "default");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].scale", "0.66");
                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].enabled", true);
                        hotspot_to_delete.push("hts_" + variant.id +"_variant-thumbnail-active");
                        thumbs_to_delete.push("hts_" + variant.id +"_variant-thumbnail-active");

                        let variant_thumb = "vr-thumb";

                        if (parseInt(config_product.active_product_variant) == parseInt(variant.id)) {
                            variant_thumb = "vr-thumb-active";
                        } 

                        krpano.set("hotspot[hts_" + variant.id +"_variant-thumbnail-active].url", "assets/thumbnails/" + variant_thumb + ".png");

                        count++;

                        var hts_variants = krpano.get("hotspot[hts_" + variant.id +"_variant-thumbnail-active]");
                        hts_variants.onclick = function() {
                            config_product.active_product_variant = variant.id;
                            count = 0;
                            generate_thumbnails();
                            get_title_price_description();
                            generate_product_title();
                            generate_product_price();
                            generate_product_description();
                            generate_big_img();
                        }
                    }
                });
            }
        });

    }
    generate_thumbnails();
    

    function generate_big_img() {
        // velky produktovy obrazok
        krpano.call("addhotspot(product-image);");
        krpano.set("hotspot[product-image].type", "image");
        krpano.set("hotspot[product-image].ath", ath);
        krpano.set("hotspot[product-image].atv", 0);
        krpano.set("hotspot[product-image].width", "370px");
        krpano.set("hotspot[product-image].height", "370px");
        krpano.set("hotspot[product-image].scale", "0.66");
        krpano.set("hotspot[product-image].distorted", "true");
        krpano.set("hotspot[product-image].cursor", "default");
        krpano.set("hotspot[product-image].visible", true);
        krpano.set("hotspot[product-image].keep", "true");
        krpano.set("hotspot[product-image].zorder", 900);
        krpano.set("hotspot[product-image].enabled", false);
        krpano.set("hotspot[product-image].ox", "-270px");
        krpano.set("hotspot[product-image].oy", "100px");
        krpano.set("hotspot[product-image].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "thumbnails/" + product_variant_thumb + ".jpg");
        krpano.set("hotspot[product-image].edge", "leftbottom");

        hotspot_to_delete.push("product-image");
    }
     
    generate_big_img();
    


    
    
    function generate_products() {

        for (let index = 0; index < konfiguracia.length; index++) {
            
            const element = konfiguracia[index];
            krpano.call("removehotspot(hts_" + element.id + "_category-thumbnail)");
            krpano.call("removehotspot(hts_" + element.id + "_category-thumbnail-active)");
            krpano.call("removehotspot(hts_" + element.id + "_category-thumb-name)");
        }

        let i = 0;
        let thumb_ox,title_ox = 0;
        let name = "";
        let thumbnail = "";
        for (let index = 0; index < konfiguracia.length; index++) {
            const element = konfiguracia[index];

            if (element.type == "product") {

               
                

                _all_products.forEach(product => {
                    if (parseInt(product.id) == parseInt(element.product_id)) {
                        name = product.name;
                    }            
                });
        

               let var_name;


                _config_product_variants.forEach(variant => {
                    if (parseInt(variant.id) == parseInt(element.active_product_variant) ) {
                        thumbnail =  userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "thumbnails/" + variant.variant_id + ".jpg";           
                        var_name = variant.name;
                    }
                });

            
            
                function add_product() {

                    let product_thumb = "vr-thumb";
                    if (parseInt(element.product_id) == parseInt(get(active_product_id))) {
                        
                        product_thumb = "vr-thumb-active";
                    }
                
                    // thumbnail pre kategoriu
                    krpano.call("addhotspot(hts_" + element.id + "_category-thumbnail);");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].type", "image");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].ath", ath);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].atv", "0");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].ox", thumb_ox);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].oy", "111px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].alpha", "1.0");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].visible", true);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].keep", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].zorder", "900");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].distorted", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].width", "55px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].height", "55px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].edge", "lefttop");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].cursor", "default");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].scale", "0.66");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].enabled", false);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail].url", thumbnail);
                    hotspot_to_delete.push("hts_" + element.id + "_category-thumbnail");

                    krpano.call("addhotspot(hts_" + element.id + "_category-thumbnail-active);");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].type", "image");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].ath", ath);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].atv", 0);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].url", "assets/thumbnails/" + product_thumb + ".png");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].edge", "lefttop");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].ox", thumb_ox);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].oy", "111px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].alpha", "1.0");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].visible", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].enabled", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].keep", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].width", "55");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].height", "55px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].scale", "0.66");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].zorder", "1100");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumbnail-active].distorted", "true");
                    hotspot_to_delete.push("hts_" + element.id + "_category-thumbnail-active");

                    // nazov kategorie pod thumbnailom
                    krpano.call("addhotspot(hts_" + element.id + "_category-thumb-name);");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].type", "text");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].ath", ath);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].atv", "0");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].html", name);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].ox", title_ox);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].oy", "148px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].alpha", "1.0");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].visible", true);
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].keep", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].zorder", "900");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].distorted", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].width", "80px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].height", "30px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].edge", "top");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].bgalpha", "0");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].bgroundedge", "12px");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].vcenter", "true");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].cursor", "default");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].scale", "0.66");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].css", "color:3f3f46;text-align:center;font-size:12px;");
                    krpano.set("hotspot[hts_" + element.id + "_category-thumb-name].enabled", false);
                    hotspot_to_delete.push("hts_" + element.id + "_category-thumb-name");

                    var hs_cat_var_img = krpano.get("hotspot[hts_" + element.id + "_category-thumbnail]");
                    hs_cat_var_img.onclick = function() {
                        active_product_id.update(n => parseInt(element.product_id));
                    }

                    var hs_cat_var_img = krpano.get("hotspot[hts_" + element.id + "_category-thumbnail-active]");
                    hs_cat_var_img.onclick = function() {
                        active_product_id.update(n => parseInt(element.product_id));
                    }

                    var hs_cat_var_img = krpano.get("hotspot[hts_" + element.id + "_category-thumb-name]");
                    hs_cat_var_img.onclick = function() {
                        active_product_id.update(n => parseInt(element.product_id));
                    }
                }

                switch (current_pagination) {
                    case 0:
                        
                        if (index < 10) {
                            thumb_ox = -250 + (i * 52); // 250
                            title_ox = -232 + (i * 52);
                            add_product();
                            i++;
                        }
                    break;
                
                    case 1 : 
                        
                        if (index > 9 && index < 19) {
                            thumb_ox = -250 + (i * 52); // 250
                            title_ox = -232 + (i * 52);
                            add_product();
                            i++;
                        }
                        break;
                }
            }
    
            

        }
    }

    generate_products();

    // pagination arrows
    krpano.call("addhotspot(pagination-arrow-r);");
    krpano.set("hotspot[pagination-arrow-r].type", "image");
    krpano.set("hotspot[pagination-arrow-r].ath", ath);
    krpano.set("hotspot[pagination-arrow-r].atv", 0);
    krpano.set("hotspot[pagination-arrow-r].url", userdata["cdn_path"] + "images/arrow-vr-r.svg");
    krpano.set("hotspot[pagination-arrow-r].ox", "273px");   
    krpano.set("hotspot[pagination-arrow-r].oy", "130px");
    krpano.set("hotspot[pagination-arrow-r].alpha", "1.0");
    krpano.set("hotspot[pagination-arrow-r].visible", "true");
    krpano.set("hotspot[pagination-arrow-r].enabled", "true");
    krpano.set("hotspot[pagination-arrow-r].keep", "true");
    krpano.set("hotspot[pagination-arrow-r].width", "prop");
    krpano.set("hotspot[pagination-arrow-r].height", "26px");
    krpano.set("hotspot[pagination-arrow-r].zorder", "1100");
    krpano.set("hotspot[pagination-arrow-r].distorted", "true");
    hotspot_to_delete.push("pagination-arrow-r");

    var hs_pag_right = krpano.get("hotspot[pagination-arrow-r]");
    hs_pag_right.onclick = function() {

        if (current_pagination !== pagination) {
            current_pagination++
        } else {
            current_pagination = 0;
        }
        generate_products();
    }

    krpano.call("addhotspot(pagination-arrow-l);");
    krpano.set("hotspot[pagination-arrow-l].type", "image");
    krpano.set("hotspot[pagination-arrow-l].ath", ath);
    krpano.set("hotspot[pagination-arrow-l].atv", 0);
    krpano.set("hotspot[pagination-arrow-l].url", userdata["cdn_path"] + "images/arrow-vr-l.svg");
    krpano.set("hotspot[pagination-arrow-l].ox", "-270px");   
    krpano.set("hotspot[pagination-arrow-l].oy", "130px");
    krpano.set("hotspot[pagination-arrow-l].alpha", "1.0");
    krpano.set("hotspot[pagination-arrow-l].visible", "true");
    krpano.set("hotspot[pagination-arrow-l].enabled", "true");
    krpano.set("hotspot[pagination-arrow-l].keep", "true");
    krpano.set("hotspot[pagination-arrow-l].width", "prop");
    krpano.set("hotspot[pagination-arrow-l].height", "26px");
    krpano.set("hotspot[pagination-arrow-l].zorder", "1100");
    krpano.set("hotspot[pagination-arrow-l].distorted", "true");
    hotspot_to_delete.push("pagination-arrow-l");

    var hs_pag_left = krpano.get("hotspot[pagination-arrow-l]");
    hs_pag_left.onclick = function() {

        if (current_pagination !== 0 ) {
            current_pagination--
        } else {
            current_pagination = pagination;
        }
        generate_products();
    }

    if (show_back_button) {
        //produkt back button
        krpano.call("addhotspot(back-product);");
        krpano.set("hotspot[back-product].type", "image");
        krpano.set("hotspot[back-product].ath", ath);
        krpano.set("hotspot[back-product].atv", "0");
        krpano.set("hotspot[back-product].url", userdata["cdn_path"] + "images/back.svg");
        krpano.set("hotspot[back-product].ox", "-255px");
        krpano.set("hotspot[back-product].oy", "-168px");
        krpano.set("hotspot[back-product].alpha", "1.0");
        krpano.set("hotspot[back-product].visible", "true");
        krpano.set("hotspot[back-product].keep", "true");
        krpano.set("hotspot[back-product].zorder", "1400");
        krpano.set("hotspot[back-product].distorted", "true");
        krpano.set("hotspot[back-product].scale", "0.66");
        hotspot_to_delete.push("back-product");

        var hs_back_btn = krpano.get("hotspot[back-product]");
        hs_back_btn.onclick = function() {
            check_ath.update(n => false);
            remove_product_detail();
            add_global_menu();
        }

        krpano.call("addhotspot(back-product-text);");
        krpano.set("hotspot[back-product-text].type", "text");
        krpano.set("hotspot[back-product-text].ath", ath);
        krpano.set("hotspot[back-product-text].atv", "0");
        krpano.set("hotspot[back-product-text].ox", "-242px");
        krpano.set("hotspot[back-product-text].html", $t("components")['vr']['back']);
        krpano.set("hotspot[back-product-text].oy", "-175px");
        krpano.set("hotspot[back-product-text].alpha", "1.0");
        krpano.set("hotspot[back-product-text].visible", "true");
        krpano.set("hotspot[back-product-text].keep", "true");
        krpano.set("hotspot[back-product-text].zorder", "1400");
        krpano.set("hotspot[back-product-text].distorted", "true");
        krpano.set("hotspot[back-product-text].width", "70px");
        krpano.set("hotspot[back-product-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[back-product-text].bgalpha", "0");
        krpano.set("hotspot[back-product-text].scale", "0.66");
        krpano.set("hotspot[back-product-text].edge", "top");
        hotspot_to_delete.push("back-product-text");

        var hs_back_btn_text = krpano.get("hotspot[back-product-text]");
        hs_back_btn_text.onclick = function() {
            check_ath.update(n => false);
            remove_product_detail();
            add_global_menu();
        }
    }
    

    // close button
    krpano.call("addhotspot(product_close_button);");
    krpano.set("hotspot[product_close_button].type", "image");
    krpano.set("hotspot[product_close_button].ath", ath);
    krpano.set("hotspot[product_close_button].atv", 0);
    krpano.set("hotspot[product_close_button].width", "21px");
    krpano.set("hotspot[product_close_button].height", "21px");
    krpano.set("hotspot[product_close_button].url", userdata["cdn_path"] + "images/close.svg");
    krpano.set("hotspot[product_close_button].ox", "283px");
    krpano.set("hotspot[product_close_button].oy", "-172px");
    krpano.set("hotspot[product_close_button].alpha", "1.0");
    krpano.set("hotspot[product_close_button].visible", true);
    krpano.set("hotspot[product_close_button].keep", "true");
    krpano.set("hotspot[product_close_button].zorder", 1000);
    krpano.set("hotspot[product_close_button].distorted", "true");
    hotspot_to_delete.push("product_close_button");

    var hs_back = krpano.get("hotspot[product_close_button]");
    hs_back.onclick = function() {
        check_ath.update(n => false);
        remove_product_detail();
    }

    check_ath.update(n => false);
}

function remove_product_detail() {
    show_back_button = false;
    
    if (krpano) {
        
        for (let index = 0; index < hotspot_to_delete.length; index++) {
            krpano.call("removehotspot(" + hotspot_to_delete[index] + ")");
        }

        vr_product_enabled.update(n => false);
    }
}

const formatter = new Intl.NumberFormat('sk-SK', {
    style: 'currency',
    currency: 'EUR'
});

function add_basket() {
    vr_safe_data.update(n => false);
    remove_product_detail();
    total_price = get(cart_price);
    // checkout background
    krpano.call("addhotspot(checkout-bg);");
    krpano.set("hotspot[checkout-bg].type", "text");
    krpano.set("hotspot[checkout-bg].ath", ath);
    krpano.set("hotspot[checkout-bg].atv", 0);
    krpano.set("hotspot[checkout-bg].width", "900");
    krpano.set("hotspot[checkout-bg].height", "560px");
    krpano.set("hotspot[checkout-bg].scale", "0.66");
    krpano.set("hotspot[checkout-bg].distorted", "true");
    krpano.set("hotspot[checkout-bg].bgcolor", "0xfafafa");
    krpano.set("hotspot[checkout-bg].alpha", "0.6");
    krpano.set("hotspot[checkout-bg].bgroundedge", "12");
    krpano.set("hotspot[checkout-bg].cursor", "default");
    krpano.set("hotspot[checkout-bg].visible", true);
    krpano.set("hotspot[checkout-bg].keep", "true");
    krpano.set("hotspot[checkout-bg].zorder", 900);
    krpano.set("hotspot[checkout-bg].enabled", false);

    // close button
    krpano.call("addhotspot(checkout_close_button);");
    krpano.set("hotspot[checkout_close_button].type", "image");
    krpano.set("hotspot[checkout_close_button].ath", ath);
    krpano.set("hotspot[checkout_close_button].atv", 0);
    krpano.set("hotspot[checkout_close_button].width", "21px");
    krpano.set("hotspot[checkout_close_button].height", "21px");
    krpano.set("hotspot[checkout_close_button].url", userdata["cdn_path"] + "images/close.svg");
    krpano.set("hotspot[checkout_close_button].ox", "281px");
    krpano.set("hotspot[checkout_close_button].oy", "-168px");
    krpano.set("hotspot[checkout_close_button].alpha", "1.0");
    krpano.set("hotspot[checkout_close_button].visible", true);
    krpano.set("hotspot[checkout_close_button].keep", "true");
    krpano.set("hotspot[checkout_close_button].zorder", 1000);
    krpano.set("hotspot[checkout_close_button].distorted", "true");

    var hs_checkout_close = krpano.get("hotspot[checkout_close_button]");
    hs_checkout_close.onclick = function() {
        remove_basket();
    }

    // checkout title
    krpano.call("addhotspot(checkout-title);");
    krpano.set("hotspot[checkout-title].type", "text");
    krpano.set("hotspot[checkout-title].ath", ath);
    krpano.set("hotspot[checkout-title].atv", "0");
    krpano.set("hotspot[checkout-title].html", $t("components")['basket']['checkout']);
    krpano.set("hotspot[checkout-title].ox", "0px");
    krpano.set("hotspot[checkout-title].oy", "-168px");
    krpano.set("hotspot[checkout-title].alpha", "1.0");
    krpano.set("hotspot[checkout-title].visible", true);
    krpano.set("hotspot[checkout-title].keep", "true");
    krpano.set("hotspot[checkout-title].zorder", "1000");
    krpano.set("hotspot[checkout-title].distorted", "true");
    krpano.set("hotspot[checkout-title].width", "500px");
    krpano.set("hotspot[checkout-title].height", "40px");
    krpano.set("hotspot[checkout-title].css", "color:#FAFAFA; text-align:left;font-size:16px;");
    krpano.set("hotspot[checkout-title].bgalpha", "0");
    krpano.set("hotspot[checkout-title].vcenter", "true");
    krpano.set("hotspot[checkout-title].cursor", "default");
    krpano.set("hotspot[checkout-title].scale", "0.66");
    krpano.set("hotspot[checkout-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    krpano.set("hotspot[checkout-title].enabled", false);

    // button ulozit kosik
    krpano.call("addhotspot(button-save-checkout);");
    krpano.set("hotspot[button-save-checkout].type", "text");
    krpano.set("hotspot[button-save-checkout].ath", ath);
    krpano.set("hotspot[button-save-checkout].atv", "0");
    krpano.set("hotspot[button-save-checkout].edge", "rightbottom");
    krpano.set("hotspot[button-save-checkout].ox", "-5px");
    krpano.set("hotspot[button-save-checkout].oy", "176px");
    krpano.set("hotspot[button-save-checkout].html", $t("components")['basket']['save-configuration']);
    krpano.set("hotspot[button-save-checkout].visible", "true");
    krpano.set("hotspot[button-save-checkout].keep", "true");
    krpano.set("hotspot[button-save-checkout].zorder", 1100);
    krpano.set("hotspot[button-save-checkout].width", "315px");
    krpano.set("hotspot[button-save-checkout].height", "40px");
    krpano.set("hotspot[button-save-checkout].distorted", "true");
    krpano.set("hotspot[button-save-checkout].bgcolor", "0x4F46E5");
    krpano.set("hotspot[button-save-checkout].alpha", "1");
    krpano.set("hotspot[button-save-checkout].bgroundedge", "6");
    krpano.set("hotspot[button-save-checkout].vcenter", "true");
    krpano.set("hotspot[button-save-checkout].scale", "0.66");
    krpano.set("hotspot[button-save-checkout].css", "color:#FAFAFA; text-align:center; font-size:14px;");

    var basket_save_button = krpano.get("hotspot[button-save-checkout]");
    basket_save_button.onclick = function() {
        vr_safe_data.update(n => true);    
        krpano.set("hotspot[button-save-checkout].html", $t("components")['basket']['saving-configuration']);
        setTimeout(() => {
            krpano.set("hotspot[button-save-checkout].html", $t("components")['basket']['save-configuration']);
        }, 2000);
    }


    

    // celkova suma - bg + text
    krpano.call("addhotspot(total-price);");
    krpano.set("hotspot[total-price].type", "text");
    krpano.set("hotspot[total-price].ath", ath);
    krpano.set("hotspot[total-price].atv", "0");
    krpano.set("hotspot[total-price].edge", "leftbottom");
    krpano.set("hotspot[total-price].ox", "5px");
    krpano.set("hotspot[total-price].oy", "176px");
    krpano.set("hotspot[total-price].html", $t("components")['basket']['total']);
    krpano.set("hotspot[total-price].visible", "true");
    krpano.set("hotspot[total-price].keep", "true");
    krpano.set("hotspot[total-price].zorder", 1100);
    krpano.set("hotspot[total-price].width", "315px");
    krpano.set("hotspot[total-price].height", "40px");
    krpano.set("hotspot[total-price].distorted", "true");
    krpano.set("hotspot[total-price].bgcolor", "0x3F3F46");
    krpano.set("hotspot[total-price].alpha", "1");
    krpano.set("hotspot[total-price].bgroundedge", "6");
    krpano.set("hotspot[total-price].vcenter", "true");
    krpano.set("hotspot[total-price].scale", "0.66");
    krpano.set("hotspot[total-price].css", "color:#FAFAFA; text-align:left; font-size:14px; padding-left:16px;");
    krpano.set("hotspot[total-price].enabled", false);

    // celkova suma - suma
    krpano.call("addhotspot(total-price-value);");
    krpano.set("hotspot[total-price-value].type", "text");
    krpano.set("hotspot[total-price-value].ath", ath);
    krpano.set("hotspot[total-price-value].atv", "0");
    krpano.set("hotspot[total-price-value].edge", "leftbottom");
    krpano.set("hotspot[total-price-value].ox", "5px");
    krpano.set("hotspot[total-price-value].oy", "176px");
    krpano.set("hotspot[total-price-value].html", formatter.format(total_price));
    krpano.set("hotspot[total-price-value].visible", "true");
    krpano.set("hotspot[total-price-value].keep", "true");
    krpano.set("hotspot[total-price-value].zorder", 1100);
    krpano.set("hotspot[total-price-value].width", "315px");
    krpano.set("hotspot[total-price-value].height", "40px");
    krpano.set("hotspot[total-price-value].distorted", "true");
    krpano.set("hotspot[total-price-value].alpha", "1");
    krpano.set("hotspot[total-price-value].bgalpha", "0");
    krpano.set("hotspot[total-price-value].bgroundedge", "6");
    krpano.set("hotspot[total-price-value].vcenter", "true");
    krpano.set("hotspot[total-price-value].scale", "0.66");
    krpano.set("hotspot[total-price-value].css", "color:#FAFAFA; text-align:right; font-size:14px; padding-right:16px; font-weight:900;");
    krpano.set("hotspot[total-price-value].enabled", false);

    // produkty
    let counter = 0;
    for (let index = 0; index < _configuration.length; index++) {
        const element = _configuration[index];
        if (element.type != "configuration") {
            

            let ox, oy = 0;

             //produkt bg+price
            let price = parseFloat(element.price);
            krpano.call("addhotspot(hts_" + element.id + "-price);");
            krpano.set("hotspot[hts_" + element.id + "-price].type", "text");
            krpano.set("hotspot[hts_" + element.id + "-price].ath", ath);
            krpano.set("hotspot[hts_" + element.id + "-price].atv", "0");
            krpano.set("hotspot[hts_" + element.id + "-price].html", formatter.format(price));
            krpano.set("hotspot[hts_" + element.id + "-price].visible", "true");
            krpano.set("hotspot[hts_" + element.id + "-price].keep", "true");
            krpano.set("hotspot[hts_" + element.id + "-price].zorder", 1100);
            krpano.set("hotspot[hts_" + element.id + "-price].width", "315px");
            krpano.set("hotspot[hts_" + element.id + "-price].height", "40px");
            krpano.set("hotspot[hts_" + element.id + "-price].distorted", "true");
            krpano.set("hotspot[hts_" + element.id + "-price].bgcolor", "0xFAFAFA");
            krpano.set("hotspot[hts_" + element.id + "-price].alpha", "1");
            krpano.set("hotspot[hts_" + element.id + "-price].bgroundedge", "6");
            krpano.set("hotspot[hts_" + element.id + "-price].vcenter", "true");
            krpano.set("hotspot[hts_" + element.id + "-price].bgborder", "1.0 0x3F3F46 0.2");
            krpano.set("hotspot[hts_" + element.id + "-price].scale", "0.66");
            krpano.set("hotspot[hts_" + element.id + "-price].css", "color:#3F3F46; text-align:right; font-size:12px; font-weight:900;padding-right:8px;");  
            krpano.set("hotspot[hts_" + element.id + "-price].enabled", "false");

             //produkt name
            krpano.call("addhotspot(hts_" + element.id + "-name);");
            krpano.set("hotspot[hts_" + element.id + "-name].type", "text");
            krpano.set("hotspot[hts_" + element.id + "-name].ath", ath);
            krpano.set("hotspot[hts_" + element.id + "-name].atv", "0");
            krpano.set("hotspot[hts_" + element.id + "-name].oy", "-118px");
            krpano.set("hotspot[hts_" + element.id + "-name].visible", "true");
            krpano.set("hotspot[hts_" + element.id + "-name].keep", "true");
            krpano.set("hotspot[hts_" + element.id + "-name].zorder", 1100);
            krpano.set("hotspot[hts_" + element.id + "-name].width", "195px");
            krpano.set("hotspot[hts_" + element.id + "-name].height", "40px");
            krpano.set("hotspot[hts_" + element.id + "-name].distorted", "true");
            krpano.set("hotspot[hts_" + element.id + "-name].bgalpha", "0");
            //krpano.set("hotspot[hts_" + element.id + "-name].bgcolor", "#0000000");
            krpano.set("hotspot[hts_" + element.id + "-name].alpha", "1");
            krpano.set("hotspot[hts_" + element.id + "-name].vcenter", "true");
            krpano.set("hotspot[hts_" + element.id + "-name].scale", "0.66");
            krpano.set("hotspot[hts_" + element.id + "-name].css", "color:#3F3F46; text-align:left; font-size:12px; font-weight:500;padding-left:8px;");
            krpano.set("hotspot[hts_" + element.id + "-name].enabled", "false");

             // produkt thumbnail
            krpano.call("addhotspot(hts_" + element.id + "-checkout-thumb);");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].type", "image");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].ath", ath);
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].atv", 0);
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].alpha", "1.0");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].visible", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].enabled", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].keep", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].width", "30");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].height", "30px");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].zorder", "1100");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].distorted", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].scale", "0.66");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].enabled", "false");

            // thumbnail border
            krpano.call("addhotspot(hts_" + element.id + "-checkout-thumb-active);");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].type", "image");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].ath", ath);
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].atv", 0);
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].url", userdata["cdn_path"] + "images/vr-thumb-active.png");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].alpha", "1.0");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].visible", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].enabled", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].keep", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].width", "30");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].height", "30px");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].zorder", "1100");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].distorted", "true");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].scale", "0.66");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].enabled", "false");

            if (counter < 8) {
            oy = -118 + (35 * (counter));
            
            
            krpano.set("hotspot[hts_" + element.id + "-price].ox", -5);
            krpano.set("hotspot[hts_" + element.id + "-price].edge", "rightbottom");
            krpano.set("hotspot[hts_" + element.id + "-name].edge", "rightbottom");
            krpano.set("hotspot[hts_" + element.id + "-name].ox", "-52px");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].ox", "-187px");
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].edge", "rightbottom"); 
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].ox", "-187px");
                krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].edge", "rightbottom"); 
            
            
            } else {
                oy = -118 + (35 * (counter - 8));

                
                krpano.set("hotspot[hts_" + element.id + "-price].ox", 5);
                krpano.set("hotspot[hts_" + element.id + "-price].edge", "lefttbottom");
                krpano.set("hotspot[hts_" + element.id + "-name].edge", "lefttbottom");
                krpano.set("hotspot[hts_" + element.id + "-name].ox", "35px");
                krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].ox", "10px");
                krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].edge", "leftbottom"); 
                krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].ox", "10px");
                krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].edge", "leftbottom"); 
            }

            let oy_thumb = oy - 3;


            krpano.set("hotspot[hts_" + element.id + "-price].oy", oy);
            krpano.set("hotspot[hts_" + element.id + "-price].oy", oy);
            krpano.set("hotspot[hts_" + element.id + "-name].oy", oy);
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].oy", oy_thumb);
            krpano.set("hotspot[hts_" + element.id + "-checkout-thumb-active].oy", oy_thumb);

            let title = "";

            _all_products.forEach(product => {
                if (parseInt(product.id) == parseInt(element.product_id)) {
                    title = product.name;
                }
            });

            _config_product_variants.forEach(variant => {
                if (parseInt(variant.id) == parseInt(element.active_product_variant) ) {

                    krpano.set("hotspot[hts_" + element.id + "-name].html", title + " - " + variant.name);
                    krpano.set("hotspot[hts_" + element.id + "-checkout-thumb].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "thumbnails/" + variant.variant_id + ".jpg");           
                }
            });

            counter++;

        }
    };


    // košik čiara
    krpano.call("addhotspot(checkout-hr);");
    krpano.set("hotspot[checkout-hr].type", "text");
    krpano.set("hotspot[checkout-hr].ath", ath);
    krpano.set("hotspot[checkout-hr].atv", "0");
    krpano.set("hotspot[checkout-hr].edge", "top");
    krpano.set("hotspot[checkout-hr].ox", "0");
    krpano.set("hotspot[checkout-hr].oy", "140px");
    krpano.set("hotspot[checkout-hr].visible", "true");
    krpano.set("hotspot[checkout-hr].keep", "true");
    krpano.set("hotspot[checkout-hr].zorder", 1100);
    krpano.set("hotspot[checkout-hr].width", "627px");
    krpano.set("hotspot[checkout-hr].height", "1px");
    krpano.set("hotspot[checkout-hr].distorted", "true");
    krpano.set("hotspot[checkout-hr].bgcolor", "0x3F3F46");
    krpano.set("hotspot[checkout-hr].alpha", "0.2");
    krpano.set("hotspot[checkout-hr].scale", "0.66");
    krpano.set("hotspot[checkout-hr].enabled", false);

    // košík back button
    krpano.call("addhotspot(back-checkout);");
    krpano.set("hotspot[back-checkout].type", "image");
    krpano.set("hotspot[back-checkout].ath", ath);
    krpano.set("hotspot[back-checkout].atv", "0");
    krpano.set("hotspot[back-checkout].url", userdata["cdn_path"] + "images/back.svg");
    krpano.set("hotspot[back-checkout].ox", "-255px");
    krpano.set("hotspot[back-checkout].oy", "-168px");
    krpano.set("hotspot[back-checkout].alpha", "1.0");
    krpano.set("hotspot[back-checkout].visible", "true");
    krpano.set("hotspot[back-checkout].keep", "true");
    krpano.set("hotspot[back-checkout].zorder", "1400");
    krpano.set("hotspot[back-checkout].distorted", "true");
    krpano.set("hotspot[back-checkout].scale", "0.66");

    krpano.call("addhotspot(back-checkout-text);");
    krpano.set("hotspot[back-checkout-text].type", "text");
    krpano.set("hotspot[back-checkout-text].ath", ath);
    krpano.set("hotspot[back-checkout-text].atv", "0");
    krpano.set("hotspot[back-checkout-text].ox", "-242px");
    krpano.set("hotspot[back-checkout-text].html", $t("components")['vr']['back']);
    krpano.set("hotspot[back-checkout-text].oy", "-175px");
    krpano.set("hotspot[back-checkout-text].alpha", "1.0");
    krpano.set("hotspot[back-checkout-text].visible", "true");
    krpano.set("hotspot[back-checkout-text].keep", "true");
    krpano.set("hotspot[back-checkout-text].zorder", "1400");
    krpano.set("hotspot[back-checkout-text].distorted", "true");
    krpano.set("hotspot[back-checkout-text].width", "70px");
    krpano.set("hotspot[back-checkout-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
    krpano.set("hotspot[back-checkout-text].bgalpha", "0");
    krpano.set("hotspot[back-checkout-text].scale", "0.66");
    krpano.set("hotspot[back-checkout-text].edge", "top");
    

    var hs_back = krpano.get("hotspot[back-checkout]");
    hs_back.onclick = function() {
        remove_basket();
        add_global_menu();
    }

    var hs_back_text = krpano.get("hotspot[back-checkout-text]");
    hs_back_text.onclick = function() {
        remove_basket();
        add_global_menu();
    }

}

function remove_basket() {
    if (krpano) {
        let elements = ["checkout-bg", "checkout_close_button", "checkout-title", "button-save-checkout", "total-price", "total-price-value", "checkout-hr", "back-checkout", "back-checkout-text"];
        for (let index = 0; index < elements.length; index++) {
            krpano.call("removehotspot(" + elements[index] + ")");
        }

        for (let index = 0; index < _configuration.length; index++) {
            const element = _configuration[index];
            if (element.type != "configuration") {
                krpano.call("removehotspot(hts_" + element.id + "-price)");
                krpano.call("removehotspot(hts_" + element.id + "-name)");
                krpano.call("removehotspot(hts_" + element.id + "-checkout-thumb)");
                krpano.call("removehotspot(hts_" + element.id + "-checkout-thumb-active)");
            }
        }
    }

}

let floorplan_pagination = 0;
if (_config_products != undefined) {
    floorplan_pagination = Math.floor(_config_products.length / 7);
}

let floorplan_current_pagination = 0;

const scene_text_container = -129;
let scene_text_container_counter = 0;
let set_pagination = true;


function add_floorplan() {
    opened_floorlan = true;
    floorplan_to_delete = [];
    scene_text_container_counter = 0;

    if (set_pagination) {
        for (let i = 0; i < _floorplan_pins.length; i++) {
            const element = _floorplan_pins[i];
            if (element.target.toLowerCase() == krpano.get("xml.scene").toLowerCase()   ) {
                floorplan_current_pagination =  Math.floor(i / 7);
            }   
        }
    }

    // BG
    krpano.call("addhotspot(floorplan-menu-bg);");
    krpano.set("hotspot[floorplan-menu-bg].type", "text");
    krpano.set("hotspot[floorplan-menu-bg].ath", ath);
    krpano.set("hotspot[floorplan-menu-bg].atv", 0);
    krpano.set("hotspot[floorplan-menu-bg].width", "900");
    krpano.set("hotspot[floorplan-menu-bg].height", "560px");
    krpano.set("hotspot[floorplan-menu-bg].scale", "0.66");
    krpano.set("hotspot[floorplan-menu-bg].distorted", "true");
    krpano.set("hotspot[floorplan-menu-bg].bgcolor", "0xfafafa");
    krpano.set("hotspot[floorplan-menu-bg].alpha", "0.6");
    krpano.set("hotspot[floorplan-menu-bg].bgroundedge", "12");
    krpano.set("hotspot[floorplan-menu-bg].cursor", "default");
    krpano.set("hotspot[floorplan-menu-bg].visible", true);
    krpano.set("hotspot[floorplan-menu-bg].keep", "true");
    krpano.set("hotspot[floorplan-menu-bg].zorder", 900);
    krpano.set("hotspot[floorplan-menu-bg].enabled", false);
    krpano.set("hotspot[floorplan-menu-bg].bgborder", "2.0 0x3F3F46 0.2");
    floorplan_to_delete.push("floorplan-menu-bg");
            
    // TITLE
    krpano.call("addhotspot(floorplan-menu-title);");
    krpano.set("hotspot[floorplan-menu-title].type", "text");
    krpano.set("hotspot[floorplan-menu-title].ath", ath);
    krpano.set("hotspot[floorplan-menu-title].atv", "0");
    krpano.set("hotspot[floorplan-menu-title].html", $t("components")['vr']['floorplan']);
    krpano.set("hotspot[floorplan-menu-title].ox", "0px");
    krpano.set("hotspot[floorplan-menu-title].oy", "-168px");
    krpano.set("hotspot[floorplan-menu-title].alpha", "1.0");
    krpano.set("hotspot[floorplan-menu-title].visible", true);
    krpano.set("hotspot[floorplan-menu-title].keep", "true");
    krpano.set("hotspot[floorplan-menu-title].zorder", "1000");
    krpano.set("hotspot[floorplan-menu-title].distorted", "true");
    krpano.set("hotspot[floorplan-menu-title].width", "500px");
    krpano.set("hotspot[floorplan-menu-title].height", "40px");
    krpano.set("hotspot[floorplan-menu-title].bgalpha", "0");
    krpano.set("hotspot[floorplan-menu-title].vcenter", "true");
    krpano.set("hotspot[floorplan-menu-title].cursor", "default");
    krpano.set("hotspot[floorplan-menu-title].scale", "0.66");
    krpano.set("hotspot[floorplan-menu-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    krpano.set("hotspot[floorplan-menu-title].enabled", false);
    floorplan_to_delete.push("floorplan-menu-title");

    // CLOSE button
    krpano.call("addhotspot(floorplan-menu_close_button);");
    krpano.set("hotspot[floorplan-menu_close_button].type", "image");
    krpano.set("hotspot[floorplan-menu_close_button].ath", ath);
    krpano.set("hotspot[floorplan-menu_close_button].atv", 0);
    krpano.set("hotspot[floorplan-menu_close_button].width", "21px");
    krpano.set("hotspot[floorplan-menu_close_button].height", "21px");
    krpano.set("hotspot[floorplan-menu_close_button].url", userdata["cdn_path"] + "images/close.svg");
    krpano.set("hotspot[floorplan-menu_close_button].ox", "281px");
    krpano.set("hotspot[floorplan-menu_close_button].oy", "-168px");
    krpano.set("hotspot[floorplan-menu_close_button].alpha", "1.0");
    krpano.set("hotspot[floorplan-menu_close_button].visible", true);
    krpano.set("hotspot[floorplan-menu_close_button].keep", "true");
    //krpano.set("hotspot[floorplan-menu_close_button].onclick", "js(toggle_floorplan-menu(););");
    krpano.set("hotspot[floorplan-menu_close_button].zorder", 1000);
    krpano.set("hotspot[floorplan-menu_close_button].distorted", "true");
    floorplan_to_delete.push("floorplan-menu_close_button");

    var hs_floorplan_close = krpano.get("hotspot[floorplan-menu_close_button]");
    hs_floorplan_close.onclick = function() {
        remove_floorplan();
        set_pagination = true;
    }

    // BACK button
    krpano.call("addhotspot(back-floorplan_detail);");
    krpano.set("hotspot[back-floorplan_detail].type", "image");
    krpano.set("hotspot[back-floorplan_detail].ath", ath);
    krpano.set("hotspot[back-floorplan_detail].atv", "0");
    krpano.set("hotspot[back-floorplan_detail].url", userdata["cdn_path"] + "images/back.svg");
    krpano.set("hotspot[back-floorplan_detail].ox", "-255px");
    krpano.set("hotspot[back-floorplan_detail].oy", "-168px");
    krpano.set("hotspot[back-floorplan_detail].alpha", "1.0");
    krpano.set("hotspot[back-floorplan_detail].visible", "true");
    krpano.set("hotspot[back-floorplan_detail].keep", "true");
    krpano.set("hotspot[back-floorplan_detail].zorder", "1400");
    krpano.set("hotspot[back-floorplan_detail].distorted", "true");
    krpano.set("hotspot[back-floorplan_detail].scale", "0.66");
    floorplan_to_delete.push("back-floorplan_detail");

    var hs_floorlan_back_button = krpano.get("hotspot[back-floorplan_detail]");
    hs_floorlan_back_button.onclick = function() {
        remove_floorplan();
        add_global_menu();
        set_pagination = true;
    }

    // BACK button text
    krpano.call("addhotspot(back-floorplan-text_detail);");
    krpano.set("hotspot[back-floorplan-text_detail].type", "text");
    krpano.set("hotspot[back-floorplan-text_detail].ath", ath);
    krpano.set("hotspot[back-floorplan-text_detail].atv", "0");
    krpano.set("hotspot[back-floorplan-text_detail].ox", "-242px");
    krpano.set("hotspot[back-floorplan-text_detail].html", $t("components")['vr']['back']);
    krpano.set("hotspot[back-floorplan-text_detail].oy", "-175px");
    krpano.set("hotspot[back-floorplan-text_detail].alpha", "1.0");
    krpano.set("hotspot[back-floorplan-text_detail].visible", "true");
    krpano.set("hotspot[back-floorplan-text_detail].keep", "true");
    krpano.set("hotspot[back-floorplan-text_detail].zorder", "1400");
    krpano.set("hotspot[back-floorplan-text_detail].distorted", "true");
    krpano.set("hotspot[back-floorplan-text_detail].width", "70px");
    krpano.set("hotspot[back-floorplan-text_detail].css", "color:#FAFAFA; text-align:left;font-size:16px;");
    krpano.set("hotspot[back-floorplan-text_detail].bgalpha", "0");
    krpano.set("hotspot[back-floorplan-text_detail].scale", "0.66");
    krpano.set("hotspot[back-floorplan-text_detail].edge", "top");
    floorplan_to_delete.push("back-floorplan-text_detail");

    var hs_floorlan_back_button_text = krpano.get("hotspot[back-floorplan-text_detail]");
    hs_floorlan_back_button_text.onclick = function() {
        remove_floorplan();
        add_global_menu();
        set_pagination = true;
    }

    //Floorplan Image beground
    krpano.call("addhotspot(floorplan-image-bg);");
    krpano.set("hotspot[floorplan-image-bg].type", "text");
    krpano.set("hotspot[floorplan-image-bg].ath", ath);
    krpano.set("hotspot[floorplan-image-bg].atv", 0);
    krpano.set("hotspot[floorplan-image-bg].width", "488px");
    krpano.set("hotspot[floorplan-image-bg].height", "488px");
    krpano.set("hotspot[floorplan-image-bg].ox", "125px");
    krpano.set("hotspot[floorplan-image-bg].oy", "15px");
    krpano.set("hotspot[floorplan-image-bg].scale", "0.66");
    krpano.set("hotspot[floorplan-image-bg].distorted", "true");
    krpano.set("hotspot[floorplan-image-bg].bgcolor", "0xfafafa");
    krpano.set("hotspot[floorplan-image-bg].alpha", "1");
    krpano.set("hotspot[floorplan-image-bg].bgroundedge", "6");
    krpano.set("hotspot[floorplan-image-bg].cursor", "default");
    krpano.set("hotspot[floorplan-image-bg].visible", true);
    krpano.set("hotspot[floorplan-image-bg].keep", "true");
    krpano.set("hotspot[floorplan-image-bg].zorder", 900);
    krpano.set("hotspot[floorplan-image-bg].enabled", false);
    krpano.set("hotspot[floorplan-image-bg].bgborder", "1.0 0x3F3F46 0.6");
    floorplan_to_delete.push("floorplan-image-bg");

    // Floorplan image
    krpano.call("addhotspot(floorplan-image);");
    krpano.set("hotspot[floorplan-image].type", "image");
    krpano.set("hotspot[floorplan-image].ath", ath);
    krpano.set("hotspot[floorplan-image].atv", 0);
    krpano.set("hotspot[floorplan-image].width", "480px");
    krpano.set("hotspot[floorplan-image].height", "480px");
    krpano.set("hotspot[floorplan-image].ox", "125px");
    krpano.set("hotspot[floorplan-image].oy", "15px");
    krpano.set("hotspot[floorplan-image].scale", "0.66");
    krpano.set("hotspot[floorplan-image].distorted", "true");
    krpano.set("hotspot[floorplan-image].cursor", "default");
    krpano.set("hotspot[floorplan-image].visible", true);
    krpano.set("hotspot[floorplan-image].keep", "true");
    krpano.set("hotspot[floorplan-image].zorder", 1000);
    krpano.set("hotspot[floorplan-image].enabled", false);
    krpano.set("hotspot[floorplan-image].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "floorplans/" + _floorplan_settings['image'].replace("_floorplan", ""));
    floorplan_to_delete.push("floorplan-image");
    
    let index = 0;

    // zobrazenie výpisu scén
    _floorplan_pins.forEach(pin => {
        if (index >= (floorplan_current_pagination * 7) && index < ( (floorplan_current_pagination * 7) + 7)) {
             // aktivy prvok text
            krpano.call("addhotspot(floorplan-text-" + pin.id +");");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].type", "text");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].ath", ath);
            krpano.set("hotspot[floorplan-text-" + pin.id +"].atv", 0);
            krpano.set("hotspot[floorplan-text-" + pin.id +"].width", "350px");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].height", "50px");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].ox", "-167px");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].oy", scene_text_container + (scene_text_container_counter * 40));
            krpano.set("hotspot[floorplan-text-" + pin.id +"].html", pin.title);
            krpano.set("hotspot[floorplan-text-" + pin.id +"].scale", "0.66");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].distorted", "true");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].alpha", "1");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].bgalpha", "0.1");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].bgroundedge", "6");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].cursor", "default");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].visible", true);
            krpano.set("hotspot[floorplan-text-" + pin.id +"].keep", "true");
            krpano.set("hotspot[floorplan-text-" + pin.id +"].zorder", 1000);
            krpano.set("hotspot[floorplan-text-" + pin.id +"].enabled", true);
            krpano.set("hotspot[floorplan-text-" + pin.id +"].vcenter", true);
            floorplan_to_delete.push("floorplan-text-" + pin.id);

            if (krpano.get("xml.scene").toLowerCase() == pin.target.toLowerCase()) {
                krpano.set("hotspot[floorplan-text-" + pin.id +"].css", "color:#4F46E5; text-align:left;font-size:16px; padding-left:50px");
                krpano.set("hotspot[floorplan-text-" + pin.id +"].bgcolor", "0x4F46E5");  
                krpano.set("hotspot[floorplan-text-" + pin.id +"].bgborder", "1.0 0x4F46E5 0.6");
            } else {
                krpano.set("hotspot[floorplan-text-" + pin.id +"].css", "color:#3F3F46; text-align:left;font-size:16px; padding-left:50px");
                krpano.set("hotspot[floorplan-text-" + pin.id +"].bgcolor", "0x3F3F46");
                krpano.set("hotspot[floorplan-text-" + pin.id +"].bgborder", "1.0 0x3F3F46 0.0");
            }

            var hs_floorplan_pin_text = krpano.get("hotspot[floorplan-text-" + pin.id + "]");
            hs_floorplan_pin_text.onclick = function() {
                krpano.call("loadscene(" + pin.target +",null,KEEPVIEW,NOBLEND)");  
                remove_floorplan();
                add_floorplan();
            }

            
                hs_floorplan_pin_text.onover = function() {
                    if (krpano.get("xml.scene").toLowerCase() != pin.target.toLowerCase()) {
                    krpano.set("hotspot[floorplan-text-" + pin.id +"].bgborder", "1.0 0x3F3F46 0.6");
                    krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].bgcolor", "0x3F3F46");
                    }
                }

                hs_floorplan_pin_text.onout = function() {
                    if (krpano.get("xml.scene").toLowerCase() != pin.target.toLowerCase()) {
                    krpano.set("hotspot[floorplan-text-" + pin.id +"].bgborder", "1.0 0x3F3F46 0.0");
                    krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].bgcolor", "0x3F3F46");
                    }
                }
            

            //floorplan text pin-active
            krpano.call("addhotspot(floorplan-text-pin-" + pin.id +");");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].type", "text");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].ath", ath);
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].atv", 0);
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].width", "20px");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].height", "20px");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].ox", "-265px");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].oy", scene_text_container + (scene_text_container_counter * 40));
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].html", "" + pin.number + "");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].scale", "0.66");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].distorted", "true");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].alpha", "1");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].bgroundedge", "13");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].cursor", "default");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].visible", true);
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].keep", "true");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].zorder", 1000);
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].enabled", true);
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].vcenter", true);
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].bgborder", "2.0 0xfafafa 1.0");
            krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].css", "color:#FAFAFA; text-align:center;font-size:10px;font-weight:900;");
            floorplan_to_delete.push("floorplan-text-pin-" + pin.id);

            if (krpano.get("xml.scene").toLowerCase() == pin.target.toLowerCase()) {
            
                krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].bgcolor", "0x4F46E5");
            } else {
                krpano.set("hotspot[floorplan-text-pin-" + pin.id +"].bgcolor", "0x3F3F46");
            }
        
            var hs_floorplan_text_pin = krpano.get("hotspot[floorplan-text-pin-" + pin.id + "]");
            hs_floorplan_text_pin.onclick = function() {
                krpano.call("loadscene(" + pin.target +",null,KEEPVIEW,NOBLEND)");  
                remove_floorplan();
                add_floorplan(); 
            }

            scene_text_container_counter++;
        }
        index++;

        // zobrazenie pinov na mape
        krpano.call("addhotspot(floorplan-pin-point-" + pin.id + ");");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].type", "text");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].ath", ath);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].atv", 0);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].width", "20px");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].height", "20px");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].ox", -32 + (pin.x / 3.1));
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].oy", -141 + (pin.y / 3.1));
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].html","" + pin.number);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].scale", "0.66");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].distorted", "true");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].bgcolor", "0x3F3F46");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].alpha", "1");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].bgroundedge", "13");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].cursor", "default");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].visible", true);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].keep", "true");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].zorder", 1000);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].enabled", true);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].vcenter", true);
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].bgborder", "2.0 0xfafafa 1.0");
        krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].css", "color:#FAFAFA; text-align:center;font-size:10px;font-weight:900;");

        if (krpano.get("xml.scene").toLowerCase() == pin.target.toLowerCase()) {
            krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].bgcolor", "0x4F46E5");
        } else {
            krpano.set("hotspot[floorplan-pin-point-" + pin.id + "].bgcolor", "0x3F3F46");
            
        }
        floorplan_to_delete.push("floorplan-pin-point-" + pin.id);


        var hs_floorplan_pin_point = krpano.get("hotspot[floorplan-pin-point-" + pin.id + "]");
        hs_floorplan_pin_point.onclick = function() {
            krpano.call("loadscene(" + pin.target +",null,KEEPVIEW,NOBLEND)");  

                for (let i = 0; i < _floorplan_pins.length; i++) {
                    const element = _floorplan_pins[i];
                    
                    if (element.target == pin.target) {
                        
                        floorplan_current_pagination =  Math.floor(i / 7);
                    }
                    
                }
                remove_floorplan();
                add_floorplan(); 
            }

    });


    krpano.call("addhotspot(floorplan-pagination-arrow-down);"); 
    krpano.set("hotspot[floorplan-pagination-arrow-down].type", "image");
    krpano.set("hotspot[floorplan-pagination-arrow-down].ath", ath);
    krpano.set("hotspot[floorplan-pagination-arrow-down].atv", 0);
    krpano.set("hotspot[floorplan-pagination-arrow-down].url", userdata["cdn_path"] + "images/arrow-vr-dr.svg");
    krpano.set("hotspot[floorplan-pagination-arrow-down].ox", "150px");
    krpano.set("hotspot[floorplan-pagination-arrow-down].oy", "150px");
    krpano.set("hotspot[floorplan-pagination-arrow-down].alpha", "1.0");
    krpano.set("hotspot[floorplan-pagination-arrow-down].visible", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-down].enabled", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-down].keep", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-down].width", "prop");
    krpano.set("hotspot[floorplan-pagination-arrow-down].height", "40px");
    krpano.set("hotspot[floorplan-pagination-arrow-down].zorder", "1600");
    krpano.set("hotspot[floorplan-pagination-arrow-down].distorted", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-down].scale", "0.66");
    krpano.set("hotspot[floorplan-pagination-arrow-down].rotate", "90");
    floorplan_to_delete.push("floorplan-pagination-arrow-down");

    var hs_pag_next = krpano.get("hotspot[floorplan-pagination-arrow-down]");
    hs_pag_next.onclick = function() {
        if (floorplan_current_pagination !== floorplan_pagination) {
            floorplan_current_pagination++
        } else {
            floorplan_current_pagination = 0;
        }
        set_pagination = false;
        remove_floorplan();
        add_floorplan();
    }

    krpano.call("addhotspot(floorplan-pagination-arrow-up);"); 
    krpano.set("hotspot[floorplan-pagination-arrow-up].type", "image");
    krpano.set("hotspot[floorplan-pagination-arrow-up].ath", ath);
    krpano.set("hotspot[floorplan-pagination-arrow-up].atv", 0);
    krpano.set("hotspot[floorplan-pagination-arrow-up].url", userdata["cdn_path"] + "images/arrow-vr-dr.svg");
    krpano.set("hotspot[floorplan-pagination-arrow-up].ox", "-150px");
    krpano.set("hotspot[floorplan-pagination-arrow-up].oy", "-190px");
    krpano.set("hotspot[floorplan-pagination-arrow-up].alpha", "1.0");
    krpano.set("hotspot[floorplan-pagination-arrow-up].visible", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-up].enabled", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-up].keep", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-up].width", "prop");
    krpano.set("hotspot[floorplan-pagination-arrow-up].height", "40px");
    krpano.set("hotspot[floorplan-pagination-arrow-up].zorder", "1600");
    krpano.set("hotspot[floorplan-pagination-arrow-up].distorted", "true");
    krpano.set("hotspot[floorplan-pagination-arrow-up].scale", "0.66");
    krpano.set("hotspot[floorplan-pagination-arrow-up].rotate", "-90");
    floorplan_to_delete.push("floorplan-pagination-arrow-up");

    var hs_pag_prev = krpano.get("hotspot[floorplan-pagination-arrow-up]");
    hs_pag_prev.onclick = function() {
        if (floorplan_current_pagination !== 0) {
            floorplan_current_pagination--
        } else {
            floorplan_current_pagination = floorplan_pagination;
        }
        set_pagination = false;
        remove_floorplan();
        add_floorplan();
    }

}

function remove_floorplan() {
    opened_floorlan = false;
    for (let index = 0; index < floorplan_to_delete.length; index++) {
        krpano.call("removehotspot(" + floorplan_to_delete[index] + ")");
    }
}


let shortcuts_pagination =  Math.floor(scenes.length / 3);
let shortcuts_current_pagination = 0;
let enable_shortcut_pagination = true;
let shortcuts_autoplay = false;

function add_shortcuts() {
     ////TMP shortcut menu
    krpano.call("addhotspot(shortcut-menu-bg);");
    krpano.set("hotspot[shortcut-menu-bg].type", "text");
    krpano.set("hotspot[shortcut-menu-bg].ath", ath);
    krpano.set("hotspot[shortcut-menu-bg].atv", 0);
    krpano.set("hotspot[shortcut-menu-bg].width", "900");
    krpano.set("hotspot[shortcut-menu-bg].height", "560px");
    krpano.set("hotspot[shortcut-menu-bg].scale", "0.66");
    krpano.set("hotspot[shortcut-menu-bg].distorted", "true");
    krpano.set("hotspot[shortcut-menu-bg].bgcolor", "0xfafafa");
    krpano.set("hotspot[shortcut-menu-bg].alpha", "0.6");
    krpano.set("hotspot[shortcut-menu-bg].bgroundedge", "12");
    krpano.set("hotspot[shortcut-menu-bg].cursor", "default");
    krpano.set("hotspot[shortcut-menu-bg].visible", true);
    krpano.set("hotspot[shortcut-menu-bg].keep", "true");
    krpano.set("hotspot[shortcut-menu-bg].zorder", 900);
    krpano.set("hotspot[shortcut-menu-bg].enabled", false);
    krpano.set("hotspot[shortcut-menu-bg].bgborder", "2.0 0x3F3F46 0.2");
    shortcuts_to_delete.push("shortcut-menu-bg");
    
    // TITLE
    krpano.call("addhotspot(shortcut-menu-title);");
    krpano.set("hotspot[shortcut-menu-title].type", "text");
    krpano.set("hotspot[shortcut-menu-title].ath", ath);
    krpano.set("hotspot[shortcut-menu-title].atv", "0");
    krpano.set("hotspot[shortcut-menu-title].html", $t("components")['vr']['shortcuts']);
    krpano.set("hotspot[shortcut-menu-title].ox", "0px");
    krpano.set("hotspot[shortcut-menu-title].oy", "-168px");
    krpano.set("hotspot[shortcut-menu-title].alpha", "1.0");
    krpano.set("hotspot[shortcut-menu-title].visible", true);
    krpano.set("hotspot[shortcut-menu-title].keep", "true");
    krpano.set("hotspot[shortcut-menu-title].zorder", "1000");
    krpano.set("hotspot[shortcut-menu-title].distorted", "true");
    krpano.set("hotspot[shortcut-menu-title].width", "500px");
    krpano.set("hotspot[shortcut-menu-title].height", "40px");
    krpano.set("hotspot[shortcut-menu-title].bgalpha", "0");
    krpano.set("hotspot[shortcut-menu-title].vcenter", "true");
    krpano.set("hotspot[shortcut-menu-title].cursor", "default");
    krpano.set("hotspot[shortcut-menu-title].scale", "0.66");
    krpano.set("hotspot[shortcut-menu-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    krpano.set("hotspot[shortcut-menu-title].enabled", false);
    shortcuts_to_delete.push("shortcut-menu-title");

    // CLOSE button
    krpano.call("addhotspot(shortcut-menu_close_button);");
    krpano.set("hotspot[shortcut-menu_close_button].type", "image");
    krpano.set("hotspot[shortcut-menu_close_button].ath", ath);
    krpano.set("hotspot[shortcut-menu_close_button].atv", 0);
    krpano.set("hotspot[shortcut-menu_close_button].width", "21px");
    krpano.set("hotspot[shortcut-menu_close_button].height", "21px");
    krpano.set("hotspot[shortcut-menu_close_button].url", userdata["cdn_path"] + "images/close.svg");
    krpano.set("hotspot[shortcut-menu_close_button].ox", "281px");
    krpano.set("hotspot[shortcut-menu_close_button].oy", "-168px");
    krpano.set("hotspot[shortcut-menu_close_button].alpha", "1.0");
    krpano.set("hotspot[shortcut-menu_close_button].visible", true);
    krpano.set("hotspot[shortcut-menu_close_button].keep", "true");
    //krpano.set("hotspot[shortcut-menu_close_button].onclick", "js(floorplan-menu(););");
    krpano.set("hotspot[shortcut-menu_close_button].zorder", 1000);
    krpano.set("hotspot[shortcut-menu_close_button].distorted", "true");
    shortcuts_to_delete.push("shortcut-menu_close_button");

    var hs_shortcut_menu_close_button = krpano.get("hotspot[shortcut-menu_close_button]");
    hs_shortcut_menu_close_button.onclick = function() {
        remove_shortcuts();
    }


    // BACK button
    krpano.call("addhotspot(back-shortcut_detail);");
    krpano.set("hotspot[back-shortcut_detail].type", "image");
    krpano.set("hotspot[back-shortcut_detail].ath", ath);
    krpano.set("hotspot[back-shortcut_detail].atv", "0");
    krpano.set("hotspot[back-shortcut_detail].url", userdata["cdn_path"] + "images/back.svg");
    krpano.set("hotspot[back-shortcut_detail].ox", "-255px");
    krpano.set("hotspot[back-shortcut_detail].oy", "-168px");
    krpano.set("hotspot[back-shortcut_detail].alpha", "1.0");
    krpano.set("hotspot[back-shortcut_detail].visible", "true");
    krpano.set("hotspot[back-shortcut_detail].keep", "true");
    krpano.set("hotspot[back-shortcut_detail].zorder", "1400");
    krpano.set("hotspot[back-shortcut_detail].distorted", "true");
    krpano.set("hotspot[back-shortcut_detail].scale", "0.66");
    shortcuts_to_delete.push("back-shortcut_detail");

    var hs_back_shortcut_detail = krpano.get("hotspot[back-shortcut_detail]");
    hs_back_shortcut_detail.onclick = function() {
        remove_shortcuts();
        add_global_menu();
    }

    // BACK button text
    krpano.call("addhotspot(back-shortcut-text_detail);");
    krpano.set("hotspot[back-shortcut-text_detail].type", "text");
    krpano.set("hotspot[back-shortcut-text_detail].ath", ath);
    krpano.set("hotspot[back-shortcut-text_detail].atv", "0");
    krpano.set("hotspot[back-shortcut-text_detail].ox", "-242px");
    krpano.set("hotspot[back-shortcut-text_detail].html", $t("components")['vr']['back']);
    krpano.set("hotspot[back-shortcut-text_detail].oy", "-175px");
    krpano.set("hotspot[back-shortcut-text_detail].alpha", "1.0");
    krpano.set("hotspot[back-shortcut-text_detail].visible", "true");
    krpano.set("hotspot[back-shortcut-text_detail].keep", "true");
    krpano.set("hotspot[back-shortcut-text_detail].zorder", "1400");
    krpano.set("hotspot[back-shortcut-text_detail].distorted", "true");
    krpano.set("hotspot[back-shortcut-text_detail].width", "70px");
    krpano.set("hotspot[back-shortcut-text_detail].css", "color:#FAFAFA; text-align:left;font-size:16px;");
    krpano.set("hotspot[back-shortcut-text_detail].bgalpha", "0");
    krpano.set("hotspot[back-shortcut-text_detail].scale", "0.66");
    krpano.set("hotspot[back-shortcut-text_detail].edge", "top");
    shortcuts_to_delete.push("back-shortcut-text_detail");

    var hs_back_shortcut_text_detail = krpano.get("hotspot[back-shortcut-text_detail]");
    hs_back_shortcut_text_detail.onclick = function() {
        remove_shortcuts();
        add_global_menu();
    }

    // shortcut controls panel
    krpano.call("addhotspot(shortcut-controls-panel);");
    krpano.set("hotspot[shortcut-controls-panel].type", "text");
    krpano.set("hotspot[shortcut-controls-panel].ath", ath);
    krpano.set("hotspot[shortcut-controls-panel].atv", "0");
    krpano.set("hotspot[shortcut-controls-panel].width", "900px");
    krpano.set("hotspot[shortcut-controls-panel].height", "48px");
    krpano.set("hotspot[shortcut-controls-panel].ox", "0px");
    krpano.set("hotspot[shortcut-controls-panel].oy", "170px");
    krpano.set("hotspot[shortcut-controls-panel].alpha", "1.0");
    krpano.set("hotspot[shortcut-controls-panel].visible", "true");
    krpano.set("hotspot[shortcut-controls-panel].keep", "true");
    krpano.set("hotspot[shortcut-controls-panel].zorder", "1400");
    krpano.set("hotspot[shortcut-controls-panel].distorted", "true");
    krpano.set("hotspot[shortcut-controls-panel].css", "color:#FAFAFA; text-align:left;font-size:16px;");
    krpano.set("hotspot[shortcut-controls-panel].bgcolor", "0x3f3f46");
    krpano.set("hotspot[shortcut-controls-panel].bgalpha", "0.1");
    krpano.set("hotspot[shortcut-controls-panel].cursor", "default");
    krpano.set("hotspot[shortcut-controls-panel].enabled", "false");
    krpano.set("hotspot[shortcut-controls-panel].bgroundedge", "0 0 12 12");
    krpano.set("hotspot[shortcut-controls-panel].scale", "0.66");
    shortcuts_to_delete.push("shortcut-controls-panel");

    // automatic tour text
    krpano.call("addhotspot(shortcut-automatic-tour-text);");
    krpano.set("hotspot[shortcut-automatic-tour-text].type", "text");
    krpano.set("hotspot[shortcut-automatic-tour-text].ath", ath);
    krpano.set("hotspot[shortcut-automatic-tour-text].atv", "0");
    krpano.set("hotspot[shortcut-automatic-tour-text].width", "150px");
    krpano.set("hotspot[shortcut-automatic-tour-text].height", "48px");
    krpano.set("hotspot[shortcut-automatic-tour-text].ox", "-305px");
    krpano.set("hotspot[shortcut-automatic-tour-text].oy", "170px");
    krpano.set("hotspot[shortcut-automatic-tour-text].alpha", "1.0");
    krpano.set("hotspot[shortcut-automatic-tour-text].visible", "true");
    krpano.set("hotspot[shortcut-automatic-tour-text].keep", "true");
    krpano.set("hotspot[shortcut-automatic-tour-text].html", "Automatic Tour");
    krpano.set("hotspot[shortcut-automatic-tour-text].zorder", "1400");
    krpano.set("hotspot[shortcut-automatic-tour-text].distorted", "true");
    krpano.set("hotspot[shortcut-automatic-tour-text].css", "color:#3F3F46; text-align:right;font-size:16px;font-weight:500;");
    krpano.set("hotspot[shortcut-automatic-tour-text].bgcolor", "0x3f3f46");
    krpano.set("hotspot[shortcut-automatic-tour-text].bgalpha", "0");
    krpano.set("hotspot[shortcut-automatic-tour-text].scale", "0.66");
    krpano.set("hotspot[shortcut-automatic-tour-text].edge", "left");
    krpano.set("hotspot[shortcut-automatic-tour-text].enabled", "false");
    krpano.set("hotspot[shortcut-automatic-tour-text].cursor", "default");
    krpano.set("hotspot[shortcut-automatic-tour-text].vcenter", "true");
    shortcuts_to_delete.push("shortcut-automatic-tour-text");

    // shortcut pagination numbers
    krpano.call("addhotspot(shortcut-pagination-numbers);");
    krpano.set("hotspot[shortcut-pagination-numbers].type", "text");
    krpano.set("hotspot[shortcut-pagination-numbers].ath", ath);
    krpano.set("hotspot[shortcut-pagination-numbers].atv", "0");
    krpano.set("hotspot[shortcut-pagination-numbers].width", "150px");
    krpano.set("hotspot[shortcut-pagination-numbers].height", "48px");
    krpano.set("hotspot[shortcut-pagination-numbers].ox", "276px");
    krpano.set("hotspot[shortcut-pagination-numbers].oy", "170px");
    krpano.set("hotspot[shortcut-pagination-numbers].alpha", "1.0");
    krpano.set("hotspot[shortcut-pagination-numbers].visible", "true");
    krpano.set("hotspot[shortcut-pagination-numbers].keep", "true");
    krpano.set("hotspot[shortcut-pagination-numbers].zorder", "1400");
    krpano.set("hotspot[shortcut-pagination-numbers].distorted", "true");
    krpano.set("hotspot[shortcut-pagination-numbers].css", "color:#3F3F46; text-align:right;font-size:16px;font-weight:500;");
    krpano.set("hotspot[shortcut-pagination-numbers].bgcolor", "0x3f3f46");
    krpano.set("hotspot[shortcut-pagination-numbers].bgalpha", "0");
    krpano.set("hotspot[shortcut-pagination-numbers].scale", "0.66");
    krpano.set("hotspot[shortcut-pagination-numbers].edge", "right");
    krpano.set("hotspot[shortcut-pagination-numbers].enabled", "false");
    krpano.set("hotspot[shortcut-pagination-numbers].cursor", "default");
    krpano.set("hotspot[shortcut-pagination-numbers].vcenter", "true");
    shortcuts_to_delete.push("shortcut-pagination-numbers");

    function set_shortcuts_pagination_number() {
        for (let index = 0; index < scenes.length; index++) {
            const element = scenes[index];
            if (element.name.toLowerCase() == krpano.get("xml.scene").toLowerCase()) {
                let i = index + 1;
                krpano.set("hotspot[shortcut-pagination-numbers].html", i + "/" + scenes.length);
            }
        }
    }

    set_shortcuts_pagination_number();


    function create_thumbnails() {
        set_shortcuts_pagination_number();

        for (let i = 0; i < scenes.length; i++) {
            const element = scenes[i];
            if (enable_shortcut_pagination) {
                if (element.name.toLowerCase() == krpano.get("xml.scene").toLowerCase()   ) {
                    shortcuts_current_pagination =  Math.floor(i / 3);
                }   
            }
            
        }
        let pag = 3 * shortcuts_current_pagination;
        if (pag < 0 ) {
            pag = 3 * shortcuts_pagination;
        }
        console.log(pag);

        let dizajn_prefix = "";
        

        if (_dizajn != "") {
            dizajn_prefix = "_" + _dizajn;
        }

        if (scenes[pag] != "undefined" && scenes[pag] != undefined) {

             // shortcut thumbnail active
            krpano.call("addhotspot(shortcut-thumbnail-left);");
            krpano.set("hotspot[shortcut-thumbnail-left].type", "text");
            krpano.set("hotspot[shortcut-thumbnail-left].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-left].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-left].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-left].height", "440px");
            krpano.set("hotspot[shortcut-thumbnail-left].ox", "-180");
            krpano.set("hotspot[shortcut-thumbnail-left].oy", "0");
            krpano.set("hotspot[shortcut-thumbnail-left].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-left].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-left].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-left].zorder", "1400");
            krpano.set("hotspot[shortcut-thumbnail-left].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-left].bgcolor", "0x3f3f46");
            krpano.set("hotspot[shortcut-thumbnail-left].bgalpha", "0");
            //krpano.set("hotspot[shortcut-thumbnail-left].bgroundedge", "6");
            krpano.set("hotspot[shortcut-thumbnail-left].bgborder", "1.0 0x3f3f46 0.6");
            krpano.set("hotspot[shortcut-thumbnail-left].scale", "0.66");
            shortcuts_to_delete.push("shortcut-thumbnail-left");

            krpano.call("addhotspot(shortcut-thumbnail-left-image);");
            krpano.set("hotspot[shortcut-thumbnail-left-image].type", "image");
            krpano.set("hotspot[shortcut-thumbnail-left-image].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-left-image].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-left-image].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-left-image].height", "440px");
            krpano.set("hotspot[shortcut-thumbnail-left-image].ox", "-180");
            krpano.set("hotspot[shortcut-thumbnail-left-image].oy", "0");
            krpano.set("hotspot[shortcut-thumbnail-left-image].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-left-image].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-image].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-image].zorder", "1300");
            krpano.set("hotspot[shortcut-thumbnail-left-image].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-image].scale", "0.66");
            shortcuts_to_delete.push("shortcut-thumbnail-left-image");

            krpano.call("addhotspot(shortcut-thumbnail-left-title);");
            krpano.set("hotspot[shortcut-thumbnail-left-title].type", "text");
            krpano.set("hotspot[shortcut-thumbnail-left-title].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-left-title].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-left-title].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-left-title].height", "48px");
            krpano.set("hotspot[shortcut-thumbnail-left-title].ox", "-180");
            krpano.set("hotspot[shortcut-thumbnail-left-title].oy", "130");
            krpano.set("hotspot[shortcut-thumbnail-left-title].html", "Living room");
            krpano.set("hotspot[shortcut-thumbnail-left-title].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-left-title].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-title].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-title].zorder", "1400");
            krpano.set("hotspot[shortcut-thumbnail-left-title].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-title].bgcolor", "0x3f3f46");
            krpano.set("hotspot[shortcut-thumbnail-left-title].bgalpha", "0.7");
            krpano.set("hotspot[shortcut-thumbnail-left-title].scale", "0.66");
            krpano.set("hotspot[shortcut-thumbnail-left-title].vcenter", "true");
            krpano.set("hotspot[shortcut-thumbnail-left-title].css", "color:#fafafa; text-align:center;font-size:16px;font-weight:500;");
            shortcuts_to_delete.push("shortcut-thumbnail-left-title");


            krpano.set("hotspot[shortcut-thumbnail-left-title].html", scenes[pag].title);
            krpano.set("hotspot[shortcut-thumbnail-left-image].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes_thumbnails/" + scenes[pag].name + dizajn_prefix + "_vr_thumbs.jpg");

            if (krpano.get("xml.scene").toLowerCase() != scenes[pag].name.toLowerCase()) {
                krpano.set("hotspot[shortcut-thumbnail-left].enabled", true);
                krpano.set("hotspot[shortcut-thumbnail-left].bgborder", "2.0 0x3f3f46 0.6");
                var thumbnail_left = krpano.get("hotspot[shortcut-thumbnail-left]");
                thumbnail_left.onclick = function() {
                    stop_autoPlay();
                    krpano.call("loadscene(" + scenes[pag].name +",null,KEEPVIEW,NOBLEND)");  
                    create_thumbnails();
                }
            } else {
                krpano.set("hotspot[shortcut-thumbnail-left].bgborder", "2.0 0x3f3f46 1");
                krpano.set("hotspot[shortcut-thumbnail-left].enabled", false);
            }
        } else {
            krpano.call("removehotspot(shortcut-thumbnail-left)");
            krpano.call("removehotspot(shortcut-thumbnail-left-image)");
            krpano.call("removehotspot(shortcut-thumbnail-left-title)");
        }

        if (scenes[pag+1] != "undefined" && scenes[pag+1] != undefined) {

            // shortcut thumbnail active
            krpano.call("addhotspot(shortcut-thumbnail-center);");
            krpano.set("hotspot[shortcut-thumbnail-center].type", "text");
            krpano.set("hotspot[shortcut-thumbnail-center].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-center].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-center].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-center].height", "440px");
            krpano.set("hotspot[shortcut-thumbnail-center].ox", "0");
            krpano.set("hotspot[shortcut-thumbnail-center].oy", "0");
            krpano.set("hotspot[shortcut-thumbnail-center].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-center].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-center].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-center].zorder", "1400");
            krpano.set("hotspot[shortcut-thumbnail-center].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-center].bgcolor", "0x3f3f46");
            krpano.set("hotspot[shortcut-thumbnail-center].bgalpha", "0");
            krpano.set("hotspot[shortcut-thumbnail-center].bgborder", "2.0 0x3f3f46 1");
            //krpano.set("hotspot[shortcut-thumbnail-center].bgroundedge", "6");
            krpano.set("hotspot[shortcut-thumbnail-center].scale", "0.66");
            shortcuts_to_delete.push("shortcut-thumbnail-center");

            krpano.call("addhotspot(shortcut-thumbnail-center-image);");
            krpano.set("hotspot[shortcut-thumbnail-center-image].type", "image");
            krpano.set("hotspot[shortcut-thumbnail-center-image].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-center-image].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-center-image].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-center-image].height", "440px");
            krpano.set("hotspot[shortcut-thumbnail-center-image].ox", "0");
            krpano.set("hotspot[shortcut-thumbnail-center-image].oy", "0");
            krpano.set("hotspot[shortcut-thumbnail-center-image].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-center-image].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-image].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-image].zorder", "1300");
            krpano.set("hotspot[shortcut-thumbnail-center-image].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-image].scale", "0.66");
            shortcuts_to_delete.push("shortcut-thumbnail-center-image");

            krpano.call("addhotspot(shortcut-thumbnail-center-title);");
            krpano.set("hotspot[shortcut-thumbnail-center-title].type", "text");
            krpano.set("hotspot[shortcut-thumbnail-center-title].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-center-title].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-center-title].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-center-title].height", "48px");
            krpano.set("hotspot[shortcut-thumbnail-center-title].ox", "0");
            krpano.set("hotspot[shortcut-thumbnail-center-title].oy", "130");
            krpano.set("hotspot[shortcut-thumbnail-center-title].html", "Living room");
            krpano.set("hotspot[shortcut-thumbnail-center-title].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-center-title].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-title].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-title].zorder", "1400");
            krpano.set("hotspot[shortcut-thumbnail-center-title].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-title].bgcolor", "0x3f3f46");
            krpano.set("hotspot[shortcut-thumbnail-center-title].bgalpha", "0.7");
            krpano.set("hotspot[shortcut-thumbnail-center-title].scale", "0.66");
            krpano.set("hotspot[shortcut-thumbnail-center-title].vcenter", "true");
            krpano.set("hotspot[shortcut-thumbnail-center-title].css", "color:#fafafa; text-align:center;font-size:16px;font-weight:500;");
            shortcuts_to_delete.push("shortcut-thumbnail-center-title");

            krpano.set("hotspot[shortcut-thumbnail-center-title].html", scenes[pag + 1].title);
            krpano.set("hotspot[shortcut-thumbnail-center-image].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes_thumbnails/" + scenes[pag + 1].name + dizajn_prefix +  "_vr_thumbs.jpg");

            if (krpano.get("xml.scene").toLowerCase() != scenes[pag + 1].name.toLowerCase()) {
                krpano.set("hotspot[shortcut-thumbnail-center].enabled", true);
                krpano.set("hotspot[shortcut-thumbnail-center].bgborder", "2.0 0x3f3f46 0.6");
                var thumbnail_center = krpano.get("hotspot[shortcut-thumbnail-center]");
                thumbnail_center.onclick = function() {
                    stop_autoPlay();
                    krpano.call("loadscene(" + scenes[pag + 1].name +",null,KEEPVIEW,NOBLEND)");  
                    create_thumbnails();
                }
            } else {
                krpano.set("hotspot[shortcut-thumbnail-center].bgborder", "2.0 0x3f3f46 1");
                krpano.set("hotspot[shortcut-thumbnail-center].enabled", false);
            }
        } else {
            krpano.call("removehotspot(shortcut-thumbnail-center)");
            krpano.call("removehotspot(shortcut-thumbnail-center-image)");
            krpano.call("removehotspot(shortcut-thumbnail-center-title)");
        }
        

        if (scenes[pag+2] != "undefined" && scenes[pag+2] != undefined) {

            // shortcut thumbnail active
            krpano.call("addhotspot(shortcut-thumbnail-right);");
            krpano.set("hotspot[shortcut-thumbnail-right].type", "text");
            krpano.set("hotspot[shortcut-thumbnail-right].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-right].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-right].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-right].height", "440px");
            krpano.set("hotspot[shortcut-thumbnail-right].ox", "180");
            krpano.set("hotspot[shortcut-thumbnail-right].oy", "0");
            krpano.set("hotspot[shortcut-thumbnail-right].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-right].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-right].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-right].zorder", "1400");
            krpano.set("hotspot[shortcut-thumbnail-right].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-right].bgcolor", "0x3f3f46");
            krpano.set("hotspot[shortcut-thumbnail-right].bgalpha", "0");
            //krpano.set("hotspot[shortcut-thumbnail-right].bgroundedge", "6");
            krpano.set("hotspot[shortcut-thumbnail-right].bgborder", "1.0 0x3f3f46 0.6");
            krpano.set("hotspot[shortcut-thumbnail-right].scale", "0.66");
            shortcuts_to_delete.push("shortcut-thumbnail-right");

            krpano.call("addhotspot(shortcut-thumbnail-right-image);");
            krpano.set("hotspot[shortcut-thumbnail-right-image].type", "image");
            krpano.set("hotspot[shortcut-thumbnail-right-image].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-right-image].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-right-image].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-right-image].height", "440px");
            krpano.set("hotspot[shortcut-thumbnail-right-image].ox", "180");
            krpano.set("hotspot[shortcut-thumbnail-right-image].oy", "0");
            krpano.set("hotspot[shortcut-thumbnail-right-image].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-right-image].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-image].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-image].zorder", "1300");
            krpano.set("hotspot[shortcut-thumbnail-right-image].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-image].scale", "0.66");
            shortcuts_to_delete.push("shortcut-thumbnail-right-image");

            krpano.call("addhotspot(shortcut-thumbnail-right-title);");
            krpano.set("hotspot[shortcut-thumbnail-right-title].type", "text");
            krpano.set("hotspot[shortcut-thumbnail-right-title].ath", ath);
            krpano.set("hotspot[shortcut-thumbnail-right-title].atv", "0");
            krpano.set("hotspot[shortcut-thumbnail-right-title].width", "260px");
            krpano.set("hotspot[shortcut-thumbnail-right-title].height", "48px");
            krpano.set("hotspot[shortcut-thumbnail-right-title].ox", "180");
            krpano.set("hotspot[shortcut-thumbnail-right-title].oy", "130");
            krpano.set("hotspot[shortcut-thumbnail-right-title].html", "Living room");
            krpano.set("hotspot[shortcut-thumbnail-right-title].alpha", "1.0");
            krpano.set("hotspot[shortcut-thumbnail-right-title].visible", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-title].keep", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-title].zorder", "1400");
            krpano.set("hotspot[shortcut-thumbnail-right-title].distorted", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-title].bgcolor", "0x3f3f46");
            krpano.set("hotspot[shortcut-thumbnail-right-title].bgalpha", "0.7");
            krpano.set("hotspot[shortcut-thumbnail-right-title].scale", "0.66");
            krpano.set("hotspot[shortcut-thumbnail-right-title].vcenter", "true");
            krpano.set("hotspot[shortcut-thumbnail-right-title].css", "color:#fafafa; text-align:center;font-size:16px;font-weight:500;");
            shortcuts_to_delete.push("shortcut-thumbnail-right-title");

           krpano.set("hotspot[shortcut-thumbnail-right-title].html", scenes[pag + 2].title);
           krpano.set("hotspot[shortcut-thumbnail-right-image].url", userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes_thumbnails/" + scenes[pag + 2].name + dizajn_prefix +  "_vr_thumbs.jpg");

           if (krpano.get("xml.scene").toLowerCase() != scenes[pag + 2].name.toLowerCase()) {
                krpano.set("hotspot[shortcut-thumbnail-right].enabled", true);
                krpano.set("hotspot[shortcut-thumbnail-right].bgborder", "2.0 0x3f3f46 0.6");
                var thumbnail_right = krpano.get("hotspot[shortcut-thumbnail-right]");
                thumbnail_right.onclick = function() {
                    stop_autoPlay();
                    krpano.call("loadscene(" + scenes[pag + 2].name +",null,KEEPVIEW,NOBLEND)");  
                    create_thumbnails();
                    
                }
            } else {
                krpano.set("hotspot[shortcut-thumbnail-right].bgborder", "2.0 0x3f3f46 1");
                krpano.set("hotspot[shortcut-thumbnail-right].enabled", false);
            }
        } else {
            krpano.call("removehotspot(shortcut-thumbnail-right)");
            krpano.call("removehotspot(shortcut-thumbnail-right-image)");
            krpano.call("removehotspot(shortcut-thumbnail-right-title)");
        }
        
        



        
        
        

        



        



       
    }

    

    
   

    

    


    create_thumbnails();

    // pagination arrows 
    krpano.call("addhotspot(shortcut-pagination-arrow-r);");
    krpano.set("hotspot[shortcut-pagination-arrow-r].type", "image");
    krpano.set("hotspot[shortcut-pagination-arrow-r].ath", ath);
    krpano.set("hotspot[shortcut-pagination-arrow-r].atv", 0);
    krpano.set("hotspot[shortcut-pagination-arrow-r].url", userdata["cdn_path"] + "images/arrow-vr-r.svg");
    krpano.set("hotspot[shortcut-pagination-arrow-r].ox", "283");   
    krpano.set("hotspot[shortcut-pagination-arrow-r].oy", "0px");
    krpano.set("hotspot[shortcut-pagination-arrow-r].alpha", "1.0");
    krpano.set("hotspot[shortcut-pagination-arrow-r].visible", "true");
    krpano.set("hotspot[shortcut-pagination-arrow-r].enabled", "true");
    krpano.set("hotspot[shortcut-pagination-arrow-r].keep", "true");
    krpano.set("hotspot[shortcut-pagination-arrow-r].width", "prop");
    krpano.set("hotspot[shortcut-pagination-arrow-r].height", "40px");
    krpano.set("hotspot[shortcut-pagination-arrow-r].zorder", "1100");
    krpano.set("hotspot[shortcut-pagination-arrow-r].scale", "0.6");
    krpano.set("hotspot[shortcut-pagination-arrow-r].distorted", "true");
    shortcuts_to_delete.push("shortcut-pagination-arrow-r");

    var hs_pag_next = krpano.get("hotspot[shortcut-pagination-arrow-r]");
    hs_pag_next.onclick = function() {
        if (shortcuts_current_pagination !== shortcuts_pagination) {
            shortcuts_current_pagination++
        } else {
            shortcuts_current_pagination = 0;
        }
        enable_shortcut_pagination = false;
        create_thumbnails();
    }

    krpano.call("addhotspot(shortcut-pagination-arrow-l);");
    krpano.set("hotspot[shortcut-pagination-arrow-l].type", "image");
    krpano.set("hotspot[shortcut-pagination-arrow-l].ath", ath);
    krpano.set("hotspot[shortcut-pagination-arrow-l].atv", 0);
    krpano.set("hotspot[shortcut-pagination-arrow-l].url", userdata["cdn_path"] + "images/arrow-vr-l.svg");
    krpano.set("hotspot[shortcut-pagination-arrow-l].ox", "-283");   
    krpano.set("hotspot[shortcut-pagination-arrow-l].oy", "0px");
    krpano.set("hotspot[shortcut-pagination-arrow-l].alpha", "1.0");
    krpano.set("hotspot[shortcut-pagination-arrow-l].visible", "true");
    krpano.set("hotspot[shortcut-pagination-arrow-l].enabled", "true");
    krpano.set("hotspot[shortcut-pagination-arrow-l].keep", "true");
    krpano.set("hotspot[shortcut-pagination-arrow-l].width", "prop");
    krpano.set("hotspot[shortcut-pagination-arrow-l].height", "40px");
    krpano.set("hotspot[shortcut-pagination-arrow-l].zorder", "1100");
    krpano.set("hotspot[shortcut-pagination-arrow-l].scale", "0.6");
    krpano.set("hotspot[shortcut-pagination-arrow-l].distorted", "true");
    shortcuts_to_delete.push("shortcut-pagination-arrow-l");

    var hs_pag_prev = krpano.get("hotspot[shortcut-pagination-arrow-l]");
    hs_pag_prev.onclick = function() {
        if (shortcuts_current_pagination !== shortcuts_pagination) {
            shortcuts_current_pagination--
        } else {
            shortcuts_current_pagination = shortcuts_pagination;
        }
        enable_shortcut_pagination = false;
        create_thumbnails();
    }


    //automatic tour controls
    //PLAY
    krpano.call("addhotspot(shortcut-autotour-play);");
    krpano.set("hotspot[shortcut-autotour-play].type", "image");
    krpano.set("hotspot[shortcut-autotour-play].ath", ath);
    krpano.set("hotspot[shortcut-autotour-play].atv", 0);
    krpano.set("hotspot[shortcut-autotour-play].url", userdata["cdn_path"] + "images/play.svg");
    krpano.set("hotspot[shortcut-autotour-play].ox", "0px");   
    krpano.set("hotspot[shortcut-autotour-play].oy", "170px");
    krpano.set("hotspot[shortcut-autotour-play].alpha", "1.0");
    krpano.set("hotspot[shortcut-autotour-play].visible", "true");
    krpano.set("hotspot[shortcut-autotour-play].enabled", "true");
    krpano.set("hotspot[shortcut-autotour-play].keep", "true");
    krpano.set("hotspot[shortcut-autotour-play].width", "prop");
    krpano.set("hotspot[shortcut-autotour-play].height", "40px");
    krpano.set("hotspot[shortcut-autotour-play].zorder", "1100");
    krpano.set("hotspot[shortcut-autotour-play].scale", "0.6");
    krpano.set("hotspot[shortcut-autotour-play].distorted", "true");
    shortcuts_to_delete.push("shortcut-autotour-play");

    let timerTime = 4000;
    let autoPlay = null;

    var hs_shortcut_autoplay = krpano.get("hotspot[shortcut-autotour-play]");
    hs_shortcut_autoplay.onclick = function() {
        switch (autoPlay) {
            case null:
                run_autoPlay();
                break;
        
            default:
                stop_autoPlay();
                break;
        }
    }

    //previous
    krpano.call("addhotspot(shortcut-autotour-previous);");
    krpano.set("hotspot[shortcut-autotour-previous].type", "image");
    krpano.set("hotspot[shortcut-autotour-previous].ath", ath);
    krpano.set("hotspot[shortcut-autotour-previous].atv", 0);
    krpano.set("hotspot[shortcut-autotour-previous].url", userdata["cdn_path"] + "images/previous.svg");
    krpano.set("hotspot[shortcut-autotour-previous].ox", "-40px");   
    krpano.set("hotspot[shortcut-autotour-previous].oy", "170px");
    krpano.set("hotspot[shortcut-autotour-previous].alpha", "1.0");
    krpano.set("hotspot[shortcut-autotour-previous].visible", "true");
    krpano.set("hotspot[shortcut-autotour-previous].enabled", "true");
    krpano.set("hotspot[shortcut-autotour-previous].keep", "true");
    krpano.set("hotspot[shortcut-autotour-previous].width", "prop");
    krpano.set("hotspot[shortcut-autotour-previous].height", "40px");
    krpano.set("hotspot[shortcut-autotour-previous].zorder", "1100");
    krpano.set("hotspot[shortcut-autotour-previous].scale", "0.6");
    krpano.set("hotspot[shortcut-autotour-previous].distorted", "true");
    shortcuts_to_delete.push("shortcut-autotour-previous");

    var hs_shortcut_previous = krpano.get("hotspot[shortcut-autotour-previous]");
    hs_shortcut_previous.onclick = function() {
        stop_autoPlay();
        let i = 0;
        for (let index = 0; index < scenes.length; index++) {
            const element = scenes[index];

            if (element.name.toLowerCase() == krpano.get("xml.scene").toLowerCase()) {
                
                if (index == scenes.length) {
                    i = scenes.length;   
                } else {
                    i = index - 1;
                }
            }
        }
        console.log(scenes);
        console.log("i je : " + i);
        if (scenes[i] == undefined || scenes[i] == "undefined") {
            i = (scenes.length - 1);
        }
        console.log(i);
        krpano.call("loadscene(" + scenes[i].name +",null,KEEPVIEW,NOBLEND)");  
        set_shortcuts_pagination_number();
        enable_shortcut_pagination = true;
        create_thumbnails();
    }

    //next
    krpano.call("addhotspot(shortcut-autotour-next);");
    krpano.set("hotspot[shortcut-autotour-next].type", "image");
    krpano.set("hotspot[shortcut-autotour-next].ath", ath);
    krpano.set("hotspot[shortcut-autotour-next].atv", 0);
    krpano.set("hotspot[shortcut-autotour-next].url", userdata["cdn_path"] + "images/next.svg");
    krpano.set("hotspot[shortcut-autotour-next].ox", "40px");   
    krpano.set("hotspot[shortcut-autotour-next].oy", "170px");
    krpano.set("hotspot[shortcut-autotour-next].alpha", "1.0");
    krpano.set("hotspot[shortcut-autotour-next].visible", "true");
    krpano.set("hotspot[shortcut-autotour-next].enabled", "true");
    krpano.set("hotspot[shortcut-autotour-next].keep", "true");
    krpano.set("hotspot[shortcut-autotour-next].width", "prop");
    krpano.set("hotspot[shortcut-autotour-next].height", "40px");
    krpano.set("hotspot[shortcut-autotour-next].zorder", "1100");
    krpano.set("hotspot[shortcut-autotour-next].scale", "0.6");
    krpano.set("hotspot[shortcut-autotour-next].distorted", "true");
    shortcuts_to_delete.push("shortcut-autotour-next");

    var hs_shortcut_next = krpano.get("hotspot[shortcut-autotour-next]");

    function openNextScene() {
        let i = 0;
        for (let index = 0; index < scenes.length; index++) {
            const element = scenes[index];

            if (element.name.toLowerCase() == krpano.get("xml.scene").toLowerCase()) {
                
                if (index == scenes.length) {
                    i = 0;   
                } else {
                    i = index + 1;
                }
            }
        }

        if (scenes[i] == undefined || scenes[i] == "undefind") {
            i = 0;
        }
        krpano.call("loadscene(" + scenes[i].name +",null,KEEPVIEW,NOBLEND)");  
        set_shortcuts_pagination_number();
        enable_shortcut_pagination = true;
        create_thumbnails();
    }

    hs_shortcut_next.onclick = function() {
        stop_autoPlay();
       openNextScene();
    }

    

    function run_autoPlay() {
        shortcuts_autoplay = true;
        krpano.set("hotspot[shortcut-autotour-play].url", userdata["cdn_path"] + "images/stop.svg");
        autoPlay = setInterval(() => {
            openNextScene();
        }, timerTime);
    }

    function stop_autoPlay() {
        clearInterval(autoPlay);
        krpano.set("hotspot[shortcut-autotour-play].url", userdata["cdn_path"] + "images/play.svg");
        autoPlay = null;
        shortcuts_autoplay = false;
    }
    opened_shortctus = true;
    

   
}

function remove_shortcuts() {
    for (let index = 0; index < shortcuts_to_delete.length; index++) {
        krpano.call("removehotspot(" + shortcuts_to_delete[index] + ")");
    }
    opened_shortctus = false;
}
    
</script>

<style lang="scss">
    //   variables
    @import "../assets/scss/mixins.scss";
</style>