<script>
    import { get } from 'svelte/store';
    import { slide } from 'svelte/transition';
    import { t, locale, locales } from "../i18n.js";
    import translations from "../translations.js";

    import { loaded_components, disabled_icons, vr_mode, languages, cart, enable_otacadlo, modal, attributes} from '../store.js';
    import { blur, flat_filter_enabled, basket_enabled } from "../store.js";
    $loaded_components = [...$loaded_components, 'languages'];

    export let userdata, languages_settings, all_languages, _dispositions_enabled;
    //console.log(languages_settings);
    

    let _modal;
    let _vrMode;
    let _languages = null;
    let _enable_otacadlo, blur_enabled, _flat_filter_enabled, _basket_enabled = false;
    let dis_icon = false;
    let tmp = userdata;

    enable_otacadlo.subscribe(value => {
      _enable_otacadlo = value;
    });

    modal.subscribe(value => {
        _modal = value;
    });
    
    vr_mode.subscribe(value => {
        _vrMode = value;
    });

    attributes.subscribe( value => {
      if (value && innerWidth < 650 ) {
          languages.update(n => false);
        }
    });

    languages.subscribe(value => {
        _languages = value;
        //console.log(value);

        if (value && innerWidth < 650 ) {
          attributes.update(n => false);
        }
    });

    blur.subscribe((value) => {
      blur_enabled = value;
    });

    flat_filter_enabled.subscribe((value) => {
      _flat_filter_enabled = value;
    });
    
    basket_enabled.subscribe((value) => {
      _basket_enabled = value;
      
    });

    disabled_icons.subscribe(value => {
      dis_icon = value;
    });

    // zmena aktívneho jazyka
    function change_language($lang) {
        $locale = $lang;
        languages.update(n => false);
    }

    function fadeSlide(node, options) {
		  const slideTrans = slide(node, options)
      return {
        duration: options.duration,
        css: t => `
          ${slideTrans.css(t)}
          opacity: ${t};
        `
      };
    }

  $: outerWidth = 0
	$: innerWidth = 0
	$: outerHeight = 0
	$: innerHeight = 0
    
    
</script>


<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />
<div id="woow-languages">
{#if (blur_enabled == false && _vrMode == false && languages_settings.enabled && !_dispositions_enabled) || (_enable_otacadlo && !_modal) }
  {#if (!_flat_filter_enabled) || (_flat_filter_enabled && innerWidth > 399)}
    <div id="lang" class:active={dis_icon == true} class:moved="{_basket_enabled && !_enable_otacadlo}" on:click="{() => languages.update(n => !_languages)}">
      {$locale}
    </div>

    {#if _languages && !_modal}
      <div id="languages" transition:fadeSlide="{{duration: 150}}">
          <ul>
              {#each all_languages as language}
                  {#if $locale == language}
                      <li class="active" on:click="{() => change_language(language)}">{translations[language]["lang_name"]} <span>({language})</span></li>
                  {:else}
                      <li on:click="{() => change_language(language)}">{translations[language]["lang_name"]} <span>({language})</span></li>
                  {/if}                
              {/each}
          </ul>
      </div>
    {/if}
  {/if}
{/if}


</div>



<style lang="scss">
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/variables.scss";

    #woow-languages{
    #lang {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-transform: uppercase;
        border-radius: 6px;
        @include blur;
        cursor: pointer;
        z-index: 9;

        &.moved {
            right: 72px;
        }

        &.active {
          display: none;
        }
    }

    #languages {
    @include blur;
    @include rubik-r14;
    width: 280px;
    height: auto;
    border-radius: $radius-M;
    position: absolute;
    top: 72px;
    right: 16px;
    padding: 10px 8px;
    z-index: 9;

    ul {
        margin: 0;
        padding: 0;
      li {
        width: 100%;
        height: 45px;
        background-color: transparent;
        margin: 2px 0;
        border-radius: $radius-S;
        display: flex;
        align-items: center;
        padding: 0 8px;
        cursor: pointer;

        span {
            text-transform: uppercase;
            margin-left: 4px;
        }

        &.active {
          background-color: var(--main-color-light005);
          border: 1px solid var(--main-color-light20);
          color: var(--main-color);
        }
      }
    }
  }

  //  ----------------HOVER-------------------------------------------------------------------------------------------------------

  @media (hover: hover) and (pointer: fine) {
    #languages {
      ul {
        li {
          &:hover:not(.active) {
            background-color: var(--primary-color-light005);
            box-shadow: 0 0 0 1px var(--primary-color-light20);
          }
        }
      }
    }
  }
}
</style>