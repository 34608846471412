<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
    
    let contact_form_loaded = false;
    let contact_form_data = null;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
  };


    // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 

      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
              key == "enabled" && json[0][key] == "1"
          ) {
              json[0][key] = true
          }
        }

        contact_form_data = json[0];
        
        contact_form_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("contact_form");

    async function save_settings() {
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(contact_form_data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateContactForm.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


</script>
  
  <div id="wrapper">
    <Aside />

    {#if contact_form_loaded}
    <main in:fade>
      <h1>Kontaktný formulár</h1>

      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button>

      <div class="component">
        <label for="smtp">SMTP server</label>
        <input type="text" name="smtp" bind:value={contact_form_data.mailserver}>
      </div> 

      <div class="component">
        <label for="port">Port</label>
        <input type="text" name="port" bind:value={contact_form_data.port}>
      </div> 

      <div class="component">
        <label for="email_alias">Email alias</label>
        <input type="text" name="email_alias" bind:value={contact_form_data.email_alias}>
      </div> 

      <div class="component">
        <label for="email_subject">Email subject</label>
        <input type="text" name="email_subject" bind:value={contact_form_data.email_subject}>
      </div> 

      <div class="component">
        <label for="sender">Odosielateľ</label>
        <input type="text" name="sender" bind:value={contact_form_data.sender}>
      </div> 

      <div class="component">
        <label for="password">Heslo</label>
        <input type="text" name="password" bind:value={contact_form_data.password}>
      </div> 

 

      <div class="component">
        <label for="recipient">Príjemca</label>
        <input type="text" name="recipient" bind:value={contact_form_data.recipient}>
      </div> 

 
      
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>