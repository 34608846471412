<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = false;

    export let userdata;

    let settings;
    let test = [];
  
    // upload loga na server
    async function importFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        console.log(formData);
        
        const res = await fetch(userdata['base_path'] + 'assets/php/ImportConfiguratorFlats.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            console.log(JSON.parse(json.json));
            let result = JSON.stringify(json);

            if (json.status == true) {
                
                // console.log(json.data);
            addNotification({
                    text: "Byty boli naimportovaní!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  

            }

            else {
            addNotification({
                    text: "Chyba : Byty neboli pridaní!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

</script>
  
  <div id="wrapper">
    <Aside />

    {#if loading_data == false}
    <main in:fade>
      
      <h1>{$t("admin")["aside"]["configurator"]["import_appartments"]}</h1>
      
        <div class="upload">
          <input id="fileupload" type="file" name="fileupload" />
          <button id="upload-button" on:click="{() => importFile()}">{$t("admin")["global"]["upload"]}</button>
        </div>
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>