<script>
    import { onMount } from 'svelte';
	import { t, locale, locales } from "../i18n.js";
    import { fade } from "svelte/transition";
	import { each } from 'svelte/internal';
	import {get, writable} from 'svelte/store';

	let vr_type = '';

	import {loaded_krpano, tour_select, flat_id} from "../store.js";
	import { vr_mode } from '../store.js';
	import { device_type } from '../store.js';
	import { gyro_mode } from '../store.js';

	import { floor} from '../store.js';


	// kontrola URL adresy
	let allow_config = false;
	let flat = null;

	flat_id.subscribe((value) => {
		flat = value;
	});	

	// zistenie parametrov z URL
	const url_parameters = new URLSearchParams(window.location.search);
	if (
		url_parameters.has('flat_id')
	) {
		flat_id.update( n => url_parameters.get('flat_id'));
		localStorage.setItem('show_intro', false);

		if (
			url_parameters.has('allow_config')
		) {
			allow_config = url_parameters.get('allow_config');
		}
	}
	
	console.log(flat + ' | ' + allow_config);


	let _floor = "1";

	floor.subscribe((value) => {
		_floor = value;
		//console.log(_floor);
	});

	export let userdata;

	export let location;
	let tmp = location;
    let showTour = false;


	import Basket from '../tour_components/Basket.svelte';
	import Languages from '../tour_components/Languages.svelte';
	import Logo from '../tour_components/Logo.svelte';
	import Sidebar from '../tour_components/Sidebar.svelte';
	import Shortcuts from '../tour_components/Shortcuts.svelte';
	// import Intro from '../tour_components/Intro.svelte';
	import Floorplan from '../tour_components/Floorplan.svelte';
	import Blur from '../tour_components/Blur.svelte';
	//import Picker from '../tour_components/Picker.svelte';
	import Vr_tools from '../tour_components/Vr_tools.svelte';
	

	function webvr_state() {
		let krpano = document.getElementById("krpanoSWFObject")
		//vr_mode = krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
		// 0=off, 1=fake, 2=mobile, 3=true webvr (oculus go, rift, etc)	
		vr_type = krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? krpano.webvr.isvrbrowser ? 3 : 1 : 2 : 0 : 14;
		// device_type.update(n => krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? krpano.webvr.isvrbrowser ? 3 : 1 : 2 : 0 : 14);
		// //console.log('TYPE : ' + vr_type);	
		// return krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
	}

	const initializeRemarkable = () => {
		fetchData(test);
	}

	let settings;
	let loading_data = true;
	let start_scene;
	let start_scene_number = 0;

	let krpano_full_loaded = false;

	let enable_sound = false;

	function set_target() {
		let tmp_data = settings;

		//console.log(tmp_data);

		for (let index = 0; index < tmp_data.scenes.length; index++) {		
			if (
				tmp_data.scenes[index].hotspots
			) {
				//console.log('scéna : '+ tmp_data.scenes[index].name);
				let new_target;
				let start_numb = -3;

				for (let i = 0; i < tmp_data.scenes[index].hotspots.length; i++) {
					if (
						tmp_data.scenes[index].hotspots[i].name.indexOf('next-node') != -1 && tmp_data.scenes[index].hotspots.length > 5
					) {

						if ( i == 3) {
							new_target = 1;
							start_numb = 2;
							
						}

						else {
							new_target = start_numb;
								start_numb ++;
						}

						tmp_data.scenes[index].hotspots[i].new_taget = new_target;
					
										
						//console.log('starý target : ' + tmp_data.scenes[index].hotspots[i].target + ' | nový target : ' + tmp_data.scenes[index].hotspots[i].new_taget + '(' + i + ')');


					}

					else {
						
						tmp_data.scenes[index].hotspots[i].new_taget = null;
					}
					
				
				}
			}
		}

		settings = tmp_data;
		//console.log(settings.scenes);

		// for (let index = 0; index < tmp_data.scenes.length; index++) {
			
		// 	let start_numb = -3;
			
		// 	for (let i = 0; index < tmp_data.scenes[index].hotspots.length; i++) {
				
		// 		// if (
		// 		// 	tmp_data.scenes[index].hotspots[i] && tmp_data.scenes[index].hotspots[i].name.indexOf('next-node') != -1
		// 		// ) {
		// 		// 	let old_target = tmp_data.scenes[index].hotspots[i].name;
		// 		// 	let new_target;
		// 		// 	switch (i) {
		// 		// 		case  i > -1 :
		// 		// 			new_target = i + 1;
		// 		// 			break;
					
		// 		// 		default:
		// 		// 			new_target = start_numb;
		// 		// 			break;
		// 		// 	}

		// 		// 	start_numb++;	
				
		// 		// 	//console.log(old_target + "|" +   new_target);
		// 		// }
		// 	}
		// }
		

		

		
		
	}

	function import_dummy_data() {
		let count = 6;
		let tmp_data = settings;

		let object_depth = {
			0 : {
				depth : 2000,
				size : "2",
				rx : -70,
				h : -90,
				v : 6
			},
			1 : {
				depth : 1400,
				size : "3",
				rx : -60,
				h : -90,
				v : 11
			},
			2 : {
				depth : 1000,
				size : "4",
				rx : -40,
				h : -90,
				v : 22
			},
			3 : {
				depth : 1000,
				size : "4",
				rx : -40,
				h : -90,
				v : 22
			},
			4 : {
				depth : 1400,
				size : "3",
				rx : -60,
				h : -90,
				v : 11
			},
			5 : {
				depth : 2000,
				size : "2",
				rx : -70,
				h : -90,
				v : 6
			}
		}
		
		for (let index = 0; index < tmp_data.scenes.length; index++) {
			tmp_data.scenes[index].hotspots = [];

			let poradie = -1;
			let tmp_ath = -90;
			let tmp_atv = 22;
			let tmp_depth = 1000;  
			let tmp_rx = -40;



			for (let i = 0; i < count; i++) {
				let hodnota;

				switch (i) {
					case 0 : 
						hodnota = index - 3;
					break;

					case 1 : 
						hodnota = index - 2;
					break;

					case 2 : 
						hodnota = index - 1;
					break;

					case 3 : 
						hodnota = index + 1 ;
					break;

					case 4 : 
						hodnota = index + 2 ;
					break;

					case 5 : 
						hodnota = index + 3 ;
					break;
				}


				let dummy_data = {
					id : i,
					name : "next-node_" + i,
					title : "",
					description : "",
					type : "next-node",
					"skin-id" : "floorspot",
					target : "praha_" + hodnota,
					enabled : true,
					ath :  object_depth[i].h,
					atv : object_depth[i].v,
					depth : object_depth[i].size,
					depth_value : object_depth[i].depth,
					rx : object_depth[i].rx,
					ry : 0, 
					data : {
						title : "",
						headline : "",
						img : "img/38.jpg",
						subtitle : "subtitle pre modal",
						content : "content pre modal",
						video_url :"",
						local_video_url : ""
					}
				};



				tmp_data.scenes[index].hotspots.push(dummy_data);
			}

				


			
			
			// for (let i = 0; index < count.length; i++) {
				
				
			// 	let poradie = -1;
			// 	let tmp_ath = -90;
			// 	let tmp_atv = 22;
			// 	let tmp_depth = 1000;  
			// 	let rx = -40;


			// 	// -1

				
			// 	// tmp_data.scenes[index].hotspots[i] = {
			// 	// 	id : i,
			// 	// 	name : "next-node_" + i,
			// 	// 	title : "",
			// 	// 	description : "",
			// 	// 	type : "next-node",
			// 	// 	"skin-id" : "floorspot",
			// 	// 	target : "praha_" + poradie,
			// 	// 	enabled : true,
			// 	// 	ath :  tmp_ath,
			// 	// 	atv : tmp_atv,
			// 	// 	depth : tmp_depth,
			// 	// 	rx : tmp_rx,
			// 	// 	ry : 0, 
			// 	// 	data : {
			// 	// 		title : "",
			// 	// 		headline : "",
			// 	// 		img : "img/38.jpg",
			// 	// 		subtitle : "subtitle pre modal",
			// 	// 		content : "content pre modal",
			// 	// 		video_url :"",
			// 	// 		local_video_url : ""
			// 	// 	}
			// 	// };

			// }
		}

		//console.log(tmp_data);
	}

	// Načítanie scén
	async function fetchData() {
		const res = await fetch(
			// "https://woowstudio.com/molly/praha/assets/json/settings.json",
			userdata['base_path'] + "assets/json/settings.json",
		{}
		);

		if (res.ok) {
		const json = await res.json();
		settings = json;
		start_scene = settings.configuration.start_scene;

			// //console.log(settings);
		loading_data = false;
		showTour = true;

		function check_pano() {
			var krpano = document.getElementById("pano");
			if(typeof(krpano) != 'undefined' && krpano != null){
				load_krpano();
			} else{
			setTimeout(check_pano, 100);
			}
		}

      	check_pano();

		//  import_dummy_data();

		



		return json;
		} else {
		throw new Error(data);
		}
  	}

	const components = [Logo, Shortcuts,Sidebar,Sounds,Floorplan];
	const components_array = ["Logo", "Shortcuts","Sidebar","Sounds","Floorplan"];

	import { active_scene } from '../store.js';

	function floorspot_animation($par) {
		
	}

	// Načítanie krpana
  	function load_krpano () {
		embedpano({
			xml: userdata['base_path'] + "assets/krpano/tour.xml",
			target:"pano",
			html5:"only",
			mobilescale:1.0,
			initvars : {
				scene_name : "tmp",
				show_hs : true,
				floor : _floor
			},
			hooks: {
            loadSceneData(scene) {
            },
            sceneChanged(scene) {
            }
        },
			onready: function (krpano) {
				let loaded_times = 0;
				var krpano = krpano.get("global");
				////console.log(settings.configuration.start_scene);
				krpano.call("loadscene(" + settings.configuration.start_scene + ")");
				webvr_state();
				let data = [];

				krpano.events.onnewpano = function() {
					current_scene = krpano.get("xml.scene").replace('scene_','').toUpperCase();
					active_scene.update(n => krpano.get("xml.scene").replace('scene_','').toUpperCase());

					// //console.log(get(vr_mode));
					if (
						get(vr_mode) == true
					) {
						//console.log(get(active_scene).toLowerCase());
						var hs = krpano.get("hotspot[info]");
						var hts_count = krpano.get("hotspot.count");
						//console.log(hts_count);

					for (let index = 0; index < hts_count; index++) {

						var hts_name = krpano.get("hotspot[" + index +"].name");
						
						if (
							krpano.get("hotspot[" + index +"].name").includes("video")) {
							// krpano.set("hotspot[" + index +"].alpha", "0.0");
							// krpano.set("hotspot[" + index +"].visible", "false");
						}

						if (
							krpano.get("hotspot[" + index +"].name").includes("info") || krpano.get("hotspot[" + index +"].name").includes("video")) 

						 {
							
							// //console.log(get(active_scene).toLowerCase());
							for (let index = 0; index < data.length; index++) {
								
								if (
									data[index].scene == get(active_scene).toLowerCase() && data[index].id == hts_name
								) {
									// //console.log(data[index].title);
									let title = data[index].title;
									let headline = data[index].content;
									let img = data[index].img.replace('assets/','');

									// //console.log(title + ' | ' + headline + ' | '  + img);
									krpano.set("hotspot[" + hts_name + "].onclick", "js(tour_hts_info(true, " + hts_name + "," + title + "," + headline + "," + img + "););");
								}
								
								
							}
							

						}
						
					}

					}


					else {
						var hs = krpano.get("hotspot[info]");
						var hts_count = krpano.get("hotspot.count");

						for (let index = 0; index < hts_count; index++) {
							if (
								krpano.get("hotspot[" + index +"].name").includes("info") ||
								krpano.get("hotspot[" + index +"].name").includes("video")	
							) {
								
								krpano.set("hotspot[" + index +"].alpha", "1.0");
								krpano.set("hotspot[" + index +"].visible", "true");
								// //console.log(krpano.get("hotspot[" + index +"].name"));
								////console.log(krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));

								var hs = krpano.get("hotspot[" + index +"]");
								hs.onclick = function() {

									hts_id = krpano.get("hotspot[" + index +"].id");
									if (
										krpano.get("hotspot[" + index +"].hstype").includes('info') != null
									) {
										modal_type.set('info-v'+ krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));
									}

									if (
										krpano.get("hotspot[" + index +"].hstype").includes('video')
									) {
										modal_type.set('video-v2');
									}

									modal.update(n => !n);
								}
							}

						}
					}
				}

				
				function bulb() {
					let distance, hotspots;
					let perimeter = 30;
					let ath = krpano.get("view.hlookat");
            		let atv = krpano.get("view.vlookat");

					console.log(ath + ' | ' + atv);

					
					if (
						ath < 0
					) {
						if (
							((ath / 360 >>0)* (-1)) > 0
						 )	{
							ath = ath + (360 * ((ath / 360 >>0)* (-1)) );
						}
						
					}

					if (
						ath > 0
					) {
						if (
							(ath / 360 >>0) > 0
						 )	{
							ath = ath - (360 * ((ath / 360 >>0)) );
						}
					}



					console.log(ath);

					// zistenie väčšej hodnoty
					if ( ath >= atv) {
						distance = ath
					}

					else {
						distance = atv;
					}


				
					settings.scenes.forEach(element => {
						if ( element.id.toLowerCase() == krpano.get("xml.scene").toLowerCase()) {
							hotspots = element.hotspots;
							//console.log(hotspots);
							
							hotspots.forEach(element => {
								console.log(element.ath + ' : ' + element.atv);
								
								// if (
								// 	element.ath >= distance || 
								// 	element.ath <= distance
								// ) {

								// }
							})
							console.log('---------------------');

						}
					});
						
	
				}

				

				krpano.events.onviewchanged = function() {
					//bulb();
				}

				krpano.events.onloadcomplete = function() {
				
					
					
				
					

					if (
						loaded_times < 1
					)
					{
						
						krpano_full_loaded = true;
						loaded_krpano.update(n => !n);

						setTimeout(function() {
							preloader.update(n => !n);
							enable_sound = true;
							tour_select.update(n => true);


						}, 1500);
					}
					
					loaded_times++;

				};

				krpano.events.onunknowndevice = function () {
					device_type.update(n=> 555);
				}

				krpano.events.webvr_onexitvr = function() {
					
					
					vr_mode.update(n => false);
					var hts_count = krpano.get("hotspot.count");

						for (let index = 0; index < hts_count; index++) {
							if (
								krpano.get("hotspot[" + index +"].type").includes("infopanel-v1") || krpano.get("hotspot[" + index +"].type").includes("infopanel-v2") || krpano.get("hotspot[" + index +"].type").includes("infopanel-v3") ||
								krpano.get("hotspot[" + index +"].type").includes("videopanel-v1")	|| krpano.get("hotspot[" + index +"].type").includes("videopanel-v2")	
							) {


								krpano.set("hotspot[" + index +"].alpha", "1.0");
								krpano.set("hotspot[" + index +"].visible", "true");

								var hs = krpano.get("hotspot[" + index +"]");
								hs.onclick = function() {

									hts_id = krpano.get("hotspot[" + index +"].id");
									if (
										krpano.get("hotspot[" + index +"].hstype").includes('info') != null
									) {
										modal_type.set('info-v'+ krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));
									}

									if (
										krpano.get("hotspot[" + index +"].hstype").includes('video')
									) {
										modal_type.set('video-v2');
									}

									modal.update(n => !n);
								}
							}
						}
						

				}
				
				krpano.events.webvr_onentervr = function() {
					
					vr_mode.update(n => true);

					var hs = krpano.get("hotspot[info]");
						var hts_count = krpano.get("hotspot.count");

					for (let index = 0; index < hts_count; index++) {

						var hts_name = krpano.get("hotspot[" + index +"].name");
						if (
							krpano.get("hotspot[" + index +"].name").includes("video")) {
							// krpano.set("hotspot[" + index +"].alpha", "0.0");
							// krpano.set("hotspot[" + index +"].visible", "false");
						}

						if (
							krpano.get("hotspot[" + index +"].name").includes("info") || krpano.get("hotspot[" + index +"].name").includes("video")) 
						 {
							
							// //console.log(get(active_scene).toLowerCase());
							for (let index = 0; index < data.length; index++) {
								// //console.log(data[index].scene);
								if (
									data[index].scene == get(active_scene).toLowerCase() && data[index].id == hts_name
								) {
									// //console.log(data[index].title);
									let title = data[index].title;
									let headline = data[index].content;
									let img = data[index].img.replace('assets/','');

									// //console.log(title + ' | ' + headline + ' | '  + img);
									krpano.set("hotspot[" + hts_name + "].onclick", "js(tour_hts_info(true, " + hts_name + "," + title + "," + headline + "," + img + "););");
								}
								
								
							}
							

						}
						
					}									
				};

				krpano.events.gyro_onenable = function() {
					gyro_mode.update(n => true);
				};

				krpano.events.gyro_ondisable = function() {
					gyro_mode.update(n => false);
				};

				krpano.events.webvr_onavailable = function() {

					if (
						krpano.device.mobilevr == true && krpano.webvr.isvrbrowser
					) 
					device_type.update(n => 'vr');

				};
			}
		});
  	}


	// zapnutie / vypnutie modalu
	import { modal } from '../store.js';
	import { modal_type } from '../store.js';
	import { preloader } from '../store.js';
	import Sounds from '../tour_components/Sounds.svelte';
  	import VrTools from '../tour_components/Vr_tools.svelte';
	
	let current_scene;

	// $:current_scene = //console.log(current_scene + ': ' + userdata['base_path']);
	
	let hts_id;
	let show_modal;
  	modal.subscribe(value => {
		show_modal = value;
	});


	let show_preloader;
  	preloader.subscribe(value => {
		show_preloader = value;
		if (
			document.getElementById("intro_div")
		) {
			document.getElementById("intro_div").remove();
		}
	});

  	let modal_type_tmp;

	modal_type.subscribe(value => {
		modal_type_tmp = value;
	});

  	let Modal;
	let Preloader;
	let EnterVR;
	let Intro;
	let TourSelect;
	let Bulb;
	let Login;
	let Screenshot;
	let FlatDispositions;

  const sleep = ms => new Promise(f => setTimeout(f, ms));
	onMount(async () => {
		//await sleep(1000); // simulate network delay
		Login = (await import('../tour_components/Login.svelte')).default
		Intro = (await import('../tour_components/Intro.svelte')).default
    	Modal = (await import('../tour_components/Modal.svelte')).default
		Preloader = (await import('../tour_components/Preloader.svelte')).default
		EnterVR = (await import('../tour_components/Enter_VR.svelte')).default
		Bulb = (await import('../tour_components/Bulb.svelte')).default
		Screenshot = (await import('../tour_components/Screenshot.svelte')).default
		FlatDispositions = (await import('../tour_components/Flat_dispositions.svelte')).default
		
		//TourSelect = (await import('../tour_components/Tour_select.svelte')).default
	});
</script>

<svelte:head>

	<script>

		function test(name) {
			tmp_name = name;
			//console.log(' a je : ' + name);
		}


	</script>

	<script src="{userdata['base_path']}assets/krpano/pano.js" on:load={initializeRemarkable}></script>


	
	{#if loading_data == false}
		<!-- {#each settings.configuration.settings.meta as {id, value}}
			{#if id == "favicon"}
				<link rel="shortcut icon" href="{userdata['base_path'] + value}">
			{/if}
			{#if id == "title"}
				<title>{value}</title>
				<meta property="og:title" content={value} />
				<meta property="twitter:title" content={value} />
			{/if}
			{#if id == "description"}
				<meta name="description" content={value} />
				<meta property="og:description" content={value} />
				<meta property="twitter:description" content={value} />
			{/if}
			{#if id == "image"}
				<meta property="og:image" content="{userdata['base_path'] + value}" />
			{/if}
			{#if id == "twitter_image"}
				<meta property="twitter:image" content="{userdata['base_path'] + value}" />
			{/if}
			{#if id == "image_alt"}
				<meta property="og:image:alt" content={value} />
				<meta property="twitter:image:alt" content={value} />
			{/if}
		{/each}

		<meta property="og:url" content={settings.configuration.settings['base_path']} />
		<meta property="twitter:url" content={settings.configuration.settings['base_path']} />
		<meta property="og:type" content="website" /> -->
	{/if}
</svelte:head>

<!-- inicializácia krpano tour -->
{#if flat != null}
	<div id="intro_div"></div>
	<div id="pano" style="width:100%;height:100%;" >

		<noscript><table style="width:100%;height:100%;"><tr style="vertical-align:middle;"><td><div style="text-align:center;">ERROR:<br/><br/>Javascript not activated<br/><br/></div></td></tr></table></noscript>
	</div>
{/if}

{#if flat == null}
	<svelte:component this={Login} {settings} {userdata}/>
{/if}

<!-- ak je načítanie JSON súboru kompletné  -->
{#if loading_data == false && flat != null}
	<svelte:component this={Blur} {settings} {userdata}/>
	<svelte:component this={FlatDispositions}/>
	{#if Preloader && show_preloader}
		<svelte:component this={Preloader}/>
	{/if}

	{#if Intro && flat != null}
		<svelte:component this={Intro}/>
	{/if}

	{#if krpano_full_loaded}
		<svelte:component this={Bulb}/>
	{/if}
	<!-- zobrazenie povolených komponentov -->
	{#each settings.components as {id, enabled}, index}
		{#if enabled == true }
			<!-- pridaný parameter {settings} pre načítanie v komponente -->
			{#each components_array as data, i}
				
				 {#if data == id}
					 <svelte:component this={components[i]} {settings} {userdata}/>
				 {/if}
			{/each}
				 <!-- content here -->
		
			
			
		{/if}
		
	{/each}

	{#if Modal && show_modal}
		{#if modal_type_tmp === 'google-map'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['maps']['title']}" settings="{settings}" url="{settings.components[7].url}" filter="{settings.components[7].filter}" position="" id="" current_scene="{current_scene}"/>
		{/if}
		{#if modal_type_tmp === 'tour-info'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['tourinfo']['title']}" settings="{settings}" url="" filter="" position="" id="" current_scene="{current_scene}"/>
		{/if}

		{#if modal_type_tmp === 'info-v1' || modal_type_tmp === 'info-v2' || modal_type_tmp === 'info-v3' }
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
		{/if}

		{#if modal_type_tmp === 'video-v1'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
		{/if}

		{#if modal_type_tmp === 'video-v2'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
		{/if}

	{/if}

	<svelte:component this={EnterVR}/>
	
	<!-- <svelte:component this={Picker}/> -->
	<!-- <svelte:component this={TourSelect}/> -->
	<svelte:component this={VrTools} {settings} {userdata}/>

	<!-- komponent screenshot -->
	{#if Screenshot != null}
		<svelte:component this={Screenshot}/>
	{/if}

	
	
{/if}

<style lang="scss">
	  @import "../assets/scss/mixins.scss";

	#pano {
		position: absolute;
		top: 0;
		left: 0;
	}
	#intro_div {
		position: absolute;
		top: 50%;
		left: 50%;
    	transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		background: rgba(255, 254, 254, 0.5) 0% 0% no-repeat padding-box;
  		backdrop-filter: blur(40px);
  		-webkit-backdrop-filter: blur(40px);
  		-moz-filter: blur(40px);
  		z-index: 9;
	}
</style>