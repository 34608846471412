<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    let share_loaded = false;
    let share_data = null;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") || (key == "enable_facebook" && json[0][key] == "0") || (key == "enable_twitter" && json[0][key] == "0") || (key == "enable_linkedin" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
            (key == "enabled" && json[0][key] == "1") || (key == "enable_facebook" && json[0][key] == "1") || (key == "enable_twitter" && json[0][key] == "1") || (key == "enable_linkedin" && json[0][key] == "1")
          ) {
              json[0][key] = true
          }
          
        }

        share_data = json[0];
        
        share_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("share");

    async function save_settings() {

      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(share_data));
      
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateShare.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia Share boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia Share neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if share_loaded}
    <main in:fade>
      
      <h1>{$t("admin")["aside"]["components"]["share"]}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button>
      
      <div class="component item">
        <label>{$t("admin")["components"]["share"]["enable_facebook"]}</label>
        <input type="checkbox" bind:checked={share_data.enable_facebook}>
      </div>  

      <div class="component item">
        <label>{$t("admin")["components"]["share"]["enable_twitter"]}</label>
        <input type="checkbox" bind:checked={share_data.enable_twitter}>
      </div>  

      <div class="component item">
        <label>{$t("admin")["components"]["share"]["enable_linkedin"]}</label>
        <input type="checkbox" bind:checked={share_data.enable_linkedin}>
      </div>  
      
    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";


  </style>
  