<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";

  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";

  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  export let userdata;

  let result;
  let test = [];

  let users = [];
  let flats = [];
  let sample_flats = [];

  let loading_users = true;

  async function fetchData() {
    const res = await fetch(
      userdata["base_path"] + "assets/php/getConfiguratorUsers.php",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      console.log(json);
      users = json;
      result = JSON.stringify(json);
      loading_users = false;

      return json;
    } else {
      throw new Error(data);
    }
  }

  async function getFlats() {
    const res = await fetch(
      userdata["base_path"] + "assets/php/getConfiguratorFlats.php",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      console.log(json);
      flats = json.data;
      result = JSON.stringify(json);

      return json;
    } else {
      throw new Error(data);
    }
  }

  getFlats();

  async function getSampleFlats() {
    const res = await fetch(
      userdata["base_path"] + "assets/php/getConfiguratorSampleFlats.php",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      console.log(json);
      sample_flats = json;
      result = JSON.stringify(json);

      return json;
    } else {
      throw new Error(data);
    }
  }

  getSampleFlats();

  let user_to_delete = {
    id: null,
  };

  async function removeUser($id) {
    user_to_delete = {
      id: users[$id].id,
    };
    if (user_to_delete == "") {
      addNotification({
        text: "nebol zvolený užívateľ.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      const res = await fetch(
        userdata["base_path"] + "assets/php/removeConfiguratorUser.php",
        {
          method: "POST",
          body: JSON.stringify(user_to_delete),
        }
      );

      if (res.ok) {
        const json = await res.json();

        result = JSON.stringify(json);
        console.log(result);

        if (json.status == true) {
          addNotification({
            text: "Užívateľ bol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          fetchData();
        } else {
          addNotification({
            text: "Užívateľ nebol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        throw new Error(data);
      }
    }
  }

  async function updateUser($id) {
    if (users[$id] == "") {
      addNotification({
        text: "nebol zvolený užívateľ.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      const res = await fetch(
        userdata["base_path"] + "assets/php/updateConfiguratorUsers.php",
        {
          method: "POST",
          body: JSON.stringify(users[$id]),
        }
      );

      if (res.ok) {
        const json = await res.json();
        result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
            text: "Užívateľ bol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          fetchData();
        } else {
          addNotification({
            text: "Chyba : Užívateľ nebol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        addNotification({
          text: "Chyba : Užívateľ nebol aktualizovaný!",
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
        throw new Error(data);
      }
    }
  }

  let new_date;

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    new_date = yyyy + "-" + mm + "-" + dd;
  }

  async function setNewDate() {
    let date = {
      value: new_date,
    };

    if (new_date == "") {
      addNotification({
        text: "nebol zvolený dátum.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      const res = await fetch(
        userdata["base_path"] + "assets/php/setConfiguratorUserNewDate.php",
        {
          method: "POST",
          body: JSON.stringify(date),
        }
      );

      if (res.ok) {
        const json = await res.json();

        result = JSON.stringify(json);
        console.log(result);

        if (json.status == true) {
          addNotification({
            text: "Užívateľom bol nastavený nový deadline!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          fetchData();
        } else {
          addNotification({
            text: "Chyba : Užívateľom nebol nastavený nový deadline!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        throw new Error(data);
      }
    }
  }
  getDate();
  fetchData();
</script>

<div id="wrapper">
  <Aside />

  <main in:fade class="configurator">
    <h1>{$t("admin")["aside"]["configurator"]["users"]}</h1>

    <div class="all-users">
      <div
        class="add-user"
        on:click={() => navigate("/add-configurator-user", { replace: true })}
      >
        <i class="fa-solid fa-user-plus" />
        <p>{$t("admin")["aside"]["configurator"]["add_user"]}</p>
      </div>
      <div class="set_date">
        <label for="new_date"
          >{$t("admin")["configurator"]["users"]["new_date_label"]}</label
        >
        <input id="new_date" type="date" bind:value={new_date} />

        <button on:click={() => setNewDate()}
          >{$t("admin")["configurator"]["users"]["new_date_button"]}</button
        >
      </div>

      {#if loading_users == false}
      <table>
        <thead>
          <tr>
            <td>{$t("admin")["global"]["id"]}</td>
            <td>{$t("admin")["global"]["username"]}</td>
            <td>{$t("admin")["global"]["password"]}</td>
            <td>{$t("admin")["configurator"]["users"]["flat_id"]}</td>
            <td>{$t("admin")["configurator"]["users"]["sample_flat"]}</td>
            <td>{$t("admin")["configurator"]["users"]["realpad_id"]}</td>
            <td>{$t("admin")["global"]["enabled"]}</td>
            <td>{$t("admin")["configurator"]["users"]["deadline"]}</td>
            <td>{$t("admin")["configurator"]["users"]["client_change"]}</td>
            <td>{$t("admin")["configurator"]["users"]["configuration"]}</td>
            <td>{$t("admin")["global"]["file"]}</td>
            <td>{$t("admin")["global"]["price"]}</td>
            <td>{$t("admin")["global"]["save"]}</td>
            <td>{$t("admin")["global"]["delete"]}</td>
          </tr>
        </thead>
        <tbody>
          {#each users as user, id}
            <tr>
              <td><p class="name">{user.id}</p></td>
              <td><p class="name">{user.username}</p></td>
              <td
                ><input
                  id="password"
                  type="text"
                  bind:value={user.password}
                /></td
              >
              <td>
                <select bind:value={user.flat_id}>
                  {#each flats as flat}
                    <option value={flat.name}>
                      {flat.name}
                    </option>
                  {/each}
                </select>
              </td>
              <td>
                <select bind:value={user.sample_flat_id}>
                  {#each sample_flats as flat}
                    <option value={flat.name}>
                      {flat.name}
                    </option>
                  {/each}
                <input id="flat_id" type="text" bind:value={user.sample_flat_id} /></td>
              <td
                ><input
                  id="realpad_id"
                  type="text"
                  bind:value={user.realpad_id}
                /></td
              >
              <td class="enable-td">
                <label
                  >{$t("admin")["global"]["yes"]}
                  <input
                    id="true"
                    type="radio"
                    bind:group={user.config_allowed}
                    value="1"
                  />
                </label>
                <label
                  >{$t("admin")["global"]["no"]}
                  <input
                    id="false"
                    type="radio"
                    bind:group={user.config_allowed}
                    value="0"
                  />
                </label>
              </td>
              <td
                ><input
                  id="deadline"
                  type="date"
                  bind:value={user.deadline}
                /></td
              >
              <td
                >{#if user.client_change == "1"}
                  <p>{$t("admin")["global"]["yes"]}</p>
                {:else}
                  <p>{$t("admin")["global"]["no"]}</p>
                {/if}
              </td>
              <td
                ><input
                  id="configuration"
                  type="textarea"
                  bind:value={user.configuration}
                /></td
              >

              <td>
                {#if user.file != null}
                  <a href={user.file} target="_blank">Link</a>
                {/if}
              </td>
              <td>
                {#if user.price != null}
                  <p>{user.price}</p>
                {/if}
              </td>
              <td on:click={() => updateUser(id)}
                ><i class="fa-solid fa-floppy-disk save" /></td
              >
              <td on:click={() => removeUser(id)}
                ><i class="fa-solid fa-trash-can delete" /></td
              >
            </tr>
          {/each}
        </tbody>
      </table>
      {:else}
      <div id="preloader">
        <div class="preloader">
        </div>
      </div>
      
      {/if}

    </div>
  </main>
  <Header />
</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";
  main {
    &.configurator {
      h1 {
        color: $text-color;
      }

      .all-users {
        margin: 0 auto;
        width: calc(100% - 64px);
        max-width: 1300px;

        .add-user {
          display: flex;
          background-color: $success;
          width: max-content;
          color: white;
          padding: 15px 20px;
          border-radius: 6px;
          margin: 20px auto;
          cursor: pointer;
          &:hover {
            background-color: darken($success, 10);
          }
          i {
            font-size: 16px;
          }
          p {
            margin-left: 10px;
          }
        }

        .user {
          display: flex;
          justify-content: space-between;
          padding: 25px 10px;
          border-top: 1px solid lighten($border-color, 7);
          .left {
            display: flex;
            align-items: center;
            .user-icon {
              width: 40px;
              height: 40px;
              background-color: $text-color;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              color: white;
            }
            .info {
              text-align: left;
              margin: 0 15px;
              .name {
                font-weight: 400;
              }
              .user-name {
                color: $text-color;
              }
            }
          }
          .right {
            display: flex;
            .role {
              display: flex;
              background-color: white;
              padding: 10px 20px;
              border: 1px solid $border-color;
              border-radius: 6px;
              span {
                margin-right: 3px;
              }
            }
            .edit,
            .delete {
              width: 40px;
              height: 40px;
              // background-color: tomato;
              margin-left: 10px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              color: $text-color;
              cursor: pointer;
            }
            .edit {
              &:hover {
                background-color: $info;
                color: white;
              }
            }
            .delete {
              &:hover {
                background-color: $danger;
                color: white;
              }
            }
          }
        }
      }
      table {
        thead {
          background: $text-color;
          inset-block-start: 0;
          color: $text-color-active;
        }
        td {
          padding: 10px;

          input {
            margin: 0;
            padding: 8px;
          }

          select {
            min-width: 60px;
          }
        }
      }
      table,
      td,
      th {
        border: 0px solid $text-color;
      }
      tbody {
        tr {
          &:nth-child(even) {
            background-color: lighten($text-color, 26);
          }
          &:nth-child(odd) {
            background-color: lighten($text-color, 29);
          }
          &:hover {
            background-color: lighten($text-color, 23);
          }
        }
        .enable-td {
          display: flex;
          gap: 10px;

          label {
            display: flex;
            flex-direction: column;
          }
        }
        .save,
        .delete {
          width: 35px;
          height: 35px;
          // background-color: tomato;
          margin-left: 10px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          color: $text-color;
          cursor: pointer;
        }
        .save {
          &:hover {
            background-color: $success;
            color: white;
          }
        }
        .delete {
          &:hover {
            background-color: $danger;
            color: white;
          }
        }
      }
    }
    .set_date {
      width: 14%;
      text-align: left;
      background-color: lighten($text-color, 26);
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 15px;

      label {
        font-weight: 500;
        color: $info;
      }

      button {
        margin: 0;
        background-color: $info;
        width: auto;
        &:hover {
          background-color: darken($info, 8);
        }
      }
    }
  }
</style>
