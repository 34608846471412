<script>
import { Link } from "svelte-routing";
import { token } from "../../stores.js";
import { t, locale, locales } from "../../i18n.js";
import { fade } from "svelte/transition";
import Aside from "../../layouts/_aside.svelte";
import Header from "../../layouts/_header.svelte";
import { navigate } from "svelte-routing";

import { product_standard_id } from "../../store.js";

let _product_standard_id;
product_standard_id.subscribe((value) => {
		_product_standard_id = value;
});

import { getNotificationsContext } from 'svelte-notifications';
const { addNotification } = getNotificationsContext();

export let userdata;
let loading_data = true;
let enable_standards, standards_types_loaded, product_categories_loaded, mj_loaded, products_loaded = false;
let products, standards_types, product_categories = [];

let order_index = 0;

// Načítanie dát z DB
async function getData($id, $target, $var, $par) {
    loading_data = true;
    let data = {
        id: $id
    }
    const res = await fetch(
        userdata['base_path'] + "assets/php/getData.php", {
            method: "POST",
            body: JSON.stringify(data),
        }
    );

    if (res.ok) {
        let json = await res.json();

        // prevod hodnoty boolean
        if (
            typeof json === 'object'

            // Object.keys(json).length === 0
        ) {
            const keys = Object.keys(json[0]);
            
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                
                if (
                    (key == "enabled" && json[i][key] == "0")
                ) {
                    json[i][key] = false
                }

                if (
                    (key == "enabled" && json[i][key] == "1")
                ) {
                    json[i][key] = true
                }
            }

            console.log(json);

            switch ($var) {

                case "standards_types" : 
                standards_types = json;
                standards_types_loaded = true;

                standards_types.forEach(element => {
                    element.standard_order = parseInt(element.standard_order);
                  });

                break;
            }

            loading_data = false;
        } else {
            console.log("žiadne dáta");
        }

    } else {
        throw new Error(data);
    }
}

getData("standards_types", standards_types, "standards_types");  

let new_date;

let result;

let timeOut = 300;
let status = "";


let product_exist = false;

async function update_standard() {
  let data = null;
  
  standards_types.forEach(element => {
    if (element.id == _product_standard_id) {
      data = element;
    }
  });

  console.log(data);

   if (
        data.id != '' &&
        data.name != ''
    ) {
      const res = await fetch(userdata['base_path'] + 'assets/php/modifyConfiguratorStandard.php', {
          method: "POST",
          body: JSON.stringify(data),
      })

        if (res.ok) {
            const json = await res.json()
            result = JSON.stringify(json);

            if (json.status == true) {
              product_exist = false;
                addNotification({
                    text: 'Štandard bol aktualizovaný.',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'success'
                });

                navigate("product-standards");
                product_standard_id.update(n => null);
                

            } else if (json.status == false) {
                product_exist = true;
                addNotification({
                    text: 'ID štandardu neexistuje!',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'danger'
                })
            }

        } else {
            throw new Error(data);
        }

    } else {
        addNotification({
            text: 'Vyplnťe všetky povinné polia!',
            position: 'top-center',
            removeAfter: 4000,
            type: 'danger'
        })
    }
}

</script>

<div id="wrapper">
    <Aside />
    <main in:fade>
        <h1 on:click={() => sort()}>{$t("admin")["configurator"]["standards"]["edit_standard"]} (id:{_product_standard_id})</h1>
        {#if standards_types_loaded }
          {#each standards_types as item}
            {#if item.id == _product_standard_id}            
            <div class="row right">
              <!-- button uložiť produkt -->
              <button on:click="{() => update_standard()}"><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["update"]}</button>
              <!-- button zrušiť -->
              <button class="cancel" on:click="{() => navigate("product-standards")}"><i class="fa-solid fa-ban"></i>{$t("admin")["global"]["cancel"]}</button>
              
            </div>  

            <div class="row">
              <!-- Názov produktu  -->
              <div class="component col4">
                  <label class="full" for="name">{$t("admin")["global"]["name"]}
                    <input type="text" bind:value="{item.name}" name="name">
                  </label>
              </div>

              <!-- MJ produktu  -->
              <div class="component col4">
                <label class="full">{$t("admin")["global"]["order"]}
                  <input type="number" bind:value="{item.standard_order}">
                </label>
              </div>
            </div>

            {/if}
          {/each}
        {/if}       
    </main>
    <Header />

</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";

  .component {
    &.comp-standard {
      label {  
        display: flex;
        justify-content: space-between;
        align-items: center;   
        gap: 6px; 
        margin: 6px 0 0;
      }
    }

    label, input, select {      
      margin: 6px 0 0;
      
      &.danger {
        border: 1px solid red;
      }
    }

    label {
      &.variant-copy {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    button {
      &.add_variant {
        background-color: $warning;
        color: $text-color-active;
      }
    }

    &.block {
      background-color: lighten($text-color, 28);
    }
  }

  .col4 {
    width: 25%;
    max-width: 25%;
    
    label {
      &.full {
      width: 100%;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    max-width: 960px;
    margin: 0 auto;

    &.right {
      justify-content: flex-end;
    }
  }
</style>
