<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";

    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
    

    
    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    let rotator_data;
    let data_loaded = false;

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") || (key == "filter" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
              key == "enabled" && json[0][key] == "1" || key == "filter" && json[0][key] == "1"
          ) {
              json[0][key] = true
          }
        }

        rotator_data = json[0];
        
        data_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("rotator");

    async function save_settings() {
      console.log(rotator_data);
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(rotator_data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateRotator.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        console.log(json);
        let result = JSON.stringify(json);


        if (json.status == true) {
          addNotification({
                text: "Nastavenia rotátora boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia rotátora neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


</script>

<div id="wrapper">
    <Aside />

    {#if data_loaded}
    <main in:fade>

        <h1>Rotátor</h1>

        <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button>

        <!-- Orbit -->
        <div class="component">
          <label for="url">{$t("components")["otacadlo"]["orbit"]}</label>
          <input type="text" name="url" bind:value={rotator_data.orbit}>
        </div>  

        <!-- Orbit -->
        <div class="component">
            <label for="url">{$t("components")["otacadlo"]["buildings"]}</label>
            <input type="text" name="url" bind:value={rotator_data.buildings}>
          </div>  

          <!-- Orbit -->
        <div class="component">
            <label for="url">{$t("components")["otacadlo"]["floors"]}</label>
            <input type="text" name="url" bind:value={rotator_data.floors}>
          </div>  

          <!-- Orbit -->
        <div class="component">
            <label for="url">{$t("components")["otacadlo"]["playground"]}</label>
            <input type="text" name="url" bind:value={rotator_data.playground}>
          </div>  

    </main>
    <Header />
    {/if}
    
  </div>