<script>
    import { Link } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
  
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
  import { getNotificationsContext } from 'svelte-notifications';
  const { addNotification } = getNotificationsContext();
  
  export let userdata;

  let new_date;

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    new_date = yyyy +'-'+ mm +'-'+ dd;
    }

    getDate();
  
      let result;

      let new_flat = {
          name : ''
      };
  
      let timeOut = 300;
      
      let status = "";
  
      async function create_user() {
  
          if (
              new_flat.name != ''
          ) {
            console.log(new_flat);
              const res = await fetch( userdata['base_path'] + 'assets/php/createConfiguratorSampleFlat.php', {
                  method: "POST",
                  body: JSON.stringify(new_flat),
              })
  
              if (res.ok) {
                  const json = await res.json()
                  result = JSON.stringify(json);
  
                  if (json.status == true) {
  
                      addNotification({
                          text: 'Byt bol pridaný.',
                          position: 'top-center',
                          removeAfter: 4000,
                          type: 'success'
                      })
  
                      
                  }
  
                  else {
                      
                      addNotification({
                          text: 'Byt nebol pridaný !',
                          position: 'top-center',
                          removeAfter: 4000,
                          type: 'danger'
                      })
                  }
                  
              } else {
                  throw new Error(data);
              }
  
          }
  
          else {
              addNotification({
                      text: 'Vyplnťe všetky povinné polia!',
                      position: 'top-center',
                      removeAfter: 4000,
                      type: 'danger'
                  })
          }
    }
  </script>
  
  <div id="wrapper">
    <Aside />
    
    <main in:fade>
      <h1>{$t("admin")["aside"]["projects"]["add_project"]}</h1>
  
          <!-- Používateľské meno  -->
          <div class="component">
            <label for="name">{$t("admin")["global"]["name"]}*</label>
            <input type="text" bind:value="{new_flat.name}" name="name">
          </div>

          <!-- Button -->
          <div class="component">
            <button on:click="{() => create_user()}">{$t("admin")["projects"]["create_project"]}</button>
          </div>
    </main>
    <Header />
  
    </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";
  
  
  </style>
  