<script>
    import { onMount } from 'svelte';
	import { t, locale, locales } from "../i18n.js";
    import { fade } from "svelte/transition";
	import { each } from 'svelte/internal';
	import {get, writable} from 'svelte/store';
	import {loaded_krpano, tour_select, flat_id, modal, modal_type, active_scene, tour_settings, sample_flats, shortcuts_settings, current_flat, active_shortcut_tag, attributes, active_product_id} from "../store.js";
	import { configurator_enabled } from '../store.js';
	import { vr_mode } from '../store.js';
	import { device_type } from '../store.js';
	import { gyro_mode } from '../store.js';
	import { gyro_available } from '../store.js';
	import { floor, dizajn} from '../store.js';
	import { all_scenes} from '../store.js';
	import { config_slug } from '../store.js';
	import { contact_form_enabled } from '../store.js';



	let _contact_form_enabled = false;

		contact_form_enabled.subscribe((value) => {
		_contact_form_enabled = value;
	
	});

	import { blur, configuration, config_products, config_product_variants, config_product_categories, config_product_measures_units, basket_categories, reset_configurator} from '../store.js';

	import { import_config_hotspots, all_hotspots } from '../store.js';
	import { getNotificationsContext } from "svelte-notifications";
  	const { addNotification } = getNotificationsContext();

	export let userdata
	export let settings;

	let krpano = null;
	let scenes, hotspots, floorplans, floorplan_pins= [];
	let project_settings, share_settings, hotspots_data, google_map, _sample_flats, _shortcuts_settings, _floorplans, _floorplan_pins, contact_form, flat_filter = null;
	let scenes_loaded = false;
	let flat_filter_loaded = false;
	let current_scene, current_scene_shortcut_tag;

	let layers = [];
	let layers_data_loaded = false;

	let _config_product_variants, _config_products = [];

	let preloader = true;

	let basket_enabled = false;


	// resetovanie konfigurátora
	reset_configurator.subscribe((value) => {
		if (value == true) {
			loadConfiguration("reset");
		}
		
		reset_configurator.update(n => false);
	});


	// Je povolený konfigurátor ? 
	let _configurator_enabled = null;
	configurator_enabled.subscribe((value) => {
		_configurator_enabled = value;
		basket_enabled = true;
	});

	// zistenie parametrov z URL
	let _flat_id = null;
	flat_id.subscribe((value) => {
		_flat_id = value;
	
	});

	let _import_config_hotspots = false;
	import_config_hotspots.subscribe((value) => {
		_import_config_hotspots = value;
	});

	// zisťovanie aktívnej scény
	active_scene.subscribe((value) => {
		current_scene = value;
		
		
	});

	$: {
		current_scene;

		import_hotspots();
	}

	// zisťovanie aktuálneho bytu
	let _current_flat = null;
	current_flat.subscribe((value) => {
		_current_flat = value;
		
	});


	$: {
		project_settings;
		if (
			project_settings != null
		) {
			const url_parameters = new URLSearchParams(window.location.search);
			let project_suffix = project_settings[2].value;

			if (url_parameters.has(project_suffix)) {
				flat_id.update( n => url_parameters.get(project_suffix));
				loadConfiguration();
				localStorage.setItem('show_intro', false);
					if ( url_parameters.has('allow_config') ) {
						allow_config = url_parameters.get('allow_config');
					}
			}
		}
	}

	let _configuration = {};
	configuration.subscribe((value) => {
	});

	async function loadConfiguration(parameter) {
		let data = {
			user_id : _flat_id,
			reset : parameter
		};

	

		const res = await fetch(
			userdata['base_path'] + "assets/php/loadData.php", {
				method: "POST",
				body: JSON.stringify(data),
			})

		if (res.ok) {
			const json = await res.json(); 
			_configuration = json.configuration;
			configuration.update(n => json.products);
			

			config_slug.update( n=> json.config_slug);
			

			if (json.status == true) {
				addNotification({
					text: 'Konfigurácia bola načítaná!.',
					position: 'top-center',
					removeAfter: 4000,
					type: 'success'
				});
				let products, products_variants, product_categories, product_measures_units = null;

				// načítanie produktov z DB
				getData("products", products, "products");
				// načítanie produktových variantov z DB
				getData("products_variants", products_variants, "products_variants");
				// načítanie produktových kategórií z DB
				getData("product_categories", product_categories, "product_categories");
				// načítanie merných jednotiek
				getData("measures_units", product_measures_units, "measures_units");

				// načítanie cien 
				

				if (json.basket_categories != null) {
					basket_categories.update(n => json.basket_categories);
				}
			
			} else {
			addNotification({
				text: 'Chyba : Konfigurácia nebola načítaná!.',
				position: 'top-center',
				removeAfter: 4000,
				type: 'danger'
			});
			}
		} else {
			throw new Error(data);
		}
	}


	let shortcuts_settings_loaded = false;

	shortcuts_settings.subscribe((value) => {
		_shortcuts_settings = value;
	});	

	// Načítanie dát z DB
	async function getData($id, $target, $var) {
      let data = {id : $id} 
      const res = await fetch(
        userdata['base_path'] + "assets/php/getData.php",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (res.ok) {
        const json = await res.json();    

		
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") || 
			  (key == "enable_facebook" && json[0][key] == "0") || 
			  (key == "enable_twitter" && json[0][key] == "0") || 
			  (key == "enable_linkedin" && json[0][key] == "0") || 
			  (key == "filter" && json[0][key] == "0") 
          ) {
              json[0][key] = false
          }

          if (
            (key == "enabled" && json[0][key] == "1") || (key == "enable_facebook" && json[0][key] == "1") || (key == "enable_twitter" && json[0][key] == "1") || (key == "enable_linkedin" && json[0][key] == "1" || key == "filter" && json[0][key] == "1")
          ) {
              json[0][key] = true
          }
          
        }

		switch ($var) {
			case "google_map" :
			google_map = json[0];
			break;

			case "contact_form" :
			contact_form = json[0];
			break;

			case "flat_filter" :
			flat_filter = json[0];
			flat_filter_loaded = true;
			break;

			case "layers" :
			layers = json;
			layers_data_loaded = true;
			break;

			case "shortcuts" : 
				shortcuts_settings.update(n => json[0]);
				shortcuts_settings_loaded = true;
			break;

			case "floorplans" : 
				floorplans = json;
			break;

			case "floorplan_pins" : 
				floorplan_pins = json;
			break;

			case "products" : 
				json.forEach(element => {
					const keys = Object.keys(element);
					for (let i = 0; i < keys.length; i++) {
					const key = keys[i];
					if (
						(key == "walls" && element[key] == "0") || (key == "bind_standard" && element[key] == "0") 
					) {
						element[key] = false
					}

					if (
						(key == "walls" && element[key] == "1") || (key == "bind_standard" && element[key] == "1") 
					) {
						element[key] = true
					}

					if (
						(key == "product_order") 
					) {
						element[key] = parseInt(element[key]);
					}
					
					}  
				});

				config_products.update(n => json);
				_config_products = json;
				
			break;

			case "product_categories" : 
				config_product_categories.update(n => json);
			break;

			case "products_variants" : 
				config_product_variants.update(n => json);
				_config_product_variants = json;
			break;

			case "measures_units" : 
              config_product_measures_units.update(n => json);
            break;

			
		}
        
		if ($var == "scenes" ) {
			scenes = json;
			all_scenes.update(n => json);
		
			scenes_loaded = true;
			add_krpano_scenes();
		}

		if ($var == "hotspot" ) {
			hotspots = json;
			all_hotspots.update(n => json);
			import_hotspots();
		}

		if ($var == "project_settings" ) {
			project_settings = json;

			getData("sample_flats", sample_flats, "sample_flats");
		
		}

		if ($var == "sample_flats" ) {
			sample_flats.update(n => json);
			
			getData("sample_flats_scenes", scenes, "scenes");
		}

		if ($var == "tour_settings" ) {
			tour_settings.update(n => json[0]);
		}

		if ($var == "share_settings" ) {
			share_settings = json[0];
		}
        
        return json;
      } else {
        throw new Error(data);
      }
  	}

	getData("shortcuts", shortcuts_settings, "shortcuts");

	let _tour_settings = null;
	tour_settings.subscribe((value) => {
		_tour_settings = value;
	});

	sample_flats.subscribe((value) => {
		_sample_flats = value;
	
	});

	let krpano_loaded = false;

	loaded_krpano.subscribe((value) => {
		krpano_loaded = value;
		blur.update(n => false);

	});

	// načítanie dát pre komponent nastavenia
	getData("tour_settings", _tour_settings, "tour_settings");
	
	// načítanie dát pre komponent share
	getData("share", share_settings, "share_settings");

	// načítanie dát pre kompoennt google map
	getData("googlemap", google_map, "google_map");

	// načítanie dát pre komponent kontaktný formulár
	getData("contact_form", contact_form, "contact_form");

	getData("flat_filter", flat_filter, "flat_filter");

	getData("layers", layers, "layers");

	// načítanie dát pre floorplan
	getData("floorplans", _floorplans, "floorplans");  

	// načítanie pinov pre floorplan
	getData("floorplan_pins", _floorplan_pins, "floorplan_pins");  

	// Pridanie všetkých scén do Krpana
	function add_krpano_scenes() {
		if (krpano == null) {
            krpano = document.getElementById("krpanoSWFObject");
        }
		
		scenes.forEach(element => {

			
			if (
			element['enable_design'] == false && element['enable_floors'] == false
			) {
			krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["base_path"] +"assets/" + project_settings[3].value +"/" + element['name'] + "_%s.jpg'/></image>");
			}

			if (
			element['enable_design'] == true && element['enable_floors'] == false
			) {
			
			krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["base_path"] + "assets/" + project_settings[3].value + "/" + element['name'] + "_%$dizajn%" +  "_%s.jpg'/></image>");
			}

			if (
			element['enable_design'] == false && element['enable_floors'] == true
			) {
			
			krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["base_path"] + "assets/" + project_settings[3].value + "/" + element['name'] + "_%$floors%f" +  "_%s.jpg'/></image>");
			}

			if (
			element['enable_design'] == true && element['enable_floors'] == true
			) {
			
			krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["base_path"] + "assets/" + project_settings[3].value + "/" + element['name'] + "_%$dizajn%_%$floors%f" +  "_%s.jpg'/></image>");
			}
			
		
		});

		load_first_scene();
    	
		//getData("project_settings", project_settings, "project_settings");

    }

	// importovanie hotspotov
	function import_hotspots() {
		
      let action = 'draghotspot();';
      let bigger = 'bigger();';
      let normal = 'normal();';

	  let BLENDMODE = "ZOOMBLEND(2.0, 2.0, easeInOutSine)";
      if (
        hotspots != null && current_scene != null
      ) {
        if (krpano == null) {
          krpano = document.getElementById("krpanoSWFObject");

        }
        for (let index = 0; index < hotspots.length; index++) {
          if (
            hotspots[index].scene_id.toLowerCase() == current_scene.toLowerCase() && _import_config_hotspots == false
            
          ) {

			
            
            krpano.call("addhotspot(hts_" + hotspots[index].id + ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].keep, false);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ath," +hotspots[index].ath+ ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].atv," +hotspots[index].atv+ ");");

			
            // krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ondown, " + action + ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].distorted, false);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].rx, 0);");
			krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ry, 0);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].alpha, 0.4);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].zorder, 2);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, auto);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].height, prop);");
			krpano.call("set(hotspot[hts_" + hotspots[index].id + "].capture, false);");
            
            switch (hotspots[index].skin) {
              case 'arrow' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/arrow-floor.png);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width," + hotspots[index].width + "px);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].height, prop);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].rx," + hotspots[index].rx + ");");
				krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ry," + hotspots[index].ry + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].distorted, true);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].atv, 30);");
                

                // // tieň
                // krpano.call("addhotspot(hts_0_" + hotspots[index].id + ");");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].keep, false);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].ath," +hotspots[index].ath+ ");");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].url, assets/krpano/icons/arrow-floor-dark.png);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].width," + hotspots[index].width + "px);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].height, prop);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].rx," + hotspots[index].rx + ");");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].distorted, true);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].atv, 30.6);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].alpha, 0.4);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].zorder, 1);");
                
              break;

              case 'floorspot' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts-ring-w.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width," + hotspots[index].width + "px);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].height, prop);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].rx," + hotspots[index].rx + ");");
				krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ry," + hotspots[index].ry + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].distorted, true);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].atv," + hotspots[index].atv + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].zorder, 2);");
              break;

              case 'info' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_infopanel.svg);");
              break;

              case 'video' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_videopanel.svg);");
              break;

              case 'tag' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_tag.svg);");
              break;

              case 'attribute-podlaha' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_podlaha.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-lista' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_lista.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-sanita' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_sanita.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-sanita2' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_sanita2.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-obklad' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_obklad.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-obklad2' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_obklad2.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-dlazba' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_dlazba.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-dvere' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_dvere.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;
            }

			krpano.set("hotspot[hts_"+hotspots[index].id+"].onover", function(hs) {
				krpano.call("tween(hotspot[hts_" + hotspots[index].id + "].alpha, 1.0);");
			});

			krpano.set("hotspot[hts_"+hotspots[index].id+"].onout", function(hs) {
				krpano.call("tween(hotspot[hts_" + hotspots[index].id + "].alpha, 0.4);");
			});

			switch (hotspots[index].type) {
				case "next-node" : 
					krpano.set("hotspot[hts_"+hotspots[index].id+"].onclick", function(hs) {
						
						if (
							hotspots[index].target_scene_id != null ||
							hotspots[index].target_scene_id != ""
						) {
							scenes.forEach(element => {
								if (
									element.id == hotspots[index].target_scene_id

								) {
									krpano.call("loadscene(" + element.name + ", null, MERGE, " + project_settings[6].value + ")");
									active_scene.update(n => element.name);
								}
							});
						}
						
						
					});

					krpano.set("hotspot[hts_"+hotspots[index].id+"].onover", function(hs) {
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts-ring-active.svg);");
						krpano.call("tween(hotspot[hts_" + hotspots[index].id + "].alpha, 1.0);");
					});

					krpano.set("hotspot[hts_"+hotspots[index].id+"].onout", function(hs) {
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts-ring-w.svg);");
						krpano.call("tween(hotspot[hts_" + hotspots[index].id + "].alpha, 0.4);");
					});
				break;

				case "info" : 
					// modal_type_tmp = hotspots[index].version;
					krpano.set("hotspot[hts_"+hotspots[index].id+"].onclick", function(hs) {
						hotspots_data = {
							title : hotspots[index].content_title,
							img: hotspots[index].content_img,
							headline : hotspots[index].content_headline,
							subtitle : hotspots[index].content_subtitle,
							text : hotspots[index].content_text
						}

						modal_type_tmp = hotspots[index].version;
						modal.update(n => true);
					});
					
				break;

				case "tag" : 

					let hts_array = ["tag_close", "tag_title", "tag_img", "tag_mask", "tag_price", "tag_content", "tag_link"];

					
					krpano.set("hotspot[hts_"+hotspots[index].id+"].onclick", function(hs) {

						// pozadie hotspotu
						krpano.call("addhotspot(hts_" + hotspots[index].id + "_tag_wrapper);");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].type, text );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].width, 280px );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].height, 454px );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].bgcolor, 0xFFFFFF );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].bgalpha, 0.9 );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].bgroundedge, 12 );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].zorder, 10 );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].ath, " + hotspots[index].ath + " );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].atv, " + hotspots[index].atv + " );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].ox, " + 30 + " );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].oy, " + -20 + " );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].edge, lefttop );");
						krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].handcursor, false );");
						// krpano.call("set(hotspot[hts_" + hotspots[index].id + "_tag_wrapper].maskchildren, true );");

						// Close button
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_close);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].type, img);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].width, 32px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].height, 32px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].url, img/close.svg );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].zorder, 11 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].oy, 8px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_close].ox, 240px );");;

						// Názov hotspotu
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_title);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].type, text );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].width, 280px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].html, " + hotspots[index].tag_title + " );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].bgalpha, 0 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].oy, 240px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].ox, 16px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].css, color:0x3F3F46; text-align: left; font-size: 16px; font-weight: 600;);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_title].handcursor, false );");

						// Cena 
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_price);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].type, text );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].html, " + hotspots[index].tag_price + "  €);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].bgalpha, 0 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].oy, 240px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].ox, 264px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].css, color:0x3F3F46; text-align: right; font-size: 14px;);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].edge, righttop );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_price].handcursor, false );");
						
						// Čiara 
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_line);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].type, text );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].width, 248 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].height, 1px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].bgalpha, 1 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].bgcolor, 0x3F3F46 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].oy, 268px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].ox, 16px );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].alpha, 0.1 );")
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_line].handcursor, false );");
						
						

						// Maska pre obrázok
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_mask);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].type, text);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].width, 264px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].height, 220px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].bgcolor, 0xffffff );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].bgroundedge, 6 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].ox, 8px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].oy, 8px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].maskchildren, true );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_mask].handcursor, false );");
						

						// Obrázok
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_img);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].type, img);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].parent, layer[hts_" + hotspots[index].id + "_tag_mask] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].width, prop );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].height, 220px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].bgroundedge, 6 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].url, " + userdata['base_path'] + "assets/" + hotspots[index].tag_img + " );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].edge, center );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].oy, 110 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].ox, 132 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_img].handcursor, false );");

						// Obsah
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_content);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].type, text );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].width, 248px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].height, 115px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].html, " + hotspots[index].tag_content + " );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].bgalpha, 0 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].ox, 16px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].oy, 280px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].css, color:0x3F3F46; text-align: left; font-size: 14px; cursor: default;);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_content].handcursor, false);");


						// Link
						krpano.call("addlayer(hts_" + hotspots[index].id + "_tag_link);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].type, text );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].parent, hotspot[hts_" + hotspots[index].id + "_tag_wrapper] );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].width, 248px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].height, 32px );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].ox, 140 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].oy, 438 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].edge, bottom );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].html, Zobraziť viac );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].bgcolor, 0x3F3F46 );"); 
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].css, color:0xFFFFFF; text-align: center; padding-top: 6px);");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].bgalpha, 1.0 );");
						krpano.call("set(layer[hts_" + hotspots[index].id + "_tag_link].bgroundedge, 6 );");
						
						

						// zatvorenie hotspotu TAG
						krpano.set("layer[hts_"+ hotspots[index].id + "_tag_close].onclick", function(hs) {
							krpano.call("removehotspot(hts_" + hotspots[index].id + "_tag_wrapper, true);");
							hts_array.forEach(element => {
								krpano.call("removelayer(hts_" + hotspots[index].id + "_" + element + ");");
							});
						});

						// Otvorenie odkazu
						krpano.set("layer[hts_"+ hotspots[index].id + "_tag_link].onclick", function(hs) {
							window.open(hotspots[index].tag_link, '_blank');
						});

					});

				break;
			}

            // krpano.set("hotspot[hts_"+hotspots[index].id+"].onout", function(hs) {
            //   hotspots[index].ath = Number(krpano.get("hotspot[hts_"+hotspots[index].id+"].ath"));
            //   hotspots[index].atv = Number(krpano.get("hotspot[hts_"+hotspots[index].id+"].atv"));
            //   //console.log(hotspots[index].ath);
            //   //console.log(hotspots[index].atv);
            // });

          }
          
		  if (
			hotspots[index].scene_id.toLowerCase() == current_scene.toLowerCase()
		  ) {
			switch (hotspots[index].type) {
				case "attribute" : 
					krpano.set("hotspot[hts_"+hotspots[index].id+"].onclick", function(hs) {
						active_product_id.update(n => hotspots[index].product_id);
						attributes.update(n => true);
					});

					_config_products.forEach(product => {
						if (parseInt(hotspots[index].product_id) == parseInt(product.id)) {
							
							
							let positions = ["f","l","r","u","d","b"];

							positions.forEach(side => {
								krpano.call("addhotspot(hts_" + product.patch_id + "_" + current_scene + "_" + side + ");");	
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].url, assets/patches/" + current_scene + "_" + product.patch_id +"_%$" + product.patch_id +"%_" + side + ".png);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].keep, false);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].distorted, true);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].width, 1000);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].height, 1000);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].torigin, image);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].enabled, false);");
								krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].cursor, pointer);");

								switch (side) {
									// front
									case "f":
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].ath, 0);");
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].atv, 0);");
										break;
									
										// left
									case "l" :
										
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].ath, -90);");
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].atv, 0);");
										break;
									
									// right
									case "r" : 
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].ath, +90);");
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].atv, 0);");
										break;
									
									// top
									case "u" : 
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].ath, 0);");
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].atv, -90);");
										break;
									
									// down
									case "d" : 
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].ath, 0);");
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].atv, +90);");
										break;

									// back
									case "b" : 
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].ath, 180);");
										krpano.call("set(hotspot[hts_" + product.patch_id + "_" + current_scene + "_" + side + "].atv, 0);");
										break;
								
									default:
										break;
								}
							});
						}
					});					


				break;
			}
		  }
        }        
        
      }

	  
	  	// PATCHE 


	  	// front

	  	

	  preloader = false;
    }

	// načítanie prvej scény
	function load_first_scene() {
		if (krpano == null) {
            krpano = document.getElementById("krpanoSWFObject");
        }

		function find_scene() {
			// ak má projekt nastavenú globálnu štartovaciu scénu
			if (
				project_settings[5].name == "project_start_scene" &&
				(project_settings[5].enabled == true || project_settings[5].enabled == "1") &&
				(project_settings[5].value != null && project_settings[5].value != "")
			) {
				
				let no_flats = true;
				scenes.every(element => {
					if (element.id == parseInt(project_settings[5].value)) {
						
						krpano.call("loadscene(" + element['name']+ ", null, MERGE)");
						krpano.call("lookto(" + element.hlookat + "," + element.vlookat +")");	
						current_scene = element['name'];						
						active_scene.update(n => element.name);
						no_flats = false;
						return false;
					}

					else {
						return true;
						
					}
				});

				if (no_flats == true) {
					krpano.call("loadscene(" + scenes[0]['name']+ ", null, MERGE)");
					krpano.call("lookto(" + scenes[0].hlookat + "," + scenes[0].vlookat +")");	
					current_scene = scenes[0]['name'];
				}

				// ak projekt nemá povolenú globálnu štartovaciu scénu
			} else {
				krpano.call("loadscene(" + scenes[0]['name']+ ", null, MERGE)");
				krpano.call("lookto(" + scenes[0].hlookat + "," + scenes[0].vlookat +")");	
				current_scene = scenes[0]['name'];
			}
		}


		if (project_settings != null && scenes != null) {
			
			switch (_flat_id) {
				// ak nie je v url parametri zadaný byt
				case null:
					find_scene();
					break;
			
				// ak je v url parametri byt
				default:
					if (_configurator_enabled && _configuration.user_id != null && _configuration.scene_id != null) {
						
						krpano.call("loadscene(" + _configuration.scene_id+ ", null, MERGE)");
						krpano.call("lookto(" + parseInt(_configuration.hlookat) + "," + parseInt(_configuration.vlookat) +")");	
						current_scene = _configuration.scene_id;
						let no_flats = false;
					} else {
						
						let no_flats = true;
					_sample_flats.forEach(element => {

						// ak je niektorý sample flats zhodný s ID bytu z URL
						if (element.name == _flat_id) {
							let start_scene_id = element.start_scene;

							
							// inicializovanie premenných floor a dizajn ( načítanie z DB )
							dizajn.update(n => element.default_dizajn);
							krpano.set("dizajn", element.default_dizajn);
							floor.update(n => element.default_dizajn);
							krpano.set("floor", element.default_floor);
							

							// ak nie je na byte zadaná štartovacia scéna
							if (start_scene_id == null || start_scene_id == "" ) {
								
								scenes.every(scene => {
									if (scene.sample_flat_id == _flat_id) {
										current_scene = scene['name'];
										krpano.call("loadscene(" + scene['name']+ ", null, MERGE)");
										krpano.call("lookto(" + scene.hlookat + "," + scene.vlookat +")");	
										
										no_flats = false;
										return false;
									}

									return true;
								});
							} else {							
								// ak má byt štartovaciu scénu
								
								scenes.every(scene => {
									if (scene.id == parseInt(start_scene_id)) {
										current_scene = scene['name'];
										
										//krpano.call("loadscene(" + scene['name']+ ", null, MERGE)");
										krpano.call("loadscene(" + scene["name"] + ", null, KEEPVIEW, BLEND(1));");
										krpano.call("lookto(" + scene.hlookat + "," + scene.vlookat +")");	
										
										no_flats = false;
										return false;
									}
									else {
										return true;
									}
								});
							}
							
							
						}
					});

					if (
						no_flats == true
					) {
						find_scene();
					}
					}

				break;
			}

		} else {
			find_scene();
		}

		getData("hotspots", hotspots, "hotspot");

		scenes.forEach(element => {
			if (element.name == current_scene) {
				current_flat.update(n => element.sample_flat_id);
				current_scene_shortcut_tag = element.shortcut_tag;
			}
		});
    }

	let vr_type = '';
	let project_setting;

	// kontrola URL adresy
	let allow_config = false;
	let flat = null;

	flat_id.subscribe((value) => {
		flat = value;
	});	

	let _floor = 1;
	floor.subscribe((value) => {
		_floor = value;
	});

	function webvr_state() {
		let krpano = document.getElementById("krpanoSWFObject")
		//vr_mode = krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
		// 0=off, 1=fake, 2=mobile, 3=true webvr (oculus go, rift, etc)	
		vr_type = krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? krpano.webvr.isvrbrowser ? 3 : 1 : 2 : 0 : 14;
		// device_type.update(n => krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? krpano.webvr.isvrbrowser ? 3 : 1 : 2 : 0 : 14);
		// return krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
	}

	const initializeRemarkable = () => {
		// fetchData(test);
		load_krpano();
	}

	

	// const components = [Logo, Shortcuts,Sidebar,Sounds,Floorplan];
	// const components_array = ["Logo", "Shortcuts","Sidebar","Sounds","Floorplan"];

	// Načítanie krpana
  	function load_krpano () {
		embedpano({
			xml: userdata['base_path'] + "assets/krpano/pano.xml",
			target:"pano",
			html5:"only+webgl",
			webglsettings:{preserveDrawingBuffer:true},
			mobilescale:1.0,
			initvars : {
				scene_name : "tmp",
				show_hs : true,
				"dizajn" : "zar",
      			"floors" : 1,
				podlaha : 4,
				dvere : 3,
				dlazba : 3,
				kovanie : 2,
				tlacidlo : 2,
				umyvadlo : 2,
				allow_saving : false,
				generating_screenshot : false,
				screenshot_data : null
			},
			hooks: {
            loadSceneData(scene) {
            },
            sceneChanged(scene) {
            }
        },
			onready: function (krpano) {
				loaded_krpano.update(n => true); 
				blur.update(n => false);
				let loaded_times = 0;
				krpano = krpano.get("global");

				getData("project_settings", project_settings, "project_settings");
				////console.log(settings.configuration.start_scene);
				//krpano.call("loadscene(" + settings.configuration.start_scene + ")");
				webvr_state();
				let data = [];

				krpano.events.onnewpano = function() {
					current_scene = krpano.get("xml.scene").toUpperCase();
					// zmena aktívneho bytu
					scenes.forEach(element => {
						if (element.name == current_scene) {
							current_flat.update(n => element.sample_flat_id);
							current_scene_shortcut_tag = element.shortcut_tag;
							active_shortcut_tag.update(n => element['shortcut_tag']);
							
						}
					});
					active_scene.update(n => current_scene);

					

					if (
						get(vr_mode) == true
					) {
						//console.log(get(active_scene).toLowerCase());
						var hs = krpano.get("hotspot[info]");
						var hts_count = krpano.get("hotspot.count");
						//console.log(hts_count);

					for (let index = 0; index < hts_count; index++) {

						var hts_name = krpano.get("hotspot[" + index +"].name");
						
						if (
							krpano.get("hotspot[" + index +"].name").includes("video")) {
							// krpano.set("hotspot[" + index +"].alpha", "0.0");
							// krpano.set("hotspot[" + index +"].visible", "false");
						}

						if (
							krpano.get("hotspot[" + index +"].name").includes("info") || krpano.get("hotspot[" + index +"].name").includes("video")) 

						 {
							
							// //console.log(get(active_scene).toLowerCase());
							for (let index = 0; index < data.length; index++) {
								
								if (
									data[index].scene == get(active_scene).toLowerCase() && data[index].id == hts_name
								) {
									// //console.log(data[index].title);
									let title = data[index].title;
									let headline = data[index].content;
									let img = data[index].img.replace('assets/','');

									// //console.log(title + ' | ' + headline + ' | '  + img);
									krpano.set("hotspot[" + hts_name + "].onclick", "js(tour_hts_info(true, " + hts_name + "," + title + "," + headline + "," + img + "););");
								}
								
								
							}
							

						}
						
					}

					}


					// else {
					// 	var hs = krpano.get("hotspot[info]");
					// 	var hts_count = krpano.get("hotspot.count");

					// 	for (let index = 0; index < hts_count; index++) {
					// 		if (
					// 			krpano.get("hotspot[" + index +"].name").includes("info") ||
					// 			krpano.get("hotspot[" + index +"].name").includes("video")	
					// 		) {
								
					// 			krpano.set("hotspot[" + index +"].alpha", "1.0");
					// 			krpano.set("hotspot[" + index +"].visible", "true");
					// 			// //console.log(krpano.get("hotspot[" + index +"].name"));
					// 			////console.log(krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));

					// 			var hs = krpano.get("hotspot[" + index +"]");
					// 			hs.onclick = function() {

					// 				hts_id = krpano.get("hotspot[" + index +"].id");
					// 				if (
					// 					krpano.get("hotspot[" + index +"].hstype").includes('info') != null
					// 				) {
					// 					modal_type.set('info-v'+ krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));
					// 				}

					// 				if (
					// 					krpano.get("hotspot[" + index +"].hstype").includes('video')
					// 				) {
					// 					modal_type.set('video-v2');
					// 				}

					// 				modal.update(n => !n);
					// 			}
					// 		}

					// 	}
					// }
				}		

				krpano.events.onviewchanged = function() {
					//bulb();
				}

				krpano.events.onloadcomplete = function() {
				
					
					
				
					

					// if (
					// 	loaded_times < 1
					// )
					// {
						
					// 	krpano_full_loaded = true;
					// 	loaded_krpano.update(n => !n);

					// 	setTimeout(function() {
					// 		preloader.update(n => !n);
					// 		enable_sound = true;
					// 		tour_select.update(n => true);


					// 	}, 1500);
					// }
					
					// loaded_times++;

				};

				krpano.events.onunknowndevice = function () {
					device_type.update(n=> 555);
				}

				krpano.events.webvr_onexitvr = function() {
					
					
					vr_mode.update(n => false);
					var hts_count = krpano.get("hotspot.count");

						for (let index = 0; index < hts_count; index++) {
							if (
								krpano.get("hotspot[" + index +"].type").includes("infopanel-v1") || krpano.get("hotspot[" + index +"].type").includes("infopanel-v2") || krpano.get("hotspot[" + index +"].type").includes("infopanel-v3") ||
								krpano.get("hotspot[" + index +"].type").includes("videopanel-v1")	|| krpano.get("hotspot[" + index +"].type").includes("videopanel-v2")	
							) {


								krpano.set("hotspot[" + index +"].alpha", "1.0");
								krpano.set("hotspot[" + index +"].visible", "true");

								var hs = krpano.get("hotspot[" + index +"]");
								// hs.onclick = function() {

								// 	hts_id = krpano.get("hotspot[" + index +"].id");
								// 	if (
								// 		krpano.get("hotspot[" + index +"].hstype").includes('info') != null
								// 	) {
								// 		modal_type.set('info-v'+ krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));
								// 	}

								// 	if (
								// 		krpano.get("hotspot[" + index +"].hstype").includes('video')
								// 	) {
								// 		modal_type.set('video-v2');
								// 	}

								// 	modal.update(n => !n);
								// }
							}
						}
						

				}
				
				krpano.events.webvr_onentervr = function() {
					
					vr_mode.update(n => true);

					var hs = krpano.get("hotspot[info]");
						var hts_count = krpano.get("hotspot.count");

					for (let index = 0; index < hts_count; index++) {

						var hts_name = krpano.get("hotspot[" + index +"].name");
						if (
							krpano.get("hotspot[" + index +"].name").includes("video")) {
							// krpano.set("hotspot[" + index +"].alpha", "0.0");
							// krpano.set("hotspot[" + index +"].visible", "false");
						}

						if (
							krpano.get("hotspot[" + index +"].name").includes("info") || krpano.get("hotspot[" + index +"].name").includes("video")) 
						 {
							
							// //console.log(get(active_scene).toLowerCase());
							for (let index = 0; index < data.length; index++) {
								// //console.log(data[index].scene);
								if (
									data[index].scene == get(active_scene).toLowerCase() && data[index].id == hts_name
								) {
									// //console.log(data[index].title);
									let title = data[index].title;
									let headline = data[index].content;
									let img = data[index].img.replace('assets/','');

									// //console.log(title + ' | ' + headline + ' | '  + img);
									krpano.set("hotspot[" + hts_name + "].onclick", "js(tour_hts_info(true, " + hts_name + "," + title + "," + headline + "," + img + "););");
								}
								
								
							}
							

						}
						
					}									
				};

				krpano.events.gyro_onenable = function() {
					gyro_mode.update(n => true);
				};

				krpano.events.gyro_ondisable = function() {
					gyro_mode.update(n => false);
				};

				krpano.events.gyro_onavailable = function() {
					gyro_available.update(n => true);
				};

				krpano.events.gyro_onunavailable = function() {
					gyro_available.update(n => false);
				};

				krpano.events.webvr_onavailable = function() {

					if (
						krpano.device.mobilevr == true && krpano.webvr.isvrbrowser
					) 
					device_type.update(n => 'vr');

				};
			}
		});
  	}


  	let modal_type_tmp;

	modal_type.subscribe(value => {
		modal_type_tmp = value;
		
	});

	let show_modal;
  	modal.subscribe(value => {
		show_modal = value;
		
	});

	let Logo, Modal, Sidebar, Shortcuts, Floorplan, Attributes, Basket, Screenshot, Contact_form = null;
	let Preloader;
	let EnterVR;
	let Intro;
	let TourSelect;
	let Bulb;
	let Login;
	let FlatFilter;
	let Languages;
	let Vr_tools;

	let components_loaded = false;



  	const sleep = ms => new Promise(f => setTimeout(f, ms));
	onMount(async () => {
	// 	//await sleep(1000); // simulate network delay
	// 	// Login = (await import('../tour_components/Login.svelte')).default
    	
	// 	EnterVR = (await import('../tour_components/Enter_VR.svelte')).default
		Modal = (await import('../tour_components/Modal.svelte')).default
		Logo = (await import('../tour_components/Logo.svelte')).default
		Preloader = (await import('../tour_components/Preloader.svelte')).default
		Sidebar = (await import('../tour_components/Sidebar_new.svelte')).default
		Shortcuts = (await import('../tour_components/Shortcuts_new.svelte')).default
		Floorplan = (await import('../tour_components/Floorplan_new.svelte')).default
		Attributes = (await import('../tour_components/Attributes.svelte')).default
		Basket = (await import('../tour_components/Basket.svelte')).default		
		Screenshot = (await import('../tour_components/Screenshot.svelte')).default		
		Contact_form = (await import('../tour_components/Contact_form.svelte')).default		
		FlatFilter = (await import('../tour_components/Flat_filter.svelte')).default	
		Languages = (await import('../tour_components/Languages.svelte')).default		
		Vr_tools = (await import('../tour_components/Vr_tools.svelte')).default
	});

	let _attributes = false;
	attributes.subscribe((value) => {
		_attributes = value;
	});

	
</script>

<svelte:head>
	<script src="{userdata['base_path']}assets/krpano/pano.js" on:load={initializeRemarkable}></script>
</svelte:head>

{#if Preloader && preloader == true}
	<svelte:component this={Preloader}/>
{/if}

<!-- inicializácia krpano tour -->
{#if Logo}
	<svelte:component this={Logo} {settings} {userdata}/>
{/if}

{#if Sidebar && krpano_loaded && google_map != null  && share_settings != null && _tour_settings != null && _attributes == false && contact_form != null && layers_data_loaded && scenes_loaded}
	<svelte:component this={Sidebar} {share_settings} {_tour_settings} {google_map} {contact_form} {layers} {scenes}/>
{/if}

{#if Shortcuts != null && _shortcuts_settings != null && shortcuts_settings_loaded == true && scenes_loaded }
	<svelte:component this={Shortcuts} {userdata} {_shortcuts_settings} {scenes} {_current_flat} {_sample_flats} {current_scene_shortcut_tag}/>
{/if}

{#if Floorplan != null }
	<svelte:component this={Floorplan} {userdata} {floorplans} {floorplan_pins} {scenes} {current_scene} {_sample_flats}/>
{/if}

{#if Modal && show_modal}
	{#if modal_type_tmp === 'google-map' && google_map != null}
		<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['maps']['title']}" settings="" url="{google_map.url}" filter="{google_map.filter}" position="" id="" current_scene="{current_scene}"/>
	{/if}
	{#if modal_type_tmp === 'tour-info'}
		<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['tourinfo']['title']}" settings="{settings}" url="" filter="" position="" id="" current_scene="{current_scene}"/>
	{/if}

	{#if modal_type_tmp === 'info-v1' || modal_type_tmp === 'info-v2' || modal_type_tmp === 'image-only' }
		<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" source={hotspots_data} title="" settings="{settings}" url="" filter=""  position="" id="" current_scene="{current_scene}" prefix="{userdata['base_path']}"/>
	{/if}

	<!-- {#if modal_type_tmp === 'info-v1' || modal_type_tmp === 'info-v2' || modal_type_tmp === 'info-v3' }
		<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
	{/if} -->

	{#if modal_type_tmp === 'video-v1'}
		<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
	{/if}

	{#if modal_type_tmp === 'video-v2'}
		<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
	{/if}
{/if}

{#if Attributes != null && _attributes == true}

	<svelte:component this={Attributes} {userdata} {krpano} {current_scene}/>
{/if}

{#if Basket != null && basket_enabled == true}
	<svelte:component this={Basket} {userdata} {krpano}/>

	{#if Languages != null}
		<svelte:component this={Languages} {basket_enabled}/>
	{/if}
{/if}

<!-- komponent Kontaktný formulár -->
{#if Contact_form != null && _contact_form_enabled == true}
	<svelte:component this={Contact_form} {userdata} {contact_form}/>
{/if}

<!-- komponent Kontaktný formulár -->
{#if FlatFilter != null && flat_filter_loaded == true && flat_filter.enabled}
	<svelte:component this={FlatFilter} {userdata} {flat_filter}/>
{/if}

<!-- komponent screenshot -->
{#if Screenshot != null}
	<svelte:component this={Screenshot}/>
{/if}


<!-- komponent screenshot -->
{#if Vr_tools != null}
	<svelte:component this={Vr_tools}/>
{/if}





<div id="pano"></div>

<style lang="scss">
	  @import "../assets/scss/mixins.scss";

	#pano {
		position: absolute;
		top: 0;
		left: 0;
		width:100%;
		height: 100%;
		z-index: 0;
	}
	#intro_div {
		position: absolute;
		top: 50%;
		left: 50%;
    	transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		background: rgba(255, 254, 254, 0.5) 0% 0% no-repeat padding-box;
  		backdrop-filter: blur(40px);
  		-webkit-backdrop-filter: blur(40px);
  		-moz-filter: blur(40px);
  		z-index: 9;
	}
</style>