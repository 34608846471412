<script>
  import { tooltip } from "@svelte-plugins/tooltips";
  const jq = window.$;
  import { fade, scale } from 'svelte/transition';
  import { getNotificationsContext } from "svelte-notifications";
  import { t, locale, locales } from "../i18n.js";
  const { addNotification } = getNotificationsContext();
  import { get } from 'svelte/store';
  import { all_scenes } from '../store';
  import { all_hotspots, hide_all_hotspots_not_patches, tooltipAnimation } from '../store';

  import { hotspots, attributes, loaded_krpano, disabled_icons, screenshot, sidebar, shortcuts, show_floorplan, cart, active_scene, vr_mode, languages, enabled_measure } from '../store.js';

  let _vrMode;
  vr_mode.subscribe(value => {
      _vrMode = value;
  });
  let _enabled_measure = false;
  enabled_measure.subscribe(value => {
    _enabled_measure = value;

  });

  export let userdata, _dispositions_enabled;


  let _current_scene = null;

  let _hotspots;
  hotspots.subscribe((value) => {
    _hotspots = value;
  });

  active_scene.subscribe((value) => {
    _current_scene = value;
  });

  import { flat_filter_enabled } from "../store.js";

  let _flat_filter_enabled = false;
  flat_filter_enabled.subscribe((value) => {
    _flat_filter_enabled = value;
  });



  let krpano = document.getElementById("krpanoSWFObject");  
  let _loaded_krpano = null;

  // výsledná veľkosť screenshotu
  let screen_width = null;
  let screen_height = null;

  // defaultná veľkosť
  let screen_size = "size-16";

  // defaultný čas pre zmenu screen size
  let time = 0.2;
  let animation = "easeInOut";

  let device = null;
  loaded_krpano.subscribe((value) => {
      _loaded_krpano = value;

      if (value == true) {
        krpano = document.getElementById("krpanoSWFObject");
      }
    });

  let screenshot_init = false;

  let _all_hotspots = [];
  all_hotspots.subscribe(value => {
    _all_hotspots = value;
  });


  // ak sa zmení screen size
  $: {
    screenshot_init;

    //console.log(screenshot_init);

    switch (screenshot_init) {
      case true:
      sidebar.update(n => false);
      attributes.update( n => false);
        break;
    
      default:
        show_hts_screenshot();
        
      break;
    }

    if (krpano != null) {
      if (screenshot_init == true) {
  
        // pozícia watermark
        let tmp_x = (screen_width / 2) - (112/2) - 8;
        let tmp_y = (screen_height / 2) - (32/2) - 8;

        disabled_icons.update(n => true);
        screenshot.update(n => true);
        sidebar.update(n => false);
        shortcuts.update(n => false);
        show_floorplan.update(n => false);
        cart.update(n => false);
      } else {
        disabled_icons.update(n => false);
        screenshot.update(n => false);
        img_data = null;
      }
    }
  }

  let button_bottom, button_left = 0;

  $: {
    screen_width; 

    switch (device) {
      case "mobile":
        button_left = 45;
        break;
    
      default:
        if (window.innerWidth == screen_width) {
          button_bottom = 32;
        } else {
          button_left = (window.innerWidth - screen_width) / 2;
        }
        break;
    }
    
    
  }

  $: {
    screen_height;
    
    switch (device) {
      case "mobile":
        button_bottom = 32;
        break;
    
      default:
        if (window.innerHeight == screen_height) {
         button_left = 32;
        } else {
          button_bottom = ((window.innerHeight - screen_height) / 2) - 24 - 40;
        }
        break;
    }
    
    
    
  }

  // výsledný BASE64 img
  let img_data = null;

  let tmp_img_width = 400;
  let tmp_img_height = 400;

  function hide_hts_screenshot() {
    ////console.log("skrývam hotspoty");
    attributes.update( n => false);
    var allHotspotsFromApi = krpano.get("hotspot").getArray();
    allHotspotsFromApi.forEach(element => {
      if (element.patch != "true") {
        element.old_alpha = krpano.get("hotspot[" + element.name + "].alpha");  
        krpano.call("set(hotspot[" + element.name + "].alpha, 0.0);");  
        krpano.call("set(hotspot[" + element.name + "].enabled, false);");  
        ////console.log(krpano.get("hotspot[" + element.name + "]"));  
      }
      
    });
    
    
  }

  function show_hts_screenshot() {
    var allHotspotsFromApi = krpano.get("hotspot").getArray();
    ////console.log("zapínam hotspoty");
    allHotspotsFromApi.forEach(element => {
      if (element.patch != "true") {
        krpano.call("set(hotspot[" + element.name + "].alpha, " + element.old_alpha +  ");");  
        krpano.call("set(hotspot[" + element.name + "].enabled, true);");      
        ////console.log(krpano.get("hotspot[" + element.name + "]"));  
      }
        
    });
  }

  // vytvorenie screenshotu cez KRPANO
  function make_screenshot(parameter) {
    // krpano.call("set(hotspot[watermark].alpha, 1.0);");
    if (krpano != null) {

      switch (parameter) {
        case true:
            krpano.set("allow_saving", true);
          break;
    
        default:
          krpano.set("allow_saving", false);
          break;
      }

      // krpano.call("makeScreenshot(" + parseInt(screen_width) + ", " + parseInt(screen_height) + ",true,'jpeg', 1.0, true)");

      let borderSideWidth =  (window.innerWidth - screen_width ) / 2;
      let borderSideHeight = (window.innerHeight - screen_height ) / 2;

      html2canvas(jq('#pano')[0], {
        x : borderSideWidth,
        y : borderSideHeight,
        width: screen_width,
        height: screen_height
        }).then(canvas => {
          
          //////console.log(canvas.toDataURL("image/png"));
          img_data = canvas.toDataURL("image/png");

        // var a = document.createElement('a');
        // a.href = canvas.toDataURL("image/png");
        // a.download = 'myfile.png';
        // a.click();
      });



      // funkcia čaká na vytvorenie screenshotu a následne sa img zobrazí
      function waitForElement(){
        if(img_data == null){
          // ////console.log("SCREEN VYTVORENÝ");
          setTimeout(waitForElement, 250);
          // img_data = krpano.get("screenshot_data");
          // krpano.call("set(hotspot[watermark].alpha, 0.0);");
        }
        
        else{
            
        }
      }

      waitForElement();
    }
  }

let hotspots_before_screenshot = false;

  // zistenie rozlíšenia zariadenia
  function calcucalte_screen_size($size) {
    //hide_htospots_during_screenshot();
    let current_width = window.innerWidth;
    let current_height = window.innerHeight;
    device = null;

    // Dotykové zariadenia
    if (window.matchMedia("(pointer: coarse)").matches) {
      
      // Mobil - iPhone
      if (
        current_width <= 428
      ) {
        screen_width = current_width;
        screen_height = current_height;

        device = "mobile";

        
      } 
      
      // Tablet
      else {
        if (
          current_height > current_width
        ) {

          screen_width = current_width - 192;
          screen_height = current_height - 256;

          device = "tablet-portrait";

        } else {
          screen_width = current_width - 256;
          screen_height = current_height - 192;

          device = "tablet-landscape";
          
        }
        
      }

    } 
    
    // Desktop
    else {
      switch ($size) {
        case "size-full": 
            screen_width = current_width;
            screen_height = current_height;
            
          break;
        case "size-16":
          
          screen_height = current_height - 270;
          screen_width = screen_height * (16/9);

          break;
        case "size-4":
          screen_height = current_height - 270;
          screen_width = screen_height * (4/3);
          break;        


      
        default:
          screen_height = current_height - 270;
          screen_width = screen_height * (16/9);
          break;
      }

      
      device = "desktop";
    
    }

    
    screenshot_init = true;
    languages.update(n => false);
  }

  // zmena veľkostí screenu pre fotenie
  function change_screen_size($parameter) {
    //////console.log("zmena veľkosti"); 
    screen_size = $parameter;
    
    let tmp_x = (screen_width / 2) - (112/2) - 8;
    let tmp_y = (screen_height / 2) - (32/2) - 8;
    // krpano.call("set(hotspot[watermark].ox, " + tmp_x  +  ");");
    // krpano.call("set(hotspot[watermark].oy, " + tmp_y  +  ");");

    krpano.call("tween(hotspot[hts_screen].width, " + screen_width + "px, " + time + ", " + animation + ");");
    krpano.call("tween(hotspot[hts_screen].height, " + screen_height + "px, " + time + ", " + animation + ");");
  } 

  // Funkcia na stiahnutie obrázka

  function download_image() {
    var a = document.createElement("a"); //Create <a>
    a.href = img_data; //Image Base64 Goes here
    a.download = _current_scene + "_screenshot.jpg"; //File name Here
    a.click(); //Downloaded file
  }

  function close_screenshot(params) {
    //console.log(params);
    if (img_data == null) {
      screenshot_init = false
      // krpano.call("set(hotspot[watermark].alpha, 0.0);");
    } else {
      img_data = null;
      
      
    }
    //show_hts_screenshot();
    
  }

</script>

<div id="woow-screenshot">
{#if _vrMode == false}
  {#if screenshot_init}
    {#if device == "desktop" && img_data == null}
      <div id="screen-sizes" transition:fade>
        <div id="size-full" class:active={screen_size == "size-full"} on:click={() => change_screen_size("size-full")} on:click={() => calcucalte_screen_size("size-full")}>Fullscreen</div>
        <div id="size-16" class:active={screen_size == "size-16"} on:click={() => change_screen_size("size-16")} on:click={() => calcucalte_screen_size("size-16")}>16:9</div>
        <div id="size-4" class:active={screen_size == "size-4"} on:click={() => change_screen_size("size-4")} on:click={() => calcucalte_screen_size("size-4")}>4:3</div>
      </div>
    {/if}
    
    <!-- ikona na zatvorenie fotenia -->
    <div id="close-screenshot" class="{device}" 
      on:click={() => close_screenshot("test")} 
      transition:fade />
      <div id="screen-background" style="width: {screen_width}px; height: {screen_height}px;" transition:fade/>
    

    <!-- ikona na vytvorenie screenshotu -->
    {#if img_data == null}
      <div id="take-screenshot" class="{device}" on:click={() => make_screenshot(true)} transition:fade>
        <img src="{userdata["cdn_path"]}icons/screenshot_full.svg" />
        <p>{$t("components")['screenshots']['take-screenshot']}</p>
      </div>
    {/if}
    

  {/if}

  {#if screenshot_init == false && _flat_filter_enabled == false && !_enabled_measure && !_dispositions_enabled}
      {#if userdata["tooltips"] == true}
      <div id="screenshot-icon" class="{device}" 
        on:click={() => hide_hts_screenshot()} 
        on:click={() => calcucalte_screen_size(screen_size)} transition:fade
        use:tooltip={{ content: $t("components")['tooltips']['screenshot'],position: 'bottom', align: 'center', animation: get(tooltipAnimation), theme: 'tooltip-woow screen'}}  
        />
      {:else}
        <div id="screenshot-icon" class="{device}" on:click={() => hide_hts_screenshot()} on:click={() => calcucalte_screen_size(screen_size)} transition:fade/>
      {/if}
    <!-- ikona na spustenie fotenia -->
    
  {/if}

  {#if img_data != null}
    {#if device == "mobile"}
      <div class="mobile_helper">
      </div>
    {/if}
    <!-- <img class="screen_thumb {device}" src="{img_data}" style="width: {screen_width}px; height: {screen_height}px;" alt="test" on:click={() => img_data = null} /> -->
    <!-- <img class="screen_thumb {device}" src="{img_data}" style="width: {screen_width}px; height: {screen_height}px;" alt="test" on:click={() => img_data = null} /> -->
    <div id="download" style="bottom: {button_bottom}px; left: {button_left}px;" on:click={() => download_image()}>
      {$t("components")['screenshots']['download-screenshot']}
    </div>
  {/if}


    <!-- iba uloženie screenshotu pre danú scénu -->
  <!-- <div id="screenshot-icon-save" on:click={() => make_screenshot()}/> -->
{/if}

</div>

<style lang="scss">
  @import "../assets/scss/themes.scss";
  @import "../assets/scss/fonts.scss";
  @import "../assets/scss/mixins.scss";
  @import "../assets/scss/variables.scss";

  #woow-screenshot{
  #tmp {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: -1;

  }

  .screen_thumb {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:99999;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &.mobile {
      width: calc(100% - 90px) !important;
      height: calc(100% - 160px) !important;
    }
  }

  #screen-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    padding: 20px;
    outline: 3000px solid rgba(0,0,0,0.7);
    pointer-events: none;
    z-index: 9;
    transition: all 0.25s ease-in-out;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
  }

  .mobile_helper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    z-index: 2;
  }

  #download {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index:99999;  
    top: unset;
    right: unset;
    height: auto;
    margin: 0px;
    background-color: var(--secondary-color);
    border-radius: 6px;
    @include rubik-r14;
    padding: 10px 16px;
    cursor: pointer;
  }

  #screenshot-icon, #screenshot-icon-save {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 6px;
    z-index: 2;
    cursor: pointer;
    
    background-position: center;
    background-repeat: no-repeat;
  }

  #screenshot-icon {
    top: 72px;

    &.hide-blur {
      display: none;
    }

  }

  #screenshot-icon-save {
    top: 132px;
  }

  #close-screenshot, #take-screenshot {
    position: absolute;
    z-index: 10;
  }

  #close-screenshot {
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;
    
    cursor: pointer;

    &.mobile {
      top: 24px;
    }

    &.tablet-landscape {
      top: 32px;
    }
  }

  #take-screenshot {
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
      cursor: pointer;
    }

    p {
      color: var(--secondary-color);
      @include rubik-r12;
    }

    &.mobile {
      bottom: 22px;
    }

    &.tablet-landscape {
      bottom: 14px;
    }

    &.tablet-portrait {
      bottom: 30px;
    }

  }

  #screen-sizes {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 10;

    div {
      position: relative;
      width: 96px;
      height: 54;
      box-shadow: 0 0 0 1px var(--secondary-color);
      padding: 18px 24px;
      border-radius: 6px;
      cursor: pointer;
      color: var(--secondary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      @include rubik-r12;

      &.active {
        box-shadow: 0 0 0 2px var(--secondary-color);
        @include rubik-m12;

        &::before {
          content: "";
          position: absolute;
          left: -20px;
          width: 8px;
          height: 14px;
          
        }
      }
    }
  }
}
</style>