<script>
    import { useForm, Hint, validators, minLength,required,email} from "svelte-use-form";
    import { loaded_components, contact_form_enabled, blur } from '../store.js';
    import { fade, scale } from 'svelte/transition';

    import { t, locale, locales } from "../i18n.js";
  
    import { getNotificationsContext } from "svelte-notifications";
    export let userdata;
    const { addNotification } = getNotificationsContext();

    // let message = {
    //     first_name: "Štefan",
    //     email: "stefan.kelbel@me.com",
    //     last_name : "Kelbel",
    //     phone : "0904",
    //     message : "správa"
    // }

    let message = {
        first_name: "",
        email: "",
        last_name : "",
        phone : "",
        message : "",
        parameters : {
          base_path : userdata["base_path"]
        }
        

    }

    

    
    
    let tmp = userdata;
    
    let email_sent = false;
    let _contact_form_enabled, blur_enabled = false;

    contact_form_enabled.subscribe(value => {
      blur.update( n => value);      
        _contact_form_enabled = value;
        email_sent = false;

    });

    

    const form = useForm({
        first_name: {},
        email: {},
        last_name : {},
        phone : {},
        message : {},
        agree : {}
    });

    async function sendMessage () {
      //console.log(message);
      
      const res = await fetch(
        userdata['cdn_path'] + "php/sendMessage.php", {
          method: 'POST',
          body: JSON.stringify(message)
        }) 
        if (res.ok) {
        const json = await res.text(); 
        //console.log(json);

        // addNotification({
        //         text: 'Správa bola odoslaná!.',
        //         position: 'top-center',
        //         removeAfter: 4000,
        //         type: 'danger'
        //     });
        
            email_sent = true;
            
    
        } else {
            addNotification({
                text: 'Chyba pri odosielaní správy!.',
                position: 'top-center',
                removeAfter: 4000,
                type: 'danger'
            });
        }
    }

</script>
<div id="contact-form">
{#if _contact_form_enabled == true} 
    {#if email_sent == false} 
        <div id="form" class="video"  transition:fade>
            <div class="close" on:click={() => contact_form_enabled.update(n => false)} />
            <div class="title">{$t("components")['contact_form']['sidebar-title']}</div>
            <div class="form-container">
                <form use:form>
                    <div class="form-level-1">
                        <label>
                        <span class="povinne">{$t("components")['contact_form']['name']}</span>
                        <input name="first_name" bind:value={message.first_name} class="{$form.first_name?.touched && $form.first_name?.errors.minLength ? 'danger' : ''}" type="text" placeholder="{$t("components")['contact_form']['name_hint']}" use:validators={[required, minLength(3)]} />
                        </label>
                        <label>
                        <span class="povinne">{$t("components")['contact_form']['email']}</span>
                        <input name="email" bind:value={message.email} class="{$form.email?.touched && $form.email?.errors.email ? 'danger' : ''}" type="text" placeholder="Email" use:validators={[required, email]} />
                        </label>
                    </div>

                    <div class="form-level-2">
                        <label>
                            <span class="povinne">{$t("components")['contact_form']['lastname']}</span>
                            <input name="last_name" bind:value={message.last_name} class="{$form.last_name?.touched && $form.last_name?.errors.minLength ? 'danger' : ''}" type="text" placeholder="{$t("components")['contact_form']['lastname_hint']}" use:validators={[required, minLength(3)]} />
                        </label>
                        <label>
                            <span class="povinne">{$t("components")['contact_form']['phone']}</span>
                            <input name="phone" bind:value={message.phone} class="{$form.phone?.touched && $form.phone?.errors.required ? 'danger' : ''}" type="text" placeholder="{$t("components")['contact_form']['phone_hint']}" use:validators={[required]} />
                        </label>
                    </div>
                    <label>
                        <span>{$t("components")['contact_form']['message']}</span>
                        <textarea name="message" bind:value={message.message} placeholder="{$t("components")['contact_form']['message_hint']}" class="full-size" />
                    </label>
                    <p class="povinne">{$t("components")['contact_form']['required_fields']}</p>
                    <button disabled={!$form.valid} class="{$form.valid == true ? '' : 'disabled' }" on:click|preventDefault={sendMessage}>{$t("components")['contact_form']['send_button']}</button>
                    <div class="checkbox povinne">
                        <input type="checkbox" id="nezobrazovat" name="agree" class="check" use:validators={[required, minLength(1)]} />
                        <label for="nezobrazovat">
                          {$t("components")['contact_form']['first_req_text']} &nbsp;<a href="https://www.woowstudio.com">{$t("components")['contact_form']['op']}</a>&nbsp;{$t("components")['contact_form']['second_req_text']}
                        </label>
                    </div>
                
                    <!-- <button disabled={!$form.valid} on:click|preventDefault>Submit</button> <br /> -->
                </form>
            </div>
        </div>    
    {/if}

    
    {#if email_sent == true} 
        <div id="thank" transition:fade>
            <img src="../assets/icons/success.svg" alt="">
            <div class="close" on:click={()=> contact_form_enabled.update( n=> false)}/>
            <div class="title">{$t("components")['contact_form']['thanks']}</div>
            <div class="text">{$t("components")['contact_form']['sent']}</div>
        </div>
    {/if}

  {/if}

</div>


<style lang="scss">
    //   variables
    

    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";

  #contact-form{
    #thank {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 32px);
        height: auto;
        max-width: 350px;
        max-height: 500px;
        padding: 16px;
        @include blur;
        border-radius: $radius-M;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        z-index: 13;
        text-align: center;

        .title {
            @include rubik-m24;
            padding: 0 32px;
            margin: 8px 0;
            text-align: center;
        }
    }

    #blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(63, 63, 70, 0.2);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        z-index: 12;
    }   

    

  #form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    max-width: 960px;
    max-height: 640px;
    padding: 16px;
    @include blur;
    border-radius: $radius-M;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    z-index: 13;

    form {
      display: flex;
      flex-direction: column;
    }

    .title {
      @include rubik-m24;
      padding: 0 32px;
      margin-bottom: 8px;
      text-align: center;
    }
    .form-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100% - 16px);
      max-width: 560px;
      input:not(.check),
      textarea {
        border-radius: $radius-S;
        border: 1px solid var(--primary-color-light20);
        background-color: transparent;
        @include rubik-r12;
        padding: 16px;
        margin-top: 40px;
        &::placeholder {
          color: var(--primary-color);
        }

        &.danger {
            border: 1px solid red;
        }
      }
      .form-level-1,
      .form-level-2 {
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        width: 100%;
        input {
          height: 46px;
          width: 100%;
        }
      }

      .full-size {
        width: 100%;
        height: 200px;
        resize: none;
      }

      p {
        @include rubik-r12;
        color: var(--primary-color-light60);
      }

      .povinne {
        &::after {
          content: "*";
          color: var(--alert-color);
        }
      }

      label {
        position: relative;
        width: 100%;
        display: flex;
        span {
          position: absolute;
          @include rubik-r12;
          margin-top: 20px;
        }
      }

      button {
        width: 100%;
        height: 45px;
        max-width: 264px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 16px;
        color: var(--secondary-color);
        background-color: var(--main-color);
        border: none;
        border-radius: $radius-S;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &.disabled {
            opacity: 0.3;
        }
      }
    }
    .checkbox {
      @include rubik-r12;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        margin-top: 8px;
      }

      #nezobrazovat {
        margin-right: 8px;
        appearance: none;
        width: 20px;
        height: 18px;
        outline: 2px solid var(--primary-color-light10);
        outline-offset: -2px;
        border-radius: 4px;
        position: relative;
        top: 3px;
        cursor: pointer;
        // display: grid;
        // place-content: center;

        &:checked {
          outline: 2px solid var(--primary-color);
        }

        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          background-color: var(--primary-color);
          position: relative;
          top: 4px;
          left: 4px;
          clip-path: polygon(
            14% 44%,
            0 65%,
            50% 100%,
            100% 16%,
            80% 0%,
            43% 62%
          );
        }

        &:checked::before {
          transform: scale(1);
        }
      }
      label {
        cursor: pointer;
      }
      a {
        color: var(--main-color);
        text-decoration: none;
        @include rubik-m12;
      }
    }
  }

  //  ----------------SCREEN-------------------------------------------------------------------------------------------------------

  @media (max-width: 450px) {
    #form {
      .form-container {
        margin-top: 25px;
      }
      .checkbox {
        label {
          @include rubik-r10;
        }
        a {
          @include rubik-m10;
        }
      }
      .form-level-1,
      .form-level-2 {
        flex-direction: column;
        label {
          span {
            margin-top: 5px;
          }
          input:not(.check) {
            margin-top: 25px;
          }
        }
      }
    }
  }

  //  ----------------HOVER-------------------------------------------------------------------------------------------------------

  @media (hover: hover) and (pointer: fine) {
    #form {
      .form-container {
        button {
          &:hover {
            background-color: var(--main-hover);
          }
        }
      }
    }
  }
}
</style>