<script>

  export let settings;
  export let userdata;
    let tmp = userdata;
    tmp = settings;

  
  import { active_components } from '../stores.js';
  import { last_used_component } from '../stores.js';
  import { t, locale, locales } from "../i18n.js";
  import { loaded_components } from '../store.js';
  import { show_floorplan } from '../store.js';
  import { vr_question } from '../store.js';
  import { vr_mode } from '../store.js';
  import { gyro_mode } from '../store.js';
  import { mobile_submenu } from '../store.js';
  import { mobile_settings_submenu } from '../store.js';
  import { sidebar } from '../store.js';
  import { shortcuts } from '../store.js';
  import { dispositions_enabled } from '../store.js';
  

  let _vrMode;

vr_mode.subscribe((value) => {
_vrMode = value;
  // switch (value) {
  //   case true:
  //   krpano.call("webvr.enterVR()");
  //     break;

  //   case false:
  //     krpano.call("webvr.exitVR()");
  //     break;
  // }
  switch_vr_mode();
});

   

  import { picker } from '../store.js';
  let _picker;

  picker.subscribe(value => {
    // console.log('disabled icons ' + value);
		_picker = value;
	});
  
  import { disabled_icons, showing_intro } from '../store.js';

  let dis_icon = get(disabled_icons);

  disabled_icons.subscribe(value => {
    // console.log('disabled icons ' + value);
		dis_icon = value;
	});

  let show_intro = true;
  let _sidebar = false;

  sidebar.subscribe(value => {
		_sidebar = value;

    if (
      value == true
    ) {
      shortcuts.update(n => false);
      vr_question.update(n => false);
    }
	});

  let blur_enabled = false;

  import { blur } from '../store.js';
    blur.subscribe(value => {
		  blur_enabled = value;
	  });

    $loaded_components = [...$loaded_components, 'sidebar'];

  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  let loading_data = false;
  
  let showing;
  let last_component;
  let is_vr = false;
  let is_gyro = false;
  let mob_submenu = false;
  let mob_settings_submenu = false;

  active_components.subscribe(value => {
		showing = value;
	});

  gyro_mode.subscribe(value => {
		is_gyro = value;
	});

  mobile_submenu.subscribe(value => {
		mob_submenu = value;
	});

  mobile_settings_submenu.subscribe(value => {
		mob_settings_submenu = value;
	});

  // $: {
  //   is_vr;
  //   settings.components[5].values[4].enabled = is_vr;
  //   let krpano = document.getElementById("krpanoSWFObject");

  //   if (
  //     is_vr == true
  //   ) {
      
  //   }

  //   else {

  //   }
  // } 
  

  $: {
    is_gyro;
    
    // settings.components[5].values[3].enabled = is_gyro;

    let krpano = document.getElementById("krpanoSWFObject");
    krpano.call("set(plugin[gyro].enabled, " + is_gyro + ");");
    
  } 

  last_used_component.subscribe(value => {
		last_component = value;
	});

    // odobratie komponentu z poľa aktívnych prvkov
  function change_active_component($component) {
    if (
      showing.includes($component)
    ) {
      active_components.update(n => n.filter( item => item !== $component));
      last_used_component.update(n => '');

    }

    else {
      active_components.update(n => [...n, $component]);
      last_used_component.update(n => $component);
    }
  }

  function autorotate() {
    var krpano = document.getElementById("krpanoSWFObject");
    // krpano.set("autorotate('speed', 5.5");
    // krpano.set("autorotate('waittime', 0.0");
    // krpano.set("autorotate('tofov', on");
    // krpano.set("autorotate('horizon', 8.0");
    // krpano.switch("autorotate.enabled");
  }

  function enable_autorotate () {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("autorotate.start()");
  }

  function disable_autorotate () {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("autorotate.stop()");
  }

  // zapínanie vypínanie autorotácie
  $: if (settings.components[5].values[1].enabled == true) {
    enable_autorotate();
  }
   
  // zapínanie vypínanie autorotácie
  $: if (settings.components[5].values[1].enabled == false) {
    disable_autorotate();
  }

  import { play_sound } from '../store.js';

  $: if (settings.components[5].values[5].enabled == true) {
    // enable background sound
    play_sound.update(n=> true);
  }

  $: if (settings.components[5].values[5].enabled == false) {
    // enable background sound
    play_sound.update(n=> false);
  }


  function enable_hotspots () {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("show_hotspots");
    krpano.set("show_hs", "true");
    
  }

  function disable_hotspots () {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("hide_hotspots");
    krpano.set("show_hs", "false");
  }

  showing_intro.subscribe(value => {
		  show_intro = value;

      if ( show_intro == true) {
      }

      else 
      
       {
        enable_hotspots();  
      }
      
    });

  // zapínanie vypínanie hotspotov
  $: if (settings.components[5].values[0].enabled == true) {
    enable_hotspots();
  }

  // zapínanie vypínanie autorotácie
  $: if (settings.components[5].values[0].enabled == false) {
    disable_hotspots();
  }

  let active = false, navigating = false;

  function share_facebook() {
    let url = 'https://www.facebook.com/sharer/sharer.php?u='+window.location.href;
    window.open(url, '_blank').focus();
  }

  function share_twitter() {
    let url = 'https://twitter.com/share?url='+window.location.href;
    window.open(url, '_blank').focus();
  }

  function share_linkedIn() {
    let url = 'https://www.linkedin.com/shareArticle?mini=true&url='+window.location.href;
    window.open(url, '_blank').focus();
  }

  function copy_url() {
    document.getElementById('share_link').select();
		document.execCommand('copy');

    // addNotification({
    //   text: "Link bol skopírovaný do schránky!",
    //   position: "top-center",
    //   removeAfter: 4000,
    //   type: "success",
    // });
    
  }

  function switch_vr_mode() {
    let krpano = document.getElementById("krpanoSWFObject");
    if (krpano) {
      switch (_vrMode) {
        case true : 
          krpano.call("webvr.enterVR()");  
        break;

        case false : 
          krpano.call("webvr.exitVR()");        
        break;
      }
    }
  }

  function switch_gyro_mode() {
    if (
      is_gyro == true
    ) {
      // console.log('vypínam gyro');
      gyro_mode.update(n => false);
    }

    else {
      // console.log('zapínam gyro');
      gyro_mode.update(n => true);
      
    }
    
  }

  // zapnutie / vypnutie modalu
  import { modal } from '../store.js';
  import { modal_type } from '../store.js';
  import { get } from 'svelte/store';

  let enable_sound = false;
  let enabled_share_sub = false;
  let enabled_settings_sub = false;

  let isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera());
    },
    vr : function () {
      return(isMobile.Windows == false);
    }
  };

  var OS = navigator.platform;

  function enable_share() {
    if (
      // isMobile.any() || OS.indexOf('linux') != - 1
      window.matchMedia("(pointer: coarse)").matches
      
    ) {
      mob_submenu = true;
      enabled_share_sub = true;
      // console.log(enabled_share_sub);
    }
    
  }

  function enable_settings() {
    
    
    if (
      window.matchMedia("(pointer: coarse)").matches
    ) {
      mob_submenu = false;
      mob_settings_submenu = !mob_settings_submenu;
      enabled_share_sub = false;
      enabled_settings_sub = !enabled_settings_sub;
      // console.log(enabled_settings_sub);
    }

    
    
  }

  function dispo_toggle() {
        dispositions_enabled.update( n => !n);
    }

</script>

{#if loading_data == false && _picker == false}
  <div class="sidebar-icon" class:active="{_sidebar == true}" class:hide_blur="{blur_enabled === true || dis_icon == true}" on:click="{() => sidebar.update(n => true)}" on:click="{() => change_active_component('sidebar')}" on:click="{() => show_floorplan.update(n => false)}"></div>

  <div id="sidebar" class:active="{_sidebar == true}" data-id="{_sidebar}">
    <div class="header">
      

      {#if mob_submenu == true || mob_settings_submenu == true}
        <h2 class="active" on:click="{() => enabled_share_sub = false}" on:click="{() => enabled_settings_sub = false}" on:click="{() => mob_submenu = false}" on:click="{() => mob_settings_submenu = false}" >Späť</h2>
      {/if}

      {#if mob_submenu == false && mob_settings_submenu == false}
        <h1 class="active">Menu</h1>
      {/if}

      <div class="close" on:click="{() => sidebar.update(n => false)}" on:click="{() => change_active_component('sidebar')}"/>
    </div>
    <div id="sidebar-content" class="content" class:active="{mob_submenu == false && mob_settings_submenu == false }">
      <ul>

        {#if settings.components[9].enabled}
        <li class="floor-plan" on:click="{() => sidebar.update(n => false)}" on:click="{() => blur.update(n => true)}" on:click="{() => show_floorplan.update(n => !n)}" on:click="{() => change_active_component('sidebar')}">
          {$t("components")['floorplan']['sidebar-title']}
        </li>
        {/if}
      <!--   <li class="model-3d">3D model</li>
       
        <li class="layers submenu">
          Layers
          <ul class="">
            <li class="layer-item">Layer 1 - (Random name)</li>
            <li class="layer-item active">Layer 2 - (Random name)</li>
            <li class="layer-item active">Layer 3 - (Random name)</li>
          </ul>
        </li>
        <li class=" attributes">Attributes</li>
        <li class=" google-map">Google map</li>
        <li class=" tour-info">Tour info</li>
        <li class="mortgage-calculator">Mortgage calculator</li>
        <li class="chatbot">ChatBot</li>
        
        <li class="product-filter submenu">
          Product filter
          <ul>
            <li class="sub-submenu">
              <label for="p-filter"
                >product filter<input type="checkbox" id="p-filter" /></label
              >
            </li>
            <li>nábytok<span>2</span></li>
            <li>osveltenie<span>16</span></li>
            <li class="active">dekorácie<span>3</span></li>
            <li>textilie<span>1</span></li>
          </ul>
        </li>-->
        {#if settings.components[6].enabled}
          <li  class="share submenu" on:click={() => enable_share()}>{$t("components")['share']['sidebar-title']} 
            <div id="share-container_submenu" class:active="{enabled_share_sub}" class="share-container">
              <div class="social-icons">
                {#each settings.components[6].values as item}
                  {#if item.enabled}
                    {#if item.id == 'facebook'}
                      <div class="social-item facebook" on:click="{share_facebook}"></div>
                    {/if}

                    {#if item.id == 'twitter'}
                      <div class="social-item twitter" on:click="{share_twitter}"></div>
                    {/if}

                    {#if item.id == 'linkedIn'}
                      <div class="social-item linkedin" on:click="{share_linkedIn}"></div>
                    {/if}
                  {/if}
                {/each}
                
              </div>
              <div class="social-link">
                <input id="share_link" type="text" value="{window.location.href}">
                <div class="copy" on:click="{copy_url}"></div>
              </div>
            </div>
          </li>
        {/if}
        {#if settings.components[7].enabled}
          <li class="google-map" 
            on:click="{() => modal_type.set('google-map')}"
            on:click="{() => modal.update(n => !n)}"
            on:click="{() => change_active_component('sidebar')}">
            {$t("components")['maps']['sidebar-title']}
          </li>
        {/if}
        {#if settings.components[8].enabled}
          <li class="tour-info" 
            on:click="{() => modal_type.set('tour-info')}"
            on:click="{() => modal.update(n => !n)}"
            on:click="{() => change_active_component('sidebar')}">
            {$t("components")['tourinfo']['sidebar-title']}
          </li>
        {/if}

      </ul>
    </div> 
    <div class="footer">
      <ul>
        <li class="settings submenu" on:click={() => enable_settings()}>{$t("components")['settings']['title']}
          <ul class:active="{enabled_settings_sub}"class="settings-container">
            {#each settings.components[5].values as item}
            {#if item.allowed == true}

            
            {#if item.id == 'vr_mode'}
              <li class="">
                <label class="settings-item" for="{item.id}">{$t("components")['settings'][item.id]}
                  <input type="checkbox" id="{item.id}" bind:checked="{_vrMode}"  on:click="{() => sidebar.update(n => false)}" on:change="{() => switch_vr_mode()}"/>
                </label>
              </li>
            {:else if item.id == 'gyro'}
              <li class="">
                <label class="settings-item" for="{item.id}">{$t("components")['settings'][item.id]}
                  <input type="checkbox" id="{item.id}" bind:checked="{item.enabled}" on:change="{() => switch_gyro_mode()}"/>
                </label>
              </li>
              {:else if item.id == 'sound'}
              <li class="">
                <label class="settings-item" for="{item.id}">{$t("components")['settings'][item.id]}
                  <input type="checkbox" id="{item.id}" disabled/>
                </label>
              </li>

            {:else}
              <li class="">
                <label class="settings-item" for="{item.id}">{$t("components")['settings'][item.id]}
                  <input type="checkbox" id="{item.id}" bind:checked="{item.enabled}"/>
                </label>
              </li>
            {/if}
              
              
            {/if}
            {/each}
          </ul>

        </li>
        <!-- <li class="user">woow@woowstudio.com</li> -->
      </ul>
      <!-- <a href="https://woowstudio.com">Log out</a> -->
    </div>
  </div>

  {#if enable_sound == true}
		{#await import('./Sounds.svelte') then c}
			<svelte:component this={c.default} {settings}/>
		{/await}
	{/if}
{/if}


  <style lang="scss">
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";

  .sidebar-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    @include blur;
    border-radius: $radius-S;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: var(--primary-color);
      -webkit-mask-image: url("../assets/icons/sidebar.svg");
      mask-image: url("../assets/icons/sidebar.svg");
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      opacity: 0;
      visibility: hidden;
      
    }
  }

  #sidebar {
    position: absolute;
    top: 50%;
    left: -$sidebar-width;
    transform: translateY(-50%);
    width: $sidebar-width;
    height: calc(100% - 32px);
    @include blur;
    border-radius: $radius-M;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &.active {
      left: 16px;
    }

    .header {
      padding: 0px 16px 2px 16px;
      @include underline-2-a;

      h1 {
        @include rubik-m20;
        padding-right: 28px;
        margin-bottom: 8px;
        display: none;
        &.active {
          display: block;
        }
      }

      h2 {
        display: none;
        align-items: center;
        @include rubik-r14;
        height: 24px;
        margin-bottom: 10px;
        cursor: pointer;
        &::before {
          content: "";
          width: 8px;
          height: 14px;
          background-color: var(--primary-color);
          mask-image: url("../assets/icons/submenu.svg");
          -webkit-mask-image: url("../assets/icons/submenu.svg");
          mask-repeat: no-repeat;
          -webkit-mask-repeat: no-repeat;
          mask-size: cover;
          -webkit-mask-size: cover;
          display: inline-block;
          transform: rotate(180deg);
          margin: 0 21px 0 8px;
        }
        &.active {
          display: flex;
        }
      }
    }

    .content {
      overflow: auto;
      height: calc(100% - 121px);
      padding: 12px 8px;
      transition: all 0.25s ease-in-out;
      // position: relative;
    }

    .content,
    .footer {
      ul {
        @include rubik-r14;
        margin: 0;
        padding: 0;

        li {
          width: 100%;
          height: 48px;
          padding-left: 8px;
          cursor: pointer;
          margin-bottom: $spacing-S;
          display: flex;
          align-items: center;

          &.active {
            &:hover {
              background-color: var(--main-color-light10);
              border: 1px solid var(--main-color-light60);
            }
          }

          &.user {
            &:hover {
              background-color: transparent;
            }
          }
        

          &.active {
            background-color: var(--main-color-light005);
            border-radius: $radius-S;
            border: 1px solid var(--main-color-light20);
            color: var(--main-color);

            &::before {
              background-color: var(--main-color);
            }

            &.submenu {
              &::after {
                background-color: var(--main-color);
              }
            }
          }

          &.submenu {
            &::after {
              content: "";
              position: relative;
              width: 6.67px;
              height: 11.67px;
              background-color: var(--primary-color);
              mask-image: url("../assets/icons/submenu.svg");
              -webkit-mask-image: url("../assets/icons/submenu.svg");
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center;
              margin-left: auto;
              margin-right: 8px;
              
            }
          }

          &::before {
            content: "";
            position: relative;
            margin-right: 12px;
            width: 24px;
            height: 24px;
            background-size: 100%;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: center;
            -webkit-mask-position: center;
            mask-size: contain;
            -webkit-mask-size: contain;
            background-color: var(--primary-color);
            display: block;
          }

          &.model-3d {
            &::before {
              mask-image: url("../assets/icons/3d-model.svg");
              -webkit-mask-image: url("../assets/icons/3d-model.svg");
            }
          }

          &.floor-plan {
            &::before {
              mask-image: url("../assets/icons/floor-plan.svg");
              -webkit-mask-image: url("../assets/icons/floor-plan.svg");
            }
          }

          &.vr-tour {
            &::before {
              mask-image: url("../assets/icons/vr-tour.svg");
              -webkit-mask-image: url("../assets/icons/vr-tour.svg");
            }
          }

          &.layers {
            // display: none;
            // visibility: hidden;
            // opacity: 0;
            &::before {
              mask-image: url("../assets/icons/layers.svg");
              -webkit-mask-image: url("../assets/icons/layers.svg");
            }
          }

          &.attributes {
            &::before {
              mask-image: url("../assets/icons/attributes.svg");
              -webkit-mask-image: url("../assets/icons/attributes.svg");
            }
          }

          &.google-map {
            &::before {
              mask-image: url("../assets/icons/google-map.svg");
              -webkit-mask-image: url("../assets/icons/google-map.svg");

            }
          }

          &.contact_form {
            &::before {
              mask-image: url("../assets/icons/contact.svg");
              -webkit-mask-image: url("../assets/icons/contact.svg");

            }
          }

          &.tour-info {
            &::before {
              mask-image: url("../assets/icons/tour-info.svg");
              -webkit-mask-image: url("../assets/icons/tour-info.svg");              
            }
          }

          &.mortgage-calculator {
            &::before {
              mask-image: url("../assets/icons/mortgage-calculator.svg");
              -webkit-mask-image: url("../assets/icons/mortgage-calculator.svg");
            }
          }

          &.chatbot {
            &::before {
              mask-image: url("../assets/icons/chatbot.svg");
              -webkit-mask-image: url("../assets/icons/chatbot.svg");
            }
          }

          &.share {
            &::before {
              mask-image: url("../assets/icons/share.svg");
              -webkit-mask-image: url("../assets/icons/share.svg");
            }
          }

          &.product-filter {
            &::before {
              mask-image: url("../assets/icons/product-filter.svg");
              -webkit-mask-image: url("../assets/icons/product-filter.svg");
            }
          }

          &.settings {
            margin-top: 6px;
            &::before {
              mask-image: url("../assets/icons/settings.svg");
              -webkit-mask-image: url("../assets/icons/settings.svg");
            }
          }

          &.user {
            cursor: default;

            &::before {
              mask-image: url("../assets/icons/user.svg");
              -webkit-mask-image: url("../assets/icons/user.svg");
            }
          }


          ul, .share-container {
            visibility: hidden;
            opacity: 0;
            color: var(--primary-color);
            position: absolute;
            left: $sidebar-width;
            width: $sidebar-width;
            @include blur;
            padding: $spacing-S;
            border-radius: $radius-M;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-self: baseline;
            margin-left: 16px;
            //margin-left: 0px;
            //transition: 0.35s all ease-out !important;

            &:before {
              content: '';
              position: absolute;
              left: -30px;
              top: 0px;
              width: 30px;
              height: 100%;
              display: block;
              
            }

              &.settings-container{
                align-self: end;
                padding: 8px 8px 0 8px;
                margin: -8px 0 -8px 16px;
                transition: all 0.25s ease-in-out;
              }

            &.active {
              visibility: visible;
            }

            li {
              padding-left: $spacing-S;
              display: flex;
              justify-content: space-between;

              &.layer-item{
                justify-content: flex-start;
                &::before {
                    content: "";
                    position: relative;
                    margin-right: 12px;
                    width: 20px;
                    height: 18px;
                    background-size: 100%;
                    mask-image: url("../assets/icons/layer.svg");
                    -webkit-mask-image: url("../assets/icons/layer.svg");
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: var(--primary-color);
                    display: block;
                  }
                  &.active {
                    &::before {
                      background-color: var(--main-color);
                    }
                  }
              }

              &.sub-submenu {
                margin-bottom: 7px;
              }

              &.active {
                span {
                  background-color: var(--main-color-light005);
                }
              }

              label {
                position: relative;
                height: 48px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                cursor: pointer;
                &:not(.settings-item) {
                  @include underline-1-a(8px);
                  &::after {
                  left: 0;
                  transform: translate(0%);
                }
                }
              }

              input {
                margin-right: 8px;
              }

              &::before {
                display: none;
              }

              span {
                margin-right: 8px;
                width: 24px;
                height: 24px;
                background-color: var(--primary-color-light005);
                border-radius: $radius-S;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .share-container{
            justify-content: flex-start;
            align-items: flex-start;
            cursor: default;
            transition: all 0.25s ease-in-out;
              .social-icons {
                display: flex;
                .social-item{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 40px;
                  height: 40px;
                  box-shadow: 0 0 0 1px var(--primary-color-light20);
                  border-radius: $radius-S;
                  margin: 0 16px 8px 0;
                  cursor: pointer;

                    &::after{
                      content: '';
                      display: block;
                      width: 21px;
                      height: 21px;
                      background-color: var(--primary-color);
                      mask-position: center;
                      -webkit-mask-position: center;
                      mask-repeat: no-repeat;
                      -webkit-mask-repeat: no-repeat;
                      // mask-size: contain;
                    }

                        &.facebook{
                          &::after{
                          mask-image: url("../assets/icons/facebook.svg");
                          -webkit-mask-image: url("../assets/icons/facebook.svg");
                        }
                      }
                        &.twitter{
                          &::after{
                          mask-image: url("../assets/icons/twitter.svg");
                          -webkit-mask-image: url("../assets/icons/twitter.svg");
                        }
                      }
                        &.linkedin{
                          &::after{
                          mask-image: url("../assets/icons/linkedin.svg");
                          -webkit-mask-image: url("../assets/icons/linkedin.svg");
                        }
                      }
                }
              }
              .social-link{
                display: flex;
                align-items: center;
                  input{
                    width: 215px;
                    height: 40px;
                    border-radius: $radius-S;
                    margin-right: 8px;
                    border: 1px solid var(--primary-color-light20);
                    background-color: transparent;
                  }
                  .copy{
                    width: 40px;
                    height: 40px;
                    background-color: var(--primary-color);
                    border-radius: $radius-S;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                      &::after{
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-color: var(--secondary-color);
                        mask-image: url("../assets/icons/link.svg");
                        -webkit-mask-image: url("../assets/icons/link.svg");
                        mask-repeat: no-repeat;
                        -webkit-mask-repeat: no-repeat;

                      
                      }
                  }
              }
          }
        }
      }
    }

 

    .footer {
      @include underline-1-b;
      position: absolute;
      bottom: 13.5px;
      width: 100%;
      padding: 0 8px;

      a {
        position: relative;
        @include rubik-r14;
        color: var(--main-color);
        text-decoration: none;
        margin-left: 44px;
        bottom: 10.5px;

        &:visited {
          color: var(--main-color);
        }
      }
    }


    //  -----------------------------------------------------------------------------------------------------------------------

    @media (hover: hover) and (pointer: fine) {
     
      ul {
        li {
          &:hover {
            background-color: var(--primary-color-light005);
            border-radius: $radius-S;

            ul, .share-container {
              opacity: 1;
              visibility: visible;
              transition: 0.35s all ease-in !important;
            }

            .share-container{
              .social-icons{
                .social-item{
                  &:hover{
                    box-shadow: 0 0 0 1px var(--primary-color-light60);
                  }
                }
              }
              .social-link{
                .copy{
                  &:hover{
                    background-color: var(--primary-hover);
                  }
                }
              }
            }

          }
          &.active {
            &:hover {
              background-color: var(--main-color-light10);
              border: 1px solid var(--main-color-light60);
            }
          }

          &.user {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
      }
  }

 //  ----------------POINTER-------------------------------------------------------------------------------------------------------

 @media (pointer: coarse) {
    #sidebar {
      overflow: hidden;
      .content{
        overflow: visible;
        position: relative;
        left: -296px;
        height: calc(100% - 121px);
        &.active {
          left: 0px;
        }
        ul{
          li{
            ul, .share-container{
              background-color: transparent;
              position: absolute;
              top: 0;
              transition: .25s;
              &.active {
                display: block;
                opacity: 1;
              }
            }
            .share-container {
              top: 8px;
            }
          }
        }
      }

      .footer {
        position: static;

        ul {
          li {
            ul {
              position: absolute;
              top: 66px;
              height: max-content;
              background-color: transparent;
              backdrop-filter: blur(0px) !important;
              &.active{
                left: -16px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
     
  </style>