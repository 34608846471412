<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    let settings;
    let test = [];

    let logo_loaded = false;
    let logo_data = null;

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {
        id : $id
      } 
      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
      const json = await res.json();
          
          // prevod hodnoty boolean
          const keys = Object.keys(json[0]);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                key == "enabled" && json[0][key] == "0"
            ) {
                json[0][key] = false
            }

            if (
                key == "enabled" && json[0][key] == "1"
            ) {
                json[0][key] = true
            }
            
          }

          logo_data = json[0];
          
          logo_loaded = true;

        return json;
      } else {
        throw new Error(data);
        }
  	}

    getData("logo");

    async function save_settings() {
      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(logo_data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateLogo.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia loga boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia loga neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload loga na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        formData.append("parameters", JSON.stringify(parameters));
        
        const res = await fetch(userdata['cdn_path'] + 'php/admin/saveImg.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Logo bolo úspešne uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  
                
                if (json.logo_url) {
                    logo_data.logo_url = json.logo_url;
                    
                    save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Logo nebolo uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

</script>
  
  <div id="wrapper">
    <Aside />

    {#if logo_loaded}
    <main in:fade>
      
      <h1>{$t("admin")["aside"]["components"]["logo"]}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button>
      

        <!-- odkaz loga -->
        <div class="component">
          <label>{$t("admin")["global"]["link"]}</label>
          <input type="text" bind:value={logo_data.url}>
        </div>   

        <!-- typ odkazu -->
        <div class="component">
            <label for="target">{$t("admin")["components"]["logo"]["link_target"]}</label>
            <select bind:value="{logo_data.target}">
                <option value="_blank">{$t("admin")["components"]["logo"]["new_tab"]}</option>
                <option value="_self">{$t("admin")["components"]["logo"]["actual_tab"]}</option>
            </select>
        </div>   
        
        <!-- náhľad obázka -->
        <div class="component">
            <label for="logo">{$t("admin")["components"]["logo"]["logo"]}</label> 
            <img src="{userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + logo_data.logo_url}" alt="logo" name="logo">
        </div>

         <!-- odkaz loga -->
         <div class="component">
          <label>{$t("admin")["global"]["file"]}</label>
          <input type="text" bind:value={logo_data.logo_url}>
        </div>  

        <div class="upload">
          <input id="fileupload" type="file" name="fileupload" />
          <button id="upload-button" on:click="{() => uploadFile()}">{$t("admin")["global"]["upload"]}</button>
        </div>
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>