
import { writable, derived } from 'svelte/store';

export const token = writable(localStorage.getItem('token'));

export const active_components = writable([]);
export const last_used_component = writable();

export const basket_enabled = writable(false);



