<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
    
    let map_loaded = false;
    let map_data = null;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") || (key == "filter" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
              key == "enabled" && json[0][key] == "1" || key == "filter" && json[0][key] == "1"
          ) {
              json[0][key] = true
          }
        }

        map_data = json[0];
        
        map_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("googlemap");

    async function save_settings() {
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(map_data));

      const res = await fetch(
        userdata['cdn_path'] + "/php/admin/updateMap.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia mapy boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia mapy neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

    function check_url($link) {
      if ($link.includes('src')) {
        

        let checked_url = $link.slice(
          $link.indexOf('src="') + 5,
          $link.lastIndexOf('" width'),
        );

        map_data.url = checked_url;
        

      }
      
    }
    
import { modal } from '../../store.js';
let show_modal;
modal.subscribe(value => {
  show_modal = value;
});

let Modal;
let modal_type = 'google-map';

import { onMount } from 'svelte';

const sleep = ms => new Promise(f => setTimeout(f, ms));

onMount(async () => {
  //await sleep(1000); // simulate network delay
  Modal = (await import('../../tour_components/Modal.svelte')).default
});

</script>
  
  <div id="wrapper">
    <Aside />

    {#if map_loaded}
    <main in:fade>
      <h1>{$t("admin")["aside"]["components"]["google_map"]}</h1>

      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button>

      <!-- url mapy z google maps - iframe ! -->
      <div class="component">
        <label for="url">{$t("admin")["global"]["link"]}</label>
        <input type="text" name="url" bind:value={map_data.url} on:keyup="{check_url(map_data.url)}">
      </div>  
      
      <!-- Použiť čiernobiely filter ? -->
      <div class="component">
        <label for="url">{$t("admin")['components']['map']['filter_black']}</label>
        <input type="checkbox" name="filter" bind:checked="{map_data.filter}">
      </div>  

      <div class="component">
        <iframe title="Google Maps" src="{map_data.url}" style="border: 0px;" loading="lazy" class="svelte-gbcw0h" class:filter={map_data.filter}></iframe>
      </div>
      <!-- náhľad mapy -->
      <!-- <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{$t("admin")["aside"]["components"]["google_map"]}" filter="{map_data.filter}" settings="" position="relative" url={map_data.url}/>   -->
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    iframe {
      border: 0px;
      border-radius: 16px;
      width: inherit;
      height: 500px;

      &.filter {
        filter: grayscale(1);
      }
    }
  </style>