<script>
    import { Link } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
  
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
  import { getNotificationsContext } from 'svelte-notifications';
  const { addNotification } = getNotificationsContext();
  
  export let userdata;

  let new_date;

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    new_date = yyyy +'-'+ mm +'-'+ dd;
    }

    getDate();
  
      let result;
      let selected = "user";
  
      let new_user = {
          user_name : '',
          user_password : '',
          flat_id : '',
          config_allowed : '0',
          deadline : new_date
      };
  
      let timeOut = 300;
      
      let status = "";
  
      async function create_user() {
  
          if (
              new_user.user_name != '' &&
              new_user.user_password != '' &&
              new_user.flat_id != '' &&
              new_user.config_allowed != ''
          ) {
            console.log(new_user);
              const res = await fetch( userdata['base_path'] + 'assets/php/createConfiguratorUser.php', {
                  method: "POST",
                  body: JSON.stringify(new_user),
              })
  
              if (res.ok) {
                  const json = await res.json()
                  result = JSON.stringify(json);
  
                  if (json.status == true) {
  
                      addNotification({
                          text: 'Užívateľ bol pridaný.',
                          position: 'top-center',
                          removeAfter: 4000,
                          type: 'success'
                      })
  
                      
                  }
  
                  else {
                      
                      addNotification({
                          text: 'Užívateľ nebol pridaný !',
                          position: 'top-center',
                          removeAfter: 4000,
                          type: 'danger'
                      })
                  }
                  
              } else {
                  throw new Error(data);
              }
  
          }
  
          else {
              addNotification({
                      text: 'Vyplnťe všetky povinné polia!',
                      position: 'top-center',
                      removeAfter: 4000,
                      type: 'danger'
                  })
          }
    }
  </script>
  
  <div id="wrapper">
    <Aside />
    
    <main in:fade>
      <h1>{$t("admin")["aside"]["configurator"]["add_user"]}</h1>
  
          <!-- Používateľské meno  -->
          <div class="component">
            <label for="user_name">{$t("admin")["global"]["username"]}*</label>
            <input type="text" bind:value="{new_user.user_name}" name="user_name">
          </div>
  
          <!-- Používateľské heslo  -->
          <div class="component">
            <label for="password">{$t("admin")["global"]["password"]}*</label>
            <input type="password" bind:value="{new_user.user_password}" name="password">
          </div>
  
          <!-- ID bytu  -->
          <div class="component">
            <label for="flat_id">{$t("admin")["configurator"]["users"]["flat_id"]}*</label>
            <input type="text" bind:value="{new_user.flat_id}" name="flat_id">
          </div>
  
          <!-- Povolenie konfigurátora  -->
          <div class="component">
            <label for="config_allowed">{$t("admin")["configurator"]["users"]["enable_config"]}*</label>
            <input type="radio" bind:group="{new_user.config_allowed}" value="0" name="config_allowed_true">
            <span>Nie</span>
            <input type="radio" bind:group="{new_user.config_allowed}" value="1" name="config_allowed_false">
            <span>Áno</span>
          </div>
  
          <!-- ID bytu  -->
          <div class="component">
            <label for="dedline">{$t("admin")["configurator"]["users"]["deadline"]}*</label>
            <input type="date" bind:value="{new_user.deadline}" name="dedline">
          </div>  
          <!-- Button -->
          <div class="component">
            <button on:click="{() => create_user()}">{$t("admin")["configurator"]["users"]["create_user"]}</button>
          </div>
    </main>
    <Header />
  
    </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";
  
  
  </style>
  