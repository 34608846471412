export default {
  sk: {
    admin : {
      global : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Prihlasovacie meno",
        "password" : "Heslo",
        "enabled" : "Povolené",
        "file" : "Súbor",
        "price" : "Cena",
        "save" : "Uložiť",
        "save_settings" : "Uložiť nastavenie",
        "delete" : "Zmazať",
        "edit" : "Upraviť",
        "cancel" : "Zrušiť",
        "back" : "Späť",
        "yes" : "Áno",
        "no" : "Nie",
        "upload" : "Nahrať",
        "order" : "Poradie",
        "fee" : "Poplatok",
        "copy" : "Kopírovať",
        "image" : "Obrázok",
        "description" : "Popis",
        "description_short" : "Krátky popis",
        "update" : "Aktualizovať",
        "link" : "Odkaz"


      },
      aside : {
        dashboard: {
          "headline" : "Nástenka",
          "subtitle" : "prehľad nastavenia aplikácie",
          "dashboard" : "Nástenka",
          "show_tour" : "Zobraziť tour"
        },
        projects: {
          "headline" : "Projekty",
          "subtitle" : "prehľad jednotlivých projektov",
          "projects" : "Projekty",
          "add_project" : "Pridať projekt",
          "import_projects" : "Importovať projekty"
        },
        scenes: {
          "headline" : "Nastavenia scén",
          "subtitle" : "Scény, hotspoty, patche",
          "scenes" : "Scény",
          "settings" : "Nastavenie"
        },
        components: {
          "headline" : "Správa komponentov",
          "subtitle" : "načítavané z databázy",
          "logo" : "Logo",
          "google_map" : "Google Mapa",
          "share" : "Zdielať",
          "settings" : "Nastavenia Tour",
          "floorplan" : "Floorplan",
          "shortcuts" : "Shortcuts",
          "sounds" : "Zvuky",
          "tour_info" : "Tour info *****"
        },
        configurator: {
          "headline" : "Konfigurátor",
          "subtitle" : "nastavenia konfigurátora",
          "users" : "Používatelia",
          "add_user" : "Pridať užívateľa",
          "import_users" : "Importovať užívateľov",
          "categories" : "Kategórie produktov",
          "add_category" : "Pridať kategóriu",
          "units" : "MJ Produktov",
          "add_unit" : "Pridať MJ",
          "standards" : "Štandardy produktov",
          "add_standard" : "Prídať štandard",
          "products" : "Produkty",
          "add_product" : "Pridať produkt",
          "import_products" : "Importovať produkty",
          "appartments" : "Byty *****",
          "add_appartment" : "Pridať byt *****",
          "import_appartments" : "Importovať byty *****",
          "flat_products" : "Počet produktov pre byt"
        },
        plugins: {
          "headline" : "Moduly",
          "subtitle" : "správa plug-inov",
          "merge_scenes" : "Spájanie scén"
        },
        users: {
          "headline" : "Používatelia",
          "subtitle" : "správa používateľov",
          "all_users" : "Všetci používatelia",
          "add_user" : "Pridať používateľa"
        },
        global_settings: {
          "headline" : "Nastavenia",
          "subtitle" : "globálne nastavenia prehliadky",
          "meta" : "Meta tagy"
        },
      },
      projects : {
        "generate_scenes" : "vygenerovať scény",
        "scenes_count" : "Počet scén",
        "scenes_default_name" : "Základný názov scén",
        "thumbnail_name" : "Názov thumbnailu",
        "scenes_starting_number" : "Scény začínajú číslom",
        "design_change" : "Zmena dizajnu?",
        "floor_change" : "Zmena poschodia?",
        "starting_scene" : "Štartovacia scéna",
        "create_project" : "Vytvoriť projekt"

      },
      components : {
        logo : {
          "link_target" : "Otvoriť odkaz",
          "new_tab" : "V novom tabe",
          "actual_tab" : "V aktuálnom tabe",
          "logo" : "Logo"
        },
        map : {
          "filter_black" : "Použiť filter ? (čiernobiela)"
        },
        share : {
          "enable_facebook" : "Povoliť Facebook?",
          "enable_twitter" : "Povoliť Twitter?",
          "enable_linkedin" : "Povoliť LinkedIn?"
        },
        tour_settings : {
          "enable_hotspots" : "Povoliť Hotspoty?",
          "enable_autorotate" : "Povoliť Autorotáciu?",
          "enable_ad" : "Povoliť Reklamy?",
          "enable_gyro" : "Povoliť Gyroskop?",
          "enable_vr" : "Povoliť VR?",
          "enable_sounds" : "Povoliť Zvuky?"
        },
        floorplan : {
          "add_floorplan" : "Pridať floorplan?",
          "delete_floorplan" : "Odstrániť floorplan",
          "add_pin" : "Pridať pin",
          "load_pins" : "Načítať piny",
          "select_floorplan" : "Zvoľ Floorplan?",
          "floorplan_name" : "Povoliť Zvuky?",
          "scene_tag" : "Tag Scén?",
          "image_url" : "URL obrázka",
          "delete_pin" : "Odstániť pin"
        }

      },
      configurator : {
        users : {
          "new_date_label" : "Nový dátum",
          "new_date_button" : "Nastaviť všetkým",
          "flat_id" : "ID bytu",
          "sample_flat" : "Vzorový byt",
          "realpad_id" : "Realpad ID",
          "deadline" : "Deadline",
          "client_change" : "Klientská zmena",
          "configuration" : "Konfigurácia",
          "enable_config" : "Povoliť konfiguráciu",
          "create_user" : "Vytvoriť užívateľa"
        },
        categories : {
          "create_category" : "Vytvoriť kategóriu",
          "edit_category" : "Editovať kategóriu"
        },
        units : {
          "create_unit" : "Vytvoriť mernú jednotku",
          "edit_unit" : "Editovať mernú jednotku"
        },
        standards : {
          "create_standard" : "Vytvoriť štandard",
          "edit_standard" : "Editovať štandard"
        },
        flats : {
          "create_flat" : "Vytvoriť byt",
        },
        products : {
          "patch_id" : "ID patchu",
          "category" : "Kategória",
          "bind_product" : "Zväzbený produkt",
          "bind_standard" : "Zväzbené štandary",
          "enable_walls" : "Povoliť steny",
          "units" : "MJ",
          "create_product" : "Vytvoriť produkt",
          "create_and_add" : "Vytvoriť a pridať ďalší",
          "edit_product" : "Editovať produkt",
          "enable_standards" : "Povoliť štandardy?",
          "add_variant" : "Pridať variant",
          "select_standard" : "Zvoľ štandard",
          "patch_number" : "Číslo patchu",
          "product_blur" : "Blur?"
        }
      }
    },
    frontend : {

    },
    "lang_name" : "Slovenský",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.new_scenes" : "Nastavenia scén",
    "aside.new_scenes.description" : "Scény, hotspoty, patche",
    "aside.new_scenes.config" : "Scény",
    "aside.new_scenes.settings" : "Nastavenie",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "aside.configurator" : "Konfigurátor",
    "aside.configurator.description" : "nastavenia konfigurátora",
    "aside.configurator.users" : "Používatelia",
    "aside.configurator.add-user" : "Pridať užívateľa",
    "aside.configurator.import-users" : "Importovať užívateľov",
    "aside.configurator.categories" : "Kategórie produktov",
    "aside.configurator.add-category" : "Pridať kategóriu",
    "aside.configurator.mj" : "MJ produktov",
    "aside.configurator.add-mj" : "Pridať MJ",
    "aside.configurator.products" : "Produkty",
    "aside.configurator.add-product" : "Pridať produkt",
    "aside.configurator.import-products" : "Importovať produtky",
    "aside.configurator.flats" : "Byty",
    "aside.configurator.add-flat" : "Pridať byt",
    "aside.configurator.import-flats" : "Importovať byty",
    "aside.configurator.sample-flats" : "Projekty",
    "aside.configurator.add-sample-flat" : "Pridať projekt",
    "aside.configurator.import-sample-flats" : "Importovať projekty",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "user.flat_id" : "ID bytu",
    "user.config_allowed" : "Povoliť konfiguráciu",
    "user.deadline" : "Deadline",
    "flat.add.button" : "Vytvoriť byt",
    "product.add.button" : "Vytvoriť produkt",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.floor" : "Poschodie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.settings.tiles" : "Názov CUBE obrázka",
    "scenes.settings.floor_enabled" : "Povoliť poschodia ?",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    "back" : "Späť",
    "delete" : "Odstrániť pin",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "clear" : "Vymazať dáta",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      "import" : "Importovať",
      settings : {
        "title" : "Nastavenia",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound"  : "Zvuky",
        "color" : "Farba",
        "permanently_hotspots" : "Prechodové hotspoty"
      },
      vr : {
        "exit-vr" : "Ukončiť VR",
        "exit" : "Ukončiť",
        "no" : "Nie",
        "exit-question" : "Naozaj chcete ukončiť VR?",
        "shortcuts" : "Skratky",
        "floorplan" : "Pôdorys",
        "attributes" : "Atribúty",
        "shop" : "Obchod",
        "layers" : "Vrstvy",
        "model" : "3D Model",
        "tour-info" : "Tour info",
        "settings" : "Nastavenia",
        "back" : "Späť",
        "ad" : "Reklama",
        "hotspots" : "Hotspoty",
        "sound" : "Zvuky",
        "zariadeny" : "Zariadený",
        "holobyt" : "Holobyt",
        "automatic-tour" : "Automatická Tour"
      },
      shortcuts : {
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatická tour"
      },
      tour_settings : {
        "admin-title" : "Nastavenia Tour",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound" : "Zvuky"
      },
      share : {
        "admin-title" : "Zdieľať",
        "sidebar-title" : "Zdieľať",
        "link" : "Link bol skopírovaný do schránky"
      },
      maps : {
        "admin-title" : "Google Mapa",
        "sidebar-title" : "Google Mapa",
        "url" : "Url link",
        "title" : "Google Mapa",
        "filter" : "Použiť filter ? (čiernobiela)"
      },
      sidebar_menu : {
        "sidebar-title" : "Menu",
        "sidebar-back" : "Späť",
      },
      screenshots : {
        "take-screenshot" : "Vytvoriť snímku",
        "download-screenshot" : "Uložiť snímku"
      },
      walls : {
        "animate" : "Animácia steny",
        "measure1" : "Ľubovoľné meranie",
        "measure2" : "Meranie protiľahlého bodu"
      },
      book_call : {
        "title" : "Rezervovať meeting"
      },
      tourinfo : {
        "admin-title" : "Tour info",
        "sidebar-title" : "Tour info",
        "url" : "Url link",
        "title" : "Tour info",
        "headline" : "Headline",
        "image" : "Obrázok",
        "subtitle" : "Subtitle",
        "content"  : "Content",
        "filter" : "Použiť filter ? (čiernobiela)",
        "global" : "Globálne info",
        "all" : "Vyberte globálne info"
      },
      floorplan : {
        "admin-title" : "Floorplan",
        "sidebar-title" : "Pôdorys",
        "floorplan-id" : "Zvoľ floorplan",
        "filter-by" : "Filtrovať podľa",
        "filter-by-value" : "Hodnota",
        "filter-none" : "Nefiltrovať",
        "pins-title" : "Názvy pinov",
        "upload-image" : "Obrázok",
        "import-file" : "Import pinov",
        "pin-x" : "pozícia X",
        "pin-y" : "pozícia Y",
        "pin-title" : "názov",
        "pin-target" : "Scéna",
        "add-floorplan" : "Pridať floorplan",
        "save-floorplan" : "Uloziť floorplan",
        "remove-floorplan" : "Ostrániť floorplan",
        "floorplan-title" : "Názov floorplanu",
        "add-pin" : "Pridať pin"
      },
      layers : {
        "sidebar-title" : "Vrstvy",
        "first" : "Zariadený",
        "second" : "Holobyt",
        "zar" : "Zariadený",
        "holo" : "Holobyt",
        "vara" : "Variant A",
        "varb" : "Variant B",
        "pure" : "Pure",
        "designed" : "Designed",
        "a" : "Minimal Classic white",
        "b" : "Minimal Classic green",
        "c" : "Minimal Rock white",
        "d" : "Minimal Classic pink",
        "e" : "Minimal Classic white",
        "f" : "Minimal Classic green",
        "g" : "Minimal Rock white",
        "h" : "Minimal Classic pink",
      },
      model3d : {
        "sidebar-title" : "3D Modely",
        "autorotate" : "Autorotácia",
        "select-variant" : "Zvoliť variant",
        "confirm-variant" : "Potvrdiť výber",
        "confirm-variant-confirmed" : "Výber uložený !",
        "show-dimensions" : "Zobraziť rozmery",
        "show-more" : "zobraziť viac",
        "price-prefix" : "cena za",
        "ks" : "ks",
        "st" : "ks",
        "m" : "m",
        "m2" : "m<sup>2</sup>"
      },
      tags : {
        "show-more" : "Zobraziť viac"
      },
      flat_filter : {
        "title" : "Apartmány",
        "floor" : "Poschodie",
        "all" : "všetky",
        "room-count" : "Počet izieb",
        "status" : "Dostupnosť",
        "last-visited" : "Naposledy videné",
        "reset" : "Resetovať",
        "reset-filter" : "Resetovať filter",
        "floor-size" : "Podlahová plocha",
        "price-range" : "Cena",
        "free" : "voľný",
        "sold" : "predaný",
        "reserved" : "rezervovaný",
        "pre-reserved" : "pred rezervovaný",
        "not-for-sale" : "nie je na predaj",
        "voľný" : "voľný",
        "predaný" : "predaný",
        "rezervovaný" : "rezervovaný",
        "odložený" : "odložený",
        "enter-vr" : "Vstúpiť do VR",
        "compare" : "Porovnať",
        "compare-title" : "Porovnanie bytov",
        "selected-flats" : "Zvolené byty",
        "flat-id": "ID bytu",
        "block": "Blok",
        "bathroom" : "Kúpeľňa",
        "bedroom" : "Spálňa",
        "hallway" : "Chodba",
        "kitchen" : "Kuchyňa",
        "loggia" : "Loggia",
        "room" : "Izba",
        "wc" : "WC",
        "terrace" : "Terasa",
        "balcony" : "Balkón",
        "garden" : "Záhrada",
        "usefull_area_of_the_apartment" : "Úžitková plocha bytu",
        "usefull_area_total" : "Úžitková plocha celkovo",
        "flat" : "Byt",
        "added-to-compare" : "bol pridaný do porovnania",
        "removed-to-compare" : "bol odstránený z porovnania",
        "visit-tour" : "Zobraziť prehliadku",
        "visit-floorplan" : "Zobraziť floorplan",
        "download-pdf" : "Stiahnuť PDF porovnanie",
        "rotate-to-view" : "Natočiť budovu",
        "not-found" : "Tento byt sa na danom zobrazení nenachádza",
        "reset-filter" : "Zvolený byt nespĺňa parametre filtra. Prajete si resetovať filter?",
        "yes" : "Áno",
        "no" : "Nie",
        "loading-images" : "načítavam dáta",
        "orbit" : "Orbit",
        "buildings" : "Budovy",
        "floors" : "Poschodia",
        "playground" : "Exteriér",
        "0" : "EG",
        "detail" : "Detail bytu",
        "vat" : "s dph",
        "check-price" : "strážiť cenu",
        "check-status" : "strážiť dostupnosť",
        "call-for-price" : "cena na vyžiadanie",
        "download-pdf_file" : "Stiahnuť pôdorys",
        "full-area" : "Celková plocha",
        "exterier-full" : "Výmera exteriér",
        "interier-full" : "Výmera interiér",
        "kobka" : "Info sklad",
        "kobka-info" : "Cena je uvedená vrátane skladu a kompletnej prepdprípravy na montáže klimatizácie a exteriérového elektrického žalúzia.",
        "action" : "Akcia",
        "type" : "Prevedenie",
        "show-gallery" : "Prezrieť fotogalériu"
      },
      basket : {
        "title" : "Košík",
        "reset" : "Resetovať",
        "total" : "Cena spolu",
        "download-pdf" : "Stiahnuť PDF",
        "download-disposition" : "Stiahnuť nápočet",
        "generate-pdf" : "Generovať PDF",
        "save-configuration" : "Uložiť konfiguráciu",
        "configuration-saved" : "Konfigurácia bola uložená!",
        "no-product-on-scene" : "Vybraný produkt sa na tejto scéne nenachádza",
        "change-scene" : "Ak chcete produkt konfigurovať vyberte jednu z nasledujúcich scén",
        "creating-pdf" : "Vytváram konfiguráciu. Počkajte prosím",
        "browser" : "Nezatvárajte Váš prehliadač",
        "checkout" : "Pokladňa SK",
        "quantity" : "mn:",
        "pieces" : "kusov",
        "piece" : "kus"
      },
      rotator : {
        "sidebar-title" : "3D Rotátor",
        "orbit" : "Orbit",
        "buildings" : "Budovy",
        "floors" : "Poschodia",
        "playground" : "Exteriér",
        "castle"  : "Hrad",
        "centrum" : "Centrum",
        "skypark" : "Skypark",
        "woow" : "WOOWSTUDIO"
      },
      contact_form : {
        "sidebar-title" : "Kontaktný formulár",
        "name" : "Meno",
        "name_hint" : "Meno",
        "lastname" : "Priezvisko",
        "lastname_hint" : "Priezvisko",
        "email" : "Email",
        "phone" : "Telefón",
        "phone_hint" : "Telefón",
        "message" : "Vaša správa",
        "message_hint" : "Sem napíšte správu",
        "required_fields" : "Povinné polia sú označené",
        "first_req_text" : "Prečítal som si ",
        "second_req_text" : "a súhlasím s nimi",
        "thanks" : "Ďakujeme",
        "sent" : "Správa bola odoslaná",
        "send_button" : "Odoslať",
        "op" : "Obchodné podmienky"
      },
      merge_scenes : {
        "admin-title" : "Spájanie scén",
        "base_path" : "Adresa priečinka so scénami",
        "base_suffix" : "Suffix názvu"
      },
      sounds : {
        "admin-title" : "Zvuky",
        "background_sound" : "Zvuk na pozadí",
        "enable_bg_sound" : "Zapnúť zvuk na pozadí",
        "sound" : "Zvuk",
        "play_sound" : "Prehrať zvuk",
        "remove_sound" : "Odstrániť zvuk"
      },
      preloader : {
        "loading_components" :  "načítavam komponenty",
        "loading_scenes" : "načítavam scény ...",
        "scenes_loaded" : "scény načítané",
        "loaded" : "načítané",
        "components_loaded" : "všetky komponenty načítané !",
        "patches_loading" : "načítavam produkt ..."
      },
      intro : {
        "title" : "Navigácia vo VR",
        "click-desktop" : "Kliknutím a pohybom sa rozhliadnite",
        "click-vr" : "Kliknutím a posunutím sa rozhliadnite",
        "scroll-desktop" : "Scrolujte pre priblíženie/oddialenie",
        "scroll-vr" : "Stlačením priblížte/oddiaľte",
        "continue" : "Pokračovať",
        "dont_show" : "viackrát nezobrazovať",
        "loading-tour" : "Pripravujeme váš zážitok",
        "tour-loaded" : "Načítaných"
      },
      picker : {
        "tour_title" : "Späť na VR",
        "floorplan_title" : "Späť na výber poschodia",
        "level" : "poschodie",
        "room_count" : "počet izieb",
        "orientation" : "orientácia",
        "last_visited" : "naposledy navštívené",
        "reset" : "resetovať výber",
        "empty_value" : "nezvolené",
        "back" : "Späť na výber poschodia",
        "all_floors_title" : "Vybrať poschodie"
      },
      import : {
        "scenes_count" : "Počet scén",
        "default_name" : "Default názov scény",
        "cf10" : "Tag pre slider",
        "enable_floor" : "Povoliť poschodia ? ",
        "import_data" : "Importovať dáta"
      },
      configurator : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Login",
        "password" : "Heslo",
        "flat_id" : "ID bytu",
        "sample_flat_id" : "Vzorový byt",
        "realpad_id" : "Realpad ID",
        "config_allowed" : "Povolené",
        "deadline" : "Deadline",
        "client_change" : "Klientská zmena",
        "configuration" : "Konfigurácia",
        "price" : "Cena",
        "file" : "Súbor",
        "save" : 'Uložiť',
        "set" : 'Nastaviť všetkým',
        "delete" : "Zmazať",
        "new_date_label" : "Nový dátum",
        "import-title" : "Import užívateľov",
        "yes" : "áno",
        "no" : "nie",
        "products" : "Produkty",
        "sample_flats_title" : "Projekty"
      },
      products : {
        "id" : "ID",
        "name" : "Názov",
        "category" : "Kategória",
        "patch_id" : "ID patchu",
        "title" : "Produkty",
        "import-title" : "Import produktov",
        "add-title" : "Pridať produkt",
        "product_order" : "Poradie",
        "bind" : "Zväzbený produkt",
        "bind_standard" : "Zväzbené štandardy?",
        "mj" : "MJ",
        "active_variant" : "Aktívny variant",
        "active_standard" : "Aktívny štandard",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť"
      },
      product_categories : {
        "title" : "Kategórie produktov",
        "add-category" : "Pridať kategóriu",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť kategóriu"
      },
      product_mj : {
        "title" : "MJ produktov",
        "add-mj" : "Pridať MJ",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť MJ"
      },
      flats : {
        "name" : "Názov",
        "title" : "Byty",
        "import-title" : "Import bytov",
        "add-title" : "Pridať byt"
      },
      sample_flats : {
        "id" : "ID",
        "name" : "Názov",
        "title" : "Produkty",
        "import-title" : "Import vzorových bytov",
        "add-name" : "Pridať projekt",
        "count" : "Počet scén",
        "default_name" : "Základný názov scén",
        "counter" : "Štartovacie číslo",
        "enable_design" : "Zmena dizajnu?",
        "enable_floors" : "Zmena poschodia?",
        "save" : "Uložiť",
        "delete" : "Vymazať",
        "generate" : "Vygenerovať scény",
        "thumbnail_name" : "Názov thumbnailu"
      },
      new_scenes_settings : {
        "name" : "názov",
        "enabled" : "povolené",
        "value" : "hodnota",
        "krpano_variable_name" : "Premenná krpano",
        "design" : "Dizajn",
        "floors" : "Poschodia",
        "project_url" : "Projekt url suffix",
        "scenes_path" : "Priečinok so scénami",
        "patches_path" : "Priečinok so scénami",
        "blendmode" : "Blend mode"
      },
      new_scenes : {
        "load_scene_orientation" : "načítať default view",
        "save_scene_orientation" : "nastaviť default view",
        "save_scene_settings" : "uložiť nastavenia",
        buttons : {
          
        }
      },
      dispositions: {
        "choose-dispositions" : "Vybrať dispozíciu",
        "detail"  : "Zobraziť detail",
        "enter" : "Vstúpiť",
        "price" : "cenový rozdiel",
        "client-change" : "Klientská zmena",
        "back" : "Späť",
        "pdf-documentation" : "PDF dokumentácia",
        "plus" : "Pripočítané položky",
        "minus" : "Odpočítané položky"
      },
      tutorial : {
        "title" : "Tutoriál",
        "new" : "Novinka",
        "dont-show" : "Tutoriál je možné opäť spustiť v nastaveniach (sidebar).",
        "screenshots" : {
          "title" : "Fotenie",
          "description" : "Pomocou funkcie Fotenie je možné vyhotoviť statický obrázok priamo z virtuálnej prehliadky. Môžete odfotiť celú obrazovku, alebo zvoliť niektorý zo štandardných rozmerov. Výsledný obrázok je následné možné stiahnuť do Vášho zariadenia vo formáte JPG."
        },
        "languages" : {
          "title" : "Jazyky",
          "description" : "Funkcia Jazyky slúži na prepínanie sa medzi jazykovými mutáciami"
        },
        "basket" : {
          "title" : "Košík",
          "description" : "V košíku sa nachádzajú všetky položky, ktoré boli zvolené počas konfigurácie projektu. Produkty sú zoradené v kategóriách a zahŕňaju výslednú sumu vrátane poplatkov. V košíku sa nachádza aj možnosť uložiť priebeh konfigurácie."
        },
        "shortcuts" : {
          "title" : "Skratky",
          "description" : "Pomocou funkcie Skratky sa môžete jednoducho dostať na akúkoľvek scénu, ktorá sa nachádza na prehliadke. Nie je teda nutné prechádzať celým objektom, ak sa potrebujete dostať do konkrétnej časti. V skratkách si takisto viete zapnuť funkciu Autotour, ktorá Vám prehrá všetky scény za sebou automaticky, bez nutnosti manuálneho prepínania."
        },
        "flat_filter" : {
          "title" : "Filter bytov",
          "description" : "Vo filtry bytov sa nachádzajú všetky dostupné byty vrámci celého objektu. Pomocou tejto funkcie si viete jednoducho odfiltrovať byty podľa Vašich požiadaviek. Následne sa viete presunúť do virtuálnej prehliadky konkrétneho bytu."
        },
        "sidebar" : {
          "title" : "Sidebar",
          "tooltip-title" : "Sidebar",
          "description" : "V sekcii Sidebar sa nachádzajú všetky doplnkové funkcie, ktoré obsahuje konrétna virtuálna prehliadka. Takisto sa tu náchádzajú nastavenia, vďaka kotrým si môžete virtuálnu prehliadku prispôsobiť podľa Vašich predstáv"
        },
        "floorplan" : {
          "title" : "Pôdorys",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Vďaka funkcii Pôdorys môžete vidieť dispozičné riešenie daného objektu a zároveň sa viete rýchlo presúvať na jednotlivé scény. Všetky pozície sú graficky vykreslené priamo na pôdoryse."
        },
        "layers" : {
          "title" : "Vrstvy",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Pomocou funkcie vrstvy si môžete zmeniť vzhľad virtuálnej prehliadky. Či už sa jedná o zariadenie konkrétneho bytu, alebo celkovú zmenu dizajnu."
        },
        "3d_model" : {
          "title" : "3D model",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Pomocou funkcie 3D model si viete pozrieť 3D vizualizáciu všetkých produktov, ktoré je možné konfigurovať vrámci virtuálnej prehliadky. 3D model produktu obsahuje aj jeho presné rozmery a po zvolení požadovaného variantu sa táto zmena prejaví aj na samotnej prehliadke."
        },
        "social" : {
          "title" : "Zdieľanie",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Vďaka funkcii Zdieľanie môžete jednoducho skopírovať odkaz stránky, na ktorej sa práve nachádzate, alebo zdieľať túto stránku na sociálnych sieťach a podeliť sa tak o svoj záčitok so svojími blízkymi."
        },
        "attributes" : {
          "title" : "Atribúty",
          "description" : "Funkcia atribúty slúži na konfiguráciu jednotlivých produktov podľa Vašich požiadaviek. Ak má niektorý z produktov viacero variantov, pomocou atribútov si zvolíte požadovaný materiál a zmena sa prejaví okamžite aj vo virtuálnej prehliadke."
        },
        "google_map" : {
          "title" : "Google Mapa",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Google Mapa slúži na zobrazenie presnej polohy objektu, v ktorom sa práve pohybujete."
        },
        "tour_info" : {
          "title" : "Tour info",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "V sekcii Tour info sa nachádzajú všeobecné informácie a zaujímavosti danej virtuálnej prehliadky."
        },
        "measure_1" : {
          "title" : "Ľubovoľné meranie ",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Pomocou funkcie Ľubovoľné meranie môžete zmerať akúkoľvek časť vrámci virtuálnej prehliadky. Stačí kliknúť dvakráť na mieste kde chcete začať merať, následne potiahnúť myš na miesto, kde chcete meranie ukončiť a znova kliknúť dvakrát."
        },
        "measure_2" : {
          "title" : "Protiľahlé meranie",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Pomocou funkcie Protiľahlé meranie môžete odmerať napríklad celkový rozmer izby. Stačí kliknuť 2x na akúkoľvek plochu a táto funkcia Vám automaticky dopočíta vzdialonosť protilahlej plochy."
        },
        "bookcall" : {
          "title" : "Rezervovať meeting",
          "tooltip-title" : "Súčasť sidebaru",
          "description" : "Vďaka funkcii Rezervovať meeting si viete dohodnúť videohovor s manažérom zodpovedným za konkrétny projekt. Pomocou kalendára si viete jednoducho pozrieť voľný termín a zarezervovať sa na presný čas. Manažér Vás bude následne kontaktovať."
        },
        "pdf" : {
          "title" : "Vytvoriť PDF",
          "tooltip-title" : "Súčasť košíka",
          "description" : "Pomocou funcie vytvoriť PDF sie viete jednoducho vygenerovať PDF súbor s doplnkovými informáciami a kompletným výpisom produktov, ktoré ste si zvolili počas konfigurácie bytu."
        },
        "smooth_transition" : {
          "title" : "Plynulý prechod",
          "description" : "Funkcia plynulého prechodu dodáva virtuálnej prehliadke ešte lepší užívateľský zážitok. V prehliadke sa nenachádzajú žiadne prechodové hotspoty. Presúvate sa teda kliknutím na časť podlahy a následne sa presuniete na na toto miesto. Ak si predsa želáte tieto prechodové hotspoty vidieť, môžete si ich zapnúť v nastaveniach virtuálnej prehliadky."
        },
        

      },
      otacadlo:  {
        "floors" : "Poschodia",
        "buildings" : "Budovy",
        "orbit" : "Orbit",
        "playground" : "Exteriér",
        "exterier" : "Exteriér",
        "skypark" : "Skypark",
        "castle" : "Hrad",
        "centrum" : "Centrum",
        "woowstudio" : "Woowstudio",
        "visit-tour" : "Vstúpiť do VR",
        "lobby" : "Lobby",
        "office-type" : "One office",
        "EVELYN" : "EVELYN",
        "ANGELA" : "ANGELA",
        "more-info" : "Viac informácií",
        "interier" : "Interiér"
      },
      tooltips : {
        "flat-filter" : "Filter bytov",
        "sidebar" : "Menu",
        "shortcuts" : "Skratky",
        "screenshot" : "Fotenie",
        "back-to-rotator" : "Späť na rotátor"
      }
    },
    admin_components : {
      settings : {
        title : "Nastavenia",
        save_button : "Uloziť nastavenia"
      },
      clear_data : {
        "admin_title" : "Vymazať dáta"
      },
      import_data : {
        "admin_title" : "Importovať dáta"
      },
      new_scenes : {
        "admin_title" : "Nastavenie scén"
      }
    }
  },
  de: {
    admin : {
      global : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Prihlasovacie meno",
        "password" : "Heslo",
        "enabled" : "Povolené",
        "file" : "Súbor",
        "price" : "Cena",
        "save" : "Uložiť",
        "save_settings" : "Uložiť nastavenie",
        "delete" : "Zmazať",
        "edit" : "Upraviť",
        "cancel" : "Zrušiť",
        "back" : "Späť",
        "yes" : "Áno",
        "no" : "Nie",
        "upload" : "Nahrať",
        "order" : "Poradie",
        "fee" : "Poplatok",
        "copy" : "Kopírovať",
        "image" : "Obrázok",
        "description" : "Popis",
        "description_short" : "Krátky popis",
        "update" : "Aktualizovať",
        "link" : "Odkaz"


      },
      aside : {
        dashboard: {
          "headline" : "Nástenka",
          "subtitle" : "prehľad nastavenia aplikácie",
          "dashboard" : "Nástenka",
          "show_tour" : "Zobraziť tour"
        },
        projects: {
          "headline" : "Projekty",
          "subtitle" : "prehľad jednotlivých projektov",
          "projects" : "Projekty",
          "add_project" : "Pridať projekt",
          "import_projects" : "Importovať projekty"
        },
        scenes: {
          "headline" : "Nastavenia scén",
          "subtitle" : "Scény, hotspoty, patche",
          "scenes" : "Scény",
          "settings" : "Nastavenie"
        },
        components: {
          "headline" : "Správa komponentov",
          "subtitle" : "načítavané z databázy",
          "logo" : "Logo",
          "google_map" : "Google Mapa",
          "share" : "Zdielať",
          "settings" : "Nastavenia Tour",
          "floorplan" : "Floorplan",
          "shortcuts" : "Shortcuts",
          "sounds" : "Zvuky",
          "tour_info" : "Tour info *****"
        },
        configurator: {
          "headline" : "Konfigurátor",
          "subtitle" : "nastavenia konfigurátora",
          "users" : "Používatelia",
          "add_user" : "Pridať užívateľa",
          "import_users" : "Importovať užívateľov",
          "categories" : "Kategórie produktov",
          "add_category" : "Pridať kategóriu",
          "units" : "MJ Produktov",
          "add_unit" : "Pridať MJ",
          "standards" : "Štandardy produktov",
          "add_standard" : "Prídať štandard",
          "products" : "Produkty",
          "add_product" : "Pridať produkt",
          "import_products" : "Importovať produkty",
          "appartments" : "Byty *****",
          "add_appartment" : "Pridať byt *****",
          "import_appartments" : "Importovať byty *****",
          "flat_products" : "Počet produktov pre byt"
        },
        plugins: {
          "headline" : "Moduly",
          "subtitle" : "správa plug-inov",
          "merge_scenes" : "Spájanie scén"
        },
        users: {
          "headline" : "Používatelia",
          "subtitle" : "správa používateľov",
          "all_users" : "Všetci používatelia",
          "add_user" : "Pridať používateľa"
        },
        global_settings: {
          "headline" : "Nastavenia",
          "subtitle" : "globálne nastavenia prehliadky",
          "meta" : "Meta tagy"
        },
        rotator : {
          "title" : "Rotátor / Otáčadlo"
        }
      },
      projects : {
        "generate_scenes" : "vygenerovať scény",
        "scenes_count" : "Počet scén",
        "scenes_default_name" : "Základný názov scén",
        "thumbnail_name" : "Názov thumbnailu",
        "scenes_starting_number" : "Scény začínajú číslom",
        "design_change" : "Zmena dizajnu?",
        "floor_change" : "Zmena poschodia?",
        "starting_scene" : "Štartovacia scéna",
        "create_project" : "Vytvoriť projekt"

      },
      components : {
        logo : {
          "link_target" : "Otvoriť odkaz",
          "new_tab" : "V novom tabe",
          "actual_tab" : "V aktuálnom tabe",
          "logo" : "Logo"
        },
        map : {
          "filter_black" : "Použiť filter ? (čiernobiela)"
        },
        share : {
          "enable_facebook" : "Povoliť Facebook?",
          "enable_twitter" : "Povoliť Twitter?",
          "enable_linkedin" : "Povoliť LinkedIn?"
        },
        tour_settings : {
          "enable_hotspots" : "Povoliť Hotspoty?",
          "enable_autorotate" : "Povoliť Autorotáciu?",
          "enable_ad" : "Povoliť Reklamy?",
          "enable_gyro" : "Povoliť Gyroskop?",
          "enable_vr" : "Povoliť VR?",
          "enable_sounds" : "Povoliť Zvuky?"
        },
        floorplan : {
          "add_floorplan" : "Pridať floorplan?",
          "delete_floorplan" : "Odstrániť floorplan",
          "add_pin" : "Pridať pin",
          "load_pins" : "Načítať piny",
          "select_floorplan" : "Zvoľ Floorplan?",
          "floorplan_name" : "Povoliť Zvuky?",
          "scene_tag" : "Tag Scén?",
          "image_url" : "URL obrázka",
          "delete_pin" : "Odstániť pin"
        }

      },
      configurator : {
        users : {
          "new_date_label" : "Nový dátum",
          "new_date_button" : "Nastaviť všetkým",
          "flat_id" : "ID bytu",
          "sample_flat" : "Vzorový byt",
          "realpad_id" : "Realpad ID",
          "deadline" : "Deadline",
          "client_change" : "Klientská zmena",
          "configuration" : "Konfigurácia",
          "enable_config" : "Povoliť konfiguráciu",
          "create_user" : "Vytvoriť užívateľa"
        },
        categories : {
          "create_category" : "Vytvoriť kategóriu",
          "edit_category" : "Editovať kategóriu"
        },
        units : {
          "create_unit" : "Vytvoriť mernú jednotku",
          "edit_unit" : "Editovať mernú jednotku"
        },
        standards : {
          "create_standard" : "Vytvoriť štandard",
          "edit_standard" : "Editovať štandard"
        },
        flats : {
          "create_flat" : "Vytvoriť byt",
        },
        products : {
          "patch_id" : "ID patchu",
          "category" : "Kategória",
          "bind_product" : "Zväzbený produkt",
          "bind_standard" : "Zväzbené štandary",
          "enable_walls" : "Povoliť steny",
          "units" : "MJ",
          "create_product" : "Vytvoriť produkt",
          "create_and_add" : "Vytvoriť a pridať ďalší",
          "edit_product" : "Editovať produkt",
          "enable_standards" : "Povoliť štandardy?",
          "add_variant" : "Pridať variant",
          "select_standard" : "Zvoľ štandard",
          "patch_number" : "Číslo patchu"
        }
      }
    },
    frontend : {

    },
    "lang_name" : "Deutsch",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.new_scenes" : "Nastavenia scén",
    "aside.new_scenes.description" : "Scény, hotspoty, patche",
    "aside.new_scenes.config" : "Scény",
    "aside.new_scenes.settings" : "Nastavenie",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "aside.configurator" : "Konfigurátor",
    "aside.configurator.description" : "nastavenia konfigurátora",
    "aside.configurator.users" : "Používatelia",
    "aside.configurator.add-user" : "Pridať užívateľa",
    "aside.configurator.import-users" : "Importovať užívateľov",
    "aside.configurator.categories" : "Kategórie produktov",
    "aside.configurator.add-category" : "Pridať kategóriu",
    "aside.configurator.mj" : "MJ produktov",
    "aside.configurator.add-mj" : "Pridať MJ",
    "aside.configurator.products" : "Produkty",
    "aside.configurator.add-product" : "Pridať produkt",
    "aside.configurator.import-products" : "Importovať produtky",
    "aside.configurator.flats" : "Byty",
    "aside.configurator.add-flat" : "Pridať byt",
    "aside.configurator.import-flats" : "Importovať byty",
    "aside.configurator.sample-flats" : "Projekty",
    "aside.configurator.add-sample-flat" : "Pridať projekt",
    "aside.configurator.import-sample-flats" : "Importovať projekty",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "user.flat_id" : "ID bytu",
    "user.config_allowed" : "Povoliť konfiguráciu",
    "user.deadline" : "Deadline",
    "flat.add.button" : "Vytvoriť byt",
    "product.add.button" : "Vytvoriť produkt",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.floor" : "Poschodie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.settings.tiles" : "Názov CUBE obrázka",
    "scenes.settings.floor_enabled" : "Povoliť poschodia ?",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    "back" : "Späť",
    "delete" : "Odstrániť pin",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "clear" : "Vymazať dáta",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      "import" : "Importovať",
      settings : {
        "intro-title" : "Einstellungen",
        "title" : "Einstellungen",
        "show_hotspots" : "Hotspots zeigen",
        "autorotate" : "Autorotation",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mode",
        "sound"  : "Geräusche",
        "color" : "Farba",
        "permanently_hotspots" : "Übergangs-Hotspots"
      },
      vr : {
        "intro-title" : "VR",
        "exit-vr" : "Beenden",
        "exit" : "Beenden",
        "no" : "Nein",
        "exit-question" : "Möchten Sie VR beenden?",
        "shortcuts" : "Abkürzungen",
        "floorplan" : "Grundriss",
        "attributes" : "Attribute",
        "shop" : "Warenkorb",
        "layers" : "Schichte",
        "model" : "3D Model",
        "tour-info" : "Tour info",
        "settings" : "Einstellungen",
        "back" : "Zurrück",
        "ad" : "Werbung",
        "hotspots" : "Hotspots",
        "sound" : "Töne",
        "zariadeny" : "Möbliert",
        "holobyt" : "Unmöbliert",
        "automatic-tour" : "Automatische Tour"
      },
      shortcuts : {
        "intro-title" : "Shortcuts",
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatik tour"
      },
      tour_settings : {
        "admin-title" : "Nastavenia Tour",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound" : "Zvuky"
      },
      share : {
        "intro-title" : "Teilen",
        "admin-title" : "Zdieľať",
        "sidebar-title" : "Teilen",
        "link" : "Der Link wurde in die Zwischenablage kopiert"
      },
      maps : {
        "intro-title" : "Google Maps",
        "admin-title" : "Google Mapa",
        "sidebar-title" : "Google Maps",
        "url" : "Url link",
        "title" : "Google Maps",
        "filter" : "Použiť filter ? (čiernobiela)"
      },
      tourinfo : {
        "intro-title" : "Infos zur Tour",
        "admin-title" : "Tour info",
        "sidebar-title" : "Infos zur Tour",
        "url" : "Url link",
        "title" : "Tour info",
        "headline" : "Headline",
        "image" : "Obrázok",
        "subtitle" : "Subtitle",
        "content"  : "Content",
        "filter" : "Použiť filter ? (čiernobiela)",
        "global" : "Globálne info",
        "all" : "Vyberte globálne info"
      },
      floorplan : {
        "intro-title" : "Grundriss",
        "admin-title" : "Floorplan",
        "sidebar-title" : "Grundriss",
        "floorplan-id" : "Zvoľ floorplan",
        "filter-by" : "Filtrovať podľa",
        "filter-by-value" : "Hodnota",
        "filter-none" : "Nefiltrovať",
        "pins-title" : "Názvy pinov",
        "upload-image" : "Obrázok",
        "import-file" : "Import pinov",
        "pin-x" : "pozícia X",
        "pin-y" : "pozícia Y",
        "pin-title" : "názov",
        "pin-target" : "Scéna",
        "add-floorplan" : "Pridať floorplan",
        "save-floorplan" : "Uloziť floorplan",
        "remove-floorplan" : "Ostrániť floorplan",
        "floorplan-title" : "Názov floorplanu",
        "add-pin" : "Pridať pin"
      },
      layers : {
        "intro-title" : "Schichte",
        "sidebar-title" : "Schichte",
        "first" : "Möbliert",
        "second" : "Unmöbliert",
        "zar" : "Möbliert",
        "holo" : "Unmöbliert",
        "vara" : "Variant A",
        "varb" : "Variant B",
        "pure" : "Pure",
        "designed" : "Designed",
        "a" : "Minimal Classic white",
        "b" : "Minimal Classic green",
        "c" : "Minimal Rock white",
        "d" : "Minimal Classic pink",
        "e" : "Minimal Classic white",
        "f" : "Minimal Classic green",
        "g" : "Minimal Rock white",
        "h" : "Minimal Classic pink",
      },
      model3d : {
        "intro-title" : "3D-Modelle",
        "sidebar-title" : "3D-Modelle",
        "autorotate" : "Autorotation",
        "select-variant" : "Variante auswählen",
        "confirm-variant" : "Variante bestätigen",
        "confirm-variant-confirmed" : "Variante gespeichert !",
        "show-dimensions" : "Maße anzeigen",
        "show-more" : "Zeigen mehr",
        "price-prefix" : "Preis für",
        "ks" : "stücke",
        "st" : "stücke",
        "m" : "m",
        "m2" : "m<sup>2</sup>"
      },
      tags : {
        "show-more" : "Zeigen mehr"
      },
      flat_filter : {
        "intro-title" : "Wohnungen",
        "title" : "Wohnungen",
        "floor" : "Geschoß",
        "all" : "Alles",
        "room-count" : "Zimmer",
        "status" : "Status",
        "last-visited" : "Zuletzt gesehen",
        "reset" : "Reset",
        "reset-filter" : "Reset Filterparametern",
        "floor-size" : "Grundfläche",
        "price-range" : "Preiss",
        "free" : "frei",
        "sold" : "verkauft",
        "reserved" : "reserviert",
        "pre-reserved" : "vorgebucht",
        "not-for-sale" : "unverkäuflich",
        "voľný" : "frei",
        "predaný" : "verkauft",
        "rezervovaný" : "reserviert",
        "odložený" : "verschoben",
        "enter-vr" : "Rundgang starten",
        "compare" : "Vergleichen",
        "compare-title" : "Wohnungen vergleichen",
        "selected-flats" : "ausgewählte Wohnungen",
        "flat-id": "Wohnung ID",
        "block": "Block",
        "bathroom" : "Badezimmer",
        "bedroom" : "Schlafzimmer",
        "hallway" : "Flur",
        "kitchen" : "Küche",
        "loggia" : "Loggia",
        "room" : "Zimmer",
        "wc" : "WC",
        "terrace" : "Terrasee",
        "balcony" : "Balkon",
        "garden" : "Garten",
        "usefull_area_of_the_apartment" : "Nutzfläche der Wohnung",
        "usefull_area_total" : "Gesamtnutzfläche",
        "flat" : "Wohnung",
        "added-to-compare" : "wurde dem Vergleich hinzugefügt",
        "removed-to-compare" : "wurde aus dem Vergleich entfernt",
        "visit-tour" : "Tour ansehen",
        "visit-floorplan" : "Grundriss ansehen",
        "download-pdf" : "PDF-Vergleich herunterladen",
        "rotate-to-view" : "Gebäude drehen",
        "not-found" : "Diese Wohnung befindet sich auf der anderen Seite des Gebäudes.",
        "reset-filter" : "Die ausgewählte Wohnung entspricht nicht den Filterparametern. Möchten Sie den Filter zurücksetzen?",
        "yes" : "Ja",
        "no" : "Nein",
        "loading-images" : "Daten werden geladen",
        "orbit" : "Umgebung",
        "buildings" : "Gebäude",
        "floors" : "Geschoßplan",
        "playground" : "Spielplatz",
        "0" : "EG",
        "detail" : "Detail der Wohnung",
        "vat" : "mit Steuern",
        "check-price" : "bewache den Preis",
        "check-status" : "bewache Status",
        "call-for-price" : "Preis auf Anfrage",
        "download-pdf_file" : "Stiahnuť kartu bytu",
        "full-area" : "Celková plocha",
        "exterier-full" : "Výmera exteriér",
        "interier-full" : "Výmera interiér",
        "kobka" : "Kobka",
        "kobka-info" : "Ceny sú ovedené vrátane skladu, kompletnej predprípravy pre montáž klimatizácie a exteriérové elektrické žalúzie.",
      },
      basket : {
        "intro-title" : "Einkaufskorb",
        "title" : "Einkaufskorb",
        "reset" : "Reset",
        "total" : "Preiss total",
        "download-pdf" : "Pdf herunterladen",
        "download-disposition" : "Disposition herunterladen",
        "generate-pdf" : "Pdf generieren",
        "save-configuration" : "Speichern der Konfiguration",
        "saving-configuration" : "Ich speichere die Konfiguration",
        "configuration-saved" : "Konfiguration gespeichert!",
        "no-product-on-scene" : "Das ausgewählte Produkt befindet sich nicht in dieser Szene",
        "change-scene" : "Wenn Sie das Produkt konfigurieren möchten, wählen Sie eine der folgenden Szenen aus",
        "creating-pdf" : "Die Konfiguration wird erstellt. Warte bitte",
        "browser" : "Schließen Sie Ihren Browser nicht",
        "checkout" : "Kasse",
        "quantity" : "menge:",
        "piece" : "stück",
        "pieces" : "stücke"
      },
      rotator : {
        "sidebar-title" : "3D Rotator",
        "orbit" : "Umgebung",
        "buildings" : "Gebäude",
        "buildings-soley" : "zur Detailansicht",
        "floors" : "Geschoßplan",
        "playground" : "Außen",
        "castle"  : "Schloss",
        "centrum" : "Center",
        "skypark" : "Skypark",
        "woow" : "WOOWSTUDIO"
      },
      contact_form : {
        "intro-title" : "Kontakt Formular",
        "sidebar-title" : "Kontakt Formular",
        "name" : "Name",
        "name_hint" : "Name",
        "lastname" : "Nachname",
        "lastname_hint" : "Nachname",
        "email" : "Email",
        "phone" : "Telefon",
        "phone_hint" : "Telefon",
        "message" : "Ihre Nachricht",
        "message_hint" : "schreibe hier eine Nachricht",
        "required_fields" : "Pflichtfelder sind markiert",
        "first_req_text" : "Ich habe die",
        "second_req_text" : "gelesen und akzeptiere sie",
        "thanks" : "Danke",
        "sent" : "Nachricht wurde gesendet!",
        "send_button" : "Senden",
        "op" : "AGB"
      },
      sidebar_menu : {
        "sidebar-title" : "Menü",
        "sidebar-back" : "Zurück"
      },
      screenshots : {
        "intro-title" : "Screenshot",
        "take-screenshot" : "Einen Screenshot machen",
        "download-screenshot" : "Screenshot herunterladen"
      },
      walls : {
        "animate" : "Animierte Wand",
        "measure1" : "Messungen Punkten",
        "measure2" : "Messungen Flächen"
      },
      book_call : {
        "title" : "Anruf buchen"
      },
      merge_scenes : {
        "admin-title" : "Spájanie scén",
        "base_path" : "Adresa priečinka so scénami",
        "base_suffix" : "Suffix názvu"
      },
      sounds : {
        "admin-title" : "Zvuky",
        "background_sound" : "Zvuk na pozadí",
        "enable_bg_sound" : "Zapnúť zvuk na pozadí",
        "sound" : "Zvuk",
        "play_sound" : "Prehrať zvuk",
        "remove_sound" : "Odstrániť zvuk"
      },
      preloader : {
        "loading_components" :  "Komponenten laden",
        "loading_scenes" : "načítavam scény ...",
        "scenes_loaded" : "scény načítané",
        "loaded" : "načítané",
        "components_loaded" : "alle Komponenten geladen !",
        "patches_loading" : "Produkt laden ..."
      },
      intro : {
        "title" : "Navigácia vo VR",
        "click-desktop" : "Kliknutím a pohybom sa rozhliadnite",
        "click-vr" : "Kliknutím a posunutím sa rozhliadnite",
        "scroll-desktop" : "Scrolujte pre priblíženie/oddialenie",
        "scroll-vr" : "Stlačením priblížte/oddiaľte",
        "continue" : "Pokračovať",
        "dont_show" : "nicht mehr anzeigen",
        "loading-tour" : "Wir bereiten Ihr Erlebnis vor",
        "tour-loaded" : "Geladen"
      },
      picker : {
        "tour_title" : "Späť na VR",
        "floorplan_title" : "Späť na výber poschodia",
        "level" : "poschodie",
        "room_count" : "počet izieb",
        "orientation" : "orientácia",
        "last_visited" : "naposledy navštívené",
        "reset" : "resetovať výber",
        "empty_value" : "nezvolené",
        "back" : "Späť na výber poschodia",
        "all_floors_title" : "Vybrať poschodie"
      },
      import : {
        "scenes_count" : "Počet scén",
        "default_name" : "Default názov scény",
        "cf10" : "Tag pre slider",
        "enable_floor" : "Povoliť poschodia ? ",
        "import_data" : "Importovať dáta"
      },
      configurator : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Login",
        "password" : "Heslo",
        "flat_id" : "ID bytu",
        "sample_flat_id" : "Vzorový byt",
        "realpad_id" : "Realpad ID",
        "config_allowed" : "Povolené",
        "deadline" : "Deadline",
        "client_change" : "Klientská zmena",
        "configuration" : "Konfigurácia",
        "price" : "Cena",
        "file" : "Súbor",
        "save" : 'Uložiť',
        "set" : 'Nastaviť všetkým',
        "delete" : "Zmazať",
        "new_date_label" : "Nový dátum",
        "import-title" : "Import užívateľov",
        "yes" : "áno",
        "no" : "nie",
        "products" : "Produkty",
        "sample_flats_title" : "Projekty"
      },
      products : {
        "id" : "ID",
        "name" : "Názov",
        "category" : "Kategória",
        "patch_id" : "ID patchu",
        "title" : "Produkty",
        "import-title" : "Import produktov",
        "add-title" : "Pridať produkt",
        "product_order" : "Poradie",
        "bind" : "Zväzbený produkt",
        "bind_standard" : "Zväzbené štandardy?",
        "mj" : "MJ",
        "active_variant" : "Aktívny variant",
        "active_standard" : "Aktívny štandard",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť"
      },
      product_categories : {
        "title" : "Kategórie produktov",
        "add-category" : "Pridať kategóriu",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť kategóriu"
      },
      product_mj : {
        "title" : "MJ produktov",
        "add-mj" : "Pridať MJ",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť MJ"
      },
      flats : {
        "name" : "Názov",
        "title" : "Byty",
        "import-title" : "Import bytov",
        "add-title" : "Pridať byt"
      },
      sample_flats : {
        "id" : "ID",
        "name" : "Názov",
        "title" : "Produkty",
        "import-title" : "Import vzorových bytov",
        "add-name" : "Pridať projekt",
        "count" : "Počet scén",
        "default_name" : "Základný názov scén",
        "counter" : "Štartovacie číslo",
        "enable_design" : "Zmena dizajnu?",
        "enable_floors" : "Zmena poschodia?",
        "save" : "Uložiť",
        "delete" : "Vymazať",
        "generate" : "Vygenerovať scény",
        "thumbnail_name" : "Názov thumbnailu"
      },
      new_scenes_settings : {
        "name" : "názov",
        "enabled" : "povolené",
        "value" : "hodnota",
        "krpano_variable_name" : "Premenná krpano",
        "design" : "Dizajn",
        "floors" : "Poschodia",
        "project_url" : "Projekt url suffix",
        "scenes_path" : "Priečinok so scénami",
        "patches_path" : "Priečinok so scénami",
        "blendmode" : "Blend mode"
      },
      new_scenes : {
        "load_scene_orientation" : "načítať default view",
        "save_scene_orientation" : "nastaviť default view",
        "save_scene_settings" : "uložiť nastavenia",
        buttons : {
          
        }
      },
      dispositions: {
        "choose-dispositions" : "Layout auswählen",
        "detail"  : "Detail anzeigen",
        "enter" : "Betreten",
        "price" : "preisunterschied",
        "client-change" : "Kundenwechsel",
        "back" : "Zurück",
        "pdf-documentation" : "PDF-Dokumentation",
        "plus" : "Artikel hinzugefügt",
        "minus" : "Artikel abgezogent"
      },
      tutorial : {
        "title" : "Lernprogramm",
        "new" : "Neuheit",
        "dont-show" : "Das Tutorial kann in den Einstellungen (Sidebar) erneut gestartet werden.",
        "screenshots" : {
          "title" : "Screenshots",
          "description" : "Mithilfe der Funktion Screenshot ist es möglich, ein statisches Bild direkt aus der virtuellen Tour heraus aufzunehmen. Sie können den gesamten Bildschirm erfassen oder einen der Standardgrößen auswählen. Das resultierende Bild kann anschließend im JPG-Format auf Ihr Gerät heruntergeladen werden.",
        },
        "languages" : {
          "title" : "Sprachen",
          "description" : "Die Funktion Sprachen dient dem Wechsel zwischen verschiedenen Sprachvarianten oder Sprachen.",
        },
        "basket" : {
          "title" : "Warenkorb",
          "description" : "Im Warenkorb befinden sich alle ausgewählten Elemente während der Konfiguration des Projekts. Die Produkte sind in Kategorien sortiert und umfassen den Gesamtbetrag einschließlich der Gebühren. Im Warenkorb gibt es auch die Möglichkeit, den Konfigurationsverlauf zu speichern.",
        },
        "shortcuts" : {
          "title" : "Abkurtzen",
          "description" : "Mithilfe der Funktion Abkürzungen können Sie ganz einfach zu jeder Szene in der Tour gelangen, ohne den gesamten Bereich durchgehen zu müssen, wenn Sie zu einem bestimmten Teil gelangen möchten. In den Abkürzungen können Sie auch die Autotour-Funktion aktivieren, die Ihnen alle Szenen automatisch nacheinander abspielt, ohne manuelles Umschalten.",
        },
        "flat_filter" : {
          "title" : "Wohnungensfilter",
          "description" : "Wohnungsfilter. In diesem Kontext handelt es sich um eine Funktion, mit der Sie alle verfügbaren Wohnungen in einem Gebäude anzeigen können. Mit dieser Funktion können Sie Wohnungen nach Ihren Anforderungen filtern und dann in die virtuelle Tour einer bestimmten Wohnung wechseln.",
        },
        "sidebar" : {
          "title" : "Sidebar",
          "tooltip-title" : "Sidebar",
          "description" : "In der Sektion Sidebar befinden sich alle zusätzlichen Funktionen, die in der jeweiligen virtuellen Tour verfügbar sind. Hier finden Sie auch Einstellungen, mit denen Sie die virtuelle Tour nach Ihren Vorstellungen anpassen können.",
        },
        "floorplan" : {
          "title" : "Grundriss",
          "tooltip-title" : "in der Sidebar",
          "description" : "Dank der Funktion Grundriss können Sie die Raumdisposition des jeweiligen Objekts sehen und gleichzeitig schnell zwischen einzelnen Szenen wechseln. Alle Positionen sind grafisch direkt auf dem Grundriss dargestellt.",
        },
        "layers" : {
          "title" : "Schiche",
          "tooltip-title" : "in der Sidebar",
          "description" : "Mithilfe der Funktion Schichten können Sie das Erscheinungsbild der virtuellen Tour ändern. Dies kann sich auf die Ausstattung einer bestimmten Wohnung oder auf eine allgemeine Änderung des Designs beziehen.",
        },
        "3d_model" : {
          "title" : "3D modell",
          "tooltip-title" : "in der Sidebar",
          "description" : "Mithilfe der Funktion 3D-Modell können Sie sich eine 3D-Visualisierung aller Produkte ansehen, die innerhalb der virtuellen Tour konfiguriert werden können. Das 3D-Modell des Produkts enthält auch seine genauen Abmessungen, und nachdem Sie die gewünschte Variante ausgewählt haben, wird diese Änderung auch in der virtuellen Tour sichtbar.",
        },
        "social" : {
          "title" : "Teilen",
          "tooltip-title" : "in der Sidebar",
          "description" : "Dank der Funktion Teilen können Sie ganz einfach den Link der Seite, auf der Sie sich gerade befinden, kopieren oder diese Seite in sozialen Netzwerken teilen und so Ihr Erlebnis mit Ihren Freunden und Kontakten teilen.",
        },
        "attributes" : {
          "title" : "Attributes",
          "description" : "Die Funktion Attribute dient dazu, die einzelnen Produkte gemäß Ihren Anforderungen zu konfigurieren. Wenn ein Produkt mehrere Varianten hat, können Sie mithilfe von Attributen das gewünschte Material auswählen, und die Änderung wird sich sofort in der virtuellen Tour widerspiegeln.",
        },
        "google_map" : {
          "title" : "Google Mappe",
          "tooltip-title" : "in der Sidebar",
          "description" : "Google Maps wird verwendet, um den genauen Standort eines Objekts, in dem Sie sich gerade befinden, anzuzeigen. Es handelt sich um eine Anwendung, die Standortdaten und Kartendienste nutzt, um Benutzern zu ermöglichen, ihre aktuelle Position zu ermitteln und Orte auf der Karte zu finden. Sie bietet auch Routenplanung und Navigationsoptionen.",
        },
        "tour_info" : {
          "title" : "Tour info",
          "tooltip-title" : "in der Sidebar",
          "description" : "In der Sektion Tour info befinden sich allgemeine Informationen und interessante Fakten über die jeweilige virtuelle Tour.",
        },
        "measure_1" : {
          "title" : "Beliebige Messung",
          "tooltip-title" : "in der Sidebar",
          "description" : "Dank der Funktion Beliebige Messung können Sie jeden beliebigen Teil der virtuellen Tour vermessen. Sie müssen nur zweimal auf die Stelle klicken, an der Sie die Messung beginnen möchten, dann die Maus an die Stelle ziehen, an der Sie die Messung beenden möchten, und erneut zweimal klicken. Dadurch können Sie die Länge oder Entfernung zwischen den beiden Punkten in der virtuellen Tour messen.",
        },
        "Gegenseitige Messung" : {
          "title" : "Measure 2",
          "tooltip-title" : "in der Sidebar",
          "description" : "Dank der Funktion Gegenseitige Messung können Sie beispielsweise die Gesamtabmessungen eines Raums messen. Sie müssen nur zweimal auf eine beliebige Oberfläche klicken, und diese Funktion berechnet automatisch die Entfernung zur gegenüberliegenden Fläche. Dies ermöglicht es Ihnen, die Diagonale oder den Gesamtdurchmesser eines Raums oder einer Fläche in der virtuellen Tour zu messen.",
        },
        "bookcall" : {
          "title" : "Meeting buchen",
          "tooltip-title" : "in der Sidebar",
          "description" : "Dank der Funktion Meeting buchen können Sie einen Videoanruf mit dem für ein bestimmtes Projekt verantwortlichen Manager vereinbaren. Mit dem Kalender können Sie sich ganz einfach verfügbare Termine ansehen und sich für eine genaue Uhrzeit reservieren. Der Manager wird sich dann mit Ihnen in Verbindung setzen.",
        },
        "pdf" : {
          "title" : "PDF erstellen",
          "tooltip-title" : "in der Warenkorb",
          "description" : "Dank der Funktion PDF erstellen können Sie einfach ein PDF-Dokument erstellen, das zusätzliche Informationen und eine vollständige Liste der Produkte enthält, die Sie während der Konfiguration der Wohnung ausgewählt haben. Dies ermöglicht es Ihnen, alle relevanten Daten und Informationen in einem PDF-Dokument zu speichern oder auszudrucken.",
        },
        "smooth_transition" : {
          "title" : "Sanfter Übergang",
          "description" : "Die Funktion des sanften Übergangs verbessert das Benutzererlebnis in der virtuellen Tour noch weiter. In der Tour sind keine Übergangs-Hotspots vorhanden. Sie bewegen sich daher, indem Sie auf einen Teil des Bodens klicken, und werden dann an diesen Ort versetzt. Wenn Sie jedoch diese Übergangs-Hotspots sehen möchten, können Sie sie in den Einstellungen der virtuellen Tour aktivieren.",
        },
        

      },
      otacadlo:  {
        "floors" : "Geschoßplan",
        "buildings" : "Gebäude",
        "buildings-soley" : "zur Detailansicht",
        "orbit" : "Umgebung",
        "playground" : "Spielplatz",
        "exterier" : "Außen",
        "skypark" : "Skypark",
        "castle" : "Schloss",
        "centrum" : "Zentrum",
        "woowstudio" : "Woowstudio",
        "visit-tour" : "Treten der VR bei",
        "lobby" : "Lobby",
        "office-type" : "One office",
        "more-info" : "Mehr info",
        "interier" : "Interior"
      },
      tooltips : {
        "flat-filter" : "Filter",
        "sidebar" : "Menü",
        "shortcuts" : "Verkürzungen",
        "screenshot" : "Aufnahme machen",
        "back-to-rotator" : "Zurück zur Übersicht"
      }
    },
    admin_components : {
      settings : {
        title : "Nastavenia",
        save_button : "Uloziť nastavenia"
      },
      clear_data : {
        "admin_title" : "Vymazať dáta"
      },
      import_data : {
        "admin_title" : "Importovať dáta"
      },
      new_scenes : {
        "admin_title" : "Nastavenie scén"
      }
    },
    intro_components : {
      "3d_model" : "3D Modelle"
    },
    
  },
  en: {
    admin : {
      global : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Prihlasovacie meno",
        "password" : "Heslo",
        "enabled" : "Povolené",
        "file" : "Súbor",
        "price" : "Cena",
        "save" : "Uložiť",
        "save_settings" : "Uložiť nastavenie",
        "delete" : "Zmazať",
        "edit" : "Upraviť",
        "cancel" : "Zrušiť",
        "back" : "Späť",
        "yes" : "Áno",
        "no" : "Nie",
        "upload" : "Nahrať",
        "order" : "Poradie",
        "fee" : "Poplatok",
        "copy" : "Kopírovať",
        "image" : "Obrázok",
        "description" : "Popis",
        "description_short" : "Krátky popis",
        "update" : "Aktualizovať",
        "link" : "Odkaz"


      },
      aside : {
        dashboard: {
          "headline" : "Nástenka",
          "subtitle" : "prehľad nastavenia aplikácie",
          "dashboard" : "Nástenka",
          "show_tour" : "Zobraziť tour"
        },
        projects: {
          "headline" : "Projekty",
          "subtitle" : "prehľad jednotlivých projektov",
          "projects" : "Projekty",
          "add_project" : "Pridať projekt",
          "import_projects" : "Importovať projekty"
        },
        scenes: {
          "headline" : "Nastavenia scén",
          "subtitle" : "Scény, hotspoty, patche",
          "scenes" : "Scény",
          "settings" : "Nastavenie"
        },
        components: {
          "headline" : "Správa komponentov",
          "subtitle" : "načítavané z databázy",
          "logo" : "Logo",
          "google_map" : "Google Mapa",
          "share" : "Zdielať",
          "settings" : "Nastavenia Tour",
          "floorplan" : "Floorplan",
          "shortcuts" : "Shortcuts",
          "sounds" : "Zvuky",
          "tour_info" : "Tour info *****"
        },
        configurator: {
          "headline" : "Konfigurátor",
          "subtitle" : "nastavenia konfigurátora",
          "users" : "Používatelia",
          "add_user" : "Pridať užívateľa",
          "import_users" : "Importovať užívateľov",
          "categories" : "Kategórie produktov",
          "add_category" : "Pridať kategóriu",
          "units" : "MJ Produktov",
          "add_unit" : "Pridať MJ",
          "standards" : "Štandardy produktov",
          "add_standard" : "Prídať štandard",
          "products" : "Produkty",
          "add_product" : "Pridať produkt",
          "import_products" : "Importovať produkty",
          "appartments" : "Byty *****",
          "add_appartment" : "Pridať byt *****",
          "import_appartments" : "Importovať byty *****",
          "flat_products" : "Počet produktov pre byt"
        },
        plugins: {
          "headline" : "Moduly",
          "subtitle" : "správa plug-inov",
          "merge_scenes" : "Spájanie scén"
        },
        users: {
          "headline" : "Používatelia",
          "subtitle" : "správa používateľov",
          "all_users" : "Všetci používatelia",
          "add_user" : "Pridať používateľa"
        },
        global_settings: {
          "headline" : "Nastavenia",
          "subtitle" : "globálne nastavenia prehliadky",
          "meta" : "Meta tagy"
        },
        rotator : {
          "title" : "Rotátor / Otáčadlo"
        }
      },
      projects : {
        "generate_scenes" : "vygenerovať scény",
        "scenes_count" : "Počet scén",
        "scenes_default_name" : "Základný názov scén",
        "thumbnail_name" : "Názov thumbnailu",
        "scenes_starting_number" : "Scény začínajú číslom",
        "design_change" : "Zmena dizajnu?",
        "floor_change" : "Zmena poschodia?",
        "starting_scene" : "Štartovacia scéna",
        "create_project" : "Vytvoriť projekt"

      },
      components : {
        logo : {
          "link_target" : "Otvoriť odkaz",
          "new_tab" : "V novom tabe",
          "actual_tab" : "V aktuálnom tabe",
          "logo" : "Logo"
        },
        map : {
          "filter_black" : "Použiť filter ? (čiernobiela)"
        },
        share : {
          "enable_facebook" : "Povoliť Facebook?",
          "enable_twitter" : "Povoliť Twitter?",
          "enable_linkedin" : "Povoliť LinkedIn?"
        },
        tour_settings : {
          "enable_hotspots" : "Povoliť Hotspoty?",
          "enable_autorotate" : "Povoliť Autorotáciu?",
          "enable_ad" : "Povoliť Reklamy?",
          "enable_gyro" : "Povoliť Gyroskop?",
          "enable_vr" : "Povoliť VR?",
          "enable_sounds" : "Povoliť Zvuky?"
        },
        floorplan : {
          "add_floorplan" : "Pridať floorplan?",
          "delete_floorplan" : "Odstrániť floorplan",
          "add_pin" : "Pridať pin",
          "load_pins" : "Načítať piny",
          "select_floorplan" : "Zvoľ Floorplan?",
          "floorplan_name" : "Povoliť Zvuky?",
          "scene_tag" : "Tag Scén?",
          "image_url" : "URL obrázka",
          "delete_pin" : "Odstániť pin"
        }

      },
      configurator : {
        users : {
          "new_date_label" : "Nový dátum",
          "new_date_button" : "Nastaviť všetkým",
          "flat_id" : "ID bytu",
          "sample_flat" : "Vzorový byt",
          "realpad_id" : "Realpad ID",
          "deadline" : "Deadline",
          "client_change" : "Klientská zmena",
          "configuration" : "Konfigurácia",
          "enable_config" : "Povoliť konfiguráciu",
          "create_user" : "Vytvoriť užívateľa"
        },
        categories : {
          "create_category" : "Vytvoriť kategóriu",
          "edit_category" : "Editovať kategóriu"
        },
        units : {
          "create_unit" : "Vytvoriť mernú jednotku",
          "edit_unit" : "Editovať mernú jednotku"
        },
        standards : {
          "create_standard" : "Vytvoriť štandard",
          "edit_standard" : "Editovať štandard"
        },
        flats : {
          "create_flat" : "Vytvoriť byt",
        },
        products : {
          "patch_id" : "ID patchu",
          "category" : "Kategória",
          "bind_product" : "Zväzbený produkt",
          "bind_standard" : "Zväzbené štandary",
          "enable_walls" : "Povoliť steny",
          "units" : "MJ",
          "create_product" : "Vytvoriť produkt",
          "create_and_add" : "Vytvoriť a pridať ďalší",
          "edit_product" : "Editovať produkt",
          "enable_standards" : "Povoliť štandardy?",
          "add_variant" : "Pridať variant",
          "select_standard" : "Zvoľ štandard",
          "patch_number" : "Číslo patchu"
        }
      }
    },
    frontend : {

    },
    "lang_name" : "English",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.new_scenes" : "Nastavenia scén",
    "aside.new_scenes.description" : "Scény, hotspoty, patche",
    "aside.new_scenes.config" : "Scény",
    "aside.new_scenes.settings" : "Nastavenie",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "aside.configurator" : "Konfigurátor",
    "aside.configurator.description" : "nastavenia konfigurátora",
    "aside.configurator.users" : "Používatelia",
    "aside.configurator.add-user" : "Pridať užívateľa",
    "aside.configurator.import-users" : "Importovať užívateľov",
    "aside.configurator.categories" : "Kategórie produktov",
    "aside.configurator.add-category" : "Pridať kategóriu",
    "aside.configurator.mj" : "MJ produktov",
    "aside.configurator.add-mj" : "Pridať MJ",
    "aside.configurator.products" : "Produkty",
    "aside.configurator.add-product" : "Pridať produkt",
    "aside.configurator.import-products" : "Importovať produtky",
    "aside.configurator.flats" : "Byty",
    "aside.configurator.add-flat" : "Pridať byt",
    "aside.configurator.import-flats" : "Importovať byty",
    "aside.configurator.sample-flats" : "Projekty",
    "aside.configurator.add-sample-flat" : "Pridať projekt",
    "aside.configurator.import-sample-flats" : "Importovať projekty",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "user.flat_id" : "ID bytu",
    "user.config_allowed" : "Povoliť konfiguráciu",
    "user.deadline" : "Deadline",
    "flat.add.button" : "Vytvoriť byt",
    "product.add.button" : "Vytvoriť produkt",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.floor" : "Poschodie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.settings.tiles" : "Názov CUBE obrázka",
    "scenes.settings.floor_enabled" : "Povoliť poschodia ?",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    "back" : "Späť",
    "delete" : "Odstrániť pin",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "clear" : "Vymazať dáta",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      "import" : "Importovať",
      settings : {
        "intro-title" : "Settings",
        "title" : "Settings",
        "show_hotspots" : "Show hotspots",
        "autorotate" : "Autorotate",
        "advertisement" : "Advertisement",
        "gyro" : "Gyroscope",
        "vr_mode" : "VR mode",
        "sound"  : "Sounds",
        "color" : "Color",
        "permanently_hotspots" : "Transition hotspots"
      },
      vr : {
        "intro-title" : "VR",
        "exit-vr" : "Exit VR",
        "exit" : "Exit",
        "no" : "No",
        "exit-question" : "Do you want to exit VR?",
        "shortcuts" : "Shortcuts",
        "floorplan" : "Floorplan",
        "attributes" : "Attributes",
        "shop" : "Shop",
        "layers" : "Layers",
        "model" : "3D Model",
        "tour-info" : "Tour info",
        "settings" : "Settings",
        "back" : "Back",
        "ad" : "Advertisement",
        "hotspots" : "Hotspots",
        "sound" : "Sounds",
        "zariadeny" : "Furnished",
        "holobyt" : "Unfurnished",
        "automatic-tour" : "Automatic Tour"
      },
      shortcuts : {
        "intro-title" : "Shortcuts",
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatic tour"
      },
      tour_settings : {
        "admin-title" : "Nastavenia Tour",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound" : "Zvuky"
      },
      share : {
        "intro-title" : "Share",
        "admin-title" : "Zdieľať",
        "sidebar-title" : "Share",
        "link" : "The link has been copied to the clipboard"
      },
      maps : {
        "intro-title" : "Google Maps",
        "admin-title" : "Google Mapa",
        "sidebar-title" : "Google Maps",
        "url" : "Url link",
        "title" : "Google Maps",
        "filter" : "Použiť filter ? (čiernobiela)"
      },
      tourinfo : {
        "intro-title" : "Tour info",
        "admin-title" : "Tour info",
        "sidebar-title" : "Tour info",
        "url" : "Url link",
        "title" : "Tour info",
        "headline" : "Headline",
        "image" : "Obrázok",
        "subtitle" : "Subtitle",
        "content"  : "Content",
        "filter" : "Použiť filter ? (čiernobiela)",
        "global" : "Globálne info",
        "all" : "Vyberte globálne info"
      },
      floorplan : {
        "intro-title" : "Floorplan",
        "admin-title" : "Floorplan",
        "sidebar-title" : "Floorplan",
        "floorplan-id" : "Zvoľ floorplan",
        "filter-by" : "Filtrovať podľa",
        "filter-by-value" : "Hodnota",
        "filter-none" : "Nefiltrovať",
        "pins-title" : "Názvy pinov",
        "upload-image" : "Obrázok",
        "import-file" : "Import pinov",
        "pin-x" : "pozícia X",
        "pin-y" : "pozícia Y",
        "pin-title" : "názov",
        "pin-target" : "Scéna",
        "add-floorplan" : "Pridať floorplan",
        "save-floorplan" : "Uloziť floorplan",
        "remove-floorplan" : "Ostrániť floorplan",
        "floorplan-title" : "Názov floorplanu",
        "add-pin" : "Pridať pin"
      },
      layers : {
        "intro-title" : "Layers",
        "sidebar-title" : "Layers",
        "first" : "Furnished",
        "second" : "Unfurnished",
        "zar" : "Furnished",
        "holo" : "Unfurnished",
        "vara" : "Variant A",
        "varb" : "Variant B",
        "pure" : "Pure",
        "designed" : "Designed",
        "a" : "Minimal Classic white",
        "b" : "Minimal Classic green",
        "c" : "Minimal Rock white",
        "d" : "Minimal Classic pink",
        "e" : "Minimal Classic white",
        "f" : "Minimal Classic green",
        "g" : "Minimal Rock white",
        "h" : "Minimal Classic pink",
      },
      model3d : {
        "intro-title" : "3D-Model",
        "sidebar-title" : "3D-Model",
        "autorotate" : "Autorotation",
        "select-variant" : "Select variant",
        "confirm-variant" : "Confirm variant",
        "confirm-variant-confirmed" : "Variant saved!",
        "show-dimensions" : "Show dimensions",
        "show-more" : "Show more",
        "price-prefix" : "Price for",
        "ks" : "pcs",
        "st" : "pcs",
        "m" : "m",
        "m2" : "m<sup>2</sup>"
      },
      tags : {
        "show-more" : "Show more"
      },
      flat_filter : {
        "intro-title" : "Apartments",
        "title" : "Apartments",
        "floor" : "Floor",
        "all" : "All",
        "room-count" : "Rooms",
        "status" : "Status",
        "last-visited" : "Last visited",
        "reset" : "Reset",
        "reset-filter" : "Reset filter",
        "floor-size" : "Floor area",
        "price-range" : "Price",
        "free" : "free",
        "sold" : "sold",
        "reserved" : "reserved",
        "pre-reserved" : "pre-reserved",
        "not-for-sale" : "not for sale",
        "voľný" : "free",
        "predaný" : "sold",
        "rezervovaný" : "reserved",
        "odložený" : "delayed",
        "enter-vr" : "Enter VR",
        "compare" : "Compare",
        "compare-title" : "Compare apartments",
        "selected-flats" : "selected apartments",
        "flat-id": "Apartment ID",
        "block": "Block",
        "bathroom" : "Bathroom",
        "bedroom" : "Bedroom",
        "hallway" : "Hallway",
        "kitchen" : "Kitchen",
        "loggia" : "Loggia",
        "room" : "Room",
        "wc" : "WC",
        "terrace" : "Terrace",
        "balcony" : "Balcony",
        "garden" : "garden",
        "usefull_area_of_the_apartment" : "Usable area of the apartment",
        "usefull_area_total" : "Total usable area",
        "flat" : "Apartment",
        "added-to-compare" : "added to the comparison",
        "removed-to-compare" : "removed from the comparison",
        "visit-tour" : "Visit tour",
        "visit-floorplan" : "View floorplan",
        "download-pdf" : "Download PDF comparison",
        "rotate-to-view" : "Rotate on the building",
        "not-found" : "The apartment is not shown on this view",
        "reset-filter" : "The selected apartment does not meet the filter parameters. Do you wish to reset the filter?",
        "yes" : "Yes",
        "no" : "No",
        "loading-images" : "Data is loading",
        "orbit" : "Orbit",
        "buildings" : "Buildings",
        "floors" : "Floors",
        "playground" : "Playground",
        "0" : "EG",
        "detail" : "Detail of the apartment",
        "vat" : "with tax",
        "check-price" : "watch price",
        "check-status" : "watch availability",
        "call-for-price" : "price on request",
        "download-pdf_file" : "Stiahnuť kartu bytu",
        "full-area" : "Celková plocha",
        "exterier-full" : "Výmera exteriér",
        "interier-full" : "Výmera interiér",
        "kobka" : "Kobka",
        "kobka-info" : "Ceny sú ovedené vrátane skladu, kompletnej predprípravy pre montáž klimatizácie a exteriérové elektrické žalúzie.",
      },
      basket : {
        "intro-title" : "Basket",
        "title" : "Basket",
        "reset" : "Reset",
        "total" : "Total price",
        "download-pdf" : "Download PDF",
        "download-disposition" : "Download disposition",
        "generate-pdf" : "Generate PDF",
        "save-configuration" : "Save configuration",
        "saving-configuration" : "saving the configuration",
        "configuration-saved" : "Configuration saved!",
        "no-product-on-scene" : "The selected product is not in this scene",
        "change-scene" : "If you want to configure the product, select one of the following scenes",
        "creating-pdf" : "The configuration is being created. please wait",
        "browser" : "Do not close your browser",
        "checkout" : "Checkout",
        "quantity" : "qty:",
        "pieces" : "pieces",
        "piece" : "piece"
      },
      rotator : {
        "sidebar-title" : "3D Rotator",
        "orbit" : "Orbit",
        "buildings" : "Buildings",
        "floors" : "Floors",
        "playground" : "Exterior",
        "castle"  : "Castle",
        "centrum" : "Centre",
        "skypark" : "Skypark",
        "woow" : "WOOWSTUDIO"

      },
      contact_form : {
        "intro-title" : "Contact form",
        "sidebar-title" : "Contact form",
        "name" : "Name",
        "name_hint" : "Name",
        "lastname" : "Last name",
        "lastname_hint" : "Last name",
        "email" : "Email",
        "phone" : "Phone",
        "phone_hint" : "Phone",
        "message" : "Your message",
        "message_hint" : "write a message here",
        "required_fields" : "Required fields are marked",
        "first_req_text" : "I have the",
        "second_req_text" : "read and accept it",
        "thanks" : "Thanks",
        "sent" : "Message has been sent!",
        "send_button" : "Send",
        "op" : "Terms & Conditions"
      },
      sidebar_menu : {
        "sidebar-title" : "Menu",
        "sidebar-back" : "Back"
      },
      screenshots : {
        "intro-title" : "Screenshot",
        "take-screenshot" : "Take a screenshot",
        "download-screenshot" : "Download screenshot"
      },
      walls : {
        "animate" : "Animated wall",
        "measure1" : "Measure points",
        "measure2" : "Measure surfaces"
      },
      book_call : {
        "title" : "Book a call"
      },
      merge_scenes : {
        "admin-title" : "Spájanie scén",
        "base_path" : "Adresa priečinka so scénami",
        "base_suffix" : "Suffix názvu"
      },
      sounds : {
        "admin-title" : "Zvuky",
        "background_sound" : "Zvuk na pozadí",
        "enable_bg_sound" : "Zapnúť zvuk na pozadí",
        "sound" : "Zvuk",
        "play_sound" : "Prehrať zvuk",
        "remove_sound" : "Odstrániť zvuk"
      },
      preloader : {
        "loading_components" :  "Loading components",
        "loading_scenes" : "Loading scenes",
        "scenes_loaded" : "Scenes loaded",
        "loaded" : "loaded",
        "components_loaded" : "all components loaded!",
        "patches_loading" : "Loading products"
      },
      intro : {
        "title" : "Navigation in VR",
        "click-desktop" : "Click and move to look around",
        "click-vr" : "Click and slide to look around",
        "scroll-desktop" : "Scroll to zoom in/out",
        "scroll-vr" : "Press to zoom in/out",
        "continue" : "Continue",
        "dont_show" : "Do not show again",
        "loading-tour" : "We are preparing your experience",
        "tour-loaded" : "Loaded"
      },
      picker : {
        "tour_title" : "Back to VR",
        "floorplan_title" : "Back to floor selection",
        "level" : "floor",
        "room_count" : "rooms",
        "orientation" : "orientation",
        "last_visited" : "last visited",
        "reset" : "reset selection",
        "empty_value" : "not selected",
        "back" : "Back to floor selection",
        "all_floors_title" : "Select floor"
      },
      import : {
        "scenes_count" : "Počet scén",
        "default_name" : "Default názov scény",
        "cf10" : "Tag pre slider",
        "enable_floor" : "Povoliť poschodia ? ",
        "import_data" : "Importovať dáta"
      },
      configurator : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Login",
        "password" : "Heslo",
        "flat_id" : "ID bytu",
        "sample_flat_id" : "Vzorový byt",
        "realpad_id" : "Realpad ID",
        "config_allowed" : "Povolené",
        "deadline" : "Deadline",
        "client_change" : "Klientská zmena",
        "configuration" : "Konfigurácia",
        "price" : "Cena",
        "file" : "Súbor",
        "save" : 'Uložiť',
        "set" : 'Nastaviť všetkým',
        "delete" : "Zmazať",
        "new_date_label" : "Nový dátum",
        "import-title" : "Import užívateľov",
        "yes" : "áno",
        "no" : "nie",
        "products" : "Produkty",
        "sample_flats_title" : "Projekty"
      },
      products : {
        "id" : "ID",
        "name" : "Názov",
        "category" : "Kategória",
        "patch_id" : "ID patchu",
        "title" : "Produkty",
        "import-title" : "Import produktov",
        "add-title" : "Pridať produkt",
        "product_order" : "Poradie",
        "bind" : "Zväzbený produkt",
        "bind_standard" : "Zväzbené štandardy?",
        "mj" : "MJ",
        "active_variant" : "Aktívny variant",
        "active_standard" : "Aktívny štandard",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť"
      },
      product_categories : {
        "title" : "Kategórie produktov",
        "add-category" : "Pridať kategóriu",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť kategóriu"
      },
      product_mj : {
        "title" : "MJ produktov",
        "add-mj" : "Pridať MJ",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť MJ"
      },
      flats : {
        "name" : "Názov",
        "title" : "Byty",
        "import-title" : "Import bytov",
        "add-title" : "Pridať byt"
      },
      sample_flats : {
        "id" : "ID",
        "name" : "Názov",
        "title" : "Produkty",
        "import-title" : "Import vzorových bytov",
        "add-name" : "Pridať projekt",
        "count" : "Počet scén",
        "default_name" : "Základný názov scén",
        "counter" : "Štartovacie číslo",
        "enable_design" : "Zmena dizajnu?",
        "enable_floors" : "Zmena poschodia?",
        "save" : "Uložiť",
        "delete" : "Vymazať",
        "generate" : "Vygenerovať scény",
        "thumbnail_name" : "Názov thumbnailu"
      },
      new_scenes_settings : {
        "name" : "názov",
        "enabled" : "povolené",
        "value" : "hodnota",
        "krpano_variable_name" : "Premenná krpano",
        "design" : "Dizajn",
        "floors" : "Poschodia",
        "project_url" : "Projekt url suffix",
        "scenes_path" : "Priečinok so scénami",
        "patches_path" : "Priečinok so scénami",
        "blendmode" : "Blend mode"
      },
      new_scenes : {
        "load_scene_orientation" : "načítať default view",
        "save_scene_orientation" : "nastaviť default view",
        "save_scene_settings" : "uložiť nastavenia",
        buttons : {
          
        }
      },
      dispositions: {
        "choose-dispositions" : "Select disposition",
        "detail"  : "Show detail",
        "enter" : "Enter",
        "price" : "price difference",
        "client-change" : "Customer change",
        "back" : "Back",
        "pdf-documentation" : "PDF documentation",
        "plus" : "Article added",
        "minus" : "Article subtracted"
      },
      tutorial : {
        "title" : "Tutorial",
        "new" : "New",
        "dont-show" : "The tutorial can be restarted in the settings (sidebar).",
        "screenshots" : {
          "title" : "Screenshots",
          "description" : "By using the screenshot function, it is possible to capture a static image directly from the virtual tour. You can capture the entire screen or select one of the standard sizes. The resulting image can then be downloaded to your device in JPG format.",
        },
        "languages" : {
          "title" : "Languages",
          "description" : "The function Languages serves the purpose of switching between different language variants or languages.",
        },
        "basket" : {
          "title" : "Basket",
          "description" : "In the shopping cart, you'll find all selected items during the project configuration. The products are categorized and encompass the total amount, including fees. The shopping cart also offers the option to save the configuration history.",
        },
        "shortcuts" : {
          "title" : "Shortcuts",
          "description" : "With the Shortcuts function, you can easily navigate to any scene within the tour without having to go through the entire area when you want to reach a specific part. Within the Shortcuts, you can also activate the Auto-tour function, which plays all scenes automatically one after another without manual switching.",
        },
        "flat_filter" : {
          "title" : "Apartment filter",
          "description" : "Apartment filter allows you to display all available apartments in a building. With this function, you can filter apartments according to your requirements and then transition to the virtual tour of a specific apartment.",
        },
        "sidebar" : {
          "title" : "Sidebar",
          "tooltip-title" : "Sidebar",
          "description" : "In the sidebar section, you'll find all the additional functions available within the respective virtual tour. Here, you'll also discover settings that allow you to customize the virtual tour according to your preferences.",
        },
        "floorplan" : {
          "title" : "Floorplan",
          "tooltip-title" : "Inside Sidebar",
          "description" : "Thanks to the Floor Plan function, you can view the spatial layout of the respective property and swiftly switch between individual scenes. All positions are graphically depicted directly on the floor plan.",
        },
        "layers" : {
          "title" : "Layers",
          "tooltip-title" : "Inside Sidebar",
          "description" : "Using the Layers function, you can alter the appearance of the virtual tour. This can apply to the furnishing of a specific apartment or entail a general change in the design.",
        },
        "3d_model" : {
          "title" : "3D model",
          "tooltip-title" : "Inside Sidebar",
          "description" : "With the 3D Model function, you can view a three-dimensional visualization of all products that can be configured within the virtual tour. The product's 3D model also includes its precise dimensions, and once you've selected the desired variant, this change will be visible in the virtual tour.",
        },
        "social" : {
          "title" : "Share",
          "tooltip-title" : "Inside Sidebar",
          "description" : "Thanks to the Share function, you can easily copy the link of the page you are currently on or share this page on social networks, allowing you to share your experience with your friends and contacts.",
        },
        "attributes" : {
          "title" : "Attributes",
          "description" : "The Attributes function is used to configure individual products according to your requirements. If a product has multiple variants, you can use attributes to select the desired material, and the change will immediately reflect in the virtual tour.",
        },
        "google_map" : {
          "title" : "Google Maps",
          "tooltip-title" : "Inside Sidebar",
          "description" : "Google Maps is used to display the precise location of an object where you are currently situated. It's an application that utilizes location data and mapping services, allowing users to determine their current position and find places on the map. It also provides route planning and navigation options.",
        },
        "tour_info" : {
          "title" : "Tour info",
          "tooltip-title" : "Inside Sidebar",
          "description" : "In the Tour Info section, you'll find general information and interesting facts about the specific virtual tour.",
        },
        "measure_1" : {
          "title" : "Measure points",
          "tooltip-title" : "Inside Sidebar",
          "description" : "Through the Points Measurement function, you can measure any part of the virtual tour. Simply click twice at the point where you want to start the measurement, then drag the mouse to where you want to end the measurement, and click twice again. This allows you to measure the length or distance between the two points within the virtual tour.",
        },
        "measure_2" : {
          "title" : "Measure surfaces",
          "tooltip-title" : "Inside Sidebar",
          "description" : "Thanks to the Surface Measurement function, for example, you can measure the overall dimensions of a room. Simply click twice on any surface, and this function automatically calculates the distance to the opposite surface. This allows you to measure the diagonal or total diameter of a room or area within the virtual tour.",
        },
        "bookcall" : {
          "title" : "Book a call",
          "tooltip-title" : "Inside Sidebar",
          "description" : "With the Book a Meeting function, you can schedule a video call with the manager responsible for a specific project. Using the calendar, you can easily view available time slots and reserve a specific time. The manager will then contact you at the scheduled time.",
        },
        "pdf" : {
          "title" : "Create PDF",
          "tooltip-title" : "Inside Basket",
          "description" : "Using the Create PDF function, you can easily generate a PDF document containing additional information and a complete list of the products you selected while configuring the apartment. This allows you to store or print all relevant data and information in a PDF document.",
        },
        "smooth_transition" : {
          "title" : "Smooth transition",
          "description" : "The function of smooth transitions further enhances the user experience within the virtual tour. In the tour, there are no transition hotspots present. Therefore, you move by clicking on a part of the floor and are then transported to that location. However, if you wish to see these transition hotspots, you can activate them in the settings of the virtual tour.",
        },
        

      },
      otacadlo:  {
        "floors" : "Floors",
        "buildings" : "Buildings",
        "orbit" : "Orbit",
        "playground" : "Playground",
        "exterier" : "Exterior",
        "skypark" : "Skypark",
        "castle" : "Castle",
        "centrum" : "Centrum",
        "woowstudio" : "Woowstudio",
        "visit-tour" : "Join the VR",
        "lobby" : "Lobby",
        "office-type" : "One office",
        "EVELYN" : "EVELYN",
        "ANGELA" : "ANGELA",
        "more-info" : "More info",
        "interier" : "Interior"
      },
      tooltips : {
        "flat-filter" : "Filter",
        "sidebar" : "Menu",
        "shortcuts" : "Shortcuts",
        "screenshot" : "Screenshots",
        "back-to-rotator" : "Back to overview"
      }
    },
    admin_components : {
      settings : {
        title : "Nastavenia",
        save_button : "Uloziť nastavenia"
      },
      clear_data : {
        "admin_title" : "Vymazať dáta"
      },
      import_data : {
        "admin_title" : "Importovať dáta"
      },
      new_scenes : {
        "admin_title" : "Nastavenie scén"
      }
    },
    intro_components : {
      "3d_model" : "3D Model"
    }
  },
  cz: {
    admin : {
      global : {
        "id" : "ID",
        "name" : "Názov",
        "username" : "Prihlasovacie meno",
        "password" : "Heslo",
        "enabled" : "Povolené",
        "file" : "Súbor",
        "price" : "Cena",
        "save" : "Uložiť",
        "save_settings" : "Uložiť nastavenie",
        "delete" : "Zmazať",
        "edit" : "Upraviť",
        "cancel" : "Zrušiť",
        "back" : "Späť",
        "yes" : "Áno",
        "no" : "Nie",
        "upload" : "Nahrať",
        "order" : "Poradie",
        "fee" : "Poplatok",
        "copy" : "Kopírovať",
        "image" : "Obrázok",
        "description" : "Popis",
        "description_short" : "Krátky popis",
        "update" : "Aktualizovať",
        "link" : "Odkaz"


      },
      aside : {
        dashboard: {
          "headline" : "Nástenka",
          "subtitle" : "prehľad nastavenia aplikácie",
          "dashboard" : "Nástenka",
          "show_tour" : "Zobraziť tour"
        },
        projects: {
          "headline" : "Projekty",
          "subtitle" : "prehľad jednotlivých projektov",
          "projects" : "Projekty",
          "add_project" : "Pridať projekt",
          "import_projects" : "Importovať projekty"
        },
        scenes: {
          "headline" : "Nastavenia scén",
          "subtitle" : "Scény, hotspoty, patche",
          "scenes" : "Scény",
          "settings" : "Nastavenie"
        },
        components: {
          "headline" : "Správa komponentov",
          "subtitle" : "načítavané z databázy",
          "logo" : "Logo",
          "google_map" : "Google Mapa",
          "share" : "Zdielať",
          "settings" : "Nastavenia Tour",
          "floorplan" : "Floorplan",
          "shortcuts" : "Shortcuts",
          "sounds" : "Zvuky",
          "tour_info" : "Tour info *****"
        },
        configurator: {
          "headline" : "Konfigurátor",
          "subtitle" : "nastavenia konfigurátora",
          "users" : "Používatelia",
          "add_user" : "Pridať užívateľa",
          "import_users" : "Importovať užívateľov",
          "categories" : "Kategórie produktov",
          "add_category" : "Pridať kategóriu",
          "units" : "MJ Produktov",
          "add_unit" : "Pridať MJ",
          "standards" : "Štandardy produktov",
          "add_standard" : "Prídať štandard",
          "products" : "Produkty",
          "add_product" : "Pridať produkt",
          "import_products" : "Importovať produkty",
          "appartments" : "Byty *****",
          "add_appartment" : "Pridať byt *****",
          "import_appartments" : "Importovať byty *****",
          "flat_products" : "Počet produktov pre byt"
        },
        plugins: {
          "headline" : "Moduly",
          "subtitle" : "správa plug-inov",
          "merge_scenes" : "Spájanie scén"
        },
        users: {
          "headline" : "Používatelia",
          "subtitle" : "správa používateľov",
          "all_users" : "Všetci používatelia",
          "add_user" : "Pridať používateľa"
        },
        global_settings: {
          "headline" : "Nastavenia",
          "subtitle" : "globálne nastavenia prehliadky",
          "meta" : "Meta tagy"
        },
      },
      projects : {
        "generate_scenes" : "vygenerovať scény",
        "scenes_count" : "Počet scén",
        "scenes_default_name" : "Základný názov scén",
        "thumbnail_name" : "Názov thumbnailu",
        "scenes_starting_number" : "Scény začínajú číslom",
        "design_change" : "Zmena dizajnu?",
        "floor_change" : "Zmena poschodia?",
        "starting_scene" : "Štartovacia scéna",
        "create_project" : "Vytvoriť projekt"

      },
      components : {
        logo : {
          "link_target" : "Otvoriť odkaz",
          "new_tab" : "V novom tabe",
          "actual_tab" : "V aktuálnom tabe",
          "logo" : "Logo"
        },
        map : {
          "filter_black" : "Použiť filter ? (čiernobiela)"
        },
        share : {
          "enable_facebook" : "Povoliť Facebook?",
          "enable_twitter" : "Povoliť Twitter?",
          "enable_linkedin" : "Povoliť LinkedIn?"
        },
        tour_settings : {
          "enable_hotspots" : "Povoliť Hotspoty?",
          "enable_autorotate" : "Povoliť Autorotáciu?",
          "enable_ad" : "Povoliť Reklamy?",
          "enable_gyro" : "Povoliť Gyroskop?",
          "enable_vr" : "Povoliť VR?",
          "enable_sounds" : "Povoliť Zvuky?"
        },
        floorplan : {
          "add_floorplan" : "Pridať floorplan?",
          "delete_floorplan" : "Odstrániť floorplan",
          "add_pin" : "Pridať pin",
          "load_pins" : "Načítať piny",
          "select_floorplan" : "Zvoľ Floorplan?",
          "floorplan_name" : "Povoliť Zvuky?",
          "scene_tag" : "Tag Scén?",
          "image_url" : "URL obrázka",
          "delete_pin" : "Odstániť pin"
        }

      },
      configurator : {
        users : {
          "new_date_label" : "Nový dátum",
          "new_date_button" : "Nastaviť všetkým",
          "flat_id" : "ID bytu",
          "sample_flat" : "Vzorový byt",
          "realpad_id" : "Realpad ID",
          "deadline" : "Deadline",
          "client_change" : "Klientská zmena",
          "configuration" : "Konfigurácia",
          "enable_config" : "Povoliť konfiguráciu",
          "create_user" : "Vytvoriť užívateľa"
        },
        categories : {
          "create_category" : "Vytvoriť kategóriu",
          "edit_category" : "Editovať kategóriu"
        },
        units : {
          "create_unit" : "Vytvoriť mernú jednotku",
          "edit_unit" : "Editovať mernú jednotku"
        },
        standards : {
          "create_standard" : "Vytvoriť štandard",
          "edit_standard" : "Editovať štandard"
        },
        flats : {
          "create_flat" : "Vytvoriť byt",
        },
        products : {
          "patch_id" : "ID patchu",
          "category" : "Kategória",
          "bind_product" : "Zväzbený produkt",
          "bind_standard" : "Zväzbené štandary",
          "enable_walls" : "Povoliť steny",
          "units" : "MJ",
          "create_product" : "Vytvoriť produkt",
          "create_and_add" : "Vytvoriť a pridať ďalší",
          "edit_product" : "Editovať produkt",
          "enable_standards" : "Povoliť štandardy?",
          "add_variant" : "Pridať variant",
          "select_standard" : "Zvoľ štandard",
          "patch_number" : "Číslo patchu"
        }
      }
    },
    frontend : {

    },
    "lang_name" : "Česky",
    "login" : "Přihlášení",
    "login.page" : "se přihlaste",
    "username" : "přihlašovací jméno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "nebo",
    "login.button" : "Přihlásit",
    "visit.tour" : "přejít do VR" ,
    "aside.dashboard" : "Nástěnka",
    "aside.dashboard.description" : "přehled nastavení aplikace",
    "aside.statistic" : "Statistiky",
    "aside.show.tour" : "Zobrazit tour",
    "aside.scenes" : "Scény / panoramata",
    "aside.scenes.description" : "všechny scény",
    "aside.new_scenes" : "Nastavení scén",
    "aside.new_scenes.description" : "Scény, hotspoty, patche",
    "aside.new_scenes.config" : "Scény",
    "aside.new_scenes.settings" : "Nastavenie",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "aside.configurator" : "Konfigurátor",
    "aside.configurator.description" : "nastavenia konfigurátora",
    "aside.configurator.users" : "Používatelia",
    "aside.configurator.add-user" : "Pridať užívateľa",
    "aside.configurator.import-users" : "Importovať užívateľov",
    "aside.configurator.categories" : "Kategórie produktov",
    "aside.configurator.add-category" : "Pridať kategóriu",
    "aside.configurator.mj" : "MJ produktov",
    "aside.configurator.add-mj" : "Pridať MJ",
    "aside.configurator.products" : "Produkty",
    "aside.configurator.add-product" : "Pridať produkt",
    "aside.configurator.import-products" : "Importovať produtky",
    "aside.configurator.flats" : "Byty",
    "aside.configurator.add-flat" : "Pridať byt",
    "aside.configurator.import-flats" : "Importovať byty",
    "aside.configurator.sample-flats" : "Projekty",
    "aside.configurator.add-sample-flat" : "Pridať projekt",
    "aside.configurator.import-sample-flats" : "Importovať projekty",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "user.flat_id" : "ID bytu",
    "user.config_allowed" : "Povoliť konfiguráciu",
    "user.deadline" : "Deadline",
    "flat.add.button" : "Vytvoriť byt",
    "product.add.button" : "Vytvoriť produkt",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.floor" : "Poschodie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.settings.tiles" : "Názov CUBE obrázka",
    "scenes.settings.floor_enabled" : "Povoliť poschodia ?",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    "back" : "Zpět",
    "delete" : "Odstrániť pin",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentů",
      "admin-title" : "Všechny komponenty",
      "admin-description" : "zapnutí/vypnutí komponentů",
      "save" : "Ulozit nastavení",
      "clear" : "Vymazat data",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
    "logo-target" : "Otevřít odkaz",
      "logo-target-blank" : "v novém tabu",
      "logo-target-self" : "v aktuálním tabu",
      "logo-upload" : "Vložit logo", 
      "import" : "Importovat",
      settings : {
        "title" : "Nastavení",
        "show_hotspots" : "Zobrazit hotspoty",
        "autorotate" : "Autootáčení",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound"  : "Zvuky",
        "color" : "Barva",
        "permanently_hotspots" : "Přechodové hotspoty"
      },
      vr : {
        "exit-vr" : "Ukončit VR",
        "exit" : "Ukončit",
        "no" : "Ne",
        "exit-question" : "Opravdu chcete ukončit VR?",
        "shortcuts" : "Zkratky",
        "floorplan" : "Půdorys",
        "attributes" : "Atributy",
        "shop" : "Obchod",
        "layers" : "Vrstvy",
        "model" : "3D Model",
        "tour-info" : "Tour info",
        "settings" : "Nastavení",
        "back" : "Zpět",
        "ad" : "Reklama",
        "hotspots" : "Hotspoty",
        "sound" : "Zvuky",
        "zariadeny" : "Zařízený",
        "holobyt" : "Holobyt",
        "automatic-tour" : "Automatická Tour"
      },
      shortcuts : {
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatická tour"
      },
      tour_settings : {
        "admin-title" : "Nastavení Tour",
        "show_hotspots" : "Zobrazit hotspoty",
        "autorotate" : "Autootáčení",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound" : "Zvuky"
      },
      share : {
        "admin-title" : "Sdílet",
        "sidebar-title" : "Sdílet",
        "link" : "Link byl zkopírován do schránky"
      },
      maps : {
        "admin-title" : "Google Mapa",
        "sidebar-title" : "Google Mapa",
        "url" : "Url link",
        "title" : "Google Mapa",
        "filter" : "Použít filtr ? (černobílá)"
      },
      sidebar_menu : {
        "sidebar-title" : "Menu",
        "sidebar-back" : "Zpět",
      },
      screenshots : {
        "take-screenshot" : "Vytvořit snímek",
        "download-screenshot" : "Uložit snímek"
      },
      walls : {
        "animate" : "Animace stěny",
        "measure1" : "Libovolné měření",
        "measure2" : "Měření protilehlého bodu"
      },
      book_call : {
        "title" : "Rezervovat meeting"
      },
      tourinfo : {
        "admin-title" : "Tour info",
        "sidebar-title" : "Tour info",
        "url" : "Url link",
        "title" : "Tour info",
        "headline" : "Headline",
        "image" : "Obrázek",
        "subtitle" : "Subtitle",
        "content"  : "Content",
        "filter" : "Použít filtr ? (černobílá)",
        "global" : "Globální info",
        "all" : "Vyberte globální info"
      },
      floorplan : {
        "admin-title" : "Floorplan",
        "sidebar-title" : "Půdorys",
        "floorplan-id" : "Zvoľ floorplan",
        "filter-by" : "Filtrovať podľa",
        "filter-by-value" : "Hodnota",
        "filter-none" : "Nefiltrovať",
        "pins-title" : "Názvy pinov",
        "upload-image" : "Obrázok",
        "import-file" : "Import pinov",
        "pin-x" : "pozícia X",
        "pin-y" : "pozícia Y",
        "pin-title" : "názov",
        "pin-target" : "Scéna",
        "add-floorplan" : "Pridať floorplan",
        "save-floorplan" : "Uloziť floorplan",
        "remove-floorplan" : "Ostrániť floorplan",
        "floorplan-title" : "Názov floorplanu",
        "add-pin" : "Pridať pin"
      },
      layers : {
        "sidebar-title" : "Vrstvy",
        "first" : "Zařízený",
        "second" : "Holobyt",
        "zar" : "Zařízený",
        "holo" : "Holobyt",
        "vara" : "Varianta A",
        "varb" : "Varianta B",
        "pure" : "Pure",
        "designed" : "Designed",
        "a" : "STANDARD - Minimal Rock white",
        "b" : "STANDARD - Minimal Classic white",
        "c" : "STANDARD - Minimal Classic pink",
        "d" : "STANDARD - Minimal Classic green",
        "e" : "NADSTANDARD - Minimal Rock white",
        "f" : "NADSTANDARD - Minimal Classic white",
        "g" : "NADSTANDARD - Minimal Classic pink",
        "h" : "NADSTANDARD - Minimal Classic green",
      },
      model3d : {
        "sidebar-title" : "3D Modely",
        "autorotate" : "Autorotace",
        "select-variant" : "Zvolit variantu",
        "confirm-variant" : "Potvrdit výběr",
        "confirm-variant-confirmed" : "Výběr uložen!",
        "show-dimensions" : "Zobrazit rozměry",
        "show-more" : "zobrazit více",
        "price-prefix" : "cena za",
        "ks" : "ks",
        "st" : "ks",
        "m" : "m",
        "m2" : "m<sup>2</sup>"
      },
      tags : {
        "show-more" : "Zobrazit více"
      },
      flat_filter : {
        "title" : "Apartmány",
        "floor" : "Patro",
        "all" : "všechny",
        "room-count" : "Počet pokojů",
        "status" : "Dostupnost",
        "last-visited" : "Naposledy viděno",
        "reset" : "Resetovat",
        "floor-size" : "Podlahová plocha",
        "price-range" : "Cena",
        "free" : "volný",
        "sold" : "prodán",
        "reserved" : "rezervován",
        "pre-reserved" : "před rezervován",
        "not-for-sale" : "není na prodej",
        "voľný" : "volný",
        "predaný" : "prodán",
        "rezervovaný" : "rezervován",
        "odložený" : "odložen",
        "enter-vr" : "Vstoupit do VR",
        "compare" : "Porovnat",
        "compare-title" : "Porovnání bytů",
        "selected-flats" : "Zvolené byty",
        "flat-id": "ID bytu",
        "block": "Blok",
        "bathroom" : "Koupelna",
        "bedroom" : "Ložnice",
        "hallway" : "Chodba",
        "kitchen" : "Kuchyně",
        "loggia" : "Loggie",
        "room" : "Pokoj",
        "wc" : "WC",
        "terrace" : "Terasa",
        "balcony" : "Balkon",
        "garden" : "Zahrada",
        "usefull_area_of_the_apartment" : "Užitná plocha bytu",
        "usefull_area_total" : "Užitná plocha celkově",
        "flat" : "Byt",
        "added-to-compare" : "byl přidán do srovnání",
        "removed-to-compare" : "byl odstraněn ze srovnání",
        "visit-tour" : "Zobrazit prohlídku",
        "visit-floorplan" : "Zobrazit floorplan",
        "download-pdf" : "Stáhnout PDF srovnání",
        "rotate-to-view" : "Natočit budovu",
        "not-found" : "Tento byt se na daném zobrazení nenachází",
        "reset-filter" : "Zvolený byt nesplňuje parametry filtru. Přejete si resetovat filtr?",
        "yes" : "Ano",
        "no" : "Ne",
        "loading-images" : "načítám data",
        "orbit" : "Orbit",
        "buildings" : "Budovy",
        "floors" : "Patra",
        "playground" : "Exteriér",
        "0" : "EG",
        "detail" : "Detail bytu",
        "vat" : "s dph",
        "check-price" : "hlídat cenu",
        "check-status" : "hlídat dostupnost",
        "call-for-price" : "cena na vyžádaní",
        "download-pdf_file" : "Stáhnout kartu bytu",
        "full-area" : "Celková výmera",
        "exterier-full" : "Výmera exteriér",
        "interier-full" : "Výmera interiér",
        "kobka" : "Info sklad",
        "kobka-info" : "Cena je uvedená vrátane skladu a kompletnej prepdprípravy na montáže klimatizácie a exteriérového elektrického žalúzia.",
      },
      basket : {
        "title" : "Košík",
        "reset" : "Resetovat",
        "total" : "Cena celkem",
        "download-pdf" : "Stáhnout PDF",
        "download-disposition" : "Stáhnout nápočet",
        "generate-pdf" : "Generovat PDF",
        "save-configuration" : "Uložit konfiguraci",
        "configuration-saved" : "Konfigurace byla uložena!",
        "no-product-on-scene" : "Vybraný produkt se na této scéně nenachází",
        "change-scene" : "Chcete-li produkt konfigurovat vyberte jednu z následujících scén",
        "creating-pdf" : "Vytvářím konfiguraci. Počkejte prosím",
        "browser" : "Nezavírejte Váš prohlížeč",
        "checkout" : "Pokladna CZ",
        "quantity" : "mn:",
        "pieces" : "kusy",
        "piece" : "kus"
      },
      rotator : {
        "sidebar-title" : "3D Rotátor",
        "orbit" : "Orbit",
        "buildings" : "Budovy",
        "floors" : "Patra",
        "playground" : "Exteriér",
        "castle"  : "Hrad",
        "centrum" : "Centrum",
        "skypark" : "Skypark",
        "woow" : "WOOWSTUDIO"
      },
      contact_form : {
        "sidebar-title" : "Kontaktní formulář",
        "name" : "Jméno",
        "name_hint" : "Jméno",
        "lastname" : "Příjmení",
        "lastname_hint" : "Příjmení",
        "email" : "Email",
        "phone" : "Telefon",
        "phone_hint" : "Telefon",
        "message" : "Vaše zpráva",
        "message_hint" : "Sem napište zprávu",
        "required_fields" : "Povinná pole jsou označena",
        "first_req_text" : "Přečetl jsem si ",
        "second_req_text" : "a souhlasím s nimi",
        "thanks" : "Děkujeme",
        "sent" : "Zpráva byla odeslána",
        "send_button" : "Odeslat",
        "op" : "Obchodní podmínky"
      },
      merge_scenes : {
        "admin-title" : "Spájanie scén",
        "base_path" : "Adresa priečinka so scénami",
        "base_suffix" : "Suffix názvu"
      },
      sounds : {
        "admin-title" : "Zvuky",
        "background_sound" : "Zvuk na pozadí",
        "enable_bg_sound" : "Zapnúť zvuk na pozadí",
        "sound" : "Zvuk",
        "play_sound" : "Prehrať zvuk",
        "remove_sound" : "Odstrániť zvuk"
      },
      preloader : {
        "loading_components" :  "načítám komponenty",
        "loading_scenes" : "načítám scény ...",
        "scenes_loaded" : "scény načtené",
        "loaded" : "načtené",
        "components_loaded" : "všechny komponenty načteny!",
        "patches_loading" : "načítám produkt ..."
      },
      intro : {
        "title" : "Navigace ve VR",
        "click-desktop" : "Klepnutím a pohybem se rozhlédněte",
        "click-vr" : "Klepnutím a posunutím se rozhlédněte",
        "scroll-desktop" : "Scrolujte pro přiblížení/oddálení",
        "scroll-vr" : "Stisknutím přibližte/oddalte",
        "continue" : "Pokračovat",
        "dont_show" : "vícekrát nezobrazovat",
        "loading-tour" : "Připravujeme váš zážitek",
        "tour-loaded" : "Načtených"
      },
      picker : {
        "tour_title" : "Zpět na VR",
        "floorplan_title" : "Zpět na výběr patra",
        "level" : "patro",
        "room_count" : "počet pokojů",
        "orientation" : "orientace",
        "last_visited" : "naposledy navštívené",
        "reset" : "resetovat výběr",
        "empty_value" : "nezvoleno",
        "back" : "Zpět na výběr patra",
        "all_floors_title" : "Vybrat patro"
      },
      import : {
        "scenes_count" : "Počet scén",
        "default_name" : "Default názov scény",
        "cf10" : "Tag pre slider",
        "enable_floor" : "Povoliť poschodia ? ",
        "import_data" : "Importovať dáta"
      },
      configurator : {
        "id" : "ID",
        "name" : "Název",
        "username" : "Login",
        "password" : "Heslo",
        "flat_id" : "ID bytu",
        "sample_flat_id" : "Vzorový byt",
        "realpad_id" : "Realpad ID",
        "config_allowed" : "Povoleno",
        "deadline" : "Deadline",
        "client_change" : "Klientská změna",
        "configuration" : "Konfigurace",
        "price" : "Cena",
        "file" : "Soubor",
        "save" : 'Uložit',
        "set" : 'Nastavit všem',
        "delete" : "Smazat",
        "new_date_label" : "Nové datum",
        "import-title" : "Import užívateľov",
        "yes" : "ano",
        "no" : "ne",
        "products" : "Produkty",
        "sample_flats_title" : "Projekty"
      },
      products : {
        "id" : "ID",
        "name" : "Název",
        "category" : "Kategorie",
        "patch_id" : "ID patchu",
        "title" : "Produkty",
        "import-title" : "Import produktov",
        "add-title" : "Pridať produkt",
        "product_order" : "Poradie",
        "bind" : "Zväzbený produkt",
        "bind_standard" : "Zväzbené štandardy?",
        "mj" : "MJ",
        "active_variant" : "Aktívny variant",
        "active_standard" : "Aktívny štandard",
        "save" : "Ulozit",
        "edit" : "Upravit",
        "update" : "Aktualizovat",
        "cancel" : "Zrušit"
      },
      product_categories : {
        "title" : "Kategórie produktov",
        "add-category" : "Pridať kategóriu",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť kategóriu"
      },
      product_mj : {
        "title" : "MJ produktov",
        "add-mj" : "Pridať MJ",
        "id" : "ID",
        "name" : "Názov",
        "category_order" : "Poradie",
        "save" : "Uloziť",
        "edit" : "Upraviť",
        "update" : "Aktualizovať",
        "cancel" : "Zrušiť",
        "delete" : "Vymazať",
        "create" : "Vytvoriť MJ"
      },
      flats : {
        "name" : "Názov",
        "title" : "Byty",
        "import-title" : "Import bytov",
        "add-title" : "Pridať byt"
      },
      sample_flats : {
        "id" : "ID",
        "name" : "Názov",
        "title" : "Produkty",
        "import-title" : "Import vzorových bytov",
        "add-name" : "Pridať projekt",
        "count" : "Počet scén",
        "default_name" : "Základný názov scén",
        "counter" : "Štartovacie číslo",
        "enable_design" : "Zmena dizajnu?",
        "enable_floors" : "Zmena poschodia?",
        "save" : "Uložiť",
        "delete" : "Vymazať",
        "generate" : "Vygenerovať scény",
        "thumbnail_name" : "Názov thumbnailu"
      },
      new_scenes_settings : {
        "name" : "názov",
        "enabled" : "povolené",
        "value" : "hodnota",
        "krpano_variable_name" : "Premenná krpano",
        "design" : "Dizajn",
        "floors" : "Poschodia",
        "project_url" : "Projekt url suffix",
        "scenes_path" : "Priečinok so scénami",
        "patches_path" : "Priečinok so scénami",
        "blendmode" : "Blend mode"
      },
      new_scenes : {
        "load_scene_orientation" : "načítať default view",
        "save_scene_orientation" : "nastaviť default view",
        "save_scene_settings" : "uložiť nastavenia",
        buttons : {
          
        }
      },
      dispositions: {
        "choose-dispositions" : "Vybrat dispozici",
        "detail"  : "Zobrazit detail",
        "enter" : "Vstoupit",
        "price" : "cenový rozdíl",
        "client-change" : "Klientská změna",
        "back" : "Zpět",
        "pdf-documentation" : "PDF dokumentace",
        "plus" : "Připočtené položky",
        "minus" : "Odečtené položky"
      },
      tutorial : {
        "title" : "Tutoriál",
        "new" : "Novinka",
        "dont-show" : "Tutoriál lze opět spustit v nastaveních (sidebar).",
        "screenshots" : {
          "title" : "Focení",
          "description" : "Pomocí funkce Focení lze pořídit statický obrázek přímo z virtuální prohlídky. Můžete vyfotit celou obrazovku, nebo zvolit některý ze standardních rozměrů. Výsledný obrázek je následně možné stáhnout do Vašeho zařízení ve formátu JPG."
        },
        "languages" : {
          "title" : "Jazyky",
          "description" : "Funkce Jazyky slouží k přepínání se mezi jazykovými mutacemi"
        },
        "basket" : {
          "title" : "Košík",
          "description" : "V košíku se nacházejí všechny položky, které byly zvoleny během konfigurace projektu. Produkty jsou seřazeny v kategoriích a zahrnují výslednou částku včetně poplatků. V košíku se nachází také možnost uložit průběh konfigurace."
        },
        "shortcuts" : {
          "title" : "Zkratky",
          "description" : "Pomocí funkce Zkratky se můžete snadno dostat na jakoukoli scénu, která se nachází na prohlídce. Není tedy nutné procházet celým objektem, potřebujete-li se dostat do konkrétní části. Ve zkratkách si také umíte zapnout funkci Autotour, která Vám přehraje všechny scény za sebou automaticky, bez nutnosti manuálního přepínání."
        },
        "flat_filter" : {
          "title" : "Filtr bytů",
          "description" : "Ve filtru bytů se nacházejí všechny dostupné byty v rámci celého objektu. Pomocí této funkce si umíte jednoduše odfiltrovat byty dle Vašich požadavků. Následně se umíte přesunout do virtuální prohlídky konkrétního bytu."
        },
        "sidebar" : {
          "title" : "Sidebar",
          "tooltip-title" : "Sidebar",
          "description" : "V sekci Sidebar se nacházejí všechny doplňkové funkce, které obsahuje konrétní virtuální prohlídka. Rovněž se zde nacházejí nastavení, díky kterým si můžete virtuální prohlídku přizpůsobit podle Vašich představ"
        },
        "floorplan" : {
          "title" : "Půdorys",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Díky funkci Půdorys můžete vidět dispoziční řešení daného objektu a zároveň se umíte rychle přesouvat na jednotlivé scény. Všechny pozice jsou graficky vykresleny přímo na půdorysu."
        },
        "layers" : {
          "title" : "Vrstvy",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Pomocí funkce vrstvy si můžete změnit vzhled virtuální prohlídky. Ať už se jedná o zařízení konkrétního bytu, nebo celkovou změnu designu."
        },
        "3d_model" : {
          "title" : "3D model",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Pomocí funkce 3D model si umíte prohlédnout 3D vizualizaci všech produktů, které lze konfigurovat v rámci virtuální prohlídky. 3D model produktu obsahuje i jeho přesné rozměry a po zvolení požadované varianty se tato změna projeví i na samotné přehlídce."
        },
        "social" : {
          "title" : "Sdílení",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Díky funkci Sdílení můžete jednoduše zkopírovat odkaz stránky, na které se právě nacházíte, nebo sdílet tuto stránku na sociálních sítích a podělit se tak o svůj záčitek se svými blízkými."
        },
        "attributes" : {
          "title" : "Atributy",
          "description" : "Funkce atributy slouží ke konfiguraci jednotlivých produktů dle Vašich požadavků. Pokud má některý z produktů více variant, pomocí atributů si zvolíte požadovaný materiál a změna se projeví okamžitě i ve virtuální prohlídce."
        },
        "google_map" : {
          "title" : "Google Mapa",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Google Mapa slouží k zobrazení přesné polohy objektu, ve kterém se právě pohybujete."
        },
        "tour_info" : {
          "title" : "Tour info",
          "tooltip-title" : "Součást sidebaru",
          "description" : "V sekci Tour info se nacházejí všeobecné informace a zajímavosti dané virtuální prohlídky."
        },
        "measure_1" : {
          "title" : "Libovolné měření",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Pomocí funkce Libovolné měření můžete změřit jakoukoli část v rámci virtuální prohlídky. Stačí kliknout dvakrát na místě kde chcete začít měřit, následně potáhnout myš na místo, kde chcete měření ukončit a znovu kliknout dvakrát."
        },
        "measure_2" : {
          "title" : "Protilehlé měření",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Pomocí funkce Protilehlé měření můžete změřit například celkový rozměr pokoje. Stačí kliknout 2x na jakoukoli plochu a tato funkce Vám automaticky dopočítá vzdálenost protilehlé plochy."
        },
        "bookcall" : {
          "title" : "Rezervovat meeting",
          "tooltip-title" : "Součást sidebaru",
          "description" : "Díky funkci Rezervovat meeting si umíte domluvit videohovor s manažerem zodpovědným za konkrétní projekt. Pomocí kalendáře si umíte jednoduše prohlédnout volný termín a zarezervovat se na přesný čas. Manažer Vás bude následně kontaktovat."
        },
        "pdf" : {
          "title" : "Vytvořit PDF",
          "tooltip-title" : "Súčasť košíka",
          "description" : "Pomocí funkce vytvořit PDF sí víte jednoduše vygenerovat PDF soubor s doplňkovými informacemi a kompletním výpisem produktů, které jste si zvolili během konfigurace bytu."
        },
        "smooth_transition" : {
          "title" : "Plynulý přechod",
          "description" : "Funkce plynulého přechodu dodává virtuální prohlídce ještě lepší uživatelský zážitek. V prohlídce se nenacházejí žádné přechodové hotspoty. Přesouváte se tedy kliknutím na část podlahy a následně se přesunete na na toto místo. Pokud si přejete tyto přechodové hotspoty vidět, můžete si je zapnout v nastavení virtuální prohlídky."
        },
        

      },
      otacadlo:  {
        "floors" : "Patra",
        "buildings" : "Budovy",
        "orbit" : "Orbit",
        "playground" : "Exteriér",
        "buildings-vysocany" : "Model",
        "orbit-vysocany" : "Okolí",
        "playground-vysocany" : "Exteriér",
        "skypark" : "Skypark",
        "castle" : "Zámek",
        "centrum" : "Centrum",
        "woowstudio" : "Woowstudio",
        "visit-tour" : "Vstoupit do VR",
        "lobby" : "Lobby",
        "office-type" : "One office",
        "more-info" : "Více info",
        "interier" : "Interiér"
      },
      tooltips : {
        "flat-filter" : "Filter",
        "sidebar" : "Menu",
        "shortcuts" : "Zkratky",
        "screenshot" : "Focení",
        "back-to-rotator" : "Zpátky na rotátor"
      }
    },
    admin_components : {
      settings : {
        title : "Nastavení",
        save_button : "Uložit nastavení"
      },
      clear_data : {
        "admin_title" : "Vymazat data"
      },
      import_data : {
        "admin_title" : "Importovat data"
      },
      new_scenes : {
        "admin_title" : "Nastavení scén"
      }
    }
  }
};
  