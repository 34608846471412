<script>
    import { onMount } from 'svelte';
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
    import { writable, get } from 'svelte/store';

    import { fields } from "../../store.js";
  
    let loading_data = 0;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    let current_floor = "";

    let settings;
    let current_sample_flat, current_sample_flat_scene= null;
    let krpano_design_variable = 1;
    let krpano_floor_variable = 1;
    let sample_flats, sample_flats_scenes, scenes_settings, products = [];

    let krpano;

    setTimeout(() => {
      krpano = document.getElementById("krpanoSWFObject");
    }, 1000);

    

    function change_floor() {
      krpano = document.getElementById("krpanoSWFObject");
      console.log(current_floor);
      var formattedFloors = ("0" + current_floor).slice(-2);
      krpano.set("floors", formattedFloors );
    }

    let krpano_current_scene = null;
    let krpano_current_orientation = {
      "hlookat" : null,
      "vlookat" : null
    };

    let default_fields = get(fields);

    $: {
      default_fields;
      fields.update((n) => default_fields);
    }

     // načítanie vzorových bytov z DB
     async function getProducts() {
      let data = {id : "products"};
      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data)); 
      const res = await fetch(
        userdata["cdn_path"] + "php/admin/getData.php", {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        products = json;
        console.log(products);

        return json;
      } else {
        throw new Error(data);
      }
    }
    getProducts();
    

    // načítanie vzorových bytov z DB
    async function getSampleFlats() {
      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      const res = await fetch(
        userdata["cdn_path"] + "php/admin/getConfiguratorSampleFlats.php",{
          method: "POST",
          body: JSON.stringify(parameters),
        }
      );

      if (res.ok) {
        const json = await res.json();
        sample_flats = json;
        current_sample_flat = sample_flats[0].name;
        loading_data++;

        return json;
      } else {
        throw new Error(data);
      }
    }
    getSampleFlats();

    // načítanie všetkých scén vzorových bytov z DB
    async function getSampleFlatsScenes() {
      const res = await fetch(
        userdata["cdn_path"] + "php/admin/getConfiguratorSampleFlatsScenes.php",
        {
          method: "POST",
          body: JSON.stringify(parameters),
        }
      );

      if (res.ok) {
        const json = await res.json();
        sample_flats_scenes = json;
        current_sample_flat_scene = sample_flats_scenes[0].name;
        loading_data++;

        console.log(json);

        sample_flats_scenes.forEach(element => {
            if (
              element.enabled == "1"
            ) {
              element.enabled = true
            }

            if (
              element.enabled == "0"
            ) {
              element.enabled = false
            }

            if (
              element.enable_design == "1"
            ) {
              element.enable_design = true
            }

            if (
              element.enable_design == "0"
            ) {
              element.enable_design = false
            }

            if (
              element.enable_floors == "1"
            ) {
              element.enable_floors = true
            }

            if (
              element.enable_floors == "0"
            ) {
              element.enable_floors = false
            }

        });

        return json;
      } else {
        throw new Error(data);
      }

      
    }
    getSampleFlatsScenes();

    // načítanie nastavenia projektu z DB
    async function getScenesSettings() {
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getConfiguratorScenesSettings.php",
        {
          method: "POST",
          body: JSON.stringify(parameters),
        }
      );
  
      if (res.ok) {
        const json = await res.json();
        scenes_settings = json;
        loading_data++;

        //  konvert boolean premennej
        scenes_settings.forEach(element => {
            if (
              element.enabled == "1"
            ) {
              element.enabled = true
            }

            if (
              element.enabled == "0"
            ) {
              element.enabled = false
            }

            if (
              element.enable_design == "1"
            ) {
              element.enable_design = true
            }

            if (
              element.enable_design == "0"
            ) {
              element.enable_design = false
            }

            if (
              element.enable_floors == "1"
            ) {
              element.enable_floors = true
            }

            if (
              element.enable_floors == "0"
            ) {
              element.enable_floors = false
            }

        });
      
        
        return json;
      } else {
        throw new Error(data);
      }
    }
    getScenesSettings();

    // Pridanie všetkých scén do Krpana
    function add_krpano_scenes() {
      sample_flats_scenes.forEach(element => {
        if (
          element['sample_flat_id'] == current_sample_flat
        ) {

          let model = false;

          sample_flats.forEach(byt => {
            if (byt.name == current_sample_flat && byt.enable_smooth_transition == "1") {
              console.log(byt);
              model = true;
            }
          });

          if (
            element['enable_design'] == false && element['enable_floors'] == false
          ) {
            if (!model) {
              krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] +  "scenes/" + element['name'] + "_%s.jpg'/></image>");
            } else  {
              krpano.set("scene[" + element['name'] + "].content", "<image style='" + element['name'] + "' type='CUBE'><cube url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes/" + element['name'] + "_%s.jpg'/><depthmap url='" + userdata["base_path"] + "assets/models/" +  current_sample_flat +".obj' hittest='true' rendermode='3dmodel' center='" + element['position_y']+ "," + element['position_z']+ "," + (element['position_x']* -1) + "' enabled='true' scale='1.0'/></image>");
              krpano.set("scene[" + element['name'] + "].model", true);   
            }
            
          }

          if (
            element['enable_design'] == true && element['enable_floors'] == false
          ) {
            
            krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='" + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes/" + element['name'] + "_%$dizajn%" +  "_%s.jpg'/></image>");
          }

          if (
            element['enable_design'] == false && element['enable_floors'] == true
          ) {
            
            krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='"  + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes/" + element['name'] + "_%$floors%" +  "_%s.jpg'/></image>");
          }

          if (
            element['enable_design'] == true && element['enable_floors'] == true
          ) {
            
            krpano.set("scene[" + element['name'] + "].content", "<image type='CUBE'><cube url='"  + userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "scenes/" + element['name'] + "_%$dizajn%_%$floors%" +  "_%s.jpg'/></image>");
          }
          
        }
      });

      // načítanie prvej scény pre konkrétny byt
      load_first_scene();

      import_hotspots();
    }

    // importovanie hotspotov
    function import_hotspots() {
      let action = 'draghotspot();';
      let bigger = 'bigger();';
      let normal = 'normal();';
      if (
        hotspots != null && krpano_current_scene != null
      ) {
        if (krpano == null) {
          krpano = document.getElementById("krpanoSWFObject");
        }
        for (let index = 0; index < hotspots.length; index++) {
          if (
            hotspots[index].scene_id.toLowerCase() == krpano_current_scene.toLowerCase()
            
          ) {
            console.log(hotspots[index].id);
            krpano.call("addhotspot(hts_" + hotspots[index].id + ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].keep, false);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ath," +hotspots[index].ath+ ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].atv," +hotspots[index].atv+ ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ondown, " + action + ");");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].distorted, false);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].rx, 0);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ry, 0);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].alpha, 1.0);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].zorder, 2);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, auto);");
            krpano.call("set(hotspot[hts_" + hotspots[index].id + "].height, prop);");
            
            switch (hotspots[index].skin) {
              case 'arrow' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/arrow-floor.png);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width," + hotspots[index].width + "px);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].height, prop);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].rx," + hotspots[index].rx + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ry," + hotspots[index].ry + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].distorted, true);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].atv, 30);");
                

                // // tieň
                // krpano.call("addhotspot(hts_0_" + hotspots[index].id + ");");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].keep, false);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].ath," +hotspots[index].ath+ ");");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].url, assets/krpano/icons/arrow-floor-dark.png);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].width," + hotspots[index].width + "px);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].height, prop);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].rx," + hotspots[index].rx + ");");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].distorted, true);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].atv, 30.6);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].alpha, 0.4);");
                // krpano.call("set(hotspot[hts_0_" + hotspots[index].id + "].zorder, 1);");
                
              break;

              case 'floorspot' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts-ring-w.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width," + hotspots[index].width + "px);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].height, prop);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].rx," + hotspots[index].rx + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].ry," + hotspots[index].ry + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].distorted, true);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].atv," + hotspots[index].atv + ");");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].alpha, 1.0);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].zorder, 2);");
              break;

              case 'info' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_infopanel.svg);");
              break;

              case 'video' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_videopanel.svg);");
              break;

              case 'tag' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_tag.svg);");
              break;

              case 'attribute-podlaha' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_podlaha.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-lista' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_lista.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-sanita' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_sanita.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-sanita2' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_sanita2.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-obklad' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_obklad.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-obklad2' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_obklad2.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-dlazba' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_dlazba.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              case 'attribute-dvere' : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_dvere.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;

              default : 
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].url, assets/krpano/icons/hts_attribute_podlaha.svg);");
                krpano.call("set(hotspot[hts_" + hotspots[index].id + "].width, 32px);");
              break;
            }

            krpano.set("hotspot[hts_"+hotspots[index].id+"].onout", function(hs) {
              hotspots[index].ath = Number(krpano.get("hotspot[hts_"+hotspots[index].id+"].ath"));
              hotspots[index].atv = Number(krpano.get("hotspot[hts_"+hotspots[index].id+"].atv"));
              //console.log(hotspots[index].ath);
              //console.log(hotspots[index].atv);
            });

          }
          
        }        
        


      }
    }

    // načítanie prvej scény
    function load_first_scene() {
      let stop = false;
      sample_flats_scenes.forEach(element => {
        if ( element['sample_flat_id'] == current_sample_flat && !stop) { 
          if (krpano == null) {
            krpano = document.getElementById("krpanoSWFObject");
          }
          krpano.call("loadscene(" + element['name']+ ", null, MERGE)");
          krpano.call("lookto(" + element['hlookat'] + "," + element.vlookat +")");
          krpano_current_scene = element['name'];
          stop = true;
        }
      });
    }

    let first_time_krpano_loading = false;
    let initVars = {
      "dizajn" : "zar",
      "floors" : ""
    };

    $: {
      loading_data;
      if (
        loading_data == 3 && first_time_krpano_loading == false
      ) {
        if (embedpano == null || embedpano == undefined) {
          setTimeout(() => {
            embedpano({
              target:"pano", 
              initvars : initVars, 
              xml:  userdata["cdn_path"] + "krpano/pano.xml", 
              html5:"only", 
              showlog:"true",
              mobilescale:1.0, 
              onready: function (krpano) {
                first_time_krpano_loading = true;
                krpano = document.getElementById("krpanoSWFObject");
                load_first_scene();
              }
            });
          }, 1000);
        } else {
          embedpano({
            target:"pano", 
            initvars : initVars, 
            xml:  userdata["cdn_path"] + "krpano/pano.xml", 
            html5:"only", 
            showlog:"true",
            mobilescale:1.0, 
            onready: function (krpano) {
              first_time_krpano_loading = true;
              krpano = document.getElementById("krpanoSWFObject");
              load_first_scene();
            }
          });
        }
      }
    }

    $: {
      current_sample_flat;
      if ( krpano != null)  {
        add_krpano_scenes();
      }
    }

    function change_scene($id) {
      krpano_current_scene = $id;           

      krpano.call("loadscene(" + $id +", null, MERGE)");
      import_hotspots();
      hotspot_mode = true;
    }

    // načítanie pôvodnej orientácie scény
    function load_scene($id) {
      if (
        krpano == null
      ) {
        krpano = document.getElementById("krpanoSWFObject");
      }
      krpano.call("lookto(" + $id.hlookat + "," + $id.vlookat + ")");
    }
    
    // uloženie novej default orientácie
    async function save_scene_orientation($id) {
      let new_hlookat = krpano.get("view.hlookat");
      let new_vlookat = krpano.get("view.vlookat");

      $id["hlookat"] = new_hlookat;
      $id["vlookat"] = new_vlookat;

      let data = {
        "scene" : $id["name"],
        "hlookat" : new_hlookat,
        "vlookat" : new_vlookat
      }

      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/saveNewSceneOrientation.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia orientácie boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia orientácie neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 

    // uloženie nových nastavení scény  + uloženie pozície hotspotov
    async function save_scene_settings($id) {

      
      let data = {
        hotspots : {push:function push(element){ [].push.call(this,element)}},
        flat : $id
      }
      // zistenie hotspotov
      for (let index = 0; index < hotspots.length; index++) {
        const element = hotspots[index];
        
        if (
          element.scene_id == krpano_current_scene
        ) {
          data.hotspots.push(element);
        }
      }

      //console.log(data);

      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/saveNewSceneSettings.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia scény boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia scény neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 

    // uloženie štartovacej scény 
    async function save_start_scene($id, $sample_flat) {
      let data = {
        "id" : $id,
        "sample_flat" : $sample_flat
      };

      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
    
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/saveNewSceneAsStartScene.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Scéna bola nastavená ako štartovacia !",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Scéna nebola nastavená ako štartovacia !",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 

    let fields_enabled = false;

    // zobrazenie / skrytie kontajnera s možnosťami polí
    function toggle_fields() {
      fields_enabled = !fields_enabled;
    }

    let mode = true; // true == scenes, false == hotspots
    let hotspot_mode = true; // true == zoznam hotspotov, false == pridať nový / upraviť hotspot
    
    // parametre hotspotu
    let hotspot_type = "next-node"; // next-node, info, tag
    let hotspot_skin = "floorspot"; // arrow, floorspot, info-v1, info-v2 ... 
    let hotspot_version = "info-v1"; // info-v1, info-v2 ... 
    let hotspots = [];
    let content_title,content_headline,content_img,content_subtitle,content_text,content_url = null;

    // pridanie nového hotspotu
    async function add_hotspot () {
      console.log("pridávam hotspot");
      let ath = krpano.get("view.hlookat");
      let atv = krpano.get("view.vlookat");
      let rx, ry, width;

      switch (hotspot_skin) {
        case "floorspot":
          rx = -45;
          ry = 0;
          width = 150;
          break;
        
        case "arrow":
          rx = -60;
          ry =  0;
          width = 80;
          break;
      
        default:
          rx = 0;
          ry =  0;
          width = 40;
          break;
      }

      let data = {
        "type" : hotspot_type,
        "skin" : hotspot_skin,
        "version" : hotspot_version,
        "ath" : ath,
        "atv" : atv,
        "scene_id" : krpano_current_scene,
        "rx" : rx,
        "ry" : ry,
        "width" : width,
        "content_title" : null,
        "content_headline" : null,
        "content_img" : null,
        "content_subtitle" : null,
        "content_text" : null,
        "content_url" : null,
        "product_id" : null, 
        "tag_title" : null,
        "tag_price" : null,
        "tag_img" : null,
        "tag_content" : "", 
        "tag_link" : null,
        "enable_permanent" : false
      }; 

      //console.log(data);
      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/AddHotspot.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);
        //console.log(json);
        if (json.status == true) {
          addNotification({
            text: "Hotspot bol pridaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });  
          
          getHotspots();
          hotspot_mode = false;
          editHotspot(json.hotspot_id);
        }

        else {
          addNotification({
                text: "Chyba : Hotspot nebol pridaný!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 

    // načítanie nastavenia projektu z DB
    async function getHotspots() {
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getHotspots.php",
        {
          method: "POST",
          body: JSON.stringify(parameters),
        }
      );
  
      if (res.ok) {
        const json = await res.json();

        json.data.forEach(element => {
          const keys = Object.keys(element);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                (key.includes("enable") && element[key] == "0")
            ) {
                element[key] = false
            }

            if (
                (key.includes("enable") && element[key] == "1")
            ) {
                element[key] = true
            }
          }  
        });
        

        hotspots = json.data;
        console.log(json);
        import_hotspots();
        return json;
      } else {
        throw new Error(data);
      }
    }
    getHotspots();

    // načítanie nastavenia projektu z DB
    async function removeHotspot($id) {
      for (let index = 0; index < hotspots.length; index++) {
        if (
          hotspots[index].id == $id
        ) {
          krpano.call('removehotspot(hts_'+ ($id) + '));');
          krpano.call('removehotspot(hts_0_'+ ($id) + '));');
        }
        
      }
      

      let data = {
        "id" : $id
      };

      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/removeHotspot.php",
        {
          method: "POST",
          body: formData,
        }
      );
  
      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);
        //console.log(json);
        if (json.status == true) {
          addNotification({
            text: "Hotspot bol zmazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });  
          //hotspot_mode = false;
          getHotspots();
        }

        else {
          addNotification({
                text: "Chyba : Hotspot nebol zmazaný!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }

    let current_hotspot = null;
    function editHotspot($id) {
      // prepnutie módu na editovanie hotspotu
      hotspot_mode = false;
      current_hotspot = $id;

      for (let index = 0; index < hotspots.length; index++) {
        
        if ( hotspots[index].id == $id) {
          live_preview_data.title = hotspots[index].content_title;
          live_preview_data.headline = hotspots[index].content_headline;
          live_preview_data.img = hotspots[index].content_img;
          live_preview_data.subtitle = hotspots[index].content_subtitle;
          live_preview_data.text = hotspots[index].content_text;
          live_preview_data.url = hotspots[index].content_url;
          modal_type_tmp = hotspots[index].version;
        }
        
      }
    }

    // uloženie nových nastavení scény
    async function save_hotspot_setting ($hotspot, $return) {
      let data = $hotspot; 

      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      //console.log(data);
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/SaveHotspot.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);
        //console.log(json);
        if (json.status == true) {
          addNotification({
            text: "Hotspot bol uložený!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });  
          
          getHotspots();

          if ($return == true) {
            hotspot_mode = true;
          }
        }

        else {
          addNotification({
                text: "Chyba : Hotspot nebol uložený!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 

      // upload loga na server
  async function uploadFile($id, $type)  {
    let formData = new FormData();           
    formData.append("file", fileupload.files[0]);
    formData.append("type", $type);
    formData.append("parameters", JSON.stringify(parameters));
    
    const res = await fetch(userdata['cdn_path'] + 'php/admin/saveImg.php', {
    method: "POST", 
    body: formData
    });    

    if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
        addNotification({
                text: "Obrázok bol úspešne uploadnutý!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
            });  

            
            if (json.logo_url) {  
              for (let index = 0; index < hotspots.length; index++) {
                
                if(hotspots[index].id == $id) {

                  switch ($type) {
                    case "info":
                      hotspots[index].content_img = json.logo_url;
                      live_preview_data.img = json.logo_url;    
                      break;
                    
                      case "tag" :
                        hotspots[index].tag_img = json.logo_url;
                        break;
                  
                    default:
                      break;
                  }

                  
                }
                
              }
            }
        }

        else {
        addNotification({
                text: "Chyba : Logo nebolo uploadnuté!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
            });  
        }
    }  
  }

    // po kliku na hotspot v zozname natočenie kamery
    function rotate_to_hotspot($ath, $atv) {
      if (
        krpano == null
      ) {
        krpano = document.getElementById("krpanoSWFObject");
      }
      krpano.call("lookto(" + $ath + "," + $atv + ")");
    }

    // LIVE úprava RX hotspotu
    function change_rx($id, $rx) {
      if (
        krpano == null
      ) {
        krpano = document.getElementById("krpanoSWFObject");
      }
      krpano.call("set(hotspot[hts_" + $id + "].rx," + $rx + ");");
      krpano.call("set(hotspot[hts_0_" + $id + "].rx," + $rx + ");");
    }

    // LIVE úprava RY hotspotu
    function change_ry($id, $ry) {
      if (
        krpano == null
      ) {
        krpano = document.getElementById("krpanoSWFObject");
      }
      krpano.call("set(hotspot[hts_" + $id + "].ry," + $ry + ");");
      krpano.call("set(hotspot[hts_0_" + $id + "].ry," + $ry + ");");
    }

    // LIVE úprava širky hotspotu
    function change_width($id, $width) {
      if (
        krpano == null
      ) {
        krpano = document.getElementById("krpanoSWFObject");
      }
      krpano.call("set(hotspot[hts_" + $id + "].width," + $width + "px);");
      krpano.call("set(hotspot[hts_0_" + $id + "].width," + $width + "px);");
    }

    // live preview mód
    let live_preview = false;
    let live_modal_type = 'info-v1';

    let live_preview_data = {
      "title" : null,
      "headline" : null,
      "img" : null,
      "subtitle" : null,
      "text" : null,
      "url" : null
    }

    $:{
      live_preview;
      //console.log(live_preview);
      modal.update(n => live_preview);
    }

    
    // zapnutie / vypnutie modalu
    import { modal } from '../../store.js';
    import { modal_type } from '../../store.js';

    modal_type.update(n => live_modal_type);
    let show_modal;
  	modal.subscribe(value => {
      live_preview = value;
    });

    let Modal;
    let modal_type_tmp = "info-v1";
    const sleep = ms => new Promise(f => setTimeout(f, ms));
	  onMount(async () => {
      //await sleep(1000); // simulate network delay
      Modal = (await import('../../tour_components/Modal.svelte')).default
	  });


let banner_width = 515 * 2;
let banner_height = 200 * 2;

let simulate_screenshot = false;
let cross = false;


let update_dizajn = null;

function change_dizajn() {
 let krpano = document.getElementById("krpanoSWFObject");
  krpano.set("dizajn", update_dizajn);
  let current_scene = krpano.get("xml.scene").toUpperCase();
  krpano.call("loadscene(" + current_scene + ", null, MERGE)");
}


</script>

<svelte:head>
  <script src="{userdata['cdn_path']}krpano/pano.js"></script>
</svelte:head>


  <div id="wrapper">
    <Aside />

    
    <main class="main-scenes" in:fade>
      
      <h1>{$t("admin_components")["new_scenes"]["admin_title"]}</h1>
      {#if loading_data > 2 }
        <div id="navigation">
          <select bind:value={current_sample_flat}>
            {#each sample_flats as flat}
              <option value={flat.name}>
                {flat.name}
              </option>
            {/each}
          </select>
          <div id="nav-buttons">
            <button class:active={mode == true} id="show_scenes" on:click={() => mode = true}>Show scenes</button>
            <button class:active={mode == false} id="show_hotspots" on:click={() => mode = false}>Show hotspots</button>
          </div>
        </div>
      {/if}
      
      <div id="content">
        <div id="viewer">
          <div id="pano" style="width:100%;height:600px;"></div>
          {#if live_preview}
            <svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" source={live_preview_data} title="" settings="{settings}" url="" filter=""  position="" id="" current_scene="{current_scene}" prefix="{userdata['base_path']}"/>
          {/if}

          <div id="screenshot-icon" on:click={() => simulate_screenshot = !simulate_screenshot}/>
          <div id="cross-icon" on:click={() => cross = !cross}/>

          {#if simulate_screenshot == true}
              <div id="screenshot" style="width: {banner_width}px; height: {banner_height}px;"/>
          {/if}

          {#if cross == true}
              <div id="cross"/>
          {/if}
          <script>
            
          </script>
        </div>
         
        <!-- nastavenia scény / hotspotu -->
        <div id="settings">

          <div class="item-container">

          <!-- nastavenia scény -->
          {#if mode == true}
            {#if loading_data > 2 }    
              {#each sample_flats_scenes as flat}
                {#if flat.name.toLowerCase() == krpano_current_scene.toLowerCase()}  
                  <!-- id scény -->
                  {#if default_fields[0].hodnota}
                    <div class="item">
                      <label>ID: </label>
                      <input type="text" disabled value={flat.id}/>
                    </div>
                  {/if}

                  <!-- názov scény -->
                  {#if default_fields[1].hodnota}
                    <div class="item">
                      <label>Name: </label>
                      <input type="text" disabled value={flat.name}/>
                    </div>
                  {/if} 
                  
                   <!-- názov scény -->
                   
                   <div class="item">
                     <label>Model name: </label>
                     <input type="text" bind:value={flat.model_name}/>
                   </div>
                  

                  <!-- ID vzorového bytu -->
                  {#if default_fields[2].hodnota}
                    <div class="item">
                      <label>Sample flat: </label>
                      <select bind:value={flat.sample_flat_id}>
                        {#each sample_flats as flat}
                          <option value={flat.name}>
                            {flat.name}
                          </option>
                        {/each}
                      </select>
                    </div>
                  {/if}

                  <!-- Je scéna povolená? -->
                  {#if default_fields[3].hodnota}
                    <div class="item radio-item">
                      <p>Povolená ?</p>
                      <label>{$t("components")["configurator"]["yes"]}
                        <input id="true" type="radio" bind:group={flat.enabled} value={true}/>
                      </label>
                      <label>{$t("components")["configurator"]["no"]}
                        <input id="false" type="radio" bind:group={flat.enabled} value={false}/>
                      </label>
                    </div>
                  {/if}

                  <!-- hlookat -->
                  {#if default_fields[4].hodnota}
                    <div class="item">
                      <label>Default hlookat: </label>
                      <input type="number" bind:value={flat.hlookat}/>
                    </div>
                  {/if}

                  <!-- vlookat -->
                  {#if default_fields[5].hodnota}
                    <div class="item">
                      <label>default vlookat: </label>
                      <input type="number" bind:value={flat.vlookat}/>
                    </div>
                  {/if}

                  <!-- Fov scény -->
                  {#if default_fields[6].hodnota}
                    <div class="item">
                      <label>Fov: </label>
                      <input type="number" bind:value={flat.number}/>
                    </div>
                  {/if}

                  <!-- Title scény -->
                  {#if default_fields[7].hodnota}
                    <div class="item">
                      <label>Title: </label>
                      <input type="text" bind:value={flat.title}/>
                    </div>
                  {/if}

                  <!-- Description scény -->
                  {#if default_fields[8].hodnota}
                    <div class="item">
                      <label>Description: </label>
                      <textarea bind:value={flat.description}/>
                    </div>
                  {/if}

                  <!-- custom field #1 -->
                  {#if default_fields[9].hodnota}
                    <div class="item">
                      <label>custom field #1: </label>
                      <input type="text" bind:value={flat.custom_field_1}/>
                    </div>
                  {/if}

                  <!-- custom field #2 -->
                  {#if default_fields[10].hodnota}
                    <div class="item">
                      <label>custom field #2: </label>
                      <input type="text" bind:value={flat.custom_field_2}/>
                    </div>
                  {/if}

                  <!-- custom field #3 -->
                  {#if default_fields[11].hodnota}
                    <div class="item">
                      <label>custom field #3: </label>
                      <input type="text" bind:value={flat.custom_field_3}/>
                    </div>
                  {/if}

                  <!-- custom field #4 -->
                  {#if default_fields[12].hodnota}
                    <div class="item">
                      <label>custom field #4: </label>
                      <input type="text" bind:value={flat.custom_field_4}/>
                    </div>
                  {/if}

                  <!-- custom field #5 -->
                  {#if default_fields[13].hodnota}
                    <div class="item">
                      <label>custom field #5: </label>
                      <input type="text" bind:value={flat.custom_field_5}/>
                    </div>
                  {/if}

                  <!-- custom field #6 -->
                  {#if default_fields[14].hodnota}
                    <div class="item">
                      <label>custom field #6: </label>
                      <input type="text" bind:value={flat.custom_field_6}/>
                    </div>
                  {/if}

                  <!-- custom field #7 -->
                  {#if default_fields[15].hodnota}
                    <div class="item">
                      <label>custom field #7: </label>
                      <input type="text" bind:value={flat.custom_field_7}/>
                    </div>
                  {/if}

                  <!-- custom field #8 -->
                  {#if default_fields[16].hodnota}
                    <div class="item">
                      <label>custom field #8: </label>
                      <input type="text" bind:value={flat.custom_field_8}/>
                    </div>
                  {/if}

                  <!-- custom field #9 -->
                  {#if default_fields[17].hodnota}
                    <div class="item">
                      <label>custom field #9: </label>
                      <input type="text" bind:value={flat.custom_field_9}/>
                    </div>
                  {/if}

                  <!-- custom field #10 -->
                  {#if default_fields[18].hodnota}
                    <div class="item">
                      <label>custom field #10: </label>
                      <input type="text" bind:value={flat.custom_field_10}/>
                    </div>
                  {/if}

                  <!-- shortcut tag -->
                  {#if default_fields[19].hodnota}
                    <div class="item">
                      <label>Shortcut tag: </label>
                      <input type="text" bind:value={flat.shortcut_tag}/>
                    </div>
                  {/if}

                  <!-- floorplan tag -->
                  {#if default_fields[20].hodnota}
                    <div class="item">
                      <label>Floorplan tag: </label>
                      <input type="text" bind:value={flat.floorplan_tag}/>
                    </div>
                  {/if}

                  <!-- poradie -->
                  {#if default_fields[21].hodnota}
                    <div class="item">
                      <label>Order: </label>
                      <input type="number" bind:value={flat.poradie}/>
                    </div>
                  {/if}

                  <!-- Je povolený dizajn ? -->
                  
                    <div class="item radio-item">
                      <p>Dizajn ?</p>
                      <label>{$t("components")["configurator"]["yes"]}
                        <input id="true" type="radio" bind:group={flat.enable_design} value={true}/>
                      </label>
                      <label>{$t("components")["configurator"]["no"]}
                        <input id="false" type="radio" bind:group={flat.enable_design} value={false}/>
                      </label>
                    </div>

                     <!-- zmena dizajnu -->
                  
                  <div class="item">
                    <label>Dizajn: </label>
                    <input type="text" bind:value={update_dizajn}/>
                    <button on:click={() => change_dizajn()}>zmeniť</button>
                  </div>
                  

                  <!-- Je povolené poschodie ? -->
                  
                    <div class="item radio-item">
                      <p>Floors ?</p>
                      <label>{$t("components")["configurator"]["yes"]}
                        <input id="true" type="radio" bind:group={flat.enable_floors} value={true}/>
                      </label>
                      <label>{$t("components")["configurator"]["no"]}
                        <input id="false" type="radio" bind:group={flat.enable_floors} value={false}/>
                      </label>
                    </div>
                  

                 
                

                <div class="item">
                  <label>Floor: </label>
                  <input type="text" bind:value={current_floor}/>
                  <button on:click={() => change_floor()}>zmeniť</button>
                </div>

                  <div id="buttons">
                    <button id="save_current_scene_orientation" on:click={save_scene_orientation(flat)}>
                      <i class="fa-solid fa-gear"></i>
                      Save scene orientation
                    </button>
                    <button id="load_current_scene_orientation" on:click={load_scene(flat)}>
                      <i class="fa-solid fa-rotate"></i>
                      {$t("components")["new_scenes"]["load_scene_orientation"]}
                    </button>
                    <button id="save_as_start_scene" on:click={save_start_scene(flat.id, flat.sample_flat_id)}>
                      <i class="fa-solid fa-flag"></i>
                      Save as start scene
                    </button>
                    <button id="save_current_scene_settings" on:click={save_scene_settings(flat)}>
                      <i class="fa-solid fa-floppy-disk"></i>
                      Save scene settings
                    </button>
                  </div>
                {/if}
              {/each}
            {/if}
          
            <!-- nastavenia zobrazenia polí pre scénu -->
            <div id="fields" class:active={ fields_enabled == true}>
              {#each default_fields as field}
                <div>
                  <label for="enabled">{field["nazov"]}</label>
                  <input type="checkbox" name="enabled" bind:checked="{field["hodnota"]}">
                </div>
              {/each}
            </div>

            <!-- button pre zobrazenie nastavení polí -->
            <div class="field" on:click={() => toggle_fields()}>
              <i class="fa-solid fa-gear"></i>
            </div>
          {/if}

          <!-- nastavenia hotspotov -->
          {#if mode == false}
            {#if hotspot_mode == true}
              <button id="add-hotspot" on:click={() => add_hotspot()}>Add hotspot</button>
              {#each hotspots as hotspot}

                <!-- výpis aktuálnych hotspotov pre aktívnu scénu -->
                {#if hotspot.scene_id.toLowerCase() == krpano_current_scene.toLowerCase()}
                  <div class="hotspot" on:click={() => rotate_to_hotspot(hotspot.ath, hotspot.atv)}>
                    <div class="hotspot-info">
                      {#if hotspot.type == "next-node"}
                        <i class=" {hotspot.type} fa-solid fa-person-walking-arrow-right"></i>
                      {:else if  hotspot.type == "info"}
                        <i class="{hotspot.type} fa-solid fa-info"></i>

                      {:else if  hotspot.type == "tag"}
                        <i class="{hotspot.type} fa-solid fa-tag"></i>
                      {:else if  hotspot.type == "attribute"}
                        <i class="{hotspot.type} fa-solid fa-basket-shopping"></i>
                      {:else}
                        <!-- else content here -->
                      {/if}
                      
                      <!-- id hotspotu -->
                      <p>{hotspot.id}</p>

                      <!-- target scéna -->
                      {#each sample_flats_scenes as scene}
                        {#if hotspot.target_scene_id.toLowerCase() == scene.id.toLowerCase()}
                          <p>{scene.name}</p>
                        {/if}
                      {/each}
                      
                      <p>{hotspot.width}</p>

                      <p>{hotspot.rx}</p>
                      <p>{hotspot.ry}</p>

                      {#if hotspot.type == "attribute"}
                        {#each products as item}
                          {#if hotspot.product_id == item.id}
                            <p>{item.name}</p>
                          {/if}
                        {/each}
                      {/if}

                    </div>
                    <div class="hotspot-buttons">
                      <button class="edit" on:click={() => editHotspot(hotspot.id)}>Edit</button>
                      <button class="remove" on:click={() => removeHotspot(hotspot.id)}>Remove</button>
                     </div>
                  </div>              
                {/if}
              {/each}
            {/if}
            {#if hotspot_mode == false}
              
              {#each hotspots as hotspot}
                {#if hotspot.id == current_hotspot}

                <!-- <button id="cancel" on:click={() => hotspot_mode = true}>Cancel</button>
                <button id="save" on:click={() => save_hotspot_setting(hotspot, false)}>Save</button>
                <button id="save" on:click={() => save_hotspot_setting(hotspot, true)}>Save & close</button> -->

                <!-- id hotspotu -->
                  <div class="item">
                    <label>ID: </label>
                    <input type="text" disabled value={hotspot.id}/>
                  </div>

                  <div class="item">
                    <label>ATH: </label>
                    <input type="number" bind:value={hotspot.ath}/>
                  </div>

                  <div class="item">
                    <label>ATV: </label>
                    <input type="number" bind:value={hotspot.atv}/>
                  </div>
                  <!-- typ hotspotu -->
                  <div class="item">
                    <label>Type: </label>
                    <select bind:value={hotspot.type} on:change={() => hotspot_type = hotspot.type }>
                      <option value="next-node">Next node</option>
                      <option value="info">Info / video</option>
                      <option value="tag">Tag</option>
                      <option value="attribute">Attribute</option>
                    </select>
                  </div>

                  {#if hotspot.type == "next-node"}
                    <!-- výber skinu -->
                    <div class="item">
                      <label>Skin: </label>
                      <select bind:value={hotspot.skin} on:change={() => hotspot_skin = hotspot.skin }>
                        <option value="floorspot">Floorspot</option>
                        <option value="arrow">Arrow</option>
                      </select>
                    </div>
                    <!-- výber odkazu na panorámu -->
                    <div class="item">
                      <label>Target scene: </label>
                      <select bind:value={hotspot.target_scene_id}>
                        {#each sample_flats_scenes as scene}
                          {#if scene.sample_flat_id == current_sample_flat}
                            <option value={scene.id}>{scene.name}</option>
                          {/if}
                        {/each}
                      </select>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RX: </label>
                      <input type="number" bind:value={hotspot.rx} on:change={() => change_rx(hotspot.id, hotspot.rx)}/>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RY: </label>
                      <input type="number" bind:value={hotspot.ry} on:change={() => change_ry(hotspot.id, hotspot.ry)}/>
                    </div>  

                    <!-- šírka hotspotu -->
                    <div class="item">
                      <label>Šírka hotspotu: </label>
                      <input type="number" bind:value={hotspot.width} on:change={() => change_width(hotspot.id, hotspot.width)}/>
                    </div>

                    <div>
                      <label for="enable_permanent">Permanent?</label>
                      <input type="checkbox" name="enable_permanent" bind:checked="{hotspot.enable_permanent}">
                    </div>
                    
                  {/if}

                  {#if hotspot.type == "attribute"}

                    <!-- výber skinu -->
                    <div class="item">
                      <label>Skin: </label>
                      <select bind:value={hotspot.skin} on:change={() => hotspot_skin = hotspot.skin }>
                        <option value="attribute-lista">Lišta</option>
                        <option value="attribute-podlaha">Podlaha</option>
                        <option value="attribute-sanita">Sanita</option>
                        <option value="attribute-sanita2">Sanita 2</option>
                        <option value="attribute-obklad">Obklad</option>
                        <option value="attribute-obklad2">Obklad 2</option>
                        <option value="attribute-dlazba">Dlažba</option>
                        <option value="attribute-dvere">Dvere</option>
                      </select>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RX: </label>
                      <input type="number" bind:value={hotspot.rx} on:change={() => change_rx(hotspot.id, hotspot.rx)}/>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RY: </label>
                      <input type="number" bind:value={hotspot.ry} on:change={() => change_ry(hotspot.id, hotspot.ry)}/>
                    </div> 

                    <!-- výber produktu -->
                    <div class="item">
                      <label>Produkt: </label>
                      <select bind:value={hotspot.product_id}>
                        {#each products as item}
                          <option value="{item.id}">{item.name}</option>
                        {/each}
                      </select>
                    </div>
                  {/if}

                  {#if hotspot.version == "info-v1"}
                    
                  {/if}

                
                  {#if hotspot.type == "info"}
                    <!-- výber skinu -->
                    <div class="item">
                      <label>Skin: </label>
                      <select bind:value={hotspot.skin} on:change={() => hotspot_skin = hotspot.skin }>
                        <option value="info">Info</option>
                        <option value="video">Video</option>
                      </select>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RX: </label>
                      <input type="number" bind:value={hotspot.rx} on:change={() => change_rx(hotspot.id, hotspot.rx)}/>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RY: </label>
                      <input type="number" bind:value={hotspot.ry} on:change={() => change_ry(hotspot.id, hotspot.ry)}/>
                    </div> 

                    <!-- verzia info/video hotspotu -->
                    <div class="item">
                      <label>Version: </label>
                      <select bind:value={hotspot.version} 
                        on:change={() => hotspot_version = hotspot.version }
                        on:change={() => modal_type_tmp = hotspot.version }
                        >
                        <option value="info-v1">info-v1</option>
                        <option value="info-v2">info-v2</option>
                        <option value="image-only">image only</option>
                      </select>
                    </div>

                    <!-- live preview switch -->
                    <div class="item">
                      <label for="enabled">Live preview</label>
                      <input type="checkbox" name="enabled" bind:checked={live_preview}>
                    </div>
                     <!-- názov obsahu hotspotu -->
                    <div class="item">
                      <label>Title: </label>
                      <input type="text" bind:value={hotspot.content_title} on:keyup={() => live_preview_data.title = hotspot.content_title} />
                    </div>
                    <!-- img obsahu hotspotu -->
                    <div class="upload item">
                      <label for="fileupload">Obrázok</label><br>
                      <input id="fileupload" type="file" name="fileupload" />
                      <button id="upload-button" class="info-img" on:click="{() => uploadFile(hotspot.id, "info")}"> Upload </button>
                    </div>
                    <!-- URL img obsahu hotspotu -->
                    <div class="item">
                      <label>Cesta k obrázku</label><br>
                      <input type="text" bind:value={hotspot.content_img} on:keyup={() => live_preview_data.img = hotspot.content_img}/>
                    </div>
                     <!-- headline obsahu hotspotu -->
                     <div class="item">
                      <label>Headline: </label>
                      <input type="text" bind:value={hotspot.content_headline} on:keyup={() => live_preview_data.headline = hotspot.content_headline} />
                    </div>
                    <!-- subtitle obsahu hotspotu -->
                    <div class="item">
                      <label>Subtitle: </label>
                      <input type="text" bind:value={hotspot.content_subtitle} on:keyup={() => live_preview_data.subtitle = hotspot.content_subtitle} />
                    </div>
                    <!-- text obsahu hotspotu -->
                    <div class="item">
                      <label>Text: </label>
                      <input type="text" bind:value={hotspot.content_text} on:keyup={() => live_preview_data.text = hotspot.content_text} />
                    </div>
                  {/if}

                  {#if hotspot.type == "tag"}
                    <!-- výber skinu -->
                    <div class="item">
                      <label>Skin: </label>
                      <select bind:value={hotspot.skin} on:change={() => hotspot_skin = hotspot.skin }>
                        <option value="tag">Tag</option>
                      </select>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RX: </label>
                      <input type="number" bind:value={hotspot.rx} on:change={() => change_rx(hotspot.id, hotspot.rx)}/>
                    </div>

                    <!-- RX hotspotu -->
                    <div class="item">
                      <label>RY: </label>
                      <input type="number" bind:value={hotspot.ry} on:change={() => change_ry(hotspot.id, hotspot.ry)}/>
                    </div> 

                    <div class="item">
                      <label>Title: </label>
                      <input type="text" bind:value={hotspot.tag_title}/>
                    </div>

                    <div class="item">
                      <label>Cena: </label>
                      <input type="number" bind:value={hotspot.tag_price}/>
                    </div>

                    <!-- img obsahu hotspotu -->
                    <div class="upload item">
                      <label for="fileupload">Obrázok</label><br>
                      <input id="fileupload" type="file" name="fileupload" />
                      <button id="upload-button" class="info-img" on:click="{() => uploadFile(hotspot.id, "tag")}"> Upload </button>
                    </div>
                    <!-- URL img obsahu hotspotu -->
                    <div class="item">
                      <label>Cesta k obrázku</label><br>
                      <input type="text" bind:value={hotspot.tag_img}/>
                    </div>

                    <div class="item">
                      <label>Content: <br />
                        {#if hotspot.tag_content}
                        Ostáva: {210 - hotspot.tag_content.length} znakov
                        {/if}
                      </label>
                        
                      <textarea bind:value={hotspot.tag_content}/>
                    </div>

                    <div class="item">
                      <label>Link: </label>
                      <input type="text" bind:value={hotspot.tag_link}/>
                    </div>
                  {/if}
                  
                {/if}
              {/each}
            {/if}

            {#each sample_flats_scenes as flat}
                {#if flat.name.toLowerCase() == krpano_current_scene.toLowerCase()}  



                <!-- buttony vo výpise hotspotov -->
                <div id="buttons">
                  {#if hotspot_mode == true}
                    <button on:click={save_scene_orientation(flat)}>
                      <i class="fa-solid fa-gear"></i>
                      Save scene orientation
                    </button>
                    <button on:click={load_scene(flat)}>
                      <i class="fa-solid fa-rotate"></i>
                      {$t("components")["new_scenes"]["load_scene_orientation"]}
                    </button>
                    <button on:click={save_start_scene(flat.id, flat.sample_flat_id)}>
                      <i class="fa-solid fa-flag"></i>
                      Save as start scene
                    </button>
                  {/if}

                  <!-- buttony v editovaní hotspotu -->
                  {#if hotspot_mode == false}
                    {#each hotspots as hotspot}
                      {#if hotspot.id == current_hotspot}
                        <button on:click={() => live_preview = false}
                                on:click={() => hotspot_mode = true}>
                          <i class="fa-solid fa-gear"></i>
                          Cancel
                        </button>
                        <button on:click={() => save_hotspot_setting(hotspot, false)}>
                          <i class="fa-solid fa-rotate"></i>
                          Save
                        </button>
                        <button on:click={() => live_preview = false}
                                on:click={() => save_hotspot_setting(hotspot, true)}>
                          <i class="fa-solid fa-flag"></i>
                          Save & exit
                        </button>
                      {/if}
                    {/each}
                  {/if}
                  

                  <button on:click={save_scene_settings(flat)}>
                    <i class="fa-solid fa-floppy-disk"></i>
                    Save scene settings
                  </button>
                </div>
                {/if}
                
            {/each}
              
          {/if}
          
        </div>
      </div>
      </div>

      <!-- slider s obrázkami -->
      <div id="slider">
        {#if loading_data > 2 }
          {#each sample_flats_scenes as flat}
            {#if flat.sample_flat_id.toLowerCase() == current_sample_flat.toLowerCase()}
              <!-- ak je scéna povolená -->
              <div class="thumb">
                <span>{flat.name}</span>
                {#if flat.enable_design == true && flat.enable_floors == false }
                  <img class:active={flat.name === krpano_current_scene} id="{flat.name}" src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}{scenes_settings[3].value}/{flat.name}_{initVars.dizajn}_f.jpg" alt="{flat.name}_{initVars.design}_f.jpg" on:click="{() => change_scene(flat.name)}" />
                {:else if flat.enable_design == false && flat.enable_floors == true}
                  <img class:active={flat.name === krpano_current_scene} id="{flat.name}" src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}{scenes_settings[3].value}/{flat.name}_{initVars.floors}_f.jpg" alt="{flat.name}_{initVars.floors}_f.jpg" on:click="{() => change_scene(flat.name)}" />
                {:else if flat.enable_design == true && flat.enable_floors == true}
                  <img class:active={flat.name === krpano_current_scene} id="{flat.name}" src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}{scenes_settings[3].value}/{flat.name}_{initVars.dizajn}d_{initVars.floors}_f.jpg" alt="{flat.name}_{initVars.design}_{initVars.floors}_f.jpg" on:click="{() => change_scene(flat.name)}" />
                {:else}
                  <img class:active={flat.name === krpano_current_scene} id="{flat.name}" src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}{scenes_settings[3].value}/{flat.name}_f.jpg" alt="{flat.name}.jpg" on:click="{() => change_scene(flat.name)}" />
                {/if}   
              </div>
            {/if}
          {/each}
          {/if}
      </div>
    </main>


    <Header />


   
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    #screenshot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      padding: 20px;
      outline: 3000px solid rgba(0,0,0,0.7);
      pointer-events: none;
      z-index: 9;
      transition: all 0.25s ease-in-out;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }

    #screenshot-icon, #cross-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background: var(--secondary-color);
      z-index: 10;
      transition: all 0.25s ease-in-out;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      background-image: url("../assets/icons/pdf-screen.svg");
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    #cross {
      position: absolute;
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      pointer-events: none;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: yellow;
      }

      &::before {
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: yellow;
      }
    }

    #cross-icon {
      top: 60px;
      background-image: url("../assets/icons/cross.svg");
    }

    #content {
      display: flex;
      justify-content: center;
      align-items: center;

      #viewer, #settings {
        width: 60%;
        min-height: 600px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
        margin: 5px 0;
      }

      #viewer {
        position: relative;
        border-radius: 12px 0 0 12px;
        overflow: hidden;
      }

      #settings {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px;
        padding-right: 0;
        overflow: hidden;
        overflow-y: auto;
        max-height: 600px;
        width: 40%;
        border-radius: 0 12px 12px 0;
        background-color: #fff;

        .hotspot {
          width: calc(100% - 15px);
          background-color: #f1f5f9;
          padding: 0 15px;

          &:hover {
            background-color: #e2e9f0;
          }

          i {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            // margin-right: 15px;
            
            &.next-node {
              background-color: $info;
            }

            &.attribute {
              background-color: $warning;
            }
            &.tag {
              background-color: $danger;
            }
            &.info {
              background-color: $success;
            }


          }

          p {
            margin-left: 10px;

            &::after {
              content: "|";
              margin-left: 10px;
            }
            &:nth-child(even) {
              color: #94a3b8;
            }
            &:nth-last-child(3)::after {
              content: "";
            }
          }

          button {
            &.remove {
              background-color: $danger;
            }
            &.edit {
              margin-left: 10px;
            }
          }
        }

        .item-container {
          width: 100%;
          height: 510px;
          overflow: auto;
        }

        #buttons {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          button {
            margin: 0;
            margin-top: 12px;
            border-radius: 0;
            background-color: #fff;
            border-top: 1px solid #e2e8f0;
            border-right: 1px solid #e2e8f0;
            color: #94a3b8;
            padding: 15px 15px;
            width: 25%;
            display: flex;
            align-items: center;

            &:hover {
              background-color: #f2f4f6;
              // color: #f1f5f9;

            }

            i {
                &.fa-rotate {
                  color: $info;
                }
                &.fa-gear {
                  color: $warning;
                }
                &.fa-floppy-disk {
                  color: $success;
                }
                &.fa-flag {
                  color: $badge-start;
                }
              }

          }
        }

        #fields {
          position : absolute;
          top: 0;
          right: -327px;
          width: 300px;
          height: 95%;
          overflow: auto;
          background: #f1f5f9;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-top: 40px;
          transition: all 0.25s ease-in-out;
          box-shadow: 0 0 0 0;
          padding-right: 27px;

          &.active {
            right: 0;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
          }

          div {
            display: flex;
            gap: 15px;
            align-items: center;
            // margin: 5px;
            width: 100%;
            justify-content: space-between;
            padding: 10px 80px 10px 15px;

          //   &:nth-child(odd) {
          //   background-color: #f2f4f6;
          // }

          input {
            scale: 0.8;
          }

          &:hover {
            background-color: #e4e6e9;
          }


          }
        }

        .field {
          position : absolute;
          top: 15px;
          right: 15px;
          width: 30px;
          height: 30px;
          z-index : 10;
          background-color: #94a3b8;
          cursor: pointer;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: $primary-color;
          }

          i {
            color: white;
            font-size: 16px;
          }

        }


        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          label {
            display: inline-block;
            min-width: 120px;
          }
        }

        .hotspot {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 4px;

          .hotspot-info {
            display: flex;
            align-items: center;
          }

          .hotspot-buttons {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .main-scenes {
      padding: 0 50px !important;
      h1 {
        margin-bottom: 5px;
      }
      select {
        margin: 5px 5px 5px 0;
        padding: 10px;
      }
      input {
        padding: 5px;
        &:focus {
          border: 1px solid #4f46e5;
        }
      }
      .item {
        width: 80%;
        &.upload {
          width: 80% !important;
          margin: 0 !important;
          input {
            padding: 0;
          }
        }
      }

      .radio-item {
        padding: 20px 0;
        p {
          text-align: left;
          min-width: 120px;
        }
        label {
          text-align: left;
          input {
            width: 30%;
            margin: 0;
          }
        }
      }

      #navigation {
        display: flex;

        #nav-buttons {
          display: flex;

          button {
            margin: 5px;
            background-color: #fff;
            border: 1px solid #e2e8f0;
            color: #94a3b8;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            &.active {
              background-color: $primary-color;
              border: 0;
              color: #fff;
            }
          }
          #show_hotspots {
            margin-right: 0;
          }
        }
      }
    }

    #slider {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      height: 130px;
      width: auto;
      overflow-x: scroll;
      
      div {
        min-width: 250px;
        height: 120px;
        position: relative;

        & > img {
          min-width: 250px;
          height: 120px;
          border: 2px solid transparent;
          cursor: pointer;
          object-fit: cover;

          &.active {
            border: 2px solid #123456;
          }
        }
        span {
          position: absolute;
          width: 100%;
          background-color: #ffffff90;
          bottom: 0;
          padding: 5px;
        }
      }

    }
    

  </style>