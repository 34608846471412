<script>
    import {get, writable} from 'svelte/store';
    import { fade } from 'svelte/transition';
    import { cubicInOut } from 'svelte/easing';
    import { new_model, sidebar, shortcuts, cart, blur } from '../store.js';
    import { configuration, config_products, all_products, config_product_measures_units, config_product_variants, active_product_id_model, tmp_attribute_from_model, vr_mode } from '../store.js';
    import { t, locale, locales } from "../i18n.js";

    export let userdata;

    let autoRotateIcon = false;

    let _vrMode;
    vr_mode.subscribe(value => {
      _vrMode = value;
    });

    let first_proudct_id = 97;


    // premenná pre 3D model NOVÝ
    let modelViewer;
    let model_enabled = false;
    new_model.subscribe(value => {
        model_enabled = value;    

        if(value == true) {
            sidebar.update(n => false);
            shortcuts.update(n => false);
            cart.update(n => false);
            blur.update(n => true);
        }
        else {
            blur.update(n => false);
        }
    });

    let object_data = [];
    let data_model_created = false;
    let active_product, active_patch_id, active_variant_patch_id, active_variant, active_variant_name = null;

    let active_product_real_id = get(active_product_id_model);

    if (active_product_real_id == -1) {
        active_product_real_id = first_proudct_id;
    }

    
    active_product_id_model.subscribe((value) => {
        active_product_real_id = value;

        ////////console.log(value);
       
    });

    let _configuration = get(configuration);
    let _config_products = get(config_products);
    let _products = get(all_products);
    let _mj = get(config_product_measures_units);
    console.log(_mj);
    let _variants = get(config_product_variants);

    if (_configuration != null && _configuration != "") {
        //////console.log(_products);
        //////console.log(_configuration);
        _configuration.forEach(element => {
            let product_name, short_desription, patch_id, tmp_mj = null;
            if (element.type != "configuration") {
                
                _products.forEach(product => {
                    
                   if (parseInt(product.id) == parseInt(element.product_id))  {
                    //////console.log(product.id + " : " + element.id);
                    product_name = product.name;
                    patch_id = product.patch_id;
                    short_desription = product.short_desription;
                    tmp_mj = product.mj
                   }
                });

                let active_product_variant, active_product_standard;

                if (element.active_product_variant == null) {
                    active_product_variant = -1;
                } else {
                    active_product_variant = element.active_product_variant;
                }
                let tmp_object = {
                    product_data : {
                        active_standard : active_product_standard,
                        active_product_variant : parseInt(active_product_variant),
                        product_id : parseInt(element.id),
                        product_real_id : parseInt(element.product_id),
                        product_name : product_name,
                        product_patch_id : patch_id,
                        product_mj : element.mj,
                        product_quantity : parseInt(element.quantity),
                        product_price : parseInt(element.price)
                    }, 
                    models : []
                }

                _variants.forEach(variant => {
                    if (parseInt(variant.product_id) == parseInt(element.product_id)) {
                        let tmp_variants = {
                            id : parseInt(variant.id),
                            name : variant.name,
                            short_description : variant.description,
                            price : parseInt(variant.price),
                            variant_id : variant.variant_id,
                            patch_id : variant.patch_id,
                            model : patch_id+"_"+variant.patch_id,
                            mj : tmp_mj
                        };

                        
                        

                        
                        tmp_object["models"].push(tmp_variants);
                    }
                });

                if (active_product_variant == -1) {
                            tmp_object.product_data.active_product_variant = parseInt(tmp_object.models[0].id);
                        }


                object_data.push(tmp_object);
                console.log(object_data);
            }
        });

        data_model_created = true;
        //////console.log(object_data);
        active_product = object_data[0].product_data.product_id;
        //////////console.log(active_product);

        let timer;

        function myLoop() {  
            select_active_model();
                //  create a loop function
            timer = setTimeout(function() { 
                
                if (document.querySelector("model-viewer") == null) {           //  if the counter < 10, call the loop function
                    myLoop();             //  ..  again which will trigger another 
                } else {
                    clearTimeout(timer);
                }                    //  ..  setTimeout()
            }, 30)
        };

            myLoop();


        
        //////////console.log(object_data);
    }

    // ////////console.log(_configuration);
    // ////////console.log(object_data);

    let ar_supported = false;

    // Funkcie
    function readModel() {
        modelViewer = document.querySelector("model-viewer");
        modelViewer.addEventListener('load', (event) => {
            //////////console.log(event);
            //modelViewer.pause();
            select_active_model();
            ar_supported = modelViewer.canActivateAR;
            //////////console.log(ar_supported);
        });

        

        
        // Event na otočenie modelu
        modelViewer.addEventListener('camera-change', (event) => {
            //   ////////console.log(event.detail);
            //   ////////console.log(modelViewer.getCameraOrbit().toString());
            //   ////////console.log(modelViewer.getCameraTarget().toString());
            //   ////////console.log(modelViewer.getFieldOfView().toString());
        });
    }

    // funckia na autotáčanie modelu
    function autorotate() {
        let modelViewer = document.querySelector("model-viewer");
        modelViewer.setAttribute("auto-rotate-delay", 0);
        modelViewer.toggleAttribute("auto-rotate");
        autoRotateIcon = !autoRotateIcon;
    }

    // spustenie animácie a v polovici zastane
    function playAnimation() {
        //////////console.log("spúšťam akciu");
        let i = 0;
        function myLoop() {         //  create a loop function
            setTimeout(function() {   //  call a 3s setTimeout when the loop is called
                
                let modelViewer2 = document.querySelector("model-viewer");
                //////////console.log(modelViewer2.animationName);
                // modelViewer.autoplay = true;

                let animation_duration = (modelViewer2.duration) / 2 * 1000;
        
                modelViewer2.play(); 
                 // //let modelViewer = document.querySelector("model-viewer");
        
                // // modelViewer.play({repetitions: 1});

                self.setTimeout(() => {
                    // modelViewer.animationName = modelViewer.animationName === 'Doska zatvorenie' ?
                    // 'Doska otvorenie' : 'Doska zatvorenie';
                    modelViewer2.pause();
                }, animation_duration);
    
                
                //  your code here
                i++;                    //  increment the counter
                if (animation_duration == 0) {           //  if the counter < 10, call the loop function
                myLoop();             //  ..  again which will trigger another 
                }                       //  ..  setTimeout()
            }, 30)
            }

            myLoop();
        
        
        
    }

    function activate_ar() {
        let modelViewer = document.querySelector("model-viewer");
        modelViewer.activateAR();
    }

    function move_to(target) {
        ////////console.log("otáčam");
        let modelViewer = document.querySelector("model-viewer");
        switch (active_product) {
            case 826:
                switch (active_variant_name) {
                    case "grohe-23590001":
                        switch (target) {
                            case "default":
                                modelViewer.cameraOrbit = "0rad 1.3089969389957472rad 0.40201601139738946m";
                                modelViewer.cameraTarget = "17.42057395164845m 0.9074973842160838m -12.168586346084595m";
                                modelViewer.fieldOfView = "30.000000000000004deg";
                            break;
                        
                            case 1:
                                modelViewer.cameraOrbit = "-0.32252091880959854rad 2.1093266264121593rad 0.3561753790397009m";
                                modelViewer.cameraTarget = "17.42057395164845m 0.9074973842160838m -12.168586346084595m";
                                modelViewer.fieldOfView = "23.877293194659103deg";
                            break;

                            case 2:
                                modelViewer.cameraOrbit = "2.48224234139882rad 0.763811501691976rad 0.3561753790397009m";
                                modelViewer.cameraTarget = "17.42057395164845m 0.9074973842160838m -12.168586346084595m";
                                modelViewer.fieldOfView = "23.877293194659103deg";
                            break;
                        }
                    break;

                    case "grohe-33163003":
                        switch (target) {
                            case "default":
                                modelViewer.cameraOrbit = "0rad 1.3089969389957472rad 0.46961992104359424m";
                                modelViewer.cameraTarget = "17.420574949094636m 0.9157605385780335m -12.158531588201274m";
                                modelViewer.fieldOfView = "30.000000000000004deg";
                                break;
                        
                            case 1:
                                modelViewer.cameraOrbit = "0.6808774952647082rad 2.384066668361077rad 0.37423144560728977m";
                                modelViewer.cameraTarget = "17.419628403323944m 0.941196880196289m -12.120227422989892m";
                                modelViewer.fieldOfView = "21.17727654193545deg";
                            break;

                            case 2:
                                modelViewer.cameraOrbit = "5.504172494704074rad 0.9258758836799182rad 0.287959909424358m";
                                modelViewer.cameraTarget = "17.401913949077457m 0.9772957027834677m -12.218262352906686m";
                                modelViewer.fieldOfView = "21.205343641652743deg";
                            break;                            
                        }
                    break;

                    case "grohe-23872003":
                        switch (target) {
                            case "default":
                                modelViewer.cameraOrbit = "0rad 1.3089969389957472rad 0.5293735202008621m";
                                modelViewer.cameraTarget = "17.449173354882248m 0.9248394023696764m -12.15412219251219m";
                                modelViewer.fieldOfView = "30.000000000000004deg";
                                break;
                        
                            case 1:
                                modelViewer.cameraOrbit = "0.6987692428228008rad 2.3942383615792346rad 0.35073535861195193m";
                                modelViewer.cameraTarget = "17.420832703611065m 1.0024577330797677m -12.102614790513732m";
                                modelViewer.fieldOfView = "13.834842378257687deg";
                            break;

                            case 2:
                                modelViewer.cameraOrbit = "2.198035682240874rad 2.107985780994789rad 0.34721089273084843m";
                                modelViewer.cameraTarget = "17.493378980121914m 0.8794964715663294m -12.221570464380049m";
                                modelViewer.fieldOfView = "13.834842378257687deg";
                            break;                            
                        }
                    break;
                
                    default:
                        break;
                }
                
                break;
        
            default:
                
                
            break;
        }
    }

    function fadeScale (
        node, { delay = 0, duration = 200, easing = x => x, baseScale = 0 }
        ) {
        const o = +getComputedStyle(node).opacity;
        const m = getComputedStyle(node).transform.match(/scale\(([0-9.]+)\)/);
        const s = m ? m[1] : 1;
        const is = 1 - baseScale;

        return {
            delay,
            duration,
            css: t => {
            const eased = easing(t);
            return `opacity: ${eased * o}; transform: scale(${(eased * s * is) + baseScale})`;
            }
        };
    }

    let show_dimensions = false;
    
    let src = null;

    $: {
        active_product_real_id;

        if (active_product_real_id == -1) {
            active_product_real_id = first_proudct_id;
        }
        select_active_model();
    }

    $: {
        active_product;
        //////////console.log(active_product);
        

        //select_active_model(); 
    }

    // označenie aktívneho variantu produktu
    function select_active_model() {
       ////////console.log("hľadám model");
       
        
        const base = userdata['cdn_path']+"assets/" + userdata["assets_folder"] + "models/products/";
        const modelViewer = document.querySelector("model-viewer");
        if (modelViewer != null) {
            
            object_data.forEach(element => {
                if(element.product_data.product_real_id == active_product_real_id) {

                    if (element.product_data.active_product_variant == null) {
                        // element.product_data.active_product_variant = element.models[0]
                    } else {
                        element.models.forEach(model => {
                        //////////console.log(model.id + " : " + element.product_data.active_product_variant);
                        if (model.id == element.product_data.active_product_variant) {
                            active_variant = model.id;
                            active_variant_patch_id = model.patch_id;

                            active_variant_name = model.variant_id;
                            src = base + model.variant_id + '.glb';
                            modelViewer.src = base + model.model + '.glb';
                            ////////console.log(base + model.model + '.glb');
                            
                        }
                    });
                    }

                }
            });

            ////////console.log(active_variant);

            modelViewer.cameraOrbit = "0deg 75deg 105%";
            modelViewer.cameraTarget = "auto auto auto";
            modelViewer.fieldOfView = "auto";
        }

        

        // ////////console.log(active_product + " : " +  active_variant);
    }

    let saving = false;
    function apply_changes() {
       //console.log("ukladám aktívny variant : " + active_variant + " " + active_product_real_id);
        let obj = {
            _active_variant : active_variant,
            _product_id : active_product_real_id
        }
        
        tmp_attribute_from_model.update(n=> obj);

        saving = true;
        setTimeout(() => {
            saving = false;
        }, 1000);

        // ////////console.log(_config_products);
        // _configuration.forEach(element => {
            
        //     if (parseInt(element.id) == parseInt(active_product)) {
        //         ////////console.log(element.id + " * " + element.product_id + " / " + active_product);
                
        //         element.active_product_variant = active_variant;
        //         ////////console.log(element.active_product_variant + " : " + active_variant);
        //     }
            
        // });
        
        // _config_products.forEach(element => {
            
        //     if (parseInt(element.product_id) == parseInt(active_product_real_id)) {
        //         ////////console.log("áno " + active_variant_patch_id + " / " + active_product_real_id);
        //         let krpano = document.getElementById("krpanoSWFObject");
            
        //         let product = element.patch_id;
        //         let current_scene = krpano.get("xml.scene").toUpperCase();
        //         ////////console.log(element.patch_id );
        //         // ////////console.log(krpano.get(element.patch_id));
        //         // krpano.set("active_patch_id", active_variant_patch_id);
                

        //         // let positions = ["f","l","r","u","d","b"];
        //         // positions.forEach(side => {
        //         //     krpano.call("set(hotspot[hts_" + active_patch_id + "_" + current_scene + "_" + side + "].url, assets/patches/" + current_scene + "_" + active_patch_id +"_" + active_variant_patch_id + "_" + side + ".png);");
        //         //     // krpano.call("set(hotspot[hts_" + element.patch_id + "_" + current_scene + "_" + side + "].url, assets/patches/" + current_scene + "_" + element.patch_id +"_%$" +element.patch_id +"%_" + side + ".png);");
        //         //     ////////console.log("hotspot[hts_" + active_patch_id + "_" + current_scene + "_" + side + "].url, assets/patches/" + current_scene + "_" + active_patch_id +"_" + active_variant_patch_id + "_" + side + ".png");
                    
        //         // });
        //     }
        // });

        //configuration.update(n => _configuration);
    }

    

    import {newDelay} from "../store.js";

    let delay = 0;
    async function setDelay(newDelay) {
		  delay = newDelay;
	}

    

    let dimLines, renderSVG;
</script>
<div id="woow-3d-model">
{#if _vrMode == false}
<div id="model-blur" transition:fade/>
<div id="model-3d"  transition:fadeScale={{
    delay: 10,
    duration: 600,
    easing: cubicInOut,
    baseScale: 0.7
}}>
    <div class="close" on:click={() => new_model.update(n => false)}/>
    <div class="title"></div>
    <div class="content">
        <div class="product">
            <div class="product-model">
                <div class="icons">
                    {#if ar_supported}
                        <!-- <div class="ar-icon" on:click={() => activate_ar()}>AR</div> -->
                    {/if}
                    
                    <!-- Autorotácia TOOGLE -->
                    <label for="model-autorotate" class="label-autorotate">
                        <input type="checkbox" id="model-autorotate" bind:checked={autoRotateIcon} on:click={() => autorotate()}>{$t("components")['model3d']['autorotate']}
                    </label>

                    <!-- prehratie animácie -->
                    
                    <!-- {#if active_variant_name == null}
                        <div class="play" on:click={() => playAnimation()}></div>
                    {/if} -->
                    
                </div>
                
                {#if active_product_real_id != -1}

                {#each object_data as object}
                 {#if active_product_real_id == object.product_data.product_real_id}
                    {#each object.models as model}
                        {#if model.id == object.product_data.active_product_variant}
                            <model-viewer id="viewer" style="width: 100%; height: 100%;" camera-controls touch-action="pan-y" src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}models/products/{model.model}.glb" ar alt="" shadow-intensity="1" skybox-image="" on:load={() => readModel()}>
                        

                                <!-- Rozmery  -->
                                <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot+X-Y+Z" data-position="1 -1 1" data-normal="1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim+X-Y"  data-position="1 -1 0" data-normal="1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}"slot="hotspot-dot+X-Y-Z" data-position="1 -1 -1" data-normal="1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim+X-Z" data-position="1 0 -1" data-normal="1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot+X+Y-Z" data-position="1 1 -1" data-normal="0 1 0"></button>
                                <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim+Y-Z" data-position="0 -1 -1" data-normal="0 1 0"></button>
                                <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}"slot="hotspot-dot-X+Y-Z" data-position="-1 1 -1" data-normal="0 1 0"></button>
                                <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim-X-Z" data-position="-1 0 -1" data-normal="-1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot-X-Y-Z" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim-X-Y" data-position="-1 -1 0" data-normal="-1 0 0"></button>
                                <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot-X-Y+Z" data-position="-1 -1 1" data-normal="-1 0 0"></button>
                                <svg class="{show_dimensions === true ? 'dimensionLineContainer show' : 'dimensionLineContainer hide'}" id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                                    <line class="dimensionLine"></line>
                                    <line class="dimensionLine"></line>
                                    <line class="dimensionLine"></line>
                                    <line class="dimensionLine"></line>
                                    <line class="dimensionLine"></line>
                                </svg>
                                <label class="label-dimensions hidden" for="show-dimensions"> {$t("components")['model3d']['show-dimensions']}</label>
                                <input class="hidden" id="show-dimensions" type="checkbox">
                            </model-viewer>
        
                            <script>
                                modelViewer = document.querySelector("model-viewer"); 
                                
                                checkbox = modelViewer.querySelector('#show-dimensions');
                                checkbox.addEventListener('change', () => {
                                  setVisibility(modelViewer.querySelector('#dimLines'));
                                  modelViewer.querySelectorAll('button').forEach((hotspot) => {
                                    setVisibility(hotspot);
                                  });
                                });
                    
                    
                                function drawLine(svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) {
                                  if (dotHotspot1 && dotHotspot1) {
                                    svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
                                    svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
                                    svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
                                    svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);
                    
                                    // use provided optional hotspot to tie visibility of this svg line to
                                    if (dimensionHotspot && !dimensionHotspot.facingCamera) {
                                      svgLine.classList.add('hide');
                                    }
                                    else {
                                      svgLine.classList.remove('hide');
                                    }
                                  }
                                }
                    
                                dimLines = modelViewer.querySelectorAll('line');
                                renderSVG = () => {
                                  drawLine(dimLines[0], modelViewer.queryHotspot('hotspot-dot+X-Y+Z'), modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Y'));
                                  drawLine(dimLines[1], modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Z'));
                                  drawLine(dimLines[2], modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X+Y-Z')); // always visible
                                  drawLine(dimLines[3], modelViewer.queryHotspot('hotspot-dot-X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dim-X-Z'));
                                  drawLine(dimLines[4], modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y+Z'), modelViewer.queryHotspot('hotspot-dim-X-Y'));
                                };
                    
                                modelViewer.addEventListener('camera-change', renderSVG);
                    
                                modelViewer.addEventListener('load', () => {
                                  const center = modelViewer.getBoundingBoxCenter();
                                  const size = modelViewer.getDimensions();
                                  const x2 = size.x / 2;
                                  const y2 = size.y / 2;
                                  const z2 = size.z / 2;
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dot+X-Y+Z',
                                    position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`
                                  });
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dim+X-Y',
                                    position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
                                  });
                                  modelViewer.querySelector('button[slot="hotspot-dim+X-Y"]').textContent =
                                      `${(size.z * 100).toFixed(0)} cm`;
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dot+X-Y-Z',
                                    position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`
                                  });
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dim+X-Z',
                                    position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
                                  });
                                  modelViewer.querySelector('button[slot="hotspot-dim+X-Z"]').textContent =
                                      `${(size.y * 100).toFixed(0)} cm`;
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dot+X+Y-Z',
                                    position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`
                                  });
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dim+Y-Z',
                                    position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`
                                  });
                                  modelViewer.querySelector('button[slot="hotspot-dim+Y-Z"]').textContent =
                                      `${(size.x * 100).toFixed(0)} cm`;
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dot-X+Y-Z',
                                    position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`
                                  });
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dim-X-Z',
                                    position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
                                  });
                                  modelViewer.querySelector('button[slot="hotspot-dim-X-Z"]').textContent =
                                      `${(size.y * 100).toFixed(0)} cm`;
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dot-X-Y-Z',
                                    position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`
                                  });
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dim-X-Y',
                                    position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
                                  });
                                  modelViewer.querySelector('button[slot="hotspot-dim-X-Y"]').textContent =
                                      `${(size.z * 100).toFixed(0)} cm`;
                    
                                  modelViewer.updateHotspot({
                                    name: 'hotspot-dot-X-Y+Z',
                                    position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`
                                  });
                    
                                  renderSVG();
                                });
                            </script>
                        {/if}
                    {/each}
                 {/if}
                {/each}


                {/if}
                
            </div>
            <div class="product-info">
                {#each object_data as object}
                    {#if active_product_real_id == object.product_data.product_real_id}
                        <h1>{object.product_data.product_name}</h1>
                        {#each object.models as model}
                            {#if model.id == object.product_data.active_product_variant}
                                <h2>{model.name}</h2>

                                <div class="price">
                                    <h1>{object.product_data.product_quantity * model.price} €</h1>
                                    
                                    <!-- <h3>{$t("components")['model3d']['price-prefix']} ({object.product_data.product_quantity}{object.product_data.product_mj})</h3> -->
                                    <h3>{$t("components")['model3d']['price-prefix']} {object.product_data.product_quantity}  
                                        {#each _mj as jednotka}
                                            {#if parseInt(jednotka.id) == parseInt(model.mj)} 
                                            {@html $t("components")['model3d'][jednotka.name]}
                                            {/if}
                                        {/each}
                                    </h3>
                                </div>

                                <h3>{model.short_description} <br /><a>{$t("components")['model3d']['show-more']}</a></h3>
                            {/if} 
                        {/each}
                        
                        {#if active_product_real_id == 80}
                            <div class="standard">
                                <button class="standard-item" on:click={() => move_to("default")}>reset</button>
                                <button class="standard-item" on:click={() => move_to(1)}>perlátor</button>
                                <button class="standard-item" on:click={() => move_to(2)}>rukoväť</button>
                            </div>
                        {/if}
                        

                        <h3 class="variant-text"> {$t("components")['model3d']['select-variant']} :</h3>
                        <div class="variants">
                            {#each object.models as model}
                                <img class:active="{model.id == object.product_data.active_product_variant}" src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{model.variant_id}.jpg" alt="" class="variat-color"
                                    on:click={() => object.product_data.active_product_variant = model.id}
                                    on:click={() => select_active_model()}>         
                            {/each}
                        </div>
                    <!-- <h3 class="variant-text">Vybrať farbu</h3>
                    <div class="colors">
                        <img src="assets/thumbnails/grohe-23872003.jpg" alt="" class="variant-product">            
                    </div> -->
                        {#if saving}
                            <button class="confirm confirmed">{$t("components")['model3d']['confirm-variant-confirmed']}</button>
                        {:else}
                            <button class="confirm" on:click={() => apply_changes()}>{$t("components")['model3d']['confirm-variant']}</button>
                        {/if}
                        
                        <label for="dimensions" class="show-dimensions"> 
                            <input id="dimensions" type="checkbox" bind:checked={show_dimensions}>{$t("components")['model3d']['show-dimensions']}
                        </label>
                    {/if}
                {/each}                    
                
                
            </div>
        </div>
        <div class="all-products">
            {#each object_data as object}<div>
                {#each object.models as model}
                    {#if parseInt(object.product_data.active_product_variant) == parseInt(model.id)}
                    <img class:active="{active_product_real_id == object.product_data.product_real_id}" src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{model.variant_id}.jpg" alt="" class="variant-product"
                    on:click={() => active_product = object.product_data.product_id}
                    on:click={() => active_product_real_id = object.product_data.product_real_id}
                    on:click={() => active_patch_id = object.product_data.product_patch_id}
                    on:click={() => autoRotateIcon = false}>
                    <span>{object.product_data.product_name}</span>
                    {/if}
                {/each}
                
                
            </div>
            {/each}
            
            
        </div>
    </div>
      
  </div>
{/if}

</div>

<style lang="scss">
    /* your styles go here */
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/variables.scss";

#woow-3d-model{
    #model-blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(63, 63, 70, 0.2);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      z-index: 1;
    }
    

    #model-3d {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: calc(100% - 64px);
        height: calc(100% - 64px);
        max-height: 900px;
        max-width: 1100px;
        padding: 16px;
        @include blur;
        border-radius: $radius-M;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 200;

        .title {
        @include rubik-m24;
        padding: 0 32px;
        margin-bottom: 8px;
        text-align: center;
        height: 24px;
        }

        .content {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        border-radius: $radius-S;
        background-color: white;
        border: 1px solid var(--primary-color-light20);
        display: flex;
        flex-direction: column;

        .product {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: calc(100% - 140px);

            .product-model {
                // background-color: tomato;
                width: 70%;
                height: 100%;
                position: relative;

                .icons {
                    position: absolute;
                    left: 16px;
                    top: 16px;
                    z-index: 220;
                    

                    .ar-icon {
                        background-color: var(--primary-color);
                        color: var(--secondary-color);
                        width: max-content;
                        display: flex;
                        padding: 9px 13px;
                        align-items: center;
                        border-radius: 6px;
                        @include rubik-m14;
                        cursor: pointer;
                        margin-bottom: 16px;

                        &:hover {
                            background-color: var(--primary-hover);
                        }

                        &::before {
                            content: "";
                            display: block;
                            width: 22px;
                            height: 22px;
                            margin-right: 9px;
                        }
                    }

                    .play {
                        width: 32px;
                        height: 32px;
                        background-color: var(--primary-color);
                        border-radius: $radius-S;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 16px;
                        cursor: pointer;

                        &:hover {
                            background-color: var(--primary-hover);
                        }

                        &::before {
                            content: "";
                            display: flex;
                            width: 24px;
                            height: 24px;
                            background-color: var(--secondary-color);
                            mask-size: contain;
                        }
                    }

                }


            }

            .product-info {
                // background-color: aqua;
                position: absolute;
                right: 0;
                width: 30%;
                height: 100%;
                overflow: auto;
                padding: 16px;
                display: flex;
                flex-direction: column;
                background-color: var(--primary-color-light005);

                h1 {
                    @include rubik-m20;
                }
                h2 {
                    @include rubik-r16;
                    font-weight: 100
                }
                h3 {
                    @include rubik-r14;
                    font-weight: 100;

                    a {
                        font-weight: 500;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .standard {
                    display: flex;
                    gap: 8px;
                    justify-content: flex-start;
                    margin: 12px 0;
                    
                    .standard-item {
                        // width: 140px;
                        width: auto;
                        height: 32px;
                        border: 1px var(--primary-color) solid;
                        @include rubik-r14;
                        border-radius: $radius-S;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background-color: transparent;
                        color: var(--primary-color);
                        margin: 0;
                    
                        &.active {
                            background-color: var(--primary-color);
                            color: var(--secondary-color);
                            @include rubik-m14;
                    }
                }
            }

                .price {
                    display: flex;
                    gap: 12px;
                }

                .colors {
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                        box-shadow: 0 0 0 2px var(--main-color-light20);

                        &:hover {
                            box-shadow: 0 0 0 2px var(--main-color);
            }
                    }
                }

                button {
                    width: 100%;
                    max-width: unset;
                    margin: 10px 0;
                    background-color: var(--main-color);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include rubik-m14;

                    &:hover {
                        background-color: var(--main-hover);
                    }
                }
            }
        }
        .all-products {
            // background-color: gold;
            width: 70%;
            height: 140px;
            padding: 12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: auto;
            gap : 20px;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 80px;

                span {
                    @include rubik-r14;
                    margin-top: 8px;
                    width: 110%;
                    text-align: center;
                    overflow-wrap: break-word;

                }

                img {
                    margin: 0px;
                }
            }
        }
        }

        img {
            width: 65px;
            height: 65px;
            border-radius: $radius-S;
            margin-right: 12px;
            cursor: pointer;
            box-shadow: 0 0 0 1px var(--primary-color-light20);

            &:hover {
                box-shadow: 0 0 0 1px var(--primary-color-light60);
            }

            &.active {
                box-shadow: 0 0 0 1px var(--primary-color);
            }
        }


        input {
        appearance: none;
        width: 16px;
        height: 16px;
        box-shadow: 0 0 0 2px var(--primary-color-light10);
        border-radius: 4px;
        position: relative;
        top: 3px;
        cursor: pointer;
        margin-right: 12px;
        margin: 0;
        margin-right: 10px;
        

        &:checked {
            box-shadow: 0 0 0 2px var(--primary-color);
            background-color: var(--primary-color);
        }

        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            background-color: var(--secondary-color);
            position: relative;
            top: 3px;
            left: 3px;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked::before {
            transform: scale(1);
        }
        }
    }


    //  Dimenzia

    .dot{
        display: none;
    }

    .dim{
        background: #fff;
        border-radius: 4px;
        border: none;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        color: rgba(0, 0, 0, 0.8);
        display: block;
        font-family: Futura, Helvetica Neue, sans-serif;
        font-size: 1em;
        font-weight: 700;
        max-width: 128px;
        overflow-wrap: break-word;
        padding: 0.5em 1em;
        position: absolute;
        width: max-content;
        height: max-content;
        transform: translate3d(-50%, -50%, 0);
        pointer-events: none;
        --min-hotspot-opacity: 0;
    }

    @media only screen and (max-width: 800px) {
        .dim{
        font-size: 3vw;
        }
    }

    .dimensionLineContainer{
        pointer-events: none;
        display: block;
    }

    .dimensionLine{
        stroke: #16a5e6;
        stroke-width: 2;
        stroke-dasharray: 2;
    }

    .hide{
        display: none;
    }
    /* This keeps child nodes hidden while the element loads */
    :not(:defined) > * {
        display: none;
    }

    .hidden {
        display: none !important;
    }

    //------------------ screen --------------------------------------

    @media (max-width: 800px) {

        #model-3d {
            .content {
                .product {
                    flex-direction: column;
                    height: calc(100% - 110px);

                    .product-model {
                        width: 100% !important;
                    }

                    .product-info {
                        position: relative;
                        width: 100%;

                        h1{
                            margin: 0;
                            order: 1;
                            @include rubik-m16;
                        }

                        .variant-text {
                            order: 2;
                            margin: 4px 0;
                        }
                                        

                        .variants {
                            order: 3;
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }

                        h2 {
                            order: 4;
                            margin: 2px 0;
                            @include rubik-r14;
                        }

                        .price {
                            order: 5;
                            margin: 0 0 8px 0;
                            align-items: baseline;

                            h1 {
                                @include rubik-m16;
                            }
                            h3 {
                                @include rubik-m12;
                                margin: 0;
                            }
                        }

                        .show-dimensions {
                            order: 7;
                            font-size: 12px;
                        }

                        h3 {
                            order: 8;
                        }

                        .confirm {
                            width: 100%;
                            max-width: unset;
                            order: 6;
                            margin: 0 0 8px 0;
                        }
                    }


                    }
                    .all-products {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 108px;
                    padding: 9px;
                    gap: 12px;
                    box-shadow: 0 0 20px -12px black;

                    img {
                        width: 50px;
                        height: 50px;
                    }
                    span {
                        @include rubik-r12;
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }

                }

                
            }
        }
    
    }

    .confirmed {
        background-color: var(--primary-color) !important;
    }

</style>