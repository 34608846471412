<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    export let userdata;

    let loading_data = true;

    let settings;
  
    // načítanie nastavení projektu z DB
    async function fetchData() {
      console.log('sťahujem dáta');
      const res = await fetch(
        userdata['base_path'] + "/assets/php/getConfiguratorScenesSettings.php",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        settings.forEach(element => {
            if (
              element.enabled == "1"
            ) {
              element.enabled = true
            }

            if (
              element.enabled == "0"
            ) {
              element.enabled = false
            }
        });
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }

    // Uložiť nastavenia 
    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "assets/php/updateConfiguratorScenesSettings.php",
        {
          method: "POST",
          body: JSON.stringify(settings),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia META boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia META neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 
  
    fetchData();

    let sample_flats_scenes = [];

    // načítanie všetkých scén vzorových bytov z DB
    async function getSampleFlatsScenes() {
      const res = await fetch(
        userdata["base_path"] + "assets/php/getConfiguratorSampleFlatsScenes.php",
        {}
      );

      if (res.ok) {
        const json = await res.json();
        sample_flats_scenes = json;

        sample_flats_scenes.forEach(element => {
            if (
              element.enabled == "1"
            ) {
              element.enabled = true
            }

            if (
              element.enabled == "0"
            ) {
              element.enabled = false
            }

            if (
              element.enable_design == "1"
            ) {
              element.enable_design = true
            }

            if (
              element.enable_design == "0"
            ) {
              element.enable_design = false
            }

            if (
              element.enable_floors == "1"
            ) {
              element.enable_floors = true
            }

            if (
              element.enable_floors == "0"
            ) {
              element.enable_floors = false
            }

        });

        return json;
      } else {
        throw new Error(data);
      }
    }
    getSampleFlatsScenes();

    // upload loga na server
    async function uploadFile($type, $i) {
      let formData = new FormData();      
      
      switch ($type) {
        case 'favicon':
          formData.append("file", upload_favicon.files[0]);
          break;
        
        case 'image':
          formData.append("file", upload_image.files[0]);
          break;
        
          case 'twitter_image':
          formData.append("file", twitter_image.files[0]);
          break;
      
        default:
          break;
      }
      
  
      const res = await fetch(userdata['base_path'] + 'assets/php/saveImg.php', {
        method: "POST", 
        body: formData
      });    

      const inputs = document.querySelectorAll('#image, #favicon, #twitter_image' );

      inputs.forEach(input => {
        input.value = '';
      });

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
            text: "súbor bol úspešne uploadnutý!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });  
            
          if (json.file_url) {

            settings.configuration.settings.meta[$i].value = 'assets/'+json.file_url;    
            save_settings();
            
          }
        }

        else {
          addNotification({
            text: "Chyba: súbor nebol uploadnutý!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });  
        }
      }  
    }
</script>
  
  <div id="wrapper">
    <Aside />
    
    <main in:fade>

      <h1>{$t("admin_components")['settings']['title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin_components")['settings']['save_button']}</button>


      {#if loading_data == false}
        {#each settings as {id, value}, i}

        {#if settings[i].name == 'project_start_scene' }
            <div class="component item inputs">
              <span>Project start scene</span>
              <div class="item-check">
                <label for="{id}_enabled">Povoliť?</label>
                <input type="checkbox" bind:checked={settings[i].enabled} name="{id}_enabled">
              </div>
              <div class="item-input">
                <label for="{id}_variable">Štartovacia scéna pre celý projekt</label>
                  <select bind:value={settings[i].value}>
                    {#each sample_flats_scenes as flat}
                      <option value={flat.id}>
                        {flat.name}
                      </option>
                    {/each}
                  </select>
              </div>
            </div>   
          {/if}

          {#if settings[i].name == 'design' || settings[i].name == 'floors'  }
            <div class="component item inputs">
              <span>{$t("components")['new_scenes_settings'][settings[i].name]}</span>
              <div class="item-check">
                <label for="{id}_enabled">Povoliť?</label>
                <input type="checkbox" bind:checked={settings[i].enabled} name="{id}_enabled">
              </div>
              <div class="item-input">
                <label for="{id}_variable">{$t("components")['new_scenes_settings']['krpano_variable_name']}</label>
                <input type="text" bind:value={settings[i].krpano_variable_name} name="{id}_variable">
              </div>
            </div>   
          {/if}

          {#if settings[i].name == 'project_url' || settings[i].name == 'scenes_path' || settings[i].name == 'patches_path' || settings[i].name == 'blendmode'}
            <div class="component item paths inputs">
              <span>{$t("components")['new_scenes_settings'][settings[i].name]}</span>
              <!-- <label for="{id}">{$t("components")['new_scenes_settings'][settings[i].name]}</label> -->
              <input type="text" name={id} bind:value={settings[i].value}>
            </div>   
          {/if}
        {/each}
        
      {/if}

      
    </main>
    <Header />
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    .component{
      display: flex;
      flex-direction: column;
      img{
        max-height: 300px;
        align-self: flex-start;
        &.fav{
          max-height: 30px;
        }
      }

      span {
        font-weight: 900;
        margin-right: 30px;
        width: 130px;
        text-align: left;
      }

    }

    .item.inputs {
      display: flex;
      flex-direction: row;

      .item-check, .item-input {
        display: flex;
        flex-direction: column;
      }

      .item-input {
        width: 90%;
        label {
          width: 150px !important;
        }
        input {
          padding: 10px 8px !important;
          margin: 5px auto !important;
        }
      }

      .item-check {
        background-color: #e4e8ed;
        border-radius: 6px;
        margin: 10px;
        padding: 11px;
        margin-left: 0;

        label {
          width: auto !important;
        }
        input {
          margin: 5px 15px 0 0 !important;
        }
      }
    }

    .component.paths {
      label {
        width: 150px !important;
      }
    }

  </style>
  