<script>
  import { get } from 'svelte/store';
  import { shortcuts, sidebar, active_components, vr_mode, otacadlo_selected_floor } from '../store.js';
  import { modal } from '../store.js';
  import { active_scene, otacadlo_compare_flats } from '../store.js';
  import { blur, flat_filter_enabled, otacadlo_all_flats, go_to_flat_from_compare, disabled_icons, otacadlo_all_status, otacadlo_selected_flat, showFlatOnFloor } from '../store.js';
  import { fade, scale } from 'svelte/transition';
  import { t, locale, locales } from "../i18n.js";
  import {enable_price_filter, enable_status_filter, enable_dispositions_filter, enable_floor_filter, enable_compare_flats, is_woow_demo, pdf_flat_compare_export, enable_enter_vr_function, enable_area_info, allow_rotate_to_flat, otacadlo_selected_flat_all_data} from '../store.js';
  import { Lightbox } from 'svelte-lightbox';
  export let userdata, position, url, modal_type_tmp,id,current_scene, title, filter, tour_info_settings, source, prefix;
  
  let _vrMode, _otacadlo_all_flats;

  let _is_woow_demo;
  let _enable_price_filter;
  let _enable_status_filter;
  let _enable_dispositions_filter;
  let _enable_floor_filter;
  let _enable_compare_flats;
  let _pdf_flat_compare_export;
  let _enable_enter_vr_function;
  let _enable_area_info;
  let _allow_rotate_to_flat;
  let _otacadlo_all_status;
  let _otacadlo_compare_flats;

  

  otacadlo_compare_flats.subscribe(value => {
    _otacadlo_compare_flats = value;
    ////console.log(value);
  });

  otacadlo_all_status.subscribe(value => {
    _otacadlo_all_status = value;
    ////console.log(value);
  });

  is_woow_demo.subscribe(value => {
        _is_woow_demo = value;
    });
  enable_price_filter.subscribe(value => {
      _enable_price_filter = value;
  });
  enable_status_filter.subscribe(value => {
      _enable_status_filter = value;
  });
  enable_dispositions_filter.subscribe(value => {
      _enable_dispositions_filter = value;
  });
  enable_floor_filter.subscribe(value => {
      _enable_floor_filter = value;
  });
  enable_compare_flats.subscribe(value => {
      _enable_compare_flats = value;
      //console.log(value);
  });
  pdf_flat_compare_export.subscribe(value => {
    _pdf_flat_compare_export = value;
  });
  enable_enter_vr_function.subscribe(value => {
    _enable_enter_vr_function = value;
  });
  enable_area_info.subscribe(value => {
    _enable_area_info = value;
  });
  allow_rotate_to_flat.subscribe(value => {
    _allow_rotate_to_flat = value;
  });

  
    
  vr_mode.subscribe(value => {
      _vrMode = value;
  });

  const animate = (node, args) =>
  args.cond ? fade(node, args) : scale(node, args);

  otacadlo_all_flats.subscribe(value => {
    _otacadlo_all_flats = value;
    if (value != undefined) {
      value.forEach(element => {
      if (element.compare) {
        //console.log(value);
      }
    });
    }
    
    
    
  });
  
  

  modal.subscribe(value => {
    
      if (!value && modal_type_tmp == "flat-compare") {
        
      }

      if (!value) {
        disabled_icons.update(n => false);
        flat_filter_enabled.update(n => false);
        blur.update(n => false);
      }

      ////console.log(get(blur) + " : " + get(disabled_icons) + " : " + get(flat_filter_enabled) );
  });

  let models = {
    skypark : {
      ground : {
        cameraOrbit : null,
        fieldOfView : null, 
      }, 
      floor_14 : {
        cameraOrbit : null,
        fieldOfView : null, 
      },
      floor_26 : {
        cameraOrbit : null,
        fieldOfView : null, 
      }
    }, 
    bateria1 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.40201601139738946m",
        cameraTarget: "17.42057395164845m 0.9074973842160838m -12.168586346084595m",
        fieldOfView : "30.000000000000004deg"
      },
      1 : {
        title : "Výpusť",
        cameraOrbit : "-0.32252091880959854rad 2.1093266264121593rad 0.3561753790397009m",
        cameraTarget: "17.42057395164845m 0.9074973842160838m -12.168586346084595m",
        fieldOfView : "23.877293194659103deg"
      },
      2 : {
        title : "Rukoväť",
        cameraOrbit : "2.48224234139882rad 0.763811501691976rad 0.3561753790397009m",
        cameraTarget: "17.42057395164845m 0.9074973842160838m -12.168586346084595m",
        fieldOfView : "23.877293194659103deg"
      }
    },
    bateria2 : {
      0 : {
        title : "Default View",
        cameraOrbit : " 0rad 1.3089969389957472rad 0.46961992104359424m",
        cameraTarget: "17.420574949094636m 0.9157605385780335m -12.158531588201274m",
        fieldOfView : "30.000000000000004deg"
      },
      1 : {
        title : "Výpusť",
        cameraOrbit : "0.6808774952647082rad 2.384066668361077rad 0.37423144560728977m",
        cameraTarget : "17.419628403323944m 0.941196880196289m -12.120227422989892m",
        fieldOfView : "21.17727654193545deg"
      },
      2 : {
        title : "Rukoväť",
        cameraOrbit : "5.504172494704074rad 0.9258758836799182rad 0.287959909424358m",
        cameraTarget: "17.401913949077457m 0.9772957027834677m -12.218262352906686m",
        fieldOfView : "21.205343641652743deg"
      }
    },
    bateria3 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.5293735202008621m",
        cameraTarget: "17.449173354882248m 0.9248394023696764m -12.15412219251219m",
        fieldOfView : "30.000000000000004deg"
      },
      1 : {
        title : "Výpusť",
        cameraOrbit : "0.6987692428228008rad 2.3942383615792346rad 0.35073535861195193m",
        cameraTarget: "17.420832703611065m 1.0024577330797677m -12.102614790513732m",
        fieldOfView : "13.834842378257687deg"
      },
      2 : {
        title : "Rukoväť",
        cameraOrbit : "2.198035682240874rad 2.107985780994789rad 0.34721089273084843m",
        cameraTarget: "17.493378980121914m 0.8794964715663294m -12.221570464380049m",
        fieldOfView : "17.14803203391154deg"
      }
    },
    kovanie1 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.4832277285586518m",
        cameraTarget: "16.944060814173643m 1.069356690373624m -11.618706662489334m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    kovanie2 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.43917144601646485m",
        cameraTarget: "16.944061011083477m 1.0695352457125897m -11.620578419249217m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    kovanie3 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.47750963989516876m",
        cameraTarget: "16.94406072043934m 1.0694249412138637m -11.622094957384679m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    tlacidlo1 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.6028281142179238m",
        cameraTarget: "18.118045653994198m 1.0002125548329872m -12.261903118745781m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    tlacidlo2 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 0.602930149509058m",
        cameraTarget: "18.118045658387476m 1.0002125548491683m -12.270970007239757m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    umyvadlo1 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 1.5685513901444184m",
        cameraTarget: "17.418701448993005m 0.7684358193654715m -12.04630639121976m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    umyvadlo2 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 1.569307109890004m",
        cameraTarget: "17.41907860053989m 0.7592177075526798m -12.04485070565728m",
        fieldOfView : "30.000000000000004deg"
      }
    },
    wc1 : {
      0 : {
        title : "Default View",
        cameraOrbit : "0rad 1.3089969389957472rad 1.4458327230161274m",
        cameraTarget: "18.12043701171875m 0.2395194530487061m -12.01369140625m",
        fieldOfView : "30.000000000000004deg"
      }
    }
  }

let _active_component = null;
active_components.subscribe(value => {
  _active_component = value;
});


    // These values are bound to properties of the video
	let time = 0;
	let duration;
	let paused = true;

	let showControls = true;
	let showControlsTimeout;

	// Used to track time of last mouse down event
	let lastMouseDown;

	function handleMove(e) {
		// Make the controls visible, but fade out after
		// 2.5 seconds of inactivity
		clearTimeout(showControlsTimeout);
		showControlsTimeout = setTimeout(() => showControls = false, 2500);
		showControls = true;

		if (!duration) return; // video not loaded yet
		if (e.type !== 'touchmove' && !(e.buttons & 1)) return; // mouse not down

		const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
		const { left, right } = this.getBoundingClientRect();
		time = duration * (clientX - left) / (right - left);
	}

	// we can't rely on the built-in click event, because it fires
	// after a drag — we have to listen for clicks ourselves
	function handleMousedown(e) {
		lastMouseDown = new Date();
	}

	function handleMouseup(e) {
		if (new Date() - lastMouseDown < 300) {
			if (paused) e.target.play();
			else e.target.pause();
		}
	}

	function format(seconds) {
		if (isNaN(seconds)) return '...';

		const minutes = Math.floor(seconds / 60);
		seconds = Math.floor(seconds % 60);
		if (seconds < 10) seconds = '0' + seconds;

		return `${minutes}:${seconds}`;
	}

    let is_modal = false;

    $: {
      is_modal;
      
      switch (is_modal) {
        case true : 
          shortcuts.update(n => false);
          sidebar.update(n => false);
          blur.update(n => true);
        break;

        case false : 
          blur.update(n => false);

          // zapnutie posledného aktívneho komponentu
          switch (_active_component) {
            case "sidebar":
            sidebar.update(n => true);
              break;
          
            default:
              break;
          }
        break;
      }
    }

    modal.subscribe(value => {
		  is_modal = value;

      switch (value) {
        case true:

          break;
      
        default:
          break;
      }
      
	  });

    
    

    if (
      filter == undefined
    ) {
      filter = false;
    }




    
    let a_scene;
  	active_scene.subscribe(value => {
		  a_scene = value;
      // ////console.log('scéna : ' + a_scene);
      
	});

  let version = 1;

  let modelViewerVariants, select, first_time;
  let selected_model = "bateria1";

   $ : {
    selected_model;

    let modelViewer = document.querySelector("model-viewer");
    if (modelViewer != null) {
      
      modelViewer.addEventListener('load', (event) => {
        //console.log(modelViewer.model.materials);
      });
      // modelViewer.addEventListener('load', (event) => {
      //   modelViewer.cameraOrbit = models[selected_model][0].cameraOrbit;
      //   modelViewer.cameraTarget = models[selected_model][0].cameraTarget;
      //   modelViewer.fieldOfView = models[selected_model][0].fieldOfView;
      // })
      
    }
    
   }

  function autorotate() {
    let modelViewer = document.querySelector("model-viewer");
    if (modelViewer != null) {
      modelViewer.setAttribute("auto-rotate-delay", 0);
      modelViewer.toggleAttribute("auto-rotate");
      //modelViewer.setAttribute("auto-rotate", "");
    }
    // modelViewer.setAttribute("auto-rotate-delay", 0);
    // modelViewer.toggleAttribute("auto-rotate");
    // modelViewer.setAttribute("auto-rotate", "");
    
    // modelViewer.fieldOfView = '45deg';
  }

  function moveTo($parameter, $target) {
    //console.log($parameter + " : " + $target);
    let modelViewer = document.querySelector("model-viewer");
    switch ($parameter) {
      case "default" :

      modelViewer.cameraOrbit = "0deg, 75deg, 105%";
      modelViewer.cameraTarget = "auto auto auto";
      modelViewer.fieldOfView = "30deg";
        
        
        
        break;
    
      default:
      modelViewer.cameraOrbit = models[$parameter][$target].cameraOrbit;
      modelViewer.cameraTarget = models[$parameter][$target].cameraTarget;
      modelViewer.fieldOfView = models[$parameter][$target].fieldOfView;
      
        break;
    }
    
  }

  let show_dimensions = false;
  const formatter = new Intl.NumberFormat('sk-SK', {
      style: 'currency',
      currency: 'EUR',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    let selected_flat_menu = null;

    function toggle_submenu(id) {
      if (selected_flat_menu == id) {
        selected_flat_menu = null;
      } else {
        selected_flat_menu = id;
      }
    }

    function should_open_filter() {
      if (modal_type_tmp == "flat-compare") {
        flat_filter_enabled.update(n => true);
      }
    }

    function select_floor(data) {
      //console.log("select_floor");
      otacadlo_selected_flat.update( n=> data.woow_id);
      otacadlo_selected_flat_all_data.update(n => data);
      //allow_rotate_to_flat.update( n => true);
      showFlatOnFloor.update( n => data );
      modal.update(n => !n);
    }
</script>
<div id="woow-modal">
{#if _vrMode == false}
  <div id="modal-blur" transition:fade/>
  <div id="modal" 
  class:relative="{position === 'relative'}" 
  class:text="{modal_type_tmp === 'info-v3' || modal_type_tmp === 'infopanel-v3' || modal_type_tmp === 'tour-info' }"
  class:image-only="{modal_type_tmp === 'image-only' }"
  class:video="{modal_type_tmp === 'video-v1' || modal_type_tmp === 'video-v2' || modal_type_tmp === 'videopanel-v2' }"
  transition:fade>
  <div class="close" on:click="{() => modal.update(n => !n)}"
    on:click="{() => should_open_filter()}"/>
    {#if modal_type_tmp == 'flat-compare'}
      <div class="title">{$t("components")['flat_filter']['compare-title']}</div>
      
      <div class="content {modal_type_tmp}" class:blank={!_pdf_flat_compare_export && !_enable_status_filter}>

        <!-- POVODNY POROVNAVAC -->

        <!-- <div class="compare-header">
          <div class="flats">{$t("components")['flat_filter']['selected-flats']}</div>
          <div class="benefits">
            <p>{$t("components")['flat_filter']['floor-size']}</p>
            <p>{$t("components")['flat_filter']['status']}</p>
            <p>{$t("components")['flat_filter']['floor']}</p>
            <p>{$t("components")['flat_filter']['room-count']}</p>
            <p>{$t("components")['flat_filter']['price-range']}</p>
          </div>
        </div>
        <div class="selected-flats">
          {#each _otacadlo_all_flats as flat}
            {#if flat.compare}
            <div class="flat-item">
              <div class="flat-name">{flat.woow_id}</div>
              <div class="flat-values">
                <p>{flat.flat_area} m<sup>2</sup></p>
                <div class="state {flat.flat_status}"></div>
                <p>{flat.floor[0]}</p>
                <p>{flat.flat_disposition}</p>
                <p class="price">{formatter.format(flat.flat_price)}</p>
              </div>
            </div>
            {/if}
          {/each}
        </div> -->

        <!-- NOVY POROVNAVAC -->

        {#each _otacadlo_all_flats as flat}
          {#if _otacadlo_compare_flats.includes(flat.woow_id)}
            <div class="flat-card" class:description-off={!_enable_area_info}>
                {#if _enable_status_filter}
                   <div class="state {flat.flat_status}"></div>
                {/if}
              <div class="img-wrapper">
                <Lightbox description="{$t("components")['flat_filter']['flat']} : {flat.woow_id}">
                  {#if (_is_woow_demo && flat.floorplan == "") || flat.floorplan == null}
                    <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}podorysy/TOP1.jpg"  alt="img" class="floorplan" class:active={selected_flat_menu == flat.woow_id}>
                    {:else if userdata["assets_folder"].includes("strabag/")}   
                      <img src="{flat.floorplan}"  alt="img" class="floorplan" class:active={selected_flat_menu == flat.woow_id}>
                    {:else if flat.floorplan != "" && flat.floorplan != null && flat.floorplan != undefined}
                        <img src="{flat.floorplan}"  alt="img" class="floorplan" class:active={selected_flat_menu == flat.woow_id}>
                    {:else }
                    <img src="{userdata["cdn_path"]}images/no-image.jpg"  alt="img" class="floorplan" class:active={selected_flat_menu == flat.woow_id}>
                  {/if}
                </Lightbox>
                
                <div class="menu-wrapper" class:active={selected_flat_menu == flat.woow_id}>
                  <div class="show-floorplan" class:active={selected_flat_menu == flat.woow_id} on:click={() => select_floor(flat)}>{$t("components")['flat_filter']['visit-floorplan']}</div>
                  {#if _enable_enter_vr_function && flat.flat_status != "sold" }
                    <div class="show-tour" class:active={selected_flat_menu == flat.woow_id}
                      on:click={() => go_to_flat_from_compare.update(n => flat)}
                      on:click="{() => modal.update(n => !n)}"
                    >{$t("components")['flat_filter']['visit-tour']}</div>
                  {/if}  
                </div>
              </div>
              <div class="flat-card-title" on:click={() => toggle_submenu(flat.woow_id)}>
                <span class="flat-name">{flat.woow_id}</span>
                <div class="submenu"><i class="fa-solid fa-bars"></i></div>
              </div>
              <div class="flat-info">
                <div class="global">
                  <div class="global-row">
                    <p>{$t("components")['flat_filter']['flat-id']}</p>
                    <p>{flat.woow_id}</p>
                  </div>
                  <div class="global-row">
                    <p>{$t("components")['flat_filter']['block']}</p>
                    <p>B1</p>
                  </div>
                  <div class="global-row">
                    <p>{$t("components")['flat_filter']['floor']}</p>
                    <p>
                      {#if flat.floor == 0}
                          {$t("components")['flat_filter']['0']}
                      {:else}
                          {flat.floor}
                      {/if}    
                    </p>
                  </div>
                  <div class="global-row">
                    <p>{$t("components")['flat_filter']['room-count']}</p>
                    <p>{flat.flat_disposition}</p>
                  </div>
                </div>
                {#if _enable_area_info}
                  <div class="room">
                    {#if flat.flat_area_bathroom1 != null && flat.flat_area_bathroom1 != undefined && flat.flat_area_bathroom1 != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['bathroom']}</p>
                        <p>{flat.flat_area_bathroom1} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_bedroom != null && flat.flat_area_bedroom != undefined && flat.flat_area_bedroom != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['bedroom']}</p>
                        <p>{flat.flat_area_bedroom} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_hallway != null && flat.flat_area_hallway != undefined && flat.flat_area_hallway != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['hallway']}</p>
                        <p>{flat.flat_area_hallway} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_kitchen != null && flat.flat_area_kitchen != undefined && flat.flat_area_kitchen != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['kitchen']}</p>
                        <p>{flat.flat_area_kitchen} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_loggia != null && flat.flat_area_loggia != undefined && flat.flat_area_loggia != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['loggia']}</p>
                        <p>{flat.flat_area_loggia} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_room != null && flat.flat_area_room != undefined && flat.flat_area_room != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['room']}</p>
                        <p>{flat.flat_area_room} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_wc != null && flat.flat_area_wc != undefined && flat.flat_area_wc != ""}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['wc']}</p>
                        <p>{flat.flat_area_wc} m<sup>2</sup></p>
                      </div>
                    {/if}
                    {#if flat.flat_area_terrace != null && flat.flat_area_terrace != undefined}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['terrace']}</p>
                        {#if flat.flat_area_terrace == ""}
                          <p>{flat.flat_area_terrace} -</p> 
                        {:else}
                          <p>{flat.flat_area_terrace} m<sup>2</sup></p>
                        {/if}
                        
                      </div>
                    {/if}
                    {#if flat.flat_area_garden != null && flat.flat_area_balcony != undefined}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['balcony']}</p>
                        {#if flat.flat_area_balcony == ""}
                          <p>{flat.flat_area_balcony} -</p> 
                        {:else}
                          <p>{flat.flat_area_balcony} m<sup>2</sup></p>
                        {/if}
                        
                      </div>
                    {/if}
                    {#if flat.flat_area_garden != null && flat.flat_area_garden != undefined}
                      <div class="room-row">
                        <p>{$t("components")['flat_filter']['garden']}</p>
                        {#if flat.flat_area_garden == ""}
                          <p>{flat.flat_area_garden} -</p> 
                        {:else}
                          <p>{flat.flat_area_garden} m<sup>2</sup></p>
                        {/if}
                        
                      </div>
                    {/if}
                  </div>
                {/if}
                
                <div class="area">
                  {#if flat.usefull_area_of_the_apartment != null && flat.usefull_area_of_the_apartment != undefined && flat.usefull_area_of_the_apartment != ""}
                    <div class="area-row">
                      <p>{$t("components")['flat_filter']['usefull_area_of_the_apartment']}</p>
                      <p>{flat.usefull_area_of_the_apartment} m<sup>2</sup></p>
                    </div>
                  {/if}

                  {#if flat.usefull_area_total != null && flat.usefull_area_total != undefined && flat.usefull_area_total != ""}
                    <div class="area-row">
                      <p>{$t("components")['flat_filter']['usefull_area_total']}</p>
                      <p>{flat.usefull_area_total} m<sup>2</sup></p>
                    </div>
                  {/if}
                 
                  
                </div>
              </div>
              {#if _enable_price_filter}
                <div class="price">{formatter.format(flat.flat_price)}</div>
              {/if}
              
            </div>
          {/if}
        {/each}

      </div>
      <div class="compare-footer">

        {#if _pdf_flat_compare_export}
          <div class="compare-pdf">{$t("components")['flat_filter']['download-pdf']}</div>
        {/if}
  
        {#if _enable_status_filter && _otacadlo_all_status.length > 0}
        <div class="state-wrapper">
          {#each _otacadlo_all_status as status}
            <span class="{status}">{$t("components")['flat_filter'][status]}</span>
          {/each}
          
        </div>
        {/if}
      
      </div>
    {/if}

    {#if modal_type_tmp == 'google-map'}
      <div class="title">{title}</div>
        <div class="content {modal_type_tmp}">
          <iframe title="{title}" src="{url}" style="border:0;" allowfullscreen="" loading="lazy" class:filter="{filter === true}"/>  
        </div>
    {/if}

    {#if modal_type_tmp == 'google-map2'}
      <div class="title">3D model</div>
        <div class="content {modal_type_tmp}">
          <iframe title="3D model" src="https://campusalbano.se/view/all" style="border:0;" allowfullscreen="" loading="lazy"/>  
        </div>
    {/if}

    {#if modal_type_tmp == 'book-call'}
      <div class="title">{$t("components")['book_call']['title']}</div>
        <div class="content {modal_type_tmp}">
          <!-- <iframe title="3D model" width="100%" height="100%" src="https://doodle.com/bp/%C5%A1tevokelbel-zone360/woowstudio---meeting-room" style="border:0;" allowfullscreen="" loading="lazy"/>   -->
          <iframe src="https://woowstudio.youcanbook.me/?noframe=true&skipHeaderFooter=true" id="ycbmiframewoowstudio" style="width:100%;height:1000px;border:0px;background-color:transparent;" frameborder="0" allowtransparency="true"></iframe><script>window.addEventListener && window.addEventListener("message", function(event){if (event.origin === "https://woowstudio.youcanbook.me"){document.getElementById("ycbmiframewoowstudio").style.height = event.data + "px";}}, false);</script>
        </div>
    {/if}

    {#if modal_type_tmp == 'model-3d'}
      <!-- <div class="title">3D model (<span class:active="{version === 1}" on:click={() => version = 1}>ver 1</span> <span class:active="{version === 2}" on:click={() => version = 2}>ver 2</span>)</div> -->
      <div class="title" on:click={() => moveTo("default")}>3D model</div>
      {#each Object.entries(models) as [key, value], index (key)} 
          {#if key == selected_model}
            <div class="views">
              <span on:click={() => autorotate()}>Auto rotate | </span>
            {#each Object.entries(value) as [data, element], index (data)} 
              <span on:click={() => moveTo(selected_model, data)}>{element["title"]}</span>
            {/each}
            </div>
        {/if}
      {/each}
      <div class="content {modal_type_tmp}">

        <!-- <div class="controls" id="color-controls">
          <div data-color="">none</div>
          <div data-color="#ff0000">Red</div>
          <div data-color="#00ff00">Green</div>
          <div data-color="#0000ff">Blue</div>
        </div> -->
      <!--  Modely produktov  -->
        <model-viewer class:hidden="{version === 2}" id="bateria" autoplay style="width: 100%; height: 100%;" camera-controls touch-action="pan-y" 
          src="{userdata["cdn_path"]}models/office_building.glb" ar alt="" shadow-intensity="1"
          skybox-image=""
          >

          
        
          <!-- skybox-image="assets/img/spruit_sunrise_1k_HDR.hdr" -->

          <!-- skybox-image="assets/img/assets/img/VR_ext_01_0100_round_01.jpg" -->
          
          
            <!-- <div class="controls">
              <div>Variant: <select id="variant"></select></div>
            </div> -->
          
        
        </model-viewer>
    
        <script>

          document.querySelector('#color-controls').addEventListener('click', (event) => {
            const colorString = event.target.dataset.color;
            const [material] = modelViewer.model.materials;
            material.pbrMetallicRoughness.setBaseColorFactor(colorString);
          });
          // modelViewerVariants = document.querySelector("model-viewer#shoe");
          // select = document.querySelector('#variant');
          

          // function removeOptions(selectElement) {
          //   var i, L = selectElement.options.length - 1;
          //   for(i = L; i >= 0; i--) {
          //       selectElement.remove(i);
          //   }
          // }

          // removeOptions(document.getElementById('variant'));
          first_time = true;
          // modelViewerVariants.addEventListener('load', () => {
          //   if(first_time == true) {
          //     const names = modelViewerVariants.availableVariants;
          //     for (const name of names) {
          //       const option = document.createElement('option');
          //       option.value = name;
          //       option.textContent = name;
          //       select.appendChild(option);
          //     }  

          //     first_time = false;
          //   }
            
            
          // });
          
          // select.addEventListener('input', (event) => {
          //   modelViewerVariants.variantName = event.target.value === 'default' ? null : event.target.value;
          // });

          let modelViewer = document.querySelector("model-viewer");
          
          modelViewer.addEventListener('camera-change', (event) => {
            // //console.log(event.detail);
            //console.log(modelViewer.getCameraOrbit().toString());
            //console.log(modelViewer.getCameraTarget().toString());
            //console.log(modelViewer.getFieldOfView().toString());
          });

          window.switchSrc = (element, name) => {
            selected_model = name;
            const base = {userdata["cdn_path"]} + "models/modely/Update/" + name;
            
            modelViewer.src = base + '.glb';
            // modelViewer.poster = base + '.webp';
            const slides = document.querySelectorAll(".slide");
            slides.forEach((element) => {element.classList.remove("selected");});
            element.classList.add("selected");
            
          };

          // //console.log(modelViewer.event.detail.totalProgress);
        

          document.querySelector(".slider").addEventListener('beforexrselect', (ev) => {
            // Keep slider interactions from affecting the XR scene.
            ev.preventDefault();
          });

          const checkbox = modelViewer.querySelector('#show-dimensions');

          function setVisibility(element) {
            // if (checkbox.checked) {
            //   element.classList.remove('hide');
            // } else {
            //   element.classList.add('hide');
            // }
          }

          checkbox.addEventListener('change', () => {
            setVisibility(modelViewer.querySelector('#dimLines'));
            modelViewer.querySelectorAll('button').forEach((hotspot) => {
              setVisibility(hotspot);
            });
          });


          function drawLine(svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) {
            if (dotHotspot1 && dotHotspot1) {
              svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
              svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
              svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
              svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);

              // use provided optional hotspot to tie visibility of this svg line to
              if (dimensionHotspot && !dimensionHotspot.facingCamera) {
                svgLine.classList.add('hide');
              }
              else {
                svgLine.classList.remove('hide');
              }
            }
          }

          const dimLines = modelViewer.querySelectorAll('line');
          const renderSVG = () => {
            drawLine(dimLines[0], modelViewer.queryHotspot('hotspot-dot+X-Y+Z'), modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Y'));
            drawLine(dimLines[1], modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Z'));
            drawLine(dimLines[2], modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X+Y-Z')); // always visible
            drawLine(dimLines[3], modelViewer.queryHotspot('hotspot-dot-X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dim-X-Z'));
            drawLine(dimLines[4], modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y+Z'), modelViewer.queryHotspot('hotspot-dim-X-Y'));
          };

          modelViewer.addEventListener('camera-change', renderSVG);

          modelViewer.addEventListener('load', () => {
            const center = modelViewer.getBoundingBoxCenter();
            const size = modelViewer.getDimensions();
            const x2 = size.x / 2;
            const y2 = size.y / 2;
            const z2 = size.z / 2;

            modelViewer.updateHotspot({
              name: 'hotspot-dot+X-Y+Z',
              position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim+X-Y',
              position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+X-Y"]').textContent =
                `${(size.z * 100).toFixed(0)} cm`;

            modelViewer.updateHotspot({
              name: 'hotspot-dot+X-Y-Z',
              position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim+X-Z',
              position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+X-Z"]').textContent =
                `${(size.y * 100).toFixed(0)} cm`;

            modelViewer.updateHotspot({
              name: 'hotspot-dot+X+Y-Z',
              position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim+Y-Z',
              position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+Y-Z"]').textContent =
                `${(size.x * 100).toFixed(0)} cm`;

            modelViewer.updateHotspot({
              name: 'hotspot-dot-X+Y-Z',
              position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim-X-Z',
              position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim-X-Z"]').textContent =
                `${(size.y * 100).toFixed(0)} cm`;

            modelViewer.updateHotspot({
              name: 'hotspot-dot-X-Y-Z',
              position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim-X-Y',
              position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim-X-Y"]').textContent =
                `${(size.z * 100).toFixed(0)} cm`;

            modelViewer.updateHotspot({
              name: 'hotspot-dot-X-Y+Z',
              position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`
            });

            renderSVG();
          });


        </script>

      <!-- Model s natočením -->
        <model-viewer class:hidden="{version === 1}" style="width: 100%; height: 100%;" id="hotspot-camera-view-demo" src="{userdata["cdn_path"]}models/ThorAndTheMidgardSerpent.glb" alt="Thor and the Midgard Serpent" camera-controls touch-action="none" camera-orbit="-8.142746deg 68.967deg 0.6179899m" camera-target="-0.003m 0.0722m 0.0391m" field-of-view="45deg" min-field-of-view="25deg" max-field-of-view="45deg" interpolation-decay="200" min-camera-orbit="auto auto 5%" poster="../../assets/SketchfabModels/ThorAndTheMidgardSerpent.webp" ar>
          <button class="view-button" slot="hotspot-0" data-position="-0.0569m 0.0969m -0.1398m" data-normal="-0.5829775m 0.2863482m -0.7603565m" data-orbit="-50.94862deg 84.56856deg 0.06545582m" data-target="-0.04384604m 0.07348397m -0.1213202m">
            The Fighters
          </button>      
          <button class="view-button" slot="hotspot-1" data-position="-0.1997m 0.11766m 0.0056m" data-normal="-0.4421014m 0.04410423m 0.8958802m" data-orbit="3.711166deg 92.3035deg 0.04335197m" data-target="-0.1879433m 0.1157161m -0.01563221m">
            Hold Tight!
          </button>      
          <button class="view-button" slot="hotspot-2" data-position="0.0608m 0.0566m 0.0605m" data-normal="0.2040984m 0.7985359m -0.56629m" data-orbit="42.72974deg 84.74043deg 0.07104211m" data-target="0.0757959m 0.04128428m 0.07109568m">
            The Encounter
          </button>      
          <button class="view-button" slot="hotspot-3" data-position="0.1989m 0.16711m -0.0749m" data-normal="0.7045857m 0.1997957m -0.6809117m" data-orbit="-40.11996deg 88.17818deg 0.07090651m" data-target="0.2011831m 0.1398312m -0.07917573m">
            Catapult
          </button>      
          <button class="view-button" slot="hotspot-4" data-position="0.0677m 0.18906m -0.0158m" data-normal="-0.008245394m 0.6207898m 0.7839338m" data-orbit="-118.8446deg 98.83521deg 0.06m" data-target="0.06528695m 0.1753406m -0.01964653m">
            Thunder and Lightning
          </button>      
          <button class="view-button" slot="hotspot-5" data-position="-0.1418m -0.041m 0.174m" data-normal="-0.4924125m 0.4698265m 0.7326617m" data-orbit="-2.305313deg 110.1798deg 0.04504082m" data-target="-0.1151219m -0.04192762m 0.1523764m">
            Knock Knock
          </button>     
          <button class="view-button" slot="hotspot-6" data-position="0.08414419m 0.134m -0.215m" data-normal="0.03777227m 0.06876653m -0.9969176m" data-orbit="-37.54149deg 82.16209deg 0.0468692m" data-target="0.08566038m 0.1249514m -0.1939646m">
            Lucky Shot
          </button>      
          <button class="view-button" slot="hotspot-7" data-position="0.14598m 0.03177m -0.05945886m" data-normal="-0.9392524m 0.2397608m -0.2456009m" data-orbit="-142.3926deg 86.45934deg 0.06213665m" data-target="0.1519967m 0.01904771m -0.05945886m">
            Get Away!
          </button>      
          <button class="view-button" slot="hotspot-8" data-position="0.0094m 0.0894m -0.15103m" data-normal="-0.3878782m 0.4957891m -0.7770094m" data-orbit="-118.6729deg 117.571deg 0.03905975m" data-target="0.007600758m 0.06771782m -0.1386167m">
            The Jump
          </button>      
          <button class="view-button" slot="hotspot-9" data-position="-0.0658m 0.1786m -0.0183m" data-normal="0.7857152m 0.4059967m 0.46671m" data-orbit="53.28236deg 95.91318deg 0.1102844m" data-target="-0.07579391m 0.1393538m -0.00851791m">
            The Beast
          </button>     
          <button class="view-button" slot="hotspot-10" data-position="0.02610224m 0.01458751m -0.004978945m" data-normal="-0.602551m 0.7856147m -0.1405055m" data-orbit="-78.89725deg 77.17752deg 0.08451112m" data-target="0.02610223m 0.0145875m -0.004978945m">
            Treasure
          </button>
          <button class="view-button" slot="hotspot-11" data-position="-0.1053838m 0.01610652m 0.1076345m" data-normal="-0.624763m 0.5176854m 0.5845283m" data-orbit="10.89188deg 119.9775deg 0.03543022m" data-target="-0.1053838m 0.01610652m 0.1076345m">
            Desperation
          </button>
        </model-viewer>

        <script>
          const modelViewer2 = document.querySelector("#hotspot-camera-view-demo");
          const annotationClicked = (annotation) => {
            let dataset = annotation.dataset;
            modelViewer2.cameraTarget = dataset.target;
            modelViewer2.cameraOrbit = dataset.orbit;
            modelViewer2.fieldOfView = '45deg';
          }
        
          modelViewer2.querySelectorAll('button').forEach((hotspot) => {
            hotspot.addEventListener('click', () => annotationClicked(hotspot));
          });
        </script> 
      </div>
    {/if}
    
    {#if modal_type_tmp == 'tour-info' && tour_info_settings != null}
      <div class="title">{tour_info_settings.title}</div>
      <div class="content {modal_type_tmp}">
        <h1 class="content-title">
          {@html tour_info_settings.headline}    
        </h1>
        <div class="grid-img-left">
          <div class="image">
            <img src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}{tour_info_settings.img}" alt="img">
          </div>
          <div class="subtitle">
            {@html tour_info_settings.subtitle}
          </div>
          <div class="description">
            <p>
              {@html tour_info_settings.content}
            </p>
          </div>
        </div>
      </div>
    {/if}
    {#if modal_type_tmp == 'info-v1'}
      <div class="title">{@html source.title }</div>
      <div class="content {modal_type_tmp}">
        <h1 class="content-title">
          {@html source.headline}
        </h1>
        <div class="grid-img-left">
          <div class="image">
            <img src="images/{source.img}" alt="img">
          </div>
          <div class="subtitle">
            {@html source.subtitle}
          </div>
          <div class="description">
            <p>
              {@html source.text}
            </p>
          </div>
        </div>           
      </div>
    {/if}

    <!-- {#if modal_type_tmp == 'info-v2'}
      <div class="title">{@html source.title}</div>
      <div class="content {modal_type_tmp} text-only">
        <div class="description text-only">
          <p>
            {@html source.text}
          </p>
        </div>
      </div>
    {/if} -->

    {#if modal_type_tmp == 'image-only'}
      <div class="title">{@html source.title}</div>
      <div class="content {modal_type_tmp}">
          <img src="{userdata["cdn_path"]}images/{source.img}" alt="img" class="image-only full-image">
      </div>
    {/if}

    {#if modal_type_tmp == 'infopanel-v1'}
      {#each settings["scenes"] as scene}   
        {#if scene.id === current_scene.toLowerCase()}
          {#each scene.hotspots as hotspot}
            {#if hotspot.name == id || 
                  hotspot.id == id
                  }
              <div class="title">{@html hotspot.title}</div>
            <div class="content {modal_type_tmp}">
              <h1 class="content-title">
                {@html hotspot.data.headline}    
            </h1>
            <div class="grid-img-left">
              <div class="image">
                <img src="{userdata["cdn_path"]}images/{hotspot.data.img}" alt="img">
              </div>
              <!-- <div class="subtitle">
                {@html hotspot.data.subtitle}
              </div> -->
              <div class="description">
                <p>
                  {@html hotspot.data.content}
                </p>
              </div>
            </div>
        </div>
            {/if}
          {/each}
        {/if}    
      {/each}            
    {/if}

    {#if modal_type_tmp == 'tour_ths'}
      {#each settings["scenes"] as scene}   
        {#if scene.id === current_scene.toLowerCase()}
          {#each scene.hotspots as hotspot}
            {#if hotspot.name == id || 
                  hotspot.id == id
                  }
              <div class="title">{@html hotspot.title}</div>
            <div class="content tour-info">
              <h1 class="content-title">
                {@html hotspot.data.headline}    
            </h1>
            <div class="grid-img-left">
              <div class="image">
                <img src="{userdata["cdn_path"]}iamges/{hotspot.data.img}" alt="img">
              </div>
              <!-- <div class="subtitle">
                {@html hotspot.data.subtitle}
              </div> -->
              <div class="description">
                <p>
                  {@html hotspot.data.content}
                </p>
              </div>
            </div>
        </div>
            {/if}
          {/each}
        {/if}    
      {/each}            
    {/if}

    {#if modal_type_tmp == 'info-v22' || modal_type_tmp == 'infopanel-v2'}
      {#each settings["scenes"] as scene}   
        {#if scene.id === current_scene.toLowerCase()}
          {#each scene.hotspots as hotspot}
          {#if hotspot.name == id || 
            hotspot.id == id
            }
              <div class="title">{@html hotspot.data.title}</div>
            <div class="content {modal_type_tmp} text-only">
              <!-- <h1 class="content-title text-only">
                {@html hotspot.data.headline}    
            </h1>
            <div class="subtitle text-only">
              {@html hotspot.data.subtitle}
            </div> -->
            <div class="description text-only">
              <p>
                {@html hotspot.data.content}
              </p>
            </div>
        </div>
            {/if}
          {/each}
        {/if}    
      {/each}            
    {/if}


    {#if modal_type_tmp == 'info-v3' || modal_type_tmp == 'infopanel-v3'}
      {#each settings["scenes"] as scene}   
        {#if scene.id === current_scene.toLowerCase()}
          {#each scene.hotspots as hotspot}
          {#if hotspot.name == id || 
            hotspot.id == id
            }
              <div class="title">{@html hotspot.title}</div>
            <div class="content {modal_type_tmp} image-only">
                <img src="{userdata["cdn_path"]}images/{hotspot.data.img}" class="full-image image-only" alt="img">
            </div>
            {/if}
          {/each}
        {/if}    
      {/each}            
    {/if}

    {#if modal_type_tmp == 'video-v1' || modal_type_tmp == 'videopanel-v1'}
      {#each settings["scenes"] as scene}   
        {#if scene.id === current_scene.toLowerCase()}
          {#each scene.hotspots as hotspot}
          {#if hotspot.name == id || 
            hotspot.id == id
            }
              <div class="title">{@html hotspot.data.title}</div>
            <div class="content {modal_type_tmp} videopanel">
              <div>
                <iframe
                  width="560"
                  height="315"
                  src="{hotspot.data.video_url}"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
            {/if}
          {/each}
        {/if}    
      {/each}            
    {/if}

    {#if modal_type_tmp == 'video-v2' || modal_type_tmp == 'videopanel-v2'}
    
      {#each settings["scenes"] as scene}   
        {#if scene.id === current_scene.toLowerCase()}
          {#each scene.hotspots as hotspot}
          {#if hotspot.name == id || 
            hotspot.id == id
            }
            <div class="title">{@html hotspot.data.title}</div>
            <div class="content {modal_type_tmp} videopanel">
              <!-- <VideoPlayer width="auto" height="auto" poster="./local_poster.jpg" source="{settings.configuration.settings.base_path}/assets/{hotspot.data.local_video_url}" loop /> -->
              <div>
                <video controls autoplay>
                  <source src="{userdata["cdn_path"]}images/{hotspot.data.local_video_url}" type="video/mp4">
                </video>
                <!-- <video
                  src="{settings.configuration.settings.base_path}/assets/{hotspot.data.local_video_url}"
                  on:mousemove={handleMove}
                  on:touchmove|preventDefault={handleMove}
                  on:mousedown={handleMousedown}
                  on:mouseup={handleMouseup}
                  bind:currentTime={time}
                  bind:duration
                  bind:paused>
                </video> -->

                
              </div>
            </div>
            {/if}
          {/each}
        {/if}    
      {/each}            
    {/if}
  </div>
{/if}
</div>

<style lang="scss">
    //   variables
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/variables.scss";
    
    #woow-modal{
    #modal-blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(63, 63, 70, 0.2);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      z-index: 1;
    }

    #modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    max-height: 640px;
    max-width: 960px;
    padding: 16px;
    @include blur;
    border-radius: $radius-M;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;

    &.image-only {
        width: calc(100% - 32px);
        height: fit-content;
        max-width: max-content;
        max-height: calc(100% - 32px);
    }

    &.video {
      height: auto;
      max-height: unset;
      max-width: 800px;
    }

    &.relative {
      position: relative;
      transform: unset;
      top: unset;
      left: unset;
      margin: auto;

    }

    &.pano {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }
    
    .title {
      @include rubik-m24;
      padding: 0 32px;
      margin-bottom: 8px;
      text-align: center;

      span {
        font-size : 12px;
        cursor: pointer;

        &.active {
          color: var(--main-color);
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: auto;
      border-radius: $radius-S;
      background-color: var(--primary-color-light005);
      border: 1px solid var(--primary-color-light20);
        &.image-only {
          padding: 0;
        }
        &.text-only {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 32px 64px 0 64px;
          text-align: center;
        }

        .full-image {
             width: 100%;
             margin-bottom: -4px;
             &.image-only {
              width: 100%;
              height: 100%;
              max-height: 550px;
              object-fit: contain;
             }
      }

    }

    .info-v1, .infopanel-v1, .info-v2, .infopanel-v2, .info-v3, .infopanel-v3, .tour-info {
      padding: 32px 64px 0 64px;

      .content-title {
        @include rubik-m16;
        text-align: center;
          &.text-only {
            &::after {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 128px);
            height: 1px;
            background-color: var(--primary-color-light10);
            margin: 15px 0
        }
          }
      }
      .grid-img-left {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
          "image subtitle"
          "image description";

        margin-top: 56px;
        align-items: start;

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: var(--primary-color-light10);
          top: -24px;
        }
      }

      .image {
        grid-area: image;

        img {
          width: calc(100% - 16px);
          border-radius: $radius-S;
        }
      }

      .subtitle {
        @include rubik-m16;
        padding-left: 16px;
        padding-bottom: 12px;
        grid-area: subtitle;
        text-align: left;
        &.text-only {
          padding: 25px 10px 10px 10px;
        }
      }

      .description {
        grid-area: description;
        @include rubik-r14;
        padding-left: 16px;
        justify-self: start;
        text-align: left;
        &.text-only {
          text-align: center;
        }
        p {
          margin-bottom: 21px;
        }
      }
    }

    // ---------- POVODNY POROVNAVAC -------------

    // .flat-compare {
    //   padding: 10px;
    //   @include rubik-r12;
    //   height: calc(100% - 70px);
    //   .compare-header{
    //     display: flex;
    //     width: 100%;
    //     justify-content: center;
    //     padding-left: 16px;
    //     margin-bottom: 16px;

    //     .flats{
    //       display: flex;
    //       align-items: center;
    //       width: 20%;
    //     }

    //     .benefits {
    //       display: flex;
    //       background-color: var(--primary-color);
    //       color: var(--secondary-color);
    //       padding: 10px 16px 10px 0;
    //       width: 80%;
    //       justify-content: space-around;
    //       border-radius: 6px;

    //       p{
    //         width: 20%;
    //         display: flex;
    //         justify-content: center;

    //       }
    //     }
    //   }

    //   .selected-flats{
        
    //     .flat-item{
    //       display: flex;
    //       padding: 8px 16px;
    //       background-color: transparent;
    //       border-radius: 6px;

    //       &:nth-child(even){
    //         background-color: var(--secondary-color-light60);
    //       }

    //       &:hover{
    //         background-color: var(--main-color);
    //         .flat-values{
    //           background-color: var(--secondary-color);
    //         }
    //         .flat-name{
    //           color: var(--secondary-color);
    //         }
    //       }

    //       .flat-name{
    //         width: 20%;
    //         @include rubik-m12;
    //         display: flex;
    //         align-items: center;
    //       }
    //       .flat-values{
    //         display: flex;
    //         width: 80%;
    //         justify-content: space-around;
    //         background-color: transparent;
    //         padding: 10px 0;
    //         border-radius: 6px;

    //         p,div{
    //           width: 20%;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //         }

    //         .price{
    //           @include rubik-m12
    //         }

    //         .state{

    //           &::after{
    //             content: '';
    //             display: block;
    //             background-color: var(--primary-color-light20);
    //             width: 10px;
    //             height: 10px;
    //             border-radius: 50%;
    //           }

    //           &.free {
    //             &::after{
    //               background-color: $success;
    //             }
    //           }
    //           &.reserve {
    //             &::after{
    //               background-color: $warning;
    //             }
    //           }
    //           &.sold {
    //             &::after{
    //               background-color: $danger;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // ---------- NOVY POROVNAVAC -------------

    .state-wrapper {
          display: flex;
          flex-wrap: wrap;
          flex-grow: 10;
          justify-content: end;
          margin-top: 8px;
            span {
              display: flex;
              align-items: center;
              margin-right: 15px;
              font-size: 12px;

                &::before{
                content: '';
                display: block;
                background-color: var(--primary-color-light20);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
              }

              &.free {
                &::before{
                  background-color: $success;
                }
              }
              &.reserve {
                &::before{
                  background-color: $warning;
                }
              }
              &.sold {
                &::before{
                  background-color: $danger;
                }
              }
              &.pre-reserved {
                &::before{
                  background-color: $info;
                }
              }
              &.not-for-sale {
                &::before{
                  background-color: var(--primary-color-light60);
                }
              }
          
            }
        }

    
    .compare-footer{
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 8px;
      flex-wrap: wrap;
    }
    
    .compare-pdf {
      background-color: var(--main-color);
      border-radius: 6px;
      padding: 8px 12px;
      color: var(--secondary-color);
      font-weight: 400;
      cursor: pointer;
      font-size: 12px;
      align-self: center;
      flex-grow: 1;
      text-align: center;
    }

    .flat-compare{
      display: flex;
      padding: 12px;
      gap: 8px;
      @include rubik-r12;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      height: calc(100% - 70px);

      .flat-card{
        width: 100%;
        max-width: 219px;
        background-color: #eee;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 0 10px -7px;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;

        &.description-off {
          height: max-content;

          .flat-info {
            height: max-content;
          }
        }

        .state{
          position: absolute;
          top: 8px;
          left: 8px;
          z-index: 2;
          &::after{
            content: '';
            display: block;
            background-color: var(--primary-color-light20);
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          &.free {
            &::after{
              background-color: $success;
            }
          }
          &.reserve {
            &::after{
              background-color: $warning;
            }
          }
          &.sold {
            &::after{
              background-color: $danger;
            }
          }
          &.pre-reserved {
            &::after{
              background-color: $info;
            }
          }

          &.not-for-sale {
            &::after{
              background-color: var(--primary-color-light60);
            }
          }
        }

        .img-wrapper{
          position: relative;
          max-height: 206px;
          overflow: hidden;
          background: white;
          .floorplan{
            width: 100%;
            height: 206px;
            object-fit: contain;
            opacity: 1;
            transition: all 0.25s ease-in-out;
            padding: 8px;

            &.active{
              opacity: 0.2;
            }
          }
          .menu-wrapper{
          position: absolute;
          background-color: var(--primary-color-light60);
          border-radius: 8px 8px 0 0;
          bottom: -120px;
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          height: max-content;
          flex-direction: column;
          align-items: center;
          padding: 16px 0 16px 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.25s ease-in-out;

          &.active{
            opacity: 1;
            visibility: visible;
            bottom: 0;
          }

          .show-floorplan, .show-tour {
              border-radius: 6px;
              background-color: var(--secondary-color);
              @include rubik-r12;
              padding: 10px 10px;
              margin: 0;
              transition: opacity 0.25s ease-in-out;
              visibility: hidden;
              opacity: 0;
              width: 85%;
              display: flex;
              justify-content: flex-start;
              margin-bottom: 10px;
              cursor: pointer;

              &:hover {
                box-shadow: 0 0 0 2px var(--main-color);
              }
              &::before {
                content: "";
                position: relative;
                margin-right: 8px;
                width: 20px;
                height: 20px;
                background-size: 100%;
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;
                mask-position: center;
                -webkit-mask-position: center;
                mask-size: contain;
                -webkit-mask-size: contain;
                background-color: var(--primary-color);
                display: block;
              }
            }


            .show-floorplan{
              &.active{
                visibility: visible;
                opacity: 1;
              }
            }

            .show-tour{
              &.active {
                visibility: visible;
                opacity: 1;
              }
            }
        }
        }

        .flat-card-title{
            background-color: var(--main-color);
            color: var(--secondary-color);
            display: inline-block;
            padding: 5px 5px;
            border-radius: 50px;
            width: max-content;
            align-self: center;
            margin-top: 16px;
            @include rubik-m14;
            display: flex;
            align-items: center;
            position: absolute;
            cursor: pointer;
            top: 172px;
            z-index: 2;

            .flat-name{
              margin-right: 8px;
              padding-left: 4px;
            }

            .submenu{
              background-color: var(--secondary-color);
              color: var(--main-color);
              border-radius: 50%;
              font-size: 11px;
              padding: 4px 8px;
              cursor: pointer;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

        
        .flat-info{
          padding: 0 10px;
          height: calc(100% - 290px);
          overflow: auto;
          margin-top: 30px;

          .global{
            margin-bottom: 20px;
            .global-row{
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid var(--primary-color-light10);
             
            }
          }
          .room{
            padding: 0 20px;
            margin-bottom: 20px;
            .room-row{
              display: flex;
              justify-content: space-between;
            }
          }
          .area{
            .area-row{
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 10px;
              @include rubik-m12;
            }
          }
        }
        .price{
          position: absolute;
          background-color: var(--primary-color);
          color: var(--secondary-color);
          @include rubik-m14;
          bottom: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 12px;
        }
      }
    }

    .videopanel {
      
      div {
        position: relative;
        aspect-ratio: 16 / 9;
        video {
          width: 100%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .google-map, .google-map2 {
      overflow: hidden;
      iframe {
        width: 100%;
        height: 100%;
        &.filter {
          filter: grayscale(1);
        }
      }

    }

    .model-3d {
      position: relative;
      // display: none;
      .navigation {
        @include blur;
        display: flex;
        width: max-content;
        height: 40px;
        border-radius: $radius-S;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        padding: 0 18px;
          .nav-item:not(:last-child){
            margin-right: 25px;
          }

          .nav-item {
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-color: var(--primary-color);
            mask-repeat: no-repeat;
            &.left,
            &.up,
            &.down,
            &.right {
              -webkit-mask-image: url("../assets/icons/submenu.svg");
              mask-image: url("../assets/icons/submenu.svg");
              mask-position: center;
            }
            &.left{
              transform: rotate(180deg);
            }
            &.up{
              transform: rotate(270deg);
            }
            &.down{
              transform: rotate(90deg);
            }
            &.zoomin{
              -webkit-mask-image: url("../assets/icons/zoom-in.svg");
              mask-image: url("../assets/icons/zoom-in.svg");
            }
            &.zoomout{
              -webkit-mask-image: url("../assets/icons/zoom-out.svg");
              mask-image: url("../assets/icons/zoom-out.svg");
            }
          }
          .separator {
            width: 1px;
            height: 32px;
            background-color: var(--primary-color-light20);
          }
      }
    }
  }

  .view-button {
    background: #fff;
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.8);
    display: block;
    font-family: Futura, Helvetica Neue, sans-serif;
    font-size: 12px;
    font-weight: 700;
    max-width: 128px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
  }

      /* This keeps child nodes hidden while the element loads */
      :not(:defined) > * {
      display: none;
    }
  
    model-viewer {
      background-color: #eee;
      overflow-x: hidden;
    }
  
    #ar-button {
      background-image: url(../../assets/ic_view_in_ar_new_googblue_48dp.png);
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 12px 50%;
      background-color: #fff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      bottom: 132px;
      padding: 0px 16px 0px 40px;
      font-family: Roboto Regular, Helvetica Neue, sans-serif;
      font-size: 14px;
      color:var(--main-color);
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      border: 1px solid #DADCE0;
    }
  
    #ar-button:active {
      background-color: #E8EAED;
    }
  
    #ar-button:focus {
      outline: none;
    }
  
    #ar-button:focus-visible {
      outline: 1px solid var(--main-color);
    }
  
    @keyframes circle {
      from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
      to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
    }
  
    @keyframes elongate {
      from { transform: translateX(100px); }
      to   { transform: translateX(-100px); }
    }
  
    model-viewer > #ar-prompt {
      position: absolute;
      left: 50%;
      bottom: 175px;
      animation: elongate 2s infinite ease-in-out alternate;
      display: none;
    }
  
    model-viewer[ar-status="session-started"] > #ar-prompt {
      display: block;
    }
  
    model-viewer > #ar-prompt > img {
      animation: circle 4s linear infinite;
    }
  
    model-viewer > #ar-failure {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 175px;
      display: none;
    }
  
    model-viewer[ar-tracking="not-tracking"] > #ar-failure {
      display: block;
    }

    #show-dimensions {
      position: absolute;
      top:0;
      left: 16px;
    }

    .label-dimensions {
      position: absolute;
      top: 10px;
      left: 35px;
    }
  
    .slider {
      width: auto;
      text-align: center;
      overflow: hidden;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }

  
    .slides {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  
    .slide {
      scroll-snap-align: start;
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      margin-right: 10px;
      border-radius: 10px;
      border: none;
      display: flex;
      margin: 0px;

    }
  
    .slide.selected {
      border: 2px solid var(--main-color);
    }
  
    .slide:focus {
      outline: none;
    }
  
    .slide:focus-visible {
      outline: 1px solid var(--main-color);
    }


    .dot{
      display: none;
    }

    .dim{
      background: #fff;
      border-radius: 4px;
      border: none;
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      color: rgba(0, 0, 0, 0.8);
      display: block;
      font-family: Futura, Helvetica Neue, sans-serif;
      font-size: 1em;
      font-weight: 700;
      max-width: 128px;
      overflow-wrap: break-word;
      padding: 0.5em 1em;
      position: absolute;
      width: max-content;
      height: max-content;
      transform: translate3d(-50%, -50%, 0);
      pointer-events: none;
      --min-hotspot-opacity: 0;
    }

    @media only screen and (max-width: 800px) {
      .dim{
        font-size: 3vw;
      }
    }

    .dimensionLineContainer{
      pointer-events: none;
      display: block;
    }

    .dimensionLine{
      stroke: var(--main-color);
      stroke-width: 2;
      stroke-dasharray: 2;
    }

    .hide{
      display: none;
    }
    /* This keeps child nodes hidden while the element loads */
    :not(:defined) > * {
      display: none;
    }

  #shoe {
    .controls{
      position: absolute;
      bottom: 0;
      left: 8px;
    }
  }


  // ------------------- SCREEN --------------------------------------------------------------------------------

  @media (max-width: 700px) {
    #modal {
      .infopanel, .tour-info {
        padding: 16px;
        .grid-img-left {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr;
          grid-template-areas:
            "subtitle"
            "image"
            "description";

          .subtitle {
            text-align: center;
          }

          .description {
            text-align: justify;
            padding: 0;
          }

          .image {
            margin-bottom: 16px;
            img {
              width: 100%;
            }
          }
        }
      }
      .info-v1, .infopanel-v1, .info-v2, .infopanel-v2, .info-v3, .infopanel-v3, .tour-info {
        padding: 16px;
        .grid-img-left {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr;
          grid-template-areas:
            "subtitle"
            "image"
            "description";

          .subtitle {
            text-align: center;
          }
          .description {
            text-align: justify;
            padding: 0;
          }
          .image {
            margin-bottom: 16px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }


  .hotspot{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: blue;
    box-sizing: border-box;
    pointer-events: none;
  }

  .hotspot[slot="hotspot-hand"]{
    --min-hotspot-opacity: 0;
    background-color: red;
  }

  .hotspot[slot="hotspot-foot"]:not([data-visible]) {
    background-color: transparent;
    border: 3px solid blue;
  }

  .annotation{
    background-color: #888888;
    position: absolute;
    transform: translate(10px, 10px);
    border-radius: 10px;
    padding: 10px;
  }
  /* This keeps child nodes hidden while the element loads */
  :not(:defined) > * {
    display: none;
  }

  .hidden {
    display: none !important;
  }

  .views {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    span {
      cursor: pointer;
    }
  }

  #color-controls {
    position: absolute;
    top: 34px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    z-index: 9999;

    div {
      cursor: pointer
    }
  }
}
</style>