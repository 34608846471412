<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";

    console.log("mala");  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
  };

    let tour_info_settings = [];
    let tour_info_data_loaded = false;
    
  
      // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") || (key == "enable_facebook" && json[0][key] == "0") || (key == "enable_twitter" && json[0][key] == "0") || (key == "enable_linkedin" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
            (key == "enabled" && json[0][key] == "1") || (key == "enable_facebook" && json[0][key] == "1") || (key == "enable_twitter" && json[0][key] == "1") || (key == "enable_linkedin" && json[0][key] == "1")
          ) {
              json[0][key] = true
          }
          
        }

        tour_info_settings = json[0];
        
        tour_info_data_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("tour_info");

    async function save_settings() {
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(tour_info_settings));
      const res = await fetch(
       
        userdata['cdn_path'] + "php/admin/updateTourinfo.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia Share boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia Share neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload loga na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        formData.append("parameters", JSON.stringify(parameters));
        
        
        const res = await fetch(userdata['cdn_path'] + 'php/admin/saveImg.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Logo bolo úspešne uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  

                
                if (json.logo_url) {
                  tour_info_settings.img = json.logo_url;
                
                    save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Logo nebolo uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

    
    // zapnutie / vypnutie modalu
  import { modal } from '../../store.js';

let show_modal;
modal.subscribe(value => {
  show_modal = value;
});

let Modal;
let modal_type = 'tour-info';

import { onMount } from 'svelte';

const sleep = ms => new Promise(f => setTimeout(f, ms));

onMount(async () => {
  //await sleep(1000); // simulate network delay
  Modal = (await import('../../tour_components/Modal.svelte')).default
});

function save_global_info(){

}
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if tour_info_data_loaded == true}

    <main in:fade>
      <h1>{$t("components")['tourinfo']['admin-title']}</h1>

      <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{$t("components")['tourinfo']['title']}" settings="{tour_info_settings}"  {tour_info_settings} {userdata} position="relative"/>  

      <!-- <div class="component">
        <label for="scenes">{$t("components")['tourinfo']['all']}</label>
        <select bind:value="{settings.components[8].admin_info}">
            <option value="global">{$t("components")['tourinfo']["global"]}</option>
          {#each settings.scenes as scene} 
            <option value="{scene.id}">{scene.id}</option>
          {/each}
            
        </select>
    </div>   
      <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{$t("components")['tourinfo']['title']}" settings="{settings}" position="relative" global="yes"/>   -->
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>

      <!-- Title tour info -->
      <div class="component">
        <label for="title">{$t("components")['tourinfo']['title']}</label>
        <input type="text" name="title" bind:value="{tour_info_settings.title}">
        <!-- <input type="text" name="title" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].title}"> -->
      </div>  

      <!-- Title tour headline -->
      <div class="component">
        <label for="headline">{$t("components")['tourinfo']['headline']}</label>
        <textarea name="headline" bind:value="{tour_info_settings.headline}"/>
        <!-- <textarea name="headline" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].headline}"/> -->
      </div>  

      <!-- upload obrázka -->
      <div class="component upload">
        <label for="fileupload">{$t("components")['tourinfo']['image']}</label><br>
        <input id="fileupload" type="file" name="fileupload" />
        <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
      </div>

      <!-- Title tour subtitle -->
      <div class="component">
        <label for="subtitle">{$t("components")['tourinfo']['subtitle']}</label>
        <input type="text" name="subtitle" bind:value="{tour_info_settings.subtitle}"/>
        <!-- <input type="text" name="subtitle" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].subtitle}"/> -->
      </div>  

      <!-- Title tour subtitle -->
      <div class="component">
        <label for="subtitle">{$t("components")['tourinfo']['content']}</label>
        <textarea name="content" bind:value="{tour_info_settings.content}"/>
        <!-- <textarea name="content" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].content}"/> -->
      </div>        


    </main>
    <Header />
    
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>