<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";

  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";

  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  export let userdata;
  let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
  };

  let result;
  let test = [];
  let sample_flats = [];
  let enable_design = false;
  let enable_floors = false;
  let enable_smooth_transition = false;

  let loading_sample_flats = true;
  let enable_component_settings = false;
  let flat_id = null;
  let new_date;

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    new_date = yyyy + "-" + mm + "-" + dd;
  }
  getDate();

  function setSampleFlatComponents($id) {
    flat_id = $id; 
    enable_component_settings = true;
  }

  async function fetchData() {
    const res = await fetch(userdata['cdn_path'] + '/php/admin/getConfiguratorSampleFlats.php',{
      method: "POST", 
      body: JSON.stringify(parameters),
    }); 

    if (res.ok) {
      const json = await res.json();
      //const json = await res.text();
      console.log(json);
      json.forEach(element => {
        const keys = Object.keys(element);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key.includes("component_") && element[key] == "0")
          ) {
              element[key] = false
          }

          if (
            (key.includes("component_") && element[key] == "1")
          ) {
              element[key] = true
          } 
        }  
      });
      
      sample_flats = json;
      result = JSON.stringify(json);
      loading_sample_flats = false;

      return json;
    } else {
      throw new Error(data);
    }
  }
  fetchData();

  async function updateSampleFlat($id) {
    console.log(sample_flats[$id]);
    if (sample_flats[$id] == "") {
      addNotification({
        text: "nebol zvolený byt.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      let formData = new FormData();           
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("sample_flat", JSON.stringify(sample_flats[$id]));
      const res = await fetch(userdata['cdn_path'] + '/php/admin/updateConfiguratorSampleFlat.php', {
          method: "POST", 
          body: formData
        });

      if (res.ok) {
        const json = await res.json();
        result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
            text: "Vzorový byt bol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          fetchData();
        } else {
          addNotification({
            text: "Chyba : Vzorový byt nebol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        addNotification({
          text: "Chyba : Vzorový byt nebol aktualizovaný!",
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
        throw new Error(data);
      }
    }
  }
  
  async function saveSampleFlatcomponents($id, $value) {
    let data;
    sample_flats.forEach(element => {
        if (element.id == $id) {
          
          data = element;
          data.copy_to_all = $value;
        }
    });
    console.log(data);

    let formData = new FormData();           
    formData.append("parameters", JSON.stringify(parameters));
    formData.append("data", JSON.stringify(data));

    const res = await fetch(userdata['cdn_path'] + '/php/admin/saveSampleFlatComponents.php', {
      method: "POST", 
      body: formData
    });

    if (res.ok) {
      const json = await res.text();
      console.log(json);
      addNotification({
        text: "Nastavenia komponentov boli uložené!",
        position: "top-center",
        removeAfter: 4000,
        type: "success",
      }); 

      enable_component_settings = false;
      
      fetchData();
      return json;
    } else {
      throw new Error(data);
    } 
  }
</script>

<div id="wrapper">
  <Aside />

  <main in:fade class="configurator">
    <h1>{$t("admin")["aside"]["projects"]["projects"]}</h1>
    
      {#if loading_sample_flats == false}
      <table>
        <thead>
          <tr>
            <td>{$t("admin")["global"]["id"]}</td>
            <td>{$t("admin")["global"]["name"]}</td>
            <td>{$t("admin")["projects"]["design_change"]}</td>
            <td>Dizajn hodnota</td>
            <td>{$t("admin")["projects"]["floor_change"]}</td>
            <td>Floor hodnota</td>
            <td>Default LANG</td>
            <td>3D prechod?</td>
            <td>Floorspot Skin</td>
            <td>Global RX</td>
            <td>Load view</td>
            <td>Komponenty</td>
            <td>{$t("components")["sample_flats"]["save"]}</td>
          </tr>
        </thead>
        <tbody>
          {#each sample_flats as flat, id}
            <tr>
              <td><p class="name">{flat.id}</p></td>
              <td><p class="name">{flat.name}</p></td>
              <td>
                <label
                  >{$t("components")["configurator"]["yes"]}
                  <input
                    id="true"
                    type="radio"
                    bind:group={flat.enable_design}
                    value="1"
                  />
                </label>
                <label
                  >{$t("components")["configurator"]["no"]}
                  <input
                    id="false"
                    type="radio"
                    bind:group={flat.enable_design}
                    value="0"
                  />
                </label> 
              </td>
              <td><input type="text" bind:value={flat.default_dizajn}></td>

              <td>
                <label
                  >{$t("components")["configurator"]["yes"]}
                  <input
                    id="true"
                    type="radio"
                    bind:group={flat.enable_floors}
                    value="1"
                  />
                </label>
                <label
                  >{$t("components")["configurator"]["no"]}
                  <input
                    id="false"
                    type="radio"
                    bind:group={flat.enable_floors}
                    value="0"
                  />
                </label> 
              </td>
              <td><input type="text" bind:value={flat.default_floor}></td>
              <td><input type="text" bind:value={flat.default_lang}></td>
              
              <td>
                <label
                  >{$t("components")["configurator"]["yes"]}
                  <input
                    id="true"
                    type="radio"
                    bind:group={flat.enable_smooth_transition}
                    value="1"
                  />
                </label>
                <label
                  >{$t("components")["configurator"]["no"]}
                  <input
                    id="false"
                    type="radio"
                    bind:group={flat.enable_smooth_transition}
                    value="0"
                  />
                </label> 
              </td>
              <td>
                <select bind:value={flat.global_floorspot_skin}>
                  <option value="none">
                    ---
                  </option>  
                  <option value="floorspot">
                      Floorspot
                    </option>
                    <option value="arrow">
                      Arrow
                    </option>
                    <option value="g-arrow">
                      G-Arrow
                    </option>
                </select>
                </td>
              <td><input type="text" bind:value={flat.global_rx}></td>
              <td>
                <select bind:value={flat.load_view}>
                  <option value="none">
                    ---
                  </option>  
                  <option value="keepview">
                      KEEPVIEW
                    </option>
                    <option value="defaultview">
                      DEFAULTVIEW
                    </option>
                </select>
                </td>
              <td on:click={() => setSampleFlatComponents(flat.id)}><i class="fa-solid fa-gear edit" /></td>
              <td on:click={() => updateSampleFlat(id)}><i class="fa-solid fa-floppy-disk save" /></td>
            </tr>
          {/each}
        </tbody>
      </table>
      {:else}
      <div id="preloader">
        <div class="preloader">
        </div>
      </div>
      {/if}
    {#if enable_component_settings}
      <div class="empty-layer"></div>

      <div class="components-modal">
        <div class="close" on:click={() => enable_component_settings = false}></div>
        
        
        {#each sample_flats as flat, id}
          {#if flat.id == flat_id}
          <h1>Komponenty pre byt<br /><span>{flat.name}<span></h1>
          <div class="buttons">
            <button class="save" on:click={() => saveSampleFlatcomponents(flat.id, false)}>Uložiť</button>
            <button class="all" on:click={() => saveSampleFlatcomponents(flat.id, true)}>Použiť pre všetky byty</button>
          </div>

            <!-- Shortcuts -->
            <div class="component item">
              <label>Shortcuts</label>
              <input type="checkbox" bind:checked={flat.component_shortcuts}>
            </div> 

            <!-- Logo -->
            <div class="component item">
              <label>Logo</label>
              <input type="checkbox" bind:checked={flat.component_logo}>
            </div> 

            <!-- Fotenie -->
            <div class="component item">
              <label>Screenshots</label>
              <input type="checkbox" bind:checked={flat.component_screenshots}>
            </div> 
             <!-- Filter bytov -->
             <div class="component item">
              <label>flat filter</label>
              <input type="checkbox" bind:checked={flat.component_flat_filter}>
            </div> 
         
            <!-- Attributes -->
            <div class="component item">
              <label>Attributes</label>
              <input type="checkbox" bind:checked={flat.component_attributes}>
            </div>             

            <!-- Košík -->
            <div class="component item">
              <label>Basket</label>
              <input type="checkbox" bind:checked={flat.component_basket}>
            </div> 

            <!-- Vytváranie PDF -->
            <div class="component item">
              <label>PDF</label>
              <input type="checkbox" bind:checked={flat.component_pdf}>
            </div>             

            <!-- Jazykové mutácie -->
            <div class="component item">
              <label>Languages</label>
              <input type="checkbox" bind:checked={flat.component_languages}>
            </div> 

            <!-- Prihlasovanie do tour -->
            <div class="component item">
              <label>Login</label>
              <input type="checkbox" bind:checked={flat.component_login}>
            </div> 

            <!-- Sidebar ikona -->
            <div class="component item">
              <label>Sidebar</label>
              <input type="checkbox" bind:checked={flat.component_sidebar}>
            </div> 

            <!-- Floorplan -->
            <div class="component item">
              <label>Floorplan</label>
              <input type="checkbox" bind:checked={flat.component_floorplan}>
            </div>  

            <!-- Layers -->
            <div class="component item">
              <label>Layers</label>
              <input type="checkbox" bind:checked={flat.component_layers}>
            </div>  

            <!-- 3D model -->
            <div class="component item">
              <label>3D model</label>
              <input type="checkbox" bind:checked={flat.component_3d_model}>
            </div>    
            
            <!--Social -->
            <div class="component item">
              <label>Social</label>
              <input type="checkbox" bind:checked={flat.component_social}>
            </div>             

            <!-- Google map -->
            <div class="component item">
              <label>Google map</label>
              <input type="checkbox" bind:checked={flat.component_google_map}>
            </div>   

            <!-- Kontaktný formulár -->
            <div class="component item">
              <label>Contact form</label>
              <input type="checkbox" bind:checked={flat.component_contact_form}>
            </div>     
            
            <!-- Tour info -->
            <div class="component item">
              <label>Tour info</label>
              <input type="checkbox" bind:checked={flat.component_tour_info}>
            </div>     
            
            
            <!-- Book call -->
            <div class="component item">
              <label>Book call</label>
              <input type="checkbox" bind:checked={flat.component_bookcall}>
            </div> 

            <!-- Prihlasovanie do tour -->
            <div class="component item">
              <label>Measures</label>
              <input type="checkbox" bind:checked={flat.component_measures}>
            </div> 

            <!--Otáčadlo -->
            <div class="component item">
              <label>Otáčadlo</label>
              <input type="checkbox" bind:checked={flat.component_otacadlo}>
            </div> 

            <!--Otáčadlo -->
            <div class="component item">
              <label>Otáčadlo IMG</label>
              <input type="checkbox" bind:checked={flat.component_otacadlo_img}>
            </div> 

            <!-- Nastavenia v sidebare -->
            <div class="component item">
              <label>Settings</label>
              <input type="checkbox" bind:checked={flat.component_settings}>
            </div> 

            <!-- Režim VR -->
            <div class="component item">
              <label>VR</label>
              <input type="checkbox" bind:checked={flat.component_vr}>
            </div> 

            <!--Zvuky -->
            <div class="component item">
              <label>Sounds</label>
              <input type="checkbox" bind:checked={flat.component_sound}>
            </div> 
            <!--Tutorial -->
            <div class="component item">
              <label>Tutorial</label>
              <input type="checkbox" bind:checked={flat.component_tutorial}>
            </div> 

            <!--Dispozície -->
            <div class="component item">
              <label>Dispozície</label>
              <input type="checkbox" bind:checked={flat.component_dispositions}>
            </div> 
          {/if}
          
        {/each}
        
      </div>
    {/if}
    
    
  </main>
  <Header />
</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";

  .empty-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: transparent;
  }

  .buttons {
    display: flex;
    gap: 16px;
    button {
      &.all {
        background-color: $info;
      }
    }
  }

  .components-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: var(--secondary-color);
    border-radius: 12px;
    padding: 40px;
    box-shadow: 0 0 10px -5px black;
    display: flex;
    flex-direction: column;
    z-index: 500;
    align-items: center;
    width: calc(100% - 64px);
    height: auto;
    max-width: 450px;
    max-height: calc(100% - 64px);
    overflow: auto;
    z-index: 21;

    h1 {
      margin: 0;
      font-weight: 300;

      span {
        color: var(--primary-color);
        font-weight: 500;
      }
    }
    
    .component {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--primary-color-light20);
      padding: 10px;

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      &:hover {
        background-color: lighten($border-color, 9);
      }
    }
  }

  main {
    &.configurator {
      h1 {
        color: $text-color;
      }

      .all-users {
        margin: 0 auto;
        width: calc(100% - 64px);
        max-width: 1300px;

        .add-user {
          display: flex;
          background-color: $success;
          width: max-content;
          color: white;
          padding: 15px 20px;
          border-radius: 6px;
          margin: 20px auto;
          cursor: pointer;
          &:hover {
            background-color: darken($success, 10);
          }
          i {
            font-size: 16px;
          }
          p {
            margin-left: 10px;
          }
        }

        .user {
          display: flex;
          justify-content: space-between;
          padding: 25px 10px;
          border-top: 1px solid lighten($border-color, 7);
          .left {
            display: flex;
            align-items: center;
            .user-icon {
              width: 40px;
              height: 40px;
              background-color: $text-color;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              color: white;
            }
            .info {
              text-align: left;
              margin: 0 15px;
              .name {
                font-weight: 400;
              }
              .user-name {
                color: $text-color;
              }
            }
          }
          .right {
            display: flex;
            .role {
              display: flex;
              background-color: white;
              padding: 10px 20px;
              border: 1px solid $border-color;
              border-radius: 6px;
              span {
                margin-right: 3px;
              }
            }
            .edit,
            .delete {
              width: 40px;
              height: 40px;
              // background-color: tomato;
              margin-left: 10px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              color: $text-color;
              cursor: pointer;
            }
            .edit {
              &:hover {
                background-color: $info;
                color: white;
              }
            }
            .delete {
              &:hover {
                background-color: $danger;
                color: white;
              }
            }
          }
        }
      }
      table {
        thead {
          background: $text-color;
          inset-block-start: 0;
          color: $text-color-active;
        }
        td {
          padding: 10px;

          input {
            margin: 0;
            padding: 8px;
          }

          select {
            min-width: 60px;
          }
        }
      }
      table,
      td,
      th {
        border: 0px solid $text-color;
      }
      tbody {
        tr {
          &:nth-child(even) {
            background-color: lighten($text-color, 26);
          }
          &:nth-child(odd) {
            background-color: lighten($text-color, 29);
          }
          &:hover {
            background-color: lighten($text-color, 23);
          }
        }
        .enable-td {
          display: flex;
          gap: 10px;

          label {
            display: flex;
            flex-direction: column;
          }
        }
        .save,
        .delete {
          width: 35px;
          height: 35px;
          // background-color: tomato;
          margin-left: 10px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          color: $text-color;
          cursor: pointer;
        }
        .save {
          &:hover {
            background-color: $success;
            color: white;
          }
        }
        .delete {
          &:hover {
            background-color: $danger;
            color: white;
          }
        }
      }
    }
    .set_date {
      width: 14%;
      text-align: left;
      background-color: lighten($text-color, 26);
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 15px;

      label {
        font-weight: 500;
        color: $info;
      }

      button {
        margin: 0;
        background-color: $info;
        &:hover {
          background-color: darken($info, 8);
        }
      }
    }
  }
</style>
