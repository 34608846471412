<script>
    
    import { blur, loaded_components, screenshot, vr_mode, flat_filter_enabled } from '../store.js';
    export let userdata, logo_settings, _dispositions_enabled;
    let tmp = userdata;
    let _vrMode;
    let _screenshot, blur_enabled, _flat_filter_enabled = false;
    
    $loaded_components = [...$loaded_components, 'logo'];

    vr_mode.subscribe(value => {
        _vrMode = value;
    });

    blur.subscribe(value => {
        blur_enabled = value;
    });
    
    screenshot.subscribe(value => {
        _screenshot = value;
    });

    
    flat_filter_enabled.subscribe(value => {
        _flat_filter_enabled = value;
    });

    $: outerWidth = 0
	$: innerWidth = 0
	$: outerHeight = 0
	$: innerHeight = 0
    // Načítanie dát z DB
</script>
<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />

<div id="woow-logo">
{#if _screenshot == false && _vrMode == false && !_dispositions_enabled}
     {#if (!_flat_filter_enabled) || (_flat_filter_enabled && innerWidth > 962)}
        <div id="logo" class="logo" class:hide_blur="{blur_enabled === true}">
            {#if logo_settings != undefined}
                {#if logo_settings.url != ''}
                    <a href="{logo_settings.url}" target="{logo_settings.target}">
                        <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}{logo_settings.logo_url}" alt="logo">
                    </a>
                {:else}
                    <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}{logo_settings.logo_url}" alt="logo">
                {/if}
            {/if}
        </div>
     {/if}
{/if}

</div>

<style lang="scss">
    //   variables
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/themes.scss";

    #woow-logo {
    $logo-width: 140px;
    $logo-height: 40px;
    
    #logo {
        position: absolute;
        top: 16px;
        left: 50%;
        width: $logo-width;
        height: $logo-height;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        @include blur;
        cursor: pointer;
        z-index: 10;
        background-color: var(--secondary-color);

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: auto;
            height: auto;
            max-width: calc(#{$logo-width} - 16px);
            max-height: calc(#{$logo-height} - 8px);
        }
    }
}
</style>