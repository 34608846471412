<script>
    
  import {dispositions_enabled, blur, mobile_patch_suffix, nazov_bytu, vr_mode, changed_flat} from "../store.js";
  import { fade, scale } from 'svelte/transition';
  import Panzoom from "@panzoom/panzoom";
  import { t, locale, locales } from "../i18n.js";
  let disposition_mode = "select";
  let zoomable, container;
  let style = ""; // show styling applied
  let _scale = { value: 1 };

  export let userdata, sample_flat_name, _enable_otacadlo;

    let _vrMode;
    vr_mode.subscribe(value => {
        _vrMode = value;
    });

  let _mobile_patch_suffix = "";

    mobile_patch_suffix.subscribe(value => {
        _mobile_patch_suffix = value;
    });


  function waitForElm(selector) {
        return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        });
    }




  let _dispositions_enabled = false;
  dispositions_enabled.subscribe((value) => {
    _dispositions_enabled = value;
    //console.log(value);

    if (value) {
        if (disposition_mode == "detail") {
            waitForElm("#my-zoomist").then((elm) => {
                const elem = document.getElementById("my-zoomist");
                const panzoom = Panzoom(elem, {
                maxScale: 5,
                });

                panzoom.zoom(1), { animate: true };
                panzoom.pan(0, 0), { animate: true };
                // panzoom.pan(10, 10)
                // panzoom.zoom(2, { animate: true });

                // elem.addEventListener('panzoomchange', (event) => {
                //   //console.log(event.detail) // => { x: 0, y: 0, scale: 1 }
                // })

                elem.parentElement.addEventListener("wheel", panzoom.zoomWithWheel);
                moveto = function ($x, $y) {
                
                panzoom.zoom(1, { animate: true });
                panzoom.pan($x, $y, { animate: true });
                };

               
                
            });
        }
    }
    blur.update(n => value);
  });

  let current_flat = "B3H";
    
    let moveto;

    $: {
        disposition_mode;

        if (disposition_mode == "detail") {
            waitForElm("#my-zoomist").then((elm) => {
                const elem = document.getElementById("my-zoomist");
                const panzoom = Panzoom(elem, {
                maxScale: 5,
                });

                panzoom.zoom(1), { animate: true };
                panzoom.pan(0, 0), { animate: true };
                // panzoom.pan(10, 10)
                // panzoom.zoom(2, { animate: true });

                // elem.addEventListener('panzoomchange', (event) => {
                //   //console.log(event.detail) // => { x: 0, y: 0, scale: 1 }
                // })

                elem.parentElement.addEventListener("wheel", panzoom.zoomWithWheel);
                moveto = function ($x, $y) {
                
                panzoom.zoom(1, { animate: true });
                panzoom.pan($x, $y, { animate: true });
                };

               
                
            });
        }
    }

    let jazyk = null;

    locale.subscribe((value) => {
        jazyk = value;
    });

    
    const dispositions = [
        {
            parent : "B3H",
            last_visited : null,
            children : [
                {
                    id : "B3HA",
                    name : "B3H-A",
                    advantages : {
                        sk : [
                            {
                                text : "Sprchový kút v kúpeľni"
                            },
                            {
                                text : "Priestranná dispozícia"
                            },
                            {
                                text : "Dostatok úložného priestoru"
                            },
                            {
                                text : "Šatník v spálni"
                            }
                        ],
                        de : [
                            {
                                text : "Dusche im Badezimmer"
                            },
                            {
                                text : "Geräumige Aufteilung"
                            },
                            {
                                text : "Viel Stauraum"
                            },
                            {
                                text : "Kleiderschrank im Schlafzimmer"
                            }
                        ],
                        en : [
                            {
                                text : "Shower in the bathroom"
                            },
                            {
                                text : "Spacious disposition"
                            },
                            {
                                text : "Plenty of storage space"
                            },
                            {
                                text : "Wardrobe in the bedroom"
                            }
                        ],
                        cz : [
                            {
                                text : "Sprchový kout v koupelně"
                            },
                            {
                                text : "Prostorná dispozice"
                            },
                            {
                                text : "Dostatek úložného prostoru"
                            },
                            {
                                text : "Šatník v ložnici"
                            }
                        ]
                    },
                    content : {
                        sk : "Priestranné lodžie<br/>Maximálne traja susedia na poschodí<br/>Kobka ku každému bytu<br/>Bezpečnostné 5-bodové dvere<br/>Nízke náklady na energie<br/>Predpríprava klimatizácie<br/>Predpríprava na exteriérové žaluzie na oknách južnej, východnej a západnej fasády",
                        de : "Geräumige Loggien<br/>Maximal drei Nachbarn pro Etage<br/>Verlies für jede Wohnung<br/>Sicherheitstür mit 5 Punkten<br/>Geringe Energiekosten<br/>Vorkonditionierung für Klimaanlage<br/>Vor -Vorbereitung für Außenjalousien an den Fenstern der Süd-, Ost- und Westfassade",
                        en : "Spacious loggias<br/>Maximum three neighbors per floor<br/>Dungeon for each apartment<br/>Safety 5-point door<br/>Low energy costs<br/>Pre-conditioning for air conditioning<br/>Pre-preparation for exterior blinds on the windows of the south, east and west facades",
            cz : "Prostorné lodžie<br/>Maximálně tři sousedé v patře<br/>Kobka ke každému bytu<br/>Bezpečnostní 5-bodové dveře<br/>Nízké náklady na energie<br/>Předpříprava klimatizace<br/>Předpříprava na exteriérové žaluzie na oknech jižní, východní a západní fasády",
                    },
                    image : "assets/dispositions/B3HA.jpg",
                    image :  "assets/dispositions/B3HA.jpg",
                    price : 0
                },
                {
                    id : "B3HB",
                    name : "B3H-B",
                    advantages : {
                        sk : [
                            {
                                text : "Vaňa v kúpeľni"
                            },
                            {
                                text : "Práčka vo vstupnej chodbe"
                            },
                            {
                                text : "Dostatok úložného priestoru"
                            },
                            {
                                text : "Šatník v spálni"
                            },
                            {
                                text : "Oddelené WC"
                            },
                        ],
                        de : [
                            {
                                text : "Badewanne im Badezimmer"
                            },
                            {
                                text : "Waschmaschine im Eingangsbereich"
                            },
                            {
                                text : "Viel Stauraum"
                            },
                            {
                                text : "Kleiderschrank im Schlafzimmer"
                            },
                            {
                                text : "Separates WC"
                            },
                        ],
                        en : [
                            {
                                text : "Bathtub in the bathroom"
                            },
                            {
                                text : "Washing machine in the entrance area"
                            },
                            {
                                text : "Plenty of storage space"
                            },
                            {
                                text : "Wardrobe in the bedroom"
                            },
                            {
                                text : "Separate toilet"
                            },
                        ],
                        cz : [
                            {
                                text : "Vana v koupelně"
                            },
                            {
                                text : "Pračka ve vstupní chodbě"
                            },
                            {
                                text : "Dostatek úložného prostoru"
                            },
                            {
                                text : "Šatník v ložnici"
                            },
                            {
                                text : "Oddělené WC"
                            },
                        ]
                    },
                    content : {
                        sk : "Priestranné lodžie<br/>Maximálne traja susedia na poschodí<br/>Kobka ku každému bytu<br/>Bezpečnostné 5-bodové dvere<br/>Nízke náklady na energie<br/>Predpríprava klimatizácie<br/>Predpríprava na exteriérové žaluzie na oknách južnej, východnej a západnej fasády",
                        de : "Geräumige Loggien<br/>Maximal drei Nachbarn pro Etage<br/>Verlies für jede Wohnung<br/>Sicherheitstür mit 5 Punkten<br/>Geringe Energiekosten<br/>Vorkonditionierung für Klimaanlage<br/>Vor -Vorbereitung für Außenjalousien an den Fenstern der Süd-, Ost- und Westfassade",
                        en : "Spacious loggias<br/>Maximum three neighbors per floor<br/>Dungeon for each apartment<br/>Safety 5-point door<br/>Low energy costs<br/>Pre-conditioning for air conditioning<br/>Pre-preparation for exterior blinds on the windows of the south, east and west facades",
                        cz : "Prostorné lodžie<br/>Maximálně tři sousedé v patře<br/>Kobka ke každému bytu<br/>Bezpečnostní 5-bodové dveře<br/>Nízké náklady na energie<br/>Předpříprava klimatizace<br/>Předpříprava na exteriérové žaluzie na oknech jižní, východní a západní fasády",
                    },
                    image : "assets/dispositions/B3HB.jpg",
                    image :  "assets/dispositions/B3HB.jpg",
                    price : 0
                },
                {
                    id : "B3HC",
                    name : "B3H-C",
                    advantages : {
                        sk : [
                            {
                                text : "Samostatný šatník"
                            },
                            {
                                text : "Integrovaný Home-office"
                            },
                            {
                                text : "Priestranná kuchyňa s barom"
                            },
                            {
                                text : "Ideálna dispozícia pre manažéra"
                            }
                        ],
                        de : [
                            {
                                text : "Separater Kleiderschrank"
                            },
                            {
                                text : "Integriertes Home-Office"
                            },
                            {
                                text : "Geräumige Küche mit Bar"
                            },
                            {
                                text : "Ideale Veranlagung für einen Manager"
                            }
                        ],
                        en : [
                            {
                                text : "Separate wardrobe"
                            },
                            {
                                text : "Integrated Home-office"
                            },
                            {
                                text : "Spacious kitchen with bar"
                            },
                            {
                                text : "Ideal disposition for a manager"
                            }
                        ], 
                        cz : [
                            {
                                text : "Samostatný šatník"
                            },
                            {
                                text : "Integrovaný Home-office"
                            },
                            {
                                text : "Prostorná kuchyně s barem"
                            },
                            {
                                text : "Ideální dispozice pro manažera"
                            }
                        ], 
                    },
                    
                    content : {
                        sk : "Priestranné lodžie<br/>Maximálne traja susedia na poschodí<br/>Kobka ku každému bytu<br/>Bezpečnostné 5-bodové dvere<br/>Nízke náklady na energie<br/>Predpríprava klimatizácie<br/>Predpríprava na exteriérové žaluzie na oknách južnej, východnej a západnej fasády",
                        de : "Geräumige Loggien<br/>Maximal drei Nachbarn pro Etage<br/>Verlies für jede Wohnung<br/>Sicherheitstür mit 5 Punkten<br/>Geringe Energiekosten<br/>Vorkonditionierung für Klimaanlage<br/>Vor -Vorbereitung für Außenjalousien an den Fenstern der Süd-, Ost- und Westfassade",
                        en : "Spacious loggias<br/>Maximum three neighbors per floor<br/>Dungeon for each apartment<br/>Safety 5-point door<br/>Low energy costs<br/>Pre-conditioning for air conditioning<br/>Pre-preparation for exterior blinds on the windows of the south, east and west facades",
                        cz : "Prostorné lodžie<br/>Maximálně tři sousedé v patře<br/>Kobka ke každému bytu<br/>Bezpečnostní 5-bodové dveře<br/>Nízké náklady na energie<br/>Předpříprava klimatizace<br/>Předpříprava na exteriérové žaluzie na oknech jižní, východní a západní fasády",
                    },
                    image : "assets/dispositions/B3HC.jpg",
                    image :  "assets/dispositions/B3HC.jpg",
                    price : 2637
                },
                {
                    id : "B3HD",
                    name : "B3H-D",
                    advantages : {
                        sk : [
                            {
                                text : "Denná časť s jedálňou"
                            },
                            {
                                text : "Samostatné WC"
                            },
                            {
                                text : "Sprchový kút"
                            },
                            {
                                text : "Ideálna dispozícia pre mladý pár"
                            }
                        ],
                        de : [
                            {
                                text : "Wohnzimmer mit Esszimmer"
                            },
                            {
                                text : "Separate Toilette"
                            },
                            {
                                text : "Dusche"
                            },
                            {
                                text : "Ideale Lage für ein junges Paar"
                            }
                        ],
                        en : [
                            {
                                text : "Living room with dining room"
                            },
                            {
                                text : "Separate WC"
                            },
                            {
                                text : "Shower"
                            },
                            {
                                text : "Ideal disposition for a young couple"
                            }
                        ],
                        cz : [
                            {
                                text : "Denní část s jídelnou"
                            },
                            {
                                text : "Samostatné WC"
                            },
                            {
                                text : "Sprchový kout"
                            },
                            {
                                text : "Ideální dispozice pro mladý pár"
                            }
                        ],
                    },
                    
                    content : {
                        sk : "Priestranné lodžie<br/>Maximálne traja susedia na poschodí<br/>Kobka ku každému bytu<br/>Bezpečnostné 5-bodové dvere<br/>Nízke náklady na energie<br/>Predpríprava klimatizácie<br/>Predpríprava na exteriérové žaluzie na oknách južnej, východnej a západnej fasády",
                        de : "Geräumige Loggien<br/>Maximal drei Nachbarn pro Etage<br/>Verlies für jede Wohnung<br/>Sicherheitstür mit 5 Punkten<br/>Geringe Energiekosten<br/>Vorkonditionierung für Klimaanlage<br/>Vor -Vorbereitung für Außenjalousien an den Fenstern der Süd-, Ost- und Westfassade",
                        en : "Spacious loggias<br/>Maximum three neighbors per floor<br/>Dungeon for each apartment<br/>Safety 5-point door<br/>Low energy costs<br/>Pre-conditioning for air conditioning<br/>Pre-preparation for exterior blinds on the windows of the south, east and west facades",
                        cz : "Prostorné lodžie<br/>Maximálně tři sousedé v patře<br/>Kobka ke každému bytu<br/>Bezpečnostní 5-bodové dveře<br/>Nízké náklady na energie<br/>Předpříprava klimatizace<br/>Předpříprava na exteriérové žaluzie na oknech jižní, východní a západní fasády",
                    },
                    image : "assets/dispositions/B3HD.jpg",
                    image :  "assets/dispositions/B3HD.jpg",
                    price : 3636
                },
                {
                    id : "B3HE",
                    name : "B3H-E",
                    advantages : {
                        sk : [
                            {
                                text : "Oddelená kuchynka"
                            },
                            {
                                text : "Samostatná kúpelňa"
                            },
                            {
                                text : "Dve plnohodnotné spálne"
                            },
                            {
                                text : "Ideálna dispozícia študentov"
                            }
                        ],
                        de : [
                            {
                                text : "Separate Küchenzeile"
                            },
                            {
                                text : "Separates Badezimmer"
                            },
                            {
                                text : "Zwei große Schlafzimmer"
                            },
                            {
                                text : "Ideale Veranlagung der Studierenden"
                            }
                        ],
                        en : [
                            {
                                text : "Separate kitchenette"
                            },
                            {
                                text : "Separate bathroom"
                            },
                            {
                                text : "Two full size bedrooms"
                            },
                            {
                                text : "Ideal disposition of students"
                            }
                        ], 
                        cz : [
                            {
                                text : "Oddělená kuchyňka"
                            },
                            {
                                text : "Samostatná koupelna"
                            },
                            {
                                text : "Dvě plnohodnotné ložnice"
                            },
                            {
                                text : "Ideální dispozice studentů"
                            }
                        ], 
                    },
                    content : {
                        sk : "Priestranné lodžie<br/>Maximálne traja susedia na poschodí<br/>Kobka ku každému bytu<br/>Bezpečnostné 5-bodové dvere<br/>Nízke náklady na energie<br/>Predpríprava klimatizácie<br/>Predpríprava na exteriérové žaluzie na oknách južnej, východnej a západnej fasády",
                        de : "Geräumige Loggien<br/>Maximal drei Nachbarn pro Etage<br/>Verlies für jede Wohnung<br/>Sicherheitstür mit 5 Punkten<br/>Geringe Energiekosten<br/>Vorkonditionierung für Klimaanlage<br/>Vor -Vorbereitung für Außenjalousien an den Fenstern der Süd-, Ost- und Westfassade",
                        en : "Spacious loggias<br/>Maximum three neighbors per floor<br/>Dungeon for each apartment<br/>Safety 5-point door<br/>Low energy costs<br/>Pre-conditioning for air conditioning<br/>Pre-preparation for exterior blinds on the windows of the south, east and west facades",
                        cz : "Prostorné lodžie<br/>Maximálně tři sousedé v patře<br/>Kobka ke každému bytu<br/>Bezpečnostní 5-bodové dveře<br/>Nízké náklady na energie<br/>Předpříprava klimatizace<br/>Předpříprava na exteriérové žaluzie na oknech jižní, východní a západní fasády",
                    },
                    image : "assets/dispositions/B3HE.jpg",
                    image :  "assets/dispositions/B3HE.jpg",
                    price : 1646
                },
                // {
                //     id : "B8H-D",
                //     name : "B8H-D",
                //     advantages : [
                //         {
                //             text : "advantage 1"
                //         },
                //         {
                //             text : "advantage 2"
                //         },
                //         {
                //             text : "advantage 3"
                //         },
                //         {
                //             text : "advantage 4"
                //         },
                //         {
                //             text : "advantage 5"
                //         }
                //     ],
                //     content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                //     image : "assets/dispositions/B8A_4.jpg",
                //     image_full : "assets/dispositions/B8A_1_f.jpg",
                //     price : 
                // }
            ]
        }
    ];

    function dispo_close() {
        dispositions_enabled.update( n => false);
    }

    const formatter = new Intl.NumberFormat('sk-SK', {
      style: 'currency',
      currency: 'EUR'
    });

    function visit_tour($target) {
        let base_url = location.protocol + '//' + location.host + location.pathname
        //console.log($target);
        
        // let krpano = document.getElementById("krpanoSWFObject");
        // if (krpano.get("xml.scene") != null) {
        //     const current_scene = krpano.get("xml.scene").toUpperCase();
        //     if (sample_flat_name == $target) {
        //         //console.log("asgg a");
        //         dispositions_enabled.update(n => false);
        //         return;
        //     } else {
        //         if (_mobile_patch_suffix != "") {
        //         location.href = base_url + '?project=' + $target;
        //         } else {
        //             location.href = base_url + '?project=' + $target;
        //         }
        //     }
             

            
            
        dispositions_enabled.update(n => false);
        if (sample_flat_name == $target) {
        }
        // }

        console.log(sample_flat_name + " : " + $target);

        if (sample_flat_name == $target) {
        } else {
            //location.href = base_url + '?project=' + $target;
            changed_flat.update( n => $target);
        }
        
        
    }

    function open_pdf($target) {
        window.open(userdata['cdn_path'] + 'assets/dispositions/' + $target + '.pdf', '_blank');
    }
</script>


<div id="woow-flat-dispositions">
{#if !_enable_otacadlo}
    <div id="dispositions-blur" transition:fade/>
    {#if disposition_mode == "select" && _dispositions_enabled && _vrMode == false}
        <div id="switcher">
            <div class="close" on:click={() => dispo_close()}></div>
            <div class="wrapper">
                <div id="flat" on:click={() => visit_tour(current_flat)}>
                    <img src="{userdata["cdn_path"]}icons/sample_flat.svg" alt="">
                    <p class="title">{current_flat}</p>
                </div>
                <div id="variations" on:click={() => disposition_mode = "all"}>
                    <img src="{userdata["cdn_path"]}icons/flat_variations.svg" alt="">
                    <p class="title">{$t("components")['dispositions']['choose-dispositions']}</p>
                </div>
            </div>
        </div>
    {/if}

    {#if disposition_mode == "all" && _dispositions_enabled && _vrMode == false}
        <div id="dispositions">
            <div class="close" on:click={() => dispo_close()}></div>
            <div class="back" on:click={() => disposition_mode = "select"}>{$t("components")['dispositions']['back']}</div>
            <div class="title">{$t("components")['dispositions']['client-change']} - {current_flat}</div>
            <div class="container">
                {#each dispositions as dispozicia}
                    {#if dispozicia.parent == current_flat }
                        {#each dispozicia.children as child}
                        <div class="wrapper">
                            <div class="image">
                                <img src="{userdata['cdn_path'] + child.image}" alt="">
                            </div>
                            <div class="data">
                                {#if child.id != "B3HA" && child.id != "B3HB"}
                                    <div class="var-title">{child.name}<span class="rozdiel">{$t("components")['dispositions']['price']} (<span class="price">{formatter.format(child.price)}</span>)</span></div>
                                {:else}
                                    <div class="var-title">{child.name}</div>
                                {/if}
                                <ul>
                                    {#each child.advantages[jazyk] as element}
                                        <li>{element.text}</li>   
                                    {/each}
                                </ul>
                                <div class="buttons">
                                    <button class="detail" on:click={() => dispozicia.last_visited = child.id} on:click={() => disposition_mode = "detail"}>{$t("components")['dispositions']['detail']}</button>
                                    <button class="select" on:click={visit_tour(child.id)}>{$t("components")['dispositions']['enter']}</button>
                                </div>
                            </div>
                        </div>    
                        {/each}

                        
                    {/if}
                {/each}
            </div>
        </div>
    {/if}

    {#if disposition_mode == "detail" && _dispositions_enabled && _vrMode == false}
        <div id="disposition-detail">
            <div class="close" on:click={() => dispo_close()}></div>
            <div class="back" on:click={() => disposition_mode = "all"}>{$t("components")['dispositions']['back']}</div>
            {#each dispositions as dispozicia}
                {#if dispozicia.parent == current_flat}
                    {#each dispozicia.children as child}
                        {#if child.id == dispozicia.last_visited}
                            <div class="title">{child.name}</div>
                            <div id="wrapper">
                                <div class="image container" bind:this={container}>
                                    <div id="my-zoomist" class="zoomable flexbox disposition">
                                    
                                        <img
                                        id="my-zoomist1"
                                        data-zoomist-src={userdata['cdn_path'] + child.image}
                                        src={userdata['cdn_path'] + child.image}
                                        class="img disposition"
                                        alt="logo"
                                        name="logo"
                                        />
                                    </div>
                                </div>
                                <div class="data">
                                    <ul>
                                        {#each child.advantages[jazyk] as item}
                                            <li>{item.text}</li>
                                        {/each}
                                    </ul>
                                    <p class="content">{@html child.content[jazyk]}</p>
                                    {#if child.id != "B3HA" && child.id != "B3HB"}
                                        <div class="rozdiel-wrapper">
                                            <span>{$t("components")['dispositions']['price']} (<span class="price">{formatter.format(child.price)}</span>)</span>
                                            <button id="pdf"on:click={open_pdf(child.id)}>{$t("components")['dispositions']['pdf-documentation']}</button>
                                        </div>
                                    {/if}
                                    <button id="enter" on:click={visit_tour(child.id)}>{$t("components")['dispositions']['enter']}</button>
                                </div>
                            </div>
                        {/if}
                    {/each}
                {/if}
            {/each}
        </div>
    {/if}
{/if}

</div>


<style lang="scss">
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";

    #woow-flat-dispositions{
    #dispositions-blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(63, 63, 70, 0.2);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        z-index: 1;
    }

    #switcher {
        width: calc(100% - 32px);
        height: calc(100% - 32px);
        max-height: 340px;
        max-width: 608px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: var(--secondary-color-light90) no-repeat padding-box;
        -webkit-backdrop-filter: blur(4px) !important;
        backdrop-filter: blur(4px) !important;
        border-radius: 12px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .wrapper {
            display: flex;
            gap: 32px;

            #flat,#variations {
                width: 160px;
                height: 180px;
                background: var(--secondary-color);
                border-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @include rubik-r20;
                cursor: pointer;
                border: 2px solid var(--primary-color-light20);
                text-align: center;
                gap: 20px;

                &:hover {
                    border: 2px solid var(--primary-color);
                }
            }
        }
    }

    #dispositions {
        z-index: 1;
        position: absolute;
        width: calc(100% - 32px);
        height: calc(100% - 32px);
        max-width: 1024px;
        max-height: 640px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 12px;
        background: var(--secondary-color-light90) no-repeat padding-box;
        -webkit-backdrop-filter: blur(4px) !important;
        backdrop-filter: blur(4px) !important;
        display: flex;
        overflow: auto;
        padding: 52px 16px 16px 16px;

        button{
            cursor: pointer;
        }


        .back {
            background-color: var(--primary-color);
            color: var(--secondary-color);
            padding: 5px 10px;
            position: absolute;
            top: 8px;
            left: 16px;
            border-radius: 6px;
            cursor: pointer;
            @include rubik-r14;
            padding: 8px 16px;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 20px;
                height: 12px;
                display: inline-block;
                margin-right: 10px;

            }
        }

        .title {
            @include rubik-m20;
            line-height: 18px !important;
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            padding: 0 56px 0 125px;
            z-index: -1;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            .wrapper {
                background-color: var(--primary-color-light005);
                width: 48.5%;
                border-radius: 12px;
                padding: 8px;
                display: flex;
                height: 270px;

                .image {
                    width: 40%;
                    background-color: white;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 90%;
                    }
                }

                .data {
                    width: 60%;
                    position: relative;

                    .var-title {
                        @include rubik-m16;
                        padding-left: 28px;
                        display: flex;
                        flex-direction: column;

                        .rozdiel {
                            @include rubik-r12;
                            color: var(--primary-color-light60);
                        }

                        .price {
                            @include rubik-m12;
                        }

                    }
                    ul {
                        @include rubik-r14;
                        height: calc(100% - 115px);
                        overflow: auto;
                        width: 100%;
                        min-height: unset;
                    }

                    .buttons {
                        display: flex;
                        gap: 8px;
                        padding-left: 28px;
                        position: absolute;
                        width: 100%;
                        bottom: 0;

                        button {
                            margin: 0;
                            // width: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-grow: 1;

                        }

                        .detail {
                            background-color: var(--primary-color);
                        }
                        
                        .select {
                            background-color: var(--main-color);
                        }
                    }
                }
            }
        }
    }

    #disposition-detail {
        z-index: 1;
        position: absolute;
        width: calc(100% - 32px);
        height: calc(100% - 32px);
        max-width: 1024px;
        max-height: 640px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 12px;
        background: var(--secondary-color-light90) no-repeat padding-box;
        -webkit-backdrop-filter: blur(4px) !important;
        backdrop-filter: blur(4px) !important;
        display: flex;
        overflow: auto;
        padding: 52px 16px 16px 16px;

        button{
            cursor: pointer;
        }


        .back {
            background-color: var(--primary-color);
            color: var(--secondary-color);
            padding: 5px 10px;
            position: absolute;
            top: 8px;
            left: 16px;
            border-radius: 6px;
            cursor: pointer;
            @include rubik-r14;
            padding: 8px 16px;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 20px;
                height: 12px;
                display: inline-block;
                margin-right: 10px;

            }
        }

        .title {
            @include rubik-m20;
            line-height: 18px !important;
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            padding: 0 56px 0 125px;
            z-index: -1;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #wrapper {
            display: flex;
            width: 100%;

            .image {
                width: 68%;
                background-color: var(--secondary-color);
                border-radius: 6px;
                border: 1px solid var(--primary-color-light20);
                display: flex;
                justify-content: center;

                img {
                    height: 100%;
                }
            }
            .data {
                width: 30%;
                position: relative;
                padding-left: 20px;

                p {
                    &.content {
                        // height: calc(100% - 236px);
                        overflow: auto;
                    }
                }

                #enter {
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - 16px);;
                    background-color: var(--main-color);
                    margin: 0;
                    display: flex;
                    justify-content: center;
                }

                .rozdiel-wrapper {
                    position: absolute;
                    bottom: 32px;
                    width: calc(100% - 16px);
                    border-top: 1px solid var(--primary-color-light20);
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        @include rubik-r12;
                        color: var(--primary-color-light60);
                    }

                    .price {
                        @include rubik-m12;
                    }

                    #pdf {
                        margin: 0;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        background-color: var(--primary-color);
                    }
                }
            }
        }
    }

    //--------------------- screen --------------------------

    @media (max-width: 870px) {
        #dispositions {
            .container {
                .wrapper {
                    width: 100%;
                    max-height: 280px;

                    .image {
                        width: 50%;
                        img {
                            height: 90%;
                            width: auto;
                        }
                    }
                    .data {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media (max-width: 610px) {
        #dispositions {
            .title {
                @include rubik-m16
            }
            .container {
                .wrapper {
                    .image {
                        img {
                            width: 90%;
                            height: auto;
                        }
                    }
                    .data {
                        ul {
                            // height: 160px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        #dispositions {
            height: calc(100% - 32px);
            .container {
                .wrapper {
                    flex-direction: column;
                    max-height: unset;
                    align-items: center;
                    height: auto;

                    .image {
                        width: 100%;
                    }
                    .data {
                        width: 100%;
                        padding-top: 16px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .buttons {
                            padding: 0;
                            width: 100%;
                        }

                        .var-title {
                            align-items: center;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            // align-items: center;
                            min-height: 160px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 420px) {
        #switcher {
            height: auto;
            max-height: unset;
            padding: 46px;
            .wrapper {
                flex-direction: column;
            }
        }
    }
    @media (max-width: 700px) {
            #disposition-detail {
                #wrapper {
                flex-direction: column;
                width: 100%;

                    .data {
                        overflow: auto;

                        .rozdiel-wrapper {
                            position: relative;
                            bottom: 0;
                        }

                        #enter {
                            position: relative;
                            bottom: 0;
                        }
                    }

                    .image {

                        width: 100%;
                        height: 65%;

                    }

                    .data {
                        width: 100%;
                    }
            }
        }
    }
}
</style>