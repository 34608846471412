<script>
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
    import AudioPlayer, { stopAll}  from './AudioPlayer.svelte';
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    let sound_loaded = false;
    let sound_data = null;

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {
        id : $id
      } 

      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
      const json = await res.json();
          
          // prevod hodnoty boolean
          const keys = Object.keys(json[0]);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                key == "enabled" && json[0][key] == "0"
            ) {
                json[0][key] = false
            }

            if (
                key == "enabled" && json[0][key] == "1"
            ) {
                json[0][key] = true
            }
            
          }

          sound_data = json[0];
          
          sound_loaded = true;
          audioTracks.push(userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "sounds/" + sound_data.url);
          

        return json;
      } else {
        throw new Error(data);
        }
  	}

    getData("sound");

    async function save_settings() {
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(sound_data));
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateSound.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia zvuku boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia zvuku neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

    // upload zvuku na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        formData.append("parameters", JSON.stringify(parameters));
        
        
        const res = await fetch(userdata['cdn_path'] + 'php/admin/saveSound.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Zvuk bol úspešne uploadnutý!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  

                
                if (json.sound_url) {
                  sound_data.url =  json.sound_url;
                  audioTracks = [];
                  audioTracks.push(userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "sounds/" + sound_data.url);
                  save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Zvuk nebol uploadnutý!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

    let audioTracks = [];
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if sound_loaded}

    <main in:fade>
      <h1>{$t("components")['sounds']['admin-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>

      <!-- Zvuk enable/disable -->
      <div class="component">
        <label for="enable">{$t("components")['sounds']['enable_bg_sound']}</label>
        <input type="checkbox" name="enable" bind:checked="{sound_data.enabled}"/>
      </div>  

      <!-- Zvuk  -->
      <div class="component">
        <label >{$t("components")['sounds']['enable_bg_sound']}</label>
        <input type="text" bind:value="{sound_data.url}"/>
      </div>   

      <!-- upload zvuku -->
      <div class="component upload">
        <label for="fileupload">{$t("components")['sounds']['sound']}</label><br>
        <input id="fileupload" type="file" name="fileupload" />
        <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
      </div>
      
      <!-- Prehrať zvuk -->
      {#each audioTracks as src}
        <AudioPlayer {src} />
      {/each}

         </main>
    <Header />
    
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>