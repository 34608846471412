<script>
    
    import { Link } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
  
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from 'svelte-notifications';
    const { addNotification } = getNotificationsContext();

    export let userdata;
    let parameters = {
        base_path : userdata["base_path"],
        cdn_path : userdata["cdn_path"],
        start_project : userdata["start_project"],
        assets_folder : userdata["assets_folder"]
    };
    

    let fetching_data = false;

    let project_to_delete = {
        id : ""
    };

    let update_parameters = [
        {
            id : "enabled",
            value : false,
            title :  "Povolená scéna",
            type : "scene"
        },
        {
            id : "start_scene",
            value : false,
            title :  "Úvodná scéna",
            type : "scene"
        },
        {
            id : "target_scene",
            value : false,
            title : "Hotspot target (scéna)",
            type : "hotspot"
        },
        {
            id : "ath",
            value : false,
            title : "pozícia ATH (os X)",
            type : "hotspot"
        },
        {
            id : "enabled",
            value : false,
            title :  "Pozícia hotspotu",
            type : "attribute"
        }
    ];

    let components_parameters = [
        {
            id : "hotspots",
            value : true,
            title :  "Hotspoty next-node",
            type : "hotspot"
        },
        {
            id : "attributes",
            value : false,
            title :  "Produktové hotspoty",
            type : "hotspot"
        },
        {
            id : "floorplan",
            value : true,
            title :  "Floorplan",
            type : "floorplan"
        }
    ]

    let reverse = {
        value : false
    }

    let ignore_terrain = {
        value : false
    }

    let revert = true;


     // upload loga na server
     async function importFile() {
        final_time = null;
        end_time = null;
        start_time = new Date().getTime();
        fetching_data = true;
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        formData.append("update_parameters", JSON.stringify(update_parameters));
        formData.append("components_parameters", JSON.stringify(components_parameters));
        formData.append("revert", JSON.stringify(reverse));
        formData.append("ignore_terrain", JSON.stringify(ignore_terrain));
        formData.append("parameters", JSON.stringify(parameters));
        console.log(formData);
        
        const res = await fetch(userdata['cdn_path'] + 'php/admin/ImportMax_new.php', {
            method: "POST", 
            body: formData
        });    

        if (res.ok) {
            // const text = await res.text();
            // console.log(text);

            const json = await res.json();
            console.log(json);
            fetchData();
            //console.log(JSON.parse(json));
            // let result = JSON.stringify(json);

            if (json.status == true) {
                
                // console.log(json.data);
                addNotification({
                    text: "Dáta z MAXU boli naimportované!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  
                fetching_data = false;
                end_time = new Date().getTime();
                console.log(end_time);
                final_time = ((end_time - start_time) /1000).toFixed(5);
                

            }

            else {
                addNotification({
                    text: "Chyba : Dáta neboli naimportované!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
                fetching_data = false;
            }
        }  
    }
    let deleting = false;
    let start_time, end_time, final_time = null;

    let all_projects = [];
    
    async function fetchData() {
        

        all_projects = [];
        const res = await fetch(
        userdata["cdn_path"] + "php/admin/getConfiguratorSampleFlats.php",
        {
            method: "POST",
            body: JSON.stringify(parameters),
        }
        );

        if (res.ok) {
        
        const json = await res.json();
        json.forEach(element => {
            all_projects = [...all_projects, element.name];
        });

        project_to_delete = {
            id : all_projects[0]
        };
        console.log(all_projects);
        
        return json;
        } else {
        throw new Error(data);
        }
    }

    fetchData();

    async function max_delete() {
       

        if (project_to_delete.id != "") {

            let formData = new FormData();           
            formData.append("project_to_delete", JSON.stringify(project_to_delete));
            formData.append("parameters", JSON.stringify(parameters));
            
            deleting = true;
            console.log(project_to_delete);
            const res = await fetch(userdata['cdn_path'] + 'php/admin/deleteMax.php', {
                method: "POST", 
                body: formData
            });    

            if (res.ok) {
                const json = await res.json();
                console.log(json);
                
                fetchData();

                addNotification({
                    text: "Dáta pre projekt" + project_to_delete.id + " boli vymazané!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  
                deleting = false;

            } else {
                addNotification({
                    text: "Dáta pre projekt" + project_to_delete.id + " neboli vymazané!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "error",
                });  
                deleting = false;
            }
        } else {
            return
        }
        
    }

    $: {
        project_to_delete;

        console.log(project_to_delete);
    }
    
</script>

<div id="wrapper">
    <Aside />
    <Header />
    <main in:fade>
        <h1>Import dát z MAXU</h1>

        <div class="component">
            <label for="hotspot-type">Hotspot <strong>next-node</strong> :</label>
            <select name="hotspot-type" id="hotspot-type">
                <option value="floorspot">floorspot</option>
                <option value="arrow">arrow</option>
            </select>
        
            <div class="upload">
                <input id="fileupload" type="file" name="fileupload" />
                
                {#if fetching_data}
                    <button id="upload-button" class="edit "><i class="fa-solid fa-spinner fa-spin"></i>Importujem</button>
                {:else}
                    <button id="upload-button" on:click="{() => importFile()}">Importovať</button>
                {/if} 
            </div>
            {#if final_time != null}
                <p>trvanie importu : {final_time} sek.</p>     
            {/if}
        </div>
        
            <div id="update">
                <div>
                    <h3>Update scén</h3>
                    <div class="component">
                        <label for="reverse">Otočiť poradie ?</label>
                        <input type="checkbox" name="reverse" bind:checked={reverse.value}>
                    </div>
                    <div class="component">
                        <label for="ignore">Ignorovať výšku (floorplan) ?</label>
                        <input type="checkbox" name="ingore" bind:checked={ignore_terrain.value}>
                    </div>
                    {#each update_parameters as item}
                        {#if item.type == "scene"}
                            <div class="component">
                                <label for="{item.id}">{item.title} ?</label>
                                <input type="checkbox" name="{item.id}" bind:checked={item.value}>
                            </div>
                        {/if}
                    {/each}
                </div>
                <div>
                    <h3>Update hotspotov</h3>
                    {#each update_parameters as item}
                        {#if item.type == "hotspot"}
                            <div class="component">
                                <label for="{item.id}">{item.title} ?</label>
                                <input type="checkbox" name="{item.id}" bind:checked={item.value}>
                            </div>
                        {/if}
                    {/each}
                </div>
                <div>
                    <h3>Update atribútov</h3>
                    {#each update_parameters as item}
                        {#if item.type == "attribute"}
                            <div class="component">
                                <label for="{item.id}">{item.title} ?</label>
                                <input type="checkbox" name="{item.id}" bind:checked={item.value}>
                            </div>
                        {/if}
                    {/each}
                </div>                
            </div>

            <div id="update" class="two">
                <div>
                    <h3>Hotspoty</h3>
                    {#each components_parameters as item}
                        {#if item.type == "hotspot"}
                            <div class="component">
                                <label for="{item.id}">{item.title} ?</label>
                                <input type="checkbox" name="{item.id}" bind:checked={item.value}>
                            </div>
                        {/if}
                    {/each}
                </div>
                <div>
                    <h3>Floorplan</h3>
                    {#each components_parameters as item}
                        {#if item.type == "floorplan"}
                            <div class="component">
                                <label for="{item.id}">{item.title} ?</label>
                                <input type="checkbox" name="{item.id}" bind:checked={item.value}>
                            </div>
                        {/if}
                    {/each}
                </div>           
            </div>
        
        <hr>

        <h3>Zmazanie projektu</h3>
        <div class="component">
            <label for="{project_to_delete}">Zvoľ projekt</label>
            <select bind:value={project_to_delete.id}>
                {#each all_projects as flat}
                  <option value={flat}>
                    {flat}
                  </option>
                {/each}
              </select>
            <!-- <input type="text" name="{project_to_delete.id}" bind:value={project_to_delete.id}> -->
            
            {#if deleting}
                <button id="delete" class="edit" style="--fa-animation-duration: 2s;"><i class="fa-solid fa-spinner fa-spin"></i>Mažem</button>
            {:else}
                <button id="delete" class="delete" on:click="{() => max_delete()}">Vymazať</button>
            {/if}

            
        </div>

        
        

          
    </main>
</div>





<style lang="scss">

    @import "../../assets/scss/themes.scss";

    #wrapper {
        .component {
            justify-content: flex-start;
            gap: 8px;
            padding: 10px;

            #delete {
                margin-left: 16px;
                display: inline-block;
            }

            label {
                width: auto !important;
                display: inline-block;
                min-width: 140px;
                text-transform: none !important;
            }

            select {
                width: auto !important;
            }

            button {
                display: inline-block;
            }
        }

        #update {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 960px;
            margin: 10px auto;
            justify-content: space-between;
            background-color: #E8EDF2;
            padding: 15px;
            border-radius: 12px;

            &.two {
                justify-content: flex-start;
                gap: 16px;
            }

            div{
                .component {
                    justify-content: space-between;
                    border-radius: 6px;
                    
                    &:hover {
                        background-color: $border-color;
                    }
                }
            }
        }

        hr {
            width: 100%;
            max-width: 960px;
            color: $border-color;
            margin: 36px auto;
            opacity: .3;

        }

        h3 {
            color: $text-color;
        }
    }
    
</style>