<script>
    import { blur, vr_mode, flat_3D, enable_filter, otacadlo_selected_flat_all_data, selected_tutorial} from '../store.js';
    import { t, locale, locales } from "../i18n.js";
    import { fade } from 'svelte/transition';
    import { cubicInOut } from 'svelte/easing';

    import Panzoom from "@panzoom/panzoom";
    let zoomable, container;

    function waitForElm(selector) {
        return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        });
    }


   
    export let userdata;
    let show_dimensions = false;
    let selcted_flat;
    let mode = 1;
    let src;
    let moveto;

    $: {
        mode;

        if (mode == 2) {
            waitForElm("#my-zoomist").then((elm) => {
                const elem = document.getElementById("my-zoomist");
                const panzoom = Panzoom(elem, {
                maxScale: 5,
                });

                panzoom.zoom(1), { animate: true };
                panzoom.pan(0, 0), { animate: true };
                // panzoom.pan(10, 10)
                // panzoom.zoom(2, { animate: true });

                // elem.addEventListener('panzoomchange', (event) => {
                //   //console.log(event.detail) // => { x: 0, y: 0, scale: 1 }
                // })

                elem.parentElement.addEventListener("wheel", panzoom.zoomWithWheel);
                moveto = function ($x, $y) {
                
                panzoom.zoom(1, { animate: true });
                panzoom.pan($x, $y, { animate: true });
                };

               
                
            });
        }
    }


    otacadlo_selected_flat_all_data.subscribe(value => {
        selcted_flat = value;
        console.log(value);
    });


    let _flat_3D;
    flat_3D.subscribe(value => {
      _flat_3D = value;
      console.log(value);
      switch (value) {
        
        case true:
            blur.update(n => true);
            enable_filter.update(n => false);
            break;
      
        default:
            enable_filter.update(n => true);
            break;
      }
    });

    let _vrMode;
    vr_mode.subscribe(value => {
      _vrMode = value;
    });

    function fadeScale (
        node, { delay = 0, duration = 200, easing = x => x, baseScale = 0 }
        ) {
        const o = +getComputedStyle(node).opacity;
        const m = getComputedStyle(node).transform.match(/scale\(([0-9.]+)\)/);
        const s = m ? m[1] : 1;
        const is = 1 - baseScale;

        return {
            delay,
            duration,
            css: t => {
            const eased = easing(t);
            return `opacity: ${eased * o}; transform: scale(${(eased * s * is) + baseScale})`;
            }
        };
    }

    const formatter = new Intl.NumberFormat('sk-SK', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits : 0

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    // Funkcie
    function readModel() {
        modelViewer = document.querySelector("model-viewer");
        modelViewer.addEventListener('load', (event) => {
            console.log(event);
            //modelViewer.pause();
            select_active_model();
            //ar_supported = modelViewer.canActivateAR;
            //////////console.log(ar_supported);
        });
    }

    // označenie aktívneho variantu produktu
    function select_active_model() {
       ////////console.log("hľadám model");
        
        const base = userdata['base_path']+"assets/models/";
        const modelViewer = document.querySelector("model-viewer");
        if (modelViewer != null) {
            src = base + 1 + '.glb';
            modelViewer.src = base + 'holobyt.glb';

            modelViewer.cameraOrbit = "0deg 75deg 105%";
            modelViewer.cameraTarget = "auto auto auto";
            modelViewer.fieldOfView = "auto";
        }

        

    
    }

    // funckia na autotáčanie modelu
    function autorotate() {
        let modelViewer = document.querySelector("model-viewer");
        modelViewer.setAttribute("auto-rotate-delay", 0);
        modelViewer.toggleAttribute("auto-rotate");
    }

    setTimeout(() => {
        //select_active_model();
    }, 5000);

    let delay = 0;
    let dimLines, renderSVG;
    
</script>
<div id="woow-3d-flat">
{#if _vrMode == false && _flat_3D}
    <div class="blur-back"></div>
    <div id="flat-3d" transition:fadeScale={{
        delay: 10,
        duration: 600,
        easing: cubicInOut,
        baseScale: 0.7
    }}>
        <div class="close" on:click={() => flat_3D.update(n => false)}/>
        <div class="title">{$t("components")['flat_filter']['detail']}</div>
        <div class="content">
        <div class="fp-buttons">
            <button class:active={mode == 1} on:click={() => mode = 1}>3D</button>
            <button class:active={mode == 2} on:click={() => mode = 2}>2D</button>
        </div>
        <div class="product">
            <div class="product-model">
                {#if mode == 1}
                    <div class="icons">
                        <label for="model-autorotate" class="label-autorotate">
                            <input type="checkbox" id="model-autorotate" on:click={() => autorotate()}>{$t("components")['model3d']['autorotate']}
                        </label>
                    </div>
                    <model-viewer id="viewer" style="width: 100%; height: 100%;" camera-controls touch-action="pan-y" src="{userdata['base_path']}assets/models/2.glb" ar alt="" shadow-intensity="1" skybox-image="" on:load={() => readModel()}>
                        <!-- Rozmery  -->
                        <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot+X-Y+Z" data-position="1 -1 1" data-normal="1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim+X-Y"  data-position="1 -1 0" data-normal="1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}"slot="hotspot-dot+X-Y-Z" data-position="1 -1 -1" data-normal="1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim+X-Z" data-position="1 0 -1" data-normal="1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot+X+Y-Z" data-position="1 1 -1" data-normal="0 1 0"></button>
                        <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim+Y-Z" data-position="0 -1 -1" data-normal="0 1 0"></button>
                        <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}"slot="hotspot-dot-X+Y-Z" data-position="-1 1 -1" data-normal="0 1 0"></button>
                        <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim-X-Z" data-position="-1 0 -1" data-normal="-1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot-X-Y-Z" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dim show' : 'dim hide'}" slot="hotspot-dim-X-Y" data-position="-1 -1 0" data-normal="-1 0 0"></button>
                        <button class="{show_dimensions === true ? 'dot show' : 'dot hide'}" slot="hotspot-dot-X-Y+Z" data-position="-1 -1 1" data-normal="-1 0 0"></button>
                        <svg class="{show_dimensions === true ? 'dimensionLineContainer show' : 'dimensionLineContainer hide'}" id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                            <line class="dimensionLine"></line>
                            <line class="dimensionLine"></line>
                            <line class="dimensionLine"></line>
                            <line class="dimensionLine"></line>
                            <line class="dimensionLine"></line>
                        </svg>
                        <label class="label-dimensions hidden" for="show-dimensions"> {$t("components")['model3d']['show-dimensions']}</label>
                        <input class="hidden" id="show-dimensions" type="checkbox" checked>
                    </model-viewer>
                    <script>
                        modelViewer = document.querySelector("model-viewer"); 
                        checkbox = modelViewer.querySelector('#show-dimensions');
                        checkbox.addEventListener('change', () => {
                            setVisibility(modelViewer.querySelector('#dimLines'));
                            modelViewer.querySelectorAll('button').forEach((hotspot) => {
                            setVisibility(hotspot);
                            });
                        });
            
            
                        function drawLine(svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) {
                            if (dotHotspot1 && dotHotspot1) {
                            svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
                            svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
                            svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
                            svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);
            
                            // use provided optional hotspot to tie visibility of this svg line to
                            if (dimensionHotspot && !dimensionHotspot.facingCamera) {
                                svgLine.classList.add('hide');
                            }
                            else {
                                svgLine.classList.remove('hide');
                            }
                            }
                        }
            
                        dimLines = modelViewer.querySelectorAll('line');
                        renderSVG = () => {
                            drawLine(dimLines[0], modelViewer.queryHotspot('hotspot-dot+X-Y+Z'), modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Y'));
                            drawLine(dimLines[1], modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Z'));
                            drawLine(dimLines[2], modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X+Y-Z')); // always visible
                            drawLine(dimLines[3], modelViewer.queryHotspot('hotspot-dot-X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dim-X-Z'));
                            drawLine(dimLines[4], modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y+Z'), modelViewer.queryHotspot('hotspot-dim-X-Y'));
                        };
            
                        modelViewer.addEventListener('camera-change', renderSVG);
            
                        modelViewer.addEventListener('load', () => {
                            const center = modelViewer.getBoundingBoxCenter();
                            const size = modelViewer.getDimensions();
                            const x2 = size.x / 2;
                            const y2 = size.y / 2;
                            const z2 = size.z / 2;
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dot+X-Y+Z',
                            position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`
                            });
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dim+X-Y',
                            position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
                            });
                            modelViewer.querySelector('button[slot="hotspot-dim+X-Y"]').textContent =
                                `${(size.z * 100).toFixed(0)} cm`;
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dot+X-Y-Z',
                            position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`
                            });
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dim+X-Z',
                            position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
                            });
                            modelViewer.querySelector('button[slot="hotspot-dim+X-Z"]').textContent =
                                `${(size.y * 100).toFixed(0)} cm`;
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dot+X+Y-Z',
                            position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`
                            });
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dim+Y-Z',
                            position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`
                            });
                            modelViewer.querySelector('button[slot="hotspot-dim+Y-Z"]').textContent =
                                `${(size.x * 100).toFixed(0)} cm`;
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dot-X+Y-Z',
                            position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`
                            });
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dim-X-Z',
                            position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
                            });
                            modelViewer.querySelector('button[slot="hotspot-dim-X-Z"]').textContent =
                                `${(size.y * 100).toFixed(0)} cm`;
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dot-X-Y-Z',
                            position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`
                            });
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dim-X-Y',
                            position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
                            });
                            modelViewer.querySelector('button[slot="hotspot-dim-X-Y"]').textContent =
                                `${(size.z * 100).toFixed(0)} cm`;
            
                            modelViewer.updateHotspot({
                            name: 'hotspot-dot-X-Y+Z',
                            position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`
                            });
            
                            renderSVG();
                        });
                    </script>
                {/if}
                {#if mode == 2}
                <div class="wrapper">
                    <div class="image container" bind:this={container}>
                        <div id="my-zoomist" class="zoomable flexbox disposition">
                        
                            <img
                            id="my-zoomist1"
                            data-zoomist-src={userdata['base_path'] + "assets/floorplans/B8A_2392.png"}
                            src={userdata['base_path'] + "assets/floorplans/B8A_2392.png"}
                            class="img disposition"
                            alt="logo"
                            name="logo"
                            />
                        </div>
                    </div>
                </div>
                {/if}
            </div>
            <div class="product-info">
                <div class="flat-name">
                    <h1>{selcted_flat.flat_penta_internal_id}</h1>
                    <div class="status free">{selcted_flat.flat_status}</div>
                    <div class="select-flat">
                        <img src="../assets/icons/submenu.svg" alt="" class="prev-flat">
                        <img src="../assets/icons/flat.svg" alt="" class="all-flats">
                        <img src="../assets/icons/submenu.svg" alt="" class="next-flat">
                    </div>
                </div>
                
                    <p>3 izbový byt s predzáhradkou v projekte Bory Bývanie, štvrť Promenáda. Táto časť projektu je najluktarívnejšou z hľadiska pešej dostupnosti do nákupného centra BoryMall ako aj do novej nemocnice.</p>
                    <p>Ponúkaný byt sa predáva v stave štandard a disponuje celkovou plochou 107,04m2 vrátane 2,20m2 pivničnej kobky a 31,92m2 predzáhradky aj so spevnenou plochou. Nachádzame sa v budove A2, na 1.NP</p>
                    <p>Orientácia je na Severnú a Južnú stranu</p>
                    <p>K bytu je možné dokúpiť si vonkajšie parkovacie státie ( 11.000€ )</p>
                
                <div class="price">
                    <h1>{formatter.format(selcted_flat.flat_price)}</h1><sub>{$t("components")['flat_filter']['vat']}</sub>
                </div>
                <button class="detail-enter-vr">{$t("components")['flat_filter']['enter-vr']}</button>
                <div class="strazit">
                    <button class="porovnat">{$t("components")['flat_filter']['compare']}</button>
                    <button class="strazit-cenu">{$t("components")['flat_filter']['check-price']}</button>
                    <button class="strazit-dostupnost">{$t("components")['flat_filter']['check-status']}</button>
                </div>
                <div class="flat-info">
                    <div class="global">
                        <div class="global-row">
                        <p>{$t("components")['flat_filter']['flat-id']}</p>
                        <p>{selcted_flat.flat_penta_internal_id}</p>
                        </div>
                        <div class="global-row">
                        <p>{$t("components")['flat_filter']['block']}</p>
                        <p>B1</p>
                        </div>
                        <div class="global-row">
                        <p>{$t("components")['flat_filter']['floor']}</p>
                        <p>{selcted_flat.floor}</p>
                        </div>
                        <div class="global-row">
                        <p>{$t("components")['flat_filter']['room-count']}</p>
                        <p>{selcted_flat.flat_disposition}</p>
                        </div>
                    </div>
                    <div class="room">
                        <div class="room-row">
                            <p>{$t("components")['flat_filter']['bathroom']}</p>
                            <p>{selcted_flat.flat_area_bathroom1} m<sup>2</sup></p>
                        </div>
                        <div class="room-row">
                            <p>{$t("components")['flat_filter']['bedroom']}</p>
                            <p>{selcted_flat.flat_area_bedroom} m<sup>2</sup></p>
                        </div>
                            <div class="room-row">
                            <p>{$t("components")['flat_filter']['balcony']}</p>
                            <p>{selcted_flat.flat_area_balcony} m<sup>2</sup></p>
                            </div>
                            <div class="room-row">
                            <p>{$t("components")['flat_filter']['floor-size']}</p>
                            <p>{selcted_flat.flat_area_total} m<sup>2</sup></p>
                            </div>
                            <div class="room-row">
                            <p>{$t("components")['flat_filter']['wc']}</p>
                            <p>{selcted_flat.flat_area_toilette} m<sup>2</sup></p>
                            </div>
                    </div>
                    
                    <div class="local-description">
                        <h3>Mestská štvrť so všetkým čo pre život potrebujete</h3><br />
                        <p>Na Boroch nájdete všetko čo potrebujete. Dostatok občianskej vybavenosti na to, aby ste nemuseli nikam chodiť.
                            Zabudnite na cestovanie za nákupmi, kaderníkom, škôlkou či ihriskami - všetko nájdete v bezprostrednom susedstve. Ušetrený časť môžete stráviť napríklad romantickou večerou na terase alebo hodinou v bazéne.</p>
                    </div>
                
            </div>
            </div>
            {#if mode == 1}
                <label for="dimensions" class="show-dimensions"> 
                    <input id="dimensions" type="checkbox" bind:checked={show_dimensions}>{$t("components")['model3d']['show-dimensions']}
                </label>
            {/if}
            
        </div>
        </div>
        <div class="all-products"></div>
    </div>
{/if}
</div>
<style lang="scss">
    /* your styles go here */
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/variables.scss";
#woow-3d-flat{
    #flat-3d {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: calc(100% - 64px);
        height: calc(100% - 64px);
        max-height: 900px;
        max-width: 1300px;
        padding: 16px;
        @include blur;
        border-radius: $radius-M;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 200;

        .flat-name{
            display: flex;
            align-items: center;
        }

        .select-flat{
            position: absolute;
            right: 16px;
            height: 40px;
            display: flex;
            align-items: center;
            gap: 5px;

            img{
                height: 25px;
                box-shadow: 0 0 0 transparent;
                margin: 0;
                width:auto;

                &.prev-flat{
                    transform: rotate(180deg);
                    height: 15px;
                }

                &.next-flat{
                    height: 15px;
                }
            }

        }

        .show-dimensions{
            position: absolute;
            left: 16px;
            top: 50px;
        }
        
        .title {
            @include rubik-m24;
            padding: 0 32px;
            margin-bottom: 8px;
            text-align: center;
            height: 24px;
        }

        .fp-buttons{
            position: absolute;
            top: 16px;
            right: 33%;
            display: flex;
            gap: 16px;
            z-index: 1;

            button{
                margin: 0;
                background-color: var(--main-color-light005);
                border: 1px solid var(--main-color-light20);
                color: var(--main-color);

                &.active {
                    background-color: var(--main-color);
                    border: 1px solid transparent;
                    color: var(--secondary-color);
                }

            }
        }

        .content {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: auto;
            border-radius: $radius-S;
            background-color: white;
            border: 1px solid var(--primary-color-light20);
            display: flex;
            flex-direction: column;



            .product {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 100%;

                .product-model {
                    // background-color: tomato;
                    width: 70%;
                    height: 100%;
                    position: relative;

                    .icons {
                        position: absolute;
                        left: 16px;
                        top: 16px;
                        z-index: 220;
                        

                        .ar-icon {
                            background-color: var(--primary-color);
                            color: var(--secondary-color);
                            width: max-content;
                            display: flex;
                            padding: 9px 13px;
                            align-items: center;
                            border-radius: 6px;
                            @include rubik-m14;
                            cursor: pointer;
                            margin-bottom: 16px;

                            &:hover {
                                background-color: var(--primary-hover);
                            }

                            &::before {
                                content: "";
                                display: block;
                                width: 22px;
                                height: 22px;
                                background-image: url("../assets/icons/ar.svg");
                                margin-right: 9px;
                            }
                        }

                        .play {
                            width: 32px;
                            height: 32px;
                            background-color: var(--primary-color);
                            border-radius: $radius-S;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                            cursor: pointer;

                            &:hover {
                                background-color: var(--primary-hover);
                            }

                            &::before {
                                content: "";
                                display: flex;
                                width: 24px;
                                height: 24px;
                                background-color: var(--secondary-color);
                                -webkit-mask-image: url("../assets/icons/play.svg");
                                mask-image: url("../assets/icons/play.svg");
                                mask-size: contain;
                            }
                        }

                    }


                }

                .product-info {
                    // background-color: aqua;
                    position: absolute;
                    right: 0;
                    width: 30%;
                    height: 100%;
                    overflow: auto;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    background-color: #f2f2f2;

                    .detail-enter-vr{
                        width: 100%;
                        max-width: unset;
                    }

                    .strazit{
                        display: flex;
                        gap: 16px;
                        flex-wrap: wrap;

                        button{
                            margin: 0;
                            @include rubik-m12;
                            background-color: var(--primary-color-light005);
                            color: var(--primary-color);
                            flex-grow: 1;
                            width: auto !important;

                            &:hover{
                            
                            background-color: var(--main-color-light005);
                            color: var(--main-color);
                            
                            }
                        }
                    }

                    .flat-info{
          padding: 0 10px;
          height: calc(100% - 290px);
          overflow: auto;
          margin-top: 30px;

          .global{
            margin-bottom: 20px;
            .global-row{
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid var(--primary-color-light10);
             
            }
          }
          .room{
            padding: 0 20px;
            margin-bottom: 20px;
            .room-row{
              display: flex;
              justify-content: space-between;
            }
          }
          .area{
            .area-row{
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 10px;
              @include rubik-m12;
            }
          }
        }
      
    

                    h1 {
                        @include rubik-m20;
                    }
                    h2 {
                        @include rubik-r16;
                        font-weight: 100
                    }
                    h3 {
                        @include rubik-r14;
                        font-weight: 100;

                        a {
                            font-weight: 500;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .standard {
                        display: flex;
                        gap: 8px;
                        justify-content: flex-start;
                        margin: 12px 0;
                        
                        .standard-item {
                            // width: 140px;
                            width: auto;
                            height: 32px;
                            border: 1px var(--primary-color) solid;
                            @include rubik-r14;
                            border-radius: $radius-S;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            background-color: transparent;
                            color: var(--primary-color);
                            margin: 0;
                        
                            &.active {
                                background-color: var(--primary-color);
                                color: var(--secondary-color);
                                @include rubik-m14;
                        }
                    }
                }

                    .price {
                        display: flex;
                        gap: 12px;
                        color: var(--main-color);
                        align-items: center;
                    }

                    .colors {
                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 4px;
                            box-shadow: 0 0 0 2px var(--main-color-light20);

                            &:hover {
                                box-shadow: 0 0 0 2px var(--main-color);
                }
                        }
                    }

                    button {
                        width: calc(100% - 32px);
                        max-width: 260px;
                        background-color: var(--main-color);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include rubik-m14;

                        &:hover {
                            background-color: var(--main-hover);
                        }
                    }
                }
            }
            .all-products {
                // background-color: gold;
                width: 70%;
                height: 140px;
                padding: 12px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                overflow: auto;
                gap : 20px;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 80px;

                    span {
                        font-family: 'Rubik';
                        font-style: normal;
                        // font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        margin-top: 8px;
                        width: 110%;
                        text-align: center;
                        overflow-wrap: break-word;

                    }

                    img {
                        margin: 0px;
                    }
                }
            }
        }

        img {
            width: 65px;
            height: 65px;
            border-radius: $radius-S;
            margin-right: 12px;
            cursor: pointer;
            box-shadow: 0 0 0 1px var(--primary-color-light20);

            &:hover {
                box-shadow: 0 0 0 1px var(--primary-color-light60);
            }

            &.active {
                box-shadow: 0 0 0 1px var(--primary-color);
            }
        }


        input {
            appearance: none;
            width: 16px;
            height: 16px;
            box-shadow: 0 0 0 2px var(--primary-color-light10);
            border-radius: 4px;
            position: relative;
            top: 3px;
            cursor: pointer;
            margin-right: 12px;
            margin: 0;
            margin-right: 10px;
            

            &:checked {
                box-shadow: 0 0 0 2px var(--primary-color);
                background-color: var(--primary-color);
            }

            &::before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                background-color: var(--secondary-color);
                position: relative;
                top: 3px;
                left: 3px;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            }

            &:checked::before {
                transform: scale(1);
            }
        }
    }

         //  Dimenzia

    .dot{
        display: none;
    }

    .dim{
        background: #fff;
        border-radius: 4px;
        border: none;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        color: rgba(0, 0, 0, 0.8);
        display: block;
        font-family: Futura, Helvetica Neue, sans-serif;
        font-size: 1em;
        font-weight: 700;
        max-width: 128px;
        overflow-wrap: break-word;
        padding: 0.5em 1em;
        position: absolute;
        width: max-content;
        height: max-content;
        transform: translate3d(-50%, -50%, 0);
        pointer-events: none;
        --min-hotspot-opacity: 0;
    }

    @media only screen and (max-width: 800px) {
        .dim{
        font-size: 3vw;
        }
    }

    .dimensionLineContainer{
        pointer-events: none;
        display: block;
    }

    .dimensionLine{
        stroke: #16a5e6;
        stroke-width: 2;
        stroke-dasharray: 2;
    }

    .hide{
        display: none;
    }
    /* This keeps child nodes hidden while the element loads */
    :not(:defined) > * {
        display: none;
    }

    .hidden {
        display: none !important;
    }

    .status {
            font-size: 12px;
            line-height: 16px;
            background-color: #D1FAE5;
            border-radius: 6px;
            padding: 6px 10px;
            color: #10B981;
            display: flex;
            align-items: center;
            height: max-content;
            margin-left: 16px;

            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #10B981;
                margin-right: 4px;
            }
        }

    //------------------ screen --------------------------------------

    @media (max-width: 800px) {

        #model-3d {
            .content {
                .product {
                    flex-direction: column;
                    height: calc(100% - 110px);

                    .product-model {
                        width: 100%;
                    }

                    .product-info {
                        position: relative;
                        width: 100%;

                        h1{
                            margin: 0;
                            order: 1;
                            @include rubik-m16;
                        }

                        .variant-text {
                            order: 2;
                            margin: 4px 0;
                        }

                        .variants {
                            order: 3;
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }

                        h2 {
                            order: 4;
                            margin: 2px 0;
                            @include rubik-r14;
                        }

                        .price {
                            order: 5;
                            margin: 0 0 8px 0;
                            align-items: baseline;

                            h1 {
                                @include rubik-m16;
                            }
                            h3 {
                                @include rubik-m12;
                                margin: 0;
                            }
                        }

                        .confirm {
                            width: 100%;
                            max-width: unset;
                            order: 6;
                            margin: 0 0 8px 0;
                        }

                        

                        .show-dimensions {
                            order: 7;
                            font-size: 12px;
                        }

                        h3 {
                            order: 8;
                        }
                    }
                }

                .all-products {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 108px;
                    padding: 9px;
                    gap: 12px;
                    box-shadow: 0 0 20px -12px black;

                    img {
                        width: 50px;
                        height: 50px;
                    }
                    span {
                        @include rubik-r12;
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }
            }
        }
    }

    .confirmed {
        background-color: var(--primary-color) !important;
    }

    #my-zoomist1 {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0 !important;
        border: 0px !important;
        box-shadow: none !important;
        margin: 0 !important;
    }

    #wrapper {
            display: flex;
            width: 100%;

            .image {
                width: 68%;
                background-color: var(--secondary-color);
                border-radius: 6px;
                border: 1px solid var(--primary-color-light20);
                display: flex;
                justify-content: center;

                img {
                    height: 100%;
                }
            
            }
    }
}
</style>