<script>
    ////////console.log("zapínam flatFilter");
    
    import { tooltip } from "@svelte-plugins/tooltips";
    import { setCookie, formatter, verifiyFloor } from '../utils.js';

    import { get } from 'svelte/store';
    import { flat_filter_enabled, disabled_icons, active_scene, flat_id, shortcuts, cart, languages, otacadlo_selected_floor, vr_mode, floor_view, enable_otacadlo, reseting_filter, otacadlo_filteredList, dispositions_enabled, changed_flat } from '../store.js';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import {newDelay, flat_3D, enable_floorplan_nav, showFlatOnFloor, tooltipAnimation, flat_not_found} from "../store.js";
    export let sample_flat, userdata, _dispositions_enabled, should_enable_disposition, Otacadlo, isMobile, fakeFlats;
    let last_visited = sample_flat.name;

    let flatFilterTooltip = "flat-filter";
    
    import RangeSlider from "svelte-range-slider-pips";
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    let delay = 0;
    async function setDelay(newDelay) {
        delay = newDelay;
    }

    import { t, locale, locales } from "../i18n.js";
    import {mobile_patch_suffix, otacadlo_floors, otacadlo_selected_flat, otacadlo_all_flats, enable_filter, otacadlo_hover_flat, otacadlo_compare_flats, modal_type, modal, cookies_compare_flats, otacadlo_to, otacadlo_selected_flat_all_data, orbit_view, building_view, show_filter, run_visit_tour, active_point, playground_view, rotate_to_floor, change_floor, go_to_flat_from_compare} from '../store.js';
    import {otacadlo_selected_rooms, otacadlo_selected_status, otacadlo_selected_min_price, otacadlo_selected_max_price, filtered_flats_for_svg, is_woow_demo, otacadlo_all_status, changedFloor, flatDetail} from '../store.js';
    import {enable_price_filter, enable_status_filter, enable_dispositions_filter, enable_floor_filter, enable_compare_flats, enable_enter_vr_function, enable_area_info, allow_rotate_to_flat, enable_flat_filter, favIDs, topNRs, toggle_compare_flat_id} from '../store.js';

    
    let _is_woow_demo, _favIDs, _topNRs, _flat_not_found;
    let _enable_price_filter;
    let _enable_status_filter;
    let _enable_dispositions_filter;
    let _enable_floor_filter;
    let _enable_compare_flats;
    let _enable_enter_vr_function;
    let _enable_area_info;
    let _allow_rotate_to_flat;
    let _reseting_filter;
    let _enable_flat_filter;
    let _shortcuts;
    let _enable_otacadlo;
    let _enable_floorplan_nav;

    let _otacadlo_all_flats, _otacadlo_to, _cookies_compare_flats, _vrMode, _current_flat, _otacadlo_selected_floor, _floor_view, _building_view, _orbit_view, _flat_filter_enabled, _show_filter;
    let _otacadlo_hover_flat,  _tmp_flat_object = null;
    let _mobile_patch_suffix = "";
    let sort_rooms, sort_stock, sort_status, sort_price, flat_filter_data_loaded = false;
    let max_price = 0;
    let min_price = 0;
    let filtered_min_price = 0;
    let filtered_max_price = 0;
    let filter_price = 0;
    const jq = window.$;
    let dis_icon = get(disabled_icons);
    let filteredList = [];
    let filter_data = [];
    let _otacadlo_compare_flats = [];
    let all_floors = [];
    let values = [];
    let _playground_view = false;
    let current_scene;
    let _rotate_to_floor = null;

    let _go_to_flat_from_compare = false;

    

    // ???
    let all_dispositions = [];
    //let all_status = ['free', 'reserve', 'sold'];
    let all_status = [];

    let selected_floor = null;
    let selected_disposition = null;
    let selected_status = null;
    let _toggle_compare_flat_id = null;

    
    let active_filter = null;
    let _otacadlo_selected_flat_all_data = null;
    let flatDetailEnabled = false;


    
    toggle_compare_flat_id.subscribe(value => {
        _toggle_compare_flat_id = value;

        if (value != null ) {
            ////console.log(value);
            toggle_compare_flat(value[0].id, true);
            
            
        }
    });

    

    flat_not_found.subscribe(value => {
        _flat_not_found = value;
   })

    flatDetail.subscribe(value => {
        flatDetailEnabled = value;
   })

    favIDs.subscribe(value => {
        _favIDs = value;
        if (value != null) {
            //////console.log("favIDs : " + value);
            checkCookiesAndAddToFlat("_favIDs");
            otacadlo_compare_flats.update(n => []);
            value.forEach(element => {
                if (_otacadlo_all_flats != undefined)
                    _otacadlo_all_flats.forEach(byt => {
                        if (byt.woow_id == element) {
                            otacadlo_compare_flats.update(n => [...n, element]);        
                        }
                })
            });
            //otacadlo_compare_flats.update(n => value);

            if (_otacadlo_all_flats != undefined) {
                _otacadlo_all_flats.forEach(element => {
                    if (value.includes(element.woow_id)) {
                        element.compare = true;
                    } else {
                        element.compare = false;
                    }
                })

                otacadlo_all_flats.update( n => _otacadlo_all_flats);
            }
            

            

            // value.forEach(element => {
            //     otacadlo_compare_flats.update(n => [..._otacadlo_compare_flats, id]);
            // });

        }
        
    });

    topNRs.subscribe(value => {
        _topNRs = value;
        
        if (value != null) {
            //////////////////console.log("topNRs : " + value);
            checkCookiesAndAddToFlat("_topNRs");
        }
        
    });

    function checkCookiesAndAddToFlat(parameter) {
        //////////////////console.log(parameter);
        let tmp_array = _otacadlo_all_flats;
        
        switch (parameter) {
            case "_topNRs" : 

                if (_topNRs != null && _topNRs.length > 0 && tmp_array != null && tmp_array != undefined) {
                    
                    ////////////////////console.log("check cookies");

                    if (tmp_array != undefined) {
                        tmp_array.forEach(element => {
                            if (!_topNRs.includes(element.woow_id)) {
                                element.topNR = false;
                            } else {
                                element.topNR = true;
                            }
                        });
                        otacadlo_all_flats.update(n => tmp_array);
                        
                    }
                } else {

                    if (tmp_array != undefined && tmp_array.length > 0) {
                        tmp_array.forEach(element => {
                            element.topNR = false;
                        });

                        otacadlo_all_flats.update(n => tmp_array);
                    }
                }

                
            break;

            case "_favIDs" : 
                if (_favIDs != null &&_favIDs.length > 0 && tmp_array != null && tmp_array != undefined) {

                    if (tmp_array != undefined) {
                        tmp_array.forEach(element => {
                            if (!_favIDs.includes(element.woow_id)) {
                                element.favID = false;
                                element.compare = false;
                            } else {
                                element.favID = true;
                                element.compare = false;
                            }
                        });
                        
                        otacadlo_all_flats.update(n => tmp_array);
                    }
                }

                else {

                    if (tmp_array != undefined && tmp_array.length > 0) {
                        tmp_array.forEach(element => {
                            element.favID = false;
                        });

                        otacadlo_all_flats.update(n => tmp_array);
                    }
                }
            break;
        }
    }
    
    enable_floorplan_nav.subscribe(value => {
        _enable_floorplan_nav = value;
    });  
    is_woow_demo.subscribe(value => {
        _is_woow_demo = value;
        //////////////////////////////////////////console.log(value);
    });
    otacadlo_filteredList.subscribe(value => {
        //////////////////////////////////console.log(value);
    })
    enable_otacadlo.subscribe(value => {
        //////////////////////////////////console.log("otacadlo: " + value);
        _enable_otacadlo = value;
        ////console.log(value);
        switch (value) {
            case true: 
                flatFilterTooltip = "flat-filter";
            default : 
                flatFilterTooltip = "back-to-rotator";
            break;
        }
    });
    enable_price_filter.subscribe(value => {
        _enable_price_filter = value;
    });
    enable_status_filter.subscribe(value => {
        _enable_status_filter = value;
        ////////console.log("status filter :" + value );
    });
    enable_dispositions_filter.subscribe(value => {
        _enable_dispositions_filter = value;
    });
    enable_floor_filter.subscribe(value => {
        _enable_floor_filter = value;
    });
    enable_compare_flats.subscribe(value => {
        _enable_compare_flats = value;
    });
    enable_enter_vr_function.subscribe(value => {
        _enable_enter_vr_function = value;
    });
    enable_area_info.subscribe(value => {
        _enable_area_info = value;
    });
    enable_flat_filter.subscribe(value => {
        _enable_flat_filter = value;
        ////////////////////////////////////////console.log("_enable_flat_filter : " + value);
    });
   
    allow_rotate_to_flat.subscribe(value => {
        _allow_rotate_to_flat = value;
        ////////////////////////////////////////console.log(value);
        if (value) {
            prejst_na_byt(_otacadlo_selected_flat_all_data.woow_id, _otacadlo_selected_flat_all_data.floor);
        }
    });

    reseting_filter.subscribe(value => {
        if (value) {
            reset_filter();
        }
    });

    filtered_flats_for_svg.subscribe(value => {
        ////////////////////////////////////////////////console.log(value);
    });
    
    go_to_flat_from_compare.subscribe(value => {
        _go_to_flat_from_compare = value;
        ////////////console.log(value)

        if (value != null) {
            _otacadlo_selected_flat_all_data = value;
            visit_tour(value.woow_id);
        }
    });

    playground_view.subscribe(value => {
        _playground_view = value;
        if (value) {
            show_filter.update(n => false);
        }
        ////////////////////////////////////////console.log("_playground_view : " + value);
    });

    show_filter.subscribe(value => {
        _show_filter = value;
        ////////////////////////////////////////console.log("_show_filter : " + value);
        if (last_visited != null) {
            scroll_to_element(last_visited);
        }
    });

    otacadlo_selected_flat_all_data.subscribe(value => {
        _otacadlo_selected_flat_all_data = value;
        ////////////////////console.log(value);
        if (value != null) {
            ////////////////////////////////////////////////////////////console.log(selected_floor + " : " + value.floor);
            if (selected_floor != value.floor) {
                otacadlo_selected_floor.update(n => null);
            }

            if (selected_disposition != value.flat_disposition) {
                selected_disposition = null;
            }

            if (selected_status != value.flat_status) {
                selected_status = null;
            }

            if (value.flat_price < filtered_min_price || value.flat_price > filtered_max_price) {
                filter_price = max_price;
                filtered_min_price = min_price;
                filtered_max_price = max_price;
                values = [min_price, max_price];
            }
        }
    });

    otacadlo_to.subscribe(value => {
        _otacadlo_to = value;
    });

    otacadlo_all_flats.subscribe(value => {
        _otacadlo_all_flats = value;
    });

    cookies_compare_flats.subscribe(value => {
        _cookies_compare_flats = value;
        //otacadlo_compare_flats.update(n => value);
        
    });

    otacadlo_compare_flats.subscribe(value => {
            _otacadlo_compare_flats = value;
            //console.log(value);
        if (value.length != 0) {
            //setCookie("compare-flats", value, 365);
        }
            
    });

    vr_mode.subscribe(value => {
      _vrMode = value;
    });

    otacadlo_hover_flat.subscribe(value => {
      _otacadlo_hover_flat = value;
      ////////////////////////////////////////////////////////////////////console.log(value);
    });
    
    floor_view.subscribe(value => {
      _floor_view = value;
    });

    mobile_patch_suffix.subscribe(value => {
        _mobile_patch_suffix = value;
    });

    disabled_icons.subscribe(value => {
      dis_icon = value;
      ////////////////////////////////////////console.log(value);
      ////////////////////////////////////////////////////////////////////console.log(value);
    });

    active_scene.subscribe(value => {
        // if (value.includes("B8A")) {
        //     last_visited = "B8A";
        // }

        // if (value.includes("B3G")) {
        //     last_visited = "B3G";
        // }

        // if (value.includes("B3H")) {
        //     last_visited = "B3H";
        // }


        // if (value.includes("EXT")) {
        //     last_visited = "EXT";
        // }

        current_scene = value;
      
    });

    enable_filter.subscribe((value) => {
        _show_filter = value;
        
    });
        
    flat_filter_enabled.subscribe((value) => {
        ////////////////////console.log("_flat_filter_enabled : " + value);
        _flat_filter_enabled = value;
        //disabled_icons.update(n => value);

        switch (value) {
            case false:
                jq('#pano').removeClass("hidden");
                break;
        
            default:
                jq('#pano').addClass("hidden");
                shortcuts.update(n => false);

                if (innerWidth < 650) {
                    languages.update(n => false);
                    cart.update(n => false);
                }
                
                if (last_visited != null) {
                    ////////////////////////////////////////////////console.log(last_visited);
                    scroll_to_element(last_visited);
                }
                break;
        }

        
    });

    otacadlo_selected_floor.subscribe(value => {
        selected_floor = value;
        _otacadlo_selected_floor = value;
    });

    let old_floor = null;

    otacadlo_selected_flat.subscribe(value => {
        if (value != null) {
            last_visited = value;
            ////////////////////////////////////////////////////////////console.log(old_floor + " / " + selected_floor);
            setTimeout(() => {
                    scroll_to_element(value);  
                    old_floor = selected_floor; 
                }, 300);
            if ((!_show_filter || old_floor != selected_floor || selected_floor == null) && userdata["allow_flat_detail"] != true) {
                _show_filter = true;
                
                
                
            }

            if (_otacadlo_all_flats != null) {
                _otacadlo_all_flats.forEach(element => {
                if (element.woow_id == value) {
                    otacadlo_selected_flat_all_data.update(n => element);
                }
                });
            }
        } else {
            ////////////////////////////////////////console.log("adsdhfsh");
            //last_visited = null;
        }
        
    });

    orbit_view.subscribe(value => {
        _orbit_view = value;
        ////////////////////////////////////////console.log("_orbit_view : " + value);
    });

    building_view.subscribe(value => {
        _building_view = value;
    });

    shortcuts.subscribe(value => {
        //////////////////////////////////////////console.log(value);
        _shortcuts = value;
    });

    function enable_rotate_to_floor(value) {
        //////////////////////////////////////////////////////console.log(value);
        if (value != undefined || value != null) {
            rotate_to_floor.update(n => value);
        }
        
        enable_filter.update(n => false);
    }

    // Funkcie

    function modify_flats(id) {
        for (let index = 0; index < id.length; index++) {
            const element = id[index];
            element.compare = false;
            if (_cookies_compare_flats.includes(element.woow_id)) {
                element.compare = true;
            }
            all_floors.indexOf(element.floor) === -1 && all_floors.push(element.floor);
        }
    }  

    function toggle_compare_flat(id, parameter) {
        //toggle_compare_flat_id.update( n => null);
        //console.log(id);
        _otacadlo_all_flats.forEach(element => {
            if (element.woow_id == id) {
                element.compare = !element.compare;
                //////console.log(element);

                if (parameter) {
                    switch (element.compare) {
                        case true: 
                            
                            addNotification({
                                text: $t("components")['flat_filter']['flat'] + " " +  element.woow_id + " " + $t("components")['flat_filter']['added-to-compare'],
                                position: 'top-center',
                                removeAfter: 6000,
                                type: 'success'
                            });
                        break;

                        default: 
                            addNotification({
                                text: $t("components")['flat_filter']['flat'] + " " +  element.woow_id + " " + $t("components")['flat_filter']['removed-to-compare'],
                                position: 'top-center',
                                removeAfter: 6000,
                                type: 'error'
                            });
                        break;
                    }
                    return;
                }

                
            }
        });

        // if (_otacadlo_compare_flats.includes(id)) {
        //     otacadlo_compare_flats.update(n => _otacadlo_compare_flats.filter(m => m !== id));
        //     //////console.log("nemá tu byť");
        // } else {
        //     if (id != undefined) {
        //         otacadlo_compare_flats.update(n => [..._otacadlo_compare_flats, id]);
        //     }
            
        // }
        

        otacadlo_all_flats.update(n => _otacadlo_all_flats);
        //console.log(_otacadlo_compare_flats);
        if (_otacadlo_all_flats.length > 0 ) {
            filter_data = _otacadlo_all_flats;
            filtering_data(filter_data);
            
        }
        if (parameter) {
            setCookie("favID", id);
        }
        
    }

    // odfiltrovanie dát
    function filtering_data() {
        ////////////////////////////////////////////////////////////////console.log(filter_data);
        filteredList = filter_data;
        if (selected_status != null) {
            filteredList = filteredList.filter(flat => flat.flat_status == (selected_status));
            otacadlo_selected_status.update(n => selected_status);
        }

        if (selected_disposition !=null) {
            filteredList = filteredList.filter(flat => flat.flat_disposition == (selected_disposition));
            otacadlo_selected_rooms.update(n => selected_disposition);
        }
        
        if (selected_floor != null) {
            filteredList = filteredList.filter(flat => (flat.floor == (selected_floor)));
            otacadlo_selected_floor.update(n => selected_floor);
        } else {
            otacadlo_selected_floor.update(n => null);
        }

        
        // if (filter_price !=null) {
        //     filteredList = filteredList.filter(flat => (parseInt(flat.flat_price) <= (max_price) && flat.flat_price) >= (min_price));
        // }
        //////////////////////////////////////////////////////////////////console.log(filtered_min_price + " <|> " + filtered_max_price)
        if (filtered_min_price !=null && filtered_max_price != null) {
            filteredList = filteredList.filter(flat => (parseInt(flat.flat_price)) <= (filtered_max_price) && parseInt(flat.flat_price) >= (filtered_min_price));
            otacadlo_selected_min_price.update(n => filtered_min_price);
            otacadlo_selected_max_price.update(n => filtered_max_price);

            filtered_flats_for_svg.update(n => filteredList);

            
        }

        
            
        _otacadlo_all_flats.forEach(otacadlo => {
            if ( typeof otacadlo === 'object' ){
                otacadlo.filtered = false;
                if (filteredList.length != _otacadlo_all_flats.length) {
                    otacadlo.deactivated = true;
                }
                
                
                if (selected_status != null || selected_disposition != null || selected_floor != null || filtered_min_price != min_price || filtered_max_price != max_price ) {
                    filteredList.forEach(byt => {
                        if ( typeof byt === 'object' ){
                            if (otacadlo.woow_id == byt.woow_id) {
                                otacadlo.filtered = true;
                                otacadlo.deactivated = false;
                            }
                        } else {
                            
                            
                        }
                    });   
                }
            } else {

            }
        });

        _otacadlo_all_flats = _otacadlo_all_flats.filter(item => typeof item === 'object');

        otacadlo_all_flats.update(n => _otacadlo_all_flats);
        otacadlo_filteredList.update(n => filteredList);
        
    }

    function reset_filter() {
        selected_floor = null;
        selected_disposition = null;
        selected_status = null;
        otacadlo_selected_rooms.update(n => null);
        otacadlo_selected_status.update(n => null);
        otacadlo_selected_min_price.update(n => null);
        otacadlo_selected_max_price.update(n => null);
        filter_price = max_price;
        filtered_min_price = min_price;
        filtered_max_price = max_price;
        values = [min_price, max_price];
        active_filter = null;
        otacadlo_compare_flats.update(n => []);
        //setCookie("compare-flats", "", 365);
        
        otacadlo_compare_flats.update(n => []);
        //////////////////////////////////////////////////console.log(_otacadlo_all_flats);
        
        
        _otacadlo_all_flats.forEach(element => {
            if ( typeof element === 'object' ){
                element.compare = false;
                element.filtered = false;
                element.deactivated = false;
            }    
        });



        // filteredList.forEach(element => {
        //     element.compare = false;
        // });
        filter_data.forEach(element => {
            if ( typeof element === 'object' ){
                element.compare = false;
            }    
            
        });

        filtering_data(filteredList);
        last_visited = null;
        otacadlo_selected_flat.update( n => null);
        otacadlo_selected_flat_all_data.update( n=> null);

        otacadlo_all_flats.update(n => _otacadlo_all_flats);
        reseting_filter.update(n => false);

        otacadlo_filteredList.update( n => null);
    }

    //otacadlo_all_flats.update(n => _tmp_flat_object);
    otacadlo_floors.update(n => all_floors.sort());
    
    // sitahnutie dát z API PENTY
    async function getPentaData() {
        
        let data = {id : null};

        const res = await fetch(
            userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "json/data.json",{
                method: "POST",
                body: JSON.stringify(data),
            }
        );

        if (res.ok) {
        const json = await res.json();    
        //console.log(json);
        // prevod hodnoty boolean
        
        let tmp = [];
        tmp = json;
        _tmp_flat_object = json;  

        let tmp_array = _tmp_flat_object;

        if (typeof tmp_array === 'object' && tmp_array !== null) {
            tmp_array = [Object.values(tmp_array)];
        }
        ////////////////////console.log(tmp_array);
            
        tmp_array.forEach(element => {
            element.favID = false;
            element.topNR = false;
        });
            

            
        ////////////////////////////////////////console.log(last_visited)      ;

        // konvertovanie objektu na pole
        filter_data = Object.values(json);

        ////console.log(_favIDs);

        // zistenie parametrov pre filter
        filter_data.forEach(element => {
            ////////////////////////////////////console.log(element);
            if (parseInt(element.flat_price) > max_price) {
                max_price = parseInt(element.flat_price);
                filter_price = max_price;
                filtered_max_price = max_price;
            }
            if (parseInt(element.flat_price) < min_price) {
                min_price = parseInt(element.flat_price);   
                filtered_min_price = min_price;
            }

            // zistenie všetkých dispozícií
            if (element.flat_disposition != null && element.flat_disposition != "" && element.flat_disposition != "undefined") {
                if (all_dispositions.indexOf(element.flat_disposition) === -1) all_dispositions.push(element.flat_disposition);
            }

            

            
            
            // zistenie všetkých poschodí
            if (element.floor != undefined) {
                if (element.floor != undefined && element.floor != null) {
                //////////////////////////////////////////console.log(element.floor);
                    if (all_floors.indexOf(element.floor) === -1) all_floors.push(element.floor);
                }
            }

            //////////////////////////////////////////console.log(all_floors);

            // zistenie všetkých dostupností
            if (element.flat_status != null && element.flat_status != "" && element.flat_status != undefined) {
                if (all_status.indexOf(element.flat_status) === -1) all_status.push(element.flat_status);
            }
        });

        all_dispositions = all_dispositions.sort();
        all_floors = all_floors.sort((a, b) => {
            const numA = parseInt(a);
            const numB = parseInt(b);
            if (numA < numB) return -1;
            if (numA > numB) return 1;
            return 0;
        });
        ////////////////////////////////////////////console.log(all_floors);
        all_status = all_status.sort();

        otacadlo_all_status.update(n => all_status);
        

        values = [min_price, max_price];

        otacadlo_all_flats.update(n => filter_data);

        ////console.log("teraz prvý krát");
        checkCookiesAndAddToFlat("_favIDs");
        checkCookiesAndAddToFlat("_topNRs");
        //////////////////////console.log(filter_data);
        flat_filter_data_loaded = true;
        filtering_data();

       
        if (_favIDs != null) {
            _favIDs.forEach(element => {
                _otacadlo_all_flats.forEach(byt => {
                    if (byt.woow_id == element) {
                        otacadlo_compare_flats.update(n => [...n, element]);
                        
                    }
                });
               
                
            });
        }

        // filter_data.forEach(element => {
        //     if (element.woow_id) {
        //         if( all_dispositions.indexOf(element.flat_disposition) === -1) {
        //             all_dispositions.push(element.flat_disposition);
        //         }

        //         if( all_status.indexOf(element.flat_status) === -1) {
        //             all_status.push(element.flat_status);
        //         }

        //         if( all_floors.indexOf(element.floor[0]) === -1) {
        //             all_floors.push(element.floor[0]);
        //         }
        //     }
            
        // });

        

      } else {
        throw new Error(data);
      }
  	}

    // Stiahnutie dát
    getPentaData();
    // po zmene hodnoty vo filtri odfiltruj byty
    $ : {
        selected_floor, selected_status, selected_disposition, filter_price, filtered_min_price, filtered_max_price;

        // ak sú už načítané dáta z API
        if( flat_filter_data_loaded) {
            filtering_data();
        }
        
    }

    function visit_tour($last_visited) {
        //////////////console.log($last_visited);
        if (_enable_otacadlo != null && Otacadlo != null) {
            enable_otacadlo.update(n => false);
            flatDetail.update( n=> false);
        }
            
        otacadlo_selected_flat.update(n => $last_visited);
        run_visit_tour.update(n => false);
        flat_filter_enabled.update(n=> false);
        show_filter.update( n=> false);
        if (should_enable_disposition && _dispositions_enabled == null) {
            dispositions_enabled.update(n => true);
        }

        let flatFound = false;
        ////////////console.log($last_visited + " vs " + sample_flat.name);
        fakeFlats.forEach(byt => {
            if (byt.flat_id == $last_visited && byt.sample_flat_id == sample_flat.name) {
                flatFound = true;
                return;
            }
        });


        if (!flatFound && $last_visited != sample_flat.name ) {
            
            let params = new URLSearchParams(window.location.search);
            if (params.has('r')) {
                params.set('r', "1");
            }
            window.history.replaceState({}, '', `${window.location.pathname}?${params}`);

            function changeUrl(parameter) {
                // Vytvoří nový objekt URLSearchParams
                let params = new URLSearchParams(window.location.search);
                
                // Přidá nový parametr do URL
                if (!params.has('project')) {
                    ////////////////////////////console.log("nie");
                    params.append('project', parameter);
                } else {
                    params.set('project', parameter);
                    ////////////////////////////console.log("áno");
                }

                if (!params.has('filter')) {
                    params.append('filter', true);
                } else {
                    params.set('filter', true);
                }
                
                // Aktualizuje URL s novým parametrem
                window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
                // Následný refresh stránky
                location.reload();
            }

            if (isMobile) {
                changeUrl($last_visited);
            } else {
                changedFloor.update(n => _otacadlo_selected_flat_all_data.floor);
                changed_flat.update( n => $last_visited);
            }
        } else {
            ////////////console.log("byt je iný, ale rodič je rovnaký, neprepínam");
            changedFloor.update(n => _otacadlo_selected_flat_all_data.floor);
            var krpano = document.getElementById('krpanoSWFObject');
            
            krpano.set("floors", verifiyFloor(_otacadlo_selected_flat_all_data.floor));
            krpano.call("loadscene(" + current_scene + ", null, KEEPVIEW, BLEND(1))");

            
        }
    }

    function go_to_tour(id) {
        //////////////////////////////////////////console.log(current_scene + " " + id);
        if (sample_flat.name == id) {
            enable_otacadlo.update(n => false);
            run_visit_tour.update(n => false);
            flat_filter_enabled.update( n=> false);
            enable_filter.update(n => false);
        } else {
            location.href = userdata['base_path'] + '?project=' + id;
        }
    }

    run_visit_tour.subscribe(value => {
        if (value) {
            visit_tour(last_visited);
        }
    });

      // Akcia po kliku na filter
    function select_filter($value) {
        if (active_filter == $value) {
            active_filter = null;
        } else {
            active_filter = $value;
        }
    }

    function scroll_to_element($id) {
        ////////////////////////////////////////////////console.log("skúšam scrollnuť");
        let container = document.getElementById("flat-items");
        let anchor = document.getElementById($id);
        let margin = -230;
        if (filtered_max_price < 1) {
            margin = -162;
        }
        if (anchor != null && container != null) {
            container.scrollTo({
                top: anchor.offsetTop + margin,
                behavior: 'smooth'
            });
        } else {
            setTimeout(() => {
               scroll_to_element($id);
            }, 200);
        }

    }

    function compare_flats() {

        if (_otacadlo_compare_flats.length > 1) {
            modal_type.update(n => 'flat-compare');
            modal.update(n => true);
            flat_filter_enabled.update(n => false);
            ////////////////////////////////////////////console.log("asgahg");
            ////////////////////////////////////////////console.log(_otacadlo_compare_flats);
            
        }
    }

    function sort_flats(parameter) {
        switch (parameter) {
            case "rooms":
                sort_rooms = !sort_rooms;
                switch (sort_rooms) {
                    case true:
                        filteredList = filteredList.sort((a,b)=>a.flat_disposition-b.flat_disposition);
                        break;
                
                    default:
                        filteredList = filteredList.sort((a,b)=>b.flat_disposition-a.flat_disposition);
                        break;
                }
            break;

            case "stock":
                sort_stock = !sort_stock;
                switch (sort_stock) {
                    case true:
                        
                        filteredList = filteredList.sort((a,b)=>a.floor-b.floor);
                        break;
                
                    default:
                        filteredList = filteredList.sort((a,b)=>b.floor-a.floor);
                        break;
                }
            break;

            case "status":
                sort_status = !sort_status;
                switch (sort_status) {
                    case true:
                        filteredList = filteredList.sort((a,b)=>a.flat_status-b.flat_status);
                        break;
                
                    default:
                        filteredList = filteredList.sort((a,b)=>b.flat_status-a.flat_status);
                        break;
                }
            break;

            case "price":
                sort_price = !sort_price;
                switch (sort_price) {
                    case true:
                        filteredList = filteredList.sort((a,b)=>a.flat_price-b.flat_price);
                        break;
                
                    default:
                        filteredList = filteredList.sort((a,b)=>b.flat_price-a.flat_price);
                        break;
                }
            break;
        }
    }

    function update_range_slider(e) {
        filtered_min_price = e.values[0];
        filtered_max_price = e.values[1];
    }

    function zapni_flat_fíilter() {
        //////console.log("asgadheaha");
        //flatDetail.update(n => false);
        //disabled_icons.update( n =>false); 
        //////////////////////////////console.log("flat_filter");
        if (Otacadlo != null) {
            enable_otacadlo.update(n => true);
            
        }
        
        flat_filter_enabled.update(n => true);
        enable_filter.update( n=> true);
    }

    function prejst_na_byt(byt, floor, flat) {
        if (_otacadlo_selected_flat_all_data == null || _otacadlo_selected_flat_all_data != flat.woow_id) {
            otacadlo_selected_flat.update(n => flat.woow_id)
            //otacadlo_to.update(n=> flat);
            otacadlo_selected_flat_all_data.update( n=> flat);
        }
        enable_filter.update(n => false);
        rotate_to_floor.update(n => byt);
        //change_floor.update(n => floor);
        allow_rotate_to_flat.update(n => false);
        enable_flat_filter.update( n=> false);
        //otacadlo_selected_floor.update(n => floor);

        

    }

    function select_flat_from_filter(flat, data, not_null) {
        ////////////////////console.log(not_null);
        if (_otacadlo_selected_flat_all_data != null && _otacadlo_selected_flat_all_data.woow_id == flat && not_null) {
            last_visited = null;
            otacadlo_selected_flat.update( n => null);
            otacadlo_selected_flat_all_data.update( n=> null);
        } else {
            otacadlo_selected_flat.update(n => flat)
            otacadlo_to.update(n=> flat);
            otacadlo_selected_flat_all_data.update( n=> data);
        }
    
        
    }

    function zobrazBytNaPoschodi(byt) {
        otacadlo_selected_flat.update( n => byt.woow_id);
        otacadlo_selected_flat_all_data.update( n => byt);
        ////////////////console.log(byt);
        showFlatOnFloor.update(n => byt);
    }
</script>

<div id="woow-flat-filter">

    {#if flat_filter_data_loaded && _flat_filter_enabled && _enable_flat_filter && !_flat_not_found && !flatDetailEnabled} 
    <!-- {#if _flat_filter_enabled}  -->
        <div id="appartments" class:active={_flat_filter_enabled == true && _show_filter && _floor_view == false}>
            <div class="header">
                <h1>{$t("components")['flat_filter']['title']}</h1>
                <!-- <div class="close" on:click={() => flat_filter_enabled.update(n => false)} /> -->
                <div class="close" on:click={() => enable_filter.update(n => false)} on:click={() => flat_filter_enabled.update(n => false)} />
            </div>
            <div class="options">
                <div class="list-options" class:two_list={!_enable_floor_filter || !_enable_status_filter || !_enable_dispositions_filter}>
                <div class="poschodie">
                    <div class="title">{$t("components")['flat_filter']['floor']}
                        <span class="filtering" on:click={() => sort_flats("stock")}>
                            <i class="fa-solid fa-sort"></i>
                        </span>
                    </div>
                    {#if selected_floor == null}
                        <div class="zoznam" on:click={() => select_filter("floor")}>{$t("components")['flat_filter']['all']}</div>    
                    {:else}
                        <div class="zoznam" on:click={() => select_filter("floor")}>
                            {#if selected_floor == 0 && userdata["assets_folder"].includes("strabag")}
                                {$t("components")['flat_filter']['0']}
                            {:else}
                                {selected_floor}
                            {/if}
                                
                        </div>
                    {/if}
                    
                    <ul class="options-list poschodie-list" class:active={active_filter === "floor"}>
                        <li on:click={() => selected_floor = null}
                            on:click={() => select_filter("floor")}>{$t("components")['flat_filter']['all']}</li>
                        {#each all_floors as floor}
                            <li on:click={() => selected_floor = floor} 
                                on:click={() => select_filter("floor")}
                                class:active={selected_floor == floor}>
                                {#if floor == 0 && userdata["assets_folder"].includes("strabag")}
                                    {$t("components")['flat_filter']['0']}
                                {:else}
                                    {floor}
                                {/if}
                            </li>
                        {/each}
                    
                    </ul>
                </div>
                <div class="pocet-izieb">
                    <div class="title">{$t("components")['flat_filter']['room-count']}
                        <span class="filtering" on:click={() => sort_flats("rooms")}>
                            <i class="fa-solid fa-sort"></i>
                        </span>
                    </div>
                    {#if selected_disposition == null}
                        <div class="zoznam" on:click={() => select_filter("disposition")}>{$t("components")['flat_filter']['all']}</div>    
                    {:else}
                        <div class="zoznam" on:click={() => select_filter("disposition")}>{selected_disposition}</div>
                    {/if}
                    <ul class="options-list pocet-izieb-list" class:active={active_filter === "disposition"}>
                        <li on:click={() => selected_disposition = null}
                            on:click={() => select_filter("disposition")}>{$t("components")['flat_filter']['all']}</li>
                        {#each all_dispositions as room}
                            <li on:click={() => selected_disposition = room} 
                                on:click={() => select_filter("disposition")}
                                class:active={selected_disposition == room}>{room}</li>
                        {/each}
                    </ul>
                </div>

                <!-- ak je povolený filter pre status -->
                {#if _enable_status_filter}
                    <div class="orientacia">
                        <div class="title">{$t("components")['flat_filter']['status']}
                            <span class="filtering" on:click={() => sort_flats("status")}>
                                <i class="fa-solid fa-sort"></i>
                            </span>
                        </div>
                        {#if selected_status == null}
                            <div class="zoznam" on:click={() => select_filter("status")}>{$t("components")['flat_filter']['all']}</div>    
                        {:else}
                            <div class="zoznam" on:click={() => select_filter("status")}>{$t("components")['flat_filter'][selected_status]}</div>
                        {/if}
            
                        <ul class="options-list orientacia-list" class:active={active_filter === "status"}>
                            <li on:click={() => selected_status = null}
                                on:click={() => select_filter("status")}>{$t("components")['flat_filter']['all']}</li>
                            {#each all_status as item}
                                <li on:click={() => selected_status = item}
                                    on:click={() => select_filter("status")}
                                    class:active={selected_status == item}>{$t("components")['flat_filter'][item]}</li>
                            {/each}
                        </ul>
                    </div>
                {/if}
                
                </div>
                {#if _enable_price_filter && max_price > 0}
                    <div class="price-range">
                        <div class="title">{$t("components")['flat_filter']['price-range']}: <span> {formatter.format(filtered_min_price)} - {formatter.format(filtered_max_price)}</span> <span class="filtering" on:click={() => sort_flats("price")}>
                            <i class="fa-solid fa-sort"></i>
                        </span></div>
                        <!-- <input type="range" min={min_price} max={max_price} class="slider" bind:value={filter_price} id="myRange" on:input="{() => filtering_data(filteredList)}"> -->
                    </div>
                    <div class="range-wrapper">
                        <RangeSlider all="pip" suffix=" €"pipstep={1000000} range={true} first="min" last="max" bind:values bind:min={min_price} bind:max={max_price} step={1000} on:change={(e) => { update_range_slider(e.detail) }}/>
                    </div>
                    <div class="labels">
                        <!-- <div class="label">{formatter.format(min_price)}</div>
                        <div class="label">{formatter.format(max_price)}</div> -->
                    </div>
                {/if}
                
                <div class="button-options">
                    <div class="btn-opt last-visited" class:active={last_visited !== null} on:click={() => scroll_to_element(last_visited)}>{$t("components")['flat_filter']['last-visited']}</div>
                    <div class="btn-opt reset" on:click={() => reset_filter()}>{$t("components")['flat_filter']['reset']}</div>
                    <div class={_otacadlo_compare_flats.length > 1 ? 'btn-opt btn-compare active' : 'btn-opt btn-compare'} on:click={() => compare_flats()}>{$t("components")['flat_filter']['compare']}
                        {#if _otacadlo_compare_flats.length > 0}
                            ({_otacadlo_compare_flats.length})
                        {/if}
                    </div>
                </div>
            </div>
        
        <div id="flat-items" class="flat-items" class:price_off={!_enable_price_filter || max_price < 1}>
            {#if _is_woow_demo}
                <div id="B8A" class="{(last_visited == "B8A") ? 'active flat' : 'flat'}" class:hovered={_otacadlo_hover_flat == "B8A"}>
                    {#if _enable_enter_vr_function}
                        <div class="enter-vr" on:click={() => visit_tour("B8A")}></div> 
                    {/if}
                    <div class="title">B8A
                        <span class="reserved">({$t("components")['flat_filter']["reserved"]})</span>
                    </div>
                    {#if _enable_price_filter}
                        <div class="price">{formatter.format(140000)}</div>
                    {/if}
                    <div class="units">
                        {#if _enable_area_info}
                            <div class="unit plocha">
                                <div class="hodnota">100 m<sup>2</sup></div>
                                <div class="subtitle">{$t("components")['flat_filter']['floor-size']}</div>
                            </div>
                        {/if}
                        
                        <div class="unit">
                            <div class="hodnota">2</div>
                            <div class="subtitle">{$t("components")['flat_filter']['floor']}</div>
                        </div>
                        <div class="unit izby">
                            <div class="hodnota">3+kk</div>
                            <div class="subtitle">{$t("components")['flat_filter']['room-count']}</div>
                        </div>
                    </div>
                </div>

                <div id="B3G" class="{(last_visited == "B3G") ? 'active flat' : 'flat'}" class:hovered={_otacadlo_hover_flat == "B3G"}>
                    {#if _enable_enter_vr_function}
                        <div class="enter-vr" on:click={() => visit_tour("B3G")}></div> 
                    {/if}
                    
                    
                    <div class="title">B3G<span class="reserved">({$t("components")['flat_filter']["reserved"]})</span></div>
                    {#if _enable_price_filter}
                        <div class="price">{formatter.format(140000)}</div>
                    {/if}
                    <div class="units">
                        {#if _enable_area_info}
                            <div class="unit plocha">
                                <div class="hodnota">120 m<sup>2</sup></div>
                                <div class="subtitle">{$t("components")['flat_filter']['floor-size']}</div>
                            </div>
                        {/if}
                        
                        <div class="unit">
                            <div class="hodnota">4</div>
                            <div class="subtitle">{$t("components")['flat_filter']['floor']}</div>
                        </div>
                        <div class="unit izby">
                            <div class="hodnota">3+kk</div>
                            <div class="subtitle">{$t("components")['flat_filter']['room-count']}</div>
                        </div>
                    </div>
                </div>

                <div id="B3H" class="{(last_visited == "B3H") ? 'active flat' : 'flat'}" class:hovered={_otacadlo_hover_flat == "B3H"}>
                    {#if _enable_enter_vr_function}
                        <div class="enter-vr" on:click={() => visit_tour("B3H")}></div>                      
                    {/if}
                    
                    <div class="title">B3H<span class="reserved">({$t("components")['flat_filter']["reserved"]})</span></div>
                    {#if _enable_price_filter}
                        <div class="price">{formatter.format(160000)}</div>
                    {/if}                
                    <div class="units">
                        {#if _enable_area_info}
                            <div class="unit plocha">
                                <div class="hodnota">140 m<sup>2</sup></div>
                                <div class="subtitle">{$t("components")['flat_filter']['floor-size']}</div>
                            </div>
                        {/if}
                        
                        <div class="unit">
                            <div class="hodnota">3</div>
                            <div class="subtitle">{$t("components")['flat_filter']['floor']}</div>
                        </div>
                        <div class="unit izby">
                            <div class="hodnota">3+kk</div>
                            <div class="subtitle">{$t("components")['flat_filter']['room-count']}</div>
                        </div>
                    </div>
                </div>
            {/if}
            
            {#each filteredList as flat}
                {#if flat.woow_id && (flat.flat_hidden == undefined || flat.woow_type == "office-full")}
                    <div id={flat.woow_id} class="{(last_visited == flat.woow_id) ? 'active flat' : 'flat'}" class:hovered={_otacadlo_hover_flat == flat.woow_id} 
                    on:mouseenter={() => otacadlo_hover_flat.update(n => flat.woow_id)}
                    on:mouseleave={() => otacadlo_hover_flat.update(n => null)}>    
                        {#if _enable_enter_vr_function && flat.woow_sample_flat_id != ""}
                            
                            <div class="enter-vr" on:click={() => visit_tour(flat.woow_id)}></div> 
                        {/if}
                        
                        <!-- <div class="tooltip tooltip-vr">Zobraziť prehliadku</div> -->
                        {#if _enable_floorplan_nav}
                            <div class="compare" 
                                
                                on:click={() => zobrazBytNaPoschodi(flat)}
                                
                                ></div> 
                        {/if}
                        
                        <!-- <div class="tooltip tooltip-compare">Pridať do porovnania</div> -->
                        
                        <div class="back_flat"
                        on:click={() => select_flat_from_filter(flat.woow_id, flat, false)}    
                        ></div>
                        <div class="title">{flat.woow_id}
                            {#if _enable_compare_flats}
                                {#if _favIDs != null &&_favIDs.includes(flat.woow_id)}
                                    <i class="fa-solid fa-code-compare selected" 
                                    on:click={() => toggle_compare_flat(flat.woow_id, true)}></i>
                                {:else}
                                    <i class="fa-solid fa-code-compare" 
                                    
                                    on:click={() => toggle_compare_flat(flat.woow_id, true)}></i>
                                {/if}
                            {/if}
                            {#if _enable_status_filter}
                                    <span class="{flat.flat_status}">({$t("components")['flat_filter'][flat.flat_status]})</span>
                            {/if}
                        </div>

                        {#if _enable_price_filter}
                            <div class="price"
                                on:click={() => select_flat_from_filter(flat.woow_id, flat, false)} 
                                on:click={() => flat_3D.update( n => true)}   
                            >   
                            {#if flat.flat_price == null || flat.flat_price == "" || flat.flat_price == 0 || flat.flat_price == "0"}
                                    {$t("components")['flat_filter']['call-for-price']}
                            {:else}
                                {formatter.format(flat.flat_price)}
                            {/if}
                                
                            </div> 
                        {/if}
                        
                        <div class="units" class:price_off={!_enable_price_filter} on:click={() => select_flat_from_filter(flat.woow_id, flat)}>
                            {#if _enable_price_filter}
                                <div class="unit plocha" on:click={() => select_flat_from_filter(flat.woow_id, flat, false)}>
                                    <div class="hodnota">{flat.flat_area} m<sup>2</sup></div>
                                    <div class="subtitle">{$t("components")['flat_filter']['floor-size']}</div>
                                </div>
                            {/if}
                            
                            <div class="unit" on:click={() => select_flat_from_filter(flat.woow_id, flat, false)}>
                                <div class="hodnota">
                                    {#if flat.floor == 0 && userdata["assets_folder"].includes("strabag")}
                                        {$t("components")['flat_filter']['0']}
                                    {:else}
                                        {flat.floor}
                                    {/if}
                                </div>
                                <div class="subtitle">{$t("components")['flat_filter']['floor']}</div>
                            </div>
                            <div class="unit izby" on:click={() => select_flat_from_filter(flat.woow_id, flat, false)}>
                                <div class="hodnota">{flat.flat_disposition}</div>
                                <div class="subtitle">{$t("components")['flat_filter']['room-count']}</div>
                            </div>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>    

        </div>
    {/if}

    {#if _building_view && !_flat_not_found}
        {#if _vrMode == false}
            {#if (!dis_icon && !_flat_filter_enabled || !_show_filter) && !_orbit_view && !_floor_view && !_playground_view && _enable_flat_filter && !_shortcuts && !flatDetailEnabled }
                {#if userdata["tooltips"] == true && _enable_otacadlo == true}
                    <div id="appartments-icon" class="appartments-icon {_enable_otacadlo}"  class:active={dis_icon == true && flat_filter_enabled} 
                        on:click={() => zapni_flat_fíilter()}
                        use:tooltip={{content: $t("components")['tooltips']['flat-filter'],  position: 'right', align: 'center', animation: get(tooltipAnimation), theme: 'tooltip-woow'}}
                    ></div>
                {:else if userdata["tooltips"] == true && _enable_otacadlo == false}
                    <div id="appartments-icon" class="appartments-icon {_enable_otacadlo}"  class:active={dis_icon == true && flat_filter_enabled} 
                        on:click={() => zapni_flat_fíilter()}
                        use:tooltip={{ content: $t("components")['tooltips']['back-to-rotator'],  position: 'right', align: 'center', animation: get(tooltipAnimation), theme: 'tooltip-woow'}}
                    ></div>
                {:else}
                    <div id="appartments-icon" class="appartments-icon {_enable_otacadlo}"  class:active={dis_icon == true && flat_filter_enabled} 
                            on:click={() => zapni_flat_fíilter()}
                    ></div>
                {/if}
                
                
            {/if}
        {/if}
    {/if}
    

</div>

<style lang="scss">
    //   variables
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";

    #woow-flat-filter{
    .rangeSlider.range{
        height: 4px !important ;
    }

    .rangeBar{
        height: 4px !important ;
    }

    .range-wrapper {
        background: var(--secondary-color);
        padding: 3px 0;
        border: 1px solid var(--main-color-light20);
        border-radius: 6px;
    }
  
    #appartments {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 320px;
        height: calc(100% - 32px);
        @include blur;
        border-radius: $radius-M;
        z-index: 10;
        display: block;
        overflow: hidden;
        left: -320px;
        opacity: 0;
        transition: all 0.25s ease-in-out !important;

        &.active {
            opacity: 1;
            left: 72px;
        }

        .header {
        padding: 16px 16px 2px 16px;
        @include underline-2-a;

        h1 {
            @include rubik-m20;
            padding-right: 28px;
            margin-bottom: 8px;
        }
        }
        .options {
        margin: 4px 16px 0;
        @include underline-1-ar;
        .list-options {
            display: flex;
            justify-content: space-between;
            @include rubik-r12;

            &.two_list {
                justify-content: start;
                gap: 16px;
            }

            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                span {
                    cursor: pointer;
                }
            }
        }
        .zoznam {
            border: 1px solid var(--primary-color-light20);
            border-radius: $radius-S;
            text-align: center;
            padding: 2px 12px;
            cursor: pointer;
            transition: 0.25s;
            font-size: 11px;
            &.active {
            &::after {
                transform: rotate(0);
            }
            }
            &::after {
            content: "";
            width: 10px;
            height: 6px;
            margin: 0 0 1px 7px;
            background-color: var(--primary-color);
            mask-size: contain;
            mask-repeat: no-repeat;
            transform: rotate(180deg);
            display: inline-block;
            }
        }
        .button-options {
            @include rubik-r12;
            font-size: 11px !important;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            margin-top: 12px;

            .btn-opt {
            height: 26px;
            border-radius: $radius-S;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 10px;
            cursor: pointer;

            &.last-visited, &.btn-compare {
                background-color: var(--primary-color-light20);
                color: var(--secondary-color);
                cursor: default;
                &.active {
                background-color: var(--primary-color);
                cursor: pointer;
                }
            }
            &.btn-compare {
                cursor: default;
                &.active {
                    background-color: var(--main-color);
                    cursor: pointer;
                }
            }
            &.reset {
                background-color: transparent;
                border: 1px solid var(--primary-color-light20);
            }
            }
        }
        }
        .flat-items {
        height: calc(100% - 242px);
        overflow: auto;
        padding-bottom: 16px;

        &.price_off {
            height: calc(100% - 178px);
        }
        .flat {
            position: relative;
            border: 1px solid var(--primary-color-light20);
            border-radius: $radius-S;
            margin: 8px 16px 0 16px;
            padding: 8px 16px;
            min-height: 93px;
            cursor: pointer;

            &.hovered {
                background-color: var(--primary-color-light005);
                border-color: var(--primary-color-light10);
            }

            .back_flat {
                position: absolute;
                width: 100%;
                height: 95px;
                z-index: 0;
                //background-color: red;
                left: 0;
                top: 0;

            }

            .title{
                display: flex;
                align-items: center;
                width: max-content;

                i {
                    color: var(--primary-color);
                    margin-left: 10px;
                    background: var(--secondary-color);
                    padding: 5px;
                    border-radius: 6px;
                    border: 1px solid var(--primary-color);
                    font-size: 14px;

                    &.active {
                        color: var(--main-color);
                        border: 1px solid var(--main-color);
                    }

                    &.selected{
                        color: var(--secondary-color);
                        background: var(--main-color);
                        border: 1px solid var(--main-color);
                    }
                }
            }

            .enter-vr, .compare {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 32px;
            height: 32px;
            border: 1px solid var(--primary-color);
            background-color: var(--secondary-color);
            border-radius: $radius-S;
            // background-color: var(--primary-color);
            z-index: 2;

            &::after {
                content: "";
                width: 19px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: var(--primary-color);
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;
            }

            
            }

            .compare {
                top: unset;
                bottom: 8px;

                &.selected {
                    border: 1px solid var(--main-color);
                    background-color: var(--main-color);
                    &::after{
                        background-color: var(--secondary-color);
                    }
                }
            }

            // .tooltip {
            //     background-color: var(--main-color);
            //     color: var(--secondary-color);
            //     position: absolute;
            //     z-index: 12;
            //     left: 70px;
            //     top: 21px;
            //     border-radius: 6px;
            //     padding: 6px 10px;

            //     &::before {
            //         content: '';
            //         display: inline-block;
            //         width: 0;
            //         height: 0;
            //         border-style: solid;
            //         border-width: 8px 16px 8px 0;
            //         border-color: transparent var(--main-color) transparent transparent;
            //         position: absolute;
            //         left: -12px;
            //     }
            // }
            
            .title, .price, .units {
                position: relative;
                z-index: 1;
                
            }

            .title {
            @include rubik-m16;
            display: flex;

                span {
                    @include rubik-r12;
                    display: flex;
                    align-items: center;

                    &::before{
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-color: var(--primary-color-light20);
                        border-radius: 50%;
                        display: block;
                        margin: 0 4px 0 6px;

                    }

                    &.free{
                        &::before{
                            background-color: $success;
                        }
                    }

                    &.reserved{
                        &::before{
                            background-color: $warning;
                        }
                    }

                    &.sold{
                        &::before{
                            background-color: $danger;
                        }
                    }
                    &.pre-reserved{
                        &::before{
                            background-color: $info;
                        }
                    }
                    &.not-for-sale{
                        &::before{
                            background-color: var(--primary-color-light60);
                        }
                    }
                }
            }
            .price {
            @include rubik-r12;
            width: max-content;
            }
            .units {
            display: flex;

            &.price_off {
                margin-top: 18px;
            }
            .unit {
                margin-right: 16px;
                .hodnota {
                    @include rubik-m12;
                    height: 20px;
                }
                .subtitle {
                font-size: 10px;
                }
            }
            }
            &.active {
            color: var(--main-color);
            border: 1px solid var(--main-color-light20);
            background-color: var(--main-color-light005);

            .title{
                i:not(.selected){
                    color: var(--main-color);
                    border: 1px solid var(--main-color);
                }
            }
            .enter-vr, .compare {
                border: 1px solid var(--main-color);
                &::after {
                background-color: var(--main-color);
                }
                &.selected{
                    &::after{
                        background-color: var(--secondary-color);
                    }
                }
            }
            }
        }
        }
        .pocet-izieb,
        .poschodie,
        .orientacia {
        position: relative;
        z-index: 500;
        width: 31%;
        }
        .options-list {
        display: none;
        border: 1px solid var(--primary-color-light20);
        border-radius: $radius-S;
        position: absolute;
        background-color: var(--secondary-color);
        width: 100%;
        margin-top: 4px;
        z-index: 500;
        overflow: hidden;
        list-style: none;
        padding: 0;

        li {
            cursor: pointer;
            text-align: center;
            padding-top: 4px;
            &:last-child {
            padding-bottom: 4px;
            }
            &:not(:last-child)::after {
            content: "";
            position: relative;
            width: 100%;
            height: 1px;
            background-color: var(--primary-color-light20);
            display: block;
            margin-top: 4px;
            left: 50%;
            transform: translateX(-50%);
            }

            &.active {
                background-color: var(--primary-color-light10);
            }
        }
        &.active {
            display: block;
        }
        }
    }

    .appartments-icon, .arrow-close {
        width: 40px;
        height: 40px;
        @include blur;
        position: absolute;
        left: 16px;
        border-radius: $radius-S;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 3;
        &::after {
            content: '';
            display: block;
            background-color: var(--primary-color);
            mask-repeat: no-repeat;

        }
    }

    .arrow-close {
        visibility: visible;
        top: 50%;
        transform: translateY(-50%);
        &::after {
            width: 8px;
            height: 14px;
            transform: rotate(180deg);
        }
    }

    .appartments-icon {
        bottom: 16px;
        &::after {
            width: 24px;
            height: 24px;
        }

        &.true{
            &::after {
            width: 24px;
            height: 24px;
        }
        }

        &.active {
            display: none;
        }

        
    }

    #appartments-icon {

        &.hide-blur {
            display: none;
        }

    }

    //  ------------ HOVER ------------------------------------------------------------------------------------------------

    @media (hover: hover) and (pointer: fine) {
        .flat {
            &:hover {
                background-color: var(--primary-color-light005);
            }
        }
        .pocet-izieb,
        .poschodie,
        .orientacia {
            li {
                &:hover {
                    background-color: var(--primary-color-light10);
                }
            }
        }

        #appartments {
        .options {
            .button-options {
                .btn-opt {
                    &.reset {
                        &:hover {
                            background-color: var(--primary-color-light10);
                        }
                    }
                }
            }
        }
    }

    }
    //  ------------ SCREEN ------------------------------------------------------------------------------------------------

    @media (max-width: 480px) {
        #appartments {
            &.active{
        left: 16px;
    }
        }
        .arrow-close {
        visibility: hidden;
    }
    }
}
</style>
