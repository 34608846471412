<script context="module">
    import Device from 'svelte-device-info';
    import { Lightbox } from 'svelte-lightbox';
  </script>

<script>
    //console.log('this device is ' + (Device.isMobile ? '' : 'not') + ' mobile');
    import { otacadlo_img, otacadlo_img_active_floor, shortcuts, sidebar, show_floorplan, modal } from "../store.js";
    import { t, locale, locales } from "../i18n.js";
    import { cubicInOut } from 'svelte/easing';

    import Panzoom from "@panzoom/panzoom";
    let zoomable, container, moveto;

    function waitForElm(selector) {
        return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        });
    }
    export let userdata;

    let _otacadlo_img, _shortcuts, _sidebar, _floorplan, _modal = false;
    let static_img_src = null;
    let active_floor = 0;

    otacadlo_img.subscribe(value => {
        _otacadlo_img = value;

        if (value) {
            waitForElm("#static-rotator").then((elm) => {
                const elem = document.getElementById("static-rotator");
                const panzoom = Panzoom(elem, {
                maxScale: 5,
                minScale: 1.0,
                });

                panzoom.zoom(1), { animate: true };
                panzoom.pan(0, 0), { animate: true };
                // panzoom.pan(10, 10)
                // panzoom.zoom(2, { animate: true });

                // elem.addEventListener('panzoomchange', (event) => {
                //   //console.log(event.detail) // => { x: 0, y: 0, scale: 1 }
                // })

                elem.parentElement.addEventListener("wheel", panzoom.zoomWithWheel);
                moveto = function ($x, $y) {
                
                panzoom.zoom(1, { animate: true });
                panzoom.pan($x, $y, { animate: true });
                };

               
                
            });
        }
    });

    otacadlo_img_active_floor.subscribe(value => {
        active_floor = value;
    });

    shortcuts.subscribe(value => {
        _shortcuts = value;
    });

    sidebar.subscribe(value => {
        _sidebar = value;
    });

    show_floorplan.subscribe(value => {
        _floorplan = value;
    });

    modal.subscribe(value => {
        _modal = value;
    });

    
    

    

    const static_source = [
        ['BG_010000.jpg', 'BG_020000.jpg', 'BG_030000.jpg', 'BG_040000.jpg'],
        ['DF_010000.jpg', 'DF_020000.jpg', 'DF_030000.jpg', 'DF_040000.jpg'],
        ['FC_010000.jpg', 'FC_020000.jpg', 'FC_030000.jpg', 'FC_040000.jpg'],
        ['SC_010000.jpg', 'SC_020000.jpg', 'SC_030000.jpg', 'SC_040000.jpg'],
    ];

    const floors_names = ["E0", 'E1', "E2", "E3"];

    static_img_src = static_source[0][0];
    
    function prevOrientation() {
        static_source.forEach(source => {
            for (let index = 0; index < source.length; index++) {
                const element = source[index];
                if (element == static_img_src) {
                    
                    if (index != 0) {
                        static_img_src = source[index-1];
                    } else {
                        static_img_src = source[source.length - 1];
                    }
                    return;
                }
                
            }
        }); 
    }

    function nextOrientation() {
        static_source.forEach(source => {
            for (let index = 0; index < source.length; index++) {
                const element = source[index];
                if (element == static_img_src) {
                    //console.log(element + " : " + static_img_src);
                    if (index != source.length - 1 ) {
                        static_img_src = source[index+1];
                    } else {
                        static_img_src = source[0];
                    }
                    return;
                }
                
            }
        }); 
    }

    function change_floor(id) {
        //console.log(id);
        for (let index = 0; index < static_source[active_floor].length; index++) {
            const current_img = static_source[active_floor][index];
            if (current_img == static_img_src) {            
                if (id == static_source.length) {
                    static_img_src = static_source[0][index];
                } else {
                    static_img_src = static_source[id][index];

                }
                otacadlo_img_active_floor.update(n => id);
                
                return;
            }            
        }
        
    }
</script>
<svelte:head>
    {#each static_source as images}
    {#each images as image}
    <link rel="preload" as="image" href="{userdata["base_path"]}assets/otacadlo/{image}"/>
    {/each}
    
    {/each}
</svelte:head>
<div id="woow-otacadlo-img">

{#if _otacadlo_img}
     <div id="static-rotator" bind:this={container}>
        {#if static_img_src != null && static_img_src != true}
            <img class:mobile={Device.isMobile}
                data-zoomist-src="{userdata["base_path"]}assets/otacadlo/{static_img_src}"
                src="{userdata["base_path"]}assets/otacadlo/{static_img_src}" 
                alt="rotate-img"/>
        {/if}
     </div>
     <div class="arrow-left" on:click={() => prevOrientation()}></div>
    <div class="arrow-right" on:click={() => nextOrientation()}></div>        
     <div class="floor-picker">
        <div class="floors-wrapper">
            {#each floors_names as [index,key]}
                <div id ={key} class={active_floor == key ? "floor active" : "floor"} 
                    on:click={() => change_floor(parseInt(key))}>
                    {floors_names[key]}
                </div>     
            {/each}
        </div>
    </div>
    <div id="background"></div>
{/if}
{#if !_shortcuts && !_sidebar && !_floorplan && !_modal}
    <div id="otacadlo-img-switch" on:click={() => otacadlo_img.update( n => !n)}/>
{/if}
</div>

<style lang="scss">
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";

    #woow-otacadlo-img{
    #static-rotator {
        position: absolute;
        display: flex;
        justify-content: center;
        align-content: center;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 11;
    }

    img{
        width: max-content;
        width: -webkit-max-content;
        object-fit: cover;

        &.mobile{
            width: auto;
            height: 100%;
        }
    }

    .arrow-left, .arrow-right {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background-image: url("../assets/icons/arrow-left.svg");
        z-index: 12;
        cursor: pointer;
    }

    .arrow-right {
        left: unset;
        right: 16px;
        background-image: url("../assets/icons/arrow-right.svg");
    }

    .floor-picker {
        position: absolute;
        top: 50%;
        right: 72px;
        transform: translateY(-50%);
        background-color: var(--secondary-color);
        width: 48px;
        border-radius: 12px;
        // height: calc(100% - 32px);
        font-size: 14px;
        line-height: 21px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 0px;
        z-index: 12;

        .floors-wrapper {
            display: flex;
            flex-direction: column-reverse;
            justify-content: end;
            align-items: center;
            height: calc(100% - 72px);
            overflow: auto;
            padding: 0px 8px 20px 8px;

            i {
                padding: 5px;
                color: var(--main-color);
            }

            .floor {
                margin-top: 16px;
                border-radius: 6px;
                width: 100%;
                display: flex;
                justify-content: center;
                cursor: pointer;

                &.active {
                    color: var(--main-color);
                    background-color: var(--main-color-light005);
                }

                &:hover:not(.active) {
                    background-color: var(--primary-color-light005);
                }
            }
        }
    }

    #otacadlo-img-switch {
        width: 40px;
        height: 40px;
        @include blur;
        position: absolute;
        left: 16px;
        bottom: 16px;
        border-radius: $radius-S;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 12;
        &::after {
            content: '';
            display: block;
            background-color: var(--primary-color);
            mask-repeat: no-repeat;
            width: 24px;
            height: 24px;
            -webkit-mask-image: url("../assets/icons/appartments.svg");
            mask-image: url("../assets/icons/appartments.svg");

        }
    }


    #background {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(60px) !important;
        z-index: 10;
    }
}
</style>