<script>
    import { formatter } from '../utils.js';  
    import { fly } from 'svelte/transition';
    export let userdata, sample_flat_name, pdf_settings, _dispositions_enabled;
    import { t, locale, locales } from "../i18n.js";

    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    import { flat_filter_enabled,dizajn, cart_price, hide_all_hotspots_not_patches, hotspots, enable_otacadlo, changedFloor } from "../store.js";

    let krpano = document.getElementById("krpanoSWFObject");

    let _enable_otacadlo = false;
    enable_otacadlo.subscribe((value) => {
      _enable_otacadlo = value;
    });

    let _flat_filter_enabled = false;
    
    flat_filter_enabled.subscribe((value) => {
      _flat_filter_enabled = value;
    });

    let _dizajn = false;
    let _floor = false;
    dizajn.subscribe((value) => {
      _dizajn = value;
    });

    changedFloor.subscribe((value) => {
      _floor = value;
    });

    const jq = window.$;

    import { get } from 'svelte/store';
    import { all_scenes } from '../store';

    import { import_config_hotspots, all_hotspots, config_slug, pdf_generating, sidebar, languages, reimport_hotspots} from '../store';
    
    import { flat_id, active_scene, disabled_icons, showing_intro, active_product_id, attributes, current_flat } from '../store.js';
    import { shortcuts, blur, cart, configuration, all_products, config_products, config_product_variants, config_product_categories, config_product_measures_units, basket_categories, reset_configurator, vr_mode, vr_safe_data } from '../store.js';

    const fixed_basket_items = [
      {
        parent: "B3HC",
        id : "price-up",
        active : true,
        category : "Wasser",
        name : "plus",
        title : "",
        description : {
          sk : "Elektro, kanalizácia, vodovod, kúrenie, vzduchotechnika",
          de : "Strom, Abwasser, Wasserversorgung, Heizung, Klimaanlage",
          en : "Electricity, sewage, water supply, heating, air conditioning",
          cz : "Elektro, kanalizace, vodovod, topení, vzduchotechnika"
        },
        price : 3390.19
      },{
        parent: "B3HC",
        id : "price-down",
        active : true,
        category : "Wasser",
        name : "minus",
        title : "",
        description : {
          sk : "Stavebné úpravy, kanalizácia, vodovod",
          de : "Bau, Kanalisation, Wasserversorgung",
          en : "Building modifications, sewerage, water supply",
          cz : "Stavební úpravy, kanalizace, vodovod"
        },
        price : -753.88
      },
      {
        parent: "B3HD",
        id : "price-up",
        active : true,
        category : "Wasser",
        name : "plus",
        title : "",
        description : {
          sk : "Elektro, kanalizácia, vodovod, kúrenie, vzduchotechnika",
          de : "Strom, Abwasser, Wasserversorgung, Heizung, Klimaanlage",
          en : "Electricity, sewage, water supply, heating, air conditioning",
          cz : "Elektro, kanalizace, vodovod, topení, vzduchotechnika"
        },
        price : 4853.79
      },{
        parent: "B3HD",
        id : "price-down",
        active : true,
        category : "Wasser",
        name : "minus",
        title : "",
        description : {
          sk : "Stavebné úpravy, kanalizácia, vodovod",
          de : "Bau, Kanalisation, Wasserversorgung",
          en : "Building modifications, sewerage, water supply",
          cz : "Stavební úpravy, kanalizace, vodovod"
        },
        price : -1218.68
      },
      {
        parent: "B3HE",
        id : "price-up",
        active : true,
        category : "Wasser",
        name : "plus",
        title : "",
        description : {
          sk : "Elektro, kanalizácia, vodovod, kúrenie, vzduchotechnika",
          de : "Strom, Abwasser, Wasserversorgung, Heizung, Klimaanlage",
          en : "Electricity, sewage, water supply, heating, air conditioning",
          cz : "Elektro, kanalizace, vodovod, topení, vzduchotechnika"
        },
        price : 2342.72
      },{
        parent: "B3HE",
        id : "price-down",
        active : true,
        category : "Wasser",
        name : "minus",
        title : "",
        description : {
          sk : "Stavebné úpravy, kanalizácia, vodovod",
          de : "Bau, Kanalisation, Wasserversorgung",
          en : "Building modifications, sewerage, water supply",
          cz : "Stavební úpravy, kanalizace, vodovod"
        },
        price : -697.29
      }
    ];

    let fixed_basket_items_price = 0;
    
    fixed_basket_items.forEach(product => {
      if (product.parent == sample_flat_name) {
        fixed_basket_items_price += product.price;
      }
    });

    //////console.log(fixed_basket_items_price);
    

    let filtered_scenes = [];
    let filtered_scenes_count = 0;
    let pdf_link = null;

    let show_progress = false;

    $: {
      show_progress;

      if (!show_progress) {
        reimport_hotspots.update(n => true);
      }
    }

    let dis_icon = get(disabled_icons);


    
    vr_safe_data.subscribe(value => {
      if (value == true) {
        saveConfiguration(true);
      }
    });
    disabled_icons.subscribe(value => {
      dis_icon = value;
    });

    let _all_hotspots = [];
    all_hotspots.subscribe(value => {
      _all_hotspots = value;
    });

    let _active_scene = null;
    active_scene.subscribe(value => {
        if (value != undefined) {
            _active_scene = value;
            
        }
    });

    let _vrMode;
    vr_mode.subscribe(value => {
      _vrMode = value;
    });

    let _flat_id = sample_flat_name;
    


    let _import_config_hotspots = false;

    import_config_hotspots.subscribe(value => {
      _import_config_hotspots = value;
    });

    // flat_id.subscribe(value => {
    //     if (value != undefined) {
    //         _flat_id = value;
            
    //     }
    // });

    let _config_slug = "peter";

    config_slug.subscribe(value => {
        if (value != undefined) {
            _config_slug = value;
            
        }
    });


    let scenes = [];
    let _all_scenes = [];
    all_scenes.subscribe(value => {
        if (value != undefined) {
          scenes = value;   
          _all_scenes = value;
          console.log(scenes);
          
        }

        
    });

    // zisťovanie aktuálneho bytu
    let _current_flat = null;
    current_flat.subscribe((value) => {
      _current_flat = value;
    });

    let active_category = null;
    // zmena aktívneho produktu + označovanie kategórie
    active_product_id.subscribe(value => {
      
        if (value != undefined && value != -1) {
            attributes.update(n => true);
            

            let categories = get(config_product_categories);
            
            let products = get(all_products);
            

            if ( categories != undefined && products != undefined) {
              products.forEach(product => {
              if (parseInt(product.id) == parseInt(value)) {
                
                categories.forEach(category => {
                  
                  if (parseInt(category.id) == parseInt(product.category)) {
                    active_category = category.id;
                    scroll_to_element();
                  } 
                });
                }
              
              });
            }
            
            
        }
    });

    let modal_no_product_on_sccene = false;
    let product_on_scenes = [];
    let product_candidate = null;


    function check_product_on_scene($id) {
      product_on_scenes = [];
      product_candidate = $id;

      

      if (filtered_scenes.length == 0) {
        for (let index = 0; index < scenes.length; index++) {
          const element = scenes[index];
          if (element.sample_flat_id == _flat_id) {
            filtered_scenes.push(element);
          }
        }
      }
      // //////console.log("filtered scenes : " + filtered_scenes);
      // //////console.log(filtered_scenes);

      let product_on_scene = false;

      _all_hotspots.every(element => {
        if (element.scene_id == _active_scene && (parseInt(element.product_id) == parseInt($id))) {
          product_on_scene = true;
          // //////console.log(element.product_id);
          return false;
        } else {
          return true;
        }
      });

      if (product_on_scene == true) {
        active_product_id.update(n => parseInt($id));
        attributes.update(n => true);
      } else {
        
        modal_no_product_on_sccene = true;
      }

      // nájdi scény, na ktorých je požadovaný produkt (jeho hotspot)
      filtered_scenes.forEach(element => {

        _all_hotspots.forEach(hotspot => {
          if (hotspot.scene_id == element.name && (parseInt(hotspot.product_id) == parseInt($id))) {
            product_on_scenes.push(element.name);
          }
        });
      });
    }

    // odznačovanie aktívnej kategórie
    attributes.subscribe(value => {
        if (value == false) {
            active_category = null;
        }

        if (value && innerWidth < 650 ) {
          cart.update(n => false);
        }
    });


    let total_price = null;

    $: {
      total_price; 

      cart_price.update(n => total_price);
    }

    let configuration_loaded, config_products_loaded, config_product_categories_loaded, config_product_measures_units_loaded = false;

    let _configuration, _config_products, _config_product_variants, _config_product_categories, _config_product_measures_units, _basket_categories = [];
    
    let add_active_category = true;

    configuration.subscribe(value => {
        if (value != undefined) {
          // nastavenie premennej na true, aby sa zozbalili všetky kategórie v košíku - aby user videl že sa zrušili hodnoty
          add_active_category = true;
            _configuration = value;

          

            total_price = 0;
            _configuration.forEach(element => {
              if (element.price != null) {
                  total_price = total_price + parseFloat(element.price);
              }
            });

            if (sample_flat_name == "B3HC" || sample_flat_name == "B3HD" || sample_flat_name == "B3HE") {
            total_price += fixed_basket_items_price;
            }
            
            
            
            configuration_loaded = true;  
            
            _basket_categories.forEach(cat => {
              if ( parseInt(cat.id) == parseInt(active_category) ) {
                cat.active_category = true;
                _basket_categories = _basket_categories;
                
              }
            });  
        }
      
    });

    all_products.subscribe(value => {
        if (value != undefined) {
            _config_products = value;
            //////console.log("produkty: ");
            //////console.log(value);
            
            config_products_loaded = true;
            sort(_config_products, "product_order");
        }

        
      
    });

    config_product_variants.subscribe(value => {
        if (value != undefined) {
            _config_product_variants = value;
            //////console.log("varianty: ");
            ////console.log(_config_product_variants);
        }
      
      
    });

    config_product_categories.subscribe(value => {
        if (value != undefined) {
            _config_product_categories = value;
            // //////console.log("kategórie: ");
            // //////console.log(_config_product_categories);
            config_product_categories_loaded = true;      
        }
      
    });

    config_product_measures_units.subscribe(value => {
        if (value != undefined) {
            _config_product_measures_units = value;
            // //////console.log("MJ: ");
            // //////console.log(_config_product_measures_units);
            config_product_measures_units_loaded = true;      
        }
    });

    
    basket_categories.subscribe(value => {
        if (value != undefined) {
          // //////console.log("basket kategórie");
          // //////console.log(value);
            _basket_categories = value;
            sort(_basket_categories, "category_order");

            if (add_active_category) {
              _basket_categories.forEach(category => {
                category.active_category = true;
              });
            }

            

            add_active_category = false;
        }
    });


    let blur_enabled = false;
    blur.subscribe(value => {    
        blur_enabled = value;
    });

    let _cart = false;

    let sort_cart_item = true;

    cart.subscribe(value => {    
        _cart = value;

        switch (value) {
            case true:
            languages.update(n => false);
            if (innerWidth < 650) {
              sidebar.update(n => false);
              flat_filter_enabled.update(n => false);
              attributes.update(n => false);
            }
            
              jq("#chat-application").addClass("hidden");
              shortcuts.update(n => false);

            // zotriedenie produktov v košíku / v kategórií
            if (sort_cart_item) {
              // pridanie hodnoty order do produktov pre zotriedenie produktov
              _configuration.forEach(element => {
                _config_products.forEach(product => {
                  if (parseInt(element.product_id) === parseInt(product.id)) {
                    element.order = parseInt(product.product_order);
                  }
                });    
              });

              sort(_configuration, "order");
              sort_cart_item = false;
            }
            
            break;
        
            default:
            jq("#chat-application").removeClass("hidden");
            break;
        }
    });

    let loading_data = false;

    let generated_screenshots = false;

    async function saveConfiguration(parameter) {
        console.log("ukladám nastavenia");
        krpano = document.getElementById("krpanoSWFObject");
        
        let data = {
            user_id : _flat_id, 
            active_scene : get(active_scene),
            hlookat : krpano.get("view.hlookat"),
            vlookat : krpano.get("view.vlookat"),
            configuration : get(configuration),
            price : total_price,
            cdn_path : userdata["cdn_path"],
            base_path : userdata["base_path"],
            assets_folder : userdata["assets_folder"]
        }

        const res = await fetch(
        userdata['cdn_path'] + "php/saveConfiguration.php", {
            method: "POST",
            body: JSON.stringify(data),
        })

        if (res.ok) {
            const json = await res.json(); 
            // //////console.log("uložil som nastavenia");
            console.log(json);

            config_slug.update(n => json["config_slug"]);
            
            if (json.status == true) {
              if (parameter == true) {
                addNotification({
                  text: $t("components")['basket']['configuration-saved'],
                  position: 'top-center',
                  removeAfter: 4000,
                  type: 'success'
                });

               
              }
            
            krpano.set("allow_saving", false);

            // krpano.call("makeScreenshot(" + window.innerWidth + ", " + window.innerHeight + ",true,'jpeg', 1.0)");
            // krpano.call("makeScreenshot(1000,460,true,'jpeg', 1.0)");
            

            // vytvor PDF 

            // generovanie PDF
            
            
            async function generate_pdf(parameter) {
              
              // //////console.log("začínam generovať PDF");
              let data = {
                  user_id : _flat_id,
                  url : document.URL,
                  slug : get(config_slug),
                  cdn_path :userdata["cdn_path"],
                  base_path :userdata["base_path"],
                  assets_folder :userdata["assets_folder"]
              }
              // //////console.log(data);
              const res = await fetch(
                userdata['cdn_path'] + "php/generatePdf.php", {
                method: "POST",
                body: JSON.stringify(data),
              })

              if (res.ok) {
                const json = await res.json();

                if (json.status == true) {

                  // //////console.log("vytvoril som PDF");
                // addNotification({
                //     text: 'PDF bolo vytvorené!.',
                //     position: 'top-center',
                //     removeAfter: 4000,
                //     type: 'success'
                // });

                pdf_generating.update(n => false);
                pdf_link = json.link;
                hide_all_hotspots_not_patches.update(n => true);
                
                
                

              
                } else {
                  addNotification({
                    text: 'Chyba: PDF nebolo vytvorené!.',
                      position: 'top-center',
                      removeAfter: 4000,
                      type: 'danger'
                  });
                }
              } else {
        
                addNotification({
                  text: 'Chyba: PDF nebolo vytvorené!.',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'danger'
                });
              }

            };

            if (parameter == true) {

              
              // krpano.set("generating_screenshot", true);

              // // funkcia, ktorá čaká na vytvorenie screenshotu
              // function waitForElement(){
              //     if(krpano.get("generating_screenshot") == false){
              //       //////console.log("SCREEN ULOŽENÝ");

              //       generate_pdf();

              //       }
                  
              //     else{
              //         setTimeout(waitForElement, 250);
              //     }
              // }

              // waitForElement();
            } else {
              generate_scenes_screenshots();
              function allow_generate_pdf() {
                if (generated_screenshots == true) {
                  generate_pdf();
                } else {
                  setTimeout(allow_generate_pdf, 1000);
                }
              }

              allow_generate_pdf();
              
              
            }

            
            } else {
            addNotification({
                text: 'Chyba : Konfigurácia nebola uložená!.',
                position: 'top-center',
                removeAfter: 4000,
                type: 'danger'
            });
            }
        } else {
            throw new Error(data);
        }
    }

    let saved_configuration = false;

    

    function generatePdf() {
      hotspots.update(n => false);
      saveConfiguration(false);
    }

    
    // sortovanie produktov
    function sort(data, key) {
      data.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
      data = data;
    }


    function generate_scenes_screenshots() {
      krpano = document.getElementById("krpanoSWFObject");
      pdf_generating.update(n => true);
      generated_screenshots = false;

      krpano.set("generating_screenshot", true);
      cart.update(n => false);
      show_progress = true;
      filtered_scenes = [];
      filtered_scenes_count = 0;
      pdf_link = null;
      
      //let krpano = document.getElementById("krpanoSWFObject"); 

      // zapamätanie si aktuálnej scény na natočenia
      let current_scene = krpano.get("xml.scene").toUpperCase();     
      let current_hlookat = krpano.get("view.hlookat");
      let current_vlookat = krpano.get("view.vlookat");   
      

      if (krpano != null ) {
        krpano = document.getElementById("krpanoSWFObject"); 
        krpano = krpano.get("global");

        krpano.events.onnewpano = function() {
          active_scene.update(n => krpano.get("xml.scene").toUpperCase());
        };

        
      }

      // filtrovanie scén
      for (let index = 0; index < scenes.length; index++) {
        const element = scenes[index];
        if (element.sample_flat_id == _flat_id) {
          filtered_scenes.push(element);
        }
      }

      let index = 0;
      
      krpano.call("loadscene(" + filtered_scenes[index]["name"] + ", null, MERGE, BLEND(0.5))");
      krpano.set("view.hlookat", filtered_scenes[index]["hlookat"]);
      krpano.set("view.vlookat", filtered_scenes[index]["vlookat"]);

      function createScreen() {

      let banner_width = 515 * 2;
      let banner_height = 260 * 2;

      let borderSideWidth =  (window.innerWidth - banner_width ) / 2;
      let borderSideHeight = (window.innerHeight - banner_height ) / 2;

      let img_data = null;

      html2canvas(jq('#pano')[0], {
        x : borderSideWidth,
        y : borderSideHeight,
        width: banner_width,
        height: banner_height
                
          }).then(canvas => {
          //document.body.appendChild(canvas)
          //////console.log(canvas.toDataURL("image/jpeg"));
          img_data = canvas.toDataURL("image/jpeg");


            

          var img = document.createElement('img');
          img.src = canvas.toDataURL("image/jpg");
          

          var tmp_name = krpano.get("xml.scene").toUpperCase(); 
				  let name =  tmp_name +  "_banner";

          // let thumb_img = document.getElementById(tmp_name);
          // thumb_img.replace(/\n/g, "");
          
          // thumb_img.src = img_data;
          // thumb_img.src = "lama.jpg";
        

          // //////console.log(_flat_id);

          jq.ajax({
					type: "POST",
					url: userdata['cdn_path'] + "php/file_saver.php",
					data: {
						imgBase64: img_data,
						name : name,
            slug : get(config_slug),
            base_path : userdata["base_path"],
            assets_folder : userdata["assets_folder"]
					}
					}).done(function(o) {
						console.log('image ' + name + ' saved');
						krpano.set("generating_screenshot", false);
					}).fail(function( jqXHR, textStatus ) {
              alert( "Request failed: " + textStatus );
  				});
      });
        
        waitForScreen();
      }
      
      setTimeout(createScreen, 2000);
      
      function waitForScreen(){
        let generating_screenshot = krpano.get("generating_screenshot");

        if (generating_screenshot == false) {
          if (index != (filtered_scenes.length - 1)) {
            
            index ++;
            filtered_scenes_count++;

            krpano.call("loadscene(" + filtered_scenes[index]["name"] + ", null, MERGE, BLEND(0.5))");
            krpano.set("view.hlookat", filtered_scenes[index]["hlookat"]);
            krpano.set("view.vlookat", filtered_scenes[index]["vlookat"]);
            krpano.set("generating_screenshot", true);

            setTimeout(createScreen, 3000);

          } else {
            
            krpano.call("loadscene(" + current_scene + ", null, MERGE, BLEND(0.5))");
            krpano.set("view.hlookat", current_hlookat);
            krpano.set("view.vlookat", current_vlookat);
            
            filtered_scenes_count++;
            
            generated_screenshots = true;
            console.log("dokončené");
            hotspots.update(n => true);
          }

        } else{
          // //////console.log("čakám, obrázok nebol vytvorený");
          setTimeout(waitForScreen, 3000);
        } 
      }
    }

    function go_to_scene($scene) {
      krpano = document.getElementById("krpanoSWFObject"); 
      krpano.call("loadscene(" + $scene + ", null, MERGE, BLEND(0.5))");
      modal_no_product_on_sccene = false;
      active_product_id.update(n => parseInt(product_candidate));

    }

    function scroll_to_element($id) {
        const container = document.getElementById("cart-items");
        const anchor = document.getElementById("cat_" + active_category);
        if (container != null) {
          container.scrollTo({
            top: anchor.offsetTop - 130,
            behavior: 'smooth'
          });
        }
        
    }

    import {newDelay} from "../store.js";

    let delay = 0;
    async function setDelay(newDelay) {
		  delay = newDelay;
	  }

    function open_pdf($target) {
        window.open(userdata['cdn_path'] + 'dispositions/' + $target + '.pdf', '_blank');
    }
</script>
<div id="woow-basket">
  {#if _vrMode == false && !_enable_otacadlo && !_dispositions_enabled}
    <div class="cart-icon" class:hide_blur="{blur_enabled === true || dis_icon == true || _flat_filter_enabled}" on:click="{() => cart.update(n => true)}"></div>
  {/if}
  
  
  {#if _cart == true && blur_enabled == false && _vrMode == false && !_enable_otacadlo && !_dispositions_enabled}
    <div id="cart" class:active="{cart == true}" transition:fly={{x: +280, delay}}>
      <div class="header">
        <h1>{$t("components")['basket']['title']}</h1>
        <div class="close" on:click="{() => cart.update(n => false)}"/>
      </div>
      <!-- <div class="reset" on:click={() => reset_configurator.update(n => true)}>{$t("components")['basket']['reset']}</div> -->
      <div id="cart-items" class="{pdf_link != null ? 'cart-items pdf' : 'cart-items'}">
          {#if configuration_loaded == true && config_products_loaded == true && config_product_categories_loaded}
          {#each _basket_categories as category}
              <div id={"cat_" +category.id} class="item" class:selected={active_category == category.id} class:active={category.active_category == true}>
                  <div class="title" on:click={() => category.active_category = !category.active_category}>{category.name}</div>
                  {#each _configuration as item}
                  
                      {#each _config_products as product}
                          {#if (parseInt(product.id) == parseInt(item.product_id)) && (parseInt(product.category) == parseInt(category.id)) }
                              
                              <!-- názov & cena -->
                              <div class="standard">
                                
                                <!-- názov produktu -->
                                <div class="standard-name" on:click={() => check_product_on_scene(product.id)}>{product.name}</div>
                                {#if item.price != null }
                                  {#if item.mj == "m2"}
                                    <div class="price">{formatter.format(item.price)} <span> {$t("components")['basket']['quantity']} {parseFloat(item.quantity)} m<sup>2</sup></span></div>

                                  {:else}
                                    <!-- <div class="price">{formatter.format(item.price)} <span>({parseFloat(item.quantity)} {item.mj})</span></div> -->
                                    <div class="price">{formatter.format(item.price)} <span>({parseFloat(item.quantity)}   
                                      {#if item.quantity > 1}  
                                        {$t("components")['basket']['pieces']}
                                      {:else}
                                        {$t("components")['basket']['piece']}
                                      {/if}
                                    )</span></div>  
                                  {/if}
                                  
                                {/if}
                                <!--{#if item.active_product_standard == null}
                                  {#each _config_product_variants as variant}
                                      {#if parseInt(variant.id) == parseInt(item.active_product_variant)}
                                      
                                      {/if}
                                  {/each}
                                {:else}
                                  {#if item.active_product_standard == item.product_standard_name_0}
                                    {#each _config_product_variants as variant}
                                        {#if parseInt(variant.id) == parseInt(item.active_product_variant_0)}
                                        <div class="price">{item.price} €</div>
                                        {/if}
                                    {/each}
                                  {/if}
                                  {#if item.active_product_standard == item.product_standard_name_1}
                                    {#each _config_product_variants as variant}
                                        {#if parseInt(variant.id) == parseInt(item.active_product_variant_1)}
                                        <div class="price">{item.price} €</div>
                                        {/if}
                                    {/each}
                                  {/if}
                                  {#if item.active_product_standard == item.product_standard_name_2}
                                    {#each _config_product_variants as variant}
                                        {#if parseInt(variant.id) == parseInt(item.active_product_variant_2)}
                                        <div class="price">{item.price} €</div>
                                        {/if}
                                    {/each}
                                  {/if}
                                {/if} -->

                              
                              </div>
                              {#each _configuration as config_item}
                                {#if config_item.type == "product" && parseInt(config_item.product_id) == product.id }

                                  <!-- ak má produkt zvolený aktívny štandard, vypíš ho -->
                                  {#if config_item.active_product_standard != null}
                                    <div class="standard-ico">{config_item.active_product_standard}</div>

                                    <!-- ak ide o steny, vypíš, o akú kombináciu ide -->
                                    {#if config_item.active_product_standard == config_item.product_standard_name_0 && config_item.walls != "0"}
                                      {#if config_item["walls_type_0"] == "single"}
                                        <div class="color-combination">Jednofarebná kombinácia obkladu</div>
                                      {:else}
                                        <div class="color-combination">Dvojfarebná kombinácia obkladu</div>
                                      {/if}
                                    {/if}

                                    {#if config_item.active_product_standard == config_item.product_standard_name_1 && config_item.walls != "0"}
                                      {#if config_item["walls_type_1"] == "single"}
                                        <div class="color-combination">Jednofarebná kombinácia obkladu</div>
                                      {:else}
                                        <div class="color-combination">Dvojfarebná kombinácia obkladu</div>
                                      {/if}
                                    {/if}

                                    {#if config_item.active_product_standard == config_item.product_standard_name_2 && config_item.walls != "0"}
                                      {#if config_item["walls_type_2"] == "single"}
                                        <div class="color-combination">Jednofarebná kombinácia obkladu</div>
                                      {:else}
                                        <div class="color-combination">Dvojfarebná kombinácia obkladu</div>
                                      {/if}
                                    {/if}

                                    
                                    
                                  {/if}
                                {/if}
                              {/each}
                              

                              
                              <div class="item-value">
                                <div class="item-icons">
                                  
                                  <!-- thumbnail -->
                                  

                                    <!-- produkt bez štandardov -->
                                    {#if item.active_product_standard == null}
                                      {#each _config_product_variants as variant}
                                        {#if parseInt(variant.id) == parseInt(item.active_product_variant)}
                                        <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                          <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                        </div>
                                        {/if}

                                      {/each}

                                    <!-- produkt so štandardami -->
                                    {:else}

                                      <!-- ak ide o steny -->
                                      {#if item.walls != "0"}
                                        <!-- ak ide o steny -->
                                        {#if item.active_product_standard == item.product_standard_name_0}
                                          <!-- jednofarebný obklad -->
                                          {#if item["walls_type_0"] == "single"}
                                            {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_single_active_product_variant_0)}
                                                <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                                </div>
                                                {/if}
                                            {/each}
                                          {:else}
                                            
                                          <!-- prvá kombinácia -->
                                          <div class="comb">
                                            <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_0)}
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" />
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="item-wall">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_0)}
                                                  {#if item.walls_first_text_0 == ""}
                                                    <img src="{userdata["cdn_path"]}icons/none_wall.svg" />
                                                  {:else}
                                                    <img src="{userdata["cdn_path"]}icons/{item.walls_first_text_0}.svg" />
                                                  {/if}
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="itemtexts">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_0)}
                                                  <p><span>{variant.name}</span></p>
                                                  <p>Stena <span>{item.walls_first_text_0}</span> - {variant.short_description}</p>
                                                {/if}
                                              {/each}
                                            </div>
                                          </div>
                                          <!-- druhá kombinácia -->
                                          <div class="comb">
                                            <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_0)}
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" />
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="item-wall">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_0)}
                                                  {#if item.walls_second_text_0 == ""}
                                                      <img src="{userdata["cdn_path"]}icons/none_wall.svg" />
                                                    {:else}
                                                      <img src="{userdata["cdn_path"]}icons/{item.walls_second_text_0}.svg" />
                                                    {/if}
                                                  {/if}
                                              {/each}
                                            </div>
                                            <div class="itemtexts">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_0)}
                                                  <p><span>{variant.name}</span></p>
                                                  <p>Stena <span>{item.walls_second_text_0}</span> - {variant.short_description}</p>
                                                {/if}
                                              {/each}
                                            </div>
                                          </div>
                                        
                                          {/if}
                                          
                                        {/if}
                                        {#if item.active_product_standard == item.product_standard_name_1}
                                          <!-- jednofarebný obklad -->
                                          {#if item.walls_type_1 == "single"}
                                            {#each _config_product_variants as variant}
                                              {#if parseInt(variant.id) == parseInt(item.walls_single_active_product_variant_1)}
                                                <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                                </div>
                                              {/if}
                                            {/each}
                                          {:else}

                                          <!-- prvá kombinácia -->
                                          <div class="comb">
                                            <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_1)}
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" />
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="item-wall">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_1)}
                                                  {#if item.walls_first_text_1 == ""}
                                                    <img src="{userdata["cdn_path"]}icons/none_wall.svg" />
                                                  {:else}
                                                    <img src="{userdata["cdn_path"]}icons/{item.walls_first_text_1}.svg" />
                                                  {/if}
                                                  
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="itemtexts">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_1)}
                                                  <p><span>{variant.name}</span></p>
                                                  <p>Stena <span>{item.walls_first_text_1}</span> - {variant.short_description}</p>
                                                {/if}
                                              {/each}
                                            </div>
                                          </div>
                                          <!-- druhá kombinácia -->
                                          <div class="comb">
                                            <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_1)}
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" />
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="item-wall">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_1)}
                                                  
                                                  {#if item.walls_second_text_1 == ""}
                                                    <img src="{userdata["cdn_path"]}icons/none_wall.svg" />
                                                  {:else}
                                                    <img src="{userdata["cdn_path"]}icons/{item.walls_second_text_1}.svg" />
                                                  {/if}
                                                  
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="itemtexts">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_1)}
                                                  <p><span>{variant.name}</span></p>
                                                  <p>Stena <span>{item.walls_second_text_1}</span> - {variant.short_description}</p>
                                                {/if}
                                              {/each}
                                            </div>
                                          </div>
                                          {/if}
                                          
                                        {/if}
                                        {#if item.active_product_standard == item.product_standard_name_2}
                                          <!-- jednofarebný obklad -->
                                          {#if item.walls_type_2 == "single"}
                                            {#each _config_product_variants as variant}
                                              {#if parseInt(variant.id) == parseInt(item.walls_single_active_product_variant_2)}
                                                <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                                  <img src="{userdata["cdn_path"]}icons/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                                </div>
                                              {/if}
                                            {/each}
                                          {:else}
                                          <!-- prvá kombinácia -->
                                          <div class="comb">
                                            <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_2)}
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" />
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="item-wall">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_2)}
                                                  {#if item.walls_first_text_2 == ""}
                                                    <img src="{userdata["cdn_path"]}icons/none_wall.svg" />
                                                  {:else}
                                                    <img src="{userdata["cdn_path"]}icons/{item.walls_first_text_2}.svg" />
                                                  {/if}
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="itemtexts">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_first_combination_2)}
                                                  <p><span>{variant.name}</span></p>
                                                  <p>Stena <span>{item.walls_first_text_2}</span> - {variant.short_description}</p>
                                                {/if}
                                              {/each}
                                            </div>
                                          </div>
                                          <!-- druhá kombinácia -->
                                          <div class="comb">
                                            <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_2)}
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" />
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="item-wall">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_2)}
                                                  {#if item.walls_second_text_2 == ""}
                                                    <img src="{userdata["cdn_path"]}icons/none_wall.svg" />
                                                  {:else}
                                                    <img src="{userdata["cdn_path"]}icons/{item.walls_second_text_2}.svg" />
                                                  {/if}
                                                {/if}
                                              {/each}
                                            </div>
                                            <div class="itemtexts">
                                              {#each _config_product_variants as variant}
                                                {#if parseInt(variant.id) == parseInt(item.walls_second_combination_2)}
                                                  <p><span>{variant.name}</span></p>
                                                  <p>Stena <span>{item.walls_second_text_2}</span> - {variant.short_description}</p>
                                                {/if}
                                              {/each}
                                            </div>
                                          </div>
                                          {/if}
                                          
                                        {/if}
                                        
                                      {:else}

                                      <!-- ak nejde o steny -->
                                        {#if item.active_product_standard == item.product_standard_name_0}
                                          {#each _config_product_variants as variant}
                                              {#if parseInt(variant.id) == parseInt(item.active_product_variant_0)}
                                              <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                                <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                              </div>
                                              {/if}
                                          {/each}
                                        {/if}
                                        {#if item.active_product_standard == item.product_standard_name_1}
                                          {#each _config_product_variants as variant}
                                              {#if parseInt(variant.id) == parseInt(item.active_product_variant_1)}
                                                <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                                </div>
                                              {/if}
                                          {/each}
                                        {/if}
                                        {#if item.active_product_standard == item.product_standard_name_2}
                                          {#each _config_product_variants as variant}
                                              {#if parseInt(variant.id) == parseInt(item.active_product_variant_2)}
                                                <div class="item-color" on:click={() => check_product_on_scene(product.id)}>
                                                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                                                </div>
                                              {/if}
                                          {/each}
                                        {/if}

                                      {/if}

                                      
                                    {/if}
                                    
                                  

                                  
                                </div>
                                <div class="item-info">
                                  {#if item.active_product_standard == null}
                                    {#each _config_product_variants as variant}
                                      {#if parseInt(variant.id) == parseInt(item.active_product_variant)}
                                        <div class="color-name">{variant.name}</div>
                                        <div class="item-description">{variant.short_description}</div>
                                      {/if}
                                    
                                    {/each}
                                  {:else}

                                    {#if item.walls != "0"}
                                      <!-- ak ide o steny -->
                                      {#if item.active_product_standard == item.product_standard_name_0}
                                        <!-- jednofarebný obklad -->
                                        {#if item["walls_type_0"] == "single"}
                                          {#each _config_product_variants as variant}
                                              {#if parseInt(variant.id) == parseInt(item.walls_single_active_product_variant_0)}
                                                <div class="color-name">{variant.name}</div>
                                                <div class="item-description">{variant.short_description}</div>
                                              {/if}
                                          {/each}
                                        {/if}
                                      {/if}
                                      {#if item.active_product_standard == item.product_standard_name_1}
                                        <!-- jednofarebný obklad -->
                                        {#if item.walls_type_1 == "single"}
                                          {#each _config_product_variants as variant}
                                            {#if parseInt(variant.id) == parseInt(item.walls_single_active_product_variant_1)}
                                              <div class="color-name">{variant.name}</div>
                                              <div class="item-description">{variant.short_description}</div>
                                              {/if}
                                          {/each}
                                        {/if}
                                        
                                      {/if}
                                      {#if item.active_product_standard == item.product_standard_name_2}
                                        <!-- jednofarebný obklad-->
                                        {#if item.walls_type_2 == "single"}
                                          {#each _config_product_variants as variant}
                                            {#if parseInt(variant.id) == parseInt(item.walls_single_active_product_variant_2)}
                                              <div class="color-name">{variant.name}</div>
                                              <div class="item-description">{variant.short_description}</div>
                                              {/if}
                                          {/each}
                                        {/if}
                                        
                                      {/if}
                                        
                                    {:else}
                                      <!-- ak nejde o steny -->
                                      {#if item.active_product_standard == item.product_standard_name_0}
                                        {#each _config_product_variants as variant}
                                            {#if parseInt(variant.id) == parseInt(item.active_product_variant_0)}
                                              <div class="color-name">{variant.name}</div>
                                              <div class="item-description">{variant.short_description}</div>
                                            {/if}
                                        {/each}
                                      {/if}
                                      {#if item.active_product_standard == item.product_standard_name_1}
                                        {#each _config_product_variants as variant}
                                            {#if parseInt(variant.id) == parseInt(item.active_product_variant_1)}
                                              <div class="color-name">{variant.name}</div>
                                              <div class="item-description">{variant.short_description}</div>
                                            {/if}
                                        {/each}
                                      {/if}
                                      {#if item.active_product_standard == item.product_standard_name_2}
                                        {#each _config_product_variants as variant}
                                            {#if parseInt(variant.id) == parseInt(item.active_product_variant_2)}
                                              <div class="color-name">{variant.name}</div>
                                              <div class="item-description">{variant.short_description}</div>
                                            {/if}
                                        {/each}
                                      {/if}
                                    {/if}
                                  {/if}
                                  
                                  
                                  
                                </div>
                              </div>
                          {/if}
                      {/each}
                  {/each}
              </div>
          {/each}
            {#if sample_flat_name.includes("B3H")}
              {#each fixed_basket_items as product}
                {#if product.parent == sample_flat_name}
                  <div id={"cat_" +product.id} class="{product.active === true ? 'item active' : 'item'}">
                    <div class="title" on:click={() => product.active = !product.active}>{$t("components")['dispositions'][product.name]}</div>
                    <div class="standard">
                      <div class="standard-name"><span on:click={() => open_pdf(sample_flat_name)}>{$t("components")['dispositions']["pdf-documentation"]}</span></div>
                      <div class="price">{formatter.format(product.price)}<span></span></div>
                    </div>
                      <div class="item-value">
                        <div class="item-icons">
                          <div class="item-color">
                            <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{product.id}.svg" alt="{product.name}"> 
                          </div>
                        </div> 
                        <div class="item-info">
                          <div class="color-name">{product.title}</div> 
                          <div class="item-description">{product.description[$locale]}</div>
                        </div>
                      </div>
                  </div>
                {/if}
              {/each}
            {/if}
          {/if}
      </div>
      <div class="basket-buttons">
        <div class="final-price">
          <p class="final-price-text">{$t("components")['basket']['total']}</p>
          
          <p class="final-price-value">{formatter.format(total_price)}</p>
        </div>
        {#if pdf_link != null}
          <a href="{pdf_link}" target="_blank">{$t("components")['basket']['download-pdf']}</a>
        {/if}
        {#if pdf_settings != null}
          <button class="download-pdf" on:click={() => generatePdf()}>{$t("components")['basket']['generate-pdf']}</button>
        {/if}
        
        <button class="send-email" on:click={() => saveConfiguration(true)}>{$t("components")['basket']['save-configuration']}</button>
      </div>
    </div>
  {/if}

  {#if show_progress}
    <div id="background"/>
    <div id="pdf-config">
      
      <p class="title">{$t("components")['basket']['creating-pdf']}</p>
      <p class="subtitle">{$t("components")['basket']['browser']}</p>
      <div class="scene-wrapper">
        {#each  Object.entries(filtered_scenes) as [key, value], index (key)}
          <div class="{filtered_scenes_count <= key ? 'scene' : 'scene active'}">
            {#each _all_scenes as scena}
              {#if scena.name == value.name}
                {#if scena.enable_design && !scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumbnails/{value.name}_{_dizajn}_thumb.jpg" alt="">
                {/if}
    
                {#if !scena.enable_design && scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumbnails/{value.name}_{_floor}_thumb.jpg" alt="">
                {/if}
    
                {#if scena.enable_design && scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumbnails/{value.name}_{_dizajn}_{_floor}_thumb.jpg" alt="">
                {/if}
    
                {#if !scena.enable_design && !scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumnails/{value.name}_thumb.jpg" alt="">
                {/if}
              {/if}
            {/each}            
            <p class="scene-name">{value.name}</p>
          </div>
        {/each}
      </div>
      {#if pdf_link != null && generated_screenshots == true}
        <div class="close" on:click={() => show_progress = false}/>
          <div class="links">
            <a href={pdf_link} target="_blank">{$t("components")['basket']['download-pdf']}</a>
            {#if sample_flat_name == "B3HC" || sample_flat_name == "B3HD" || sample_flat_name == "B3HE"}
              <a href="{userdata['cdn_path'] + "dispositions/" + sample_flat_name + ".pdf"}" target="_blank">{$t("components")['dispositions']['pdf-documentation']}</a>
            {/if}

          </div>
        {/if}
      
    </div>
  {/if}

  {#if modal_no_product_on_sccene && _vrMode == false}
    <div id="background"/>
    <div id="not-found">
      <div class="close" on:click={() => modal_no_product_on_sccene = false}></div>
      <p class="title">{$t("components")['basket']['no-product-on-scene']}</p>
      <p class="subtitle">{$t("components")['basket']['change-scene']}</p>
      <div class="scene-wrapper">
        {#each product_on_scenes as scene}
          {#each _all_scenes as scena}
            {#if scena.name == scene}
            <div class="scene" on:click={() => go_to_scene(scene)}>
              
                {#if scena.enable_design && !scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumbnails/{scene}_{_dizajn}_thumb.jpg" alt="">
                {/if}
    
                {#if !scena.enable_design && scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumbnails/{scene}_{_floor}_thumb.jpg" alt="">
                {/if}
    
                {#if scena.enable_design && scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumbnails/{scene}_{_dizajn}_{_floor}_thumb.jpg" alt="">
                {/if}
    
                {#if !scena.enable_design && !scena.enable_floors}
                  <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}scenes_thumnails/{scene}_thumb.jpg" alt="">
                {/if}
                
                <p class="scene-name">{scene}</p>
            </div>
            {/if}

            {/each}
        {/each}
      </div>
    </div>
  {/if}

<div id="tmp" />

</div>

  <style lang="scss">
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";

  
    #woow-basket{
    #pdf-config, #not-found {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-color);
    border-radius: $radius-M;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 64px);
    max-width: 846px;
    height: auto;
    max-height: calc(100% - 144px);
    overflow: auto;

    .title {
      @include rubik-m20;
      text-align: center
    }
    .subtitle {
      @include rubik-r14;
    }


    .scene-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;
      justify-content: center;
      margin: 32px 0;
      overflow: auto;
      .scene {
        width: 150px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        border-radius: $radius-S;

        &::after {
          content: "";
        }

        &::before {
          content: "";
        }

        img {
          width: 100%;
        }
        p {
          width: 100%;
          position: absolute;
          background-color: var(--primary-color-light60);
          color: var(--secondary-color);
          padding: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include rubik-r12;
          bottom: 0;
        }
      }
    }



    a {
    display: flex;
    background-color: var(--main-color);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: var(--secondary-color);
    padding: 10px 15px;
    border-radius: 6px;
    margin: 0px;
    cursor: pointer;
    @include rubik-m12;
  }

}

#pdf-config {
    .scene {
      &::after {
          content: "";
          display: block;
          background-color: var(--secondary-color);
          width: 24px;
          height: 24px;
          opacity: .8;
          scale: 120;
          position: absolute;
          left: -40px;
          right: 0;
          transition: all .25s ease-in-out;
          transition-delay: 1s;
          
        }

        &::before {
          content: "";
          display: block;
          background-size: contain;
          width: 50px;
          height: 50px;
          opacity: .4;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 22;
          opacity: .6;
          scale: 1;
          transition: scale 0.25s ease-in-out;
          transition-delay: 1s;
        }


        &.active {
          &::after {
            scale: 1.5;
            left: 40%;
            right:0;
          }

          &::before {
            scale: 0;
          }
      }
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
}

#not-found {

  .scene {
        cursor: pointer;
        border: 2px solid transparent;

        &:hover {
          border: 2px solid var(--primary-color);
        }
  }

}

  #background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 63, 70, 0.2);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 19;
  }

  .cart-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    @include blur;
    border-radius: $radius-S;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: var(--primary-color);
      
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      opacity: 0;
      visibility: hidden;
      
    }
  }
  
  #cart {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 320px;
    height: calc(100% - 31px);
    @include blur;
    border-radius: $radius-M;
    z-index: 10;
    transition: all 0.2s ease-in-out;

    .header {
      padding: 16px 16px 2px 16px;
      @include underline-2-a;

      h1 {
        @include rubik-m20;
        padding-right: 28px;
        margin-bottom: 8px;
      }
    }
    .reset {
      @include rubik-r12;
      margin: 8px;
      width: calc(100% - 16px);
      border: 1px solid var(--primary-color-light20);
      border-radius: $radius-S;
      text-align: center;
      padding: 2px;
      cursor: pointer;
    }
    .cart-items {
      position: relative;
      height: calc(100% - 240px);
      overflow: auto;

      &.pdf {
        height: calc(100% - 326px);
      }

      .item {
        position: relative;
        box-shadow: 0 0 0 1px var(--primary-color-light20);
        border-radius: $radius-S;
        height: auto;
        max-height: 39px;
        margin: 8px;
        width: calc(100% - 16px);
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
        &::after {
          content: "";
          width: 12px;
          height: 7px;
          background-color: var(--primary-color);
          
          display: inline-block;
          position: absolute;
          top: 16px;
          right: 14px;
          transform: rotate(180deg);
          z-index: -1;
          transition: all 0.3s;
        }

        &.active {
          max-height: 200%;
          box-shadow: 0 0 0 1px var(--primary-color-light20);
          &::after {
            transform: rotate(0);
          }
        }

        &.selected {
          box-shadow: 0 0 0 1px var(--main-color);
          background-color: var(--primary-color-light005);
        }

        .title {
          @include rubik-m16;
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          // &::after {
          //   content: "";
          //   width: 12px;
          //   height: 7px;
          //   background-color: var(--primary-color);
          //   mask-image: url("../assets/icons/menu-arrow-up.svg");
          //   display: inline-block;
          //   position: relative;
          //   transform: rotate(180deg);
          // }
        }
        .standard {
          display: flex;
          justify-content: space-between;
          position: relative;
          padding: 8px;
          @include rubik-m14;
          
          &::before {
            content: "";
            position: absolute;
            width: calc(100% - 16px);
            height: 1px;
            background-color: var(--primary-color-light10);
            display: block;
            top: -1px;
          }
          .standard-name {
            @include rubik-m14;
            cursor: pointer;

            span {
              color: var(--main-color);
              text-decoration: underline;
            }
          }
          
          .price {
            @include rubik-r14;
            span {
              opacity: 0.6;
              position: absolute;
              top: 25px;
              right: 8px;
              text-align: right;
              
              font-size: 12px;
            }
          }
        }

        .standard-ico {
          display: flex;
          align-items: center;
          padding: 8px;
          @include rubik-m14;
          &::before {
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            margin-right: 4px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        .color-combination {
          @include rubik-m14;
          padding: 0 8px 5px 8px;
        }
        .item-value {
          padding: 0 8px 8px 8px;
          display: flex;
          .item-icons {
            display: flex;
            gap: 8px;
            flex-direction: column;

            .comb {
              display: flex;
              gap: 8px;
              span {
                @include rubik-m12
              }
            }

            .item-color {
              width: 40px;
              height: 40px;
              border-radius: $radius-S;
              cursor: pointer;
              box-shadow: 0 0 0 1px var(--primary-color);

              img {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: $radius-S;
                object-fit: contain;
                background-color: white;
              }
            }
            .item-wall {
              width: 40px;
              height: 40px;
              border: 1px solid var(--primary-color);
              border-radius: $radius-S;
              background-color: var(--secondary-color);
              display: flex;
              justify-content: center;

              img {
                width: 24px;
              }
            }
          }
          .item-info {
            margin-left: 8px;
            .color-name {
              @include rubik-m12;
            }
            .item-description {
              @include rubik-r12;
              span {
                @include rubik-m12;
              }
            }
          }
        }
      }
    }

    .basket-buttons {
      position: absolute;
      bottom: 0;
      padding: 8px;
      width: 100%;
      @include underline-1-b;

      .download-pdf, .send-email{
      cursor: pointer;
    }

      .final-price {
        background-color: var(--primary-color);
        border-radius: $radius-S;
        padding: 10px 15px;
        color: var(--secondary-color);
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .final-price-value {
          @include rubik-m14
        }
      }

      button, a {
        margin: 0;
        background-color: var(--main-color);
        width: 100%;
        @include rubik-m14;
        justify-content: center;
        margin-top: 8px;

      }

      a {
          margin-top: 8px;
          display: flex;
          color: #fff;
          padding: 10px 15px;
          border-radius: 6px;
        }
    }
  }

  #tmp {
    position: absolute;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
  </style>