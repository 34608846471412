
<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";
  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";
  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  let live_preview = false;
  export let userdata;

  import { Swiper, SwiperSlide } from "swiper/svelte";
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/free-mode";
  import "swiper/css/navigation";
  import "swiper/css/thumbs";
  import "swiper/css/scrollbar";

  // import required modules
  import { FreeMode, Navigation, Thumbs, Scrollbar } from "swiper";
  let thumbsSwiper = null;

  const setThumbsSwiper = (e) => {
    const [swiper] = e.detail;
    // set Thumbs swiper instance
    setTimeout(() => {
      thumbsSwiper = swiper;
    });
  };

  import { onMount } from "svelte";

  // KONTROLA NOVÝCH SCÉN NA FTP
  async function check_new_scene() {
    const res = await fetch(
      userdata['base_path']  + "assets/php/check_new_scene.php",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      let scenes = json;
      let ftp_scenes_count = scenes.length;
      let settings_scenes_count = Object.keys(settings.scenes).length;
      
      if (
        Object.keys(settings.scenes).length  
         == scenes.length
      ) {
        
      }
      else {
        if (
          settings_scenes_count > ftp_scenes_count
        ) {
          //confirm('Počet panorám nesedí, v konfigurácií je viac panorám ako na FTP ! ');
        }

        else {

          let settings_scenes_array = [];
          let key = "id";
          let order = 0;


          // vytvorenie poľa s názvami scén
          for (key in settings.scenes ) {
            if (settings.scenes.hasOwnProperty(key)) {
              settings_scenes_array.push(settings.scenes[key]['id']);
            }

            if (settings.scenes.hasOwnProperty(key)) {
              if (
                order < settings.scenes[key]['order']
              ) {
                order = settings.scenes[key]['order'];
              }
            }
          }
          // porovnanie scén z FTP vs scén z konfigurácie
          let difference = scenes.filter(x => !settings_scenes_array.includes(x));


            


          if (
            window.confirm('Počet panorám nesedí, na FTP je viac panorám ako v konfigurácií (' + difference +')! Mám pridať nové panorámy do konfigurácie ?')
          ) {
            

            

            // zistenie poradia


            for (let index = 0; index < difference.length; index++) {
              let tmp_order = order + (index+1)*10;
              settings.scenes = [...settings.scenes, {
                'id' : difference[index], 
                "name" : null,
                "tags" : ["equirects"],
                "enabled" : true,
                "hlookat" : 0.0,
                "vlookat" : 0.0,
                "fov" : 120,
                "title" : null,
                "description" : null,
                "north" : 0.0,
                "floor_enabled" : false,
                "custom_field_1" : null,
                "custom_field_2" : null,
                "custom_field_3" : null,
                "custom_field_4" : null,
                "custom_field_5" : null,
                "custom_field_6" : null,
                "custom_field_7" : null,
                "custom_field_8" : null,
                "custom_field_9" : null,
                "custom_field_10" : null,
                "thumb" : "scenes/"+difference[index]+"/thumb.jpg",
                "order" : tmp_order,
                "hotspots" : []
              }];  
            }

              async function add_new_scene_to_json () {
                const res = await fetch(
                  userdata['base_path'] + "assets/php/saveSceneSettings.php",
                  {
                    method: "POST",
                    body: JSON.stringify(settings.scenes),
                  }
                );

                if (res.ok) {
                  const json = await res.json();
                  let result = JSON.stringify(json);
                  

                  if (json.status == true) {
                    addNotification({
                          text: "Scéna(y) boli pridané !",
                          position: "top-center",
                          removeAfter: 4000,
                          type: "success",
                        });                      


                  }
                  else {
                    addNotification({
                      text: "Chyba pri pridávaní nových scény!",
                      position: "top-center",
                      removeAfter: 4000,
                      type: "danger",
                    });
                  }
                  
                } else {
                  throw new Error(data);
                }
              }
              add_new_scene_to_json();

            
          }

          else {
            return;
          }
        }

      }
    }
  }

  function check_youtube_url($link) {
    if ($link.includes('src')) {
      let checked_url = $link.slice(
        $link.indexOf('src="') + 5,
        $link.lastIndexOf('" title'),
      );

      hts_object.data.video_url = checked_url

      // switch (hotspots_view) {
      //   case 'add_hotspot':
      //     new_hts_video_url = checked_url;    
      //     break;
      //   case 'edit_hotspot' : 
      //       for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
      //         if (
      //           settings.scenes[active_scene].hotspots[index].id == edit_hotspot_id
      //         ) {
      //           settings.scenes[active_scene].hotspots[index].data['video_url'] = checked_url;
      //         }
      //       }
      //   default:
      //     break;
      // }
      
      

    }
    else {
      
    }    
  }

  // VARIABLES
  let pano;
  let settings;
  let loading_data = true;
  let active_scene = 0;
  let active_scene_id = '';
  let selected_core_type = "scene_hotspots"; // default :  scene_settings
  let hotspots_view = 'list_view'; // default : list_view
  let hotspot_type = ['next-node','infopanel-v1', 'infopanel-v2', 'infopanel-v3', 'videopanel-v1', 'videopanel-v2', 'url','attribute','tag','localvideo'];
  let showTour = false;


  $: {
    hotspots_view;

    switch (hotspots_view) {
      case 'list_view':
        live_preview = false;
        break;
    
      default:
        break;
    }
  }

  // FUNCTIONS
  const initializeRemarkable = () => {
    showTour = true;
  };

  let sortBy = {col: "order", ascending: true};

  function order_scenes () {
    loading_data = true;
    // zmena poradia podĺa order ! 
    let sortModifier = (sortBy.ascending) ? 1 : -1;
		
    let sort = (a, b) => 
      (a["order"] < b["order"]) 
      ? -1 * sortModifier 
      : (a["order"] > b["order"]) 
      ? 1 * sortModifier 
      : 0;
  
    settings["scenes"] = settings["scenes"].sort(sort);
    loading_data = false;
  }

  function rename_hts_ids() {
    
  }



  // Načítanie scén
  async function fetchData() {
    const res = await fetch(
      userdata['base_path']  + "assets/json/settings.json",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      settings = json;
      start_scene = settings.configuration.start_scene;
      active_scene_id = settings.configuration.start_scene;
      order_scenes(settings);
      check_new_scene();

      

      function check_pano() {
        var krpano = document.getElementById("pano");
      //If it isn't "undefined" and it isn't "null", then it exists.
        if(typeof(krpano) != 'undefined' && krpano != null){
            load_krpano();
        } else{
          setTimeout(check_pano, 100);
        }
      }

      check_pano();
      

      function import_dummy_data() {
        let count = 6;
        let tmp_data = settings;

        let object_depth = {
          0 : {
            depth : 2000,
            size : "2",
            rx : -70,
            h : -90,
            v : 6
          },
          1 : {
            depth : 1400,
            size : "3",
            rx : -60,
            h : -90,
            v : 11
          },
          2 : {
            depth : 1000,
            size : "4",
            rx : -40,
            h : -90,
            v : 22
          },
          3 : {
            depth : 1000,
            size : "4",
            rx : -40,
            h : 90,
            v : 22
          },
          4 : {
            depth : 1400,
            size : "3",
            rx : -60,
            h : 90,
            v : 11
          },
          5 : {
            depth : 2000,
            size : "2",
            rx : -70,
            h : 90,
            v : 6
          }
        }
        
        for (let index = 0; index < tmp_data.scenes.length; index++) {
          tmp_data.scenes[index].hotspots = [];
          tmp_data.scenes[index].thumb = "scenes/praha_" +index+ "_thumb.jpg";

          for (let i = 0; i < count; i++) {
            let hodnota;

            switch (i) {
              case 0 : 
                hodnota = index - 3;
              break;

              case 1 : 
                hodnota = index - 2;
              break;

              case 2 : 
                hodnota = index - 1;
              break;

              case 3 : 
                hodnota = index + 1 ;
              break;

              case 4 : 
                hodnota = index + 2 ;
              break;

              case 5 : 
                hodnota = index + 3 ;
              break;
            }


            let dummy_data = {
              id : i,
              name : "next-node_" + i,
              title : "",
              description : "",
              type : "next-node",
              "skin-id" : "floorspot",
              target : "praha_" + hodnota,
              enabled : true,
              ath :  object_depth[i].h,
              atv : object_depth[i].v,
              depth : object_depth[i].size,
              depth_value : object_depth[i].depth,
              rx : object_depth[i].rx,
              ry : 0, 
              data : {
                title : "",
                headline : "",
                img : "img/38.jpg",
                subtitle : "subtitle pre modal",
                content : "content pre modal",
                video_url :"",
                local_video_url : ""
              }
            };
            tmp_data.scenes[index].hotspots.push(dummy_data);
          }
        }

        settings = tmp_data;

		    console.log(settings);
	    }

      // import_dummy_data();

      // save_scene_settings();


      function add_target () {
        for (let index = 0; index < settings.scenes.length; index++) {
          if (
            settings.scenes[index].hotspots.length > 5 
          ) {
            console.log(settings.scenes[index].id);
            for (let i = 0; i < settings.scenes[index].hotspots.length; i++) {
              
                if (
                  settings.scenes[index].hotspots[i].type.includes('next-node')
                ) {
                  console.log(settings.scenes[index].hotspots[i].name);
              let target_number = parseInt(   settings.scenes[index].hotspots[i].new_target   );
              console.log('target_number : ' + target_number);

              let tmp_ind = parseInt(index + target_number);
              console.log('tmp_id :' + tmp_ind);
              
              
              settings.scenes[index].hotspots[i].target = settings.scenes[tmp_ind].id;
                }

              
            }
          }
        }
		}	

		// add_target();

      
      return json;
    } else {
      throw new Error(data);
    }
    
  }

  // Zmena aktívnej scény
  function set_active_scene(e) {
    edit_hotspot_id = null;
    hotspots_view = 'list_view';
    active_scene = e;
    active_scene_id = settings.scenes[e].id;
    
    const panoName = settings.scenes[e].id;
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("loadscene(" + panoName + ");");
    krpano.set("view.hlookat", settings.scenes[active_scene].hlookat);
    krpano.set("view.vlookat", settings.scenes[active_scene].vlookat);
  }

  // Zmena default view scény
  async function set_new_default_view() {
    var krpano = document.getElementById("krpanoSWFObject");
    settings.scenes[active_scene].hlookat = krpano.get("view.hlookat");
    settings.scenes[active_scene].vlookat = krpano.get("view.vlookat");
    

    const res = await fetch(
      userdata['base_path'] + "assets/php/saveSceneDefaultView.php",
      {
        method: "POST",
        body: JSON.stringify(settings.scenes[active_scene]),
      }
    );

    if (res.ok) {
      const json = await res.json();
      let result = JSON.stringify(json);
      

      if (json.status == true) {
        addNotification({
          text: '"Default view" bolo nastavené!',
          position: "top-center",
          removeAfter: 4000,
          type: "success",
        });
      } else {
        addNotification({
          text: 'Chyba pri ukladaní "default view"!',
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
      }
    } else {
      throw new Error(data);
    }
  }

  // Načítanie default view scény
  function load_default_value() {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call(
      "moveto(" +
        settings.scenes[active_scene].hlookat +
        "," +
        settings.scenes[active_scene].vlookat +
        ")"
    );
  }

  // Načítanie default view scény
  function move_scene() {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call(
      "lookto(" +
        settings.scenes[active_scene].hlookat +
        "," +
        settings.scenes[active_scene].vlookat +
        "," +
        settings.scenes[active_scene].fov +
        ")"
    );
  }

  let tmp_active_index = 0;
  // Nastavenie start scene
  async function set_as_start_scene() {
    settings.configuration.start_scene = settings.scenes[active_scene].id;

    const res = await fetch(
      userdata['base_path'] + "assets/php/saveStartScene.php",
      {
        method: "POST",
        body: JSON.stringify(settings.configuration.start_scene),
      }
    );

    if (res.ok) {
      const json = await res.json();
      let result = JSON.stringify(json);
      

      if (json.status == true) {
        addNotification({
          text: "Úvodná scéna bola nastavená!",
          position: "top-center",
          removeAfter: 4000,
          type: "success",
        });
      } else {
        addNotification({
          text: "Chyba pri ukladaní úvodnej scény!",
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
      }
    } else {
      throw new Error(data);
    }
  }

  // Uloženie nových dát scény
  async function save_scene_settings(id) {
    //update_new_hotspot();
    //get_current_hts_position();
    
    var krpano = document.getElementById("krpanoSWFObject");
    var tmp_hlookat = krpano.get('view.hlookat');
    var tmp_vlookat = krpano.call("get('view.vlookat')");
    order_scenes(settings);

    console.log(settings.scenes);
    
    const res = await fetch(
      userdata['base_path'] + "assets/php/saveSceneSettings.php",
      {
        method: "POST",
        body: JSON.stringify(settings.scenes),
      }
    );

    if (res.ok) {
      const json = await res.json();
      let result = JSON.stringify(json);
      

      if (json.status == true) {
        switch ( id ) {
          case "scenes":
            addNotification({
              text: "Nastavenia scény boli uložené!",
              position: "top-center",
              removeAfter: 4000,
              type: "success",
            });  
          break;

          case "hotspot":
            addNotification({
              text: "Nastavenia hotspotov boli uložené!",
              position: "top-center",
              removeAfter: 4000,
              type: "success",
            });  
          break;
        
          default:
            addNotification({
              text: "Nastavenia boli uložené!",
              position: "top-center",
              removeAfter: 4000,
              type: "success",
            });  
          break;
        } 
        
        
        
        krpano.call('loadpano('+ krpano_xml_url +'?t=' + new Date().getTime() +')');
        krpano.call("loadscene(" + active_scene_id +")");
        krpano.call("set('view.hlookat', " + tmp_hlookat + ")");

        
        
        for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
            let spotname = settings.scenes[active_scene].hotspots[index].name

            krpano.set("hotspot["+spotname+"].onclick", function(hs) {
              edit_hotspot_id = krpano.get("hotspot["+spotname+"].name");
              
            });
          }

        if (
          hotspots_view == 'add_hotspot'
        ) {

          for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
            //edit_hotspot_id = settings.scenes[active_scene].hotspots[index].id;
          }
          
          //hotspots_view = 'edit_hotspot'
        }
        
      }
      else {
        addNotification({
          text: "Chyba pri ukladaní nastavení scény!",
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
      }
    } else {
      throw new Error(data);
    }

    adding_new_hts = false;
  }
  
  let start_scene;

  //   ../../assets/tour/tour.xml

  const krpano_xml_url = userdata['base_path'] + "assets/krpano/tour.xml";

  let _floor = 1;

  $: {
    _floor;
    let krpano = document.getElementById("krpanoSWFObject");
    if (
      krpano !=  null
      ) {
        
        krpano.set("floor", _floor);
        krpano.call("loadscene(" + active_scene + ", null, KEEPVIEW, BLEND(1));");
    }
    

  }
  function load_krpano () {
    
		embedpano({
      initvars : {
				scene_name : "tmp",
				show_hs : true,
				floor : _floor
			},
    hooks: {
        loadSceneData(scene) {
        },
        sceneChanged(scene) {
        }
    },
			xml: krpano_xml_url, 
			target:"pano", 
			html5:"only", 
      showlog:"true",
			mobilescale:1.0, 
			
			onready: function (krpano) {

        krpano = krpano.get("global");
				krpano.call("loadscene(" + start_scene +")");

        pano = document.getElementById("pano");

        krpano.events.onnewpano = function() {
          
          let action = 'draghotspot();';
					current_scene = krpano.get("xml.scene").replace('scene_','').toUpperCase(); 
          if (
            settings.scenes[active_scene].hotspots
          ) {
            for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
              let spotname = settings.scenes[active_scene].hotspots[index].name;           

              if (
                spotname.indexOf('next-node') > -1
              ) {
                
                krpano.call("set(hotspot[" + spotname + "_empty].enabled, false);");
                krpano.call("set(hotspot[" + spotname + "_empty].visible, false);");
                krpano.call("remove(hotspot[" + spotname + "_empty].linkedscene");
                krpano.call("remove(hotspot[" + spotname + "].linkedscene");
                
                
                // krpano.call("set(hotspot[" + spotname + "_empty].onover, test);");
                // krpano.call("set(hotspot[" + spotname + "_empty].onout, test);");
              }
              
              krpano.call("set(hotspot[" + spotname + "].ondown, " + action + ");");
              krpano.call("set(hotspot[" + spotname + "_empty].ondown, " + action + ");");

              krpano.set("hotspot["+spotname+"].onout", function(hs) {
                console.log(settings.scenes[active_scene].hotspots[index]);
                settings.scenes[active_scene].hotspots[index].ath = krpano.get("hotspot["+spotname+"].ath");
                settings.scenes[active_scene].hotspots[index].atv = krpano.get("hotspot["+spotname+"].atv");
              });

              // krpano.set("hotspot["+spotname+"_empty].onout", function(hs) {
                
              //   let updated_ath = krpano.get("hotspot["+spotname+"_empty].ath");
              //   let updated_atv = krpano.get("hotspot["+spotname+"_empty].atv");
                
                
              //   settings.scenes[active_scene].hotspots[index].ath = krpano.get("hotspot["+spotname+"].ath");
              //   settings.scenes[active_scene].hotspots[index].atv = krpano.get("hotspot["+spotname+"].atv");
              //   krpano.set("hotspot["+spotname+"].ath," + updated_ath);
              //   krpano.set("hotspot["+spotname+"].atv," + updated_atv);


              // });
            }
          }

          
        };
			}		
		});
  	}

  fetchData();

  let current_scene;
  let edit_hotspot_id = null;

  function remove_hotspot(name, settings) {
    
    var krpano = document.getElementById("krpanoSWFObject");
    settings.scenes[active_scene].hotspots = settings.scenes[active_scene].hotspots.filter((value) => value.name !== name);  
    krpano.call('removehotspot('+ (name) + '));');
    save_scene_settings();
  }

  function oznac_hotspot($name) {
    let krpano = document.getElementById("krpanoSWFObject");

    for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
      if (
        settings.scenes[active_scene].hotspots[index].name == $name
      ) {
        
        switch (settings.scenes[active_scene].hotspots[index].type) {
          case 'next-node' : 
            krpano.call("set(hotspot[" + $name + "].url, assets/krpano/icons/hts-ring-active.svg);");
            krpano.call("set(hotspot[" + $name + "].distorted, true);");
          break;

          case 'infopanel-v1' : 
            krpano.call("set(hotspot[" + $name + "].url, assets/krpano/icons/hts_infopanel_active.svg);");
          break;

          case 'videopanel-v1' : 
          case 'videopanel-v2' : 
            krpano.call("set(hotspot[" + $name + "].url, assets/krpano/icons/hts_videopanel_active.svg);");
          break;
        }
      }
    }
  }

  function odznac_hotspot($name) {
    let krpano = document.getElementById("krpanoSWFObject");

    for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
      if (
        settings.scenes[active_scene].hotspots[index].name == $name
      ) {

        switch (settings.scenes[active_scene].hotspots[index].type) {
          case 'next-node' : 
            krpano.call("set(hotspot[" + $name + "].url, assets/krpano/icons/hts-ring-w.svg);");
          break;

          case 'infopanel-v1' : 
            krpano.call("set(hotspot[" + $name + "].url, assets/krpano/icons/hts_infopanel.svg);");
          break;

          case 'videopanel-v1' : 
          case 'videopanel-v2' : 
            krpano.call("set(hotspot[" + $name + "].url, assets/krpano/icons/hts_videopanel.svg);");
          break;
        }
      }
    }
  }

  function natoc_scenu($hlookat, $vlookat) {
    let krpano = document.getElementById("krpanoSWFObject");

    krpano.call(
      "moveto(" +
        $hlookat +
        "," +
        $vlookat +
        ")"
    );
  }

  let new_tag = null;

  function add_new_tag() {
    settings.scenes[active_scene].tags.push(new_tag);
      new_tag = null;
      save_scene_settings();
  }

  function onKeyDown(e) {
    if(
      e.key === 'Enter'
    ) {
      add_new_tag();
    }
  }

  // upload loga na server
  async function uploadFile($data, $par, $id) {
    let formData = new FormData();           
    formData.append("file", fileupload.files[0]);
    
    const res = await fetch(userdata['base_path'] + 'assets/php/saveLogo.php', {
    method: "POST", 
    body: formData
    });    

    if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
        addNotification({
                text: "Obrázok bol úspešne uploadnutý!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
            });  

            
            if (json.logo_url) {

            // for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
              
            //   switch (hotspots_view) {
            //     case 'add_hotspot':
            //       if (
            //         settings.scenes[active_scene].hotspots[index].id == max+1
            //       ) {
            //         settings.scenes[active_scene].hotspots[index].data.img = json.logo_url;
            //         new_hts_img = json.logo_url;
            //       }  
            //       break;
              
            //     case 'edit_hotspot' : 
            //     //console.log(edit_hotspot_id + ' | ' + hotspots_view);
            //       if (
            //         settings.scenes[active_scene].hotspots[index].name == edit_hotspot_id
            //       ) {
            //         //console.log('áno sedí to');
            //         settings.scenes[active_scene].hotspots[index].data.img = json.logo_url;
            //         new_hts_img = json.logo_url;
            //       }
            //       break;
                  
            //     default:
            //       $data['img'] = json.logo_url;
            //       break;
            //   }
            // }

            if (
              $par == 'edit'
            ) {
              for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
                if (
                  settings.scenes[active_scene].hotspots[index].name == $id
                ) {
                //console.log('áno sedí to');
                  settings.scenes[active_scene].hotspots[index].data.img = json.logo_url;
                }
              }
            }

            else {
              hts_object.data.img = json.logo_url;
            }

            

            

            
                                
              //save_scene_settings();
            }
        }

        else {
        addNotification({
                text: "Chyba : Logo nebolo uploadnuté!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
            });  
        }
    }  
  }

// zapnutie / vypnutie modalu
import { modal } from '../../store.js';

let show_modal;
modal.subscribe(value => {
  show_modal = value;
});

let url = '';
let filter = false

let Modal;
let modal_type = 'info-v1';

const sleep = ms => new Promise(f => setTimeout(f, ms));

onMount(async () => {
  //await sleep(1000); // simulate network delay
  Modal = (await import('../../tour_components/Modal.svelte')).default
});

let max = 0;

// nove premenne;
let adding_new_hts = false;
let edit_hts = false;
let edit_hts_id = '';
let hts_type = 'next-node';
let default_hts_object = {
  id : 0,
  name : 'hts_name',
  title : '',
  description : '',
  type : 'next-node',
  style : 'floorspot',
  "skin-id" : 'floorspot',
  target : 'nenastavené',
  new_target : "1",
  enabled: true,
  ath : 0,
  atv : 0,
  depth : "4", // XL
  depth_value : 1000, // XL
  rx : -40, // XL
  ry : 0, 
  data : {
    title : 'názov pre modal',
    headline : '',
    img : "img/38.jpg",
    subtitle : "",
    content : '',
    video_url : "video url",
    local_video_url : "videos/local_video.mp4"
  }

};
let hts_object = {};

let depth_values = {
  0 : { // xs
    depth_value : 3000,
    rx : -82
  },
  1 : { // s 
    depth_value : 2900,
    rx : -75
  },
  2 : { // m
    depth_value : 2000,
    rx : -70
  },
  3 : { // l
    depth_value : 1400,
    rx : -60
  },
  4 : { // xl
    depth_value : 1000,
    rx : -40
  }
}

// ak sa zmení hts_typ
$: {
  hts_object.type;

  
  let krpano = document.getElementById("krpanoSWFObject");

  if (
    krpano
  ) {
    switch (hts_object.type) {
      case 'next-node' : 
        krpano.call("set(hotspot[" + hts_object.name + "].url, assets/krpano/icons/hts-ring-active.svg);");
        krpano.call("set(hotspot[" + hts_object.name + "].distorted, true);");
      break;

      case 'infopanel-v1' : 
        krpano.call("set(hotspot[" + hts_object.name + "].url, assets/krpano/icons/hts_infopanel.svg);");
        krpano.call("set(hotspot[" + hts_object.name + "].depth,1000);");
        krpano.call("set(hotspot[" + hts_object.name + "].rx,0);");
        krpano.call("set(hotspot[" + hts_object.name + "].distorted, false);");
      break;
    }
  }

  switch (hts_object.type) {
      case 'infopanel-v1' :
        hts_object['skin-id'] = 'circle';
        hts_object.style = 'circle';
        modal_type = 'info-v1';
        break;
      case 'infopanel-v2' :
        hts_object['skin-id'] = 'circle';
        modal_type = 'info-v2';
        break;
      case 'infopanel-v3' :
        hts_object['skin-id'] = 'circle';
        modal_type = 'info-v3';
        break;
      case 'videopanel-v1' :
        hts_object['skin-id'] = 'circle';
        modal_type = 'video-v1';
      break;

      case 'videopanel-v2' :
        hts_object['skin-id'] = 'circle';
        modal_type = 'video-v2';
      break;

      case 'next-node':
        hts_object['skin-id'] = 'floorspot';
      break;
    }
}

// vytvorenie nového hotspotu
function generate_hts() {
  adding_new_hts = true;
  hts_object = default_hts_object;

  let max = 0;

  // nájdenie MAX pre nový hotspot
  for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
      if (
        settings.scenes[active_scene].hotspots[index].id >= max
      ) {
        max = settings.scenes[active_scene].hotspots[index].id + 1
      }
    }

    

    // Definícia názvu hotspotu
    let spotname = '';

    switch (hts_object.type) {
      case 'next-node':
        spotname = 'next-node_'+max;    
        break;
      case 'infopanel-v1' :
        spotname = 'info_'+max;
        break;
      case 'infopanel-v2' :
        spotname = 'info_'+max;
        break;
      case 'infopanel-v3' :
        spotname = 'info_'+max;
        break;
      case 'videopanel-v1' :
        spotname = 'video_'+max;
        break;
      default:
        spotname = 'hts_'+max;
        break;
    }
    hts_object.id = max;
    hts_object.name = spotname;

    let krpano = document.getElementById("krpanoSWFObject");
    // nastavenie ATH a ATV z aktuálneho pohľadu
    hts_object.ath = Number( krpano.get("view.hlookat") );
    hts_object.atv = Number( krpano.get("view.vlookat") );

    // Akcia po kliku na hotspot - drag 
    let action = 'draghotspot();';
    krpano.call("addhotspot(" + spotname + ");");

    switch (hts_object.type) {
      case 'next-node' : 
        krpano.call("set(hotspot[" + spotname + "].url, assets/krpano/icons/hts-ring-active.svg);");
        krpano.call("set(hotspot[" + spotname + "].depth,"+hts_object.depth_value+");");
        krpano.call("set(hotspot[" + spotname + "].rx,"+hts_object.rx+");");
        krpano.call("set(hotspot[" + spotname + "].distorted, true);");
      break;

      case 'infopanel-v1' : 
        krpano.call("set(hotspot[" + spotname + "].url, assets/krpano/icons/hts_infopanel.svg);");
      break;
    }
    
    krpano.call("set(hotspot[" + spotname + "].ath,"+hts_object.ath+");");
    krpano.call("set(hotspot[" + spotname + "].atv,"+hts_object.atv+");");
    
    
    krpano.call("set(hotspot[" + spotname + "].alpha, 1);");
    krpano.call("set(hotspot[" + spotname + "].id,"+max+");");
    krpano.call("set(hotspot[" + spotname + "].ondown, " + action + ");");

    krpano.set("hotspot["+spotname+"].onout", function(hs) {
      hts_object.ath = krpano.get("hotspot["+spotname+"].ath");
      hts_object.atv = krpano.get("hotspot["+spotname+"].atv");
    });
}

// úprava existujúceho hotspotu
function modify_hts($id) {
  adding_new_hts = false;
  hotspots_view = 'edit';
  edit_hts_id = $id;
  edit_hts = true;
  

  let action = 'draghotspot();';
  let krpano = document.getElementById("krpanoSWFObject");
  current_scene = krpano.get("xml.scene").replace('scene_','').toUpperCase();

  if (
    settings.scenes[active_scene].hotspots
  ) {
    for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
      let spotname = settings.scenes[active_scene].hotspots[index].name;
      
      krpano.call("set(hotspot[" + spotname + "].ondown, " + action + ");");
    }
  }
}

// zápis nového hotspotu do JSON-u
async function add_hts_to_json() {
  

  let max = 0;

  // nájdenie MAX pre nový hotspot
  for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
      if (
        settings.scenes[active_scene].hotspots[index].id >= max
      ) {
        max = settings.scenes[active_scene].hotspots[index].id + 1
      }
    }

    

    // Definícia názvu hotspotu
    let spotname = '';
    let skin_id = '';

    switch (hts_object.type) {
      case 'next-node':
        spotname = 'next-node_'+max;    
        skin_id = 'floorspot';
        
        break;
      case 'infopanel-v1' :
        spotname = 'info_'+max;
        break;
      case 'infopanel-v2' :
        spotname = 'info_'+max;
        break;
      case 'infopanel-v3' :
        spotname = 'info_'+max;
        break;
      case 'videopanel-v1' :
        spotname = 'video_'+max;
        break;
      case 'videopanel-v2' :
        spotname = 'video_'+max;
        break;        
      default:
        spotname = 'hts_'+max;
        break;
    }

  if (settings) {
    settings.scenes[active_scene].hotspots = [...settings.scenes[active_scene].hotspots, {
      id: max,
      name : spotname,
      title : hts_object.title,
      description : hts_object.description,
      type : hts_object.type,
      "skin-id" : hts_object['skin-id'],
      target : hts_object.target,
      new_taget : hts_object.new_taget,
      enabled: hts_object.enabled,
      ath : hts_object.ath,
      atv : hts_object.atv,
      depth : hts_object.depth,
      depth_value : hts_object.depth_value,
      rx : hts_object.rx,
      ry : hts_object.ry,
      data : {
        title : hts_object.title,
        headline : hts_object.data.headline,
        img : hts_object.data.img,
        subtitle : hts_object.data.subtitle,
        content : hts_object.data.content,
        video_url : hts_object.data.video_url,
        local_video_url : hts_object.data.local_video_url
      }
    }];
  }

  save_scene_settings();
  hts_object = {};
  hotspots_view = 'list_view';
  adding_new_hts = false;
}

// zrušenie editovania hotspotu
function cancel_adding() {
  hotspots_view = 'list_view';
  hts_object = {};
  adding_new_hts = false;
  edit_hts_id = '';
  edit_hts = false;
}

function convert_depth($type, $id, $val) {
  let krpano = document.getElementById("krpanoSWFObject");
  switch ($type) {
    case 'new_hts' : 
      
      hts_object.depth_value = depth_values[Number(hts_object.depth)].depth_value;
      hts_object.rx = depth_values[Number(hts_object.depth)].rx;
      hts_object.ry = 0;

      krpano.call("set(hotspot[" + hts_object.name + "].depth,"+ depth_values[Number(hts_object.depth)].depth_value +");");
      krpano.call("set(hotspot[" + hts_object.name + "].rx,"+ depth_values[Number(hts_object.depth)].rx +");");
      krpano.call("set(hotspot[" + hts_object.name + "].ry, 0);");
    break;
    case 'edit_hts' : 
    
      for (let index = 0; index < settings.scenes[active_scene].hotspots.length; index++) {
        if (
          settings.scenes[active_scene].hotspots[index].name == $id
        ) {
          settings.scenes[active_scene].hotspots[index].depth_value = depth_values[$val].depth_value;
          settings.scenes[active_scene].hotspots[index].rx = depth_values[$val].rx;
          settings.scenes[active_scene].hotspots[index].ry = 0;
        }
      }

      krpano.call("set(hotspot[" + $id + "].depth,"+ depth_values[Number($val)].depth_value +");");
      krpano.call("set(hotspot[" + $id + "].rx,"+ depth_values[Number($val)].rx +");");
      krpano.call("set(hotspot[" + $id + "].ry, 0);");
      
    break;
  }
}

function update_depth($hts_id, $type, $val) {
  
  let krpano = document.getElementById("krpanoSWFObject");

  switch ($type) {
    case "rx" : 
      krpano.call("set(hotspot[" + $hts_id + "].rx,"+$val+");");
    break;

    case "ry" : 
      krpano.call("set(hotspot[" + $hts_id + "].ry,"+$val+");");
    break;

    
    case "depth" : 
      
      krpano.call("set(hotspot[" + $hts_id + "].depth,"+ depth_values[Number($val)].depth_value +");");
      krpano.call("set(hotspot[" + $hts_id + "].rx,"+ depth_values[Number($val)].rx +");");
      krpano.call("set(hotspot[" + $hts_id + "].ry, 0 );");
    break;
  }
}
  $: {
  hts_object.depth;
  

  if (
    hts_object.depth
  ) {
    //convert_depth('new_hts');
  }

}

let banner_width = 515 * 2;
let banner_height = 200 * 2;

let simulate_screenshot = true;
    
</script>

<svelte:head>
  <script src="{userdata['base_path']}assets/krpano/pano.js" on:load={initializeRemarkable}></script>
</svelte:head>

<div id="wrapper">
  <Aside/>
  
    <main in:fade>
      <div id="scene-container">
            {#if showTour}
                <div id="pano">
                    <noscript><table style="width:100%;height:100%;"><tr style="vertical-align:middle;"><td><div style="text-align:center;">ERROR:<br/><br/>Javascript not activated<br/><br/></div></td></tr></table></noscript>
                    {#if live_preview === true}
                    <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{hts_object.title}" settings="{hts_object}" url="{url}" filter="{filter}"  position="pano" id="{edit_hotspot_id}" current_scene="{current_scene}" prefix="{userdata['base_path']}"/>
                    {/if}

                  {#if simulate_screenshot}
                     <div id="screenshot" style="width: {banner_width}px; height: {banner_height}px;"/>
                  {/if}
                </div>
                
                <div id="scene-settings">
                    <div class="core">
                        <!-- nastavenia scény -->
                        {#if selected_core_type === "scene_settings"}
                          <h3>{$t("scenes.core.type.settings")}</h3>
                          {#if loading_data == false}
                              <!-- ID panorámy -->    
                              <div>    
                                  <label for="id">ID:</label>
                                  <p name="id">{settings.scenes[active_scene].id}</p>
                              </div>
                              
                              <!-- name -->
                              <div>
                                  <label for="name">{$t("scenes.settings.name")}:</label>
                                  <input type="text" name="name" bind:value="{settings.scenes[active_scene].name}">
                              </div>

                              <!-- Tags -->
                              <div>
                                  <label for="tags">{$t("scenes.settings.tags")}:</label>
                                  <div class="tag-wrapper">
                                    <div class="add-tag">
                                  <input type="text" name="tags" bind:value="{new_tag}" on:keypress="{(e) => onKeyDown(e)}">
                                  <i class="fa-solid fa-circle-plus" 
                                    on:click="{() => add_new_tag()}"
                                  ></i>
                                </div>
                                <div class="tags">
                                  {#each settings.scenes[active_scene].tags as tag}
                                    <div class="tag">
                                      {tag}<i class="fa-solid fa-circle-xmark" on:click="{() => settings.scenes[active_scene].tags = settings.scenes[active_scene].tags.filter(m => m !== tag)}"></i>
                                    </div>
                                     <!-- content here -->
                                  {/each}
                                  </div>
                              </div>
                              </div>
                              <!-- Enabled -->
                              <div>
                                  <label for="enabled">{$t("scenes.settings.enabled")}</label>
                                  <input type="checkbox" name="enabled" bind:checked="{settings.scenes[active_scene].enabled}">
                              </div>

                              <!-- Hlookat -->
                              <div class="null">
                                  <label for="hlookat">{$t("scenes.settings.hlookat")}:</label>
                                  <input type="number" name="hlookat" bind:value="{settings.scenes[active_scene].hlookat}" on:change="{() => move_scene()}">
                                  <i class="fa-solid fa-circle-xmark" 
                                    on:click="{() => settings.scenes[active_scene].hlookat = 0}"
                                    on:click="{() => move_scene()}"
                                    ></i>
                              </div>
                              <!-- Vlookat -->
                              <div class="null">
                                  <label for="vlookat">{$t("scenes.settings.vlookat")}:</label>
                                  <input type="number" name="vlookat" bind:value="{settings.scenes[active_scene].vlookat}" on:change="{() => move_scene()}">
                                  <i class="fa-solid fa-circle-xmark" 
                                    on:click="{() => settings.scenes[active_scene].vlookat = 0}"
                                    on:click="{() => move_scene()}"
                                    ></i>
                                </div>
                              <!-- Fov -->
                              <div>
                                  <label for="fov">{$t("scenes.settings.fov")}:</label>
                                  <input type="number" name="fov" bind:value="{settings.scenes[active_scene].fov}" on:change="{() => move_scene()}">
                              </div>                                    
                              <!-- Title -->
                              <div>
                                  <label for="title">{$t("scenes.settings.title")}:</label>
                                  <input type="text" name="title" bind:value="{settings.scenes[active_scene].title}">
                              </div> 
                              <!-- Description -->
                              <div>
                                  <label for="description">{$t("scenes.settings.description")}:</label>
                                  <input type="text" name="description" bind:value="{settings.scenes[active_scene].description}">
                                  
                              </div>
                              <!-- North -->
                              <div>
                                  <label for="north">{$t("scenes.settings.north")}:</label>
                                  <input type="number" name="north" bind:value="{settings.scenes[active_scene].north}">
                              </div>     
                              <!-- Custom field #1 -->
                              <div>
                                  <label for="cf1">{$t("scenes.settings.cf1")}:</label>
                                  <input type="text" name="cf1" bind:value="{settings.scenes[active_scene].custom_field_1}">
                              </div> 
                              <!-- Custom field #2 -->
                              <div>
                                  <label for="cf2">{$t("scenes.settings.cf2")}:</label>
                                  <input type="text" name="cf2" bind:value="{settings.scenes[active_scene].custom_field_2}">
                              </div> 
                              <!-- Custom field #3 -->
                              <div>
                                  <label for="cf3">{$t("scenes.settings.cf3")}:</label>
                                  <input type="text" name="cf3" bind:value="{settings.scenes[active_scene].custom_field_3}">
                              </div> 
                              <!-- Custom field #4 -->
                              <div>
                                  <label for="cf4">{$t("scenes.settings.cf4")}:</label>
                                  <input type="text" name="cf4" bind:value="{settings.scenes[active_scene].custom_field_4}">
                              </div> 
                              <!-- Custom field #5 -->
                              <div>
                                  <label for="cf5">{$t("scenes.settings.cf5")}:</label>
                                  <input type="text" name="cf5" bind:value="{settings.scenes[active_scene].custom_field_5}">
                              </div> 
                              <!-- Custom field #1 -->
                              <div>
                                  <label for="cf6">{$t("scenes.settings.cf6")}:</label>
                                  <input type="text" name="cf6" bind:value="{settings.scenes[active_scene].custom_field_6}">
                              </div> 
                              <!-- Custom field #7 -->
                              <div>
                                  <label for="cf7">{$t("scenes.settings.cf7")}:</label>
                                  <input type="text" name="cf7" bind:value="{settings.scenes[active_scene].custom_field_7}">
                              </div> 
                              <!-- Custom field #8 -->
                              <div>
                                  <label for="cf8">{$t("scenes.settings.cf8")}:</label>
                                  <input type="text" name="cf8" bind:value="{settings.scenes[active_scene].custom_field_8}">
                              </div> 
                              <!-- Custom field #1 -->
                              <div>
                                  <label for="cf9">{$t("scenes.settings.cf9")}:</label>
                                  <input type="text" name="cf9" bind:value="{settings.scenes[active_scene].custom_field_9}">
                              </div> 
                              <!-- Custom field #10 -->
                              <div>
                                  <label for="cf10">{$t("scenes.settings.cf10")}:</label>
                                  <input type="text" name="cf10" bind:value="{settings.scenes[active_scene].custom_field_10}" on:change={() => settings.scenes[active_scene].thumb = settings.scenes[active_scene].custom_field_10 + '_' + settings.scenes[active_scene].name + "_thumb.jpg"}>
                              </div>                                                                                                                                                                                                                                                                                                                                                                                                     
                              <!-- Thumbnail -->    
                              <div>    
                                  <label for="thumb">{$t("scenes.settings.thumbnail")}:</label>
                                  <p name="thumb">{settings.scenes[active_scene].thumb}</p>
                              </div>

                               <!-- Enabled -->
                               <div>
                                <label for="floor_enabled">{$t("scenes.settings.floor_enabled")}</label>
                                <input type="checkbox" name="floor_enabled" bind:checked="{settings.scenes[active_scene].floor_enabled}">
                              </div>

                              {#if settings.scenes[active_scene].floor_enabled}
                                <div>    
                                  <label for="floor">{$t("scenes.settings.floor")}:</label>
                                  <input type="number" name="floor" bind:value="{_floor}">
                              </div> 
                              {/if}

                              <!-- Scene IMG example -->    
                              <div>    
                                <label for="cubes">{$t("scenes.settings.tiles")}:</label>
                                {#if settings.scenes[active_scene].floor_enabled == true}
                                  <p name="cubes">{settings.scenes[active_scene].custom_field_10}_{settings.scenes[active_scene].name}_20f_b.jpg</p>
                                {:else}
                                  <p name="cubes">{settings.scenes[active_scene].custom_field_10}_{settings.scenes[active_scene].name}_b.jpg</p>
                                {/if}
                            </div>
                              <!-- Order -->    
                              <div>    
                                  <label for="order">{$t("scenes.settings.order")}:</label>
                                  <input type="number" name="order" bind:value="{settings.scenes[active_scene].order}">
                              </div>                                    

                              <!-- Načítanie default view -->
                              <div class="separate">
                                  <label for="load_new_view">{$t("scenes.load.default.view")}:</label>
                                  <button class="default-view" name="load_new_view" on:click={() => load_default_value()}>
                                      <i class="fa-solid fa-arrows-rotate" />
                                      {$t("scenes.load.default.view.button")}
                                  </button>
                              </div>

                              <!-- nové nastavenie default view -->
                              <div>
                                  <label for="set_new_view">{$t("scenes.set.default.view")}:</label>
                                  <button class="default-view" name="set_new_view" on:click={() => set_new_default_view()}>
                                      <i class="fa-solid fa-gear" />
                                      {$t("scenes.set.default.view.button")}</button>
                              </div>

                              <!-- nastaviť ako štart panoráma -->
                              <div>
                                  <label for="set_new_view">{$t("scenes.set.start.scene")}:</label>
                                  <button class="start-scene" name="set_new_view" on:click={() => set_as_start_scene()}>
                                      <i class="fa-solid fa-flag-checkered" />
                                      {$t("scenes.set.start.scene.button")}
                                  </button>
                              </div>
                          {/if}
                             
                        <!-- hotspoty scény -->
                        {:else if  selected_core_type === "scene_hotspots"}
                          <h3>{$t("scenes.core.type.hotspots")}</h3>

                          <!-- zoznam hotspotov -->
                          {#if loading_data == false && hotspots_view == 'list_view'}
                            <button class="add-hotspot" on:click="{() => generate_hts()}" on:click="{() => hotspots_view = 'add_hotspot'}">Pridať hotspot</button>
                              <div class="hotspots-list">
                                {#each settings.scenes as data}
                                  {#if data.hotspots && data.id == active_scene_id}
                                    {#each data.hotspots as hotspot, i}
                                      <div class="hotspot" on:mouseenter="{() => oznac_hotspot(hotspot.name)}" on:mouseleave="{() => odznac_hotspot(hotspot.name)}" on:click="{() => natoc_scenu(hotspot.ath, hotspot.atv)}">
                                        <div class="heading">
                                            <span class="{hotspot.type}">
                                              {#if hotspot.type == 'next-node'}
                                                <i class="fa-solid fa-panorama"></i>  
                                              {:else if  hotspot.type == 'infopanel-v1' || hotspot.type == 'infopanel-v2' || hotspot.type == 'infopanel-v3' }
                                              <i class="fa-solid fa-info"></i>
                                              {:else if  hotspot.type == 'videopanel-v1' || hotspot.type == 'videopanel-v2' }
                                              <i class="fa-solid fa-video"></i>
                                              {:else if  hotspot.type == 'url'}
                                                <i class="fa-solid fa-link"></i>
                                              {:else if  hotspot.type == 'attribute'}
                                              <i class="fa-solid fa-list-check"></i>                                                  
                                              {:else if  hotspot.type == 'tag'}
                                              <i class="fa-solid fa-tag"></i>
                                              {:else if  hotspot.type == 'localvideo'}
                                              <i class="fa-solid fa-video"></i>
                                              {:else if  hotspot.type == 'model'}
                                              <i class="fa-solid fa-cube"></i>
                                              {:else}
                                                  <!-- else content here -->
                                              {/if}
                                              </span>
                                            <div class="hotspot-info">
                                              <p class="title">{hotspot.name}</p>
                                              {#if hotspot.type == 'next-node'}
                                                <p class="description"><span class:warning="{hotspot.target === 'nenastavené'}">{hotspot.target} | {#if hotspot.depth == 0}XS{/if}{#if hotspot.depth == 1}S{/if}{#if hotspot.depth == 2}M{/if}{#if hotspot.depth == 3}L{/if}{#if hotspot.depth == 4}XL{/if} | {hotspot.rx}</span></p>
                                              {:else}
                                                <p class="description">{hotspot.title}</p>
                                              {/if}
                                              
                                              
                                            </div>
                                          </div>
                                        <div class="setting">
                                          <i class="fa-solid fa-pen-to-square" 
                                            on:click="{() => modify_hts(hotspot.name)}"
                                            ></i>
                                          <i class="fa-solid fa-trash-can" on:click="{() => remove_hotspot(hotspot.name, settings)}"></i>
                                        </div>
                                      </div> 
                                  {/each}
                                {/if}
                                {/each}        
                              </div>
                          {/if}

                          <!-- pridanie nového hotspotu -->
                          {#if loading_data == false && hotspots_view == 'add_hotspot'}
                            <div class="add-hotspot-buttons">
                              <button class="cancel-hotspot"on:click="{() => cancel_adding()}">Zrušiť</button>
                              
                            </div>
                            <!-- Hotspot Type -->
                            <div>
                              <label for="hotspot-type">Typ hotspotu</label>
                              <select name="hotspot-type" bind:value="{hts_object.type}">
                                {#each hotspot_type as type}
                                  <option value="{type}">{type}</option>
                                {/each}
                              </select>
                            </div>

                            <!-- title -->
                            <div>
                              <label for="title">Title:</label>
                              <input type="text" name="title" bind:value={hts_object.title}>
                            </div> 

                            <!-- Pan -->
                            <div>
                              <label for="pan">Pan:</label>
                              <input type="number" name="pan" bind:value={hts_object.ath}>
                            </div> 
                            <!-- Tilt -->
                            <div>
                              <label for="tilt">Tilt:</label>
                              <input type="number" name="tilt" bind:value={hts_object.atv}>
                            </div>  

                            {#if  hts_object.type === 'next-node' && hts_object['skin-id'] === "floorspot"}
                               <!-- Depth -->
                              <div>
                                <label for="depth-type">Depth:</label>
                                <select name="depth-type" bind:value="{hts_object.depth}" on:change={() => convert_depth('new_hts')}>
                                  <option value="0">XS</option>
                                  <option value="1">S</option>
                                  <option value="2">M</option>
                                  <option value="3">L</option>
                                  <option value="4">XL</option>
                              </div>  

                              <!-- RX -->
                              <div>
                                <label for="rx">RX:</label>
                                <input type="number" name="rx" bind:value={hts_object.rx} on:change={() => update_depth(hts_object.name, "rx", hts_object.rx)}>
                              </div>  
                              <!-- RY -->
                              <div>
                                <label for="ry">RY:</label>
                                <input type="number" name="ry" bind:value={hts_object.ry} on:change={() => update_depth(hts_object.name, "ry", hts_object.ry)}>
                              </div>  
                            {/if}



                            <!-- Enabled -->
                            <div>
                              <label for="enabled">Zapnutý</label>
                              <input type="checkbox" name="enabled" bind:checked={hts_object.enabled}>
                            </div>

                            {#if hts_object.type === 'next-node'}
                              <!-- Target node -->
                            <div>
                              <label for="hotspot-target">odkaz na panorámu</label>
                              <select name="hotspot-target" bind:value={hts_object.target}>
                                {#each settings.scenes as scene}
                                  {#if scene.id != active_scene_id}
                                    <option value="{scene.id}" >{scene.id}</option>
                                  {/if}
                                  
                                {/each}
                              </select>
                            </div>

                            <div>
                              <label for="hotspot-new-target">NEW odkaz na panorámu</label>
                                <select name="hotspot-new-target" bind:value={hts_object.new_target}>
                                    <option value="-3" >-3</option>
                                    <option value="-2" >-2</option>
                                    <option value="-1" >-1</option>
                                    <option value="1" >1</option>
                                    <option value="2" >-2</option>
                                    <option value="3" >3</option>
                                </select>
                              </div>
                            {/if}
                            
                          
                            
                            <!-- Description -->
                            <div>
                              <label for="title">Popis:</label>
                              <textarea type="text" name="description" bind:value="{hts_object.description}"></textarea>
                            </div>  
                            
                            {#if hts_object.type == 'infopanel-v1' || hts_object.type == 'infopanel-v2' || hts_object.type == 'infopanel-v3' || hts_object.type == 'videopanel-v1' || hts_object.type == 'videopanel-v2'}
                              <!-- Live preview -->
                              <div>
                                <label for="enabled">Live preview</label>
                                <input type="checkbox" name="enabled" bind:checked={live_preview}>
                              </div> 
                            {/if}

                            {#if hts_object.type === 'infopanel-v1'}
 
                                <div>
                                  <label for="headline">Headline</label>
                                  <input type="text" name="headline" bind:value="{hts_object.data.headline}">
                                </div> 
                                <div class="upload">
                                  <label for="fileupload">Obrázok</label><br>
                                  
                                  <input id="fileupload" type="file" name="fileupload" />
                                  <button id="upload-button" class="info-img" on:click="{() => uploadFile(hts_object.data.img)}"> Upload </button>
                                </div>
                                <div class="url">
                                  <p>URL : {hts_object.data.img}</p><br />
                                  <img src="{settings.configuration.settings.base_path}/assets/{hts_object.data.img}" alt="" class="hts-img">
                                </div>
                              
                                <div>
                                  <label for="subtitle">Podnadpis</label>
                                  <input type="text" name="subtitle" bind:value="{hts_object.data.subtitle}">
                                </div> 

                                <div>
                                  <label for="content">Obsah</label>
                                  <textarea name="content" bind:value="{hts_object.data.content}"/>
                                </div> 
                            {/if}

                            {#if hts_object.type === 'infopanel-v2'} 
                                <div>
                                  <label for="headline">Headline</label>
                                  <input type="text" name="headline" bind:value="{hts_object.data.headline}">
                                </div> 
                                                              
                                <div>
                                  <label for="subtitle">Podnadpis</label>
                                  <input type="text" name="subtitle" bind:value="{hts_object.data.subtitle}">
                                </div> 

                                <div>
                                  <label for="content">Obsah</label>
                                  <textarea name="content" bind:value="{hts_object.data.content}"/>
                                </div> 
                            {/if}

                            {#if hts_object.type === 'infopanel-v3'}
                                <div class="upload">
                                  <label for="fileupload">Obrázok</label><br>
                                  
                                  <input id="fileupload" type="file" name="fileupload" />
                                  <button id="upload-button" class="info-img" on:click="{() => uploadFile(hts_object.data.img)}"> Upload </button>
                                </div>
                                <div class="url">
                                  <p>URL : {hts_object.data.img}</p><br />
                                  <img src="{settings.configuration.settings.base_path}/assets/{hts_object.data.img}" alt="" class="hts-img">
                                </div> 
                            {/if}

                            {#if hts_object.type === 'videopanel-v1'}
                              <div>
                                <label for="url">Url: </label>
                                <input type="text" name="url" bind:value="{hts_object.data.video_url}" on:change={() => check_youtube_url(hts_object.data.video_url)}>
                              </div>
                            {/if}

                            {#if hts_object.type === 'videopanel-v2'}
                              <div>
                                <label for="url">Url: </label>
                                <input type="text" name="url" bind:value="{hts_object.data.local_video_url}">
                              </div>
                            {/if}

                            

                            <!-- Upload image -->
                            <!-- <div class="upload">
                              <label for="upload-image">Obrázok:</label>
                              <button class="upload" name="upload-image">
                                <i class="fa-solid fa-upload"></i>Načítať 
                              </button>
                            </div> -->
                          {/if}

                          <!-- editácia hotspotu -->
                          {#if loading_data == false && edit_hts == true}
                            <!-- tlačidlo zrušiť -->
                            <div class="add-hotspot-buttons">
                              <button class="cancel-hotspot" on:click="{() => cancel_adding()}">Zrušiť</button>
                            </div>
                            {#each settings.scenes[active_scene].hotspots as hotspot}
                              {#if hotspot.name == edit_hts_id}

                                <!-- Hotspot Type -->
                                <div>
                                  <label for="hotspot-type">Typ hotspotu</label>
                                    <select name="hotspot-type" bind:value="{hotspot.type}" on:change={() => modal_type = hotspot.type}>
                                      {#each hotspot_type as type}
                                        <option value="{type}">{type}</option>
                                      {/each}
                                    </select>     
                                </div>    

                                 <!-- title -->
                            <div>
                              <label for="title">Title:</label>
                              <input type="text" name="title" bind:value={hotspot.title}>
                            </div> 

                                <!-- Pan -->
                                <div>
                                  <label for="pan">Pan:</label>
                                  <input type="number" name="pan" bind:value="{hotspot.ath}">
                                </div> 

                                <!-- Tilt -->
                                <div>
                                  <label for="tilt">Tilt:</label>
                                  <input type="number" name="tilt" bind:value="{hotspot.atv}">
                                </div>  

                                {#if hotspot['skin-id'] === "floorspot"}
                                  <!-- Depth -->
                                  <div>
                                    <label for="depth-type">Depth:</label>
                                    <select name="depth-type" bind:value="{hotspot.depth}" on:change="{() => convert_depth("edit_hts", hotspot.name, hotspot.depth)}" on:change={() => update_depth(hotspot.name, "depth", hotspot.depth)}>
                                      <option value="0">XS</option>
                                      <option value="1">S</option>
                                      <option value="2">M</option>
                                      <option value="3">L</option>
                                      <option value="4">XL</option>
                                  </div>  

                                  <!-- RX -->
                                  <div>
                                    <label for="rx">RX:</label>
                                    <input type="number" name="rx" bind:value={hotspot.rx} on:change={() => update_depth(hotspot.name, "rx", hotspot.rx)}>
                                  </div>  
                                  <!-- RY -->
                                  <div>
                                    <label for="ry">RY:</label>
                                    <input type="number" name="ry" bind:value={hotspot.ry} on:change={() => update_depth(hotspot.name, "ry", hotspot.ry)}>
                                  </div>  

                                {/if}




                                <!-- Enabled -->
                                <div>
                                  <label for="enabled">Zapnutý</label>
                                  <input type="checkbox" name="enabled" bind:checked={hotspot.enabled}>
                                </div>  

                                <!-- Target node -->
                                {#if hotspot.type === 'next-node'}
                                  <div>
                                    <label for="hotspot-target">odkaz na panorámu</label>
                                    <select name="hotspot-target" bind:value="{hotspot.target}">
                                      {#each settings.scenes as scene}    
                                        {#if scene.id != active_scene_id}
                                          <option value="{scene.id}" >{scene.id}</option>
                                        {/if}
                                      {/each}
                                    </select>
                                  </div>

   
                                    <div>
                                      <label for="hotspot-new-target">NEW odkaz na panorámu {hotspot.new_target}</label>
                                      <select name="hotspot-new-target" bind:value={hotspot.new_target}>
                                          <option value="-3">-3</option>
                                          <option value="-2">-2</option>
                                          <option value="-1">-1</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3" >3</option>
                                      </select>
                                    </div>
          
                                {/if}
                                

                                <!-- Skin ID -->
                                <div class="skin-images">
                                  <label for="hotspot-type">Skin-ID</label>
                                  <select name="skin-ids" bind:value="{hotspot["skin-id"]}">
                                    {#if hotspot["skin-id"] == 'floorspot'}
                                      <option value="floorspot">floorspot</option>
                                    {/if}
                                    <option value="arrow">arrow</option>
                                    <option value="circle">circle</option>
                                    <option value="circle-title" disabled>circle-title</option>
                                  </select>
                                </div>

                                {#if 
                                hotspot.type === 'infopanel-v1' || 
                                hotspot.type === 'infopanel-v2' ||
                                hotspot.type === 'infopanel-v3' ||
                                hotspot.type === 'videopanel-v1' || 
                                hotspot.type === 'videopanel-v2'
                                }
                                  <!-- Live preview -->
                                  <div>
                                    <label for="enabled">Live preview</label>
                                    <input type="checkbox" name="enabled" bind:checked={live_preview}>
                                  </div>  
                                {/if} 

                                <!-- Description -->
                                <div>
                                  <label for="title">Popis:</label>
                                  <textarea type="text" name="description" bind:value="{hotspot.description}"></textarea>
                                </div> 

                                {#if hotspot.type === 'infopanel-v1'}
                                    <div>
                                      <label for="headline">Headline</label>
                                      <input type="text" name="headline" bind:value="{hotspot.data.headline}">
                                    </div> 
                                    <div class="upload">
                                      <label for="fileupload">Obrázok</label><br>
                                      
                                      <input id="fileupload" type="file" name="fileupload" />
                                      <button id="upload-button" class="info-img" on:click="{() => uploadFile(hotspot.data.img, 'edit', hotspot.name)}"> Upload </button>
                                    </div>
                                    <div class="url">
                                      <p>URL : {hotspot.data.img}</p><br />
                                      <img src="{settings.configuration.settings.base_path}/assets/{hotspot.data.img}" alt="" class="hts-img">
                                    </div>
                                  
                                    <div>
                                      <label for="subtitle">Podnadpis</label>
                                      <input type="text" name="subtitle" bind:value="{hotspot.data.subtitle}">
                                    </div> 

                                    <div>
                                      <label for="content">Obsah</label>
                                      <textarea name="content" bind:value="{hotspot.data.content}"/>
                                    </div> 
                                {/if}

                                {#if hotspot.type === 'infopanel-v2'}
                                    <div>
                                      <label for="headline">Headline</label>
                                      <input type="text" name="headline" bind:value="{hotspot.data.headline}">
                                    </div> 
                                   
                                    <div>
                                      <label for="subtitle">Podnadpis</label>
                                      <input type="text" name="subtitle" bind:value="{hotspot.data.subtitle}">
                                    </div> 

                                    <div>
                                      <label for="content">Obsah</label>
                                      <textarea name="content" bind:value="{hotspot.data.content}"/>
                                    </div> 
                                {/if}

                                {#if hotspot.type === 'infopanel-v3'}

                                  <div class="upload">
                                    <label for="fileupload">Obrázok</label><br>
                                    
                                    <input id="fileupload" type="file" name="fileupload" />
                                    <button id="upload-button" class="info-img" on:click="{() => uploadFile(hotspot.data.img)}"> Upload </button>
                                  </div>
                                  <div class="url">
                                    <p>URL : {hotspot.data.img}</p><br />
                                    <img src="{settings.configuration.settings.base_path}/assets/{hotspot.data.img}" alt="" class="hts-img">
                                  </div>
                                {/if}

                                {#if hotspot.type === 'videopanel-v1'}
                                  <div>
                                    <label for="title">Url</label>
                                    <input type="text" name="url" bind:value="{hotspot.data.video_url}"  on:change={() => check_youtube_url(hotspot.data.video_url)}>
                                  </div>
                                {/if}

                                {#if hotspot.type === 'videopanel-v2'}
                                  <div>
                                    <label for="title">Url</label>
                                    <input type="text" name="url" bind:value="{hotspot.data.local_video_url}">
                                  </div>
                                {/if}

                              {/if}
                            {/each}
                          {/if}


                        <!-- patche scény -->
                        {:else if selected_core_type === "scene_patches"}
                          <h3>{$t("scenes.core.type.patches")}</h3>
                        {/if}
                    </div>
                    <div class="select-area">
                        <div class="save-buttons">
                            <div class="selector">
                              {#if adding_new_hts == true}
                              <div class="save-settings" on:click={() => add_hts_to_json()}><i class="fa-solid fa-floppy-disk" />{$t("scenes.save.settings.button")} !</div>
                              {/if}
                              {#if adding_new_hts == false}
                              <div class="save-settings" on:click={() => save_scene_settings()}><i class="fa-solid fa-floppy-disk" />{$t("scenes.save.settings.button")}</div>
                              {/if}
                                
                                  
                                  
                                <select bind:value={selected_core_type}>
                                    <option value="scene_settings">nastavenia scény</option>
                                    <option value="scene_hotspots">hotspoty</option>
                                    <option value="scene_patches">patche</option>
                                </select>
                            </div>
                        </div>
                      </div>                      
                </div>
                {/if}
        </div>
      <div id="scene-thumbnails">
        {#if loading_data == false}
          <Swiper
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            class="mySwiper2"
          />

          <Swiper
            on:swiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs, Scrollbar]}
            scrollbar={{ draggable: true }}
            navigation
            class="mySwiper"
            >
            {#each settings["scenes"] as scene, i}
              <SwiperSlide id={scene.id}>
                <div class="badges">
                  <!-- Start scene -->
                  {#if scene.id === settings.configuration.start_scene}
                  
                    <i class="fa-solid fa-flag-checkered" />
                    
                  {/if}

                  <!-- Enabled/disabled scene -->
                  {#if scene.enabled}
                    <i class="fa-solid fa-eye" />
                  {:else}
                    <i class="fa-solid fa-eye-slash" />
                  {/if}
                </div>
                <img
                  src="{userdata['base_path']}assets/krpano/scenes/{scene.thumb}"
                  alt={scene.thumb}
                  on:click={() => set_active_scene(i)}
                />
                <p>{scene.id}</p>
              </SwiperSlide>
            {/each}
            
          </Swiper>
        {/if}
      </div>
  </main>
  <Header/>
</div>

<style lang="scss">
    @import "../../assets/scss/themes.scss";
  
    $select-area-height: 40px;
    $thumbnails-height: 200px;

    #screenshot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      padding: 20px;
      outline: 3000px solid rgba(0,0,0,0.7);
      pointer-events: none;
      z-index: 9;
      transition: all 0.25s ease-in-out;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
  
    main {
      width: 100%;
      max-width: 100%;
      height: calc(100% - 32px);
      overflow-x: hidden;

      .tag-wrapper{
        width: calc(100% - 140px);
        display: flex;
        flex-direction: column;
        .add-tag {
          display: flex;
          i{
            color: $success;
            margin-left: 5px;
            cursor: pointer;
          }

        }
        .tags{
          display: flex;
          flex-wrap: wrap;
            .tag{
              margin-right: 5px;
              border-radius: 6px;
              border: 1px $border-color solid;
              padding: 5px;
              width: max-content;
              i{
                margin-left: 5px;
                color: $danger;
                cursor: pointer;
              }
            }
          }
      }
  
      #scene-container {
        width: 100%;
        height: calc(100% - #{$thumbnails-height});
        display: flex;
        flex-direction: row;
  
        #pano {
          position: relative;
          width: 70%;
          height: 100%;
        }
  
        #scene-settings {
          position: relative;
          width: 30%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-content: flex-start;
          padding: 0px 0px 0px 16px;
  
          & > div {
            width: 100%;
          }
  
          .core {
            position: relative;
            height: calc(100% - #{$select-area-height});
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            overflow-x: hidden;
            overflow-y: auto;
            margin-bottom: 8px;

              .upload {
                display: flex;
                align-items: center;
                label{
                  display: flex;
                  align-items: center;
                }
              }
              .url {
                display: flex;
                flex-direction: column;
                img {
                  max-width: 160px;
                }
                p {
                  line-break: anywhere;
                }
              }

            .skin-images {
              position: relative;
              .skin-id-item {
                width: 35px;
                height: 35px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius: 50%;
                position: relative;
                cursor: pointer;
                margin-right: 5px;
                &.tag {
                  background-image: url("../../assets/icons/hts_tag.svg");
                  background-size: contain;
                }
                &.infopanel {
                  background-image: url("../../assets/icons/hts_infopanel.svg");
                  background-size: contain;
                }
                &.url {
                  background-image: url("../../assets/icons/hts_url.svg");
                  background-size: contain;
                }
                &.model {
                  background-image: url("../../assets/icons/hts_model.svg");
                  background-size: contain;
                }
                &:hover{
                  outline: 2px solid $text-color;
                }
                  
                &.active{
                &::before {
                  content: '';
                  width: 12px;
                  height: 12px;
                  background-image: url("../../assets/icons/selected.svg");
                  background-color: white;
                  background-size: contain;
                  position: absolute;
                  top: 0;
                  right: 0;
                  }
                 }
                }
              }
  
            h3 {
              width: 100%;
              text-align: center;
              color: $text-color;
            }
  
            div:not(.tag):not(.tag-wrapper) {
              position: relative;
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              width: 100%;
              &.url {
                align-items: flex-start;
              }

  
              &.separate {
                margin-top: 20px;
                padding-top: 20px;
  
                &:before {
                  position: absolute;
                  top: 0px;
                  content: "";
                  display: block;
                  background-color: $main-color;
                  width: 100%;
                  height: 1px;
                  opacity: 0.2;
                }
              }
  
              p {
                text-align: left;
                color: darken($text-color, 20);
              }
  

  
              button:not(.cancel-hotspot):not(.info-img) {
                background-color: $primary-color;
                cursor: pointer;
                margin: 0;
                &.start-scene {
                  background-color: $badge-start;
                  color: black;
                  &:hover {
                    background-color: darken($badge-start, 5);
                  }
                }
                &.default-view {
                  background-color: $info;
                  &:hover {
                    background-color: darken($info, 10);
                  }
                }
  
                &:hover {
                  background-color: $primary-color-hover;
                }
              }
            }
            .add-hotspot{
              background-color: $success;
              margin-bottom: 10px;
              padding: 10px 20px;
              cursor: pointer;
              &:hover{
                background-color: darken($success, 10);
              }
            }

            .cancel-hotspot{
              background-color: $danger;
              padding: 10px 20px;
              &:hover {
                background-color: darken($danger, 10);
              }
            }

            .hotspots-list{
              width: 100%;
              display: flex;
              flex-direction: column;

              .hotspot{
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid $border-color;
                padding: 10px;
                margin: 0;
                cursor: pointer;
                &:hover{
                  background-color: $border-color;
                }
                
                .heading {
                  width: max-content;
                  margin: 0;
                  padding-left: 5px;

                  span {
                    i{
                    min-width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    &.fa-panorama{
                      background-color: $success;
                    }
                    &.fa-info{
                      background-color: $info;
                    }
                    &.fa-link{
                      background-color: $warning;
                    }
                    &.fa-list-check{
                      background-color: $primary-color;
                    }
                    &.fa-tag{
                      background-color: $badge-start;
                    }
                    &.fa-video{
                      background-color: $danger;
                    }
                    &.fa-cube{
                      background-color: $text-color;
                    }
                  }
                }
                  .hotspot-info{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0 10px;
                    margin: 0;
                    .title{
                      font-weight: 400;
                    }

                    p.description {
                    span.warning {
                        color: red;
                      }
                    }
                  }
                }
                .setting {
                  width: auto;
                  margin: 0;
                  padding-right: 5px;
                  i{
                    width: 30px;
                    height: 30px;
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    margin-left: 4px;
                    color: $text-color;
                    cursor: pointer;
                    &:hover{
                      color: white;
                    }
                    &.fa-pen-to-square{
                      &:hover{
                      background-color: $info;
                      }
                    }
                    &.fa-trash-can{
                      &:hover{
                      background-color: $danger;
                      }
                    }
                  }
                }
              }
            }
          }
  
          .null {
            i {
              font-size: 18px;
              margin-left: 6px;
              color: $text-color;
              cursor: pointer;
              &:hover {
                color: $danger;
              }
            }
          }
  
          .select-area {
            position: relative;
            bottom: 0px;
            height: $select-area-height;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: lighten($border-color, 8);
            .selector {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;

              .save-settings{
                background-color: $primary-color;
                width: 50%;
                color: white;
                font-weight: 400;
                padding: 11px 0;
                cursor: pointer;
                i{
                  margin-right: 5px;
                }
                &:hover{
                  background-color: darken($primary-color, 10);
                }
              }

              select {
                background-color: $success;
                border: none;
                cursor: pointer;
                height: $select-area-height;
                padding: 10px;
                width: 50%;
                color: white;
                text-align: center;
                font-weight: 800;
                border-radius: 0;
                text-transform: uppercase;
                &:hover{
                  background-color: darken($success, 10);
                }
              }
            }
            .save-buttons {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              width: 100%;
              gap: 8px;
  
              button {
                background-color: $main-color;
                cursor: pointer;
                width: 100px;
              }
  
              & > div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: end;
                align-items: center;
              }
            }
  
            &:before {
              position: absolute;
              content: "";
              top: 0px;
              width: 100%;
              height: 2px;
              background-color: $main-color;
              opacity: 0.6;
            }
  
            label {
              margin-right: 16px;
            }
          }
        }
      }
  
      #scene-thumbnails {
        //background-color: grey;
        width: 100%;
        height: $thumbnails-height;
        display: flex;
        justify-content: center;
        align-items: center;
  
        .badges {
          position: absolute;
          top: 4px;
          right: 4px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 8px;
          i {
            background-color: $success;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &.fa-eye-slash {
              background-color: $border-color;
            }
            &.fa-flag-checkered {
              background-color: $badge-start;
            }
          }

        }

      }


    }


  </style>
