
import { writable } from 'svelte/store';

// Ak ide o WOOW DEMO APLIKÁCIU
export const is_woow_demo = writable(false);

// Defaultné hodnoty pre Filter + otáčadlo
export const enable_price_filter = writable(); // zapnutie/vypnutie filtra pre cenu bytov
export const enable_status_filter = writable(); // zapnutie/vypnutie filtra pre dostupnosť
export const enable_dispositions_filter = writable(); // zapnutie/vypnutie filtra pre počet izieb
export const enable_floor_filter = writable(); // zapnutie/vypnutie filtra pre poschodie
export const enable_compare_flats = writable(); // zapnutie/vypnutie funkcie porovnania bytov vo flat filter - ikona
export const show_floorplan_thumbnail = writable(); // zapnúť miniatúru obrázka pôdorysu bytu vo flat-info na pôdoryse budovy
export const pdf_flat_compare_export = writable(); // zapnúť tlačilo pre generovanie PDF porovnania kariet bytov v porovnávacom okne
export const enable_enter_vr_function = writable(); // povoliť vstupovanie do jednotlivých bytov z falt filtra alebo otáčadla alebo porovnania
export const enable_krpano_import = writable(true); // povoliť vstupovanie do jednotlivých bytov z falt filtra alebo otáčadla alebo porovnania
export const enable_playground = writable(); // povoliť vstupovanie do jednotlivých bytov z falt filtra alebo otáčadla alebo porovnania
export const enable_flat_filter = writable(false); // povoliť vstupovanie do jednotlivých bytov z falt filtra alebo otáčadla alebo porovnania
export const enable_floorplan_nav = writable(false); // povoliť vstupovanie do jednotlivých bytov z falt filtra alebo otáčadla alebo porovnania

export const enable_area_info = writable(); // povoliť zobrazenie rozlohy
export const svg_w = writable(1920);
export const svg_h = writable(1080);

//Aktívne poschodie
export const active_floor = writable();

export const active_scene = writable('');
export const active_components = writable();
export const loaded_components = writable(['core']);
export const loaded_krpano = writable(false);
export const show_intro = writable(localStorage.getItem('show_intro'));
export const showing_intro = writable(false);
export const intro = writable(true);

export const hotspots = writable(true);
export const vr_mode = writable(false);
export const device_type = writable();
export const gyro_mode = writable(false);
export const gyro_available = writable(false);
export const blur = writable(false);

export const show_floorplan = writable(false);
export const mobile_submenu = writable(false);
export const mobile_settings_submenu = writable(false);

export const sidebar = writable(false);
export const shortcuts = writable(false);
export const shortcuts_settings = writable(null);
export const vr_question = writable(false);

export const modal = writable(false);
export const modal_type = writable();

export const preloader = writable(true);
export const play_sound = writable(false);


export const disabled_icons = writable(false);

// Skypark
export const picker = writable(false);
export const floor = writable("1");
export const dizajn = writable("");
export const tour_select = writable(false);

// configurator
export const configurator_enabled = writable(true);
export const sample_flats = writable(null);
export const current_flat = writable(null);
export const flat_id = writable(null);
export const fields = writable([{  "nazov" : "id",  "hodnota" : false},{  "nazov" : "name",  "hodnota" : true},{  "nazov" : "sample_flat_id",  "hodnota" : false},{  "nazov" : "enabled",  "hodnota" : false},{  "nazov" : "hlookat",  "hodnota" : false},{  "nazov" : "vlookat",  "hodnota" : false},{  "nazov" : "fov",  "hodnota" : false},{  "nazov" : "title",  "hodnota" : true},{  "nazov" : "description",  "hodnota" : false},{  "nazov" : "custom_field_1",  "hodnota" : false},{  "nazov" : "custom_field_2",  "hodnota" : false},{  "nazov" : "custom_field_3",  "hodnota" : false},{  "nazov" : "custom_field_4",  "hodnota" : false},{  "nazov" : "custom_field_5",  "hodnota" : false},{  "nazov" : "custom_field_6",  "hodnota" : false},{  "nazov" : "custom_field_7",   "hodnota" : false},{  "nazov" : "custom_field_8",  "hodnota" : false},{  "nazov" : "custom_field_9",  "hodnota" : false},{  "nazov" : "custom_field_10",  "hodnota" : false},{  "nazov" : "shortcut_tag",  "hodnota" : true},{  "nazov" : "floorplan_tag",  "hodnota" : true},{  "nazov" : "poradie",  "hodnota" : false},{  "nazov" : "enable_design",  "hodnota" : false},{  "nazov" : "enable_floors",  "hodnota" : false},{  "nazov" : "change_dizajn",  "hodnota" : false}
  ]);
export const tour_settings = writable(null);
export const share_settings = writable(null);
export const active_shortcut_tag = writable(null);

export const attributes = writable(false);
export const product_id = writable(-1);
export const product_category_id = writable(-1);
export const product_mj_id = writable(-1);
export const product_standard_id = writable(-1);
export const active_product_id = writable(-1);
export const active_product_id_model = writable(-1);
export const tmp_attribute_from_model = writable(-1);

export const cart = writable(false);
export const basket_categories = writable();
export const configuration = writable([]);

export const all_products = writable([]);
export const config_products = writable();
export const config_product_categories = writable();
export const config_product_variants = writable();
export const config_product_standards = writable();
export const config_product_measures_units = writable();
export const reset_configurator = writable(false);
export const price_list = writable([]);
export const config_slug = writable();

// screenshot
export const screenshot = writable(false);
export const all_scenes = writable([]);
export let import_config_hotspots = writable(false);
export let all_hotspots = writable([]);
export let pdf_generating = writable(false);

// contact form
export let contact_form_enabled = writable(false);

// flat filter
export let flat_filter_enabled = writable(false);

export let newDelay = writable(100);


// 3D model
export let new_model = writable(false);



// VR section
export let vr_menu = writable(false);


export let video_time = writable(0);


export let infopanel = writable(false);
export let videopanel = writable(false);

export let infopanelData = writable({});
export let videopanelData = writable({});




export const basket_enabled = writable(false);

export const mobile_patch_suffix = writable("");



export const dispositions_enabled = writable(null);

export let nazov_bytu = writable("");

export let tour_mode = writable("");

export let cart_price = writable(0);

export let global_project_settings = writable({});
export const vr_product_enabled = writable(false);

export const check_ath = writable(false);

export const fl_settings = writable("");
export const fl_pins = writable("");
export const shortcuts_data = writable("");

export const vr_safe_data = writable(false);

export const measures = writable(false);


export const languages = writable(false);

export const reimport_hotspots = writable(false);


export const hide_all_hotspots_not_patches = writable(false);

export const enabled_measure = writable(false);

export const tutorial = writable(null);
export const selected_tutorial = writable(null);
export const show_tutorial = writable(true);

export const permanently_hotspots = writable(false);

export const tut_first_time = writable(true);
export const otacadlo_floors = writable([]);
export const otacadlo_selected_floor = writable(null);
export const otacadlo_selected_rooms = writable(null);
export const otacadlo_selected_status = writable(null);
export const otacadlo_selected_min_price = writable(null);
export const otacadlo_selected_max_price = writable(null);
export const otacadlo_selected_flat = writable(null);
export const otacadlo_all_flats = writable();
export const otacadlo_all_status = writable(null);
export const otacadlo_filteredList = writable(null);


export const otacadlo_selected_flat_all_data = writable(null);

export const otacadlo_hover_flat = writable(null);
export const enable_filter = writable(false);
export const floor_view = writable(false);
export const orbit_view = writable(false);
export const building_view = writable(false);
export const otacadlo_compare_flats = writable([]);
export const cookies_compare_flats = writable([]);
export const otacadlo_to = writable("");

export const show_filter = writable(false);
export const enable_otacadlo = writable(null);
export const active_point = writable(0);
export const active_poster = writable("");
export const otacadlo_buildings = writable([]);


export const run_visit_tour = writable(false);
export const playground_view = writable(false);
export const rotate_to_floor = writable(null);

export const change_floor = writable(null);

export const poster_image = writable("01A");

export const go_to_flat_from_compare = writable(null);

export const filtered_flats_for_svg = writable(null);

export const allow_rotate_to_flat = writable(false);
export const reseting_filter = writable(false);

export const flat_3D = writable(false);
export const enabled_flat_detail = writable(false);


export const otacadlo_img = writable(false);
export const otacadlo_img_active_floor = writable(0);


export const changed_flat = writable(null);

export const should_deactivate_otacadlo_graphic = writable(false);
export const enabled_screenshot = writable(false);

export const favIDs = writable(null);
export const topNRs = writable(null);

export const showFlatOnFloor = writable(null);
export const changedFloor = writable(null);

export const officeType = writable(true);
export const poisScene = writable(null);

export const otacadlo_logika = writable(null);
export const AnimationTimes = writable({});

export const flatDetail = writable(false);


export const tooltipAnimation = writable("slide");
export const toggle_compare_flat_id = writable(null);

export const flat_not_found = writable(false);
export const load_view = writable("keepview");
