import { writable } from 'svelte/store';

if (
	localStorage.getItem('show_intro') == null
) {
	localStorage.setItem('show_intro', true);
}


export const show_intro = writable(localStorage.getItem('show_intro'));
import App from './App.svelte';

let targetElement = document.querySelector('#woowstudio');

// Overenie, či bol cieľový element nájdený
if (!targetElement) {
    // Ak cieľový element neexistuje, vytvorte ho
    targetElement = document.createElement('div');
    targetElement.id = 'woowstudio';
    document.body.appendChild(targetElement);
}

const app = new App({
    target: targetElement, // Použijeme obalovací element ako cieľový
    props: {
        // Vaše vlastné props, ak sú potrebné
    }
});
export default app;