<script>
    import {tutorial, selected_tutorial, blur, tut_first_time} from '../store.js';
    import { t, locale, locales } from "../i18n.js";
    import { fade, fly, slide, scale } from "svelte/transition";
    import { quintOut } from "svelte/easing";
    import { getNotificationsContext } from "svelte-notifications";

    const { addNotification } = getNotificationsContext();
    export let userdata, components, enable_smooth_transition, languages_settings, _basket_enabled, logo_settings;
    //console.log(components);
    const animate_in = " delay: 0, duration: 300, easing: quintOut";
    const animate_out = "delay: 0, duration: 300, easing: quintOut";
    const animate = (node, args) => args.cond ? fade(node, args) : scale(node, args);

   


    let dont_show = false;

    let tmp_cookie = getCookie("tutorial") ;
    if (tmp_cookie != null && getCookie("tutorial") != "") {
        //dont_show = !tmp_cookie;
    }
    // kontrola cookies
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie(parameter) {
        let cookie = getCookie(parameter);
        if (cookie != "" && cookie != null) {
            //console.log("Cookie " + parameter + " = " + cookie);
            console.log(cookie);
            dont_show = cookie;
        } else {
            setCookie(parameter, true, 365);
            dont_show = false;
        }
    }

    //checkCookie("tutorial");


    let active_class_cart, active_class_lang;
    let _tutorial, active_tutorial, _tut_first_time = null;
    let show_tut_container = false;
    let updated_array = [];
    let timeout, duration = 0;
    let show_tutorial_icons = [];

    tut_first_time.subscribe(value => {
       _tut_first_time = value;
    });

    selected_tutorial.subscribe(value => {
        active_tutorial = value;
    });

    tutorial.subscribe(value => {
        _tutorial = value;

        switch (value) {
            case true:
                blur.update(n => true);
                
                break;
        
            default:
                blur.update(n => false);
                break;
        }
    });    

    if (languages_settings != "undefined" ) { active_class_cart = "cart-tooltip"; } else { active_class_cart = "cart-alone-tooltip";}
    if (_basket_enabled == true ) {active_class_lang = "lang-tooltip";} else {active_class_lang = "lang-alone-tooltip";}

    //console.log(_basket_enabled);
    
    if (enable_smooth_transition) {
        updated_array = [...updated_array, "smooth_transition"];     
    }
    components.forEach(element => {
        element = element.replace("component_", "");   

        
        switch (element) {
            case "shortcuts":
            case "sidebar":
            case "flat_filter":
            case "screenshots":
            case "languages":
            case "basket":
            case "floorplan":
            case "3d_model":
            case "layers":
            case "google_map":
            case "social":
            case "tour_info":
            case "bookcall":
            case "attributes":
            case "pdf":
                updated_array = [...updated_array, element];        
                break;
        
            case "measures": 
                updated_array = [...updated_array, "measure_1"];        
                updated_array = [...updated_array, "measure_2"];        
                break;
            default:
                break;
        }
        
    });

    if (!dont_show) {
        animate_tut_icons();
    }
    

    function set_first_tutorial() {
        if (active_tutorial == null) {

            if (enable_smooth_transition) {
                active_tutorial = "smooth_transition";
            } else {
                active_tutorial = updated_array[0];
            }

        }

        show_tut_container = true;
    }

    function animate_tut_icons() {
        for (let index = 0; index < updated_array.length; index++) {
            const element = updated_array[index];
            show_tutorial_icons = [...show_tutorial_icons, element];
            if (index == updated_array.length - 1) {
                setTimeout(() => {
                    set_first_tutorial();
                }, 500);
            }
        }
        tut_first_time.update( n => false);
    }

    function next_tutorial() {
        for (let index = 0; index < updated_array.length; index++) {
            const element = array[index];
            if (element ==  active_tutorial) {
                if (index == updated_array.length) {
                    active_tutorial = updated_array[0];
                } else {
                    active_tutorial = updated_array[index+1];
                }
            }
             
        }
    }

    function prev_tutorial() {
        for (let index = 0; index < updated_array.length; index++) {
            const element = array[index];
            if (element ==  active_tutorial) {
                if (index == 0) {
                    active_tutorial = updated_array[updated_array.length];
                } else {
                    active_tutorial = updated_array[index-1];
                }
            }
             
        }
    }

    $: {
        dont_show;
        setCookie("tutorial", !dont_show, 30);

        if (dont_show) {
            addNotification({
                  text: $t("components")['tutorial']['dont-show'],
                  position: 'top-center',
                  removeAfter: 6000,
                  type: 'warning'
                });
        }
    }

    let show_description, show_tut_menu = false;

    $: outerWidth = 0
	$: innerWidth = 0
	$: outerHeight = 0
	$: innerHeight = 0

</script>

<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />

<div id="woow-tutorial">
{#if show_tut_container}
    <div class="tut-blur"></div>
    <div id="floorplan-big" transition:animate>
        <div class="close" on:click={() => tutorial.update(n => false)} />
        <div class="title"> {$t("components")['tutorial']['title']}</div>
        <div class="content">

        <div class="floorplan-list">
            {#if innerWidth >= 630} 
                <ul>
                    {#each updated_array as item}    
                        <li class={"s-"}{item}{item == active_tutorial ? ' active fp-item' : ' fp-item'} on:click={() => selected_tutorial.update(n => item)}>
                            {$t("components")['tutorial'][item]['title']}
                            {#if item == "smooth_transition" || item == "screenshots" || item == "measure_1" || item == "measure_2" || item == "bookcall" || item == "3d_model"}
                                <span>{$t("components")['tutorial']["new"]}</span>
                            {/if}
                        </li>
                    {/each}
                </ul>
            {/if}

            {#if innerWidth < 630} 
                {#each updated_array as item}
                     {#if item == active_tutorial} 
                        <p class="tut-item-name">{$t("components")['tutorial'][item]['title']}</p>
                     {/if}
                {/each}
                
                <button id="tut-menu" on:click={() => show_tut_menu = !show_tut_menu}>tutorial menu</button>
            {/if}

            <div class="checkbox">
                <input type="checkbox" id="nezobrazovat" class="check" bind:checked={dont_show} />
                <label for="nezobrazovat">{$t("components")['intro']['dont_show']}</label>
            </div>
        </div>
        {#if innerWidth < 630} 
            {#if show_tut_menu}
                <div class="tut-menu-wrapper">
                    {#each updated_array as item}
                        <div class={"s-"}{item}{item == active_tutorial ? " active " : ""} on:click={() => active_tutorial = item} on:click={() => show_tut_menu = false}> {$t("components")['tutorial'][item]['title']}</div>
                    {/each} 
                </div>
            {/if}
        {/if}
        <div class="floorplan-image">
            {#each updated_array as tutorial}
                {#if active_tutorial == tutorial}
                    <video width="100%" height="100%" controls="false" muted autoplay loop playsinline>
                        <source id={active_tutorial} src="{userdata["base_path"]}tutorials/{active_tutorial}.mov" type="video/mp4" alt="">
                    </video> 
                {/if}
            {/each}
             
            <div class={show_description == true ? "active description" : "description"}>
                <p>{$t("components")['tutorial'][active_tutorial]['description']}</p>
            </div> 
            <div on:click={() => show_description = !show_description} class={show_description == true ? "active description-icon" : "description-icon"}></div>
       
        </div>
        </div>
    </div>

    {#if logo_settings != null}
        <div in:fade={{animate_in}} out:fade={{animate_out}} class="logo-tut">
            <a href="{logo_settings.url}" target="{logo_settings.target}">
                <img src="assets/img/{logo_settings.logo_url}" alt="logo">
            </a>
        </div>
    {/if}

    {#each show_tutorial_icons as item}
        {#if item == "sidebar"}
            <div in:fade={{animate_in}} out:fade={{animate_out}} class={active_tutorial == "sidebar" || active_tutorial ==  "floorplan" || active_tutorial ==  "3d_model" || active_tutorial ==  "layers" || active_tutorial ==  "google_map" || active_tutorial ==  "social" || active_tutorial ==  "tour_info" || active_tutorial ==  "measure_1" || active_tutorial ==  "measure_2" || active_tutorial ==  "bookcall" ? 'sidebar-icon-tut tut-selected' : 'sidebar-icon-tut '}></div>
            {#if active_tutorial == "sidebar" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["sidebar"]['tooltip-title']}</div>
            {/if}   
            {#if active_tutorial == "floorplan" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["floorplan"]['tooltip-title']}</div>
            {/if}   
            {#if active_tutorial == "3d_model" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["3d_model"]['tooltip-title']}</div>
            {/if}   
            {#if active_tutorial == "layers" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["layers"]['tooltip-title']}</div>
            {/if}   
            {#if active_tutorial == "google_map" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["google_map"]['tooltip-title']}</div>
            {/if}   
            {#if active_tutorial == "social" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["social"]['tooltip-title']}</div>
            {/if}   

            {#if active_tutorial == "tour_info" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["tour_info"]['tooltip-title']}</div>
            {/if}   

            {#if active_tutorial == "measure_1" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["measure_1"]['tooltip-title']}</div>
            {/if}   

            {#if active_tutorial == "measure_2" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["measure_2"]['tooltip-title']}</div>
            {/if}   
            {#if active_tutorial == "bookcall" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip sidebar-tooltip">{$t("components")['tutorial']["bookcall"]['tooltip-title']}</div>
            {/if}   
        {/if}

        {#if item == "languages"}
            <div in:fade={{animate_in}} out:fade={{animate_out}} class={active_tutorial == "languages" ? 'lang-icon-tut tut-selected' : 'lang-icon-tut'} >{$locale}</div>
            
            {#if active_tutorial == "languages" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip {active_class_lang}">{$t("components")['tutorial'][active_tutorial]['title']}</div>    
            {/if}
        {/if}

        {#if item == "basket" || item == "pdf"}
            <div in:fade={{animate_in}} out:fade={{animate_out}} class={active_tutorial == "basket" || active_tutorial == "pdf" ? 'cart-icon-tut tut-selected' : 'cart-icon-tut'} ></div>
            {#if active_tutorial == "basket" }
                <div in:fade={{animate_in}} out:fade={{animate_out}}  class="tooltip {active_class_cart}">{$t("components")['tutorial']["basket"]['title']}</div>
            {/if}

            {#if active_tutorial == "pdf" }
                <div in:fade={{animate_in}} out:fade={{animate_out}}  class="tooltip {active_class_cart}">{$t("components")['tutorial']["pdf"]['tooltip-title']}</div>
            {/if}
        {/if}

        {#if item == "shortcuts"}
            <div in:fade={{animate_in}} out:fade={{animate_out}} class={active_tutorial == "shortcuts" ? 'shortcuts-icon-tut tut-selected' : 'shortcuts-icon-tut'} ></div>
            {#if active_tutorial == "shortcuts" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip shortcut-tooltip">{$t("components")['tutorial'][active_tutorial]['title']}</div>
            {/if}
        {/if}

        {#if item == "flat_filter"}
            <div in:fade={{animate_in}} out:fade={{animate_out}} class={active_tutorial == "flat_filter" ? 'filter-icon-tut tut-selected' : 'filter-icon-tut'} ></div>
            {#if active_tutorial == "flat_filter" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip filter-tooltip">{$t("components")['tutorial'][active_tutorial]['title']}</div>    
            {/if}
        {/if}

        {#if item == "screenshots"}
            <div in:fade={{animate_in}} out:fade={{animate_out}} class={active_tutorial == "screenshots" ? 'screenshot-icon-tut tut-selected' : 'screenshot-icon-tut'} ></div>
            {#if active_tutorial == "screenshots" }
                <div in:fade={{animate_in}} out:fade={{animate_out}} class="tooltip screenshot-tooltip">{$t("components")['tutorial'][active_tutorial]['title']}</div>
            {/if}
        {/if}
    {/each}

    
{/if}

</div>

<style lang="scss">

@import "../assets/scss/themes.scss";
@import "../assets/scss/fonts.scss";
@import "../assets/scss/mixins.scss";
@import "../assets/scss/variables.scss";


#woow-tutorial{
.tut-blur {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(60px) !important;
    backdrop-filter: blur(60px) !important;
    z-index: 2;
}

    
.tooltip {
    background-color: var(--main-color);
    color: var(--secondary-color);
    position: absolute;
    z-index: 12;
    left: 70px;
    top: 21px;
    border-radius: 6px;
    padding: 6px 10px;

    &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 16px 8px 0;
        border-color: transparent var(--main-color) transparent transparent;
        position: absolute;
        left: -12px;
    }
}

.filter-tooltip {
    top: unset;
    bottom: 21px;
}

.shortcut-tooltip {
        top: unset;
        bottom: 22px;
        left: 50%;
        transform: translateX(32px);

        &::before {
            border-width: 8px 16px 8px 0;
            border-color: transparent var(--main-color) transparent transparent;
            left: -6px;
            transform: translateX(-50%);
            top: 7px;
        }
    }

    .screenshot-tooltip {
        top: 78px;
        left: 50%;
        transform: translateX(31px);
        // margin-left: 83px;

        &::before {
            border-width: 8px 16px 8px 0;
            border-color: transparent var(--main-color) transparent transparent;
            left: -3px;
            transform: translateX(-50%);
            bottom: 7px;
        }
    }

.cart-tooltip {
    top: 70px;
    left: unset;
    right: 16px;

    &::before {
        border-width: 0 8px 16px 8px;
        border-color: transparent transparent var(--main-color) transparent;
        left: unset;
        right: 12px;
        bottom: 25px;
    }
}

.lang-tooltip {
    left: unset;
    right: 125px;

    &::before {
        border-width: 8px 0 8px 16px;
        border-color: transparent transparent transparent var(--main-color);
        left: unset;
        right: -12px;
        bottom: 7px;
    }
}

#floorplan-big {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    max-width: 834px;
    max-height: 552px;
    padding: 16px;
    @include blur;
    border-radius: $radius-M;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 13;
    overflow: auto;
        

    .title {
      @include rubik-m20;
      padding: 0 32px;
      margin-bottom: 8px;
      text-align: center;
    }

    .content {
      display: flex;
      width: 100%;
            height: calc(100% - 32px);
      // align-items: stretch;
      .floorplan-list {

        ul {
            padding: 0;
            height: calc(100% - 60px);
            overflow: auto;
            padding-right: 24px;
            border-bottom: 1px solid var(--primary-color);
            width: 95%;

            li {
                position: relative;
                span {
                    margin-left: 16px;
                    background-color: var(--main-color);
                    color: var(--secondary-color);
                    font-size: 12px;
                    padding: 2px 6px !important;
                    border-radius: 6px;
                    line-height: 16px;

                }
            }
        }

        position: relative;
        min-width: 304px;
        height: 100%;
        margin-left: 8px;
        overflow: auto;

        .fp-item {
          cursor: pointer;
          display: flex;
          @include rubik-r14;
          padding: 13.5px 8px 12px;
          margin-top: 8px;

          &.active {
            color: var(--main-color);
            outline: 1px solid var(--main-color-light20);
            outline-offset: -1px;
            border-radius: $radius-S;
            background-color: var(--main-color-light005);

            &::before {
                background-color: var(--main-color);
            }
          }

          &::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-color: var(--primary-color);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    margin-right: 8px;
                }
        }
      }

      .checkbox {
        justify-self: center;
        @include rubik-r14;
        align-self: center;
        position: absolute;
        bottom: 6px;
        padding-left: 10px;

        #nezobrazovat {
        appearance: none;
        width: 16px;
        height: 16px;
        box-shadow: 0 0 0 2px var(--primary-color-light10);
        border-radius: 4px;
        position: relative;
        top: 3px;
        left: 3px;
        cursor: pointer;
        margin: 0;

        &:checked {
            box-shadow: 0 0 0 2px var(--primary-color);
        }

        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            background-color: var(--primary-color);
            position: relative;
            top: 3px;
            left: 3px;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked::before {
            transform: scale(1);
        }
        }
        label {
        cursor: pointer;
        margin-left: 5px;
        }
    }

      .floorplan-image {
        position: relative;
        // width: 66%;
        height: 100%;
        overflow: hidden;
        border-radius: $radius-S;
        background-color: var(--primary-color-light005);
        border: 1px solid var(--primary-color-light20);
        margin: auto;

        .description {
            position: absolute;
            width: 95%;
            height: max-content;
            padding: 15px 10px 30px 10px;
            border-radius: 12px;
            background-color: var(--primary-color-light60);
            transition: all .25s ease-in-out;
            bottom: -300px;
            right: 50%;
            transform: translate(50%);
            color: var(--secondary-color);
            opacity: 0;
            display: none;

            p {
                text-align: center;
            }

            &.active {
                bottom: 45px;
                opacity: 1;
                display: block;
                
                padding: 15px 10px 40px 10px;
                border-radius: 12px;


                p {
                    visibility: visible;
                }
            }

        }

        .description-icon{
                position: absolute;
                bottom: 52px;
                right: 18px;
                width: 35px;
                height: 35px;
                background-color: var(--primary-color);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &::before{
                    content: '';
                    width: 25px;
                    height: 25px;
                    background-color: var(--secondary-color);
                    display: block;
                    mask-position: center;
                    -webkit-mask-position: center;
                    mask-size: 90%;
                    -webkit-mask-size: 90%;
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;

                }

                &.active {
                    &::before{
                        mask-size: 60%;
                        -webkit-mask-size: 60%;
                    }
                }
            }

        svg {
                position: absolute;
                bottom: 50px;
                right: 20px;
                transition: all 0.25s ease-in-out;
                width: 30px;
                height: 30px;
                cursor: pointer;
                
            }

        video {
            object-fit: contain;
        }
      }
    }
  }

  .tut-selected {
      border: 2px solid var(--main-color);
    }

  .sidebar-icon-tut {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    @include blur;
    border-radius: $radius-S;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: var(--primary-color);
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .lang-icon-tut {
        position: absolute;
        top: 16px;
        right: 72px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-transform: uppercase;
        border-radius: 6px;
        @include blur;
        z-index: 2;

        &.alone {
            right: 16px;
        }
    }

    .cart-icon-tut {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    @include blur;
    border-radius: $radius-S;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: var(--primary-color);
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .shortcuts-icon-tut {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  width: 40px;
  height: 40px;
  @include blur;
  border-radius: $radius-S;
  transition: all 0.2s ease-in-out;
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    display: block;
    background-color: var(--primary-color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.filter-icon-tut {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    @include blur;
    border-radius: $radius-S;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: var(--primary-color);
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .screenshot-icon-tut {
    position: absolute;
    left: 50%;
    top: 72px;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 6px;
    z-index: 2;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
  }

    $logo-width: 140px;
    $logo-height: 40px;
    
    .logo-tut {
        position: absolute;
        top: 16px;
        left: 50%;
        width: $logo-width;
        height: $logo-height;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        @include blur;
        cursor: pointer;
        z-index: 10;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: auto;
            height: auto;
            max-width: calc(#{$logo-width} - 16px);
            max-height: calc(#{$logo-height} - 8px);
        }
    }

  //  ----------------SCREEN-------------------------------------------------------------------------------------------------------

  @media (max-width: 630px) {
    #floorplan-big {
      max-height: none;
      top: 53%;
      transform: translate(-50%,-50%);
      height: auto;
      max-width: 392px;
      .content {
        flex-direction: column;
        height: calc(100% - 26px);
        .floorplan-list {
          order: 5;
          margin-top: 20px;
          min-width: auto;

          p {
            text-align: center;
            @include rubik-m14
          }

          button {
            background-color: var(--main-color);
            width: 100%;
            text-align: center;
            margin-top: 7px;
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
          }

          ul {
            height: calc(100% - 82px);
          }

          .checkbox {
            text-align: center;
            width: 100%;
            font-size: 12px;
            position: relative;

            input {
                width: 14px;
                height: 14px;

                &::before{
                    width: 8px;
                    height: 8px;
                }
            }
          }
        }

        .tut-menu-wrapper{

            position: absolute;
            z-index: 50;
            background: var(--primary-color);
            width: calc(100% - 32px);
            border-radius: 6px;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            height: 360px;
            gap: 20px;
            overflow: auto;

            div {
                width: 46.8%;
                background-color: var(--secondary-color);
                border-radius: 6px;
                display: flex;
                justify-content: center;
                // align-items: center;
                min-height: 80px;
                cursor: pointer;
                padding-top: 45px;
                position: relative;

                &.active {
                    border: 2px solid var(--main-color);
                    color: var(--main-color);

                    &::before {
                        background-color: var(--main-color);
                    }


                }

                &:hover {
                    opacity: 0.8;
                }

                &::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-color: var(--primary-color);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: absolute;
                    bottom: 40px;
                }
            }            
        }


        .floorplan-image {
          order: 1;
          width: 100%;
          min-height: 360px;
          overflow: visible;

          video{
            border-radius: 6px;
            object-fit: cover;
          }

          .description-icon{
            bottom: -40px;
            width: 30px;
            height: 30px;
            z-index: 2;
            right: 0;
          }
        }
      }
    }
    .screenshot-tooltip {
        top: 78px;
        left: 50%;
        transform: translateX(31px);
        // margin-left: 83px;

        &::before {
            border-width: 8px 16px 8px 0;
            border-color: transparent var(--main-color) transparent transparent;
            left: -3px;
            transform: translateX(-50%);
            bottom: 7px;
        }
    }
    .shortcut-tooltip {
        top: unset;
        bottom: 22px;
        left: 50%;
        transform: translateX(32px);

        &::before {
            border-width: 8px 16px 8px 0;
            border-color: transparent var(--main-color) transparent transparent;
            left: -6px;
            transform: translateX(-50%);
            top: 7px;
        }
    }

    .lang-tooltip {
        top: 70px;
        right: 73px;
        left: unset;

        &::before {
            border-width: 0 8px 16px 8px;
            border-color: transparent transparent var(--main-color) transparent;
            left: unset;
            right: 11px;
            bottom: 26px;
        }
    }

    .sidebar-tooltip {
        left: 16px;
        top: 68px;

        &::before {
            border-width: 0 8px 16px 8px;
            border-color: transparent transparent var(--main-color) transparent;
            left: 12px;
            top: -12px;
        }
    }
  }


  @media (max-width: 428px) {

    #floorplan-big {
        .content{
            .floorplan-image{
                // height: calc(100vw + 50px);
                min-height: 83vw;
                height: auto;

                // width: calc(100% - 43px);

                video {
                    object-position: top;
                }
            }
        }
        .tut-menu-wrapper {
            height: 83vw !important;
            min-height: unset;
            
            div {
                width: 45% !important;
            }
        }
    }

  }

  @media (max-height: 730px) {

    #floorplan-big {
        height: calc(100% - 190px );
        max-height: 552px;
        overflow: auto;
        top: 54%;
        }

        .content{
            height: calc(100% - 32px);
        }
  }


  //  ----------------Hover-----------------------------------------------------------------------------------------------

  @media (hover: hover) and (pointer: fine) {
    #floorplan-big {
      .content {
        .floorplan-list {
          .fp-item {
            &:hover:not(.active) {
              outline: 1px solid var(--primary-color-light20);
              outline-offset: -1px;
              border-radius: $radius-S;
              background-color: var(--primary-color-light005);
            }
          }
        }
      }
    }
  }
}
</style>