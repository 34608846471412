<script>
	import { onMount } from 'svelte';
	import {active_scene, nazov_bytu, shortcuts}  from '../store.js';
	
	window.dataLayer = window.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}

	export let userdata;

	onMount(async () => {

		// Trackovanie ID projektu
		nazov_bytu.subscribe( value => {
				if (value != null && value != "") {
					//console.log("event project_change : " + value);
					gtag('event', 'project_change', {
						project_name: value,
						send_to: userdata["ga_id"]
					});
				}	
			});

			// Trackovanie scén
			active_scene.subscribe( value => {
				if (value != null && value != "") {
					//console.log("event scene_change : " + value);
					gtag('event', 'scene_change', {
						scene_name: value,
						send_to: userdata["ga_id"]
					});
				}
			});

			let opened_shortcuts = 0;
			shortcuts.subscribe( value => {
				if (value != null && value != "" && value == true) {
					//console.log("event shortcus : " + value);
					opened_shortcuts++;
					gtag('event', 'shortcuts', {
						shortctus_opened: opened_shortcuts,
						send_to: userdata["ga_id"]
					});
				}
			});

		if (userdata["ga_id"] != null && userdata["ga_id"] != undefined ) {
			//console.log(userdata["ga_id"]);

			
		}
	});
</script>