<script>  
	import { onMount } from 'svelte';
  import { tooltip } from "@svelte-plugins/tooltips";
  let _dizajn;
  let _floor;
  export let userdata;
  export let shortcuts_settings;
  export let sample_flat, sample_flat_scenes, _dispositions_enabled, current_user;
  //console.log(sample_flat);
  
  import { active_scene, blur, sidebar, shortcuts, loaded_components, tooltipAnimation } from "../store.js";
  import { t, locale, locales } from "../i18n.js";
  import { fade, fly, slide } from "svelte/transition";
  import { get, writable } from "svelte/store";
  import { disabled_icons, showing_intro,loaded_krpano, active_shortcut_tag } from "../store.js";
  import { attributes, cart, flat_filter_enabled, dizajn, floor, vr_mode, enable_otacadlo, should_deactivate_otacadlo_graphic } from "../store.js";

  import { Splide, SplideSlide, SplideTrack } from "@splidejs/svelte-splide";
  // import { Splide, SplideSlide } from "svelte-splide";
  import "@splidejs/svelte-splide/css";

  

  const jq = window.$;
  let _flat_filter_enabled, _should_deactivate_otacadlo_graphic = false;
  let _enable_otacadlo = false;
  let shorctuts_scenes = [];
  let _vrMode;
  let loading_data = true;
  let start_index = 0;

  let krpano = document.getElementById("krpanoSWFObject");
  let searched_tags = null;
  let filter_by = shortcuts_settings.filter;
  let name_by = shortcuts_settings.title;
  let current_scene = "";
  let actual_index = 1;
  let splide;
  // //console.log(splide);
  let _active_shortcut_tag = null;

  let tmp_sh_tag = null;
  let reading_scenes_from_settings = true;
  let _laoded_krpano = false;
  let dis_icon = get(disabled_icons);
  let show_intro = true;
  let blur_enabled = false;
  let _shortcuts = false;

  
  should_deactivate_otacadlo_graphic.subscribe((value) => {
    _should_deactivate_otacadlo_graphic = value;
  });

  enable_otacadlo.subscribe((value) => {
    _enable_otacadlo = value;
    //console.log("otacadlo : " + value);
  });

  flat_filter_enabled.subscribe((value) => {
    _flat_filter_enabled = value;
  });

  vr_mode.subscribe(value => {
    _vrMode = value;
  });

  active_shortcut_tag.subscribe((value) => {
    _active_shortcut_tag = value;
    
  });

  active_scene.subscribe((value) => {
    reading_scenes_from_settings = true;
    if (current_scene.toLowerCase() != value.toLowerCase()) {
      current_scene = value;
      read_scenes_from_settings();
    }
  });

  $: {
    reading_scenes_from_settings; 

    if (reading_scenes_from_settings == false && shorctuts_scenes != null) {
      if (splide != undefined && splide.index != undefined) {
        let i = 0;
        
        for (let index = 0; index < shorctuts_scenes.length; index++) {
          
          if ( 
            shorctuts_scenes[index].name == current_scene )
              splide.go(index);
              
            } 
          
          
        
      }
    }
  }

  loaded_krpano.subscribe((value) => {
    _laoded_krpano = value;
  });
  
  disabled_icons.subscribe((value) => {
    // ////console.log('disabled icons ' + value);
    dis_icon = value;
  });

  showing_intro.subscribe((value) => {
    show_intro = value;
  });

  blur.subscribe((value) => {
    blur_enabled = value;
  });

  shortcuts.subscribe((value) => {
    _shortcuts = value;
    ////console.log(value);

    if (value == true) {
      sidebar.update((n) => false);

      cart.update((n) => false);
      attributes.update((n) => false);

      jq("#chat-application").addClass("hidden");
    } else {
      jq("#chat-application").removeClass("hidden");
      if (splide != undefined) {
        splide.destroy();
      }
      
    }

  });

  $loaded_components = [...$loaded_components, "shortcuts"];

  // nájdenie, aké scény sa majú zobraziť v shortcuts

  function read_scenes_from_settings() {
    shorctuts_scenes = [];

    let thumb_suffix = null;
    let _filter_byt = null;

    // vytvorenie názvu thumbnailu
    var krpano = document.getElementById('krpanoSWFObject');
    sample_flat_scenes.forEach(element => {
      
      

        // základný mázov thumbnailu
        let thumbnail = "_thumb";

        if (
          sample_flat.shortcuts_thumbnail_suffix != "_thumb"
        ) {
          thumbnail = sample_flat.shortcuts_thumbnail_suffix;
        }

        // ak je názov thumbnailu iný ako default

        if (
          element.enable_design == true && element.enable_floors == false
        ) {
          thumb_suffix = "_" + krpano.get("dizajn") + "_" + thumbnail +".jpg";
          
        }
        else if (element.enable_design == false && element.enable_floors == true) {
          thumb_suffix = "_" + krpano.get("floors") + "_" + thumbnail +".jpg";
          
        }

        else if (element.enable_design == true && element.enable_floors == true) {
          thumb_suffix = "_" + krpano.get("dizajn") + "_" + krpano.get("floors") + "_" + thumbnail +".jpg";
          
        }
        else {
          thumb_suffix = "_" + thumbnail +".jpg";
          
        }

        if (element.enabled) {
          shorctuts_scenes = [...shorctuts_scenes,{
            id: element.id,
            name: element.name,
            order: element.poradie,
            title: element.title.replace(sample_flat.name, current_user),
            thumbnail : element.name + thumb_suffix
          }];
        }


        
      
    });

    // sample_flat_scenes.every(element => {
    //   if (element.name == current_scene) {
    //     tmp_sh_tag = element.shortcut_tag;
    //     return false;
    //   }

    //   else {
    //     return true;
    //   }
    // });

    
    // switch (filter_by) {
    //   case "all":
    //     sample_flat_scenes.forEach(({ id, enabled, poradie, name, title, thumb, sample_flat_id, enable_design, enable_floors }) => {
    //       shorctuts_scenes = [...shorctuts_scenes,{
    //         id: id,
    //         name: name,
    //         order: poradie,
    //         title: title,
    //         thumbnail : name + thumb_suffix
    //       }];
    //     });

    //     break;
    //   case "tag":
    //   sample_flat_scenes.forEach(({ id, enabled, order, name, title, thumb, sample_flat_id, shortcut_tag }) => {
    //       if (
    //         enabled == true && 
    //         sample_flat_id == _current_flat && 
    //         shortcut_tag == tmp_sh_tag) {
              
    //             shorctuts_scenes = [
    //               ...shorctuts_scenes,
    //               {
    //                 id: id,
    //                 name : name,
    //                 tag: shortcut_tag,
    //                 order: order,
    //                 title: title,
    //                 thumbnail : name + thumb_suffix
    //               },
    //             ];
    //       }
    //     });
        
    //     break;
    // }

    

    // for (let index = 0; index < shorctuts_scenes.length; index++) {
    //     ////console.log(shorctuts_scenes[index].id + ' | ' + settings.configuration.start_scene );
    //   if (
    //     shorctuts_scenes[index].id == settings.configuration.start_scene
    //   ) {
    //     ////console.log(shorctuts_scenes[index]);
    //     start_index = index;
    //     ////console.log(start_index);
    //   }
      
    // }

    loading_data = false;
    reading_scenes_from_settings = false;
    // console.log(shorctuts_scenes);
  }
  //read_scenes_from_settings();

  let width = 0;
  let inverval_timer;
  let auto_play = false;
  let active_slide_index = 0;
  let auto_play_timeout = 2000;

  function swiper_next() {
    swiper_autoplay_stop();
  }

  function swiper_prev() {
    swiper_autoplay_stop();
  }

  function swiper_autoplay_stop() {
    auto_play = false;
  }
  function swiper_autoplay_start() {
    auto_play = true;
  }

  let sortBy = { col: "order", ascending: true };

  function order_scenes() {
    loading_data = true;
    // zmena poradia podĺa order !
    let sortModifier = sortBy.ascending ? 1 : -1;

    let sort = (a, b) =>
      a["order"] < b["order"]
        ? -1 * sortModifier
        : a["order"] > b["order"]
        ? 1 * sortModifier
        : 0;

      sample_flat_scenes = sample_flat_scenes.sort(sort);
    loading_data = false;
  }

  order_scenes();

  import { active_components } from "../stores.js";
  import { last_used_component } from "../stores.js";

  

  let showing;
  let last_component;

  active_components.subscribe((value) => {
    showing = value;
  });

  last_used_component.subscribe((value) => {
    last_component = value;
  });

  // odobratie komponentu z poľa aktívnych prvkov
  function change_active_component($component) {
    if (showing.includes($component)) {
      active_components.update((n) => n.filter((item) => item !== $component));
      last_used_component.update((n) => "");
    } else {
      active_components.update((n) => [...n, $component]);
      last_used_component.update((n) => $component);
    }

    //
  }

  let new_titles = [];

  const options = {
    label : "woow",
    rewind: true,
    gap: "4",
    autoplay: false,
    fixedWidth: 240,
    arrows: true,
    height: "120px",
    pagination: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    updateOnMove : false,
    slideFocus : false,
    wheel: true,
    start: start_index,
    // omitEnd: true,
    focus  : 'center',
    // trimSpace: false,
  };

  let count = 0;

  $: {
    splide;

    
    if (splide != null && reading_scenes_from_settings == false ) {
      // splide = new Splide( '#splide01' );
      splide.on("active", () => {
        
        actual_index = splide.index + 1;
        if (
          shorctuts_scenes[splide.index].name.toLowerCase() != current_scene.toLowerCase()
        ) { krpano.call("loadscene(" + shorctuts_scenes[splide.index].name + ", null, KEEPVIEW, BLEND(1))");}
      });
        count++
    } else {
    
    }

    if (splide != undefined && Splide.STATES != undefined) {
    
      if (splide.state.is(Splide.STATES.IDLE)) {
        // //console.log(splide.state);
      }
    }
  }

  function autoplay($value) {
    let { Autoplay } = splide.Components;
    

    switch ($value) {
      case "play":
        Autoplay.play();
        break;

      case "stop":
        Autoplay.stop();
        break;

      case "pause":
        Autoplay.pause();
        break;
    }

    // splide.go( '>' );
    // //console.log(splide.index);
  }

  
  function go_to($id) {
    //console.log("go to : " + $id);
    krpano = document.getElementById("krpanoSWFObject");
    krpano.call("loadscene(" + $id + ", null, KEEPVIEW, BLEND(1))");
    // splide.go( '>' );
  }

  function prev() {
    splide.go( '-3' );
  }

  function next() {
    splide.go( '+3' );
  }

  
  dizajn.subscribe((value) => {
    _dizajn = value;
    read_scenes_from_settings();
  });
  
  floor.subscribe((value) => {
    _floor = value;
  });

  import {newDelay} from "../store.js";
  let delay = 0;
  async function setDelay(newDelay) {
		delay = newDelay;
	}

</script>

<div id="woow-shortcuts-new">

{#if loading_data == false && _vrMode == false && !_dispositions_enabled && !_enable_otacadlo || ( loading_data == false && _vrMode == false && !_dispositions_enabled && _enable_otacadlo && _should_deactivate_otacadlo_graphic) }
  {#if userdata["tooltips"] == true}
    <div class="shortcuts-icon" class:active={_shortcuts === true} class:hide_blur={blur_enabled === true || dis_icon == true || _flat_filter_enabled} class:moved={_enable_otacadlo && _should_deactivate_otacadlo_graphic}
      on:click={() => shortcuts.update(n => !n)}
      on:click={() => change_active_component("shortcuts")}
      use:tooltip={{ content: $t("components")['tooltips']['shortcuts'],  position: 'top', align: 'center', animation: get(tooltipAnimation), theme: 'tooltip-woow'}}
    />
  {:else}
  
    <div
      class="shortcuts-icon"
      class:active={_shortcuts === true}
      class:hide_blur={blur_enabled === true || dis_icon == true || _flat_filter_enabled}
      class:moved={_enable_otacadlo && _should_deactivate_otacadlo_graphic}
      on:click={() => shortcuts.update(n => !n)}
      on:click={() => change_active_component("shortcuts")}
    />
  
  {/if}  


  {#if _shortcuts}
  <div id="shortcuts" class="scene-thumbnails-tour" transition:fly={{y: 230, delay}} class:moved={_enable_otacadlo}>
    <img id="woow" src="{userdata['cdn_path']}images/woow-logo-slider.svg" alt="logo" on:click={() => window.open("https://woowstudio.com/")}/>

    <!-- <svelte:component this={Splide}>

    </svelte> -->
    <Splide bind:splide {options} hasTrack={false} aria-labelledby="heading">
      <div style="position: relative">
        <SplideTrack>
          {#each shorctuts_scenes as slide}
            <SplideSlide on:click={() => go_to(slide.name)}>
              <img
                id={slide.name} class={slide.name}
                src="{userdata['cdn_path']}assets/{userdata["assets_folder"]}scenes_thumbnails/{slide.thumbnail}" alt={slide.id}
                on:click={() => go_to(slide.name)}
              />
              <span on:click={() => go_to(slide.name)}>{slide.title}</span>
            </SplideSlide>
          {/each}
        </SplideTrack>
      </div>

      <div class="splide__progress">
        <div class="splide__progress__bar" />
      </div>

      <button class="splide__toggle">
        <span class="splide__toggle__play" on:click={() => autoplay("play")} />
        <span
          class="splide__toggle__pause"
          on:click={() => autoplay("pause")}
        />
      </button>
    </Splide>

    <!-- <div class="progress-bar">
      <div class="progress-bar-0" />
      <div id="progress" class="progress-bar-100" style="width: {width}%;" />
    </div> -->
    <div class="control-bar">
      <p class="title">{$t("components")["shortcuts"]["auto-tour"]}</p>
      <div class="controls-items">
        <div class="control-option previous" on:click={() => prev()} />
        {#if auto_play == true}
          <div
            class="control-option stop"
            on:click={() => swiper_autoplay_stop()}
          />
        {:else}
          <div
            class="control-option play"
            on:click={() => swiper_autoplay_start()}
          />
        {/if}
        <div class="control-option next" on:click={() => next()} />
      </div>
      <p class="pagination">
        <span class="current">{actual_index}</span>

        /
        <span class="all">{shorctuts_scenes.length}</span>
      </p>
    </div>
  </div>
  {/if}


{/if}

</div>

<style lang="scss">
  // -------------- SHORTCUTS je nastylovany v global.scss -------------------------------
  
  #woow-shortcuts-new{
  #woow {
    cursor: pointer;
    right: 0;
    position: absolute;
    z-index: 9;
    top: -48px;
  }

  .splide__toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    span.splide__toggle__play {
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-color: var(--primary-color);
        
      }
    }
    span.splide__toggle__pause {
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-color: var(--primary-color);
        
      }
    }
  }
}

</style>
