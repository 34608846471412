<script>
    import { Link } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import { navigate } from "svelte-routing";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";  
    import { getNotificationsContext } from 'svelte-notifications';
    const { addNotification } = getNotificationsContext();
  
    export let userdata;

    let new_standard = {
        name : ''
    };
  
    let timeOut = 300;    
    let status = "";
  
  async function create_standard() {
    if (
        new_standard.name != ''
    ) {
      const res = await fetch( userdata['base_path'] + 'assets/php/createConfiguratorStandard.php', {
        method: "POST",
        body: JSON.stringify(new_standard),
      })

      if (res.ok) {
        const json = await res.json();
        if (json.status == true) {
          addNotification({
            text: 'Štandard bol pridaný.',
            position: 'top-center',
            removeAfter: 4000,
            type: 'success'
          });
          navigate("product-standards");
        }

        else {
          addNotification({
            text: 'Štandard nebol pridaný !',
            position: 'top-center',
            removeAfter: 4000,
            type: 'danger'
          });
        }
          
      } else {
        throw new Error(data);
      }

    }
    else {
      addNotification({
        text: 'Vyplnťe všetky povinné polia!',
        position: 'top-center',
        removeAfter: 4000,
        type: 'danger'
      });
    }
  }
  </script>
  
  <div id="wrapper">
    <Aside />
    
    <main in:fade>
      <h1>{$t("admin")["aside"]["configurator"]["add_standard"]}</h1>
  
          <!-- Názov kategórie  -->
          <div class="component">
            <label for="name">{$t("admin")["global"]["name"]}*</label>
            <input type="text" bind:value="{new_standard.name}" name="name">
          </div>

          <!-- Button -->
          <div class="component">
            <button on:click="{() => create_standard()}">{$t("admin")["configurator"]["standards"]["create_standard"]}</button>
          </div>
    </main>
    <Header />
  
    </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";
  </style>