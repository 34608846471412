<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    import {shortcuts_settings} from "../../store.js";
  
    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
    };

    
    let _shortcuts_settings = null;

    shortcuts_settings.subscribe((value) => {
		_shortcuts_settings = value;
	});
    // Načítanie dát z DB
    async function getData($id, $target, $var) {
      let data = {id : $id} 
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
            (key == "enabled" && json[0][key] == "1") 
          ) {
              json[0][key] = true
          }
          
        }

        switch ($var) {
          case "shortcuts_settings" :
          shortcuts_settings.update(n => json[0]);
          break;
        }
        
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("shortcuts", shortcuts_settings, "shortcuts_settings");

    async function save_settings() {
      let formData = new FormData(); 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(_shortcuts_settings));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateShortcuts.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia Shortcuts boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia Shortcuts neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if _shortcuts_settings != null}
    <main in:fade>
      
      <h1>{$t("components")["shortcuts"]['admin-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
       
      <div class="component item">
        <label>Povoliť ?</label>
        <input type="checkbox" bind:checked={_shortcuts_settings.enabled}>
      </div>  

      <div class="component">
          <label for="scenes">{$t("components")['shortcuts']['scenes-short-description']}</label>
          <select name="scenes" bind:value="{_shortcuts_settings.filter}">
            <option class="all" value="all">{$t("components")['shortcuts']['scenes-all']}</option>
            <option value="tag">{$t("components")['shortcuts']['scenes-tag']}</option>
          </select>
        </div>   
        <div class="component">
          <label for="titles">{$t("components")['shortcuts']['titles-short-description']}</label>
            <select name="titles" bind:value="{_shortcuts_settings.title}">
                <option value="number">{$t("components")['shortcuts']['title-number']}</option>
                <option value="title">{$t("components")['shortcuts']['title-title']}</option>
                <option value="custom_field_1">{$t("components")['shortcuts']['title-cf-1']}</option>
                <option value="custom_field_2">{$t("components")['shortcuts']['title-cf-2']}</option>
                <option value="custom_field_3">{$t("components")['shortcuts']['title-cf-3']}</option>
                <option value="custom_field_4">{$t("components")['shortcuts']['title-cf-4']}</option>
                <option value="custom_field_5">{$t("components")['shortcuts']['title-cf-5']}</option>
                <option value="custom_field_6">{$t("components")['shortcuts']['title-cf-6']}</option>
                <option value="custom_field_7">{$t("components")['shortcuts']['title-cf-7']}</option>
                <option value="custom_field_8">{$t("components")['shortcuts']['title-cf-8']}</option>
                <option value="custom_field_9">{$t("components")['shortcuts']['title-cf-9']}</option>
                <option value="custom_field_10">{$t("components")['shortcuts']['title-cf-10']}</option>

            </select>
        </div>   
    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>