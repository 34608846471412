<script>
import { Link } from "svelte-routing";
import { token } from "../../stores.js";
import { t, locale, locales } from "../../i18n.js";
import { fade } from "svelte/transition";
import Aside from "../../layouts/_aside.svelte";
import Header from "../../layouts/_header.svelte";
import { navigate } from "svelte-routing";

import { getNotificationsContext } from 'svelte-notifications';
const { addNotification } = getNotificationsContext();

export let userdata;
let loading_data = true;
let enable_standards, standards_types_loaded, product_categories_loaded, mj_loaded = false;
let standards_types, product_categories = [];

let mj = [];

// Načítanie dát z DB
async function getData($id, $target, $var, $par) {
    loading_data = true;
    let data = {
        id: $id
    }
    const res = await fetch(
        userdata['base_path'] + "assets/php/getData.php", {
            method: "POST",
            body: JSON.stringify(data),
        }
    );

    if (res.ok) {
        const json = await res.json();

        // prevod hodnoty boolean
        if (
            typeof json[0] === 'object'

            // Object.keys(json).length === 0
        ) {
            const keys = Object.keys(json[0]);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (
                    (key == "enabled" && json[0][key] == "0")
                ) {
                    json[0][key] = false
                }

                if (
                    (key == "enabled" && json[0][key] == "1")
                ) {
                    json[0][key] = true
                }

            }

            switch ($var) {
                case "standards_types":
                  standards_types = json;
                  standards_types_loaded = true;
                  console.log(standards_types);

                  standards_types.forEach(element => {
                    element.enabled = false;
                    element.copy = false;
                    element.target = "";
                    element.standard_fee = 0;
                  });
                  getData("product_categories", product_categories, "product_categories");  
                break;

                case "product_categories" : 
                  product_categories = json;
                  product_categories_loaded = true;
                break;

                case "measures_units" : 
                  mj = json;
                  mj_loaded = true;
                  console.log(mj);
                break;

                    // case "measures_units" : 
                    // measures_units = json;

                    // measures_units_loaded = true;
                    // console.log(products);
                    // console.log(measures_units);
                    // break;



                    // case "sample_flats_scenes" : 
                    // sample_flats_scenes = json;
                    // sample_flats_scenes_loaded = true;
                    // break;
            }

            loading_data = false;
        } else {
            console.log("žiadne dáta");
        }

    } else {
        throw new Error(data);
    }
}

getData("standards_types", standards_types, "standards_types");
getData("measures_units", mj, "measures_units");

let new_date;

function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    new_date = yyyy + '-' + mm + '-' + dd;
}

getDate();
let result;

let new_product = {
    patch_id : "",
    name: '',
    mj: "1",
    category : '',
    variants : []
};

let timeOut = 300;
let status = "";
let order_index = 0;

let product_exist = false;

async function create_product(value) {

    if (
        new_product.id != '' &&
        new_product.name != '' &&
        new_product.category != '' &&
        new_product.mj != ''
    ) {

      standards_types.forEach(standard => {
          new_product.variants.forEach(variant => {
            if (
              variant.type == standard.name
            ) {
              variant.standard_fee = standard.standard_fee;
            }
          });
      });

        console.log(new_product);
        const res = await fetch(userdata['base_path'] + 'assets/php/createConfiguratorProduct.php', {
            method: "POST",
            body: JSON.stringify(new_product),
        })

        if (res.ok) {
            const json = await res.json()
            result = JSON.stringify(json);

            if (json.status == true) {
              product_exist = false;
                addNotification({
                    text: 'Produkt bol pridaný.',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'success'
                });

                switch (value) {

                  case true : 
                    navigate("products");
                  break;

                  case false :
                    new_product = {
                      patch_id : "",
                      name: '',
                      mj: "1",
                      category : '',
                      variants : []
                  };

                  status = "";
                  order_index = 0;
                  product_exist = false;
                  break;
                }

            } else if (json.status == false) {
                product_exist = true;
                addNotification({
                    text: 'ID produktu existuje!',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'danger'
                })
            }

        } else {
            throw new Error(data);
        }

    } else {
        addNotification({
            text: 'Vyplnťe všetky povinné polia!',
            position: 'top-center',
            removeAfter: 4000,
            type: 'danger'
        })
    }
}

function add_variant(name) {

  new_product.variants = [...new_product.variants, {
    id : "",
    patch_id : null,
    name : "",
    short_description : "",
    description : "",
    type : name,
    price : 0,
    order : order_index * 10,
    standard_fee : 0
  }]

  order_index++

  console.log(new_product);
}

function remove_variant(index) {
  console.log(index);
  new_product.variants.splice(index, 1);
  new_product.variants = new_product.variants;
}


function remove_single_variants(parameter, mode) {
  var i = new_product.variants.length;

  switch (mode) {
    case true:
      while (i--) {
        if (new_product.variants[i].type == parameter) {
          new_product.variants.splice(i, 1);
        }
      }    
      break;
  
    default:
      while (i--) {
          if (new_product.variants[i].type !== parameter) {
            new_product.variants.splice(i, 1);
          }
        }    
      break;
  }

  
}

$: {
  enable_standards;


  switch (enable_standards) {
    case true : 
      remove_single_variants("single", true);
    break;

    case false : 
      remove_single_variants("single", false);

      standards_types.forEach(element => {
          element.enabled = false;
      });
    break;
  }
}
  

function test() {
  console.log(standards_types);
}

function copy_variants($name, $target) {
  console.log($name + " " + $target);
  new_product.variants.forEach(element => {
      if (
    element.type == $name
      ) {
        new_product.variants = [...new_product.variants, {
          id : element.id,
          name : element.name,
          short_description : element.short_description,
          description : element.description,
          type : $target,
          price : element.price,
          order : element.order
        }];
      }
  });
}

function sort() {
  new_product.variants.sort((a, b) => (a.order > b.order) ? 1 : -1);

  new_product.variants = new_product.variants;
}

</script>

<div id="wrapper">
    <Aside />
    <main in:fade>
        <h1>{$t("admin")["aside"]["configurator"]["add_product"]}</h1>
        <div class="row right">
          <!-- Button -->
          <button on:click="{() => create_product(true)}"><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["configurator"]["products"]["create_product"]}</button>
          <button on:click="{() => create_product(false)}"><i class="fa-regular fa-floppy-disk"></i>{$t("admin")["configurator"]["products"]["create_and_add"]}</button>
          <!-- button zrušiť -->
          <button class="cancel" on:click="{() => navigate("products")}"><i class="fa-solid fa-ban"></i>{$t("admin")["global"]["cancel"]}</button>
        </div>  
        <div class="row">
          <!-- Patch ID  -->
          <div class="component col4">
            <label class="full" for="patch_id"> {$t("admin")["configurator"]["products"]["patch_id"]}*
              <input class:danger="{product_exist == true}" type="text" bind:value="{new_product.patch_id}" name="patch_id">
            </label>
        </div>

          <!-- Názov produktu  -->
          <div class="component col4">
              <label class="full" for="name">{$t("admin")["global"]["name"]}*
                <input type="text" bind:value="{new_product.name}" name="name">
              </label>
          </div>

          <!-- Kategória produktu  -->
          <div class="component col4">
            <label class="full" >{$t("admin")["configurator"]["products"]["category"]}*
              <select bind:value={new_product.category}>
                {#each product_categories as category}
                    <option value={category.id}>
                      {category.name}
                    </option>
                {/each}
              </select>
            </label>
          </div>

           <!-- MJ produktu  -->
          <div class="component col4">
            <label class="full">{$t("admin")["configurator"]["products"]["units"]}*
              <select bind:value={new_product.mj}>
                {#each mj as item}
                    <option value={item.id}>
                      {item.name}
                    </option>
                {/each}
              </select>
            </label>
          </div>
        </div>


       

        <!-- Povoliť štandardy  -->
        <div class="component block">
            <label for="standards">{$t("admin")["configurator"]["products"]["enable_standards"]}</label>
            <input type="checkbox" name="enabled" bind:checked="{enable_standards}">
        </div>

        {#if enable_standards == true && standards_types_loaded == true}
          {#each standards_types as standard}
            <!-- Povoliť konkrétny štandard  -->
            <div class="component comp-standard">
                <label>{standard.name}
                  <input type="checkbox" bind:checked={standard.enabled}>
                </label>
                {#if standard.enabled == true} 
                  <!-- Standard fee  -->
                   <label>{$t("admin")["global"]["fee"]}
                    <input type="number" bind:value="{standard.standard_fee}">
                  </label>  

                    <!-- button pridať variant -->
                    <button class="add_variant" on:click="{() => add_variant(standard.name)}">+ {$t("admin")["configurator"]["products"]["add_variant"]}</button>
                    <label class="variant-copy">{$t("admin")["global"]["copy"]}?
                      <input type="checkbox" bind:checked={standard.copy}>
                    </label>

                    {#if standard.copy == true}
                      <label class="standard-copy-type" for="name">{$t("admin")["configurator"]["products"]["select_standard"]}:</label>
                        <select class="standard-select" bind:value={standard.target}>
                          {#each standards_types as value}
                            {#if value.name != standard.name}
                              <option value={value.name}>
                                {value.name}
                              </option>
                            {/if}
                      {/each}

                    </select>
                    <button on:click="{() => copy_variants(standard.name, standard.target)}">{$t("admin")["global"]["copy"]}</button>
                    {/if}

                {/if}
              </div>  
              
              {#if standard.enabled == true} 
              {#if new_product.variants.length > 0}
              <table class="variant-table">
                <thead>
                    <tr>
                      <td>{$t("admin")["global"]["id"]}</td>
                      <td>{$t("admin")["configurator"]["products"]["patch_number"]}</td>
                      <td>{$t("admin")["global"]["name"]}</td>
                      <td>{$t("admin")["global"]["description_short"]}</td>
                      <td>{$t("admin")["global"]["description"]}</td>
                      <td>{$t("admin")["global"]["image"]}</td>
                      <td>{$t("admin")["global"]["price"]}</td>
                      <td>{$t("admin")["global"]["order"]}</td>
                      <td>{$t("admin")["global"]["delete"]}</td>
                    </tr>
                  </thead>
                  <tbody>    
                    {#each new_product.variants as variant, index}
                    {#if variant.type == standard.name}
                      <tr>
                        <td><input type="text" bind:value="{variant.id}"></td>
                        <td><input type="text" class="variant-order" bind:value="{variant.patch_id}"></td>
                        <td><input type="text" bind:value="{variant.name}"></td>
                        <td><input type="text" bind:value="{variant.short_description}"></td>
                        <td><textarea bind:value="{variant.description}"></textarea></td>
                        <td><img class="variant-thumb" src="../assets/thumbnails/{variant.id}.jpg" alt="assets/thumbnails/{variant.id}.jpg"></td>
                        <td><input type="number" bind:value="{variant.price}"></td>
                        <td><input class="variant-order" type="number" bind:value="{variant.order}" on:change={() => sort()}></td>
                        <td><button class="remove" on:click="{() => remove_variant(index)}">X</button></td>
                      </tr>
                    {/if}
                        
                    {/each}
                  </tbody>
                </table>
              {/if}
              {/if}
          {/each}
        {:else}
          <!-- Button -->
          <div class="component">
            <button class="add_variant" on:click="{() => add_variant("single")}">+ {$t("admin")["configurator"]["products"]["add_variant"]}</button>
          </div>
          {#if new_product.variants.length > 0}
          <table class="variant-table">
            <thead>
                <tr>
                  <td>{$t("admin")["global"]["id"]}</td>
                  <td>{$t("admin")["configurator"]["products"]["patch_number"]}</td>
                  <td>{$t("admin")["global"]["name"]}</td>
                  <td>{$t("admin")["global"]["description_short"]}</td>
                  <td>{$t("admin")["global"]["description"]}</td>
                  <td>{$t("admin")["global"]["image"]}</td>
                  <td>{$t("admin")["global"]["price"]}</td>
                  <td>{$t("admin")["global"]["order"]}</td>
                  <td>{$t("admin")["global"]["delete"]}</td>
                </tr>
              </thead>
              <tbody>    
                {#each new_product.variants as variant, index}
                {#if variant.type === "single"}
                  <tr>
                    <td><input type="text" bind:value="{variant.id}"></td>
                    <td><input type="number" class="variant-order" bind:value="{variant.patch_id}"></td>
                    <td><input type="text" bind:value="{variant.name}"></td>
                    <td><input type="text" bind:value="{variant.short_description}"></td>
                    <td><textarea bind:value="{variant.description}"></textarea></td>
                    <td><img class="variant-thumb" src="assets/thumbnails/{variant.id}.jpg" alt="assets/thumbnails/{variant.id}.jpg"></td>
                    <td><input type="number" bind:value="{variant.price}"></td>
                    <td><input class="variant-order" type="number" bind:value="{variant.order}" on:change={() => sort()}></td>
                    <td><button class="remove" on:click="{() => remove_variant(index)}">X</button></td>
                  </tr>
                {/if}
                    
                {/each}
              </tbody>
            </table>
          {/if}

        {/if}

       
    </main>
    <Header />

</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";

  .component {
    &.comp-standard {
      label {  
        display: flex;
        justify-content: space-between;
        align-items: center;   
        gap: 6px; 
        margin: 6px 0 0;
      }
    }

    label, input, select {      
      margin: 6px 0 0;
      
      &.danger {
        border: 1px solid red;
      }
    }

    label {
      &.variant-copy {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    button {
      &.add_variant {
        background-color: $warning;
        color: $text-color-active;
      }
    }

    &.block {
      background-color: lighten($text-color, 28);
    }
  }

  .col4 {
    width: 25%;
    max-width: 25%;
    
    label {
      &.full {
      width: 100%;
      }
    }
  }


  
</style>
