<script>
import { Link } from "svelte-routing";
import { token } from "../../stores.js";
import { t, locale, locales } from "../../i18n.js";
import { fade } from "svelte/transition";
import Aside from "../../layouts/_aside.svelte";
import Header from "../../layouts/_header.svelte";
import { navigate } from "svelte-routing";

import { product_id } from "../../store.js";

let _product_id;
product_id.subscribe((value) => {
		_product_id = value;	
    console.log(_product_id);	
});

import { getNotificationsContext } from 'svelte-notifications';
const { addNotification } = getNotificationsContext();

export let userdata;
let loading_data = true;
let enable_standards, standards_types_loaded, product_categories_loaded, mj_loaded, products_loaded, products_variants_loaded = false;
let products, standards_types, product_categories, products_variants = [];

let mj = [];
let order_index = 0;

// Načítanie dát z DB
async function getData($id, $target, $var, $par) {
    loading_data = true;
    let data = {
        id: $id
    }
    const res = await fetch(
        userdata['base_path'] + "assets/php/getData.php", {
            method: "POST",
            body: JSON.stringify(data),
        }
    );

    if (res.ok) {
        let json = await res.json();

        // prevod hodnoty boolean
        if (
            typeof json === 'object'

            // Object.keys(json).length === 0
        ) {
            const keys = Object.keys(json[0]);
            
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                
                if (
                    (key == "enabled" && json[i][key] == "0")
                ) {
                    json[i][key] = false
                }

                if (
                    (key == "enabled" && json[i][key] == "1")
                ) {
                    json[i][key] = true
                }
            }

            console.log(json);

            switch ($var) {
                case "standards_types":
                  standards_types = json;
                  standards_types_loaded = true;
                  console.log(standards_types);

                  standards_types.forEach(element => {
                    element.enabled = false;
                    element.copy = false;
                    element.target = "";
                    element.standard_fee = 0;
                  });
                  getData("product_categories", product_categories, "product_categories");  
                break;

                case "product_categories" : 
                  product_categories = json;
                  product_categories_loaded = true;

                  getData("products", products, "products");
                break;

                case "products_variants" : 
                  products_variants = json;
                  products_variants_loaded = true;

                  products_variants.forEach(element => {
                    element.delete = false;
                    order_index++;


                    element.variant_order = parseInt(element.variant_order);
                    element.price = parseInt(element.price);
                    element.standard_fee = parseInt(element.standard_fee);

                    if (
                      element.product_id == _product_id
                    ) {
                      if (
                        element.type != "single"
                      ) {

                        standards_types.forEach(standard => {
                          if ( standard.name == element.type) {
                            standard.enabled = true;
                            standard.standard_fee = element.standard_fee;
                          }

                        });
                        enable_standards = true;
                      }
                    }
                  });

                  console.log(products_variants);
                  sort();
                  

                  getData("measures_units", mj, "measures_units");
                break;

                case "products" : 
                  products = json;
                  products_loaded = true;

                  getData("products_variants", products_variants, "products_variants");
                break;

                case "measures_units" : 
                  mj = json;
                  mj_loaded = true;
                  console.log(mj);

                 // sort();
                break;
            }

            loading_data = false;
        } else {
            console.log("žiadne dáta");
        }

    } else {
        throw new Error(data);
    }
}

getData("standards_types", standards_types, "standards_types");

let new_date;

function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    new_date = yyyy + '-' + mm + '-' + dd;
}

getDate();
let result;

let timeOut = 300;
let status = "";


let product_exist = false;

async function update_product() {
  let data = {
    product : null, 
    variants : []
  }
  products.forEach(element => {
    if (element.id == _product_id) {
      data.product = element;
    }
  });

  products_variants.forEach(element => {
    console.log(element);
    console.log(_product_id);
    if (element.product_id == _product_id) {
      data.variants = [...data.variants, element
      ];
    }
  });

    if (
        data.product.id != '' &&
        data.product.name != '' &&
        data.product.category != '' &&
        data.product.mj != ''
    ) {

      standards_types.forEach(standard => {
          data.variants.forEach(variant => {
            if (
              variant.type == standard.name
            ) {
              variant.standard_fee = standard.standard_fee;
            }
          });
      });

      const res = await fetch(userdata['base_path'] + 'assets/php/modifyConfiguratorProduct.php', {
          method: "POST",
          body: JSON.stringify(data),
      })

        if (res.ok) {
            const json = await res.json()
            result = JSON.stringify(json);

            if (json.status == true) {
              product_exist = false;
                addNotification({
                    text: 'Produkt bol pridaný.',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'success'
                });

                //getData("products", products, "products");
                navigate("products");
                product_id.update(n => null);
                

            } else if (json.status == false) {
                product_exist = true;
                addNotification({
                    text: 'ID produktu existuje!',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'danger'
                })
            }

        } else {
            throw new Error(data);
        }

    } else {
        addNotification({
            text: 'Vyplnťe všetky povinné polia!',
            position: 'top-center',
            removeAfter: 4000,
            type: 'danger'
        })
    }
}

function add_variant(name, id, fee) {

  products_variants = [...products_variants, {
    id : null,
    variant_id : "",
    name : "",
    product_id : id,
    short_description : "",
    description : "",
    type : name,
    price : 0,
    order : order_index * 10,
    standard_fee : 0,
    variant_order : order_index,
    delete : false
  }];

  products_variants = products_variants;

  order_index++;
  console.log(products_variants);
}

function remove_variant(index) {
  console.log(index);
  products_variants[index].delete = true;
  
}


function remove_single_variants(parameter, mode) {
  var i = products_variants.length;

  switch (mode) {
    case true:
      while (i--) {
        if (products_variants[i].type == parameter) {
          products_variants.splice(i, 1);
        }
      }    
      break;
  
    default:
      while (i--) {
          if (products_variants[i].type !== parameter) {
            products_variants.splice(i, 1);
          }
        }    
      break;
  }

  
}

function sort() {
  console.log("sortujem");
  products_variants.sort((a, b) => (a.variant_order > b.variant_order) ? 1 : -1);
  products_variants = products_variants;
}

$: {
  enable_standards;


  switch (enable_standards) {
    case true : 
      remove_single_variants("single", true);
    break;

    case false : 
      remove_single_variants("single", false);

      standards_types.forEach(element => {
          element.enabled = false;
      });
    break;
  }
}

</script>

<div id="wrapper">
    <Aside />
    <main in:fade>
        <h1 on:click={() => sort()}>{$t("admin")["configurator"]["products"]["edit_product"]} (id:{_product_id})</h1>
        {#if products_loaded && product_categories_loaded && products_variants_loaded && standards_types_loaded && mj_loaded}
          {#each products as item}
            {#if item.id == _product_id}            
            <div class="row right">
              <!-- button uložiť produkt -->
              <button on:click="{() => update_product()}"><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["update"]}</button>
              <!-- button zrušiť -->
              <button class="cancel" on:click="{() => navigate("products")}"><i class="fa-solid fa-ban"></i>{$t("admin")["global"]["cancel"]}</button>
              
            </div>  

            <div class="row">
              <!-- Patch ID  -->
              <div class="component col4">
                <label class="full" for="patch_id"> {$t("admin")["configurator"]["products"]["patch_id"]}
                  <input class:danger="{product_exist == true}" type="text" bind:value="{item.patch_id}" name="patch_id">
                </label>
              </div>

              <!-- Názov produktu  -->
              <div class="component col4">
                  <label class="full" for="name">{$t("admin")["global"]["name"]}
                    <input type="text" bind:value="{item.name}" name="name">
                  </label>
              </div>

              <!-- Kategória produktu  -->
              <div class="component col4">
                <label class="full" >{$t("admin")["configurator"]["products"]["category"]}
                  <select bind:value={item.category}>
                    {#each product_categories as category}
                        <option value={category.id}>
                          {category.name}
                        </option>
                    {/each}
                  </select>
                </label>
              </div>

              <!-- MJ produktu  -->
              <div class="component col4">
                <label class="full">{$t("admin")["configurator"]["products"]["units"]}
                  <select bind:value={item.mj}>
                    {#each mj as item}
                        <option value={item.id}>
                          {item.name}
                        </option>
                    {/each}
                  </select>
                </label>
              </div>
            </div>
            
            {#if enable_standards}
              
    
              <!-- Povoliť štandardy  -->
              <div class="component block">
                <label for="standards">{$t("admin")["configurator"]["products"]["enable_standards"]}</label>
                <input type="checkbox" name="enabled" bind:checked="{enable_standards}">
              </div>

              {#each standards_types as standard}
                <!-- Povoliť konkrétny štandard  -->
                <div class="component comp-standard">
                  <label>{standard.name}
                    <input type="checkbox" bind:checked={standard.enabled}>
                  </label>
                  {#if standard.enabled == true} 
                    <!-- Standard fee  -->
                      <label>{$t("admin")["global"]["fee"]}
                      <input type="number" bind:value="{standard.standard_fee}">
                    </label>  

                    <!-- button pridať variant -->
                    <button class="add_variant" on:click="{() => add_variant(standard.name, _product_id, standard.standard_fee)}">+ {$t("admin")["configurator"]["products"]["add_variant"]}</button>
                  
                  {/if}
                </div>  
                  
                  {#if standard.enabled == true} 
                  <table class="variant-table">
                    <thead>
                      <tr>
                        <td>{$t("admin")["configurator"]["products"]["patch_id"]}</td>
                        <td>{$t("admin")["configurator"]["products"]["patch_number"]}</td>
                        <td>{$t("admin")["global"]["name"]}</td>
                        <td>{$t("admin")["global"]["description_short"]}</td>
                        <td>{$t("admin")["global"]["description"]}</td>
                        <td>{$t("admin")["global"]["image"]}</td>
                        <td>{$t("admin")["global"]["price"]}</td>
                        <td>{$t("admin")["global"]["order"]}</td>
                        <td>{$t("admin")["global"]["delete"]}</td>
                      </tr>
                    </thead>
                    <tbody>
                        {#each products_variants as variant, index}
                          {#if variant.type == standard.name && Number(variant.product_id) == Number(item.id) && variant.delete == false}
                            <tr>
                              <td><input type="text" bind:value="{variant.variant_id}"></td>
                              <td><input type="text" class="variant-order" bind:value="{variant.patch_id}"></td>
                              <td><input type="text" bind:value="{variant.name}"></td>
                              <td><input type="text" bind:value="{variant.short_description}"></td>
                              <td><textarea bind:value="{variant.description}"></textarea></td>
                              <td><img class="variant-thumb" src="../assets/thumbnails/{variant.variant_id}.jpg" alt="assets/thumbnails/{variant.variant_id}.jpg"></td>
                              <td><input type="number" bind:value="{variant.price}"></td>
                              <td><input class="variant-order" type="number" bind:value="{variant.variant_order}" on:change={() => sort()}></td>
                              <td><button class="remove" on:click="{() => remove_variant(index)}">X</button></td>
                            </tr>
                          {/if}
                        {/each}
                      
                    </tbody>
                  </table>
                  {/if}
              {/each}

            {:else}
              <!-- Button pridať single variant -->
              <div class="component">
                <button class="add_variant" on:click="{() => add_variant("single", _product_id, 0)}">+ {$t("admin")["configurator"]["products"]["add_variant"]}</button>
              </div>
              {#if products_variants != undefined}
                <table class="variant-table">
                  <thead>
                    <tr>
                      <td>{$t("admin")["configurator"]["products"]["patch_id"]}</td>
                      <td>{$t("admin")["configurator"]["products"]["patch_number"]}</td>
                      <td>{$t("admin")["global"]["name"]}</td>
                      <td>{$t("admin")["global"]["description_short"]}</td>
                      <td>{$t("admin")["global"]["description"]}</td>
                      <td>{$t("admin")["global"]["image"]}</td>
                      <td>{$t("admin")["global"]["price"]}</td>
                      <td>{$t("admin")["global"]["order"]}</td>
                      <td>{$t("admin")["global"]["delete"]}</td>
                    </tr>
                  </thead>
                  <tbody>
                      {#each products_variants as variant, index}
                        {#if variant.type === "single" && Number(variant.product_id) == Number(item.id) && variant.delete != true }
                          <tr>
                            <td><input type="text" bind:value="{variant.variant_id}"></td>
                            <td><input type="text" class="variant-order" bind:value="{variant.patch_id}"></td>
                            <td><input type="text" bind:value="{variant.name}"></td>
                            <td><input type="text" bind:value="{variant.short_description}"></td>
                            <td><textarea bind:value="{variant.description}"></textarea></td>
                            <td><img class="variant-thumb" src="assets/thumbnails/{variant.variant_id}.jpg" alt="assets/thumbnails/{variant.variant_id}.jpg"></td>
                            <td><input type="number" bind:value="{variant.price}"></td>
                            <td><input class="variant-order" type="number" bind:value="{variant.variant_order}" on:change={() => sort()}></td>
                            <td><button class="remove" on:click="{() => remove_variant(index)}">X</button></td>
                          </tr>
                        {/if}
                      {/each}         
                  </tbody>
                </table>
              {/if}
            {/if}

            {/if}
          {/each}
        {/if}       
    </main>
    <Header />

</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";

  .component {
    &.comp-standard {
      label {  
        display: flex;
        justify-content: space-between;
        align-items: center;   
        gap: 6px; 
        margin: 6px 0 0;
      }
    }

    label, input, select {      
      margin: 6px 0 0;
      
      &.danger {
        border: 1px solid red;
      }
    }

    label {
      &.variant-copy {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    button {
      &.add_variant {
        background-color: $warning;
        color: $text-color-active;
      }
    }

    &.block {
      background-color: lighten($text-color, 28);
    }
  }

  .col4 {
    width: 25%;
    max-width: 25%;
    
    label {
      &.full {
      width: 100%;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    max-width: 960px;
    margin: 0 auto;

    &.right {
      justify-content: flex-end;
    }
  }
</style>
