<script>
    import {loaded_krpano} from "../store.js";

    loaded_krpano.subscribe((value) => {
        if (
            value == true
        ) {
            let krpano = document.getElementById("krpanoSWFObject");

           
            
        }
    });



       
</script>

<style lang="scss">

</style>