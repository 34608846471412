import {favIDs, topNRs} from "./store.js";

export function mySharedFunction(param) {
    // Vaša zdieľaná funkcionalita
    return param.toUpperCase();
}

export function setCookie(paramName, id, onlySet) {

    const exdays = 365;
    const cookies = document.cookie;
   
    const cookieStartIndex = cookies.indexOf(`${paramName}=`);
    ////console.log(cookieStartIndex);

    // Ak parameter 'cname' nie je v cookies, pridaj nový
    if (cookieStartIndex === -1) {
        document.cookie = `${paramName}=${id}`;
        ////console.log(`ID ${id} pridané do obľúbených.`);
        return;
    }


    // Nájdi koniec hodnoty parametra 'fav'
    let cookieEndIndex = cookies.indexOf(';', cookieStartIndex);
    if (cookieEndIndex === -1) {
        cookieEndIndex = cookies.length;
    }

    // Získať hodnotu parametra 'fav' a rozdeliť ju na ID bytov
    const favParamValue = cookies.substring(cookieStartIndex + paramName.length + 1, cookieEndIndex);
    const favoriteIDs = favParamValue.split(',');

    // Odstrániť znak čiarky z počiatku reťazca, ak existuje
    if (favoriteIDs[0] === '') {
        favoriteIDs.shift();
    }

    // Skontrolovať, či je ID v zozname
    const idIndex = favoriteIDs.indexOf(id.toString());

    //console.log(idIndex);

    let updatedValue;

    if (idIndex !== -1) {
        // ID bytu je v zozname, takže ho odstráň
        if (onlySet != "set") {
            favoriteIDs.splice(idIndex, 1);
            updatedValue = favoriteIDs.join(',');
            const finalValue = updatedValue || ''; // Ak je updatedValue prázdný, použije sa prázdny reťazec
            ////console.log(updatedValue);
            document.cookie = `${paramName}=${finalValue}`;
            ////console.log(`ID ${id} odstránené z obľúbených.`);
        }
        
    } else {
        // ID bytu nie je v zozname, takže ho pridaj
            //console.log("nie je v zozname");
            favoriteIDs.push(id);
            updatedValue = favoriteIDs.join(',');
            ////console.log(updatedValue);
            document.cookie = `${paramName}=${updatedValue}`;
            ////console.log(`ID ${id} pridané do obľúbených.`);
        
        
    }

    if (paramName == "favID") {
        // zápis hodnôt do store premennej
        if (updatedValue == "") {
            favIDs.set([]);
        } else {
            favIDs.set(updatedValue.split(","));    
        }
    }

    
}

export function getCookie(paramName) {
    const cookies = document.cookie;
    const cookieStartIndex = cookies.indexOf(`${paramName}=`);
    
    if (cookieStartIndex === -1) {
        return null; // Parameter nie je prítomný v cookies
    }

    let cookieEndIndex = cookies.indexOf(';', cookieStartIndex);
    if (cookieEndIndex === -1) {
        cookieEndIndex = cookies.length;
    }

    const CookieArray = cookies.substring(cookieStartIndex + paramName.length + 1, cookieEndIndex);

    switch (paramName) {
        case "favID":
            if (CookieArray == "") {
                favIDs.set([]);
            } else {
                favIDs.set(CookieArray.split(","));    
            }        
        break;

        case "topNR":
            if (CookieArray == "") {
                topNRs.set([]);
            } else {
                topNRs.set(CookieArray.split(","));    
            }        
        break;
    
        default:
            break;
    }   
}

export const formatter = new Intl.NumberFormat('sk-SK', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits : 0
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export function rearrangeArray(defaultArray, finalArray, activeIndex, way) {
    switch (way) {
        case "left" : 
            // Pridáme prvky od nuly po prvok za activeIndex
            for (let i = 0; i < activeIndex; i++) {
                finalArray.push(defaultArray[i]);
            }

            // Pridáme prvky odzadu po prvok pred activeIndex
            for (let i = defaultArray.length - 1; i > activeIndex; i--) {
                finalArray.push(defaultArray[i]);
            }

           
        break;

        case "right" : 
            // Pridáme prvky od začiatku po prvok za activeIndex
            for (let i = activeIndex + 1; i < defaultArray.length; i++) {
                finalArray.push(defaultArray[i]);
            }

            // Ak je potrebné, pridáme prvky od nuly po activeIndex
            if (activeIndex > 0) {
                for (let i = 0; i < activeIndex; i++) {
                    finalArray.push(defaultArray[i]);
                }
            }
        break;
    }
    ////console.log(finalArray);
}

export function verifiyFloor(number) {
    let cislo = "";
    if (number >= 0 && number <= 9) {
        cislo =  '0' + number;
    } else {
        
        cislo = number.toString();
    }
    return (cislo);
}

export function fadeScale (
    node, { delay = 0, duration = 200, easing = x => x, baseScale = 0 }
    ) {
    const o = +getComputedStyle(node).opacity;
    const m = getComputedStyle(node).transform.match(/scale\(([0-9.]+)\)/);
    const s = m ? m[1] : 1;
    const is = 1 - baseScale;

    return {
        delay,
        duration,
        css: t => {
        const eased = easing(t);
        return `opacity: ${eased * o}; transform: scale(${(eased * s * is) + baseScale})`;
        }
    };
}