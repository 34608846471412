<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
    
    let layers_loaded = false;
    let layers_data = [];

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
  };

    // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 

      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        
        if(typeof json !== 'undefined' && json !== null && json.length != 0 ) {
          const keys = Object.keys(json[0]);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                (key == "enabled" && json[0][key] == "0")
            ) {
                json[0][key] = false
            }

            if (
                key == "enabled" && json[0][key] == "1"
            ) {
                json[0][key] = true
            }
          }
          layers_data = json;
        }
        
        layers_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("layers");


  
    async function save_settings() {
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(layers_data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateLayers.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

    // sample data pre novú vrstvu
    let new_layer = {
      enabled : true,
      name : "",
      krpano_value : "",
      krpano_variable : "design",
      layer_order: 100
    }

    // pridať novú vrstvu
    async function add_layer() {
      let data = new_layer; 

      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      const res = await fetch(
        userdata['cdn_path'] + "php/admin/addLayer.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        
        if (
            typeof json[0] === 'object'

            // Object.keys(json).length === 0
        ) {
          const keys = Object.keys(json[0]);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                (key == "enabled" && json[0][key] == "0")
            ) {
                json[0][key] = false
            }

            if (
                key == "enabled" && json[0][key] == "1"
            ) {
                json[0][key] = true
            }
          }
          layers_data = json[0];
        }

        addNotification({
          text: "Vrstva bola pridaná!",
          position: "top-center",
          removeAfter: 4000,
          type: "success",
        }); 
        
        layers_loaded = false;
        getData("layers");
        return json;
      } else {
        throw new Error(data);
      }
  	}

    // aktualizácia vrstvy
    async function update_layer($id) {
      let data = null; 
      layers_data.forEach(element => {
          if (element.id == $id) {
            data = element; 
          }
      });

      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));

      
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateLayer.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        if (
            typeof json[0] === 'object'

            // Object.keys(json).length === 0
        ) {
          const keys = Object.keys(json[0]);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                (key == "enabled" && json[0][key] == "0")
            ) {
                json[0][key] = false
            }

            if (
                key == "enabled" && json[0][key] == "1"
            ) {
                json[0][key] = true
            }
          }
          layers_data = json[0];
        }

        addNotification({
          text: "Vrstva bola pridaná!",
          position: "top-center",
          removeAfter: 4000,
          type: "success",
        }); 
        
        layers_loaded = false;
        getData("layers");
        return json;
      } else {
        throw new Error(data);
      }
  	}

    // odstránenie vrstvy
    async function remove_layer($id) {
      let data = {id : $id}; 

      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
      
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/removeLayer.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        // prevod hodnoty boolean
        if (
            typeof json[0] === 'object'

            // Object.keys(json).length === 0
        ) {
          const keys = Object.keys(json[0]);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
                (key == "enabled" && json[0][key] == "0")
            ) {
                json[0][key] = false
            }

            if (
                key == "enabled" && json[0][key] == "1"
            ) {
                json[0][key] = true
            }
          }
          layers_data = json[0];
        }

        addNotification({
          text: "Vrstva bola vymazaná!",
          position: "top-center",
          removeAfter: 4000,
          type: "success",
        }); 
        
        layers_loaded = false;
        getData("layers");
        return json;
      } else {
        throw new Error(data);
      }
  	}


</script>
  
  <div id="wrapper">
    <Aside />

    {#if layers_loaded}
    <main in:fade>
      <h1>Vrstvy - Layers</h1>

      <!-- <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button> -->
      <button class="save_settings" name="save_settings" on:click={() => add_layer()}><i class="fa-solid fa-floppy-disk"></i>Pridať vrstvu</button>
  
      {#if layers_data}
      <table>
        <thead>
          <tr>
            <td>Povolené</td>
            <td>{$t("admin")["global"]["name"]}</td>
            <td>Hodnota (Krpano)</td>
            <td>Premenná (Krpano)</td>
            <td>{$t("admin")["global"]["order"]}</td>
            <td>{$t("admin")["global"]["delete"]}</td>
            <td>Aktualizovať</td>
          </tr>
        </thead>
        
        <tbody>
          {#each layers_data as layer}
            <tr>
              <td>  <input type="checkbox" name="enabled" bind:checked="{layer.enabled}"></td>
              <td>   <input type="text" name="Name" bind:value={layer.name}></td>
              <td>  <input type="text" name="krpano_value" bind:value={layer.krpano_value}></td>
              <td>   <input type="text" name="krpano_variable" disabled bind:value={layer.krpano_variable}></td>
              <td>  <input type="number" min=10 name="layer_order" bind:value={layer.layer_order}></td>
              <td> <i on:click={() => remove_layer(layer.id)} class="fa-solid fa-trash delete" /></td>
              <td> <i on:click={() => update_layer(layer.id)} class="fa-solid fa-refresh save" /></td>
            </tr>
          {/each}
        </tbody>
      </table>
      {/if}
    
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";


    .layer-wrapper {
      display: flex;
      width: 80%;
      margin: 0 auto;
      gap: 16px;

      &:hover {
        background-color: lighten($border-color, 9);
        }

      .component {
        width: auto !important;
        &:hover {
          background-color: transparent !important;
        }
        
        label {

          width: auto !important;
        }
      }
    }

  </style>