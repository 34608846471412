<script>
    import { Link } from 'svelte-routing';
    import { token } from '../../stores.js';
    import { fade } from 'svelte/transition';
    import { t, locale, locales } from "../../i18n.js";
    import Aside from '../../layouts/_aside.svelte';
    import Header from '../../layouts/_header.svelte';

    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    export let userdata;

    let loaded_flats, loaded_flat_products, loaded_products = false;
    let flat_products, flats, products = [];

    let flat_keys = null;

    let fetching_data = false;


   // Načítanie dát z DB
   async function getData(value) {
      let data = {id : null};

      let url = null;

      switch (value) {
         case "users":
         case "products":
               url = userdata['base_path'] + "assets/php/getData.php";
               data.id = value;
         break;
      
         default:
               url = userdata['base_path'] + "assets/php/load_flat_products.php";
            break;
      }

      const res = await fetch(
        url,
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (res.ok) {
        const json = await res.json(); 
   
        // prevod hodnoty boolean
        if (typeof json[0] === 'object') {
         json.forEach(element => {
            const keys = Object.keys(element);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              if (
                  (key == "sample_flat_data" && element[key] == "0") 
              ) {
                  element[key] = false
              }

              if (
                  (key == "sample_flat_data" && element[key] == "1")
              ) {
                  element[key] = true
              }
              
               }  
            });
         }

        else {
            console.log("žiadne dáta");
        }

        switch (value) {
         case "users":
            flats = json;
            loaded_flats = true;
         break;

         case "products":
            products = json;
            loaded_products = true;
            console.log("produkty");
            console.log(products);
         break;
        
         default:
            flat_products = json;
            loaded_flat_products = true;
            console.log(flat_products);
            flat_products.forEach(element => {
               flat_keys = Object.keys(element);   
            });

            
            
            
         break;
        }
        
      } else {
        throw new Error(data);
      }
   }

   getData();
   getData("users");
   getData("products");

   async function save(value) {
      console.log(value);
      const res = await fetch(
         userdata['base_path'] + "assets/php/save_flat_products.php",
        {
          method: "POST",
          body: JSON.stringify(value),
        }
      );

      if (res.ok) {
         const json = await res.json(); 
         
         if (json.status == true) {
                addNotification({
                    text: 'Množstvá produktov bytu boli aktualizované.',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'success'
                });

                getData(); 

              } else if (json.status == false) {
                addNotification({
                    text: 'Množstvá produktov bytu neboli aktualizované!',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'danger'
                })
              }
        
      } else {
        throw new Error(data);
      }
   }

    
    async function importFile() {
        final_time = null;
        end_time = null;
        start_time = new Date().getTime();
        fetching_data = true;
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        console.log(formData);
        
        const res = await fetch(userdata['base_path'] + 'assets/php/Import_products_flat.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            // const text = await res.text();
            // console.log(text);

            const json = await res.json();
            console.log(json);
            console.log(JSON.parse(json));
            // let result = JSON.stringify(json);

            if (json.status == true) {
                
                // console.log(json.data);
                addNotification({
                    text: "Produkty boli naimportované!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  
                fetching_data = false;
                end_time = new Date().getTime();
                console.log(end_time);
                final_time = ((end_time - start_time) /1000).toFixed(5);
                getData();
                getData("users");
                getData("products");

            }

            else {
                addNotification({
                    text: "Chyba : Produkty neboli naimportované!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
                fetching_data = false;
            }
        }  
    }
    let deleting = false;
    let start_time, end_time, final_time = null;

</script>
<div id="wrapper">
    <Aside/>
    {#if loaded_flat_products && loaded_flats && loaded_products }
    <main in:fade>
      <h1>{$t("admin")["aside"]["configurator"]["flat_products"]}</h1>

      <div class="container">
        <div class="upload">
          <input id="fileupload" type="file" name="fileupload" />
          {#if fetching_data}
              <button id="upload-button" class="edit "><i class="fa-solid fa-spinner fa-spin"></i>Importujem</button>
          {:else}
              <button id="upload-button" on:click="{() => importFile()}">Importovať</button>
          {/if} 
        </div>
          {#if final_time != null}
              <p>trvanie importu : {final_time} sek.</p>     
          {/if}
        </div>
      

      <div class="table-container">
      <table>
          <thead>
            <tr>
              <th class="fixed2"><i class="fa-solid fa-floppy-disk save" /></th>
              <th class="fixed">{$t("admin")["configurator"]["users"]["flat_id"]}</th>
              <td>{$t("admin")["configurator"]["users"]["sample_flat"]}</td>
              <td>{$t("admin")["global"]["copy"]}</td>
              
               
              {#each flat_keys as item}
                {#each products as product}
                  {#if ("n_" + parseInt(product["id"])) == item}
                      <td>{product["name"]}</td>
                  {/if}
                {/each}
              {/each}
            </tr>
          </thead>
          <tbody>
              {#each flat_products as flat}
              <tr>
               <!-- button uložiť -->
                <td class="fixed2"><i class="fa-solid fa-floppy-disk save" on:click={save(flat)} /></td>

                <!-- názov bytu -->
                {#each flats as item}
                    {#if parseInt(item["ID"]) == parseInt(flat.flat_id)}
                    <td class="fixed"><p class="name">{item.flat_id}</p></td>
                    {/if}
                {/each}
                
                <td><input type="checkbox"></td>
                <td><input type="checkbox"></td>

                {#each products as product}
                      <!-- <td><input  type="number" bind:value={flat["n_" + product.id]}/></td> -->
                      <td><input class="product-count" type="number" min=0 bind:value={flat["n_" + product.id]}/></td>
                   <!-- content here -->
                {/each}
                
               </tr>
              {/each}


                 



         </tbody>

      </div>

  </main>
  {:else}
  <div id="blur"></div>
    {/if}
 
    <Header/>
</div>

<style lang="scss">

    main {
        width: 90% !important;
        padding: 0 !important;
        
        button {
            margin-left: auto;
        }

        .table-container {
            width: 100%;
            height: 80%;
            overflow: auto;
        }
    }



    
</style>