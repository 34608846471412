<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    export let userdata;
    let parameters = {
      base_path : userdata["base_path"],
      cdn_path : userdata["cdn_path"],
      start_project : userdata["start_project"],
      assets_folder : userdata["assets_folder"]
  };

    let flat_filter_loaded = false;
    let flat_filter = [];

        // Načítanie dát z DB
	  async function getData($id) {
      let data = {id : $id} 
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(data));
        
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/getData.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();    
        console.log(json);
        // prevod hodnoty boolean
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key.includes("enable") && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
              (key.includes("show_floorplan") && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
              (key.includes("pdf_flat_compare_export") && json[0][key] == "0")
          ) {
              json[0][key] = false
          }

          if (
              (key.includes("enable") && json[0][key] == "1")
          ) {
              json[0][key] = true
          }

          if (
              (key.includes("show_floorplan") && json[0][key] == "1")
          ) {
              json[0][key] = true
          }

          if (
              (key.includes("pdf_flat_compare_export") && json[0][key] == "1")
          ) {
              json[0][key] = true
          }
        }

        flat_filter = json[0];
        console.log(json);
        
        flat_filter_loaded = true;
        return json;
      } else {
        throw new Error(data);
      }
  	}

    getData("flat_filter");

    async function save_settings() {
      let formData = new FormData();                 
      formData.append("parameters", JSON.stringify(parameters));
      formData.append("data", JSON.stringify(flat_filter));
      
      const res = await fetch(
        userdata['cdn_path'] + "php/admin/updateFlatFilter.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 


</script>
  
  <div id="wrapper">
    <Aside />

    {#if flat_filter_loaded}
    <main in:fade>
      <h1>Filter bytov</h1> 

      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin")["global"]["save_settings"]}</button>

      <div class="component">
        <label>Realpad USERNAME</label>
        <input type="text" name="username" bind:value="{flat_filter.realpad_username}">
      </div>

      <div class="component">
        <label>Realpad PASSWORD</label>
        <input type="text" name="password" bind:value="{flat_filter.realpad_password}">
      </div>

      <div class="component">
        <label>Realpad SCREEN ID</label>
        <input type="text" name="screenid" bind:value="{flat_filter.realpad_screenid}">
      </div>

      <div class="component">
        <label>Realpad DEVELOPER ID</label>
        <input type="text" name="developerid" bind:value="{flat_filter.realpad_developerid}">
      </div>

      <div class="component">
        <label>Realpad PROJECT ID</label>
        <input type="text" name="projectid" bind:value="{flat_filter.realpad_projectid}">
      </div>

      <div class="component">
        <label>Realpad URL</label>
        <input type="text" name="url" bind:value="{flat_filter.realpad_url}">
      </div>

      <!-- Použiť cenový filter ? -->
      <div class="component">
        <label for="url">Povoliť cenu ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_price_filter}">
      </div>  

      <!-- Použiť dostupnostný filter ? -->
      <div class="component">
        <label for="url">Povoliť dostupnosť ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_status_filter}">
      </div>  

      <!-- Použiť dispozičný filter ? -->
      <div class="component">
        <label for="url">Povoliť dispozíciu ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_dispositions_filter}">
      </div>  

      <!-- Použiť filter na poschodie ? -->
      <div class="component">
        <label for="url">Povoliť poschidia ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_floor_filter}">
      </div>  

      <!-- Povoliť porovnanie bytov ? -->
      <div class="component">
        <label for="url">Povoliť porovnanie bytov ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_compare_flats}">
      </div>  

      <!-- Povoliť náhľady pôdorysov bytov ? -->
      <div class="component">
        <label for="url">Povoliť obrázky pôdorysov bytov ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.show_floorplan_thumbnail}">
      </div>  

      <!-- Povoliť náhľady export bytov ? -->
      <div class="component">
        <label for="url">Povoliť export porovnania bytov do PDF ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.pdf_flat_compare_export}">
      </div>  

      <!-- Povoliť vstup do VR bytov ? -->
      <div class="component">
        <label for="url">Povoliť vstup do VR bytu ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_enter_vr_function}">
      </div>  

      <!-- Povoliť import Krpana ? -->
      <div class="component">
        <label for="url">Povoliť import krpana ? (ak existuje apsoň jeden sample flat)</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_krpano_import}">
      </div>  

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Povoliť playground ? (nálet na VR EXT alebo Google StreetView)</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_playground}">
      </div>

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Povoliť textové info o byte ?</label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_area_info}">
      </div> 

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Veľkosť svg - šírka :</label>
        <input type="number" name="filter" bind:value="{flat_filter.svg_w}">
      </div> 

      <div class="component">
        <label for="url">Veľkosť svg - výška :</label>
        <input type="number" name="filter" bind:value="{flat_filter.svg_h}">
      </div> 

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Povoliť Flat filter ? </label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_flat_filter}">
      </div>

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Povoliť Floorplan navigáciu ? </label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_floorplan_nav}">
      </div>

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Povoliť DEMO byty ? </label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_demo_flats}">
      </div>

      <!-- Povoliť playground ? -->
      <div class="component">
        <label for="url">Povoliť detail bytu ? </label>
        <input type="checkbox" name="filter" bind:checked="{flat_filter.enable_flat_detail}">
      </div>

    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>