<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";
  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";
  import { panzoom } from "@douganderson444/panzoom-node";
  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();
  export let userdata;
  let zoomable, container;
  let style = ''; // show styling applied
  let scale = { value: 1 };
  let count = 10;
  let min = count;
  
  let _floorplan_tag = null;
  let loading_data = true;

  let floorplans, floorplans_pins = [];
  let floorplan_tags = [];
  let floorplan_loaded, floorplan_pins_loaded, sample_flats_loaded, sample_flats_scenes_loaded = false;
  let active_floorplan_id = null;
  let sample_flats, sample_flats_scenes = [];

  
  // Načítanie dát z DB
  async function getData($id, $target, $var, $par) {
    loading_data = true;
    let data = {id : $id} 
    const res = await fetch(
      userdata['base_path'] + "assets/php/getData.php",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );

    if (res.ok) {
      const json = await res.json(); 
    
      // prevod hodnoty boolean
      if (
        typeof json[0] === 'object'
        
        
        // Object.keys(json).length === 0
        ) 
      {
        const keys = Object.keys(json[0]);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
              (key == "enabled" && json[0][key] == "0") 
          ) {
              json[0][key] = false
          }

          if (
            (key == "enabled" && json[0][key] == "1")
          ) {
              json[0][key] = true
          }
          
        }

        switch ($var) {
          case "floorplans" :
          floorplans = json;
          floorplan_loaded = true;
          if ($par != true) {
            active_floorplan_id = floorplans[0].id;
          }
          
          getData("floorplan_pins", floorplans_pins, "floorplan_pins");  
          break;

          case "floorplan_pins" : 
          floorplans_pins = json;
          floorplan_pins_loaded = true;
          ////console.log(floorplans_pins);
          break;

          case "sample_flats" : 
          sample_flats = json;
          sample_flats_loaded = true;
          break;

          case "sample_flats_scenes" : 
          sample_flats_scenes = json;
          sample_flats_scenes_loaded = true;

          sample_flats_scenes.forEach(scene => {
            if (!floorplan_tags.includes(scene.floorplan_tag) && !scene.floorplan_tag.includes("apartment_name")) {
                floorplan_tags = [...floorplan_tags, scene.floorplan_tag];
            }
          });
          //console.log(floorplan_tags);

          _floorplan_tag = floorplan_tags[0];
          break;
        }

        loading_data = false;
      }

      else {
        //console.log("žiadne dáta");
      }
      
    } else {
      throw new Error(data);
    }
  }

  getData("floorplans", floorplans, "floorplans");  
  getData("sample_flats", sample_flats, "sample_flats");  
  getData("sample_flats_scenes", sample_flats_scenes, "sample_flats_scenes");  

  async function addFloorplan() {
    active_pin = null;
    
    const res = await fetch(
      userdata["base_path"] + "assets/php/createFloorplan.php",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      
      floorplans = json.floorplans;
      active_floorplan_id = json.floorplan_id;
      //result = JSON.stringify(json);



      return json;
    } else {
      throw new Error(data);
    }
  }

  async function updateFloorplan($id, $par) {
    let updated_floorplan = null;
  
    if ($id == "") {
      addNotification({
        text: "nebol zvolený floorplan.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {

      floorplans.forEach(element => {
        if (element.id == $id) {
          updated_floorplan = element;
        }
      });

      const res = await fetch(
        userdata["base_path"] + "assets/php/updateFloorplan.php",
        {
          method: "POST",
          body: JSON.stringify(updated_floorplan),
        }
      );

      if (res.ok) {
        const json = await res.json();

        if (json.status == true) {
          addNotification({
            text: "Floorplan bol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          if ($par == true) {
            getData("floorplans", floorplans, "floorplans", true);  
          }
        } else {
          addNotification({
            text: "Chyba : Floorplan nebol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        addNotification({
          text: "Chyba : Vzorový byt nebol aktualizovaný!",
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
        throw new Error(data);
      }
    }
  }

  async function updateFloorplanPin($id) {
    let updated_floorplan_pin = null;
  
    if ($id == "") {
      addNotification({
        text: "nebol zvolený pin.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {

      floorplans_pins.forEach(element => {
        if (element.id == $id) {
          updated_floorplan_pin = element;
        }
      });

      const res = await fetch(
        userdata["base_path"] + "assets/php/updateFloorplanPin.php",
        {
          method: "POST",
          body: JSON.stringify(updated_floorplan_pin),
        }
      );

      if (res.ok) {
        const json = await res.json();

        if (json.status == true) {
          addNotification({
            text: "Pin bol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

        } else {
          addNotification({
            text: "Chyba : Pin nebol aktualizovaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        addNotification({
          text: "Chyba : Vzorový byt nebol aktualizovaný!",
          position: "top-center",
          removeAfter: 4000,
          type: "danger",
        });
        throw new Error(data);
      }
    }
  }

  async function removeFloorplan($id) {
    let floorplan_to_delete = {
      id: parseInt($id)
    };
  
    if (floorplan_to_delete == "") {
      addNotification({
        text: "nebol zvolený floorplan.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      const res = await fetch(
        userdata["base_path"] + "assets/php/removeFloorplan.php",
        {
          method: "POST",
          body: JSON.stringify(floorplan_to_delete),
        }
      );

      if (res.ok) {
        const json = await res.json();

        
        

        if (json.status == true) {
          addNotification({
            text: "Floorplan bol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          getData("floorplans", floorplans, "floorplans");  

        } else {
          addNotification({
            text: "Floorplan byt nebol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
        active_floorplan_id = null;
        getData("floorplans", floorplans, "floorplans");  

      } else {
        throw new Error(data);
      }
    }
  }

  async function removeFloorplanPin($id) {
    let floorplan_pin_to_delete = {
      id: parseInt($id)
    };
  
    if (floorplan_pin_to_delete == "") {
      addNotification({
        text: "nebol zvolený pin.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      const res = await fetch(
        userdata["base_path"] + "assets/php/removeFloorplanPin.php",
        {
          method: "POST",
          body: JSON.stringify(floorplan_pin_to_delete),
        }
      );

      if (res.ok) {
        const json = await res.json();

        
        

        if (json.status == true) {
          addNotification({
            text: "Pin bol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

        } else {
          addNotification({
            text: "Pin byt nebol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
       active_pin = null;

      } else {
        throw new Error(data);
      }
    }
  }

  async function loadPins($id, $par) {
    
    let floorplan_tag, sample_flat_name, pins = null;
    let floorplan_scenes = [];
    let sample_flat_id = null;
    

    floorplans.forEach(element => {
      if (element.id == parseInt($id)) { 
        floorplan_tag = element.floorplan_tag;
        sample_flat_id = element.sample_flat_id;
        

        sample_flats.forEach(element => {
          if (parseInt(element.id) == parseInt(sample_flat_id)) {
            sample_flat_name = element.name;
          }
        });
      }
    });

    sample_flats_scenes.forEach(element => {
      
      if (element.enabled == true && 
          element.floorplan_tag == _floorplan_tag &&
          element.sample_flat_id == sample_flat_name
          ) {
          floorplan_scenes.push(element.name);
      }
    });

    //console.log(floorplan_scenes);

    

    if ($par == true) {
      pins = {
        floorplan_id : $id,
        sample_flat_id: sample_flat_name,
        tag : floorplan_tag,
        scenes : floorplan_scenes,
        only_one : true
      };
    } else {
        pins = {
        floorplan_id : $id,
        sample_flat_id: sample_flat_name,
        tag : floorplan_tag,
        scenes : floorplan_scenes,
        only_one : false
      };
    }

    //console.log(pins);

    
  
    if (floorplan_scenes == null) {
      addNotification({
        text: "nebol zvolený floorplan.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      
      const res = await fetch(
        userdata["base_path"] + "assets/php/AddFloorplansPins.php",
        {
          method: "POST",
          body: JSON.stringify(pins),
        }
      );

      if (res.ok) {
        const json = await res.json();

        if (json.status == true) {
          addNotification({
            text: "Piny boli vytvorené!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          getData("floorplan_pins", floorplans_pins, "floorplan_pins");  
          
        } else {
          addNotification({
            text: "Piny neboli vytvorené!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
       

      } else {
        throw new Error(data);
      }
    }
  }

  function handleZoom(e) {
    scale = e.detail.scale;
    style = zoomable.style.transform;
  }

  const grid = Array.from({ length: count }, (_, i) =>
    Array.from({ length: count }, (_, j) => ({ id: i * count + j }))
  );
  
  // upload obrázku floorplanu na server
  async function uploadFile(data) {
    let formData = new FormData();           
    formData.append("file", fileupload.files[0]);

    //console.log(formData);
    
    const res = await fetch(userdata['base_path'] + 'assets/php/saveFloorplan.php', {
    method: "POST", 
    body: formData
    });    

    if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
        addNotification({
                text: "Obrázok bol úspešne uploadnutý!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
            });  

            
            if (json.logo_url) {
              
              floorplans.forEach(element => {
                if (element.id == active_floorplan_id) {
                  element.image = json.logo_url;
                  
                }
              });

              updateFloorplan(active_floorplan_id, true);
              
            }
        }

        else {
        addNotification({
                text: "Chyba : Obrázok nebol úspešne uploadnutý!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
            });  
        }
    }  
  }

  let elemt;

  function dragElement(elmnt) {
    
    
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      var cusid_ele = document.getElementsByClassName('pin');
      for (var i = 0; i < cusid_ele.length; ++i) {
          var item = cusid_ele[i];  
          item.classList.remove("active");
      }




      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
      
    }

    function elementDrag(e) {
      var cusid_ele = document.getElementsByClassName('pin');
      for (var i = 0; i < cusid_ele.length; ++i) {
        
          var item = cusid_ele[i];  
          if (i != active_pin ) {
            item.classList.remove("active");
          }
          
      }

      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";

      elmnt.classList.add("active");
      
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
      save_pins();
    }

    

    
    
  }

  function save_pins() {
    let pins = floorplans_pins;
    let pins_length = pins.length;
    for (let i = 0; i < pins_length; i++) {
      let current_pin = document.getElementById("pin_" + pins[i].id);
      if (
        current_pin != undefined &&
        current_pin != null
      ) {
        pins[i].x = current_pin.style.left.replace('px', '');
        pins[i].y = current_pin.style.top.replace('px', '');
      }
      
    }        
  }
    
  let active_pin = null;
  let showing = 0;

  function select_active_pin () {
    let pins = floorplans_pins;
    let pins_length = pins.length;
    for (let i = 0; i < pins_length; i++) {
      if (
        pins[i].floorplan_id == active_floorplan_id
      ) {
        let current_pin = document.getElementById("pin_" + pins[i].id);
        if (pins[i].id == active_pin) {
          current_pin.classList.add("active");
        } else {
          current_pin.classList.remove("active");
        }  
      }
        
    }     
  }

</script>

<div id="wrapper">
  <Aside />

  {#if loading_data == true}
    načítavam dáta
  {/if}
  
  {#if loading_data == false}
  
  <main in:fade>
    
    <h1>{$t("components")['floorplan']['admin-title']}</h1>

    <!-- tlačidlá -->
    <div class="fp-buttons">
      <button class="save_settings" name="save_settings" on:click={() => updateFloorplan(active_floorplan_id)}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button> 
      
        <button class="add-fp" name="add_floorplan" on:click={() => addFloorplan()}>
          <i class="fa-solid fa-plus"></i>{$t("components")['floorplan']['add-floorplan']}
        </button>    
        <button class="remove-fp" name="remove_floorplan" on:click={() => removeFloorplan(active_floorplan_id)}>
          <i class="fa-solid fa-trash-can"></i>{$t("components")['floorplan']['remove-floorplan']}
        </button>    
      
      <!-- pridať pin -->
        <button class="add-pin" name="add_pin" on:click={() => loadPins(active_floorplan_id, true)}>
          <i class="fa-solid fa-plus"></i>{$t("components")['floorplan']['add-pin']}
        </button>    

        <!-- načítať piny -->
        <button class="add-pin" name="add_pin" on:click={() => loadPins(active_floorplan_id, false)}>
          <i class="fa-solid fa-plus"></i>Načítať piny
        </button>    
    </div>
    
      <div id="floorplan-container">
        <div class="floorplan-image container" bind:this={container}>
          <div class="zoomable flexbox" use:panzoom on:zoomed={handleZoom}>
            {#if active_floorplan_id != null}
              {#each floorplans as floorplan}
                 {#if parseInt(floorplan.id) == parseInt(active_floorplan_id)}
                  <img src="{userdata.base_path}assets/floorplans/{_floorplan_tag}.png" class="img" alt="{floorplan.image}" name="floorplan imgage">
                 {/if}
              {/each}                
            {/if}                

            {#if showing !== 2 && active_floorplan_id != null && floorplans_pins.length > 0 }
            {#each floorplans_pins as pin, i}
              {#each sample_flats_scenes as scene, i}
                {#if pin.target == scene.name && scene.floorplan_tag == _floorplan_tag}
                  <div id="pin_{pin.id}" class="pin" class:active="{active_pin === pin.id}" style="left: {pin.x}px; top: {pin.y}px;" 
                    on:mouseenter="{() => dragElement(document.getElementById("pin_" + pin.id))}"
                    on:click="{() => active_pin = pin.id}"
                    on:click="{() => showing = 1}"
                    on:click="{() => select_active_pin()}">
                    {pin.number}
                  </div>   
                {/if}
              
              {/each}
            {/each}
            {/if}
            
            
          </div>
        </div>
        
        <div class="floorplan-data">
          {#if active_floorplan_id != null && active_pin == null && sample_flats_scenes_loaded} 

            <!-- výber floorplanu -->
            <div class="component">
              <label for="floorplan-id">{$t("components")['floorplan']['floorplan-id']}</label>
              <select name="floorplan-id" id="floorplan-id" bind:value="{active_floorplan_id}">
                {#each floorplans as floorplan}
                  <option value="{floorplan.id}">{floorplan.name}</option>
                {/each}
              </select>
            </div>

            <!-- názov aktívneho floorplanu -->
            <div class="component">
              {#each floorplans as floorplan}
                {#if floorplan.id == active_floorplan_id}
                  <label for="floorplan-id">Názov floorplanu</label>
                  <input type="text" bind:value={floorplan.name} />
                {/if}
              {/each}
            </div>

            <!-- výber sample bytu  -->
            {#if sample_flats_loaded == true}
              <div class="component">
                {#each floorplans as floorplan}
                  {#if floorplan.id == active_floorplan_id}
                    <label for="floorplan-id">Sample flat</label>
                    <select name="floorplan-id" id="floorplan-id" bind:value="{floorplan.sample_flat_id}">
                      {#each sample_flats as flat}
                        <option value="{flat.id}">{flat.name}</option>
                      {/each}
                    </select>
                  {/if}
                {/each}
              </div>
            {/if}

            <!-- Voľba floorplan tagu -->
            <div class="component">
                {#each floorplans as floorplan}
                  {#if floorplan.id == active_floorplan_id}
                    <label for="floorplan-id">Tag scén</label>
                    <input type="text" bind:value={floorplan.floorplan_tag} />
                  {/if}
                {/each}
            </div>

            <!-- Filter tagov -->
            <div class="component">
              <label for="floorplan-id">Filter tagov</label>
              <select name="floorplan-id" id="floorplan-id" bind:value="{_floorplan_tag}">
                {#each floorplan_tags as tag}
                  <option value="{tag}">{tag}</option>
                {/each}
              </select>
          </div>
          
            <!-- Url pre obrázok floorplanu -->
            <div class="component">
              {#each floorplans as floorplan}
                {#if floorplan.id == active_floorplan_id}
                  <label for="url_img" >URL obrázka</label>
                  <input id="url_img" type="text" bind:value={floorplan.image} />
                {/if}
              {/each}
            </div>

            <!-- Upload obrázku pre floorplan -->
            <div class="upload">
              <label for="pins-title">{$t("components")['floorplan']['upload-image']}</label>  
              <input id="fileupload" type="file" name="fileupload" />
              <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
            </div>

          {/if}  

          {#if floorplans_pins.length > 0 && sample_flats_scenes_loaded == true && active_pin != null}
            {#each floorplans_pins as pin}
              {#if pin.id === active_pin}
              <button class="pin-back" name="back" on:click={() => active_pin = null}><i class="fa-solid fa-arrow-left"></i>{$t("back")}</button>    
              <button class="pin_save" name="save_pin" on:click={() => updateFloorplanPin(pin.id)} ><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button> 
              <button class="pin_remove" name="remove" on:click={() => removeFloorplanPin(pin.id)} ><i class="fa-solid fa-trash-can"></i>{$t("delete")}</button>    
            
            
              <!-- Pozícia pinu na osi X -->
              <div class="component">
                 <label for="pinx">{$t("components")['floorplan']['pin-x']}:</label>
                 <input type="number" name="pinx" bind:value="{pin.x}">
              </div>  
              <!-- Pozícia pinu na osi Y -->
              <div class="component">
                <label for="piny">{$t("components")['floorplan']['pin-y']}:</label>
                <input type="number" name="piny" bind:value="{pin.y}">
              </div>

               <!-- číslo pinu -->
               <div class="component">
                <label for="number">Číslo:</label>
                <input type="number" name="number" bind:value="{pin.number}">
              </div>

              <!-- Názov pinu -->
              <div class="component">
                <label for="title">{$t("components")['floorplan']['pin-title']}:</label>
                <input type="text" name="text" bind:value="{pin.title}">
              </div>

              <!-- odkaz pinu na panorámu -->
              <div class="component">
                <label for="pin-target">{$t("components")['floorplan']['pin-target']}:</label>
                <select name="pin-target" bind:value="{pin.target}">
                  {#each sample_flats_scenes as scene}
                      {#if scene.sample_flat_id == pin.sample_flat_id}
                        <option value="{scene.name}">{scene.name}</option>
                      {/if}
                      
                  {/each}
                </select>
              </div>
              {/if}
            {/each}
              
            

            
            
          {/if}

           
          
        </div>  
        
      </div>
    

    
    

  </main>
  {/if}
  <Header />
  
</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";
  @import "../../assets/scss/fonts.scss";

  .pin {
    position: absolute;
    bottom: 1px;
    left: 1px;
    z-index: 999999999;
    cursor: all-scroll;
    display: flex;
    justify-content: center;
    align-items: center;


    @include rubik-m10;
    color: var(--secondary-color);
    width: 22px;
    height: 22px;
    background-color: var(--primary-color);
    border-radius: 50%;
    border: 2px solid var(--secondary-color);
    box-shadow: 0 0 6px 1px var(--primary-color-light60);
    transition: box-shadow 0.25s ease;

    &.active {
      background-color: var(--main-color);
      box-shadow: 0 0 6px 1px var(--main-color-light60),
      0 0 0 8px var(--main-color-light20);
      -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;

        @-webkit-keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 var(--main-color);
          }
        
          70% {
            box-shadow: 0 0 0 10px transparent;
          }
        
          100% {
            box-shadow: 0 0 0 0 transparent;
          }
        }


        &::before {
          content: '';
          position: absolute;
          background: transparent;
          width: 12PX;
          height: 0PX;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 40px solid transparent;
          border-top: 40px solid var(--main-color-light20);
          border-radius: 4px;
          transform: rotate(0deg);
          z-index: -1;
  }
    }

    &:hover:not(.active) {
    box-shadow: 0 0 6px 1px var(--primary-color-light60),
      0 0 0 8px var(--primary-color-light20);
    }

    
  }

  .fp-buttons{
    display: flex;
    justify-content: center;
    button {
      margin: 10px 5px;
      &.add-fp {
        background-color: $info;
      }
      &.remove-fp {
        background-color: $danger;
      }
      &.add-pin {
        background-color: $info;
      }
    }
  }

  #floorplan-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    min-height: 500px;


    .floorplan-image {
      width: 1000px;
      height: 1000px;
      position: relative;
      /* touch-action: none;  its set by the directive :) */

      img, .img {
        width: 100%;
      }

      .zoomable {
        border: 4px dashed blue;
        height: 1000px !important;
        width: 1000px !important;
        position: relative;
      }
      .flexbox {
        display: flex;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: space-evenly;
        align-items: stretch;
      }

      
    }

    .floorplan-data {
      width: 420px;
        button {
          &.pin_remove {
            background-color: $danger;
          }
          &.pin-back {
            background-color: $primary-color;
          }
          &.new-fp-back {
            background-color: $primary-color;
          }
        
        }
        .upload {
          label{
            margin-right: 38px;
            width: 100px;
          }
        }
    }

  }
  
</style>
