<script>
  import {get, writable} from 'svelte/store';
  import { active_scene } from "../store.js";
  import { t, locale, locales } from "../i18n.js";
  import { blur, vr_mode, active_floor, dizajn } from "../store.js";
  import { fade, scale } from "svelte/transition";

  
  export let userdata, floorplan_settings, floorplan_pins, sample_flat_scenes, current_scene, sample_flat;
  ////console.log(sample_flat);

  let current_floorplan, krpano, _vrMode, _active_floor, _dizajn, loading_data;
  let suffix_dizajn = "";
  let sample_flat_id, sample_flat_name, current_floorplan_tag, current_floorplan_id, floorplan_image = null;

  floorplan_image = userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "floorplans/" + sample_flat.name + suffix_dizajn + ".png";
  
  dizajn.subscribe(value => {
      _dizajn = value;
      //console.log(value);

      if (value != "") {
        suffix_dizajn = "_" + value;
      } else {
        suffix_dizajn = "";
      }

      floorplan_image = userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "floorplans/" + sample_flat.name + suffix_dizajn + ".png";
  });
  vr_mode.subscribe(value => {
      _vrMode = value;
  });


  $: {
    floorplan_image;

    //////console.log(floorplan_image);
  }

  active_floor.subscribe(value => {
      _active_floor = value;
      //////////console.log(value);
      var krpano = document.getElementById('krpanoSWFObject');

      sample_flat_scenes.forEach(scene => {
        if (scene.poschodie == value) {
          // if (scene.enable_design) {
          //   suffix_dizajn = "_" + krpano.get("dizajn");
          // } else {
          //   suffix_dizajn = "";
          // }
          floorplan_image = userdata["cdn_path"] + "assets/" + userdata["assets_folder"] + "floorplans/" + sample_flat.name + suffix_dizajn + ".png";
         // ////////console.log(floorplan_image);
          return;
        }
      });
  })

  let floors = [];
  sample_flat_scenes.forEach(scene => {
    let floor;
    switch (scene.floorplan_tag) {
      case "FLUGHAFEN_1786":     
        floor = 3;
      break;

      case "FLUGHAFEN_165":        
        floor = 0;
      break;

      case "FLUGHAFEN_1282":        
        floor = 2;
      break;

      case "FLUGHAFEN_772":        
        floor = 1;
      break;

      case "FLUGHAFEN_972":        
        floor = 3;
      break;

      case "FLUGHAFEN_956":        
        floor = 0;
      break;

      case "FLUGHAFEN_967":        
        floor = 2;
      break;

      case "FLUGHAFEN_962":        
        floor = 1;
      break;

      case "FLUGHAFEN_12":        
        floor = "E2";
      break;

      case "FLUGHAFEN_7":        
        floor = "E1";
      break;

      case "FLUGHAFEN_1":        
        floor = "E0";
      break;

      case "FLUGHAFEN_17":        
        floor = "E3";
      break;

      case "FLUGHAFEN_15":        
        floor = "none";
      break;

      case "FLUGHAFEN_15":        
        floor = "none";
      break;

      case "BYTE_446":        
        floor = "1";
      break;

      case "BYTE_185":        
        floor = "0";
      break;

      case "BYTE_324":        
        floor = "0";
      break;

      default:
        floor = 0;
      break;
    }
    scene.poschodie = floor;   
    
    if (!floors.includes(floor)) {
      floors = [...floors, floor];
    }
  });
  floors.sort();
  //////////console.log(sample_flat_scenes);
  //////////console.log(floors);

  floorplan_pins.sort((a,b)=>a.number-b.number);
  // _floorplans = get(floorplans);
  // _floorplan_pins = get(floorplan_pins);

  let floorplan_loaded, floorplan_pins_loaded, sample_flats_loaded, sample_flats_scenes_loaded = false;
  let active_floorplan_id = null;
  let sample_flats, sample_flats_scenes = [];

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  function center_floorplan($left, $top, $target, $par) {
      
      let halfX = document.getElementsByClassName("floorplan-image")[0].clientWidth / 2;
      let halfY = document.getElementsByClassName("floorplan-image")[0].clientHeight / 2;

      let moveX = halfX - $left;
      let moveY = halfY - $top;

      if (
        current_scene.toLowerCase() != $target.toLowerCase()
      ) {
       
        if (krpano == undefined) {
          krpano = document.getElementById("krpanoSWFObject");
        }
        krpano.call("loadscene(" + $target + ", null, MERGE, BLEND(1))");
        moveto(moveX, moveY);

      }

      else {
        //////////////console.log('sgagh');
        moveto(moveX, moveY);
      }
  }

  
  // Načítanie správenho floorplanu popdľa TAGU aktuálnej scény

  let _floorplan_tag = null;
  active_scene.subscribe((value) => {
    //////////console.log(value);
    var krpano = document.getElementById('krpanoSWFObject');
    if (sample_flat_scenes != undefined) {
      sample_flat_scenes.forEach(scene => {
        if (scene.name == value) {
          _floorplan_tag = scene.floorplan_tag;
          // if (scene.enable_design) {
          //   suffix_dizajn = "_" + krpano.get("dizajn");
          // } else {
          //   suffix_dizajn = "";
          // }
          
          active_floor.update(n => scene.poschodie);
        }
        
      });


      //////////console.log(_floorplan_tag);
      // floorplan_image = userdata["cdn_path"] + "assets/floorplans/" + _floorplan_tag + suffix_dizajn + ".png";
    }

    //suffix_dizajn = "_" + krpano.get("dizajn");
   
    current_scene = value.toLowerCase();

    // for (let index = 0; index < settings.scenes.length; index++) {
    //   if (settings.scenes[index].id.toLowerCase() == value.toLowerCase()) {
    //     for (let i = 0; i < settings.components[9].data.length; i++) {
    //       for (let y = 0; y < settings.scenes[index].tags.length; y++) {
    //         if (
    //           settings.components[9].data[i].filter_by_value ==
    //           settings.scenes[index].tags[y]
    //         ) {
    //           //////////////console.log('aktuálny floorlan : ' +  settings.components[9].data[i].title);
    //           current_floorplan = i;

    //           return;
    //         }
    //       }
    //     }
    //   } else {
    //     //////////////console.log('nenašiel som');
    //   }
    // }

    //////////////console.log(current_floorplan);
  });

  let floorplan_enabled;
  let floorplan_size = "small";

  function init_floorplan_center() {
    //////////////console.log(current_scene);
    
    for ( let index = 0; index < floorplan_pins.length; index++
    ) {
      if ( floorplan_pins[index].target.toLowerCase() == current_scene.toLowerCase()
      ) {
        
        let current_pin = floorplan_pins[index];

        center_floorplan(current_pin.x, current_pin.y, current_scene, true);
      }
    }
  }

  $: {
    floorplan_size;

    switch (floorplan_size) {
      case "big":
        blur.update((n) => true);
        
        //////////////console.log('otváram');
        break;

      default:
        blur.update((n) => false);
        break;
    }
  }

  import { show_floorplan } from "../store.js";
  import Panzoom from "@panzoom/panzoom";

  let zoomable, container;
  let style = ""; // show styling applied
  let _scale = { value: 1 };
  let count = 10;
  let min = count;

  show_floorplan.subscribe((value) => {
    floorplan_enabled = value;

    if (value == true) {
      //////////////console.log('otváram');

      waitForElm("#my-zoomist").then((elm) => {
        const elem = document.getElementById("my-zoomist");
        const panzoom = Panzoom(elem, {
          maxScale: 5,
        });

        panzoom.zoom(1), { animate: true };
        panzoom.pan(0, 0), { animate: true };
        // panzoom.pan(10, 10)
        // panzoom.zoom(2, { animate: true });

        // elem.addEventListener('panzoomchange', (event) => {
        //   ////////////console.log(event.detail) // => { x: 0, y: 0, scale: 1 }
        // })

        elem.parentElement.addEventListener("wheel", panzoom.zoomWithWheel);
        moveto = function ($x, $y) {
          
          panzoom.zoom(1, { animate: true });
          panzoom.pan($x, $y, { animate: true });
        };

        setTimeout(() => init_floorplan_center(), 10);
        
      });
    }
  });

  let moveto;

  

  const animate = (node, args) =>
    args.cond ? fade(node, args) : scale(node, args);

  let active_pin = -1;
  let showing = 0;

  let new_floorplan = {
    id: 999,
    title: "",
    image: "assets/img/floorplan.svg",
    filter_by: "none",
    filter_by_value: null,
    pins_title: "order",
    pins: [{}],
  };

  function go_to_scene($panoName) {
    var krpano = document.getElementById("krpanoSWFObject");
    krpano.call("loadscene(" + $panoName + ", null, MERGE, BLEND(1))");
    //center_floorplan();
  }

  ////////console.log(floorplan_pins);
</script>
<div id="woow-floorplan-new">
<!-- {#if floorplan_enabled && floorplan_size == "small1"}
  <div id="floorplan-small" transition:animate>
    <div class="floorplan-image container" bind:this={container}>
      <div
        class="zoomable flexbox"
        bind:this={zoomable}
        use:panzoom
        on:zoomed={handleZoom}
      >
        {#if showing === 2}
          <img
            src={userdata["base_path"] + new_floorplan.image}
            class="img"
            alt="logo"
            name="logo"
          />
        {:else}
          <img
            src={userdata["base_path"] +
              settings.components[9].data[current_floorplan].image}
            class="img"
            alt="logo"
            name="logo"
          />
        {/if}
        {#each _floorplan_pins as pin, i}
          <div
            id="pin_{pin.id}"
            class="pin floorplan-hotspot"
            class:active={current_scene.toLowerCase() ===
              pin.target.toLowerCase()}
            style="left: {pin.x}px; top: {pin.y}px;"
            on:click={() => go_to_scene(pin.target)}
          >
            {#if pin.target != null}
              {#each settings.scenes as scene, i}
                {#if scene.id === pin.target}
                  {pin.title}
                {/if}
              {/each}
            {:else}
              ?
            {/if}
          </div>
        {/each}
      </div>

      <div class="resize" on:click={() => (floorplan_size = "big")} />
    </div>

    <div class="title">{$t("components")["floorplan"]["sidebar-title"]}</div>
    <div class="close" on:click={() => show_floorplan.update((n) => !n)} />
  </div>
{/if} -->

{#if floorplan_enabled && floorplan_size == "small" && _vrMode == false}
  <div id="floorplan-blur" transition:fade/>
  <div id="floorplan-big" transition:animate>
    <div
      class="close"
      on:click={() => show_floorplan.update((n) => !n)}
      on:click={() => (floorplan_size = "small")}
      on:click={() => blur.update((n) => false)}
    />
    <div class="title">{$t("components")["floorplan"]["sidebar-title"]}</div>
    <div class="content">
      <div class="floorplan-list">
        <ul data-id={current_floorplan}>
          {#each floorplan_pins as pin, i}
            {#each sample_flat_scenes as scene, i}
              {#if scene.name == pin.target && scene.poschodie == _active_floor && scene.poschodie != "none"}
                <li class="fp-item" class:active={current_scene.toLowerCase() === pin.target.toLowerCase()} id="pins_{pin.id}" data-id={pin.target} on:click={() => center_floorplan(pin.x, pin.y, pin.target, true)}>
                  {#if pin.target != null}
                    <span>{pin.number}</span>
                  {:else}
                    ?
                  {/if}
                  {pin.title}
                </li>
              {/if}
            {/each}
          {/each}
        </ul>
      </div>
      <div class="floorplan-image container" bind:this={container}>
        <div id="my-zoomist" class="zoomable flexbox">
          
            <img
              id="my-zoomist1"
              data-zoomist-src={floorplan_image}
              src={floorplan_image}
              class="img"
              alt="logo"
              name="logo"
            />
          
          {#each floorplan_pins as pin, i}
            {#each sample_flat_scenes as scene, i}
              {#if scene.name == pin.target && scene.poschodie == _active_floor}
                <div
                  id="pin_{pin.id}"
                  class="pin floorplan-hotspot"
                  class:active={current_scene.toLowerCase() === pin.target.toLowerCase()}
                  style="left: {pin.x}px; top: {pin.y}px;"
                  on:click={() => center_floorplan(pin.x, pin.y, pin.target, true)}>
                  {#if pin.target != null}
                    {pin.number}
                  {:else}
                    ?
                  {/if}
                </div>
              {/if}
            {/each}
          {/each}
        </div>
      </div>
      {#if floors.length > 1}
        <div class="select-floor">
          {#each floors as floor}
            {#if floor != "none"}
              <div class:active={_active_floor == floor} on:click={() => active_floor.update(n => floor)}>{floor}</div>
            {/if}
            
          {/each}
        </div>
      {/if}
      
    </div>
  </div>
{/if}

</div>

<style lang="scss">
  @import "../assets/scss/themes.scss";
  @import "../assets/scss/fonts.scss";
  @import "../assets/scss/mixins.scss";
  @import "../assets/scss/variables.scss";


  #woow-floorplan-new {
  #floorplan-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 63, 70, 0.2);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 1;
  }

  #floorplan-small {
    width: 320px;
    height: 224px;
    position: absolute;
    top: 72px;
    left: 16px;
    @include blur;
    border-radius: $radius-M;

    .close {
      cursor: pointer;
    }

    .floorplan-image {
      position: relative;
      width: 304px;
      height: 168px;
      border: 1px solid var(--primary-color-light20);
      border-radius: $radius-S;
      margin: 8px 8px 0 8px;

      .flexbox {
        .draggable {
          img {
            width: 310px;
          }
        }
      }

      .floorplan-hotspot {
        margin: 20px;
        z-index: 100;
      }
    }

    .title {
      @include rubik-r16;
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pin {
    position: absolute;
    bottom: 1px;
    left: 1px;
    z-index: 999999999;
    cursor: all-scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include rubik-m10;
    color: var(--secondary-color);
    width: 22px;
    height: 22px;
    background-color: var(--primary-color);
    border-radius: 50%;
    border: 2px solid var(--secondary-color);
    box-shadow: 0 0 6px 1px var(--primary-color-light60);
    transition: box-shadow 0.25s ease;

    &.active {
      background-color: var(--main-color);
      box-shadow: 0 0 6px 1px var(--main-color-light60),
        0 0 0 8px var(--main-color-light20);
      -webkit-animation: pulse 2s infinite;
      animation: pulse 2s infinite;

      @-webkit-keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 var(--main-color);
        }

        70% {
          box-shadow: 0 0 0 10px transparent;
        }

        100% {
          box-shadow: 0 0 0 0 transparent;
        }
      }

      &::before {
        // content: '';
        // position: absolute;
        // background: transparent;
        // width: 12PX;
        // height: 0PX;
        // border-left: 20px solid transparent;
        // border-right: 20px solid transparent;
        // border-bottom: 40px solid transparent;
        // border-top: 40px solid var(--main-color-light20);
        // border-radius: 4px;
        // transform: rotate(0deg);
        // z-index: -1;
      }
    }

    &:hover:not(.active) {
      box-shadow: 0 0 6px 1px var(--primary-color-light60),
        0 0 0 8px var(--primary-color-light20);
    }
  }

  #floorplan-big {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    max-width: 960px;
    max-height: 640px;
    padding: 16px;
    @include blur;
    border-radius: $radius-M;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 10;

    .title {
      @include rubik-m20;
      padding: 0 32px;
      margin-bottom: 8px;
      text-align: center;
    }

    .content {
      display: flex;
      width: 100%;
      height: inherit;
      // align-items: stretch;
      .floorplan-list {
        min-width: 304px;
        height: 100%;
        margin-left: 8px;
        padding: 0 24px 0 1px;
        overflow: auto;

        ul {
          padding-left: 0px;

          .fp-item {
            cursor: pointer;
            display: flex;
            @include rubik-r14;
            padding: 13.5px 8px 12px;
            margin-top: 8px;
            span {
              content: "";
              @include rubik-m10;
              color: var(--secondary-color);
              width: 20px;
              height: 20px;
              background-color: var(--primary-color);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 2px solid var(--secondary-color);
              box-shadow: 0 0 6px 1px var(--primary-color-light60);
              cursor: pointer;
              transition: 0.25s;
              margin-right: 16px;
            }
            &.active {
              box-shadow: 0 0 0 1px var(--main-color-light20);
              border-radius: $radius-S;
              background-color: var(--main-color-light005);
              color: var(--main-color);
              span {
                background-color: var(--main-color);
                box-shadow: 0 0 6px 1px var(--main-color-light60);
              }
            }
          }
        }
      }

      .floorplan-image {
        position: relative;
        width: 66%;
        height: 100%;
        overflow: hidden;
        border-radius: $radius-S;
        background-color: var(--primary-color-light005);
        border: 1px solid var(--primary-color-light20);

        .flexbox {
          .draggable {
            img {
              width: 800px;
            }
          }
        }
        // // align-self: stretch;
      }
    }
  }

    .select-floor{
      position: absolute;
      top: 50%;
      right: 29px;
      transform: translateY(-50%);
      background-color: var(--secondary-color);
      width: 40px;
      border-radius: 6px;
      // height: calc(100% - 32px);
      font-size: 14px;
      line-height: 21px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      z-index: 9;
      padding: 14px 4px;

      
      div {
            margin-top: 16px;
            border-radius: 6px;
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;

            &.active {
                color: var(--main-color);
                background-color: var(--main-color-light005);
            }
    }
  }

  //  ----------------SCREEN-------------------------------------------------------------------------------------------------------

  @media (max-width: 630px) {
    #floorplan-big {
      max-height: none;
      .content {
        flex-direction: column;
        .floorplan-list {
          order: 5;
          margin-top: 20px;
          min-width: auto;
          padding: 0 1px;
          margin-left: 0;
        }
        .floorplan-image {
          order: 1;
          width: 100%;
          min-height: 360px;
        }
      }
    }
    .select-floor {
      top: 60px;
      transform: translateY(0%);
    }
  }


  //  ----------------Hover-----------------------------------------------------------------------------------------------

  @media (hover: hover) and (pointer: fine) {
    #floorplan-big {
      .content {
        .floorplan-list {
          .fp-item {
            &:hover {
              box-shadow: 0 0 0 1px var(--primary-color-light20);
              border-radius: $radius-S;
              background-color: var(--primary-color-light005);
            }
          }
        }
      }
    }
  }
}
  </style>
