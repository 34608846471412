<script>
  import { formatter } from '../utils.js';
  import { fly } from 'svelte/transition';
  import { t, locale, locales } from "../i18n.js";
  import {get, writable} from 'svelte/store';
  // import {flat_id, shortcuts, attributes, active_product_id, active_scene, configuration, config_products, config_product_variants, config_product_standards, blur, price_list } from '../store.js';
  import {flat_id, shortcuts, attributes, active_product_id, active_scene, configuration, config_products, config_product_variants, config_product_standards, blur, sidebar, active_product_id_model } from '../store.js';
  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();
  import {modal_type, modal, vr_mode, dizajn, tmp_attribute_from_model, new_model} from "../store.js";
  import { fade, scale } from 'svelte/transition';
  import {mobile_patch_suffix} from '../store.js';
  import { Lightbox } from 'svelte-lightbox';

  let _mobile_patch_suffix = "";

  let patch_preloader = false;
  let model_enabled = false;
  new_model.subscribe(value => {
      model_enabled = value; 
  });

  $: {
    patch_preloader;

    //////////console.log("preloader : " + patch_preloader);
  }

  mobile_patch_suffix.subscribe(value => {
      _mobile_patch_suffix = value;
      //////////console.log(value);
  });
  
  let _current_scene, _dizajn;
    active_scene.subscribe(value => {
        _current_scene = value;
        attributes.update( n=> false);
    });

    dizajn.subscribe(value => {
        _dizajn = value;
       // ////////////console.log("dizajn = " + value);
    });
  export let userdata;
  export let products, product_variants, product_categories, product_mj, price_list, attributes_settings;

  let _flat_id, product_id, active_variant, active_standard = null;
  let loading_data = false;

  let krpano = document.getElementById("krpanoSWFObject");

  let _attributes = false;
  attributes.subscribe(value => {    
    _attributes = value;
    switch (value) {
      case true:
        sidebar.update( n=> false);
        break;
    
      default:
        active_product_id.update(n => -1);
        break;
    }
  });

  let _vrMode;

  vr_mode.subscribe(value => {
    _vrMode = value;
    let krpano = document.getElementById("krpanoSWFObject");
        
    switch (value) {
      case true:
          // krpano.call("webvr.enterVR();");
          // krpano.call("addhotspot(hts_vt_test);");
          // krpano.call("set(hotspot[hts_vt_test].keep, false);");
          // krpano.call("set(hotspot[hts_vt_test].ath, 0);");
          // krpano.call("set(hotspot[hts_vt_test].atv, 0);");
          
          // krpano.call("set(hotspot[hts_vt_test].distorted, false);");
          
          
          // krpano.call("set(hotspot[hts_vt_test].zorder, 2);");
          // krpano.call("set(hotspot[hts_vt_test].width, 100px);");
          // krpano.call("set(hotspot[hts_vt_test].height, 100px);");
          // krpano.call("set(hotspot[hts_vt_test].type, image);");
          // krpano.call("set(hotspot[hts_vt_test].url, assets/krpano/icons/hts_infopanel.svg);");

          // krpano.set("hotspot[hts_vt_test].onclick", function(hs) {
          //   product_id = 78;
          //   select_active_variant(182);
          // });

        break;
    
      default:
        krpano.call("webvr.exitVR();");
        break;
    }
    
  });
  
  let _configuration, _config_products, _config_product_variants = [];

  let config_products_loaded = false;
  let config_product_variants_loaded = false;
  let configuration_loaded = false;

  // načítanie konfigurácie produktov 
  configuration.subscribe(value => {
    if (value != undefined) {
      _configuration = value;  
      configuration_loaded = true;
    }    
  });


  let _product_standards = [];

  let blur_enabled = false;
    blur.subscribe(value => {    
        blur_enabled = value;
    });

  let _price_list = [];

  flat_id.subscribe((value) => {
    _flat_id = value;
    // getData(_flat_id);
  });

  let products_loaded = false;
  // let products, products_variants, product_standards = [];
  let active_all_walls = null;
  let active_first_walls, active_second_walls = null;

  

  let walls_type = null;
  let walls_order = "first";
  let walls_message_first, walls_message_second = null;
  

  // zmena aktívneho produktu po kliku na hotspot
  active_product_id.subscribe((value) => {
    //////////console.log(value);
		product_id = value;

    if (value != -1) {
      active_product_id_model.update( n => value);
    }
    
    
    
    // if (config_product_variants_loaded == true && configuration_loaded == true) {
      if (products != null && _configuration != undefined) {
      get_standards();
      //get_active_item();
    }

    shortcuts.update(n => false);
    
    
	});

  // ak sa načítajú varianty produktu
  $: {
    config_product_variants_loaded;

    switch (config_product_variants_loaded) {
      case true:
        get_standards();
        break;
    
      default:
        break;
    }
  }

  // ak sa zmení aktívny variant
  $: {
    active_variant;
    update_price(active_variant);
  }

  

  // Zistenie štandardov produktu
  function get_standards() {
    _product_standards = [];
    active_standard = null;

    // zistenie či má produkt štandardy
    product_variants.forEach(element => {
      if ( element.type != "single" && parseInt(element.product_id) == parseInt(product_id)) {

        // zapísanie štandardov do poľa - jedinečné
        if( _product_standards.indexOf(element.type) === -1) {
          _product_standards.push(element.type);
          if (active_standard == null) {
            active_standard = element.type;
          }
        }

        
      }
    });

    // načítanie aktívneho štandardu produktu z konfigurácie

    if (_product_standards.length > 0) {
      _configuration.forEach(element => {
        if (parseInt(element.product_id) === parseInt(product_id)) {
          if (element.active_product_standard != null) {
            active_standard = element.active_product_standard;
          }
        }
      });
      
    }
  

    //////////////console.log(_product_standards);
    get_active_item();
    
  }

  // Zistenie aktívneho produktu
  function get_active_item() {
   // ////////console.log(product_id);
    //////////console.log("idem nájsť aktívny variant produktu");

    if (krpano.get("xml.scene") != null) {
      let product, variant_id = null;
      let current_scene = krpano.get("xml.scene").toUpperCase();
      products.forEach(element => {
        if (element.id == product_id) {
          product = element.patch_id;
        }
      });
      active_variant = null;

      if (_configuration != null) {
        _configuration.forEach(element => {
          if ( (element.active_product_variant != null) && (parseInt(element.product_id) == parseInt(product_id)) 
              ){ 
              //  product = element.patch_id
              product_variants.forEach(item => {
                
              if (parseInt(element.active_product_variant) == parseInt(item.id)) {
                active_variant = parseInt(item.id);
                variant_id = item.patch_id;
                walls_type = item.walls_type;
                update_price(active_variant);
              }
            });

          } else {
            if ( (element["walls"] == "1") && (parseInt(element.product_id) == parseInt(product_id)) ) {
              
              
              for (let index = 0; index < 3; index++) {
                if (element["product_standard_name_" + index] == active_standard ) {
                  
                  switch (element["walls_type_" + index]) {
                    case "single":
                        active_variant = parseInt(element["walls_single_active_product_variant_" + index]);

                        update_price(active_variant);

                        walls_type = "single";
                      break;
                  
                    default:
                        walls_type = "multi";
                        active_variant = parseInt(element["walls_" + walls_order + "_combination_" + index]);
                        update_price(active_variant);
                      break;
                  }
                }
                
              }

            }

            else {
              if ( (element.active_product_variant == null) && (parseInt(element.product_id) == parseInt(product_id)) ) {
              // product = element.patch_id
              Object.entries(element).forEach( item  => {
                const [key,value] = item;
                for (let index = 0; index < 3; index++) {
                  if (key == "product_standard_name_"+index && value == active_standard) {
                    active_variant = parseInt(element["active_product_variant_" + index]);
                    //////////////console.log(active_variant);

                    product_variants.forEach(item => {
                      if (parseInt(active_variant) == parseInt(item.id)) {
                        variant_id = item.patch_id;
                      }
                    });
                    
                    update_price(active_variant);
                    // označenie aktívnej kombinácie stien
                    walls_type = element["walls_type_" + index];
                  }
                }
              });
            }
            }

          }
        });
      }

      //////////console.log(product + " : " + variant_id);

      let positions = ["f","l","r","u","d","b"];
      let scene = krpano.get("xml.scene").toUpperCase();

      positions.forEach(side => {
        //console.log(product);
        // krpano.call("set(hotspot[hts_" + element.patch_id + "_" + current_scene + "_" + side + "].url, assets/patches/" + current_scene + "_" + element.patch_id +"_%$" +element.patch_id +"%_" + side + ".png);");
        if (_dizajn != "") {
          krpano.call("set(hotspot[hts_" + product + "_" + scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "patches/" + scene + "_" + _dizajn + "_&_" + product +"_" + variant_id + "_" + side + _mobile_patch_suffix + ".webp);");
          //console.log(userdata['cdn_path'] + "assets/patches/" + scene + "_" + _dizajn + "_&_" + product +"_" + variant_id + "_" + side + _mobile_patch_suffix + ".webp");
        } else {
          krpano.call("set(hotspot[hts_" + product + "_" + scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "patches/" + scene + "_&_" + product +"_" + variant_id + "_" + side + _mobile_patch_suffix + ".webp);");
          //////////console.log(userdata['base_path'] + "assets/patches/" + scene + "_&_" + product +"_" + variant_id + "_" + side + _mobile_patch_suffix + ".webp");
        }
        
        //krpano.call("set(hotspot[hts_" + element.patch_id + "_" + scene + "_" + side + "].url, " + userdata['base_path'] + "assets/patches/" + scene + "_" + _dizajn + "_&_" + element.patch_id +"_" + variant_id + "_" + side + ".webp);");
       
      });

      
      get_walls_message();
    }

  }



  // načítanie produktov
  config_products.subscribe(value => {
    _config_products = value;

    if (value != undefined) {
      
      config_products_loaded = true;
      // ////////////console.log(_config_products);
    }    
  });

  // načítanie produktových variantov
  config_product_variants.subscribe(value => {
    _config_product_variants = value;
    
    if (value != undefined) {
      
      config_product_variants_loaded = true;
      // sort(_config_product_variants, "variant_order");

      get_standards();
    }    
  });

  

  // označenie aktívneho variantu produktu
  function select_active_variant(id) {
    //////////console.log(id);
    
    
    
    if (id != undefined) {
     ////console.log("******** idem zvoliť aktívny variant produktu : " + id + "product_di : " + product_id);

      krpano = document.getElementById("krpanoSWFObject");
      let variant_id = null;
      let product = null;
      let krpano_var = null;
      product_variants.forEach(element => {
        if (parseInt(element.id) == parseInt(id)) {
          //////////////console.log(element);
          ////console.log("aktívny produkt variant : " + id);
          variant_id = element.patch_id;
          krpano_var = element.patch_id;
        }
      });

      products.forEach(element => {
        
        if (parseInt(element.id) == parseInt(product_id)) {
          //////////console.log(element.id + " " + product_id);  
          krpano = document.getElementById("krpanoSWFObject");
        
          product = element.patch_id;
          ////console.log(element);
          ////console.log("product : " + product);

          if (element.product_blur) {
            patch_preloader = true;
          }

          
          // ////////////console.log(element.patch_id );
          //////////console.log(krpano.get(element.patch_id));
          

          let positions = ["f","l","r","u","d","b"];

          let scene = krpano.get("xml.scene").toUpperCase();

          positions.forEach(side => {
            //////////console.log("[hts_" + element.patch_id + "_" + _current_scene + "_" + side + "]");
            // krpano.call("set(hotspot[hts_" + element.patch_id + "_" + current_scene + "_" + side + "].url, assets/patches/" + current_scene + "_" + element.patch_id +"_%$" +element.patch_id +"%_" + side + ".png);");
            if (_dizajn != "") {
              //console.log("strana : " + _mobile_patch_suffix);
              krpano.call("set(hotspot[hts_" + element.patch_id + "_" + scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "patches/" + scene + "_" + _dizajn + "_&_" + element.patch_id +"_" + variant_id + "_" + side + _mobile_patch_suffix + ".webp);");
            } else {
              krpano.call("set(hotspot[hts_" + element.patch_id + "_" + scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/" + userdata["assets_folder"] + "patches/" + scene + "_&_" + element.patch_id +"_" + variant_id + "_" + side + _mobile_patch_suffix + ".webp);");
            }
            
            
            ////console.log("hotspot[hts_" + element.patch_id + "_" + _current_scene + "_" + side + "].url, " + userdata['cdn_path'] + "assets/patches/" + _current_scene + "_" + _dizajn + "_&_" + element.patch_id +"_" + variant_id + "_" + side  + _mobile_patch_suffix + ".webp");
          });
        }
      });
    
   
      _configuration.forEach(element => {
        if (parseInt(element.product_id) === parseInt(product_id)) {
          if (
            element.active_product_standard == null
          ) {
            element.active_product_variant = id;  
          } else {
            
            Object.entries(element).forEach( item  => {
              const [key,value] = item;

              
              for (let index = 0; index < 3; index++) {
                
                if (key == "product_standard_name_"+index && value == active_standard) {

                  // ak ide o steny
                  if ( (element["walls"] == "1") && (parseInt(element.product_id) == parseInt(product_id)) ) {
            
                    for (let index = 0; index < 3; index++) {
                      if (element["product_standard_name_" + index] == active_standard ) {
                        switch(element["walls_type_" + index]) {

                          // označenie aktívneho produktu ak ide o jednotný obklad stien
                          case "single":
                              element["walls_single_active_product_variant_" + index] = parseInt(id);
                            break;
                        
                          // označenie aktívneho produktu ak ide o dvojfarebnú kombináciu stien
                          default:
                              switch (walls_order) {

                                // ak ide o prvú kombináciu
                                case "first":
                                    element["walls_first_combination_" + index] = id;
                                  break;
                              
                                // ak ide o druhú kombináciu  
                                default:

                                    element["walls_second_combination_" + index] = id;
                                  break;
                              }
                            break;
                        }
                      }
                    }
                  } else {
                    element["active_product_variant_" + index] = id;
                  }
                }
                
              }
            });
          }
          
          
        }
      });
      active_variant = id;

      // aktualizácia konfigurácie produktov
      configuration.update(n => _configuration);

      krpano.set(product, krpano_var);
        // ////////////console.log("product : " + product + " | variant : " + active_variant + "/" + krpano_var);

        // let positions = ["f","l","r","u","d","b"];

        // positions.forEach(side => {
        //   // krpano.call("set(hotspot[hts_" + product + "_" + _current_scene + "_" + side + "].url, assets/patches/" + _current_scene + "_" + product + "_" + krpano_var + "_" + side + ".png);");
        //   ////////////console.log("assets/patches/" + _current_scene + "_" + product + "_" + krpano_var + "_" + side + ".png)");
        // });
    }
    
    setTimeout(() => {
      patch_preloader = false;
      //////////console.log("končím");  
    }, 400);

    
  }

  tmp_attribute_from_model.subscribe(value => {
    
    if (value._active_variant != -1) {
      product_id = value._product_id;

      ////////////console.log(product_id);

      select_active_variant(value._active_variant);
    }
  });

  // označenie aktívneho štandardu produktu
  function select_active_standard(id) {
    active_standard = id;

    // naájdenie produktu v konfigurácií a uloženie aktívneho štandardu
    _configuration.forEach(element => {
      if (parseInt(element.product_id) === parseInt(product_id)) {
        element.active_product_standard = active_standard;
      }
    });

    // aktualizácia konfigurácie produktov
    configuration.update(n => _configuration);

  }

  // sortovanie produktov
  function sort(data, key) {
    data.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
    data = data;
  }

  function change_active_wall_combination(type, product) {
    walls_type = type;

    _configuration.forEach(element => {

      if (parseInt(element.product_id) == parseInt(product.id)) {
        
        Object.entries(element).forEach( item  => {
            const [key,value] = item;

            for (let index = 0; index < 3; index++) {
              
              if (key == "product_standard_name_"+index && value == active_standard) {
                // ak ide o steny
                if ( (item["walls"] != "0") ) {
                  
                  element["walls_type_" + index] = type;
                  active_variant = parseInt(element["walls_first_combination_" + index]);
                } 
              }
              
            }
          });
      }

    });

    configuration.update(n => _configuration);
    
  }

  // nastavenie textov pre skupinu stien
  function get_walls_message() {
    walls_message_first = "";
    walls_message_second = "";
    _configuration.forEach(element => {
      if (element.product_id == product_id) {
        //walls_message = product_id; 

        if (
          element.walls != "0"
        ) {

        Object.entries(element).forEach( item  => {
          const [key,value] = item;
          for (let index = 0; index < 3; index++) {
            
            if (key == "product_standard_name_"+index && value == active_standard) {
              // ak ide o steny

              if ( (item["walls"] != "0") ) {

                let walls_array = ["A","B","C","D"];

                for (let i = 0; i < walls_array.length; i++) {
                  const letter = walls_array[i];

                  if (element["wall_" + letter +"_" + walls_order + "_comb_" + index] == "first") {
                    walls_message_first = walls_message_first + letter;
                    element["walls_first_text_" + index] = walls_message_first;
                    element["walls_second_text_" + index] = walls_message_second;
                  }
                  else {
                    walls_message_second = walls_message_second + letter;
                    element["walls_second_text_" + index] = walls_message_second;
                    element["walls_first_text_" + index] = walls_message_first;
                  }

                }                  
              } 
            }

          }

        });
          
        }

      }
    });

    _configuration.forEach(element => {
      if (element.product_id == product_id) {
        Object.entries(element).forEach( item  => {
          const [key,value] = item;
          for (let index = 0; index < 3; index++) {
            if (key == "product_standard_name_"+index && value == element.active_product_standard) {

            }
          }
        });
      }

      });

    
    configuration.update(n => _configuration);
    

  }

  // zmena steny A,B,C,D
  function change_wall(from, where, value, id){
    
    _configuration.forEach(element => {
      if (parseInt(element.id) == parseInt(id)) {
        // ////////////console.log(element[from] + " : " + element[where]);
        switch (value) {
          case "first" : 
            element[from] = "second";
            element[where] = "second";
          break;

          default : 
            element[from] = "first";
            element[where] = "first";
          break;

        }

        // ////////////console.log(element[from] + " : " + element[where]);
          
         
        
        
      }
      
    });

    configuration.update(n => _configuration);
    
  }

  // zmena ceny
  function update_price(active_variant) {
    ////////console.log("mám preárť cenu : " + active_variant);
    ////////console.log(price_list);
    if ( price_list != null &&
      price_list.length != 0) {
      ////////console.log("prerátavam cenu");
      let variant_price = null;
      let variant_mnozstvo = null;
      let product_id = null;
      let final_price = 0;
    
      product_variants.forEach(element => {
      
        if (parseInt(element.id) == parseInt(active_variant)) {
          //////////////console.log(element.id + " " + active_variant);
          variant_price = element.price;
          product_id = (element.product_id);
        }
      });

      //////////////console.log("cena variantu :" + variant_price);

      Object.keys(price_list).forEach(key => {
        let var_id = key.replace("n_", "");
        //////////////console.log(var_id + " ||  " + product_id);
        if (var_id == product_id) {
          variant_mnozstvo = price_list[key]  ;
          final_price = variant_price * variant_mnozstvo;
          //////////////console.log(variant_price + " * " + variant_mnozstvo + " = " + final_price);
        }
      });

      _configuration.forEach(element => {
        // ////////////console.log(element);
        if (parseInt(element.product_id) == parseInt(product_id)) {
          element.price = final_price;
          //////////////console.log(element);
        }
      });

      configuration.update(n => _configuration);
    }
  }

  function open_3d_model() {
    modal.update(n => true);
    modal_type.update(n => 'model-3d');
    attributes.update(n => false);
  }

  import {newDelay} from "../store.js";
  let delay = 0;
  async function setDelay(newDelay) {
		delay = newDelay;
	}

</script>
<div id="woow-attributes">
  {#if products != null && product_variants != null && _attributes == true && _vrMode == false}
    <div id="attributes" class="attributes" class:hide_blur="{blur_enabled === true}" transition:fly={{x: -280, delay}}>
      <div class="header">
        {#each products as item}
          {#if item.id == product_id}
          <h1>{item.name}</h1>
          {/if}
        {/each}
        <div class="close"  on:click={() => attributes.update(n => false)}/>
      </div>

      {#each products as item}
        {#if item.id == product_id}
          <!-- výpis štandardov -->
          <div class="standard">
            {#each _product_standards as standard}
              <button class="standard-item" class:active="{standard === active_standard}" 
                on:click={() => select_active_standard(standard)}
                on:click={() => get_active_item()}
              >{standard}</button>
            {/each}
          </div>
        {/if}
        
        <!-- ak ide o steny -->
        {#if item.id == product_id && item.walls == true}
          <div class="attribute-type one-color">
            <div class="title" on:click={() => change_active_wall_combination("single", item)}>Jednotný obklad stien</div>
            <div class="attribute-content" class:active={walls_type === "single"}>
              <div class="walls">
                <div class="checked" />
                <div class="selected-material">
                  {#each product_variants as variant}
                    {#if variant.id == active_variant}
                      <Lightbox description="{variant.short_description}">
                        <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                      </Lightbox>
                    {/if}
                  {/each}
                </div>
                <div class="all-walls">
                  <div class="wall-basic wall-a" />
                  <div class="wall-basic wall-b" />
                  <div class="wall-basic wall-c" />
                  <div class="wall-basic wall-d" />
                </div>
              </div>
              <div class="description-text">Voľba obkladu pre stenu <strong>A B C D</strong></div>
              <div class="dimension">
                {#each product_variants as variant}
                  {#if variant.id == active_variant}  
                    {variant.short_description}
                  {/if}
                {/each}
              </div>
              <div class="material-name">
                {#each product_variants as variant}
                  {#if variant.id == active_variant}  
                    <span>{variant.name}</span> <span>{formatter.format(variant.price)}</span>
                  {/if}
                {/each}
              </div>
              <div class="wall-materials">
                <!-- výpis variantov produktov -->
                {#each product_variants as variant}
                  {#if variant.product_id == product_id}
          
                  <!-- ak produkt nemá štandardy zobraz všetky atribúty -->
                    {#if active_standard === null}
                      <div class="wall-item" class:active={parseInt(variant.id) == parseInt(active_variant)} on:click={() => select_active_variant(variant.id)}>
                        <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                      </div>
                    {:else}
                      <!-- ak má produkt štandardy zobraz iba varianty so zhodným aktívnym štandardom -->
                      {#if variant.type == active_standard}
                        <div class="wall-item" class:active="{parseInt(variant.id) === parseInt(active_variant)}" on:click={() => select_active_variant(variant.id)}>
                          <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                        </div>
                      {/if}
                    {/if}
                  {/if}
                {/each}
              </div>
            </div>
          </div>
          <!-- výpis stien pre dvojfarebnú kombináciu -->
          <div class="attribute-type two-color">
            <div class="title" on:click={() => change_active_wall_combination("multi", item)}>Dvojfarebná kombinácia obkladu</div>
            <div class="attribute-content" class:active={walls_type === "multi"} >
              <div class="wall-description">1. Zvoľte prvú kombináciu stien</div>
              <div class="walls" class:active={walls_order === "first"} on:click={() => walls_order = "first"} on:click={() => get_active_item()}>
                <div class="checked" />
                <div class="selected-material">
                  {#each _configuration as produkt}
                     {#if produkt.product_id == item.id}
                      {#each product_variants as variant}
                        {#if active_standard == "style"}
                          {#if parseInt(variant.id) == parseInt(produkt["walls_first_combination_0"])}
                            <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                          {/if}
                        {:else}
                          {#if parseInt(variant.id) == parseInt(produkt["walls_first_combination_1"])}
                            <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                          {/if}
                        {/if}
                        
                      {/each}
                     {/if}
                  {/each}
                </div>
                <div class="all-walls">
                  {#each _configuration as value}
                    {#if parseInt(value.product_id) == parseInt(product_id)} 
                        {#if value.active_product_standard == value.product_standard_name_0}
                          <div class="wall-basic wall-a" class:active={value.wall_A_first_comb_0 === "first"} on:click={() => change_wall("wall_A_first_comb_0", "wall_A_second_comb_0", value.wall_A_first_comb_0, value.id)}/>
                          <div class="wall-basic wall-b" class:active={value.wall_B_first_comb_0 === "first"} on:click={() => change_wall("wall_B_first_comb_0", "wall_B_second_comb_0", value.wall_B_first_comb_0, value.id)}/>
                          <div class="wall-basic wall-c" class:active={value.wall_C_first_comb_0 === "first"} on:click={() => change_wall("wall_C_first_comb_0", "wall_C_second_comb_0", value.wall_C_first_comb_0, value.id)}/>
                          <div class="wall-basic wall-d" class:active={value.wall_D_first_comb_0 === "first"} on:click={() => change_wall("wall_D_first_comb_0", "wall_D_second_comb_0", value.wall_D_first_comb_0, value.id)}/>
                        {/if}

                        {#if value.active_product_standard == value.product_standard_name_1}
                          <div class="wall-basic wall-a" class:active={value.wall_A_first_comb_1 == "first"} on:click={() => change_wall("wall_A_first_comb_1", "wall_A_second_comb_1", value.wall_A_first_comb_1, value.id)}/>                       
                          <div class="wall-basic wall-b" class:active={value.wall_B_first_comb_1 == "first"} on:click={() => change_wall("wall_B_first_comb_1", "wall_B_second_comb_1", value.wall_B_first_comb_1, value.id)}/>                       
                          <div class="wall-basic wall-c" class:active={value.wall_C_first_comb_1 == "first"} on:click={() => change_wall("wall_C_first_comb_1", "wall_C_second_comb_1", value.wall_C_first_comb_1, value.id)}/>                
                          <div class="wall-basic wall-d" class:active={value.wall_D_first_comb_1 == "first"} on:click={() => change_wall("wall_D_first_comb_1", "wall_D_second_comb_1", value.wall_D_first_comb_1, value.id)}/>                
                        {/if}

                        {#if value.active_product_standard == value.product_standard_name_2}
                          <div class="wall-basic wall-a" class:active={value.wall_A_first_comb_2 == "first"} on:click={() => change_wall("wall_A_first_comb_2", "wall_A_second_comb_2", value.wall_A_first_comb_2, value.id)}/>                                          
                          <div class="wall-basic wall-b" class:active={value.wall_B_first_comb_2 == "first"} on:click={() => change_wall("wall_B_first_comb_2", "wall_B_second_comb_2", value.wall_B_first_comb_2, value.id)}/>
                          <div class="wall-basic wall-c" class:active={value.wall_C_first_comb_2 == "first"} on:click={() => change_wall("wall_C_first_comb_2", "wall_C_second_comb_2", value.wall_C_first_comb_2, value.id)}/>
                          <div class="wall-basic wall-d" class:active={value.wall_D_first_comb_2 == "first"} on:click={() => change_wall("wall_D_first_comb_2", "wall_D_second_comb_2", value.wall_D_first_comb_2, value.id)}/>
                        {/if}
                    {/if}
                  {/each}
                </div>
              </div>
              <div class="wall-description">2. Zvoľte druhú kombináciu stien</div>
              <div class="walls" class:active={walls_order === "second"} on:click={() => walls_order = "second"} on:click={() => get_active_item()}>
                <div class="checked" />
                <div class="selected-material">
                  {#each _configuration as produkt}
                     {#if produkt.product_id == item.id}
                      {#each product_variants as variant}
                        {#if active_standard == "style"}
                          {#if parseInt(variant.id) == parseInt(produkt["walls_second_combination_0"])}
                            <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                          {/if}
                        {:else}
                          {#if parseInt(variant.id) == parseInt(produkt["walls_second_combination_1"])}
                            <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                          {/if}
                        {/if}
                      {/each}
                     {/if}
                  {/each}
                </div>
                <div class="all-walls">
                  {#each _configuration as value}
                    {#if parseInt(value.product_id) == parseInt(product_id)} 
                        {#if value.active_product_standard == value.product_standard_name_0}
                        <div class="wall-basic wall-a" class:active={value.wall_A_second_comb_0 === "second"} on:click={() => change_wall("wall_A_second_comb_0", "wall_A_first_comb_0", value.wall_A_second_comb_0, value.id)}/>
                          <div class="wall-basic wall-b" class:active={value.wall_B_second_comb_0 === "second"} on:click={() => change_wall("wall_B_second_comb_0", "wall_B_first_comb_0", value.wall_B_second_comb_0, value.id)}/>
                          <div class="wall-basic wall-c" class:active={value.wall_C_second_comb_0 === "second"} on:click={() => change_wall("wall_C_second_comb_0", "wall_C_first_comb_0", value.wall_C_second_comb_0, value.id)}/>
                          <div class="wall-basic wall-d" class:active={value.wall_D_second_comb_0 === "second"} on:click={() => change_wall("wall_D_second_comb_0", "wall_D_first_comb_0", value.wall_D_second_comb_0, value.id)}/>
                        {/if}

                          {#if value.active_product_standard == value.product_standard_name_1}
                          <div class="wall-basic wall-a" class:active={value.wall_A_second_comb_1 == "second"} on:click={() => change_wall("wall_A_second_comb_1", "wall_A_first_comb_1", value.wall_A_second_comb_1, value.id)}/>                       
                            <div class="wall-basic wall-b" class:active={value.wall_B_second_comb_1 == "second"} on:click={() => change_wall("wall_B_second_comb_1", "wall_B_first_comb_1", value.wall_B_second_comb_1, value.id)}/>                       
                            <div class="wall-basic wall-c" class:active={value.wall_C_second_comb_1 == "second"} on:click={() => change_wall("wall_C_second_comb_1", "wall_C_first_comb_1", value.wall_C_second_comb_1, value.id)}/>                
                            <div class="wall-basic wall-d" class:active={value.wall_D_second_comb_1 == "second"} on:click={() => change_wall("wall_D_second_comb_1", "wall_D_first_comb_1", value.wall_D_second_comb_1, value.id)}/> 
                        {/if}

                        {#if value.active_product_standard == value.product_standard_name_2}
                        <div class="wall-basic wall-a" class:active={value.wall_A_second_comb_2 == "second"} on:click={() => change_wall("wall_A_second_comb_2", "wall_A_first_comb_2", value.wall_A_second_comb_2, value.id)}/>                                          
                          <div class="wall-basic wall-b" class:active={value.wall_B_second_comb_2 == "second"} on:click={() => change_wall("wall_B_second_comb_2", "wall_B_first_comb_2", value.wall_B_second_comb_2, value.id)}/>
                          <div class="wall-basic wall-c" class:active={value.wall_C_second_comb_2 == "second"} on:click={() => change_wall("wall_C_second_comb_2", "wall_C_first_comb_2", value.wall_C_second_comb_2, value.id)}/>
                          <div class="wall-basic wall-d" class:active={value.wall_D_second_comb_2 == "second"} on:click={() => change_wall("wall_D_second_comb_2", "wall_D_first_comb_2", value.wall_D_second_comb_2, value.id)}/>
                        {/if}
                    {/if}
                  {/each}
                  </div>
              </div>
              <div class="description-text">
                {#if walls_order == "first"}
                  Voľba obkladu pre stenu <strong>{walls_message_first}</strong>
                {:else}
                  Voľba obkladu pre stenu <strong>{walls_message_second}</strong>
                {/if}
                
              </div>
              <div class="dimension">
                {#each product_variants as variant}
                  {#if variant.id == active_variant}  
                    {variant.short_description}
                  {/if}
                {/each}
              </div>
              <div class="material-name">
                {#each product_variants as variant}
                  {#if variant.id == active_variant}  
                  <span>{variant.name}</span> <span>{formatter.format(variant.price)}</span>
                  {/if}
                {/each}
              </div>
              <div class="wall-materials">
                <!-- výpis variantov produktov -->
                {#each product_variants as variant}
                  {#if variant.product_id == product_id}
                  <!-- ak produkt nemá štandardy zobraz všetky atribúty -->
                    {#if active_standard === null}
                      <div class="wall-item" class:active="{parseInt(variant.id) === parseInt(active_variant)}" on:click={() => select_active_variant(variant.id)}>
                        <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                      </div>
                    {:else}
                      <!-- ak má produkt štandardy zobraz iba varianty so zhodným aktívnym štandardom -->
                      {#if variant.type == active_standard}
                        <div class="wall-item" class:active="{parseInt(variant.id) === parseInt(active_variant)}" on:click={() => select_active_variant(variant.id)}>
                          <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                        </div>
                      {/if}
                    {/if}
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        {/if}
        {#if item.id == product_id && item.walls == false}
        
          <!-- Názov produktu -->
          <div class="subtitle">
            <div class="sub-name">
              {#each product_variants as variant}
                {#if variant.id == active_variant}  
                  {variant.name}
                {/if}
              {/each}
            </div>
            <div class="price">
              {#each product_variants as variant}
                {#if variant.id == active_variant}  
                  {formatter.format(variant.price)}
                {/if}
              {/each}
            </div>
          </div>
      

          <!-- náhľadový obrázok pre aktívny prvok -->
          <div class="materials">
            {#each product_variants as variant}
              {#if variant.id == active_variant}
                <div class="item item-thumb span-col-2 span-row-2">
                  <Lightbox description="{variant.name} - {variant.short_description}">
                    <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                  </Lightbox>
                </div>
              {/if}
            {/each}
            
            <!-- výpis variantov produktov -->
            {#each product_variants as variant}
              {#if variant.product_id == product_id}
      
              <!-- ak produkt nemá štandardy zobraz všetky atribúty -->
                {#if active_standard === null}
                  <div class="item" class:active="{parseInt(variant.id) === parseInt(active_variant)}" on:click={() => select_active_variant(variant.id)}>
                    <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                  </div>
                {:else}
                  <!-- ak má produkt štandardy zobraz iba varianty so zhodným aktívnym štandardom -->
                  {#if variant.type == active_standard}
                    <div class="item" class:active="{parseInt(variant.id) === parseInt(active_variant)}" on:click={() => select_active_variant(variant.id)}>
                      <img src="{userdata["cdn_path"]}assets/{userdata["assets_folder"]}thumbnails/{variant.variant_id}.jpg" alt={variant.variant_id} />
                    </div>
                  {/if}
                {/if}
      
                
              {/if}
            {/each}
          </div>
        
          <!-- krátky popis produktu -->
          <div class="description">
            {#each product_variants as variant}
              {#if variant.id == active_variant}  
                {variant.short_description}
              {/if}
            {/each}
          </div>
        {/if}
      {/each}

      <!-- zobrazenie zväzbeného produktu -->
      {#each products as item}
        {#if item.id == product_id}
          {#if item.bind != null || item.bind != "" }
            {#each products as product}
              {#if product.id == item.bind}
                <div class="attribute-type floor" on:click={() => active_product_id.update( n => product.id)}>
                  <div class="title">{product.name}</div>
                  <div class="attribute-content" />
                </div>
              {/if}
            {/each}      
          {/if}
        {/if}
      {/each}
    </div>
  {/if}

  {#if patch_preloader && _vrMode == false}
     <div id="patch_preloader">
      <div class="wrapper">
        <div class="preloader">
        </div>
        <div class="data">
          <p>{$t("components")['preloader']['patches_loading']} </p>
        </div>
      </div>
     </div>
  {/if}

</div>
  <style lang="scss">
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/variables.scss";
  
    #woow-attributes{
    #attributes {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      width: 320px;
      height: calc(100% - 32px);
      @include blur;
      border-radius: $radius-M;
      transition: all 0.25s ease-in-out;
        z-index: 10;

      .header {
        padding: 16px 16px 2px 16px;
        @include underline-2-a;
  
        h1 {
          @include rubik-m20;
          padding-right: 28px;
          margin-bottom: 8px;
        }

        img {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          height: 30px;
          cursor: pointer;
        }
      }
      .standard {
        display: flex;
        gap: 8px;
        justify-content: center;
        margin: 12px 0;
        .standard-item {
          width: 140px;
          height: 32px;
          border: 1px var(--primary-color) solid;
          @include rubik-r14;
          border-radius: $radius-S;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.active {
            background-color: var(--primary-color);
            color: var(--secondary-color);
            @include rubik-m14;
          }
        }
      }
      .subtitle {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 0 16px;
        @include rubik-r14;
        @include underline-1-ar(0px);
        flex-wrap: wrap;
        margin-bottom: 12px;
  
        .price {
          // &::after {
          //   content: " €";
          //   position: relative;
          //   margin-left: 2px;
          // }
        }
      }
  
      .materials {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 6px;
        padding: 0 16px;
        // @include underline-1-b;
  
        .item {
          width: 48px;
          height: 48px;
          border-radius: $radius-S;
          background-color: var(--secondary-color-light005);
          // border: solid 1px var(--primary-color-light20);
          box-shadow: 0 0 0 1px var(--primary-color-light20);
          cursor: pointer;

          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color: white;
            border-radius: $radius-S;
          }
  
          &.default-color {
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              content: "";
              display: block;
              width: 200px;
              height: 2px;
              background-color: var(--alert-color);
              transform: rotate(45deg);
            }
          }
  
          &.item-thumb {
            position: relative;
            width: 108px;
            height: 108px;
            
            &::after {
              content: "";
              width: 32px;
              height: 32px;
              pointer-events: none;
              position: absolute;
              bottom: 8px;
              left: 8px;
              border-radius: $radius-S;
              filter: drop-shadow(0px 0px 1px var(--secondary-color)) drop-shadow(0px 0px 1px var(--secondary-color));

            }
          }
          &.active {
            box-shadow: 0 0 0 2px var(--primary-color-light60);
          }
        }
      }
  
      .span-col-2 {
        grid-column: span 2 / auto;
      }
  
      .span-row-2 {
        grid-row: span 2 / auto;
      }
  
      .description {
        @include rubik-r14;
        padding: 0 16px;
        @include underline-1-b(0px);
        margin-top: 12px;
      }
  
      .attribute-type {
        position: relative;
        width: calc(100% - 16px);
        margin: auto;
        margin-bottom: 8px;
        height: auto;
        min-height: 32px;
        border: 1px solid var(--primary-color-light20);
        border-radius: $radius-S;
  
        .title {
          @include rubik-m14;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          cursor: pointer;
          border-radius: $radius-S;
  
          &::after {
            content: "";
            width: 12px;
            height: 7px;
            background-color: var(--primary-color);
            
            display: inline-block;
            position: relative;
            transform: rotate(180deg);
          }
        }
        .attribute-content {
          display: none;
          width: 100%;
          height: auto;
          @include underline-1-b(0px);
          padding: 0 8px 8px;
          .walls {
            display: flex;
            justify-content: space-between;
            border: 1px solid var(--primary-color);
            border-radius: $radius-S;
            background-color: var(--primary-color-light10);
            cursor: pointer;
  
            .checked {
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: var(--primary-color);
              outline: 1px solid var(--primary-color);
              outline-offset: 2px;
              margin: 5px;
              cursor: pointer;
            }
            .selected-material {
              position: relative;
              margin: 22px 0 8px 18px;
              width: 40px;
              height: 40px;
              box-shadow: 0 0 0 1px var(--primary-color);
              border-radius: $radius-S;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              &::after {
                content: "";
                position: relative;
                width: 8px;
                height: 14px;
                background-color: var(--primary-color);
                mask-repeat: no-repeat;
                mask-position: center;
                display: block;
                left: 21px;
              }

              img {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: $radius-S;
              }
            }
            .all-walls {
              margin: 22px 8px 8px 0;
              display: flex;
              gap: 8px;
              .wall-basic {
                width: 40px;
                height: 40px;
                border: 1px solid var(--primary-color);
                border-radius: $radius-S;
                display: flex;
                justify-content: center;
                background-color: var(--secondary-color);
                background-repeat: no-repeat;
                background-position: center;
                &::before {
                  content: "";
                  @include rubik-m12;
                  position: relative;
                  top: -18px;
                }
                &.wall-a {
                  &::before {
                    content: "A";
                  }
                }
                &.wall-b {
                  &::before {
                    content: "B";
                  }
                }
                &.wall-c {
                  &::before {
                    content: "C";
                  }
                }
                &.wall-d {
                  &::before {
                    content: "D";
                  }
                }
              }
            }
          }
          &.active {
            display: block;
          }
        }
        &.two-color {
          .wall-description {
            @include rubik-r12;
            margin: 8px 0 4px;
          }
          .walls {
            &:not(.active) {
              background-color: transparent;
              border: 1px solid var(--primary-color-light20);
              .checked {
                background-color: transparent;
                outline: 1px solid var(--primary-color-light20);
              }
            }
  
            .all-walls {
              .wall-basic {
                cursor: pointer;
                &:not(.active) {
                  border: 1px solid var(--primary-color-light60);
                  opacity: 0.4;
                }
              }
            }
          }
        }
      }
  
      .description-text {
        @include rubik-r12;
        margin: 12px 0 4px 0;
        strong {
          @include rubik-m12;
        }
      }
      .dimension {
        width: max-content;
        padding: 3px 12px;
        color: var(--secondary-color);
        background-color: var(--primary-color);
        border-radius: $radius-S;
        @include rubik-r12;
      }
      .material-name {
        @include rubik-m12;
        margin: 11px 0 4px;
        display: flex;
        justify-content: space-between;
      }
      .wall-materials {
        display: flex;
        gap: 10px;
        .wall-item {
          width: 40px;
          height: 40px;
          border: 1px solid var(--primary-color-light20);
          border-radius: $radius-S;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: $radius-S;
          }
          &.active {
            box-shadow: 0 0 0 1px var(--primary-color);
          }
        }
      }
    }

    #patch_preloader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(63, 63, 70, 0.2);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .wrapper {
        min-width: 200px;
        min-height: 140px;
        border-radius: 16px;
        background: #F1F5F9;
        backdrop-filter: blur(40px);
        -webkit-backdrop-filter: blur(40px);
        -moz-filter: blur(40px);
        cursor: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 100;

        .preloader {
          width: 64px;
          height: 64px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          opacity: 1;
          visibility: visible;
          transition: all 0.25s ease-in-out;
          
        }
          & > div >  p {
            color: #292930;
          }
      }

      
    }
  
    // thumbnails
    .grey {
      background-image: url("../assets/images/thumbnails/grey.jpg");
    }
  
    .white {
      background-image: url("../assets/images/thumbnails/white.jpg");
    }
  
    .taupe {
      background-image: url("../assets/images/thumbnails/taupe.jpg");
    }
  
    .dark {
      background-image: url("../assets/images/thumbnails/dark.jpg");
    }
  
    .biela {
      background-image: url("../assets/images/thumbnails/biela.jpg");
    }
  
    .bardolino {
      background-image: url("../assets/images/thumbnails/bardolino.jpg");
    }
  
    .hamilton {
      background-image: url("../assets/images/thumbnails/hamilton.jpg");
    }
  
    .lyon {
      background-image: url("../assets/images/thumbnails/lyon.jpg");
    }
  
    .pacifik {
      background-image: url("../assets/images/thumbnails/pacifik.jpg");
    }
  
    //  ----------------HOVER-------------------------------------------------------------------------------------------------------
  
    @media (hover: hover) and (pointer: fine) {
      #attributes {
        .materials {
          .item {
            &:hover {
              // border: 2px solid var(--primary-color-light60);
              box-shadow: 0 0 0 2px var(--primary-color-light60);
            }
          }
        }
        .attribute-type {
          .title {
            &:hover {
              background-color: var(--primary-color-light005);
            }
          }
          .wall-materials {
            .wall-item {
              &:hover {
                box-shadow: 0 0 0 1px var(--primary-color);
              }
            }
          }
          &.two-color {
            .walls {
              &:not(.active) {
                &:hover {
                  background-color: var(--primary-color-light005);
                }
              }
            }
            .all-walls {
              .wall-basic {
                &:not(.active) {
                  &:hover {
                    border: var(--primary-color) 1px solid;
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  </style>